function ChevronDoubleRight() {
  return (
    <path
      d="M11 19L4 12L11 5M19 19L12 12L19 5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ChevronDoubleRight;
