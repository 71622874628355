import { Col, Row } from 'antd';
import { useMeContext } from '../../../context';
import { getFullName } from '../../../helpers';
import { ConnectAdmin } from '../../../stories/assets/svgs';
import './ChcAdminHeader.scss';
import { ChcAdminHeaderMenu } from './ChcAdminHeaderMenu';

function ChcAdminHeader() {
  const { me } = useMeContext();
  const fullName = getFullName(me);
  return (
    <Row className="chc-admin-header" align="middle">
      <Col flex={1}>
        <ConnectAdmin />
      </Col>
      <Col flex={1} className="display-flex justify-end">
        <ChcAdminHeaderMenu text={fullName} />
      </Col>
    </Row>
  );
}
export default ChcAdminHeader;
