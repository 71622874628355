import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Col, Row, Typography } from 'antd';
import { PopupButton } from '@typeform/embed-react';

import { capitalizeText, formatDecimal } from '../../../helpers';
import { Anchor, Select, Title, Paragraph } from '../../../stories/atoms';
import { useAsync } from '../../../hooks';
import InitiativeTemplateService from '../../../services/initiativeTemplateService';
import './StepOne.scss';

const { Text } = Typography;

export function StepOne({ companySlug }) {
  const { setValues, values } = useFormikContext();
  const [currentTemplate, setCurrentTemplate] = useState();

  const fetchBenchmarkTemplates = useCallback(async () => {
    return await InitiativeTemplateService.listInitiativeTemplates({
      companySlug: companySlug,
      type: 'BENCHMARK'
    });
  }, [companySlug]);

  const {
    value: benchmarkTemplateResults,
    isLoading: isBenchmarkTemplatesLoading
  } = useAsync({
    defaultValue: { list: [], count: 0 },
    asyncFunction: fetchBenchmarkTemplates
  });

  const emptyState = (
    <Row
      justify="middle"
      align="center"
      className="detailsContainer emptyState"
    >
      <Row>Select a benchmark to see the details here</Row>
    </Row>
  );

  let benchmarkTemplates = [];

  if (!isBenchmarkTemplatesLoading) {
    benchmarkTemplates = benchmarkTemplateResults.list.map((temp) => ({
      ...temp,
      label: temp.name,
      value: temp.id
    }));
  }

  const onSelectChange = (value) => {
    setValues({ ...values, benchmarkTemplate: value });
    setCurrentTemplate(benchmarkTemplates.find((temp) => temp.value === value));
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row wrap={false} gutter={16} align="middle">
          <Col flex="1">
            {!isBenchmarkTemplatesLoading && (
              <Select
                disableForm
                options={benchmarkTemplates}
                placeholder="Select"
                value={values?.benchmarkTemplate}
                onChange={(value) => onSelectChange(value)}
              />
            )}
          </Col>
          <Col>
            <PopupButton
              id="tYn1BI1W"
              className="ch-typeform-button--secondary"
              buttonProps={{
                type: 'button'
              }}
            >
              Request
            </PopupButton>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Title bottomSpacing={4}>Benchmark Details</Title>
        </Row>
        <Row>
          <Paragraph bottomSpacing={8}>
            Select a benchmark to see the deatils here
          </Paragraph>
        </Row>
        {currentTemplate ? (
          <BenchmarkDetails
            totalBenchmarkEmissions={formatDecimal(
              currentTemplate.details.CO2E_totalEmissions / 1000,
              9
            )}
            benchmarkSourceUrl={currentTemplate.details.sourceUrl}
            benchmarkSourceName={currentTemplate.details.sourceName}
            benchmarkType={currentTemplate.details.benchmarkType}
            intensityMetric={currentTemplate.details.intensityMetric}
            categories={currentTemplate.tags ? currentTemplate.tags : []}
          />
        ) : (
          emptyState
        )}
      </Col>
    </Row>
  );
}

function BenchmarkDetails({
  totalBenchmarkEmissions,
  benchmarkSourceUrl,
  benchmarkSourceName,
  benchmarkType,
  intensityMetric,
  categories
}) {
  return (
    <div>
      <div className="detailsContainer mt-4" span={24}>
        <Row>
          <Col span={12}>
            <Title size="sm">Total benchmark emissions</Title>
          </Col>
          <Col span={12} className="text-right">
            <Title size="sm">Benchmark Source</Title>
          </Col>
        </Row>
        <Row>
          <Col span={8}>{totalBenchmarkEmissions} tCO2e</Col>
          <Col span={16} className="text-right">
            <Anchor href={benchmarkSourceUrl}>{benchmarkSourceName}</Anchor>
          </Col>
        </Row>
      </div>
      {benchmarkType === 'INTENSITY' && (
        <div className="detailsContainer mt-4" span={24}>
          <Row>
            <Col span={12}>
              <Title size="sm">Benchmark Type</Title>
            </Col>
            <Col span={12} className="text-right">
              <Title size="sm">Intensity Metric</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Text>{capitalizeText(benchmarkType)}</Text>
            </Col>
            <Col span={12} className="text-right">
              <Text>{intensityMetric}</Text>
            </Col>
          </Row>
        </div>
      )}
      {categories.length > 0 && (
        <div className="detailsContainer mt-4" span={24}>
          <Row>
            <Col span={12}>
              <Title size="sm">Included Categories</Title>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingTop: '10px' }}>
              {categories.map((cat) => {
                return (
                  <Row justify="space-between" className="categoriesSpacing">
                    <Col>
                      <Text className="categoriesText">{cat.name}</Text>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
StepOne.propTypes = {
  companySlug: PropTypes.string
};
