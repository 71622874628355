import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class InitiativeTemplateService {
  static async listInitiativeTemplates(
    { pagination, companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE_TEMPLATES_ROOT}/${companySlug}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.initiativeTemplates,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.initiativeTemplates,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getInitiativeTemplate(
    initiativeTemplateId,
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE_TEMPLATES_ROOT}/${initiativeTemplateId}`;
      const response = await AxiosInstance.get(API_URL);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default InitiativeTemplateService;
