import { Col, Row, Select as AntSelect } from 'antd';
import { useFormikContext } from 'formik';
import {
  DISTANCE_CALCULATION_TYPE,
  TransportationType
} from '../../../constants';
import transportationType from '../../../constants/transportationType';
import { Select } from '../../atoms';
import { DistanceFields } from './DistanceFields';
import { PersonalVehicleFields } from './PersonalVehicleFields';
import { ToFromAirportFields } from './ToFromAirportFields';
import { ToFromFields } from './ToFromFields';

const SelectOption = AntSelect.Option;
export const defaultDistanceMetrics = [
  TransportationType.CAR,
  transportationType.MOTORBIKE
];
export const defaultToFromAirportMetrics = [transportationType.PLANE];
export const defaultToFromMetrics = [
  transportationType.INTERCITY_TRAIN,
  transportationType.LOCAL_BUS
];

const renderDistanceCalculationTypeFieldOption = ({
  label,
  value,
  travelCategoryName
}) => {
  let showDefaultLabel = false;
  switch (travelCategoryName) {
    case TransportationType.PLANE: {
      if (value === 'CIRCLE_GEOMETRY') {
        showDefaultLabel = true;
      }
      break;
    }
    case TransportationType.LOCAL_BUS:
    case TransportationType.INTERCITY_TRAIN: {
      if (value === 'GOOGLE_DISTANCE_MATRIX_API') {
        showDefaultLabel = true;
      }
      break;
    }
    default: {
      if (value === 'NONE') {
        showDefaultLabel = true;
      }
      break;
    }
  }
  return (
    <SelectOption value={value}>
      {`${label}${showDefaultLabel ? ' (Default)' : ''}`}
    </SelectOption>
  );
};
export function DistanceCalculationTypeFields({
  isEmissionSourceBased,
  distanceFieldKey
}) {
  const { setFieldValue, values } = useFormikContext();
  const { travelCategoryName } = values;
  const distanceCalculationTypeOptions = [
    ...(travelCategoryName === TransportationType.PLANE
      ? [
          {
            label: 'To/From Airport',
            value: DISTANCE_CALCULATION_TYPE.CIRCLE_GEOMETRY
          }
        ]
      : [
          {
            label: 'To/From Address',
            value: DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX
          }
        ]),
    {
      label: 'Total Distance ',
      value: DISTANCE_CALCULATION_TYPE.NONE
    }
  ];

  const isPersonalVehicle =
    defaultDistanceMetrics.indexOf(travelCategoryName) > -1;
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Select
          setFieldValue={setFieldValue}
          title="How do you want to enter your data?"
          name="distanceCalculationType"
          value={values.distanceCalculationType}
          disabled={!values.travelCategoryId && !isEmissionSourceBased}
        >
          {distanceCalculationTypeOptions.map((opt) =>
            renderDistanceCalculationTypeFieldOption({
              ...opt,
              travelCategoryName
            })
          )}
        </Select>
      </Col>
      <Col span={24}>
        {isPersonalVehicle && !isEmissionSourceBased && (
          <PersonalVehicleFields />
        )}
      </Col>
      <Col span={24}>
        {values.distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE && (
          <DistanceFields distanceFieldKey={distanceFieldKey} />
        )}
        {values.distanceCalculationType ===
          DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX && (
          <ToFromFields distanceFieldKey={distanceFieldKey} />
        )}
        {values.distanceCalculationType ===
          DISTANCE_CALCULATION_TYPE.CIRCLE_GEOMETRY && <ToFromAirportFields />}
      </Col>
    </Row>
  );
}
