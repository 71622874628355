import { Popover as AntPopover } from 'antd';
import classNames from 'classnames';
import { Icon } from '../../stories/atoms';
import './Popover.scss';

function Popover({
  title,
  content,
  className,
  cardClassName,
  children,
  trigger = 'click',
  warning,
  readMoreLink
}) {
  return (
    <sup>
      <AntPopover
        arrowPointAtCenter
        placement="topLeft"
        className={classNames('text-primary ch-popover ml-1 mr-1', {
          [className]: !!className,
          warning
        })}
        overlayClassName={classNames('ch-popover-card', {
          [cardClassName]: !!cardClassName
        })}
        title={title && <span className="text-sbd">{title}</span>}
        content={
          <div className="ch-popover__content">
            {readMoreLink ? (
              <>
                {content}
                {readMoreLink}
              </>
            ) : (
              content
            )}
          </div>
        }
        trigger={trigger}
      >
        {!!children ? (
          children
        ) : (
          <Icon size={18} name="InformationCircle" color="#5C29C7" />
        )}
      </AntPopover>
    </sup>
  );
}
export default Popover;
