import * as Yup from 'yup';
import { TargetType } from '../../../constants';

export const setTargetSettingStrategyValidation = Yup.object().shape({
  type: Yup.string().nullable().required('Please select a type'),
  endYear: Yup.number().nullable().required('Please select a target end year'),
  name: Yup.string()
    .nullable()
    .when('type', {
      is: TargetType.INTENSITY,
      then: Yup.string()
        .nullable()
        .required('Please select and intensity metric'),
      otherwise: Yup.string().nullable()
    }),
  customName: Yup.string()
    .nullable()
    .when('name', {
      is: 'CUSTOM',
      then: Yup.string()
        .nullable()
        .required('Please name your intensity metric'),
      otherwise: Yup.string().nullable()
    })
});
