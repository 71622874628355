import React from 'react';
import { Outlet } from 'react-router-dom';

function EmployeeDetails() {
  return (
    <div className="employee-details">
      <Outlet />
    </div>
  );
}

export default EmployeeDetails;
