import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './Switcher.scss';

function Switcher({ onClick, options = [], activeValue = false, className }) {
  return (
    <div className={classNames('ch-switcher', { [className]: !!className })}>
      {options.map(({ label, value }, i) => (
        <Button
          key={i}
          value={value}
          onClick={() => onClick?.(value)}
          className={classNames('ch-switcher-button', {
            active: activeValue === value
          })}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}
export default Switcher;
