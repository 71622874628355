import { formatInTimeZone } from 'date-fns-tz';
import { Notification } from '../components';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';
import apiRoutes from '../routes/apiRoutes';

class TravelExpenseService {
  static async fetchTravelExpenses(
    { pagination, companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const baseUrl = ApiRoutes.TRAVEL_EXPENSE.replace(
        ':companySlug',
        companySlug
      );
      const API_URL = `${baseUrl}/list`;
      const response = await axiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data.travelItems,
        count: Number(response.data.totalRowCount)
      });
      return {
        list: response.data.travelItems,
        count: Number(response.data.totalRowCount)
      };
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createTravelExpense(
    companySlug,
    travelExpense,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.TRAVEL_EXPENSE.replace(
      ':companySlug',
      companySlug
    );

    try {
      const response = await axiosInstance.post(API_URL, travelExpense);
      Notification({
        message: 'Travel expenses',
        description: 'Travel expense item created',
        type: 'success'
      });
      onSuccess?.(response.data);
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateTravelExpense(
    companySlug,
    travelExpense,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.TRAVEL_ITEM}/${travelExpense.id}`;
    if (travelExpense.userId && !travelExpense.userCompanyId) {
      travelExpense.ownerId = travelExpense.userId;
      travelExpense.companySlug = companySlug;
    }

    try {
      const response = await axiosInstance.put(API_URL, travelExpense);
      Notification({
        message: 'Travel expenses',
        description: 'Travel expense item updated',
        type: 'success'
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async deleteTravelExpense(travelExpense, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.TRAVEL_ITEM}/${travelExpense.id}`;
    try {
      await axiosInstance.delete(API_URL, travelExpense);
      Notification({
        message: 'Travel expenses',
        description: 'Travel expense item deleted',
        type: 'success'
      });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchTravelExpenseSummary(
    {
      companySlug,
      rangeStart,
      rangeEnd,
      userIds,
      groupBy = 'CATEGORY',
      userCompanyIds
    },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.TRAVEL_SUMMARY.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = await axiosInstance.get(API_URL, {
        params: {
          rangeStart,
          rangeEnd,
          userIds,
          userCompanyIds,
          groupBy
        }
      });
      let formattedResp = [];
      if (!!response?.data?.emissionSummary) {
        formattedResp = response.data?.emissionSummary.map(
          ({ date, users, ...emissions }) => {
            if (groupBy === 'CATEGORY') {
              return {
                ...emissions,
                month: formatInTimeZone(new Date(date), 'UTC', 'MMM yy')
              };
            }
            const month = formatInTimeZone(new Date(date), 'UTC', 'MMM-yy');
            return {
              users,
              month
            };
          }
        );
      }

      onSuccess?.(formattedResp);
      return formattedResp;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static sendTravelExpenseEmail(
    { companySlug, groupSlug, userIds },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.TRAVEL_EMPLOYEE.replace(
      ':companySlug',
      companySlug
    )}/notify/send`;
    axiosInstance
      .post(API_URL, {
        groupSlug,
        userIds
      })
      .then((response) => {
        Notification({
          message: 'Reminder sent',
          type: 'success'
        });
        onSuccess(response.data);
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }

  static async listTravelEmissionSummaries(
    params,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = apiRoutes.TRAVEL_EMISSION_SUMMARIES;
    await axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        if (response?.data?.summary) {
          onSuccess(response.data.summary);
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async listTravelEmissionSummariesByCompany(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = apiRoutes.TRAVEL_EMISSION_SUMMARIES_COMPANY.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.get(API_URL, { params });

      onSuccess?.(response.data?.emissionSummary);
      return response.data?.emissionSummary;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async hardDeleteTravelItems(
    companySlug,
    { uploadProcessId },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.TRAVEL_ITEM_HARD_CLEAR.replace(
      ':companySlug',
      companySlug
    );
    try {
      await axiosInstance.delete(`${API_URL}`, {
        data: {
          uploadProcessId
        }
      });
      onSuccess?.();
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }
}

export default TravelExpenseService;
