import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class VehicleService {
  static async fetchVehicles(
    { companySlug, loggedInUserId },
    params,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.COMMUTE_VEHICLE.replace(
      ':companySlug',
      companySlug
    )}/list${loggedInUserId ? '' : '/me'}`;
    try {
      const response = await axiosInstance.get(API_URL, {
        params: {
          ...params,
          ...(loggedInUserId && { userId: loggedInUserId })
        }
      });
      onSuccess?.(response.data.vehicles);
      return response.data.vehicles ?? [];
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createVehicle(
    { companySlug, loggedInUserId },
    vehicle,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.COMMUTE_VEHICLE.replace(
      ':companySlug',
      companySlug
    )}${loggedInUserId ? '' : '/me'}`;
    if (loggedInUserId) vehicle.userId = loggedInUserId;
    try {
      const response = await axiosInstance.post(API_URL, vehicle);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default VehicleService;
