import * as Yup from 'yup';

export const employeeDetailsAddressValidation = Yup.object().shape({
  countryId: Yup.string().nullable().required('Country is required'),
  postalCode: Yup.string()
    .nullable()
    .max(10, 'Should not contain more than 10 characters'),
  officeCollectionId: Yup.string()
    .nullable()
    .required('Office Location is required')
});
