import { Col, Row, Select as AntSelect } from 'antd';
import { useFormikContext } from 'formik';
import produce from 'immer';
import { useCallback, useState } from 'react';
import { Popover } from '../../../../../components';
import { CollectionType, YesOrNoOptions } from '../../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../../constants/colours';
import { useMeContext } from '../../../../../context';
import {
  getDropdownOptions,
  getFormattedAddress
} from '../../../../../helpers/generators';
import { useAsync } from '../../../../../hooks';
import { CollectionService } from '../../../../../services';
import TagService from '../../../../../services/tagService';
import {
  Button,
  DatePicker,
  Drawer,
  Icon,
  Paragraph,
  Radio,
  Select,
  Title
} from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import Input from '../../../../../stories/atoms/Input';
import { LocationForm } from '../../../../../stories/molecules';
import './AddDetails.scss';

function AddDetails({ isEdit = true }) {
  const { setFieldValue, values } = useFormikContext();
  const { me } = useMeContext();

  const [showLocationForm, setShowLocationForm] = useState(false);
  const loadTags = useCallback(async () => {
    const response = await TagService.listTags({
      companySlug: me.company?.slug,
      tagFamilyNames: ['EMISSION_SOURCE']
    });

    return response.list.map((arrayItem) => ({
      label: arrayItem.name,
      value: arrayItem.id,
      description: arrayItem.description,
      tagFamilies: arrayItem.tagFamilies
    }));
  }, [me.company?.slug]);

  const loadLocations = useCallback(async () => {
    const response = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [CollectionType.LOCATION]
    });

    const locationOptions = response.list?.map((collection) => {
      const { location, name } = collection;
      return {
        value: collection.id,
        label: getFormattedAddress({ location, name })
      };
    });
    return locationOptions;
  }, [me.company?.slug]);

  const { isLoading: isLoadingTags, value: tags } = useAsync({
    asyncFunction: loadTags,
    defaultValue: []
  });
  const {
    isLoading: isLoadingLocations,
    value: locations,
    setValue: setLocations
  } = useAsync({
    asyncFunction: loadLocations,
    defaultValue: []
  });

  const handleFetchBusinessUnitsList = useCallback(async () => {
    const response = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [CollectionType.BUSINESS_UNIT]
    });
    return [
      { value: null, label: 'None' },
      ...response.list.map((collection) => ({
        value: collection.id,
        label: collection.name
      }))
    ];
  }, [me?.company?.slug]);

  const { isLoading: isLoadingBusinessUnits, value: businessUnits } = useAsync({
    asyncFunction: handleFetchBusinessUnitsList
  });

  const handleFetchCollectionsList = useCallback(async () => {
    const response = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [CollectionType.COLLECTION]
    });

    return getDropdownOptions(response.list, 'name', 'id');
  }, [me?.company?.slug]);

  const { isLoading: isLoadingCollections, value: collections } = useAsync({
    asyncFunction: handleFetchCollectionsList
  });

  const onCreateLocationSuccess = (location) => {
    setLocations((prevState) =>
      produce(prevState, (draft) => {
        draft.push({
          value: location.collectionId,
          label: getFormattedAddress({ location, name: location.name })
        });
      })
    );
    setFieldValue('locationId', location.collectionId);

    setShowLocationForm(false);
  };
  const isNewSource = !values?.id;
  const isCustomSource = !values?.isInternal;
  return (
    <div className="emission-source-detail-form-add-details">
      <Row className="mb-7" gutter={[24, 24]}>
        <Col span={24}>
          <Input
            name={isCustomSource ? 'name' : 'displayName'}
            title="Name"
            placeholder="Enter"
          />
        </Col>
        <Col span={24}>
          <Input
            type="textarea"
            name="description"
            title="Source Description (Optional)"
            placeholder="Enter"
          />
        </Col>
        <Col span={12}>
          <Select
            name="businessUnitId"
            title="Business Unit (Optional)"
            value={values?.businessUnitId}
            setFieldValue={setFieldValue}
            options={businessUnits}
            loading={isLoadingBusinessUnits}
            placeholder="Select"
          />
        </Col>
        <Col span={12}>
          <Select
            mode="multiple"
            name="collectionId"
            title="Collection (Optional)"
            value={values?.collectionId}
            loading={isLoadingCollections}
            setFieldValue={setFieldValue}
            options={collections}
            placeholder="Select"
          />
        </Col>
        {isCustomSource ? (
          <>
            <Col span={12}>
              <Radio
                setFieldValue={setFieldValue}
                value={values?.dateType}
                disabled={!isNewSource}
                options={[
                  { label: 'Yes', value: 'RANGE' },
                  { label: 'No', value: 'SINGLE' }
                ]}
                name="dateType"
                raised
                title={
                  <span>
                    Do the usage items for this source have a date range?
                    <Popover
                      title="Date Ranges"
                      content="Some usage items occur over a period of time, i.e a monthly utility bill, whereas discrete purchases, such as a flight, require a single date."
                    />
                  </span>
                }
              />
            </Col>
            <Col span={12}>
              <Radio
                setFieldValue={setFieldValue}
                value={values?.hasOperationalControl}
                options={YesOrNoOptions}
                disabled={!isNewSource}
                name="hasOperationalControl"
                raised
                title={
                  <span>
                    Do you have operational control?
                    <Popover
                      title="Operational Control "
                      content={
                        <>
                          <p>
                            If you have operational control it means you have
                            the full authority to implement operating policies.
                          </p>
                          <p>
                            Examples of this would be sources that your company
                            owns (company fleets, planes, delivery cars,
                            offices, etc)
                          </p>
                        </>
                      }
                    />
                  </span>
                }
              />
            </Col>
            <Col span={12}>
              <Radio
                setFieldValue={setFieldValue}
                value={values?.requireNotifications}
                options={YesOrNoOptions}
                name="requireNotifications"
                raised
                title={
                  <span>
                    Will there be monthly data inputs for this source?
                    <Popover
                      title="Monthly Data Inputs"
                      content="Some sources require monthly inputs, for example: Monthly Energy Bills.
  If you would like reminders to enter that data monthly select Yes."
                    />
                  </span>
                }
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <DatePicker
                    defaultValue={values.startDate}
                    name="startDate"
                    title="When did this source become active?"
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={8}>
                  <Radio
                    setFieldValue={setFieldValue}
                    value={values?.isInactive}
                    options={YesOrNoOptions.map((opt) => ({
                      ...opt,
                      value: !opt.value
                    }))}
                    name="isInactive"
                    raised
                    title="Is this source currently active?"
                  />
                </Col>
                {values?.isInactive && (
                  <Col span={8}>
                    <DatePicker
                      defaultValue={values.endDate}
                      name="endDate"
                      title="When did this stop being active?"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Select
                name="tagIds"
                title="Category"
                value={values?.tagIds}
                setFieldValue={setFieldValue}
                loading={isLoadingTags}
                optionLabelProp="title"
                placeholder="Select"
                disabled={!isNewSource}
              >
                {tags.map(
                  (tag) =>
                    ((values?.hasOperationalControl &&
                      tag?.tagFamilies?.indexOf('CONTROLLED') >= 0) ||
                      (!values?.hasOperationalControl &&
                        tag?.tagFamilies?.indexOf('UNCONTROLLED') >= 0)) && (
                      <AntSelect.Option
                        key={tag.value}
                        title={tag.label}
                        value={tag.value}
                      >
                        <div className="emission-source-detail-form-add-details__type-option__header text-sbd">
                          {tag.label}
                        </div>
                        <p className="text-12 m-0 emission-source-detail-form-add-details__type-option__content text-sbd">
                          {tag.description}
                        </p>
                      </AntSelect.Option>
                    )
                )}
              </Select>
            </Col>
            <Col span={24}>
              <Row
                gutter={16}
                className="emission-source-detail-form-add-details__location mb-7"
                justify="center"
              >
                <Col flex="1">
                  <Select
                    name="locationId"
                    title="Location (Optional)"
                    value={values?.locationId}
                    setFieldValue={setFieldValue}
                    options={locations}
                    loading={isLoadingLocations}
                    placeholder="Select or add new"
                  />
                </Col>
                <Col
                  className="emission-source-detail-form-add-details__location__add"
                  flex="shrink"
                >
                  <Button
                    onClick={() => setShowLocationForm(true)}
                    type={BUTTON_TYPES.SECONDARY}
                  >
                    Add new
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col
            span={24}
            className="emission-source-detail-form-add-details__callout"
          >
            <Row gutter={16}>
              <Col>
                <Icon
                  name="Bell"
                  className="mt-1 ml-2"
                  color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                />
              </Col>
              <Col flex="1">
                <Title bottomSpacing={0} fontWeight={500}>
                  This is a system generated source
                </Title>
                <Paragraph bottomSpacing={0}>
                  Only certain details of system generated sources can be
                  modified.
                </Paragraph>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Drawer
        showHeader={false}
        width="33%"
        destroyOnClose
        visible={showLocationForm}
        onClose={() => setShowLocationForm(false)}
      >
        <LocationForm
          isEdit={isEdit}
          showAdditionalFields
          onSuccess={onCreateLocationSuccess}
        />
      </Drawer>
    </div>
  );
}
export default AddDetails;
