import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { ChcAdminService } from '../../../services';
import { ColumnTitle, Icon, Table } from '../../../stories/atoms';
import TableTitle from '../../../stories/molecules/TableTitle';

export function ChcAdminCompanyList() {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const loadAdminCompanyList = useCallback(
    async () => await ChcAdminService.listCompanies(),
    []
  );
  const { value: companyList, isLoading } = useAsync({
    asyncFunction: loadAdminCompanyList,
    defaultValue: []
  });

  const filteredCompanyList = companyList.filter(
    (company) =>
      company.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );
  const handleSearchList = (value) => {
    setSearchValue(value);
  };
  const columns = [
    {
      title: <ColumnTitle>Company Name</ColumnTitle>,
      dataIndex: 'name',
      render: (name) => <span className="text-bd">{name}</span>
    },
    // {
    //   title: <ColumnTitle>Carbonhound Account Status</ColumnTitle>,
    //   dataIndex: "status",
    //
    //   render: (status) => {
    //     if (status) return <Tag color={TAG_COLOURS.BLUE}>{status}</Tag>;
    //     else return <Tag color={TAG_COLOURS.YELLOW}>In Trial</Tag>;
    //   },
    // },
    {
      title: <ColumnTitle>Carbonhound Connect</ColumnTitle>,
      dataIndex: 'integration',
      render: (integration) => {
        if (!integration) return 'No';
        return capitalizeText(integration.status);
      }
    },
    {
      width: '24px',
      render: () => <Icon size={24} name="ChevronRight" />
    }
  ];

  return (
    <div>
      <TableTitle
        className="mb-8"
        title="Companies"
        searchProps={{ onSearch: handleSearchList }}
      />
      <Table
        dataSource={filteredCompanyList}
        columns={columns}
        isLoading={isLoading}
        onRow={(companyDetails) => ({
          className: 'cursor-pointer',
          onClick: () => {
            navigate(
              AppRoutes.CHC_ADMIN_COMPANY_DETAILS.replace(
                ':companySlug',
                companyDetails.slug
              ),
              { state: { companyDetails } }
            );
          }
        })}
      />
    </div>
  );
}
