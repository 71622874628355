import classNames from 'classnames';
import './Page.scss';

function Page({ children, className, ...props }) {
  return (
    <div
      className={classNames('ch-page', { [className]: !!className })}
      {...props}
    >
      {children}
    </div>
  );
}
export default Page;
