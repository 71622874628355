import { Col, Row } from 'antd';
import {
  InitiativeTemplateFinancing,
  InitiativeTemplateRelatedActions
} from '../../../../features/Initiative/InitiativeTemplate';

export function CompanyInitiativeFunding() {
  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Planning',
    level3: 'Funding'
  };
  return (
    <Row gutter={[0, 40]}>
      <Col span={24}>
        <InitiativeTemplateFinancing
          analyticsContext={analyticsContext}
          includeCaseStudies={false}
        />
      </Col>
      <Col span={24}>
        <InitiativeTemplateRelatedActions
          analyticsContext={{ ...analyticsContext, level4: 'Actions Table' }}
        />
      </Col>
    </Row>
  );
}
