import classNames from 'classnames';
import React from 'react';
import './SubscriptionStatusIndicator.scss';
import { AppRoutes } from '../../../routes';
import { Link } from '../../atoms';

export function SubscriptionStatusIndicator({
  className,
  expiryDateCount,
  subscriptionStatus
}) {
  return (
    <Link
      to={AppRoutes.SUBSCRIPTION}
      className={`${classNames('subscription-status-indicator', {
        [className]: !!className,
        warning:
          !!expiryDateCount && expiryDateCount <= 15 && expiryDateCount > 0,
        error: !expiryDateCount
      })} justify-center display-flex`}
      align="center"
    >
      <span>
        Free trial:
        <span className="text-bd">{` ${subscriptionStatus}`}</span>
      </span>
    </Link>
  );
}
