import { Col, Radio as AntRadio, Row } from 'antd';
import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import Error from '../../../components/Error';
import { Img } from '../Img';
import Label from '../Label';
import './radio.scss';

function Radio({
  title,
  name,
  raised = true,
  descriptive,
  options,
  className,
  value,
  setFieldValue,
  onChange,
  disabled,
  type
}) {
  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <>
      {title && <Label>{title}</Label>}
      <Field name={name}>
        {() => (
          <div
            className={classNames('ch-radio-field-new', {
              [className]: !!className,
              'ch-radio-field-descriptive': !!descriptive
            })}
          >
            <AntRadio.Group
              disabled={disabled}
              className={classNames('ch-radio', {
                'ant-radio-raised': !!raised
              })}
              onChange={onChange || handleChange}
              value={value}
            >
              {options.map((option, i) =>
                type === 'button' ? (
                  <AntRadio.Button value={option.value} key={i}>
                    <span className="ch-radio-field__icon">{option.icon}</span>
                    <span className="ch-radio-field__button-label">
                      {option.label}
                    </span>
                  </AntRadio.Button>
                ) : (
                  <AntRadio value={option.value} key={i}>
                    <Row gutter={16} align="middle">
                      {option.icon && (
                        <Col>
                          <Img width={30} src={option.icon} />
                        </Col>
                      )}
                      <Col>
                        {option.label}
                        {option.description ? (
                          <div>{option.description}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </AntRadio>
                )
              )}
            </AntRadio.Group>
            <ErrorMessage name={name}>
              {(message) => (
                <Error className={`${name}__error`} message={message} />
              )}
            </ErrorMessage>
          </div>
        )}
      </Field>
    </>
  );
}

export default Radio;
