import classNames from 'classnames';

export function Img({ src, alt, width, height, className, ...props }) {
  return (
    <img
      {...props}
      src={src}
      alt={alt}
      style={{ width, height }}
      className={classNames('ch-image', {
        [className]: !!className
      })}
    />
  );
}
