import axios from 'axios';
import { Notification } from '../components';
import { history } from '../helpers';
import { AppRoutes } from '../routes';
import ApiRoutes from '../routes/apiRoutes';
import { AuthService } from '../services';
import { successValidator } from './statusValidators';

export const getHeaders = () => {
  let headers;
  let token;
  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token');
  }
  headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 200000,
  validateStatus: successValidator
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => ({
    data: response.data,
    message: response.statusText,
    status: response.status
  }),
  (error) => {
    const { response } = error;
    let errorMessage;
    if (response?.data?.errors?.length > 0) {
      errorMessage = response?.data?.errors
        .map((error) => error.friendlyMessage ?? error.msg)
        .join('\n');
    }
    if (
      (response.status === 401 || response.status === 498) &&
      (response?.data?.name === 'JWT_TOKEN_EXPIRED' ||
        response?.data?.name === 'JWT_TOKEN_INVALID' ||
        response?.data?.name === 'JWT_TOKEN_MISSING')
    ) {
      AuthService.logout(() => {
        history.push(AppRoutes.LOGIN, { isLoggedOut: true });
      });
      Notification({
        type: 'error',
        message: 'Your session expired, please sign in again'
      });
    } else if (response.status === 401) {
      Notification({
        type: 'error',
        message: 'Invalid username or password'
      });
    } else if (
      response.status === 403 &&
      response?.data?.name === 'TERMS_AND_CONDITIONS_UPDATE_REQUIRED'
    ) {
      history.push(AppRoutes.TERMS, {
        isTermsInvalid: true
      });
    } else if (
      response.status === 403 &&
      (response?.data?.name === 'APP_ACCESS_SUBSCRIPTION_NOT_FOUND' ||
        response?.data?.name === 'APP_ACCESS_SUBSCRIPTION_EXPIRED')
    ) {
      history.push(AppRoutes.SUBSCRIPTION, { isSubscriptionInvalid: true });
    } else if (response.status === 400 && errorMessage) {
      Notification({
        type: 'error',
        message: errorMessage
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
