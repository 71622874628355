import { Col, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import { Notification } from '../../../components';
import { ESTIMATION_METHODS, YesOrNoOptions } from '../../../constants';
import { useAsync } from '../../../hooks';
import { CollectionService, EmissionSourceService } from '../../../services';
import { Button, InputNumberField, Paragraph, Radio, Title } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import { GenerateCollectionEstimateFormValidation } from './GenerateCollectionEstimateFormValidation';

export function GenerateCollectionEstimateForm({
  source,
  onSuccess,
  onCancel
}) {
  const {
    internalDetails,
    id: emissionSourceDetailsId,
    collections
  } = source ?? {};
  const { hasSeparateMeter } = internalDetails ?? {};
  const collection = collections?.[0] ?? {};
  const { id: collectionId, details: collectionDetails } = collection;
  const { buildingSquareFootage, officeSquareFootage } =
    collectionDetails ?? {};
  const initialFormValues = {
    emissionSourceDetailsId,
    hasSeparateMeter,
    estimationMethod: undefined,
    officeSquareFootage,
    buildingSquareFootage
  };

  const estimationMethodOptions = [
    {
      label: 'Fill gaps',
      value: ESTIMATION_METHODS.GAP,
      description:
        'Populates estimated data for the missing data portions within your data.'
    },
    {
      label: 'Overwrite existing data',
      value: ESTIMATION_METHODS.REPLACE,
      description:
        'Clears existing data and re-populates it with estimated data'
    }
  ];

  const handleGenerateEmissions = async (values) => {
    const locationParams = {
      officeSquareFootage: values.officeSquareFootage,
      buildingSquareFootage: values.buildingSquareFootage,
      collectionId
    };
    const emissionSourceParam = {
      id: emissionSourceDetailsId,
      hasSeparateMeter: values.hasSeparateMeter
    };
    const generateEmissionsParams = {
      emissionSourceDetailsId,
      collectionId,
      estimationMethod: values.estimationMethod
    };
    try {
      await CollectionService.updateCollection(locationParams);
    } catch (e) {
      Notification({
        type: 'error',
        message: 'Unable to update Collection. Please try again'
      });
      return;
    }
    try {
      await EmissionSourceService.updateEmissionSourcesDetails(
        emissionSourceParam
      );
    } catch (e) {
      Notification({
        type: 'error',
        message: 'Unable to update Collection. Please try again'
      });
      return;
    }
    try {
      await CollectionService.processCollectionEstimations(
        generateEmissionsParams
      );
      onSuccess?.();
    } catch (e) {
      Notification({
        type: 'error',
        message: 'Unable to generate estimations. Please try again'
      });
    }
  };

  const { execute: handleSubmit, isLoading } = useAsync({
    immediate: false,
    asyncFunction: handleGenerateEmissions
  });
  return (
    <Formik
      validationSchema={GenerateCollectionEstimateFormValidation}
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Paragraph bottomSpacing={0}>
                Estimates will populate activity data for the timeline this
                source has been active. Activity is estimated based off of
                square footage and country average.
              </Paragraph>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Title bottomSpacing={16}>
                    Before we generate estimates we need a few more details
                  </Title>
                  <Radio
                    title="Is data for this source separately metered?"
                    name="hasSeparateMeter"
                    options={YesOrNoOptions}
                    value={values.hasSeparateMeter}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col flex="1">
                  <InputNumberField
                    title="Location Square Footage"
                    name="officeSquareFootage"
                    value={values.officeSquareFootage}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                {!values.hasSeparateMeter && (
                  <Col span={12}>
                    <InputNumberField
                      title="Building Square Footage"
                      name="buildingSquareFootage"
                      value={values.buildingSquareFootage}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Title bottomSpacing={16}>
                    How would you like to generate your estimates?
                  </Title>
                  <Radio
                    name="estimationMethod"
                    value={values.estimationMethod}
                    setFieldValue={setFieldValue}
                    options={estimationMethodOptions}
                    descriptive
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Space>
                    <Button
                      disabled={isLoading}
                      onClick={onCancel}
                      type={BUTTON_TYPES.SECONDARY}
                    >
                      Back
                    </Button>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      htmlType="save"
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
