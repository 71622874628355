import { notification } from 'antd';

export const copyToClipboardFormatted = ({ ref, name }) => {
  function listener(e) {
    e.clipboardData.setData('text/html', ref.current.innerHTML);
    e.clipboardData.setData('text/plain', ref.current.innerHTML);
    e.preventDefault();
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
  notification.open({
    type: 'success',
    message: 'Copied Successfully',
    description: `${name} has been copied to your clipboard`
  });
};

export const copyToClipboard = ({ ref, text }) => {
  navigator.clipboard
    .writeText(!!ref?.current ? ref?.current.textContent : text)
    .then(() => {
      notification.open({
        type: 'success',
        message: 'Success',
        description: 'Text copied successfully'
      });
    });
};
