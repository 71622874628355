import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Card } from '../../../../components';
import { InitiativeImplementationPartners } from '../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { InitiativeResource } from '../../../../features/Initiative/InitiativeResource';
import { InitiativeTemplateRelatedActions } from '../../../../features/Initiative/InitiativeTemplate';
import {
  analytics,
  createLocationList,
  createLocationOptionList
} from '../../../../helpers';
import {
  Anchor,
  Icon,
  Paragraph,
  Select,
  Title
} from '../../../../stories/atoms';
import { InitiativeContentCard } from '../../../../stories/molecules';
import './CompanyInitiativeBusinessPlan.scss';

export function CompanyInitiativeBusinessPlan() {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { details } = initiativeTemplate;
  const { businessResourceUrls, technicalPartnerFilter } = details ?? {};
  const { me } = useMeContext();

  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Planning',
    level3: 'Business Plan'
  };
  const handleHelpClicked = () => {
    analytics.track('Help clicked', me, {
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
  };

  const setFilteredTechnicalPartners = new Set();
  for (const filter of technicalPartnerFilter ?? []) {
    const technicalPartners = InitiativeImplementationPartners.filter(
      (tpf) => tpf.technicalPartnerFilters.indexOf(filter?.toUpperCase()) > -1
    );
    if (technicalPartners?.length) {
      technicalPartners.forEach((study) =>
        setFilteredTechnicalPartners.add(study)
      );
    }
  }
  const filteredTechnicalPartners = Array.from(setFilteredTechnicalPartners);

  const [
    technicalPartnersLocationFilters,
    setTechnicalPartnersLocationFilters
  ] = useState([]);
  const templateTechnicalPartnersLocationListOptions = createLocationOptionList(
    filteredTechnicalPartners
  );
  const templateTechnicalPartnersLocationList = createLocationList(
    filteredTechnicalPartners,
    technicalPartnersLocationFilters
  );
  const handleFilterTechnicalPartnersLocations = (values) => {
    setTechnicalPartnersLocationFilters(values);
  };
  return (
    <Row gutter={[0, 40]} className="Company-Initiative-Business-Plan">
      {!!filteredTechnicalPartners?.length && (
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={16}>
              <Title bottomSpacing={4}>
                Technical & Implementation Partners
              </Title>
              <Paragraph bottomSpacing={8}>
                This is a collection of vetted, experienced providers who have
                successfully implemented initiatives with our clients
              </Paragraph>
            </Col>
            <Col span={8}>
              <Select
                mode="multiple"
                disableForm
                options={templateTechnicalPartnersLocationListOptions}
                placeholder="Filter by location"
                onChange={handleFilterTechnicalPartnersLocations}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                {templateTechnicalPartnersLocationList.map((card) => (
                  <Col className="display-flex">
                    <InitiativeContentCard
                      analyticsContext={{
                        ...analyticsContext,
                        level4: 'Implementation Partners'
                      }}
                      card={card}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={24} className="Company-Initiative-Business-Plan__help">
              <Anchor
                flex
                href="https://meetings.hubspot.com/hannah472/initiatives-provider-consultation-"
                showLinkIcon={false}
                onClick={handleHelpClicked}
              >
                <Card>
                  <Row align="middle" gutter={16}>
                    <Col className="display-flex align-center">
                      <Icon
                        name="Phone"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                    <Col flex={1}>
                      Contact us for help selecting a provider that makes sense
                      for you
                    </Col>
                    <Col className="display-flex align-center">
                      <Icon
                        name="ChevronRight"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                  </Row>
                </Card>
              </Anchor>
            </Col>
          </Row>
        </Col>
      )}
      {!!businessResourceUrls && (
        <Col span={24}>
          <Title bottomSpacing={4}>Resources</Title>
          <Paragraph bottomSpacing={32}>
            This library has been compiled to help you research and communicate
            your plan to internal and external stakeholders
          </Paragraph>
          {businessResourceUrls?.map((resource, i) => (
            <div key={i} className="mb-4">
              <InitiativeResource
                analyticsContext={{ ...analyticsContext, level4: 'Resources' }}
                theme="light"
                companyInitiative={companyInitiative}
                resource={resource}
              />
            </div>
          ))}
        </Col>
      )}
      <Col span={24}>
        <InitiativeTemplateRelatedActions
          analyticsContext={{ ...analyticsContext, level4: 'Actions Table' }}
        />
      </Col>
    </Row>
  );
}
