import classNames from 'classnames';
import { COLOURS_GRAY } from '../../../constants/colours';
import * as IconList from '../../assets/icons';
import './Icon.scss';

function Icon({
  name,
  size = 16,
  _viewBox,
  color = COLOURS_GRAY.GRAY_400,
  className,
  bgColor = 'transparent',
  ...iconProps
}) {
  const showBackground = bgColor !== 'transparent';
  const IconComponent = IconList.default[name];
  return (
    <span
      className={classNames('ch-icon', {
        [className]: !!className,
        [`ch-icon__${name}`]: !!name,
        'ch-icon__circle-bg': showBackground
      })}
      style={{
        width: showBackground ? size * 2 : size,
        height: showBackground ? size * 2 : size,
        backgroundColor: bgColor
      }}
    >
      <svg
        {...iconProps}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        stroke={color}
        style={{
          width: size,
          height: size
        }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {IconComponent && <IconComponent color={color} />}
      </svg>
    </span>
  );
}

export default Icon;
