import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Card, DropdownField, EnergyUsage } from '../../../../../components';
import { EnergyTypeConstants } from '../../../../../constants';
import {
  useMeContext,
  useOfficeOnboardingContext
} from '../../../../../context';
import {
  analytics,
  capitalizeText,
  getYearDropdownOptions
} from '../../../../../helpers';
import { usePagination } from '../../../../../hooks';
import { Energy } from '../../../../../models';
import { AppRoutes } from '../../../../../routes';
import { EmissionSourceService, EnergyService } from '../../../../../services';
import { BarChart, Button } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import './manageBills.scss';

function ManageBills() {
  const navigate = useNavigate();

  const params = useParams();

  const { me, selectCarbonYearRange } = useMeContext();

  const { collectionId, energyDetailsId, energyTypeId } = params;

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [activeEnergyType, setActiveEnergyType] = useState();

  const [selectedYear, setSelectedYear] = useState(
    me.company?.footprintRange?.[0] || new Date().getUTCFullYear()
  );

  const [timeline, setTimeline] = useState([]);

  const {
    officeOnboardingDetails,
    setActiveCompanyLocation,
    setActiveEnergyTypeId
  } = useOfficeOnboardingContext();

  const [completedEnergyItems, setCompletedEnergyItems] = useState([]);

  const [_loading, setLoading] = useState(false);

  const [emissionSummary, setEmissionSummary] = useState([]);

  const { paginationParams } = usePagination({
    paginationArray: completedEnergyItems,
    orderBy: ['startDate', 'id']
  });
  const yearOptions = getYearDropdownOptions();

  const {
    pageSize,
    pageNumber,
    prevPageNumber,
    orderBy,
    previousValue,
    reverse
  } = paginationParams;

  const hasEmissionData =
    emissionSummary.filter((summary) => summary?.emissions !== 0).length > 0;

  const handlePrevious = () => navigate(-1);

  const handleNext = async () => {
    const activeIndex =
      officeOnboardingDetails?.activeCompanyLocation?.energyTypes.findIndex(
        (energyType) =>
          officeOnboardingDetails?.activeEnergyTypeId ===
          energyType?.energyTypeId
      );

    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Office Onboarding',
      level3: 'Enter Usage',
      energyDetails: officeOnboardingDetails.activeCompanyLocation,
      energyTypeId: officeOnboardingDetails?.activeEnergyTypeId
    });
    const nextIndex =
      officeOnboardingDetails?.activeCompanyLocation?.energyTypes.findIndex(
        (energyType, i) => i > activeIndex && energyType?.hasAccessToBills
      );
    if (nextIndex > 0) {
      navigate(
        generatePath(
          AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS,
          {
            energyDetailsId,
            collectionId,
            energyTypeId:
              officeOnboardingDetails?.activeCompanyLocation?.energyTypes[
                nextIndex
              ].energyTypeId
          }
        )
      );
    } else {
      navigate(AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OVERVIEW);
    }
  };

  const handleFetchEnergyItems = useCallback(async () => {
    if (energyDetailsId) {
      setLoading(true);
      await EnergyService.fetchEnergyItems(
        energyDetailsId,
        {
          energyTypeId,
          pagination: {
            pageSize,
            pageNumber,
            prevPageNumber,
            orderBy,
            previousValue,
            reverse
          }
        },
        ({ list: energyItems, count: totalRowsCount }) => {
          setTotalRowsCount(totalRowsCount);
          setCompletedEnergyItems(energyItems);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [
    energyDetailsId,
    energyTypeId,
    orderBy,
    pageNumber,
    pageSize,
    prevPageNumber,
    previousValue,
    reverse
  ]);

  const handleFormSuccess = () => {
    handleFetchEnergyItems();
    handleFetchSummary();
    handleFetchTimelineSummary();
  };

  const handleFetchTimelineSummary = useCallback(() => {
    if (activeEnergyType) {
      const { rangeStart } = selectCarbonYearRange({
        activeYear: selectedYear
      });
      const { rangeEnd } = selectCarbonYearRange({
        activeYear:
          me.company?.footprintRange?.[me.company.footprintRange.length - 1] ||
          selectedYear
      });
      EmissionSourceService.fetchEmissionSourceDetailsTimeline(
        {
          emissionSourceDetailsId: activeEnergyType.sourceId,
          rangeStart,
          rangeEnd
        },
        (timeline) => {
          setTimeline(timeline);
        },
        () => {},
        () => {}
      );
    }
  }, [activeEnergyType?.sourceId]);

  const handleFetchSummary = useCallback(() => {
    if (energyDetailsId) {
      const { rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: selectedYear
      });
      EnergyService.fetchOfficeActivitySummary(
        {
          // companySlug: me?.company?.slug,
          energyDetailsId,
          energyTypeId,
          rangeStart,
          rangeEnd
        },
        (emissionSummary) => {
          setEmissionSummary(emissionSummary);
        },
        () => {},
        () => {}
      );
    }
  }, [energyDetailsId, energyTypeId, selectedYear]);

  useEffect(() => {
    handleFetchEnergyItems();
    setActiveEnergyType(
      officeOnboardingDetails?.activeCompanyLocation?.energyTypes?.find(
        (energyType) =>
          energyType.energyTypeId ===
          officeOnboardingDetails?.activeEnergyTypeId
      )
    );
  }, [officeOnboardingDetails, handleFetchEnergyItems]);

  useEffect(() => {
    if (selectedYear) {
      handleFetchSummary();
    }
  }, [handleFetchSummary, selectedYear]);

  useEffect(() => {
    setActiveEnergyTypeId(energyTypeId);
  }, [energyTypeId]);

  useEffect(() => {
    if (collectionId) {
      EnergyService.fetchEnergies(
        collectionId,
        (energies) => {
          if (energies.length > 0) {
            const energyDetails = Object.assign(new Energy(), energies[0]);
            setActiveCompanyLocation(energyDetails);
          }
        },
        () => {},
        () => {}
      );
      handleFetchTimelineSummary();
    }
  }, [collectionId, handleFetchTimelineSummary]);

  return (
    <div className="manage-bills">
      <h3 className="text-bd">
        Enter{' '}
        {activeEnergyType ? capitalizeText(activeEnergyType?.energyType) : ''}{' '}
        data
      </h3>
      <div className="manage-bills__description">
        Please enter the information from your bills so we can calculate the
        impact of your office space
      </div>
      <Card className="manage-bills__card">
        <Row>
          <Col offset={15} span={9} className="manage-bills__select-wrapper">
            <span>Date Range</span>
            <DropdownField
              className="manage-bills__select-year"
              value={selectedYear}
              disableForm
              options={yearOptions}
              onChange={(year) => setSelectedYear(year)}
            />
          </Col>
        </Row>
        <div className="manage-bills__chart-wrapper">
          <BarChart
            yAxisText={`Usage (${
              activeEnergyType?.energyType === EnergyTypeConstants.ELECTRICITY
                ? 'kwh'
                : 'mmbtu'
            })`}
            labels={emissionSummary?.map((summary) => summary.month)}
            bgColors={['#5C29C7']}
            data={emissionSummary?.map((summary) => summary.emissions)}
          />
          {!hasEmissionData && (
            <div className="manage-bills__chart-no-data"> No data yet</div>
          )}
        </div>
        <EnergyUsage
          total={totalRowsCount}
          paginationParams={paginationParams}
          energyTypeId={energyTypeId}
          activeEnergyType={activeEnergyType}
          energyDetailsId={
            officeOnboardingDetails?.activeCompanyLocation?.energyDetailsId
          }
          timeline={timeline}
          onSuccess={handleFormSuccess}
          energyItemList={completedEnergyItems}
          analyticsContext={{
            level1: 'Office-Based Operations',
            level2: 'Office Onboarding',
            level3: 'Enter Usage',
            energyDetails: officeOnboardingDetails?.activeCompanyLocation
          }}
          analyticsSaveContext={{
            level1: 'Office-Based Operations',
            level2: 'Office Onboarding',
            level3: 'Enter Usage',
            level4: 'Enter Usage Modal',
            energyDetails: officeOnboardingDetails?.activeCompanyLocation
          }}
        />
      </Card>
      <Row>
        <Col span={12}>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            onClick={handlePrevious}
            prefixIcon="ArrowLeft"
          >
            Previous
          </Button>
        </Col>
        <Col span={12} className="justify-end display-flex">
          <Button htmlType="submit" onClick={handleNext} icon="ArrowRight">
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ManageBills;
