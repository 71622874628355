import { Steps as AntSteps, Modal as AntModal } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMeContext } from '../../../../context';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { Paragraph, Steps, Title } from '../../../../stories/atoms';
import ModalFooter from '../../../../stories/atoms/ModalFooter';
import StepsDot from '../../../../stories/atoms/StepsDot';
import AddDetails from './AddDetails';
import { AddDetailsValidation } from './AddDetails/AddDetailsValidation';
import Complete from './Complete';
import SelectEmissionFactor from './SelectEmissionFactor';
import { SelectEmissionFactorValidation } from './SelectEmissionFactor/SelectEmissionFactorValidation';
import { CollectionType } from '../../../../constants';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { Notification } from '../../../../components';
import { AppRoutes } from '../../../../routes';
import { useCollectionContext } from '../../../../context/CollectionContext';

function EmissionSourceDetailForm({
  onSuccess,
  onClose,
  collectionProps,
  source
}) {
  const navigate = useNavigate();
  const { me } = useMeContext();
  const { fetchChart, fetchTable } = useCollectionContext();
  const formValues = {
    name: source?.name,
    displayName: source?.displayName,
    description: source?.description,
    requireNotifications: source?.requireNotifications,
    hasOperationalControl: source?.hasOperationalControl,
    dateType: source?.dateType,
    tagIds: source?.tags?.[0]?.id,
    locationId:
      collectionProps?.[CollectionType.LOCATION] ||
      source?.collections?.find((cl) => cl.type === CollectionType.LOCATION)
        ?.id,
    collectionId:
      collectionProps?.[CollectionType.COLLECTION] ||
      source?.collections?.find((cl) => cl.type === CollectionType.COLLECTION)
        ?.id,
    businessUnitId:
      collectionProps?.[CollectionType.BUSINESS_UNIT] ||
      source?.collections?.find(
        (cl) => cl.type === CollectionType.BUSINESS_UNIT
      )?.id,
    emissionFactorDetailsId: source?.emissionFactorDetailsId,
    isInactive: source?.isInactive,
    isInternal: source?.isInternal,
    endDate: !!source?.endDate
      ? new Date(
          formatInTimeZone(new Date(source?.endDate), 'UTC', 'yyyy-MM-dd')
        )
      : null,
    startDate: !!source?.startDate
      ? formatInTimeZone(new Date(source?.startDate), 'UTC', 'yyyy-MM-dd')
      : null,
    id: source?.id
  };

  const isNewSource = !source?.id;
  const isCustomSource = !source?.isInternal;
  const formSteps = [
    {
      key: 1,
      dotTitle: 'Details',
      title: 'Add source details',
      content: <AddDetails isEdit={false} />,
      validation: isCustomSource ? AddDetailsValidation : null
    },
    {
      key: 2,
      dotTitle: 'Emission Factor',
      title: 'Select an emission factor',
      content: <SelectEmissionFactor />,
      validation: SelectEmissionFactorValidation
    },
    {
      key: 3,
      dotTitle: 'Complete',
      content: <Complete />
    }
  ];
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleSubmit = async (values) => {
    if (current === formSteps.length - 2 || !isNewSource) {
      if (typeof values.dateType === 'undefined') {
        values.dateType = 'SINGLE';
      }
      const collectionIds = [];
      if (values.locationId) collectionIds.push(values.locationId);
      if (values.collectionId)
        (!Array.isArray(values.collectionId)
          ? Array.of(values.collectionId)
          : values.collectionId
        ).forEach((cid) => collectionIds.push(cid));
      if (values.businessUnitId) collectionIds.push(values.businessUnitId);
      values.collectionIds = collectionIds;

      const submitMethod = isNewSource
        ? EmissionSourceService.createEmissionSourcesDetails
        : EmissionSourceService.updateEmissionSourcesDetails;

      const submitParams = isNewSource
        ? {
            companySlug: me.company?.slug,
            source: values
          }
        : values;
      await submitMethod(submitParams, (emissionSourceDetails) => {
        onSuccess?.(emissionSourceDetails);
        next();
      });
    } else if (current === formSteps.length - 1) {
      fetchChart();
      fetchTable();
      onClose?.();
    } else {
      next();
    }
  };

  const handleDeleteSource = async ({ id, name }) => {
    await EmissionSourceService.deleteEmissionSourcesDetails({ id }, () => {
      Notification({
        type: 'success',
        message: 'Delete Emission Source',
        description: `${name} has been removed from company.`
      });
      navigate(AppRoutes.SOURCES);
    });
  };
  const handleConfirmDeleteSource = () => {
    const { id, name } = source;
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Delete Source',
      cancelText: 'Cancel',
      content: (
        <>
          <Title size="small">
            Are you sure you would like to delete this source?
          </Title>
          <Paragraph>
            If you delete this source it cannot be undone, all the data related
            to this source will also be deleted.
          </Paragraph>
        </>
      ),
      closable: true,
      onOk: () => handleDeleteSource({ id, name })
    });
  };
  const { Step } = AntSteps;

  return (
    <div>
      {isNewSource && (
        <Steps labelPlacement="vertical" size="small" current={current}>
          {formSteps.map((item) => (
            <Step
              className="mb-7"
              key={item.key}
              title={<span className="no-wrap">{item.dotTitle}</span>}
              progressDot={(iconDot, { status, index }) => (
                <StepsDot iconDot={iconDot} status={status} index={index} />
              )}
            />
          ))}
        </Steps>
      )}
      <Formik
        onSubmit={handleSubmit}
        validationSchema={formSteps[current]?.validation}
        enableReinitialize
        initialValues={formValues}
      >
        {({ isValid }) => (
          <Form>
            {isNewSource && formSteps[current]?.title && (
              <Title size="rg" className="mb-6">
                {formSteps[current]?.title}
              </Title>
            )}
            <div>{formSteps[current]?.content}</div>
            <ModalFooter
              showHelpLink={false}
              showCancelButton={isCustomSource}
              cancelButtonCopy={isNewSource ? 'Back' : 'Delete Source'}
              cancelButtonProps={
                isNewSource && current > 0
                  ? {
                      disabled:
                        current === formSteps.length - 1 || current === 0,
                      onClick: prev
                    }
                  : {
                      type: BUTTON_TYPES.DELETE,
                      onClick: handleConfirmDeleteSource
                    }
              }
              saveButtonCopy={
                isNewSource
                  ? current === formSteps.length - 1
                    ? 'Close'
                    : 'Next'
                  : 'Save'
              }
              saveButtonProps={{
                htmlType: 'submit',
                disabled: !isValid
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default EmissionSourceDetailForm;
