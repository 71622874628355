import { Outlet, useNavigate } from 'react-router-dom';
import { Alert, Loader } from '../../../../components';
import {
  EMPLOYEE_ONBOARDING_STEPS_ROUTES,
  EMPLOYEE_ONBOARDING_STEPS_TUPLE
} from '../../../../constants';
import { COLOURS_YELLOW } from '../../../../constants/colours';
import { LayoutContextProvider, useMeContext } from '../../../../context';
import { getFullName } from '../../../../helpers';
import { AppRoutes } from '../../../../routes';
import { Icon, Title } from '../../../../stories/atoms';
import { Content, Layout, Sider } from '../../../../stories/atoms/Layout';
import './OnboardingEmployeeLayout.scss';
import { OnboardingEmployeeLayoutSidebar } from './OnboardingEmployeeLayoutSidebar';

export function OnboardingEmployeeLayout() {
  const navigate = useNavigate();
  const { me, updateLoggedInAs } = useMeContext();
  const { loggedInAs } = me;
  const { user: loggedInAsUser } = loggedInAs ?? {};

  const handleLogOut = () => {
    updateLoggedInAs({ loggedInAs: null });
    navigate(AppRoutes.USER_COMPANY);
  };

  return (
    <LayoutContextProvider
      stepsTuple={EMPLOYEE_ONBOARDING_STEPS_TUPLE}
      routeMapping={EMPLOYEE_ONBOARDING_STEPS_ROUTES}
      routeParams={{ id: 'commuteId' }}
    >
      <Layout className="onboarding-employee-layout">
        <Layout>
          <Sider width={386}>
            <Loader>
              <OnboardingEmployeeLayoutSidebar />
            </Loader>
          </Sider>
          <Layout>
            <Content>
              {loggedInAs && (
                <Alert
                  className="mb-50"
                  afterClose={handleLogOut}
                  closeText={
                    <Title
                      color={COLOURS_YELLOW.YELLOW_800}
                      bottomSpacing={0}
                      size="rg"
                    >
                      Log out
                    </Title>
                  }
                  message={
                    <Title
                      color={COLOURS_YELLOW.YELLOW_800}
                      bottomSpacing={0}
                      size="rg"
                    >
                      {`Acting as ${getFullName(loggedInAsUser)}`}
                    </Title>
                  }
                  type="warning"
                  isVisible
                  icon={<Icon name="ExclamationTriangle" />}
                />
              )}
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </LayoutContextProvider>
  );
}
