import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { UserStatus } from '../../../constants';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { EmployeeService, NotificationService } from '../../../services';
import { Button, DatePicker, Select } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import {
  dueDateFormValidation,
  ownerFormValidation
} from './actionItemFormValidation';

function ActionItemForm({ notificationAction, formKey, onCancel, onSuccess }) {
  const { me } = useMeContext();
  const [formValues] = useState({
    ...notificationAction,
    id: notificationAction?.id,
    ownerId: notificationAction?.ownerId,
    dueDate: notificationAction?.dueDate
      ? moment.utc(notificationAction.dueDate).format('YYYY-MM-DD')
      : undefined
  });

  const handleUpdateNotification = async (values) => {
    await NotificationService.updateNotificationAction({
      ...values,
      id: notificationAction?.id
    });

    onSuccess?.();
  };

  const { isLoading: isSubmittingForm, execute: handleSubmit } = useAsync({
    asyncFunction: handleUpdateNotification,
    immediate: false
  });

  const handleLoadEmployeeDropdown = useCallback(async () => {
    if (formKey === 'owner') {
      const response = await EmployeeService.fetchEmployees({
        companySlug: me?.company?.slug,
        status: UserStatus.ACTIVE
      });
      return response.list?.map((employee) => ({
        label: employee?.user?.getFullName(),
        value: employee?.id
      }));
    }
    return [];
  }, [formKey, me?.company?.slug]);

  const { isLoading: isLoadingEmployeeDropdown, value: employeeOptions } =
    useAsync({
      asyncFunction: handleLoadEmployeeDropdown
    });

  return (
    <div className="action-item-form">
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={
          formKey === 'owner' ? ownerFormValidation : dueDateFormValidation
        }
      >
        {({ values, _errors, isValid, dirty, setFieldValue }) => (
          <Form className="mt-5">
            <Row gutter={[20, 0]}>
              <Col span={15}>
                {formKey === 'owner' ? (
                  <Select
                    loading={isLoadingEmployeeDropdown}
                    title="Select owner"
                    name="ownerId"
                    value={values?.ownerId}
                    options={employeeOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Select Owner"
                  />
                ) : (
                  <DatePicker
                    name="dueDate"
                    defaultValue={values?.dueDate}
                    title="Due date"
                    setFieldValue={setFieldValue}
                  />
                )}
              </Col>
            </Row>
            <Row gutter={[20, 20]} className="mt-4" justify="end">
              <Col className="text-right">
                <Button type={BUTTON_TYPES.SECONDARY} onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="ml-2"
                  htmlType="submit"
                  disabled={!dirty || !isValid || isSubmittingForm}
                  loading={isSubmittingForm}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ActionItemForm;
