import { Col, Row } from 'antd';
import gusEnvelope from '../../../../stories/assets/svgs/Gus/gusEnvelope.svg';
import { Button, Img, Paragraph, Title } from '../../../../stories/atoms';

export function InviteEmailPreviewSuccess({ onClose }) {
  return (
    <Row gutter={[0, 48]}>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Img
              width="85px"
              src={gusEnvelope}
              alt="An illustration of a dog wearing a party hat"
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="text-center">
        <Title size="md">Your invites are on their way!</Title>
        <Paragraph>
          We’re excited to get your team onboarded to the platform! You can view
          your team's details at any point in the My Company section.
        </Paragraph>

        <Row justify="center">
          <Col>
            <Button onClick={onClose}>Close</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
