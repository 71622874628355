import * as Yup from 'yup';

export const CarbonhoundConnectProviderCredentialsValidation =
  Yup.object().shape({
    utilityProviderId: Yup.string()
      .nullable()
      .required('Provider name required'),
    username: Yup.string().required('Username required'),
    password: Yup.string().required('Password required')
  });
