import { Col, Row } from 'antd';
import React from 'react';
import { Title } from '../../stories/atoms';
import './dataWidget.scss';

function DataWidget({ title, emoji, icon, value, actions, justify }) {
  return (
    <Row
      gutter={[16, 16]}
      className="data-widget"
      align="middle"
      justify={justify}
    >
      {emoji && (
        <Col className="data-widget__icon">
          <div className="data-widget__icon__inner">{emoji}</div>
        </Col>
      )}
      {icon && <Col className="data-widget__icon">{icon}</Col>}
      <Col flex="1 1 auto" className="data-widget__content">
        <div className="data-widget__label">
          <Title className="display-flex" bottomSpacing={8} size="sm">
            {title}
          </Title>
        </div>
        <div className="data-widget__value">
          <Title bottomSpacing={0} size="md">
            {value}
          </Title>
        </div>
      </Col>
      {actions && (
        <Col className="data-widget__actions justify-end">{actions}</Col>
      )}
    </Row>
  );
}

export default DataWidget;
