import { EMISSION_SCOPES } from '../emissionScopes';

export const SCOPE_OPTIONS = [
  {
    label: 'Scope 1 - Direct Emissions',
    value: EMISSION_SCOPES.ONE
  },
  {
    label: 'Scope 2 - Indirect (Grid energy)',
    value: EMISSION_SCOPES.TWO
  },
  {
    label: 'Scope 3 - Indirect Emissions',
    value: EMISSION_SCOPES.THREE
  }
];
