import { Col, Row } from 'antd';
import { COLOURS_GREEN } from '../../../../../constants/colours';
import { Button, Icon, Paragraph, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';

export function StepThree({ onSuccess }) {
  return (
    <Row
      gutter={[24, 24]}
      justify="center"
      align="middle"
      className="flex-direction-column"
    >
      <Col>
        <Icon
          size={24}
          name="Check"
          bgColor={COLOURS_GREEN.GREEN_100}
          color={COLOURS_GREEN.GREEN_800}
        />
      </Col>
      <Col>
        <Title bottomSpacing={8} align="center">
          Intensity Metric Created
        </Title>
        <Paragraph bottomSpacing={0} align="center">
          Intensity metrics allow you to centralize all your business about info
          in one place.
        </Paragraph>
      </Col>
      <Col>
        <Button type={BUTTON_TYPES.TEXT} onClick={onSuccess}>
          Close
        </Button>
      </Col>
    </Row>
  );
}
