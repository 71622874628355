import classNames from 'classnames';
import './StepContainer.scss';

export function StepContainer({
  children,
  title,
  description,
  icon,
  activeStep
}) {
  if (activeStep > 0) {
    return (
      <div
        className={classNames('bulk-upload-form-step-container', {
          'bulk-upload-form-step-container--initial-step': activeStep === 0,
          complete: title === 'Data upload complete!'
        })}
      >
        {icon && (
          <span className="bulk-upload-form-step-container__icon mb-8">
            {icon}
          </span>
        )}
        <h4
          className={classNames('text-bd', {
            'text-center': activeStep > 0,
            'initial-step__title': activeStep === 0
          })}
        >
          {title}
        </h4>
        <p
          className={classNames('', {
            'text-center mt-2 mb-6': activeStep > 0,
            'mb-6 initial-step__description': activeStep === 0
          })}
        >
          {description}
        </p>
        <div className="bulk-upload-form-container__content">{children}</div>
      </div>
    );
  }
  return <div className="bulk-upload-form-container__content">{children}</div>;
}
