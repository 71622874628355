const PatchSampleProjects = {
  biochar: {
    id: 'pro_prod_2e049bbfe8c998ca82025d776f8289ff',
    average_price_per_tonne_cents_usd: 24700,
    country: 'FI',
    description:
      "Carbo Culture takes biomass, or carbon that's been drawn down by plants from the atmosphere, and converts it into an ultra-stable material called biochar. What sets the company apart from other biochar firms is its re-engineering of biochar technology to scale.\n\nCarbo Culture puts special focus on the permanence of the biochar (how long the carbon will be stored on earth), as well as the scalability of the machinery (how easy is it to build thousands of machines). The firm believes that this quality-first focus is the best way to effectively fight climate change.\n\nThe woman-led, Finnish-Californian company is currently scaling this biochar technology at their R&amp;D site in California, with an eye on their next big goal: building one of Europe’s largest carbon drawdown facilities, located in Finland. You can pre-purchase carbon credits from this upcoming facility today.\n\nCarbo Culture’s credits have been verified by puro.earth.",
    developer: 'Carbo Culture',
    highlights: [
      {
        slug: 'sup_operational_3_5_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/sup_operational_3_5_years-135616cc7bb5b04f10835b2f76e21616.svg',
        title: 'Supplier operational 3-5 years'
      },
      {
        slug: 'pioneers_new_tech',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/pioneers_new_tech-7fb0fbc0983332de8ad7c3a4b2543a6c.svg',
        title: 'Pioneers new technology'
      },
      {
        slug: 'proj_operational_3_5_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/proj_operational_3_5_years-135616cc7bb5b04f10835b2f76e21616.svg',
        title: 'Project operational 3-5 years'
      },
      {
        slug: 'female_or_minority_led_supplier',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/female_or_minority_led_supplier-a4556c4b5af37fde53766145bb8e08fe.svg',
        title: 'Female or minority-led supplier'
      }
    ],
    inventory: [
      {
        amount_available: 34355446,
        currency: 'USD',
        price: 24700,
        unit: 'g',
        vintage_year: 2024
      }
    ],
    latitude: 60.192059,
    longitude: 24.945831,
    mechanism: 'removal',
    name: 'Carbo Culture Biochar',
    photos: [
      {
        id: 'pho_prod_94a3b5458ca303dc365b00969d4b59e3',
        url: 'https://storage.googleapis.com/carbonhound-prod-public/integration/patch/projects/biochar_project.jpeg'
      }
    ],
    production: true,
    remaining_mass_g: 34355446,
    sdgs: [
      {
        description: 'End poverty in all its forms everywhere',
        number: 1,
        title: 'No Poverty',
        url: 'https://sdgs.un.org/goals/goal1'
      },
      {
        description:
          'End hunger, achieve food security and improved nutrition and promote sustainable agriculture',
        number: 2,
        title: 'Zero Hunger',
        url: 'https://sdgs.un.org/goals/goal2'
      },
      {
        description:
          'Ensure healthy lives and promote well-being for all at all ages',
        number: 3,
        title: 'Good Health and Well-Being',
        url: 'https://sdgs.un.org/goals/goal3'
      },
      {
        description:
          'Ensure availability and sustainable management of water and sanitation for all',
        number: 6,
        title: 'Clean Water and Sanitation',
        url: 'https://sdgs.un.org/goals/goal6'
      },
      {
        description:
          'Ensure access to affordable, reliable, sustainable and modern energy for all',
        number: 7,
        title: 'Affordable and Clean Energy',
        url: 'https://sdgs.un.org/goals/goal7'
      },
      {
        description:
          'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all',
        number: 8,
        title: 'Decent Work an Economic Growth',
        url: 'https://sdgs.un.org/goals/goal8'
      },
      {
        description:
          'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
        number: 9,
        title: 'Industry, Innovation and Infrastructure',
        url: 'https://sdgs.un.org/goals/goal9'
      },
      {
        description: 'Reduce inequality within and among countries',
        number: 10,
        title: 'Reduce Inequality',
        url: 'https://sdgs.un.org/goals/goal10'
      },
      {
        description:
          'Make cities and human settlements inclusive, safe, resilient and sustainable',
        number: 11,
        title: 'Sustainable Cities and Communities',
        url: 'https://sdgs.un.org/goals/goal11'
      },
      {
        description: 'Ensure sustainable consumption and production patterns',
        number: 12,
        title: 'Responsible Consumption and Production',
        url: 'https://sdgs.un.org/goals/goal12'
      },
      {
        description:
          'Take urgent action to combat climate change and its impacts',
        number: 13,
        title: 'Climate Action',
        url: 'https://sdgs.un.org/goals/goal13'
      },
      {
        description:
          'Conserve and sustainably use the oceans, seas and marine resources for sustainable development',
        number: 14,
        title: 'Life Below Water',
        url: 'https://sdgs.un.org/goals/goal14'
      },
      {
        description:
          'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
        number: 15,
        title: 'Life on Land',
        url: 'https://sdgs.un.org/goals/goal15'
      }
    ],
    standard: {
      acronym: 'puro',
      description:
        'A marketplace, standard and registry focused primarily on biochar and building projects.',
      type: 'puro_earth'
    },
    state: '08',
    tagline:
      'Converting forestry and food production residues into biochar, locking the carbon contained in organic matter into a stable, solid form for thousands of years.',
    technology_type: {
      slug: 'biochar',
      name: 'Biochar',
      parent_technology_type: {
        slug: 'biomass',
        name: 'Biomass'
      }
    },
    type: 'biomass',
    verifier: 'EnergyLink Services Pty Ltd'
  },
  concreteCure: {
    id: 'pro_prod_5b4556d99fc4f2be1d637757e4893e7d',
    average_price_per_tonne_cents_usd: 23500,
    country: 'CA',
    description:
      "CarbonCure's carbon dioxide removal (CDR) technology offers permanent, verifiable, and scalable carbon reductions for the concrete industry. The retrofit technology is installed in hundreds of concrete plants globally, and the company is continuing to launch new innovative products and technologies. CarbonCure's technology won the grand prize in the $20 million NRG COSIA Carbon XPRIZE competition, selected as the most scalable breakthrough technology to convert CO2 emissions into usable products. CarbonCure was also recognized as Cleantech Group's North American Company of the Year in 2020.",
    developer: 'CarbonCure Technologies Inc.',
    highlights: [
      {
        slug: 'sup_operational_10_plus_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/sup_operational_10_plus_years-3721f3ab7104236654927027f972cd02.svg',
        title: 'Supplier operational 10+ years'
      },
      {
        slug: 'xprize_carbon_removal_finalist',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/xprize_carbon_removal_finalist-a9df3da7b4eb12b8089cdf3691341f7d.svg',
        title: 'XPRIZE Carbon Removal finalist'
      },
      {
        slug: 'pioneers_new_tech',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/pioneers_new_tech-7fb0fbc0983332de8ad7c3a4b2543a6c.svg',
        title: 'Pioneers new technology'
      },
      {
        slug: 'operates_in_mult_contries',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/operates_in_mult_contries-41cde910e2943e8876b914db7adc5400.svg',
        title: 'Operates in multiple countries'
      },
      {
        slug: 'proj_operational_10_plus_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/proj_operational_10_plus_years-3692e136fdf405a8ed0902b26b1477e2.svg',
        title: 'Project operational 10+ years'
      }
    ],
    inventory: [
      {
        amount_available: 596602054,
        currency: 'USD',
        price: 23500,
        unit: 'g',
        vintage_year: 2019
      }
    ],
    latitude: 45.0,
    longitude: -64.0,
    mechanism: 'removal',
    name: 'CarbonCure Concrete Mineralization',
    photos: [
      {
        id: 'pho_prod_ddd8a7ef4fcea51d81c817687e24ac5a',
        url: 'https://storage.googleapis.com/carbonhound-prod-public/integration/patch/projects/mineralization_project.jpeg'
      }
    ],
    production: true,
    remaining_mass_g: 596602054,
    sdgs: [
      {
        description:
          'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
        number: 9,
        title: 'Industry, Innovation and Infrastructure',
        url: 'https://sdgs.un.org/goals/goal9'
      },
      {
        description:
          'Make cities and human settlements inclusive, safe, resilient and sustainable',
        number: 11,
        title: 'Sustainable Cities and Communities',
        url: 'https://sdgs.un.org/goals/goal11'
      },
      {
        description:
          'Take urgent action to combat climate change and its impacts',
        number: 13,
        title: 'Climate Action',
        url: 'https://sdgs.un.org/goals/goal13'
      }
    ],
    standard: null,
    state: 'NS',
    tagline:
      'Injecting CO₂ into cement to make super-strong low-carbon concrete.',
    technology_type: {
      slug: 'concrete_injection',
      name: 'Concrete Injection',
      parent_technology_type: {
        slug: 'mineralization',
        name: 'Mineralization'
      }
    },
    type: 'mineralization',
    verifier: 'N/A'
  },
  kelpFarming: {
    id: 'pro_prod_8ef8c4473803046247818d35f9f8294b',
    average_price_per_tonne_cents_usd: 25000,
    country: 'US',
    description:
      "Running Tide is harnessing the power of the ocean to build a climate positive future. Oceans represent two-thirds of the Earth’s surface and have an understated role to play in the future of our life on Earth. Coastlines that were once abundant ecosystems are now closer to empty wastelands facing ecologic and economic collapse. At Running Tide, we have experienced this firsthand, and we know the problem won't solve itself. That’s why we are building technologies to accelerate and scale the naturally restorative benefits of the ocean. Kelp removes CO2 from the ocean as it grows (20x faster than trees!). We are building the most efficient carbon removal system in the world by scaling this natural process. Our solution relies on photosynthesis, ocean currents and gravity to remove and store carbon in the deep ocean. Our system offers permanent, scalable carbon removal at low cost and without high land use.",
    developer: 'Running Tide',
    highlights: [
      {
        slug: 'sup_operational_3_5_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/sup_operational_3_5_years-135616cc7bb5b04f10835b2f76e21616.svg',
        title: 'Supplier operational 3-5 years'
      },
      {
        slug: 'pioneers_new_tech',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/pioneers_new_tech-7fb0fbc0983332de8ad7c3a4b2543a6c.svg',
        title: 'Pioneers new technology'
      },
      {
        slug: 'proj_operational_1_3_years',
        icon_url:
          '//cdn.patch.io/packs/media/images/highlights/proj_operational_1_3_years-2790cb8b7ef576e4d54d46f9a4cfca89.svg',
        title: 'Project operational 1-3 years'
      }
    ],
    inventory: [
      {
        amount_available: 864658123,
        currency: 'USD',
        price: 25000,
        unit: 'g',
        vintage_year: 2022
      },
      {
        amount_available: 15828719,
        currency: 'USD',
        price: 25000,
        unit: 'g',
        vintage_year: 2025
      }
    ],
    latitude: 43.6591,
    longitude: -70.2568,
    mechanism: 'removal',
    name: 'Running Tide Kelp Sequestration',
    photos: [
      {
        id: 'pho_prod_0cd03004ee9c0a1f7bfef77594e48f5c',
        url: 'https://storage.googleapis.com/carbonhound-prod-public/integration/patch/projects/kelp_project.jpeg'
      }
    ],
    production: true,
    remaining_mass_g: 880486842,
    sdgs: [
      {
        description:
          'Take urgent action to combat climate change and its impacts',
        number: 13,
        title: 'Climate Action',
        url: 'https://sdgs.un.org/goals/goal13'
      },
      {
        description:
          'Conserve and sustainably use the oceans, seas and marine resources for sustainable development',
        number: 14,
        title: 'Life Below Water',
        url: 'https://sdgs.un.org/goals/goal14'
      }
    ],
    state: 'ME',
    tagline:
      'Growing and sinking kelp forests, which can store up to 20 times more carbon per acre than land forests.',
    technology_type: {
      slug: 'kelp_farming',
      name: 'Kelp Farming',
      parent_technology_type: {
        slug: 'ocean',
        name: 'Ocean'
      }
    },
    type: 'ocean',
    verifier: 'N/A'
  }
};

export default PatchSampleProjects;
