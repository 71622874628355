import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import {
  EmissionSourceItemOrigin,
  EmissionSourceItemOriginCopy
} from '../../../constants';
import { useBulkUploadContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { preventOnEnter } from '../../../helpers/eventListenerHelper';
import {
  capitalizeText,
  getCollectionBusinessUnit,
  getCollectionLocation,
  getDataImportFormatting,
  getFormattedAddress
} from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { EmissionSourceService, NotificationService } from '../../../services';
import notificationService from '../../../services/notificationService';
import { ColumnTitle, Input, Table, Tag, Title } from '../../../stories/atoms';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../stories/atoms/Tag/tag.types';
import { Footer } from '../Footer';
import './importType.scss';

function ImportType({ contentTitle, contentDescription }) {
  const { me } = useMeContext();
  const { values, setValues } = useFormikContext();
  const {
    nextStep,
    updateNotification,
    bulkUploadState,
    updateNotificationReqParams
  } = useBulkUploadContext();
  const { notification, companySlug } = bulkUploadState;
  const { id, details } = notification;
  const [currentRow, setCurrentRow] = useState({});
  const [sourceImportList, setSourceImportList] = useState([]);
  const handleFetchEmissionSourceImportList = useCallback(async () => {
    const response = await EmissionSourceService.fetchEmissionSourceImportList({
      companySlug,
      ...(details?.origin && { origin: details.origin })
    });
    setSourceImportList(response.sources);
    return response.sources;
  }, [companySlug]);

  const { value: initialSourceImportList } = useAsync({
    asyncFunction: handleFetchEmissionSourceImportList
  });

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: <ColumnTitle>Name</ColumnTitle>,
      render: (_, item) => (
        <Title bottomSpacing={0}>{capitalizeText(item.name)}</Title>
      ),
      width: '25%'
    },
    {
      key: 'category',
      dataIndex: 'category',
      title: <ColumnTitle>Category</ColumnTitle>,
      render: (_, item) => (
        <Tag
          color={INTERNAL_TAG_COLOURS[item.tagName] ?? TAG_COLOURS.NIGHT_BLUE}
        >
          {item.tagName ?? 'Other'}
        </Tag>
      ),
      width: '10%'
    },
    {
      key: 'location',
      dataIndex: 'location',
      title: <ColumnTitle>Location</ColumnTitle>,
      render: (_, item) => {
        const collection = getCollectionLocation(item);
        const location = collection?.location;
        const name = collection?.name;
        return getFormattedAddress({
          location,
          name,
          defaultReturnString: '--'
        });
      },
      width: '30%'
    },
    {
      key: 'unit',
      dataIndex: 'unit',
      title: <ColumnTitle>Business Unit</ColumnTitle>,
      render: (_, item) => {
        const collection = getCollectionBusinessUnit(item);
        return collection?.name ?? '--';
      },
      width: '25%'
    }
  ];

  const handleCreateNotification = async () => {
    const method = id
      ? notificationService.updateNotification
      : NotificationService.createNotification;

    const response = await method(
      {
        ...values,
        notificationId: id,
        ...(details?.origin && { origin: details.origin }),
        ...(details?.origin ===
          EmissionSourceItemOrigin.CARBONHOUND_CONNECT && {
          title: `${
            EmissionSourceItemOriginCopy[
              EmissionSourceItemOrigin.CARBONHOUND_CONNECT
            ]
          }: ${values.title}`
        })
      },
      (notification) => {
        updateNotification?.({
          notification
        });
      }
    );
    if (!!response) {
      nextStep();
    }
  };

  const { isLoading: isNotificationBeingCreated, execute: createNotification } =
    useAsync({
      asyncFunction: handleCreateNotification,
      immediate: false
    });

  const handleFetchSourceDetails = useCallback(async (emissionSourceId) => {
    let esDetails = {};

    esDetails = await EmissionSourceService.getEmissionSourceDetails(
      emissionSourceId
    );

    const notificationReqParams = {
      unitFamilyName: esDetails?.emissionFactor?.unitFamilyName
    };
    updateNotificationReqParams({ notificationReqParams });
  }, []);

  const { execute: fetchSourceDetails } = useAsync({
    asyncFunction: handleFetchSourceDetails,
    immediate: false
  });
  const handleNext = async () => {
    if (currentRow.id) await fetchSourceDetails(currentRow.id);
    await createNotification();
  };

  const isStepInvalid = typeof values?.uploadType === 'undefined';

  const handleSourceSearch = (e) => {
    setSourceImportList(
      initialSourceImportList.filter((source) => {
        const { name, tagName } = source;
        const locationAddress =
          getCollectionLocation(source)?.location?.address1;
        const businessUnitName = getCollectionBusinessUnit(source)?.name;
        const searchFields = [name, tagName, locationAddress, businessUnitName];
        return (
          searchFields.findIndex(
            (field) =>
              field &&
              field.toLowerCase().includes(e.target.value.toLowerCase())
          ) > -1
        );
      })
    );
  };

  const handleRowSelected = (record) => {
    const formattedValues = getDataImportFormatting({
      record: {
        ...record,
        category: notification.category
      },
      companySlug
    });
    analytics.track('Step Completed', me, {
      level1: 'Bulk Uploads',
      level2: 'Select Source',
      notification: formattedValues
    });
    setValues({ ...formattedValues });
    setCurrentRow(record);
  };

  return (
    <div className="bulk-upload-form-activity-type import-type__wrapper">
      <Row className="justify-space-between align-center mt-5 mb-2">
        <div className="bulk-upload-form-step-container bulk-upload-form-step-container--initial-step">
          <h4 className="text-bd initial-step__title">{contentTitle}</h4>
          <p className="mb-6 initial-step__description">{contentDescription}</p>
        </div>
        <div className="search-filter__container">
          <Input
            type="text"
            name="Search"
            placeholder="Search by source, category, or location "
            onKeyPress={preventOnEnter}
            onChange={handleSourceSearch}
            isFormikField={false}
          />
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <div className="import-table__container">
            <Table
              scroll={{ y: '250px' }}
              sticky
              columns={columns}
              rowClassName={(record) => {
                const isSelected =
                  Object.keys(currentRow).length > 0 &&
                  record.id === currentRow.id &&
                  record.uploadType === currentRow.uploadType;
                if (!!isSelected) {
                  return 'row-selected';
                }
                return 'ch-table__row ch-table__row--other';
              }}
              onRow={(record) => ({
                onClick: () => handleRowSelected(record)
              })}
              isAlternatingRowBackgroundDisabled
              dataSource={sourceImportList}
            />
          </div>
        </Col>
      </Row>
      <Footer
        Footer
        analyticsStepContext="Select Source"
        isLoading={isNotificationBeingCreated}
        onNextFn={handleNext}
        isStepInvalid={isStepInvalid && !isNotificationBeingCreated}
        importTypeNotification={values}
      />
    </div>
  );
}

export default ImportType;
