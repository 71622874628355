import { useModal } from '../../../hooks';
import { Button } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import { TravelItemForm } from '../TravelItemForm';

export function TravelItemFormButton({
  onSuccess,
  _analyticsContext,
  buttonProps = {},
  modalProps = {},
  travelItem = {},
  onClose,
  onOpen,
  ...props
}) {
  const { id } = travelItem;
  const defaultButtonChildren = id ? 'Edit Travel Item' : 'Add Travel Item';
  const defaultModalTitle = id ? 'Edit Travel Item' : 'Add Travel Item';
  const {
    type: buttonType = BUTTON_TYPES.SECONDARY,
    children: buttonChildren = defaultButtonChildren
  } = buttonProps;
  const { title: modalTitle = defaultModalTitle } = modalProps;
  const { Modal, handleShowModal, handleCloseModal } = useModal({
    width: '90%',
    onCloseModal: () => onClose?.(travelItem),
    onShowModal: () => onOpen?.(travelItem)
  });

  const handleTravelItemFormSuccess = () => {
    onSuccess?.();
    handleCloseModal();
  };
  return (
    <>
      <Button {...buttonProps} type={buttonType} onClick={handleShowModal}>
        {buttonChildren}
      </Button>

      <Modal title={modalTitle} className="travel-item-form-modal">
        <TravelItemForm
          onSuccess={handleTravelItemFormSuccess}
          activeItem={travelItem}
          travelItem={travelItem}
          {...props}
        />
      </Modal>
    </>
  );
}
