const InitiativeImplementationPartners = [
  {
    locations: ['Regional - Ontario'],
    technicalPartnerFilters: ['CANADA-ENIVRO_STEWARDS-ENERGY_EFFICIENCY'],
    url: 'https://www.enviro-stewards.com/solutions/energy-efficiency/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/EnviroStewards.png',
    title: 'Enviro-Stewards: Energy Efficiency',
    description: `
      Enviro-Stewards’ approach is unlike that of most consultants. Instead of performing a traditional audit with a specific focus on solving your end state dilemma, they examine the root cause in order to develop innovative and sustainable solutions.

      Enviro-Stewards takes a holistic approach and conducts a full-spectrum assessment and implements solutions focused on prevention, resource conservation, and improving your bottom line.
    `
  },
  {
    locations: ['Regional - Ontario'],
    technicalPartnerFilters: [
      'CANADA-ENIVRO_STEWARDS-FOOD_LOSS_WASTE_PREVENTION'
    ],
    url: 'https://www.enviro-stewards.com/solutions/waste-reduction/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/EnviroStewards.png',
    title: 'Enviro-Stewards: Food Loss & Waste Prevention',
    description: `
      Enviro-Stewards’ approach is unlike that of most consultants. Instead of performing a traditional solid waste audit with a specific focus on finding the best location for your dumpster contents, we examine the root causes leading to waste in order to develop innovative and sustainable solutions.

      In Canada, the equivalent of 30 – 40% of the food produced is lost along the value chain. This food waste is worth an estimated $27 billion each year. Traditional solid waste audits find homes for this food waste at margin value of plus $20 to minus $100 per tonne. However, this approach fails to recognize that, just before it was lost during processing, the food was valued at plus $1,000 – $15,000 per tonne.
      
      Rather than focusing on ways to manage your food waste by finding efficient ways to destroy the food, we will develop solutions to reduce or eliminate wasted food in the first place.
      
      Enviro-Stewards takes a holistic approach and conducts a full-spectrum assessment and implementation focused on prevention, resource conservation, and improving your bottom line.
      
      Our unique approach to reduce food waste during the manufacturing process will ensure that more ingredients and finished goods leave the line as saleable product and not through the dumpster.
     `
  },
  {
    locations: ['Regional - Ontario'],
    technicalPartnerFilters: ['CANADA-ENIVRO_STEWARDS-SUSTAINABILITY'],
    url: 'https://www.enviro-stewards.com/solutions/sustainability/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/EnviroStewards.png',
    title: 'Enviro-Stewards: Sustainability',
    description: `
      Enviro-Stewards is reframing the conversation about sustainability and business. Being sustainable is about prevention and conservation. Taking the steps to implement feasible processes to increase your operational sustainability will generate improved revenues and achieve long-term environmental benefits.

      Achieving sustainability will enhance your organization now — and in the future.  
    `
  },
  {
    locations: ['Local - Waterloo, ON'],
    technicalPartnerFilters: [
      'CANADA-SUSTAINABLE_WATERLOO_REGION-IMPACT_NETWORK'
    ],
    url: 'https://www.sustainablewaterlooregion.ca/programs/impact-network/supports/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/SWR-logo-2021-1024x225.png',
    title: 'Sustainable Waterloo Region: Impact Network',
    description: `
      When your company becomes a member of the Impact Network, you not only join a growing group of fierce climate change warriors, you also become our partner. And like all good partners, they're in your corner and here to help you succeed! 

      Sustainable Waterloo Region (SWR) offers a variety of support services customized to each member company’s unique needs:
      
      Coaching to help with measurement and reporting, employee engagement and Green Team support
      
      Member Specific Reports showcasing sustainability goal progress
      
      Access to learning opportunities, resources and events, and much more!
      
      SWR wants your sustainability goals and business goals to be in sync, and they're committed to making sure your experience is a success on all fronts.
    `
  },
  {
    locations: ['Local - Waterloo, ON'],
    technicalPartnerFilters: ['CANADA-SUSTAINABLE_WATERLOO_REGION-TRAVELWISE'],
    url: 'https://www.sustainablewaterlooregion.ca/programs/travelwise/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/SWR-logo-2021-1024x225.png',
    title: 'Sustainable Waterloo Region: TravelWise',
    description: `
      TravelWise is a Transportation Management Association (TMA) dedicated to providing tools and services to employers in Waterloo Region to reduce the number of people who drive to work alone. These include providing strategies to encourage employees to carpool, take public transportation, cycle, or walk to the office. 

      This nonprofit, member-based program is delivered through a partnership between the Region of Waterloo and Sustainable Waterloo Region, with the ultimate goal of reducing the number of cars on the road and decreasing greenhouse gas emissions. Benefits available to TravelWise members include corporate transit passes, access to employee engagement events and challenges, and reporting assistance, to name a few.
    `
  },
  {
    locations: ['Local - Toronto, ON'],
    technicalPartnerFilters: ['CANADA-BIKE_SHARE_TORONTO'],
    url: 'https://bikesharetoronto.com/corporate/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Bike_Share_Toronto_logo.png',
    title: 'Bike Share Toronto',
    description: `
      Bike Share Toronto offers 20% off for employees by participating in Bike Share Toronto's Corporate Membership Program to encourage subsidized use of Bike Share Toronto's services across the city!

      Participating organizations in the Corporate Membership Program must have a minimum of 10 employees. 
    `
  },
  {
    locations: ['Local - London, ON'],
    technicalPartnerFilters: ['CANADA_VILLAGE_COMPOST'],
    url: 'https://villagecompost.com/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/village-compost-london-ontario.png',
    title: 'Village Compost',
    description: `
      Village Compost provides organics collection service for businesses, institutions and residential clients in the region of London Ontario. 
      Consultations are free of charge and there is no obligation. Schedule yours today or simply phone us with your questions.
    `
  },
  {
    locations: ['National - Canada', 'National - USA'],
    technicalPartnerFilters: ['NORTH_AMERICA-LEVITON'],
    url: 'https://www.leviton.com/en',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Leviton.png',
    title: 'Leviton',
    description: `
      From electrical, to lighting, to data networks, and energy management, Leviton develops thoughtful solutions that help make its customers' lives easier, safer, more efficient and more productive.

      Every day, Leviton is engineering possibilities that make innovation happen, meeting the needs of today’s residential, commercial, and industrial customers globally. 
    `
  },
  {
    locations: ['National - Canada', 'National - USA'],
    technicalPartnerFilters: ['NORTH_AMERICA-GRAINGER'],
    url: 'https://www.grainger.ca/en',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Grainger-Canada-Logo.png',
    title: 'Grainger',
    description: `
      Grainger is Canada’s largest distributor of maintenance, repair and operational supplies with a massive inventory for every aspect of your business. They have the largest selection of in-stock brand-name products from the world’s top manufacturers and the largest exclusive private-label offering in the industry.
    `
  },
  {
    locations: ['Regional - Ontario'],
    technicalPartnerFilters: ['CANADA-SAVE_ENERGY-SMALL_BUSINESS_PROGRAM'],
    url: 'https://saveonenergy.ca/en/For-Your-Small-Business/Programs-and-Incentives/Small-Business-Program',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Save-On-Energy-Logo.png',
    title: 'Save on Energy Audits (Small Business Program)',
    description: `
      The Small Business Energy Audits Program is a simple and easy solution to improve the energy efficiency of your business. Here’s how it works:

      Complete the form to schedule a free on-site assessment to identify energy-efficiency opportunities for your business, including lighting, refrigeration and smart thermostat upgrades.
      
      After the assessment, you will be presented with options for energy-efficient upgrades that can be installed in your business, free of charge. If you choose to proceed, you will be sent a participant agreement and work order for your review and signature.
      
      Qualified Save on Energy delivery partners will handle the entire installation process on your behalf – everything from searching for a qualified contractor to obtaining quotes and scheduling installations, and even project management of the retrofit and clean-up.
    `
  },
  {
    locations: ['National - Canada', 'National - USA'],
    technicalPartnerFilters: ['NORTH_AMERICA-GFL_ENVIRONMENTAL'],
    url: 'https://gflenv.com/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/GFL-Logo.png',
    title: 'GFL Environmental',
    description: `
      GFL Environmental is the only major diversified environmental services company in North America offering services in solid waste management, liquid waste management, and soil remediation.

      GFL is uniquely equipped to undertake practically any environmental challenge, with unparalleled commitment to safety and customer service.  
      
      Recognized by our signature fleet of bright green trucks and equipment, we offer a wide range of environmental and industrial services to businesses, communities and households, providing a consolidated and sophisticated approach to meeting our customers’ needs. 
    `
  },
  {
    locations: ['Local - Toronto, ON'],
    technicalPartnerFilters: [
      'CANADA-CITY_OF_TORONTO_BETTER_BUILDINGS_NAVIGATION_SUPPORT_SERVICES'
    ],
    url: 'https://www.toronto.ca/services-payments/water-environment/net-zero-homes-buildings/better-buildings-partnership/better-buildings-navigation-support-services/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/City-Of-Toronto-Logo.png',
    title: 'Better Buildings Navigation & Support Services',
    description: `
      Better Buildings Navigation & Support Services provides building owners, operators, and property managers support navigating the process of improving the energy efficiency of their buildings and reducing greenhouse gas emissions. These improvements help decrease operating costs, increase occupant comfort, and improve the overall value of buildings.

      Better Buildings Navigation & Support Services can help you to:
      
      - Identify potential energy efficiency opportunities in your building
      - Access available incentives and financing for identified projects
      - Overcome hurdles you may be facing with project implementation
      - Work with you one-on-one, offering tailored support every step of the way
      
      Complete the Navigation & Support Services Application PDF to the best of your ability and email it to bbp@toronto.ca. City staff will contact you to better understand your needs and inform you of next steps.
      
      For assistance with completing the form or to learn more about how Navigation Services can help you, please contact us by email at bbp@toronto.ca or by phone at 416-392-1500.
    `
  },
  {
    locations: ['National - Canada', 'National - USA'],
    technicalPartnerFilters: ['NORTH_AMERICA-DIGI_KEY_ELECTRONICS'],
    url: 'https://www.digikey.ca/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Digi-Key-Logo.png',
    title: 'Digi-Key Electronics',
    description: `
      Digi-Key Electronics is recognized as both the leader and continuous innovator in the high service distribution of electronic components and automation products worldwide. 

      Digi-Key offers a large selection of electronic components in stock and available for immediate shipment. Beyond the products that drive technology innovation, Digi-Key also supports design engineers and procurement professionals with a wealth of digital solutions and tools to make their jobs more efficient.  
    `
  },
  {
    locations: ['National - Canada', 'National - USA'],
    technicalPartnerFilters: ['NORTH_AMERICA-HONEYWELL'],
    url: 'https://www.honeywell.com/us/en/industries/products',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Honeywell-Logo.png',
    title: 'Honeywell',
    description: `
      Honeywell is a premier software-industrial, providing software-enabled technologies that help create a smarter, safer and more sustainable world. Using their comprehensive range of controllers, field devices and software, they can help you customize a system to fit your building and your future.
    `
  },
  {
    locations: ['National - USA'],
    technicalPartnerFilters: ['USA-EVERNEX'],
    url: 'https://www.evernex.com/recycling/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Evernex-Logo.png',
    title: 'Evernex',
    description: `
      Evernex hardware recycling provides an end-to-end recycling service certified by the EU’s Waste Electrical and Electronic Equipment Directive (WEEE) to generate value and action a circular IT economy by giving repurposed components a second life as refurbished hardware.

      Our hardware recycling forms part of our comprehensive lifecycle management services, providing businesses with direct carbon gains while reducing their hardware spend by up to 70%: good news for CIOs, IT budgets and the planet.
    `
  },
  {
    locations: ['Local - Toronto, ON'],
    technicalPartnerFilters: ['CANADA_WASTE-REDUCTION-GROUP'],
    url: 'https://www.wastereductiongroup.ca/',
    urlText: 'Learn More',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Waste-Reduction-Group-Logo.png',
    title: 'Waste Reduction Group',
    description: `
      Waste Reduction Group is a non traditional waste management company that prides itself on building strong relationships with both customers and suppliers. Their mission is to help clients meet and exceed their recycling and waste reduction goals and objectives.

      They provide services, products, equipment and consulting to conduct waste audits, reduce waste, and improve recycling.
    `
  }
];
export default InitiativeImplementationPartners;
