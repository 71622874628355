import { Col, Row } from 'antd';
import { useState } from 'react';
import { InitiativeImplementationPartners } from '../../../../constants';
import { InitiativeFinancePartnerships } from '../../../../constants/InitiativeFinancePartnerships';
import {
  createLocationOptionList,
  createLocationList
} from '../../../../helpers';
import { Select, Title } from '../../../../stories/atoms';
import { InitiativeContentCard } from '../../../../stories/molecules';

export function InitiativeTemplateFinancing({
  includeCaseStudies = true,
  analyticsContext
}) {
  const [financeLocationFilters, setFinanceLocationFilters] = useState([]);
  const [implementationLocationFilters, setImplementationLocationFilters] =
    useState([]);
  const templateFinancingLocationListOptions = createLocationOptionList(
    InitiativeFinancePartnerships
  );
  const templateFinancingLocationList = createLocationList(
    InitiativeFinancePartnerships,
    financeLocationFilters
  );
  const handleFilterFinancingLocations = (values) => {
    setFinanceLocationFilters(values);
  };
  const templateImplementationLocationListOptions = createLocationOptionList(
    InitiativeImplementationPartners
  );
  const templateImplementationLocationList = createLocationList(
    InitiativeImplementationPartners,
    implementationLocationFilters
  );
  const handleFilterImplementationLocations = (values) => {
    setImplementationLocationFilters(values);
  };
  return (
    <Row gutter={[0, 40]}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              <Col flex="1 1 auto" className="display-flex align-center">
                <Title bottomSpacing={0}>Funding</Title>
              </Col>
              <Col flex="314px">
                <Select
                  mode="multiple"
                  disableForm
                  options={templateFinancingLocationListOptions}
                  placeholder="Filter by location"
                  onChange={handleFilterFinancingLocations}
                />
              </Col>

              <Col span={24}>
                <Row gutter={[24, 24]}>
                  {templateFinancingLocationList.map((card) => (
                    <Col className="display-flex">
                      <InitiativeContentCard
                        card={card}
                        analyticsContext={analyticsContext}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {includeCaseStudies && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row gutter={[0, 24]}>
                <Col flex="1 1 auto" className="display-flex align-center">
                  <Title bottomSpacing={0}>
                    Technical & Implementation Partners
                  </Title>
                </Col>
                <Col flex="314px">
                  <Select
                    mode="multiple"
                    disableForm
                    options={templateImplementationLocationListOptions}
                    placeholder="Filter by location"
                    onChange={handleFilterImplementationLocations}
                  />
                </Col>

                <Col span={24}>
                  <Row gutter={[24, 24]}>
                    {templateImplementationLocationList.map((card) => (
                      <Col className="display-flex">
                        <InitiativeContentCard
                          analyticsContext={analyticsContext}
                          card={card}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
}
