import { Col, Row } from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { INITIATIVE_TYPE } from '../../../../constants/InitiativeType';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { useAsync, usePagination } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import TagService from '../../../../services/tagService';
import MagnifyingGlassCircle from '../../../../stories/assets/svgs/MagnifyingGlassCircle.svg';
import {
  Button,
  Icon,
  Paragraph,
  Segmented,
  Select,
  Title
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import Pagination from '../../../../stories/atoms/Pagination';
import { InitiativeCard } from '../../../../stories/molecules/Initiative';
import './InitiativeTemplateList.scss';

const getSelectedSegmentParams = (selectedSegment, industryId) =>
  !!INITIATIVE_TYPE[selectedSegment]
    ? {
        type: selectedSegment
      }
    : { industryIds: [industryId] };

export function InitiativeTemplateList({ analyticsContext }) {
  const [tagIds, setTagIds] = useState([]);
  const { me, isAdmin } = useMeContext();
  const segmentOptions = [
    {
      label: 'Recommended',
      value: me.company?.industry?.id,
      icon: <Icon name="Fire" />
    },
    {
      label: 'Measurement ',
      value: INITIATIVE_TYPE.MEASUREMENT,
      icon: <Icon name="Libra" />
    },
    {
      label: 'Reduction',
      value: INITIATIVE_TYPE.REDUCTION,
      icon: <Icon name="TrendingDown" />
    }
  ];
  const [selectedSegment, setSelectedSegment] = useState(
    segmentOptions[0].value
  );
  const { handleLoadInitiativeTemplateList, initiativeState } =
    useInitiativeContext();

  const { initiativeTemplateList, totalInitiativeTemplateCount } =
    initiativeState;

  const { paginationParams, handleSetPaginationParams } = usePagination({
    pageSize: 9,
    order: 'ASC',
    paginationArray: initiativeTemplateList,
    orderBy: ['name']
  });
  const loadInitiativeList = useCallback(async () => {
    const filterParams = getSelectedSegmentParams(
      selectedSegment,
      me.company?.industry?.id
    );
    await handleLoadInitiativeTemplateList({
      ...filterParams,
      pagination: paginationParams,
      tagIds
    });
  }, [
    handleLoadInitiativeTemplateList,
    me.company?.industry?.id,
    paginationParams.pageNumber,
    selectedSegment,
    tagIds
  ]);

  const { Loader } = useAsync({
    asyncFunction: loadInitiativeList
  });

  const loadTags = useCallback(async () => {
    const response = await TagService.listTags({
      companySlug: me.company?.slug,
      tagFamilyNames: ['EMISSION_SOURCE'],
      pagination: {
        orderBy: ['name', 'id'],
        order: 'ASC'
      }
    });
    return response.list.map((arrayItem) => ({
      label: arrayItem.name,
      value: arrayItem.id
    }));
  }, [me.company?.slug]);

  const { isLoading: isLoadingTags, value: tags } = useAsync({
    asyncFunction: loadTags,
    defaultValue: []
  });

  const handleClearFilter = () => {
    handleSetFilter([]);
  };

  const handleSetFilter = (values) => {
    handleSetPaginationParams({
      ...paginationParams,
      pageNumber: 1,
      prevPageNumber: 1,
      previousValue: null
    });
    setTagIds(values);
  };

  const handleOnClickTemplate = (template) => {
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: template.id
    });
  };

  const handleOnChangeSegment = (value) => {
    handleSetPaginationParams({
      ...paginationParams,
      pageNumber: 1,
      prevPageNumber: 1,
      previousValue: null
    });
    setSelectedSegment(value);
  };

  function getCardLink(type, id) {
    if (type === INITIATIVE_TYPE.MEASUREMENT) {
      if (isAdmin) {
        return AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW;
      } else {
        return AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW;
      }
    } else {
      return AppRoutes.INITIATIVE_TEMPLATE.replace(':initiativeTemplateId', id);
    }
  }

  // We don't want non admins to see the Office onboarding card
  // so we hide it.
  function getTemplateCard(template, isAdmin) {
    if (isAdmin || template.name !== 'Office Onboarding') {
      return <InitiativeCard card={template} />;
    }
    return null;
  }

  return (
    <Row gutter={[0, 24]} className="initiative-template-list">
      <Col span={24}>
        <Row>
          <Col flex="1 1 auto">
            <Title bottomSpacing={0}>Initiatives Templates</Title>
          </Col>
          <Col flex="314px">
            <Select
              placement="bottomRight"
              disableForm
              placeholder="Filter by category"
              onChange={handleSetFilter}
              loading={isLoadingTags}
              options={tags}
              mode="multiple"
              showSearch
              value={tagIds}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Segmented
          onChange={handleOnChangeSegment}
          defaultValue={selectedSegment}
          options={segmentOptions}
        />
      </Col>
      <Col span={24} className="initiative-template-list__grid">
        <Loader>
          {initiativeTemplateList.length ? (
            <Row gutter={[16, 16]} wrap>
              {initiativeTemplateList.map((template) => (
                <Col className="display-flex" key={template.id}>
                  <Link
                    onClick={() => {
                      handleOnClickTemplate(template);
                    }}
                    to={getCardLink(template.type, template.id)}
                    children={getTemplateCard(template, isAdmin)}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Row className="mt-8" justify="center" gutter={[0, 16]}>
              <Col span={24} className="display-flex justify-center">
                <img src={MagnifyingGlassCircle} alt="Magnifying Glass" />
              </Col>
              <Col span={24} className="display-flex justify-center">
                <Title bottomSpacing={0} size="md">
                  No initiatives found
                </Title>
              </Col>
              <Col span={24} className="display-flex justify-center">
                <Paragraph bottomSpacing={0}>
                  Please select another filter, or clear all your filters to see
                  all initiatives.
                </Paragraph>
              </Col>
              <Col span={24} className="display-flex justify-center">
                <Button onClick={handleClearFilter} type={BUTTON_TYPES.LINK}>
                  Clear filters
                </Button>
              </Col>
            </Row>
          )}
        </Loader>
      </Col>
      <Col span={24} className="display-flex justify-center">
        <Pagination
          pagination={paginationParams}
          total={totalInitiativeTemplateCount}
        />
      </Col>
    </Row>
  );
}
