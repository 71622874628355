/** CompanyInitiativeLayoutContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import produce from 'immer';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CompanyInitiativeStepsRoutes,
  CompanyInitiativeStepsTuple
} from '../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import { AppRoutes } from '../routes';
import { CompanyTrailService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const CompanyInitiativeLayoutContext = createContext({});

// Define the default context state
const defaultCompanyInitiativeLayoutState = {
  currentStep: undefined,
  prevRoute: AppRoutes.INITIATIVE,
  completedSteps: undefined,
  startedSteps: undefined,
  companyTrailOverviewList: []
};

// Create method to use context
function useCompanyInitiativeLayoutContext() {
  const { me } = useMeContext();
  const context = useContext(CompanyInitiativeLayoutContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { companyInitiativeId } = useParams();
  const { pathname, state } = location;
  const { prevRoute } = state ?? {};
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useInitiativeLayoutContext must be used within a InitiativeLayoutContextProvider'
    );
  }

  const [companyInitiativeLayoutState, setCompanyInitiativeLayoutState] =
    context;

  // Context Methods //
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  const setCurrentStep = (step) => {
    setCompanyInitiativeLayoutState((prevState) =>
      produce(prevState, (draft) => {
        draft.currentStep = step;
      })
    );
  };
  const nextStep = () => {
    const currentStepIndex = CompanyInitiativeStepsTuple.indexOf(
      companyInitiativeLayoutState.currentStep
    );
    if (
      currentStepIndex > -1 &&
      currentStepIndex !== CompanyInitiativeStepsTuple.length - 1
    ) {
      const nextStepValue = CompanyInitiativeStepsTuple[currentStepIndex + 1];
      let navigationRoute;
      Object.entries(CompanyInitiativeStepsRoutes).forEach(([key, value]) => {
        if (value === nextStepValue) {
          navigationRoute = key.replace(
            ':companyInitiativeId',
            companyInitiativeId
          );
        }
      });
      setCurrentStep(CompanyInitiativeStepsTuple[nextStepValue]);
      if (navigationRoute) {
        navigate(navigationRoute);
      }
    }
  };
  const prevStep = () => {
    const currentStepIndex = CompanyInitiativeStepsTuple.indexOf(
      companyInitiativeLayoutState.currentStep
    );
    if (currentStepIndex > -1 && currentStepIndex !== 0) {
      const prevStepValue = CompanyInitiativeStepsTuple[currentStepIndex - 1];
      let navigationRoute;
      Object.entries(CompanyInitiativeStepsRoutes).forEach(([key, value]) => {
        if (value === prevStepValue) {
          navigationRoute = key.replace(
            ':companyInitiativeId',
            companyInitiativeId
          );
        }
      });
      setCurrentStep(prevStepValue);

      if (navigationRoute) {
        navigate(navigationRoute);
      }
    }
  };
  // Navigate the menu directly without using the Next/Back helpers
  const navStep = (url) => {
    const pathWithoutParams = url?.replace(
      companyInitiativeId,
      ':companyInitiativeId'
    );
    const step = CompanyInitiativeStepsRoutes[pathWithoutParams];
    setCurrentStep(step);
    if (url) {
      navigate(url);
    }
  };

  const handleLoadCompanyTrailOverviewList = useCallback(
    async (params) => {
      const trailList = await CompanyTrailService.fetchCompanyTrails({
        companySlug: me?.company?.slug,
        ...params
      });
      setCompanyInitiativeLayoutState((prevState) =>
        produce(prevState, (draft) => {
          draft.completedSteps = trailList
            ?.filter((listItem) => listItem.isComplete)
            .map((listItem) => listItem.segmentName);
          draft.startedSteps = trailList
            ?.filter((listItem) => listItem.isStarted)
            .map((listItem) => listItem.segmentName);
          draft.companyTrailOverviewList = trailList;
        })
      );
    },
    [me?.company?.slug]
  );
  const handleCreateOrUpdateCompanyTrailOverview = useCallback(
    async (trail) => {
      const newTrail = await CompanyTrailService.createCompanyTrail(
        me?.company?.slug,
        trail
      );
      setCompanyInitiativeLayoutState((prevState) =>
        produce(prevState, (draft) => {
          draft.startedSteps.push(newTrail.data?.segmentName);
          if (newTrail.data?.isComplete) {
            draft.completedSteps.push(newTrail.data?.segmentName);
          }
          const trail = draft.companyTrailOverviewList.find(
            (trail) =>
              trail.segmentName === newTrail.data.segmentName &&
              trail.trailName === newTrail.data.trailName
          );
          if (trail !== undefined) {
            trail.isComplete = newTrail.data.isComplete;
          } else {
            draft.companyTrailOverviewList.push(newTrail.data);
          }
        })
      );
    },
    [me?.company?.slug]
  );
  useEffect(() => {
    if (!!prevRoute) {
      setCompanyInitiativeLayoutState((prevState) =>
        produce(prevState, (draft) => {
          draft.prevRoute = prevRoute;
        })
      );
    }
  }, [prevRoute]);

  useEffect(() => {
    if (!companyInitiativeLayoutState.currentStep) {
      navStep(pathname);
    }
  }, [pathname, companyInitiativeId]);
  return {
    companyInitiativeLayoutState,
    setCurrentStep,
    nextStep,
    prevStep,
    navStep,
    handleLoadCompanyTrailOverviewList,
    handleCreateOrUpdateCompanyTrailOverview
  };
}

// Create the context provider
function CompanyInitiativeLayoutContextProvider(props) {
  const [companyInitiativeLayoutState, setCompanyInitiativeLayoutState] =
    useState(defaultCompanyInitiativeLayoutState);
  const value = useMemo(
    () => [companyInitiativeLayoutState, setCompanyInitiativeLayoutState],
    [companyInitiativeLayoutState]
  );
  return <CompanyInitiativeLayoutContext.Provider value={value} {...props} />;
}

export {
  CompanyInitiativeLayoutContextProvider,
  useCompanyInitiativeLayoutContext
};
