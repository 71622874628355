import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useState } from 'react';
import { Card } from '../../../../components';
import {
  COLOURS_CARBONHOUND,
  COLOURS_INDIGO,
  COLOURS_VIOLET
} from '../../../../constants/colours';
import DateFormats from '../../../../constants/dateFormats';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { formatDecimal, getYearDropdownOptions } from '../../../../helpers';
import {
  formatSingleEmission,
  getDefaultYearForChart
} from '../../../../helpers/generators';
import { useAsync, useModal } from '../../../../hooks';
import sleepingGus from '../../../../stories/assets/svgs/Gus/gusSleeping.svg';
import { Button, Paragraph, Select, Title } from '../../../../stories/atoms';
import StackedBarChart from '../../../../stories/atoms/StackedBarChart';
import { CompanyInitiativeCompleteForm } from '../CompanyInitiativeCompleteForm';

export function CompanyInitiativeOverviewDetails() {
  const { me, selectCarbonYearRange } = useMeContext();
  const {
    initiativeState,
    handleLoadInitiativeOverview,
    handleSetInitiativeState
  } = useInitiativeContext();
  const { initiativeOverview, companyInitiative } = initiativeState;
  const { completeAt } = companyInitiative;
  const { reductions, chartSummary, targetSummary, monthlyBaseline } =
    initiativeOverview;

  const [selectedDateRange, setSelectedDateRange] = useState(
    selectCarbonYearRange({
      activeYear: getDefaultYearForChart({
        startingMonth: me.company?.startingMonth
      })
    })
  );

  const [chartDateYear, setChartDateYear] = useState(
    new Date(selectedDateRange.rangeStart).getUTCFullYear()
  );

  const { rangeStart, rangeEnd } = selectedDateRange;
  const loadInitiativeOverview = useCallback(async () => {
    await handleLoadInitiativeOverview({
      rangeStart,
      rangeEnd
    });
  }, [rangeStart, rangeEnd]);
  const hasReductions = reductions && Math.abs(reductions) > 0;
  const { Loader } = useAsync({ asyncFunction: loadInitiativeOverview });
  const chartLabels =
    chartSummary?.map((s) =>
      formatInTimeZone(new Date(s.date), 'UTC', 'MMM yy')
    ) ?? [];

  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];

  const handleSetChartYear = (val) => {
    setChartDateYear(val);
    if (val === -1) {
      setSelectedDateRange({
        rangeStart: `${2006}-${String(me?.company?.startingMonth).padStart(
          2,
          '0'
        )}-01`,
        rangeEnd: formatInTimeZone(new Date(), 'UTC', DateFormats.API)
      });
    } else {
      setSelectedDateRange(
        selectCarbonYearRange({
          activeYear: val
        })
      );
    }
  };
  const chartConfig = {
    labels: chartLabels,
    datasets: [
      {
        type: 'line',
        label: 'Baseline',
        data: chartLabels.map(() =>
          formatDecimal(formatSingleEmission(monthlyBaseline))
        ),
        borderColor: COLOURS_CARBONHOUND.PRIMARY_ORANGE
      },
      {
        type: 'bar',
        label: 'Emissions',
        barPercentage: 0.25,
        data: chartSummary?.map((s) =>
          formatDecimal(formatSingleEmission(s.totalEmissions))
        ),
        backgroundColor: COLOURS_VIOLET.VIOLET_500,
        borderRadius: 50
      },
      {
        type: 'line',
        label: 'Target',
        data: targetSummary?.[0]?.targetEmissions?.map((s) =>
          formatDecimal(formatSingleEmission(s.targetEmissions))
        ),
        borderColor: COLOURS_INDIGO.INDIGO_900
      }
    ]
  };

  const { Modal, handleShowModal, handleCloseModal } = useModal({
    width: '60%'
  });
  const onMarkAsCompleteSuccess = (completeAt) => {
    handleSetInitiativeState({
      companyInitiative: {
        completeAt
      }
    });
    handleCloseModal();
  };
  return (
    <Row gutter={[16, 40]} align="middle">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {' '}
          {!completeAt && (
            <Col span={24}>
              <Card>
                <Loader>
                  <Row>
                    <Col flex={1}>
                      <Title bottomSpacing={8}>
                        Have you completed this initiative?
                      </Title>
                      <Paragraph bottomSpacing={0}>
                        Set it to complete so you can start to track your future
                        reductions.
                      </Paragraph>
                    </Col>
                    <Col className="align-center display-flex">
                      <Button onClick={handleShowModal}>
                        Mark as Complete
                      </Button>
                    </Col>
                  </Row>
                </Loader>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Card>
              <Loader>
                <Row justify="center" align="middle">
                  <Col span={12}>
                    <Title
                      align="center"
                      fontWeight={400}
                      size="sm"
                      bottomSpacing={24}
                    >
                      {hasReductions && Math.sign(reductions) === -1
                        ? "You've increased your emissions by"
                        : 'Reductions'}
                    </Title>
                    <Paragraph
                      align="center"
                      size="lg"
                      bottomSpacing={0}
                      weight={600}
                      color={
                        Math.sign(reductions) === -1
                          ? COLOURS_CARBONHOUND.PRIMARY_RED
                          : 'default'
                      }
                    >
                      {formatDecimal(Math.abs(reductions))}{' '}
                      {hasReductions ? 'Tonnes CO2e' : ''}
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Title align="center" size="xl" bottomSpacing={24}>
                      {hasReductions ? (
                        '🌲'
                      ) : (
                        <img
                          src={sleepingGus}
                          alt="An illustration of a sleeping dog, named Gus"
                        />
                      )}
                    </Title>
                    <Title
                      align="center"
                      fontWeight={400}
                      size="sm"
                      bottomSpacing={0}
                    >
                      {hasReductions
                        ? `That is how much carbon ${formatDecimal(
                            Math.abs(reductions) / 1.2,
                            0
                          )} fully mature Gumtrees absorb a year`
                        : 'You haven’t made any reductions yet'}
                    </Title>
                  </Col>
                </Row>
              </Loader>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Title bottomSpacing={8}>GHG Category Source Usage</Title>
        <Paragraph bottomSpacing={16}>
          Once you select specific sources you’ll be able to see their usage
          here and forecast your future reductions
        </Paragraph>

        <Row justify="end" gutter={[16, 16]}>
          <Col flex="180px">
            <Select
              disableForm
              name="dateFilter"
              options={footprintRangeDropdownOptions}
              placeholder="Current Fiscal Year"
              value={chartDateYear}
              onChange={handleSetChartYear}
            />
          </Col>
        </Row>
        <StackedBarChart data={chartConfig} />
      </Col>
      <Modal
        title="Mark initiative as complete?"
        bodyStyle={{ padding: 0 }}
        className="company-initiative-complete-form__modal"
      >
        <CompanyInitiativeCompleteForm
          onSuccess={onMarkAsCompleteSuccess}
          companyInitiative={companyInitiative}
        />
      </Modal>
    </Row>
  );
}
