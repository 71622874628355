import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { Input } from 'antd';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import Error from '../../../components/Error';
import { GoogleApiLibraries } from '../../../constants';
import { useMetaContext } from '../../../context';
import { disableAutocomplete, getAddressValues } from '../../../helpers';
import Icon from '../Icon';
import { Label } from '../index';
import './GoogleSelect.scss';

const googlePlacesLibraries = GoogleApiLibraries;
function GoogleSelect({
  id,
  onSelect,
  disabled,
  placeholder,
  title,
  defaultValue,
  name,
  isEdit,
  className
}) {
  const [searchText, setSearchText] = useState();

  const { meta } = useMetaContext();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GCP_PLACES_API_KEY,
    id: `${id}-script-loader`,
    libraries: googlePlacesLibraries
  });

  const [searchBoxRef, setSearchBoxRef] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const [places, setPlaces] = useState([]);

  const [isFocusedOut, setFocusedOut] = useState(false);

  const onLoad = (ref) => {
    setSearchBoxRef(ref);
  };

  const onPlacesChanged = () => {
    setErrorMessage(undefined);
    if (searchBoxRef) {
      const places = searchBoxRef.getPlaces();
      if (places.length > 0) {
        const address = getAddressValues(places[0], meta.countries);
        setSearchText(places[0].formatted_address);
        if (!address.countryId && isEdit) {
          setErrorMessage('This country is currently not supported.');
          return;
        }
        if (!!isEdit && !address.isEstimationSupported) {
          setErrorMessage(
            'Sorry, that country isn’t supported for home offices'
          );
          onSelect(places);
          return;
        }
        if (!!address.address1) {
          onSelect(places);
          setPlaces(places);
        } else {
          setErrorMessage('Please enter a location with an exact address');
        }
      }
    }
  };

  useEffect(() => {
    if (!!defaultValue && !!Object.keys(defaultValue).length)
      setSearchText(defaultValue);
  }, [defaultValue]);

  return (
    isLoaded && (
      <div
        data-standalone-searchbox=""
        className={classNames('google-places-autocomplete-new', {
          [className]: !!className
        })}
      >
        {title && (
          <Label className="google-places-autocomplete-new__title">
            {title}
          </Label>
        )}
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <Input
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
            disabled={disabled}
            suffix={<Icon name="Search" />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder={placeholder ?? 'Search address'}
            className="google-places-autocomplete-new__input-search"
            onFocus={disableAutocomplete}
            onBlur={() => setFocusedOut(true)}
            autoComplete="off"
          />
        </StandaloneSearchBox>

        {name && (
          <ErrorMessage name={name}>
            {(message) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        )}
        {errorMessage ? (
          <div className="mt-2 text-danger">{errorMessage}</div>
        ) : (
          isFocusedOut &&
          places.length === 0 && (
            <div className="mt-2 text-danger">Please select any address</div>
          )
        )}
      </div>
    )
  );
}
export default GoogleSelect;
