function Key() {
  return (
    <path
      d="M15 7C15.5304 7 16.0391 7.21071 16.4142 7.58579C16.7893 7.96086 17 8.46957 17 9M21 9C21.0003 9.93717 20.781 10.8614 20.3598 11.6985C19.9386 12.5357 19.3271 13.2626 18.5744 13.8209C17.8216 14.3792 16.9486 14.7534 16.0252 14.9135C15.1018 15.0736 14.1538 15.0152 13.257 14.743L11 17H9V19H7V21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V17.414C3.00006 17.1488 3.10545 16.8945 3.293 16.707L9.257 10.743C9.00745 9.91801 8.93857 9.04894 9.05504 8.19495C9.17152 7.34095 9.47062 6.52207 9.93199 5.79405C10.3934 5.06602 11.0062 4.44594 11.7287 3.97602C12.4512 3.50609 13.2665 3.19735 14.1191 3.0708C14.9716 2.94426 15.8415 3.00288 16.6693 3.24268C17.4972 3.48249 18.2637 3.89784 18.9166 4.46046C19.5696 5.02309 20.0936 5.71979 20.4531 6.50314C20.8127 7.28649 20.9992 8.1381 21 9Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Key;
