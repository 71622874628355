import { add, eachMonthOfInterval } from 'date-fns';

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import { TargetType } from '../../../constants';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { TargetService } from '../../../services';
import { ItemDataForm } from '../../../stories/molecules';
import './TargetSettingData.scss';
import { generateItemFieldArray } from '../../../helpers/generators';

const createIntensityMonthItems = (months) =>
  months.map((m) => ({
    date: m,
    value: null
  }));

export const createIntensityItems = (baseYear, startingMonth) => {
  const items = {};
  for (
    let currentYear = baseYear;
    currentYear <= new Date().getUTCFullYear();
    currentYear++
  ) {
    const startingDate = new Date(`${currentYear}-${startingMonth}-01`);
    const endDate = add(startingDate, {
      months: 11
    });
    const months = eachMonthOfInterval({
      start: startingDate,
      end: endDate
    });
    items[currentYear] = createIntensityMonthItems(months);
  }
  return items;
};

export function TargetSettingData() {
  const { me } = useMeContext();
  const { targetDetailsId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const loadTargetDetails = useCallback(
    async () =>
      await TargetService.getTargetDetails({
        companySlug: me?.company?.slug,
        targetDetailsId
      }),
    [targetDetailsId, me?.company?.slug]
  );

  const loadTargetItems = useCallback(async () => {
    const items = createIntensityItems(
      me.company?.baseYear,
      me.company?.startingMonth
    );
    const { data: listResponse } = await TargetService.listTargetItems({
      companySlug: me?.company?.slug,
      targetDetailsId
    });
    generateItemFieldArray(listResponse, items);
    return items;
  }, [
    me.company?.baseYear,
    me.company?.startingMonth,
    me.company?.slug,
    targetDetailsId
  ]);

  const { value: targetList, isLoading: isLoadingList } = useAsync({
    asyncFunction: loadTargetItems,
    defaultValue: {}
  });

  const {
    value: { data: targetDetails },
    isLoading
  } = useAsync({
    asyncFunction: loadTargetDetails,
    defaultValue: {}
  });

  const createOrUpdateTargetItem = async (formValues) => {
    await TargetService.createOrUpdateTargetItem({
      companySlug: me?.company?.slug,
      targetDetailsId,
      items: formValues.filter((year) => !!year.values.length)
    });
    navigate(
      AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_REDUCTIONS.replace(
        ':targetDetailsId',
        targetDetailsId
      ),
      { state: { type: targetDetails.type } }
    );
  };

  useEffect(() => {
    if (
      targetDetails?.type === TargetType.INTENSITY &&
      typeof location.state?.type === 'undefined'
    ) {
      navigate('.', { replace: true, state: { type: targetDetails?.type } });
    }
  }, [targetDetails?.type, location.state?.type]);

  return (
    <div>
      <h3>Enter your data</h3>
      <p className="text-12">
        For intensity targets you have to enter the values for each month so you
        can see your progress.
      </p>
      <ItemDataForm
        details={targetDetails}
        list={targetList}
        onSaveFn={createOrUpdateTargetItem}
        isLoading={isLoading}
        isLoadingList={isLoadingList}
        formType="TARGET"
      />
    </div>
  );
}
