export const getTargetYearDropdownOptions = (
  startYear = new Date().getUTCFullYear()
) => {
  const options = [];
  // Adding one because target year cannot be same as current
  const initialDate = startYear + 5;
  for (let i = initialDate; i <= initialDate + 30; i++) {
    options.push({
      label: i,
      value: i
    });
  }
  return options;
};

export const absoluteStrengths = [
  'Reduces GHG’s emitted to the atmosphere',
  'Transparent',
  'Easy to communicate',
  'More credible'
];

export const absoluteWeaknesses = [
  'Peer comparison',
  'Less transparent',
  'More challenging to achieve'
];

export const intensityStrengths = [
  'Peer comparison',
  'Highlights efficiency improvements',
  'Scales with company growth'
];

export const intensityWeaknesses = [
  'Less credible',
  'Less transparent',
  'Doesn’t guarantee reduction of total emissions'
];

export const targetIntensityTypes = {
  REVENUE_MONTH: 'Monthly Revenue',
  EMPLOYEES_MONTH: 'Monthly Total Employees',
  CUSTOM: 'CUSTOM',
  TEMPLATE: 'TEMPLATE'
};

const targetIntensityAggregateTypes = {
  AVERAGE: 'AVERAGE',
  TOTAL: 'TOTAL'
};

export const targetIntensityTypeOptions = [
  {
    label: 'Monthly Revenue',
    value: targetIntensityTypes.REVENUE_MONTH
  },
  {
    label: 'Monthly Total Employees',
    value: targetIntensityTypes.EMPLOYEES_MONTH
  },
  {
    label: 'Create Custom Intensity Metric',
    value: targetIntensityTypes.CUSTOM
  },
  {
    label: 'Create Metric from Template',
    value: targetIntensityTypes.TEMPLATE
  }
];

export const targetIntensityAggregateOptions = [
  {
    label: 'Average',
    value: targetIntensityAggregateTypes.AVERAGE
  },
  {
    label: 'Total',
    value: targetIntensityAggregateTypes.TOTAL
  }
];

export const targetIntensityInternalAggregateMap = {
  [targetIntensityTypes.REVENUE_MONTH]: targetIntensityAggregateTypes.TOTAL,
  [targetIntensityTypes.EMPLOYEES_MONTH]: targetIntensityAggregateTypes.AVERAGE
};
