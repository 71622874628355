import { useEffect, useState } from 'react';
import { useCompanyInitiativeLayoutContext } from '../../../../../context/CompanyInitiativeLayoutContext';
import { useInitiativeContext } from '../../../../../context/InitiativeContext';
import {
  COMPANY_INITIATIVE_STEPS,
  COMPANY_INITIATIVE_STEPS_LABEL,
  COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS,
  CompanyInitiativeForecastingSteps,
  CompanyInitiativePlanningSteps
} from '../../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import { Menu, MenuItem, SubMenu } from '../../../../../stories/atoms';
import './company-initiative-sidebar-menu.scss';

export function InitiativeDetailSidebarMenu() {
  const [openKeys, setOpenKeys] = useState([]);
  const { companyInitiativeLayoutState, navStep } =
    useCompanyInitiativeLayoutContext();

  const { currentStep, completedSteps } = companyInitiativeLayoutState;
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;

  const handleTogglePlanningMenu = () => {
    setOpenKeys((prevValue) => {
      if (prevValue.length < 1) {
        return [COMPANY_INITIATIVE_STEPS.PLANNING];
      }
      return [];
    });
  };
  const handleToggleForecastingMenu = () => {
    setOpenKeys((prevValue) => {
      if (prevValue.length < 2) {
        return [
          COMPANY_INITIATIVE_STEPS.FORECASTING,
          COMPANY_INITIATIVE_STEPS.PLANNING
        ];
      }
      return [COMPANY_INITIATIVE_STEPS.PLANNING];
    });
  };
  useEffect(() => {
    //  The length checks are to prevent the sidebar menu from opening/closing because the values refresh
    if (CompanyInitiativeForecastingSteps.indexOf(currentStep) > -1) {
      setOpenKeys((prevValue) => {
        if (prevValue.length < 2) {
          return [
            COMPANY_INITIATIVE_STEPS.FORECASTING,
            COMPANY_INITIATIVE_STEPS.PLANNING
          ];
        }
        return prevValue;
      });
    } else if (CompanyInitiativePlanningSteps.indexOf(currentStep) > -1) {
      setOpenKeys((prevValue) => {
        if (prevValue.length < 1) {
          return [COMPANY_INITIATIVE_STEPS.PLANNING];
        }
        return prevValue;
      });
    } else {
      setOpenKeys([]);
    }
  }, [currentStep]);
  const handleNavigate = ({ key }) => {
    navStep(
      COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS[key].replace(
        ':companyInitiativeId',
        companyInitiative.id
      )
    );
  };
  return (
    <Menu
      className="company-initiative-sidebar-menu"
      mode="inline"
      selectedKeys={[currentStep]}
      openKeys={openKeys}
      onClick={handleNavigate}
    >
      <MenuItem
        currentStep={currentStep}
        completedSteps={completedSteps}
        key={COMPANY_INITIATIVE_STEPS.OVERVIEW}
        stepName={COMPANY_INITIATIVE_STEPS.OVERVIEW}
      >
        {COMPANY_INITIATIVE_STEPS_LABEL[COMPANY_INITIATIVE_STEPS.OVERVIEW]}
      </MenuItem>
      <SubMenu
        onTitleClick={handleTogglePlanningMenu}
        key={COMPANY_INITIATIVE_STEPS.PLANNING}
        title={
          <span>
            <span className="mr-2">1.</span>
            Planning
          </span>
        }
      >
        <SubMenu
          onTitleClick={handleToggleForecastingMenu}
          key={COMPANY_INITIATIVE_STEPS.FORECASTING}
          title="Forecasting"
          className="m-0"
        >
          <MenuItem
            currentStep={currentStep}
            completedSteps={completedSteps}
            key={COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES}
            stepName={COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES}
          >
            {
              COMPANY_INITIATIVE_STEPS_LABEL[
                COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES
              ]
            }
          </MenuItem>
          <MenuItem
            currentStep={currentStep}
            completedSteps={completedSteps}
            key={COMPANY_INITIATIVE_STEPS.TARGET_BASELINE}
            stepName={COMPANY_INITIATIVE_STEPS.TARGET_BASELINE}
          >
            {
              COMPANY_INITIATIVE_STEPS_LABEL[
                COMPANY_INITIATIVE_STEPS.TARGET_BASELINE
              ]
            }
          </MenuItem>
        </SubMenu>
        <MenuItem
          currentStep={currentStep}
          completedSteps={completedSteps}
          key={COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN}
          stepName={COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN}
        >
          {
            COMPANY_INITIATIVE_STEPS_LABEL[
              COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN
            ]
          }
        </MenuItem>
        <MenuItem
          currentStep={currentStep}
          completedSteps={completedSteps}
          key={COMPANY_INITIATIVE_STEPS.FUNDING}
          stepName={COMPANY_INITIATIVE_STEPS.FUNDING}
        >
          {COMPANY_INITIATIVE_STEPS_LABEL[COMPANY_INITIATIVE_STEPS.FUNDING]}
        </MenuItem>
      </SubMenu>
      <MenuItem
        currentStep={currentStep}
        completedSteps={completedSteps}
        key={COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS}
        stepName={COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS}
      >
        {`2. ${
          COMPANY_INITIATIVE_STEPS_LABEL[
            COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS
          ]
        }`}
      </MenuItem>
    </Menu>
  );
}
