import { useState } from 'react';
import { Row, Col, Space } from 'antd';
import { useFormikContext } from 'formik';
import { formatInTimeZone } from 'date-fns-tz';
import { Button, Input, Paragraph } from '../../../../stories/atoms';
import {
  SCOPE_OPTIONS,
  TIME_UNIT_OPTIONS
} from '../../../../constants/options';
import './requestCSVDetails.scss';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { NotificationService } from '../../../../services';
import { useMeContext } from '../../../../context';
import { Notification } from '../../../../components';
import { useAsync } from '../../../../hooks';
import { getKeyByValue } from '../../../../helpers/generators';
import { convertConstantToString } from '../../../../helpers/formatters';
import DateFormats from '../../../../constants/dateFormats';
import { AGGREGATE_TYPE } from '../../../../constants';
import { analytics } from '../../../../helpers';

function RequestCSVDetails({
  displayTypeOptions,
  categoryList = [],
  changeTab,
  onSuccess
}) {
  const { me } = useMeContext();
  const [exportName, setExportName] = useState('');
  const handleExportName = (e) => {
    setExportName(e.target.value);
  };
  const { values } = useFormikContext();

  const collections = !!values?.collectionIds
    ? getKeyByValue(
        values?.collectionIds,
        displayTypeOptions.list,
        'id',
        'name'
      ).join(', ')
    : 'All';
  const categories = !!values?.tagIds
    ? getKeyByValue(values?.tagIds, categoryList, 'id', 'name').join(', ')
    : 'All';
  const emissionScopes = !!values?.emissionScopes
    ? values?.emissionScopes
        .map((scope) =>
          Object.values(SCOPE_OPTIONS)[scope - 1].label.substring(0, 7)
        )
        .join(', ')
    : 'All';
  const startDate = formatInTimeZone(
    new Date(values?.selectedDateRange?.rangeStart),
    'UTC',
    'MMM y'
  );
  const endDate = formatInTimeZone(
    new Date(values?.selectedDateRange?.rangeEnd),
    'UTC',
    'MMM y'
  );

  const handleSubmitRequest = async () => {
    const submissionBody = {
      title: exportName,
      companySlug: me?.company?.slug
    };
    const submissionQuery = Object.entries(values)
      .filter(([_, value]) => !!value)
      .reduce((acc, [key, value]) => {
        if (key !== 'selectedDateRange') {
          acc[key] = value;
        } else {
          const { rangeStart, rangeEnd } = value;
          acc.rangeStart = formatInTimeZone(
            new Date(rangeStart),
            'UTC',
            DateFormats.API
          );
          acc.rangeEnd = formatInTimeZone(
            new Date(rangeEnd),
            'UTC',
            DateFormats.API
          );
        }
        return acc;
      }, {});

    const response =
      await NotificationService.createNotificationActionDataExport({
        ...submissionQuery,
        ...submissionBody
      });
    if (!!response) {
      onSuccess();
      Notification({
        type: 'success',
        message: 'Request Submitted',
        description: (
          <Space direction="vertical">
            <Paragraph bottomSpacing={0}>
              You can view your CSVs in Your Exports tab.{' '}
            </Paragraph>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={() => changeTab('3', true)}
              className="view-export-anchor"
            >
              View Report
            </Button>
          </Space>
        )
      });
    }
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmitRequest,
    immediate: false
  });

  const groupDataType = getKeyByValue(
    [values.timeUnit],
    TIME_UNIT_OPTIONS,
    'value',
    'label'
  );

  const isTypeCategoryOrScope = [
    AGGREGATE_TYPE.CATEGORY,
    AGGREGATE_TYPE.SCOPE
  ].includes(values.aggregateType);

  const handleSendRequest = () => {
    analytics.track('Upload data clicked', me, {
      level1: 'Data Explorer',
      level2: 'Reporting'
    });
    execute();
  };
  return (
    <Row>
      <Col span={24}>
        <Input
          type="text"
          value={exportName}
          title="Name your export (optional) "
          name="Export Name"
          placeholder="Enter"
          onChange={handleExportName}
          isFormikField={false}
        />
      </Col>
      <Col span={24} className="mt-4">
        <Paragraph>Your report will cover these parameters: </Paragraph>
        <Row className="request-csv-body">
          <Col span={24}>
            <Row className="reporting-parameter">
              <Col span={12}>
                <Paragraph size="sm" bottomSpacing={0}>
                  Report Type
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph
                  size="sm"
                  bottomSpacing={0}
                  weight={600}
                  align="right"
                >
                  {convertConstantToString(values.aggregateType).singular}
                </Paragraph>
              </Col>
            </Row>
            <Row className="reporting-parameter">
              <Col span={12}>
                <Paragraph size="sm" bottomSpacing={0}>
                  Display
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph
                  size="sm"
                  bottomSpacing={0}
                  weight={600}
                  align="right"
                >
                  {collections}
                </Paragraph>
              </Col>
            </Row>
            {!isTypeCategoryOrScope ? (
              <>
                <Row className="reporting-parameter">
                  <Col span={12}>
                    <Paragraph size="sm" bottomSpacing={0}>
                      Scopes
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Paragraph
                      size="sm"
                      bottomSpacing={0}
                      weight={600}
                      align="right"
                    >
                      {emissionScopes}
                    </Paragraph>
                  </Col>
                </Row>
                <Row className="reporting-parameter">
                  <Col span={12}>
                    <Paragraph size="sm" bottomSpacing={0}>
                      Categories
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Paragraph
                      size="sm"
                      bottomSpacing={0}
                      weight={600}
                      align="right"
                    >
                      {categories}
                    </Paragraph>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row className="reporting-parameter">
              <Col span={12}>
                <Paragraph size="sm" bottomSpacing={0}>
                  Reporting Period
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph
                  size="sm"
                  bottomSpacing={0}
                  weight={600}
                  align="right"
                >
                  {convertConstantToString(values.reportingPeriod).singular},{' '}
                  {`${startDate} - ${endDate}`}
                </Paragraph>
              </Col>
            </Row>
            <Row className="reporting-parameter">
              <Col span={12}>
                <Paragraph size="sm" bottomSpacing={0}>
                  Group Data Type
                </Paragraph>
              </Col>
              <Col span={12}>
                <Paragraph
                  size="sm"
                  bottomSpacing={0}
                  weight={600}
                  align="right"
                >
                  {groupDataType}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="mt-6">
        <Paragraph>
          Report requests can take up to 20 minutes to generate. You can track
          the progress of your request in the Your Exports tab.
        </Paragraph>
      </Col>
      <Col span={24} className="send-request-button__wrapper">
        <Button
          onClick={handleSendRequest}
          type={BUTTON_TYPES.PRIMARY}
          loading={isLoading}
        >
          Send Request
        </Button>
      </Col>
    </Row>
  );
}

export default RequestCSVDetails;
