import { TransportationType } from '../constants';
import { getDistance, getGreatCircleDistance } from './generators';

export const handleCalculateDistance = async ({
  place: { originStop = {}, destStop = {} },
  setFieldValue,
  travelCategoryName,
  googleType,
  distanceUnitOption,
  distanceFieldKey
}) => {
  let distance = 0;
  const isValidOrigin = !!originStop.lat && !!originStop.lng;
  const isValidDestination = !!destStop.lat && !!destStop.lng;
  const { google } = window;
  if (!isValidOrigin || !isValidDestination || !google) {
    return distance;
  }
  const origin = new google.maps.LatLng(originStop.lat, originStop.lng);
  const destination = new google.maps.LatLng(destStop.lat, destStop.lng);
  if (travelCategoryName === TransportationType.PLANE) {
    // Plane isn't compatible with distanceMatrix
    distance = await getGreatCircleDistance(google, origin, destination);
  } else if (googleType) {
    const directionsService = new google.maps.DirectionsService();

    distance = await getDistance(
      directionsService,
      origin,
      destination,
      googleType
    );
  }
  setFieldValue(distanceFieldKey, distance);
  setFieldValue('conversionUnitName', distanceUnitOption.name);
};
