import * as Yup from 'yup';

export const LocationFormValidation = Yup.object().shape({
  moveInDate: Yup.string().nullable().required('Move-in date is required'),
  address1: Yup.string().nullable(),
  address2: Yup.string()
    .nullable()
    .max(35, 'Should not contain more than 35 characters'),
  countryId: Yup.string().nullable().required('Country is required'),
  postalCode: Yup.string()
    .nullable()
    .max(10, 'Should not contain more than 10 characters'),
  isOwner: Yup.boolean().required('Required'),
  isCurrentOffice: Yup.boolean().required('Required'),
  isMonthlyInput: Yup.boolean().required('Required'),
  moveOutDate: Yup.string()
    .nullable()
    .when('isCurrentOffice', {
      is: false,
      then: Yup.string().nullable().required('Move-out date is required'),
      otherwise: Yup.string().nullable()
    }),
  energyTypeIds: Yup.array().when(['isMonthlyInput', 'isEnergyTypeUnknown'], {
    is: (isMonthlyInput, isEnergyTypeUnknown) =>
      isMonthlyInput && !isEnergyTypeUnknown,
    then: Yup.array().min(
      1,
      'Please select either "I don\'t know" or at least one energy type'
    )
  }),
  officeSquareFootage: Yup.number()
    .nullable()
    .when('energyTypes', {
      is: (energyTypes) =>
        energyTypes.filter(
          (energyType) =>
            !energyType.hasAccessToBills || !energyType.hasSeparateMeter
        ).length > 0,
      then: Yup.number()
        .nullable()
        .required('Office square footage is required')
        .min(1, 'Value should be greater than 1'),
      otherwise: Yup.number().nullable()
    })
    .when('isEnergyTypeUnknown', {
      is: (isEnergyTypeUnknown) => isEnergyTypeUnknown,
      then: Yup.number()
        .nullable()
        .required('Office square footage is required')
        .min(1, 'Value should be greater than 1'),
      otherwise: Yup.number().nullable()
    }),
  buildingSquareFootage: Yup.number()
    .nullable()
    .when('energyTypes', {
      is: (energyTypes) =>
        !!energyTypes.find(
          (energyType) => energyType.hasSeparateMeter === false
        ),
      then: Yup.number()
        .nullable()
        .required('Building square footage is required')
        .min(1, 'Value should be atleast 1')
        .moreThan(
          Yup.ref('officeSquareFootage'),
          'Value should be greater than office square footage'
        ),
      otherwise: Yup.number().nullable()
    })
});
