import * as Yup from 'yup';

export const energyItemFormValidation = Yup.object().shape({
  energyTypeId: Yup.string()
    .nullable()
    .notOneOf(['Unknown'])
    .required('Energy type is required'),
  conversionUnitName: Yup.string()
    .nullable()
    .required('Activity unit is required'),
  activity: Yup.number().typeError('Only numbers should be allowed').nullable(),
  billingStart: Yup.string()
    .nullable()
    .required('Billing start date is required'),
  billingEnd: Yup.string().nullable().required('Billing end date is required'),
  isEstimate: Yup.boolean().nullable().required('Required')
});
