import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { DropdownField } from '../../../components';
import { UserStatus } from '../../../constants';
import { useMeContext } from '../../../context';
import { getFullName } from '../../../helpers';
import { EmployeeService, TravelExpenseService } from '../../../services';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

function RemindUsersForm({ onSuccess, onCancel }) {
  const { me } = useMeContext();
  const { groupSettings } = me;

  const defaultOption = {
    label: 'Select All',
    value: 'all'
  };

  const [formLoading, _setFormLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  const handleSubmit = ({ userIds }) => {
    TravelExpenseService.sendTravelExpenseEmail(
      {
        companySlug: me.company.slug,
        groupSlug: groupSettings?.slug,
        userIds
      },
      () => {
        onSuccess();
        onCancel();
      }
    );
  };

  useEffect(() => {
    EmployeeService.fetchEmployees(
      { companySlug: me?.company?.slug, status: UserStatus.ACTIVE },
      (employees) => {
        setUserOptions(
          employees.map((employee) => ({
            label: getFullName(employee?.user),
            value: employee?.user?.id
          }))
        );
      },
      () => {},
      () => {}
    );
  }, [me]);

  return (
    <div className="remind-users-form">
      <h4 className="text-bd">Select users to remind</h4>
      <Formik
        enableReinitialize
        initialValues={{ userIds: [] }}
        onSubmit={handleSubmit}
        validationSchema={null}
      >
        {({ values, _errors, _isValid, dirty, setFieldValue }) => (
          <Form className="mt-5">
            <Row gutter={[20, 0]}>
              <Col span={15}>
                <DropdownField
                  name="userIds"
                  showSearch
                  mode="multiple"
                  value={values?.userIds}
                  suffixIcon={<SearchOutlined />}
                  options={[...[defaultOption], ...userOptions]}
                  setFieldValue={setFieldValue}
                  placeholder="Search and select"
                  onChange={(values, _options) => {
                    if (values.includes('all')) {
                      setFieldValue(
                        'userIds',
                        userOptions.map((option) => option.value)
                      );
                    } else {
                      setFieldValue('userIds', values);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[20, 20]} className="mt-4">
              <Col span={24} className="display-flex justify-end">
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  htmlType="button"
                  small
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  htmlType="submit"
                  disabled={
                    !dirty || values?.userIds?.length === 0 || formLoading
                  }
                  loading={formLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RemindUsersForm;
