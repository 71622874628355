import { Col, Row } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components';
import { NotificationCategoryLabelMapping } from '../../../constants';
import { COLOURS_GRAY } from '../../../constants/colours';
import {
  ActionMonthlyInputCategories,
  ActionOverviewListTuple,
  NotificationCategoryColours,
  NotificationCategoryLabel
} from '../../../constants/notificationCategory';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { NotificationService } from '../../../services';
import GusParty from '../../../stories/assets/svgs/Gus/gusParty.svg';
import {
  Button,
  DonutChart,
  Icon,
  Paragraph,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './ActionsOverview.scss';

export function ActionsOverview({ isOverviewOutdated, setIsOverviewOutdated }) {
  const { me } = useMeContext();
  const navigate = useNavigate();

  const handleGetFilteredNotificationOverview = useCallback(
    async () =>
      await NotificationService.getNotificationOverview({
        isDataImport: false
      }),
    []
  );
  const {
    value: {
      actionTypeSummary = [],
      overdueNotificationCount = 0,
      upcomingNotificationCount = 0
    },
    Loader,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleGetFilteredNotificationOverview,
    defaultValue: {
      actionTypeSummary: [],
      overdueNotificationCount: 0,
      upcomingNotificationCount: 0
    }
  });

  const data = actionTypeSummary.reduce(
    (acc, { count, category }) => {
      const countInt = parseInt(count);
      const [dataset] = acc.datasets;
      const categoryLabel = NotificationCategoryLabelMapping[category];
      const categoryIdx = acc.labels.indexOf(categoryLabel);
      const categoryExists = categoryIdx > -1;

      if (
        ActionMonthlyInputCategories.indexOf(category) > -1 &&
        categoryExists
      ) {
        dataset.data[categoryIdx] = dataset.data[categoryIdx] + countInt;
        acc.dataMapping[categoryLabel] =
          acc.dataMapping[categoryLabel] + countInt;
      } else {
        acc.labels.push(categoryLabel);
        dataset.data.push(countInt);
        dataset.backgroundColor.push(
          NotificationCategoryColours[categoryLabel]
        );
        acc.dataMapping[categoryLabel] = countInt;
      }
      acc.total += countInt;
      return acc;
    },
    {
      labels: [],
      datasets: [{ data: [], backgroundColor: [] }],
      total: 0,
      dataMapping: {}
    }
  );

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    cutout: '55%',
    elements: {
      arc: {
        borderWidth: 4
      }
    },
    plugins: {
      legend: { display: false }
    }
  };

  const onClickInitiativesLink = () => {
    analytics.track('Initiative Overview Clicked', me, {
      level1: 'Actions'
    });
    navigate(AppRoutes.INITIATIVE);
  };
  useEffect(() => {
    if (isOverviewOutdated) {
      refreshList();
      setIsOverviewOutdated(false);
    }
  }, [isOverviewOutdated]);

  return (
    <Row className="actions-overview" gutter={[24, 24]}>
      <Col className="display-flex" span={6}>
        <Card>
          <Loader>
            <Title className="text-center" bottomSpacing={24} size="sm">
              Overdue
            </Title>
            <div
              className={classNames(
                'actions-overview__statistic actions-overview__statistic--overdue',
                {
                  'no-overdue': overdueNotificationCount == 0
                }
              )}
            >
              <div>{overdueNotificationCount}</div>
            </div>
          </Loader>
        </Card>
      </Col>
      <Col className="display-flex" span={6}>
        <Card>
          <Loader>
            <Title className="text-center" bottomSpacing={24} size="sm">
              Due next 7 days
            </Title>
            <div
              className={classNames('actions-overview__statistic', {
                'actions-overview__statistic--upcoming':
                  upcomingNotificationCount > 0
              })}
            >
              <div>
                {upcomingNotificationCount > 0 ? (
                  upcomingNotificationCount
                ) : (
                  <>
                    <Paragraph
                      color={COLOURS_GRAY.GRAY_500}
                      weight={400}
                      bottomSpacing={40}
                      size="xs"
                    >
                      0 actions upcoming
                    </Paragraph>
                    <img
                      className="actions-overview__statistic--gus"
                      src={GusParty}
                      alt="Dog wearing a party hat"
                    />
                  </>
                )}
              </div>
            </div>
          </Loader>
        </Card>
      </Col>
      <Col className="display-flex" span={12}>
        <Card>
          <Loader>
            <Title bottomSpacing={24} size="sm">
              Action Types
            </Title>
            <Row gutter={54}>
              <Col flex="1">
                {ActionOverviewListTuple.map((categoryLabel) => {
                  const count = data.dataMapping?.[categoryLabel] ?? 0;
                  const hasNoInitiatives =
                    categoryLabel === NotificationCategoryLabel.INITIATIVE &&
                    count === 0;
                  return (
                    <Row
                      key={categoryLabel}
                      justify="space-between"
                      className="actions-overview__action-type-row"
                    >
                      <Col span={24}>
                        <Row gutter={64}>
                          <Col className="display-flex align-center" flex="1">
                            <Paragraph bottomSpacing={0} weight={600} size={12}>
                              {categoryLabel}
                            </Paragraph>
                          </Col>
                          <Col className="display-flex align-center justify-center">
                            <Paragraph bottomSpacing={0} weight={400} size={10}>
                              {count}
                            </Paragraph>
                            <Icon
                              color={NotificationCategoryColours[categoryLabel]}
                              name="CircleFilled"
                              className="ml-3"
                            />
                          </Col>
                        </Row>
                      </Col>
                      {hasNoInitiatives && (
                        <Col flex={1}>
                          <Button
                            onClick={onClickInitiativesLink}
                            className="actions-overview__action-type-row__initiative-button"
                            icon="ChevronRight"
                            fullWidth
                            type={BUTTON_TYPES.PRIMARY_ACCENT}
                          >
                            Get started with initiatives
                          </Button>
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </Col>
              <Col>
                <DonutChart options={chartOptions} data={data} />
              </Col>
            </Row>
          </Loader>
        </Card>
      </Col>
    </Row>
  );
}
