import { Col, Modal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Card, Loader } from '../../../../../components';
import {
  useEmployeeCommuteContext,
  useLayoutContext,
  useMeContext,
  useMetaContext
} from '../../../../../context';
import { analytics } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import { CommuteService, LocationService } from '../../../../../services';
import { Button, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../../../stories/atoms/Layout';
import { Blurb } from '../../../../../stories/molecules';
import { employeeDetailsWFHValidation } from './EmployeeDetailsWFHValidation';
import { WFHFields } from './WFHFields';

export function EmployeeDetailsWFH() {
  const { me } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};

  const { meta } = useMetaContext();

  const { navStep } = useLayoutContext();
  const {
    setActiveCommute,
    employeeCommuteDetails: { activeCommute }
  } = useEmployeeCommuteContext();
  const params = useParams();
  const { commuteId } = params;
  const [formValues, setFormValues] = useState({
    name: undefined,
    countryId: undefined,
    officeCollectionId: undefined,
    homeLocationId: undefined,
    homeOfficeSquareFootage: undefined,
    isCurrentCommute: undefined,
    postalCode: undefined,
    averageCommuteDaysPerWeek: undefined,
    buildingTypeId: undefined
  });

  const handlePrevious = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
  };

  const handleNext = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
  };

  const handleLoadCommuteDetails = useCallback(async () => {
    if (params.commuteId) {
      const commute = await CommuteService.getCommuteDetails(params.commuteId);
      setActiveCommute(commute);
      const {
        homeLocation,
        officeCollectionId,
        name,
        homeOfficeSquareFootage,
        startDate,
        endDate,
        averageCommuteDaysPerWeek,
        buildingType
      } = commute ?? {};
      const {
        id: homeLocationId,
        country: homeOfficeCountry,
        ...homeLocationDetails
      } = homeLocation;
      const { name: homeOfficeName } = homeOfficeCountry;
      const country = meta.countries.find(
        (countryItem) => countryItem?.name === homeOfficeName
      );

      setFormValues({
        startDate: startDate
          ? formatInTimeZone(new Date(startDate), 'UTC', 'yyyy-MM-dd')
          : undefined,
        endDate: endDate
          ? formatInTimeZone(new Date(endDate), 'UTC', 'yyyy-MM-dd')
          : undefined,
        name,
        countryId: homeLocation?.country?.id,
        officeCollectionId,
        homeLocationId,
        isCurrentCommute: !endDate,
        averageCommuteDaysPerWeek,
        homeOfficeSquareFootage,
        buildingTypeId: buildingType?.id,
        isEstimationSupported: !!country?.isEstimationSupported,
        ...homeLocationDetails
      });
    }
  }, [params.commuteId]);

  const { isLoading: isLoadingCommutes } = useAsync({
    asyncFunction: handleLoadCommuteDetails
  });

  const handleAsyncFn = async (values) => {
    const commuteMethod = commuteId
      ? CommuteService.updateCommute
      : CommuteService.createCommute;

    if (commuteId) {
      values.id = commuteId;
      if (
        values.startDate !== activeCommute.startDate ||
        values.endDate !== activeCommute.endDate
      ) {
        values.clearEstimations = true;
      }
    }
    const location = await LocationService.createLocation({
      companySlug: me.company?.slug,
      location: values,
      isCollection: false
    });
    values.homeLocationId = location?.id;
    values.isWorkFromHome = true;
    const commute = await commuteMethod({
      companySlug: me.company.slug,
      commute: values,
      loggedInUserId
    });
    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Work From Home',
      commuteDetails: commute
    });
    handleNext();
  };
  const handleSubmit = async (values) => {
    if (commuteId) {
      Modal.confirm({
        className: 'ch-modal',
        icon: null,
        okText: 'Yes',
        cancelText: 'No',
        content: (
          <div>
            Editing existing commutes will change historical data, Add a new
            commute if you have changed routes. <br />
            <br /> Do you wish to proceed?
          </div>
        ),
        onOk: async () => await handleAsyncFn(values)
      });
    } else {
      await handleAsyncFn(values);
    }
  };
  const { isLoading: isSubmittingStep, execute } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });
  const openHelpDeskLink = () =>
    window.open(
      '//carbonhound.freshdesk.com/en/support/solutions/articles/72000565636-getting-started-for-employees',
      '_blank'
    );

  return (
    <Row className="employee-details-overview mb-4" gutter={[0, 16]}>
      <Col span={24}>
        <Title bottomSpacing={40} size="xl">
          Your home office
        </Title>
      </Col>
      <Loader isLoading={isLoadingCommutes || isSubmittingStep}>
        <Col span={24}>
          <Formik
            validationSchema={employeeDetailsWFHValidation}
            initialValues={formValues}
            enableReinitialize
            onSubmit={execute}
          >
            {({ isValid, values }) => (
              <Form>
                <Card>
                  <WFHFields />
                  <div className="mt-4">
                    <Blurb
                      titleProps={{
                        title: 'Quick tips for adding your home offices'
                      }}
                      buttonProps={{
                        text: 'Read support article',
                        type: BUTTON_TYPES.LINK,
                        onClick: openHelpDeskLink
                      }}
                    >
                      <ul className="blurb-list">
                        <li>
                          When entering your home office square footage only
                          enter the square footage of your office space, not
                          your entire home.
                        </li>
                        <li>
                          If you have changed your home address during your
                          tenure at your current company you can add your former
                          home offices as a separate item.
                        </li>
                        <li>
                          You can modify and delete your home offices at any
                          point.
                        </li>
                      </ul>
                    </Blurb>
                  </div>
                </Card>
                <Footer className="justify-end display-flex">
                  <Space>
                    <Button
                      type={BUTTON_TYPES.SECONDARY}
                      prefixIcon="ArrowLeft"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>

                    <Button
                      htmlType="submit"
                      disabled={!isValid || !values.isEstimationSupported}
                    >
                      Next
                    </Button>
                  </Space>
                </Footer>
              </Form>
            )}
          </Formik>
        </Col>
      </Loader>
    </Row>
  );
}
