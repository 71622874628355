import { Steps as AntSteps } from 'antd';
import { useEffect, useState } from 'react';
import { CHCContextProvider } from '../../../context';
import { Steps } from '../../../stories/atoms';
import StepsDot from '../../../stories/atoms/StepsDot';
import { CarbonConnectDetails } from '../CarbonhoundConnectDetails';
import { CarbonhoundConnectHistoricalImport } from '../CarbonhoundConnectHistoricalImport';
import { CarbonhoundConnectProviderCredentials } from '../CarbonhoundConnectProviderCredentials';
import { CarbonhoundConnectSuccess } from '../CarbonhoundConnectSuccess';
import { CarbonhoundConnectType } from '../CarbonhoundConnectType';

const typeSelectStep = {
  key: 0,
  dotTitle: 'Type',
  content: CarbonhoundConnectType
};

const integrationSteps = [
  {
    key: 1,
    dotTitle: 'Details',
    content: CarbonConnectDetails
  },
  {
    key: 2,
    dotTitle: 'Credentials',
    content: CarbonhoundConnectProviderCredentials
  },
  {
    key: 3,
    dotTitle: 'Historical Import',
    content: CarbonhoundConnectHistoricalImport
  },
  {
    key: 4,
    dotTitle: 'Complete',
    content: CarbonhoundConnectSuccess
  }
];
export function CarbonhoundConnectSteps({ onClose, integration }) {
  const { Step } = AntSteps;
  const [current, setCurrent] = useState(0);
  const [integrationStepsList, setIntegrationStepsList] =
    useState(integrationSteps);
  const [activeIntegration, setActiveIntegration] = useState(integration);
  const { slug } = integration ?? {};
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleNext = async () => {
    if (current === integrationStepsList.length - 2) {
      next();
    } else if (current === integrationStepsList.length - 1) {
      onClose?.(integration);
    } else {
      next();
    }
  };
  const StepComponent = integrationStepsList[current]?.content;

  useEffect(() => {
    if (!slug) {
      setIntegrationStepsList((prevState) => [typeSelectStep, ...prevState]);
    }
  }, [slug]);

  return (
    <div>
      <Steps
        labelPlacement="vertical"
        size="small"
        current={current}
        className="mb-5"
      >
        {integrationStepsList.map((item) => (
          <Step
            className="mb-7"
            key={item.key}
            title={<span className="no-wrap">{item.dotTitle}</span>}
            progressDot={(iconDot, { status, index }) => (
              <StepsDot iconDot={iconDot} status={status} index={index} />
            )}
          />
        ))}
      </Steps>
      <div>
        <CHCContextProvider>
          <StepComponent
            current={current}
            integrationSteps={integrationStepsList}
            prev={prev}
            handleNext={handleNext}
            integration={activeIntegration}
            setActiveIntegration={setActiveIntegration}
            onClose={onClose}
            analyticsContext={{
              level1: 'My Company',
              level2: 'Integrations',
              integrationName: 'CARBONHOUND CONNECT'
            }}
          />
        </CHCContextProvider>
      </div>
    </div>
  );
}
