import { Col, Modal as AntModal, Row, Space } from 'antd';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../../components';
import {
  OfficeTrail,
  OfficeTrailSegment,
  TargetType
} from '../../../constants';
import { useMeContext, useOfficeTrailContext } from '../../../context';
import { analytics, formatDecimal } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import {
  CompanyTrailService,
  DashboardService,
  TargetService
} from '../../../services';
import { Button, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { TargetSettingEmptyState } from '../index';
import TargetSummaryCard from '../TargetSummaryCard';
import './TargetSettingOverview.scss';
import { TargetSettingOverviewSummary } from './TargetSettingOverviewSummary';

export function TargetSettingOverview({ isReadOnly }) {
  const showSummaryItems = !isReadOnly;
  const [emissionsSum, setEmissionsSum] = useState();
  const { me, updateCompany } = useMeContext();
  const { refreshOfficeTrails } = useOfficeTrailContext();
  const { slug: companySlug, baseYear, startingMonth } = me?.company;
  const navigate = useNavigate();

  const handleManageTargets = () => {
    navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING);
  };

  const handleClear = () => {
    if (targetList.length <= 0) {
      return navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_BASELINE);
    }
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Continue',
      cancelText: 'Cancel',
      content:
        'If you change your baseline year all your goals will be cleared. This action cannot be undone.',
      onOk: () => {
        analytics.track('Edit Item Clicked', me, {
          level1: 'Office-Based Operations',
          level2: 'Set Targets',
          level3: 'Overview',
          level4: 'Baseline Year'
        });
        handleClearDetails();
      }
    });
  };

  const handleClearDetails = async () => {
    await TargetService.clearTargetDetails({
      companySlug
    });
    navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_BASELINE);
  };

  const handleFetchEmissionsSum = async () => {
    const month = startingMonth ? startingMonth - 1 : 0;
    const { emissions } = await DashboardService.fetchEmissionsSum({
      companySlug,
      rangeStart: formatInTimeZone(
        new Date(baseYear, month, 1),
        'UTC',
        'yyyy-MM-dd'
      ),
      rangeEnd: formatInTimeZone(
        new Date(baseYear + 1, month, 0),
        'UTC',
        'yyyy-MM-dd'
      )
    });

    const formattedEmissions = formatDecimal((emissions || 0) / 1000);
    setEmissionsSum(formattedEmissions);
    updateCompany({ baseYearEmissionsSum: formattedEmissions });
  };

  const handleNextStep = async () => {
    await CompanyTrailService.createCompanyTrail(me.company.slug, {
      trailName: OfficeTrail.COMPANY,
      segmentName: OfficeTrailSegment.TARGET,
      isComplete: true
    });
    await TargetService.commitAllTargets(me.company?.slug);
    analytics.track('Segment Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Set Targets',
      level3: 'Overview'
    });
    await refreshOfficeTrails();
    navigate(AppRoutes.ONBOARDING_ADMIN_COMPLETE);
  };

  const handleListTargets = useCallback(async () => {
    const { data } = await TargetService.listTargetDetails({
      companySlug: me?.company?.slug
    });
    return data;
  }, [me?.company?.slug]);

  const { value: targetList, Loader } = useAsync({
    asyncFunction: handleListTargets,
    defaultValue: []
  });

  const absoluteTargetList = targetList.filter(
    (target) => target.type === TargetType.ABSOLUTE
  );
  const intensityTargetList = targetList.filter(
    (target) => target.type === TargetType.INTENSITY
  );
  useEffect(() => {
    handleFetchEmissionsSum();
  }, [baseYear]);

  const isSegmentCompletionLocked = !targetList.length || !baseYear;
  return (
    <div
      className={classNames('target-setting-overview', {
        'read-only': isReadOnly
      })}
    >
      {!!baseYear || emissionsSum > 0 ? (
        <>
          {showSummaryItems && (
            <>
              <Title size="rg" bottomSpacing={40}>
                Target overview
              </Title>
              <p>
                You are about to em(bark) on a journey towards a healthier
                planet. We are so excited to be a part of your path towards a
                greener future.
              </p>
            </>
          )}
          <div className="mt-4 target-setting-overview__baseline">
            <Row align="middle">
              <Col span={12}>
                <Title size="rg" bottomSpacing={32}>
                  Target Baseline
                </Title>
              </Col>
              <Col className="justify-end display-flex" span={12}>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  onClick={showSummaryItems ? handleClear : handleManageTargets}
                >
                  {showSummaryItems ? 'Edit' : 'Manage'}
                </Button>
              </Col>
            </Row>
            {showSummaryItems ? (
              <Card className="mt-6">
                <TargetSettingOverviewSummary
                  emissionsSum={emissionsSum}
                  showSummaryItems={showSummaryItems}
                />
              </Card>
            ) : (
              <TargetSettingOverviewSummary
                className="mt-6"
                emissionsSum={emissionsSum}
                showSummaryItems={showSummaryItems}
              />
            )}
          </div>
          <Row align="middle" className="mt-6 ">
            <Col span={12}>
              <Title size="rg" bottomSpacing={32}>
                Targets
              </Title>
            </Col>
            {showSummaryItems && (
              <Col className="justify-end display-flex" span={12}>
                <Link
                  to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
                    ':targetDetailsId',
                    'new'
                  )}
                >
                  <Button
                    disabled={emissionsSum <= 0}
                    onClick={() => {
                      analytics.track('Add new Selected', me, {
                        level1: 'Office-Based Operations',
                        level2: 'Set Targets',
                        level3: 'Overview'
                      });
                    }}
                  >
                    Add Target
                  </Button>
                </Link>
              </Col>
            )}
          </Row>
          <Loader>
            {absoluteTargetList.map((target) =>
              showSummaryItems ? (
                <Card className="mt-6">
                  <TargetSummaryCard
                    showSummaryItems={showSummaryItems}
                    key={target.id}
                    target={target}
                  />
                </Card>
              ) : (
                <TargetSummaryCard
                  showSummaryItems={showSummaryItems}
                  key={target.id}
                  target={target}
                />
              )
            )}
            {intensityTargetList.map((target) =>
              showSummaryItems ? (
                <Card className="mt-6">
                  <TargetSummaryCard
                    showSummaryItems={showSummaryItems}
                    key={target.id}
                    target={target}
                  />
                </Card>
              ) : (
                <TargetSummaryCard
                  showSummaryItems={showSummaryItems}
                  key={target.id}
                  className="mt-6"
                  target={target}
                />
              )
            )}
            {showSummaryItems && (
              <div className="target-setting-overview__actions mt-6 text-right">
                <Space size={16}>
                  <Link to={AppRoutes.ONBOARDING_ADMIN_SOURCES}>
                    <Button type={BUTTON_TYPES.SECONDARY}>Back</Button>
                  </Link>
                  <Button
                    disabled={isSegmentCompletionLocked}
                    primary
                    small
                    onClick={handleNextStep}
                  >
                    Commit to your Targets
                  </Button>
                </Space>
              </div>
            )}
          </Loader>
        </>
      ) : (
        <TargetSettingEmptyState
          emissionsSum={emissionsSum}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
}
