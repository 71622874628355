import { Col, Row } from 'antd';
import sophiepantin from '../../assets/images/consultants/sophiepantin.png';
import saramiltenberger from '../../assets/images/consultants/saramiltenberger.png';
import chriscaners from '../../assets/images/consultants/chriscaners.png';
import { Card } from '../../components';
import { AppRoutes } from '../../routes';
import {
  Anchor,
  Breadcrumb,
  Button,
  Icon,
  Link,
  Page,
  Paragraph,
  Title
} from '../../stories/atoms';
import './Consultants.scss';
import { COLOURS_GRAY } from '../../constants/colours';

const routes = [
  {
    path: AppRoutes.HELP,
    breadcrumbName: 'Help'
  },
  {
    breadcrumbName: 'Consultants'
  }
];
function Consultants() {
  return (
    <Page className="ch-consultants">
      <Breadcrumb
        routes={routes}
        itemRender={(route) =>
          !!route.path ? (
            <Link to={route.path}>
              {route.breadcrumbName === 'Help' && (
                <Icon size={16} name="ChevronLeft" />
              )}
              {route.breadcrumbName}
            </Link>
          ) : (
            <span key={route.breadcrumbName}>{route.breadcrumbName}</span>
          )
        }
      />
      <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <Row justify="center">
            <Col span={24} className="mb-10">
              <Row justify="center">
                <Col span={24}>
                  <Title bottomSpacing={12} align="center" size="xl">
                    Connect with a consultant
                  </Title>
                </Col>
                <Col span={14}>
                  <Paragraph align="center" bottomSpacing={24}>
                    We make a conscious effort to match you with a consultant
                    that fits your specific needs. You can book some time with
                    us so we can match you with the best consultant.
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <Anchor
                    className="justify-center"
                    flex
                    href="https://meetings.hubspot.com/hannah472/carbonhound-contact-consultant-inbound"
                  >
                    <Button>Book Now</Button>
                  </Anchor>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Title size="lg" bottomSpacing={20} align="center">
                Our consultant spotlight
              </Title>
            </Col>
            <Col span={18}>
              <Row
                className="ch-consultants__profile-section"
                justify="center"
                gutter={[24, 24]}
              >
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Card>
                    <img
                      className="ch-consultants__profile-section__headshot"
                      alt="A headshot of Sophie Pantin"
                      src={sophiepantin}
                    />

                    <Title bottomSpacing={8} align="center" size="md">
                      Sophie Pantin
                    </Title>
                    <Title
                      align="center"
                      bottomSpacing={16}
                      color={COLOURS_GRAY.GRAY_500}
                      size="xs"
                    >
                      MAPPSC, CIVIL ENGINEERING
                    </Title>
                    <Paragraph bottomSpacing={24} align="center">
                      With over ten years of experience Sophie is an expert in
                      Sustainable development and climate change mitigation and
                      adaptation. She has spent a large amount of time working
                      specifically with municipalities and building out
                      environmental performance plans hand in hand with them.
                    </Paragraph>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Card>
                    <img
                      className="ch-consultants__profile-section__headshot"
                      alt="A headshot of Sara Miltenberger"
                      src={saramiltenberger}
                    />
                    <Title bottomSpacing={8} align="center" size="md">
                      Sara Miltenberger
                    </Title>
                    <Title
                      align="center"
                      bottomSpacing={16}
                      color={COLOURS_GRAY.GRAY_500}
                      size="xs"
                    >
                      MS, SUSTAINABILITY MANAGEMENT
                    </Title>
                    <Paragraph bottomSpacing={24} align="center">
                      Sara Miltenberger has made a name for herself in the
                      sustainability world of science and technology for the
                      past nine years. A Forbes Under 30 Scholar, she utilizes
                      creativity and communication to help clients develop
                      programs, procedures and goals to achieve environmental
                      leadership in their respective industries.
                    </Paragraph>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Card>
                    <img
                      className="ch-consultants__profile-section__headshot"
                      alt="A headshot of Chris Caners"
                      src={chriscaners}
                    />
                    <Title bottomSpacing={8} align="center" size="md">
                      Chris Caners
                    </Title>
                    <Title
                      align="center"
                      bottomSpacing={16}
                      color={COLOURS_GRAY.GRAY_500}
                      size="xs"
                    >
                      M.SC, MECHANICAL ENGINEER
                    </Title>
                    <Paragraph bottomSpacing={24} align="center">
                      With twenty years of experience with government and
                      private businesses, Chris has extensive knowledge of
                      energy efficiency and GFG mitigation as well as
                      measurement and emissions modeling. He has experience
                      leading energy and GHG audits, and teaches GHG reporting
                      at University of Toronto Continuing studies.
                    </Paragraph>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Page>
  );
}
export default Consultants;
