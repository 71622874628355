import { checkHttpSuccessStatus } from '../helpers';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';
import MeService from './meService';

class AuthService {
  static async login(user) {
    try {
      const response = await axiosInstance.post(ApiRoutes.LOGIN, user);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);

        const companies = await MeService.fetchMyCompanies();
        if (companies.length > 0) {
          return {
            token: response.data.token,
            companies
          };
        }
        return {
          token: response.data.token
        };
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  static async signUp({ groupSlug, user }) {
    try {
      const response = await axiosInstance.post(ApiRoutes.SIGNUP, {
        groupSlug,
        ...user
      });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  static async validateEmail(verificationCode, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.post(ApiRoutes.VALIDATE_EMAIL, {
        verificationCode
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async sendResetPasswordEmail({ groupSlug, email }) {
    try {
      const res = await axiosInstance.post(
        ApiRoutes.SEND_RESET_PASSWORD_EMAIL,
        {
          groupSlug,
          email
        }
      );
      if (!checkHttpSuccessStatus(+res.status)) {
        throw Error(res.status);
      }
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  static resetPassword(user, onSuccess, onError, onFinal) {
    axiosInstance
      .post(ApiRoutes.RESET_PASSWORD, user)
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        onSuccess?.(response.data);
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }

  static logout(onSuccess) {
    localStorage.clear();
    onSuccess?.();
  }

  static addUserToCompany({ verificationCode }, onSuccess, onError, onFinal) {
    axiosInstance
      .post(ApiRoutes.ADD_USER_TO_COMPANY, {
        verificationCode
      })
      .then(() => {
        onSuccess?.();
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }
}

export default AuthService;
