import { Col, Row, Space } from 'antd';
import { useNavigate } from 'react-router';
import { Card, InviteList } from '../../../../components';
import { OfficeTrail, OfficeTrailSegment } from '../../../../constants';
import { useMeContext, useOfficeTrailContext } from '../../../../context';
import InviteEmailPreviewButton from '../../../../features/EmployeeDetail/InviteEmailPreview';
import { AppRoutes } from '../../../../routes';
import { CommuteService, CompanyTrailService } from '../../../../services';
import { PawClipboard } from '../../../../stories/assets/svgs';
import { Button, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import './onboardingAdminInviteEmployees.scss';

export function OnboardingAdminInviteEmployees() {
  const { me } = useMeContext();
  const { groupSettings } = me;
  const { refreshOfficeTrails } = useOfficeTrailContext();
  const navigate = useNavigate();

  const handleDashboard = () => {
    navigate(AppRoutes.DASHBOARD);
  };
  const handleMyDetails = () => {
    navigate(AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW);
  };

  const handlePrevious = () => {
    navigate(AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING);
  };

  const handleNext = async () => {
    await CompanyTrailService.createCompanyTrail(me.company.slug, {
      trailName: OfficeTrail.OFFICE_ONBOARDING,
      segmentName: OfficeTrailSegment.COMMUTE,
      isComplete: true
    });
    await refreshOfficeTrails();
    navigate(AppRoutes.ONBOARDING_ADMIN_INVITE_TRAVEL_EXPENSE);
  };

  const submitFunction = async () => {
    await CommuteService.sendInviteBulk({
      companySlug: me.company.slug,
      groupSlug: groupSettings?.slug
    });
    await CompanyTrailService.createCompanyTrail(me.company.slug, {
      trailName: OfficeTrail.OFFICE_ONBOARDING,
      segmentName: OfficeTrailSegment.COMMUTE,
      isComplete: true
    });
    await refreshOfficeTrails();
  };

  return (
    <div className="admin-invite-employees__container">
      <Row gutter={[32, 32]}>
        <Col
          span={24}
          className="display-flex justify-space-between align-center"
        >
          <Space direction="vertical">
            <Title size="xl" bottomSpacing={0}>
              Onboarding your team
            </Title>
            <Paragraph bottomSpacing={0} size="sm">
              Ensure all of your employees are listed here so their information
              will be collected
            </Paragraph>
          </Space>
          <Button type={BUTTON_TYPES.SECONDARY} onClick={handleDashboard}>
            Save and return to dashboard
          </Button>
        </Col>
        <Col span={24}>
          <Card>
            <Row gutter={[0, 24]} className="my-details__container">
              <Col span={24}>
                <PawClipboard />
              </Col>
              <Col span={24}>
                <Title>Add your details first</Title>
                <Paragraph size="xs" bottomSpacing={0}>
                  Add your own commute, work from home, and non working periods
                  before you invite your team.
                </Paragraph>
              </Col>
              <Col span={24} className="my-details__button">
                <Button onClick={handleMyDetails}>Add my details</Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="admin-invite-employees__card">
            <InviteList />
          </Card>
        </Col>
      </Row>
      <Row className="admin-invite-employees__footer">
        <Col span={24} className="display-flex justify-end">
          <Space>
            <Button type={BUTTON_TYPES.SECONDARY} onClick={handlePrevious}>
              Back
            </Button>
            <Button type={BUTTON_TYPES.SECONDARY} onClick={handleNext}>
              Next
            </Button>
            <InviteEmailPreviewButton
              buttonProps={{
                text: 'Send Invites',
                type: BUTTON_TYPES.PRIMARY,
                onSubmit: submitFunction
              }}
            />
          </Space>
        </Col>
      </Row>
    </div>
  );
}
