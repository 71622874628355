import { Field, ErrorMessage } from 'formik';
import { Input } from 'antd';
import Error from '../Error';
import './inputField.scss';

function InputField(props) {
  const {
    name,
    type,
    title,
    mt,
    _rounded,
    classes,
    _addonBefore,
    _readOnly,
    _onChange,
    _value,
    _disabled,
    _prefix,
    _rows = 6
  } = props;

  return (
    <div
      className={classes || 'input-field-wrapper'}
      style={{ marginTop: `${mt}rem` }}
    >
      <div style={{ position: 'relative' }}>
        {title && <p className="input-field__title">{title}</p>}
        <Field
          className="input-field"
          as={type === 'textarea' ? Input.TextArea : Input}
          {...props}
        />
        <div className="input-field__gradient-bg" />
      </div>
      <ErrorMessage name={name}>
        {(message) => <Error className={`${name}__error`} message={message} />}
      </ErrorMessage>
    </div>
  );
}

export default InputField;
