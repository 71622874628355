import classNames from 'classnames';
import { forwardRef } from 'react';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { copyToClipboard, copyToClipboardFormatted } from '../../../helpers';
import { BUTTON_TYPES } from '../Button/Button.types';
import { Button, ButtonIcon } from '../index';

const CopyToClipboard = forwardRef(
  ({ className, text = '', name, copyFormatted }, ref) => {
    if (!!copyFormatted) {
      return (
        <Button
          htmlType="submit"
          type={BUTTON_TYPES.PRIMARY}
          onClick={() => copyToClipboardFormatted({ ref, name })}
        >
          Copy to Clipboard
        </Button>
      );
    }
    return (
      <ButtonIcon
        className={classNames('ch-copy-to-clipboard', {
          [className]: !!className
        })}
        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
        backgroundColour="transparent"
        name="ClipboardCopy"
        onClick={() => copyToClipboard({ ref, text })}
      />
    );
  }
);

export default CopyToClipboard;
