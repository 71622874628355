import { useCallback, useEffect } from 'react';
import { getDropdownOptions } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { ChcProviderService } from '../../../services';
import { Select } from '../../atoms';

function ProviderSelect({ integration, value, ...props }) {
  const { urlSlug } = integration;
  const handleLoadDropdown = useCallback(async () => {
    const resp = await ChcProviderService.listCHCProviders({
      integrationSlug: urlSlug
    });
    return getDropdownOptions(resp, 'name', 'id');
  }, []);

  const {
    value: providerList,
    isLoading: isProviderList,
    execute
  } = useAsync({
    asyncFunction: handleLoadDropdown,
    defaultValue: []
  });

  useEffect(() => {
    if (!!value) {
      execute();
    }
  }, [value]);

  return (
    <Select
      value={value}
      {...props}
      loading={isProviderList}
      options={providerList}
      showSearch
    >
      {!!props.children && props.children}
    </Select>
  );
}
export default ProviderSelect;
