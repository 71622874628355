import * as dateFns from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppHeader as PrimaryAppHeader } from '../../../components';
import { useMeContext } from '../../../context';
import { SubscriptionHeader } from '../../../features/Subscription';
import { AppRoutes } from '../../../routes';
import { AdminHeader } from '../index';

export function AppHeader() {
  const { isAppExpired, appAccessSubscription, me, isSuperUser } =
    useMeContext();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [expiryDateCount, setExpiryDateCount] = useState();
  const { company } = me;
  const location = useLocation();

  let expiredAppAccessSubscription;
  let isFreeTrial = appAccessSubscription?.isFreeTrial;

  if (!appAccessSubscription) {
    expiredAppAccessSubscription = company?.subscriptions?.find(
      (sub) => sub.type === 'APP_ACCESS'
    );
    isFreeTrial =
      !expiredAppAccessSubscription || expiredAppAccessSubscription.isFreeTrial;
  }
  const getSubscriptionStatus = useCallback(() => {
    if (isAppExpired) {
      setExpiryDateCount(undefined);
      // If unexpired sub not found, look for an expired one to check if its a free trial or not
      return 'Expired';
    }
    const { expiryDate } = appAccessSubscription;

    if (isFreeTrial && expiryDate) {
      setExpiryDateCount(
        Math.abs(dateFns.differenceInDays(new Date(expiryDate), new Date())) + 1
      );
      return `${expiryDateCount} Days Left`;
    }
  }, [appAccessSubscription, expiryDateCount, isAppExpired, isFreeTrial]);
  useEffect(() => {
    setSubscriptionStatus(getSubscriptionStatus());
  }, [getSubscriptionStatus]);
  let HeaderComponent = PrimaryAppHeader;
  if (location.pathname.startsWith(AppRoutes.CHC_ADMIN)) {
    HeaderComponent = AdminHeader;
  } else if (
    isAppExpired &&
    !process.env.REACT_APP_BYPASS_SUBSCRIPTIONS &&
    !isSuperUser()
  ) {
    HeaderComponent = SubscriptionHeader;
  }
  return (
    <HeaderComponent
      subscriptionStatus={subscriptionStatus}
      expiryDateCount={expiryDateCount}
    />
  );
}
