import { Col, Row, Space } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { COLOURS_GRAY } from '../../../constants/colours';
import { preventOnEnter } from '../../../helpers/eventListenerHelper';
import {
  Button,
  ButtonIcon,
  Input,
  Paragraph,
  Title
} from '../../../stories/atoms';

function YourOrganization() {
  const { values, setFieldValue } = useFormikContext();

  const handleAddBusinessUnitField = () => {
    const updatedBusinessUnitFields = [
      ...values.businessUnitFields,
      { name: '' }
    ];
    setFieldValue('businessUnitFields', updatedBusinessUnitFields);
  };

  const handleDeleteBusinessUnitField = (index) =>
    setFieldValue(
      'businessUnitFields',
      values.businessUnitFields
        .slice(0, index)
        .concat(values.businessUnitFields.slice(index + 1))
    );

  return (
    <div>
      <Row>
        <Col span={24}>
          <Title size="lg">Configure your organization</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row className="display-flex align-center">
            <Col span={22}>
              <Space align="center">
                <Title size="md">Setup your business units</Title>
                <Paragraph>(optional)</Paragraph>
              </Space>
              <Paragraph size="sm">
                Business units are divisions within your company that you can
                assign data to. If you don’t have any you can skip this section.
              </Paragraph>
            </Col>
            <Col span={2}>
              <Space>
                <Button onClick={handleAddBusinessUnitField}>Add</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <FieldArray name="businessUnitFields">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { businessUnitFields } = form.values;
                  return (
                    <div>
                      {businessUnitFields?.map((_, fieldIndex) => (
                        <Row className="mb-2">
                          <Col span={23}>
                            <Input
                              type="text"
                              name={`businessUnitFields[${fieldIndex}].name`}
                              placeholder="Enter Name"
                              onKeyPress={preventOnEnter}
                            />
                          </Col>
                          <Col span={1} className="display-flex align-center">
                            <div className="ml-1">
                              <ButtonIcon
                                onClick={() =>
                                  handleDeleteBusinessUnitField(fieldIndex)
                                }
                                name="MinusCircle"
                                backgroundColour="transparent"
                                color={COLOURS_GRAY.GRAY_400}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  );
                }}
              </FieldArray>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default YourOrganization;
