import * as Yup from 'yup';
import {
  DISTANCE_CALCULATION_TYPE,
  TransportationType
} from '../../../constants';
import {
  defaultDistanceMetrics,
  defaultToFromAirportMetrics,
  defaultToFromMetrics
} from '../DistanceCalculationTypeFields';

export const travelItemFormValidation = Yup.object().shape({
  userCompanyId: Yup.string().nullable().required('Required'),
  date: Yup.string().nullable().required('Required'),
  travelCategoryId: Yup.string()
    .nullable()
    .notOneOf(['Unknown'])
    .required('Required'),
  travelCategoryName: Yup.string().nullable(),
  // Personal Vehicle Fields
  isRental: Yup.boolean().when(
    ['travelCategoryName', 'emissionSourceDetailsId'],
    {
      is: (travelCategoryName, emissionSourceDetailsId) =>
        travelCategoryName === TransportationType.CAR &&
        !emissionSourceDetailsId,
      then: Yup.boolean().nullable().required('Required'),
      otherwise: Yup.boolean().nullable()
    }
  ),
  vehicleTypeId: Yup.string().when(['isRental', 'emissionSourceDetailsId'], {
    is: (isRental, emissionSourceDetailsId) =>
      isRental && !emissionSourceDetailsId,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  fuelTypeId: Yup.string().when(['isRental', 'emissionSourceDetailsId'], {
    is: (isRental, emissionSourceDetailsId) =>
      isRental && !emissionSourceDetailsId,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  vehicleId: Yup.string().when(
    ['travelCategoryName', 'isRental', 'emissionSourceDetailsId'],
    {
      is: (travelCategoryName, isRental, emissionSourceDetailsId) =>
        defaultDistanceMetrics.includes(travelCategoryName) &&
        !isRental &&
        !emissionSourceDetailsId,
      then: Yup.string().nullable().required('Required'),
      otherwise: Yup.string().nullable()
    }
  ),
  distance: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  conversionUnitName: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  departureLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  destinationLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  isRoundTrip: Yup.boolean().when('travelCategoryName', {
    is: (travelCategoryName) =>
      defaultToFromAirportMetrics.includes(travelCategoryName) ||
      defaultToFromMetrics.includes(travelCategoryName),
    then: Yup.boolean().nullable().required('Required'),
    otherwise: Yup.boolean().nullable()
  }),
  departureAirport: Yup.object().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.CIRCLE_GEOMETRY,
    then: Yup.object().test(
      'departureAirport',
      'Required',
      (airport) => !!airport?.latitude && !!airport?.longitute
    ),
    otherwise: Yup.object().nullable()
  }),
  destinationAirport: Yup.object().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.CIRCLE_GEOMETRY,
    then: Yup.object().test(
      'destinationAirport',
      'Required',
      (airport) => !!airport?.latitude && !!airport?.longitute
    ),
    otherwise: Yup.object().nullable()
  }),
  isEstimate: Yup.boolean().nullable().required('Required')
});
