import { Pagination as AntPagination } from 'antd';
import './Pagination.scss';

function Pagination({ pagination, total }) {
  const { pageSize, pageNumber, onChange } = pagination;
  return (
    <AntPagination
      pageSize={pageSize}
      current={pageNumber}
      onChange={(targetPageNumber) => {
        onChange?.(targetPageNumber, pageNumber);
      }}
      total={total}
      showSizeChanger={false}
      simple
      hideOnSinglePage
    />
  );
}
export default Pagination;
