import { SourceCategoryTag, TransportationType } from '../../../constants';
import {
  COLOURS_AMBER,
  COLOURS_BLUE,
  COLOURS_CYAN,
  COLOURS_EMERALD,
  COLOURS_FUSCHIA,
  COLOURS_GRAY,
  COLOURS_GREEN,
  COLOURS_INDIGO,
  COLOURS_LIME,
  COLOURS_ORANGE,
  COLOURS_PINK,
  COLOURS_PURPLE,
  COLOURS_RED,
  COLOURS_ROSE,
  COLOURS_TEAL,
  COLOURS_VIOLET,
  COLOURS_YELLOW
} from '../../../constants/colours';

/*
 *
 * Constants relating to category charts
 *
 * */

const CHART_CATEGORY_COLOURS = {
  GREEN: COLOURS_GREEN.GREEN_400,
  RED: COLOURS_RED.RED_400,
  DARK_RED: COLOURS_RED.RED_700,
  BLUE: COLOURS_BLUE.BLUE_500,
  VIOLET: COLOURS_VIOLET.VIOLET_500,
  INDIGO: COLOURS_INDIGO.INDIGO_400,
  YELLOW: COLOURS_YELLOW.YELLOW_400,
  ORANGE: COLOURS_ORANGE.ORANGE_400,
  GRAY: COLOURS_GRAY.GRAY_400,
  FUCHSIA: COLOURS_FUSCHIA.FUCHSIA_400,
  PINK: COLOURS_PINK.PINK_400,
  CYAN: COLOURS_CYAN.CYAN_400,
  TEAL: COLOURS_TEAL.TEAL_400,
  STRAWBERRY: '#FF9CB9',
  LIME: COLOURS_LIME.LIME_400,
  DESERT: '#E2A37C',
  EMERALD: COLOURS_EMERALD.EMERALD_900,
  ROSE: COLOURS_ROSE.ROSE_400,
  AMBER: COLOURS_AMBER.AMBER_400,
  NAVY: '#CDCDFF',
  POPSICLE: '#E9CCF8',
  DARK_GREEN: '#059669',
  DARK_BLUE: COLOURS_BLUE.BLUE_600
};
export const CategoryColours = {
  [SourceCategoryTag.BUSINESS_TRAVEL]: CHART_CATEGORY_COLOURS.CYAN,
  [SourceCategoryTag.CAPITAL_GOODS]: CHART_CATEGORY_COLOURS.YELLOW,
  [SourceCategoryTag.DOWNSTREAM_LEASED_ASSETS]: CHART_CATEGORY_COLOURS.DESERT,
  [SourceCategoryTag.DOWNSTREAM_TRANSPORTATION_DISTRIBUTION]:
    CHART_CATEGORY_COLOURS.ROSE,
  [SourceCategoryTag.EMPLOYEE_COMMUTING]: CHART_CATEGORY_COLOURS.PINK,
  [SourceCategoryTag.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]:
    CHART_CATEGORY_COLOURS.AMBER,
  [SourceCategoryTag.FRANCHISES]: CHART_CATEGORY_COLOURS.POPSICLE,
  [SourceCategoryTag.FUEL_ENERGY_RELATED_ACTIVITIES]:
    CHART_CATEGORY_COLOURS.ORANGE,
  [SourceCategoryTag.FUGITIVE_EMISSIONS]: CHART_CATEGORY_COLOURS.RED,
  [SourceCategoryTag.IMPORTED_ENERGY]: CHART_CATEGORY_COLOURS.VIOLET,
  [SourceCategoryTag.INVESTMENTS]: CHART_CATEGORY_COLOURS.GRAY,
  [SourceCategoryTag.MOBILE_COMBUSTION]: CHART_CATEGORY_COLOURS.BLUE,
  [SourceCategoryTag.OFFSETS]: CHART_CATEGORY_COLOURS.EMERALD,
  [SourceCategoryTag.PROCESSING_OF_SOLD_PRODUCTS]: CHART_CATEGORY_COLOURS.LIME,
  [SourceCategoryTag.PURCHASED_GOODS_SERVICES]: CHART_CATEGORY_COLOURS.INDIGO,
  [SourceCategoryTag.STATIONARY_COMBUSTION]: CHART_CATEGORY_COLOURS.GREEN,
  [SourceCategoryTag.UPSTREAM_LEASED_ASSETS]: CHART_CATEGORY_COLOURS.STRAWBERRY,
  [SourceCategoryTag.UPSTREAM_TRANSPORTATION_DISTRIBUTION]:
    CHART_CATEGORY_COLOURS.DARK_RED,
  [SourceCategoryTag.USE_OF_SOLD_PRODUCTS]: CHART_CATEGORY_COLOURS.NAVY,
  [SourceCategoryTag.WASTE_GENERATED_IN_OPERATIONS]:
    CHART_CATEGORY_COLOURS.FUCHSIA,
  [SourceCategoryTag.NON_MECHANICAL]: CHART_CATEGORY_COLOURS.DARK_BLUE
};

/*
 *
 * Constants relating to travel charts
 *
 * */

const CHART_TRAVEL_COLOURS = {
  [TransportationType.PLANE]: COLOURS_GREEN.GREEN_400,
  [TransportationType.MOTORBIKE]: COLOURS_BLUE.BLUE_400,
  [TransportationType.CAR]: COLOURS_RED.RED_400,
  [TransportationType.INTERCITY_TRAIN]: COLOURS_VIOLET.VIOLET_400,
  [TransportationType.LOCAL_BUS]: COLOURS_YELLOW.YELLOW_400
};

export const TransportationTypeColours = {
  Flights: CHART_TRAVEL_COLOURS[TransportationType.PLANE],
  Motorbike: CHART_TRAVEL_COLOURS[TransportationType.MOTORBIKE],
  Car: CHART_TRAVEL_COLOURS[TransportationType.CAR],
  'Intercity Train': CHART_TRAVEL_COLOURS[TransportationType.INTERCITY_TRAIN],
  'Local Bus': CHART_TRAVEL_COLOURS[TransportationType.LOCAL_BUS]
};

/*
 *
 * Constants relating to scope charts
 *
 * */

export const CHART_SCOPE_COLOURS = {
  SCOPE_ONE: COLOURS_GREEN.GREEN_500,
  SCOPE_TWO: COLOURS_ORANGE.ORANGE_400,
  SCOPE_THREE: COLOURS_PURPLE.PURPLE_800
};

export const ScopeColours = {
  'Scope 1 - Direct Emissions': CHART_SCOPE_COLOURS.SCOPE_ONE,
  'Scope 2 - Indirect (Grid energy)': CHART_SCOPE_COLOURS.SCOPE_TWO,
  'Scope 3 - Indirect Emissions': CHART_SCOPE_COLOURS.SCOPE_THREE
};
