import { format } from 'date-fns';
import DateFormats from '../constants/dateFormats';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class ReportingService {
  static async getEmissionSourceSummary({ companySlug, ...params }) {
    try {
      let formattedRangeEnd = params.rangeEnd;
      if (formattedRangeEnd instanceof Date) {
        formattedRangeEnd = format(new Date(params.rangeEnd), DateFormats.API);
      }
      let formattedRangeStart = params.rangeStart;
      if (formattedRangeStart instanceof Date) {
        formattedRangeStart = format(
          new Date(params.rangeStart),
          DateFormats.API
        );
      }
      const response = await axiosInstance.get(
        ApiRoutes.REPORTING_CHART.replace(':companySlug', companySlug),
        {
          params: {
            ...params,
            rangeEnd: formattedRangeEnd,
            rangeStart: formattedRangeStart
          }
        }
      );

      return response.data.emissionChart;
    } catch (error) {
      console.error(error);
    }
  }

  static async listEmissionSourceDetails({ companySlug, ...params }) {
    let formattedRangeEnd = params.rangeEnd;
    if (formattedRangeEnd) {
      formattedRangeEnd = format(
        new Date(params.rangeEnd),

        DateFormats.API
      );
    }
    let formattedRangeStart = params.rangeStart;
    if (formattedRangeStart) {
      formattedRangeStart = format(
        new Date(params.rangeStart),

        DateFormats.API
      );
    }
    try {
      const response = await axiosInstance.get(
        ApiRoutes.REPORTING_SUMMARY.replace(':companySlug', companySlug),
        {
          params: {
            ...params,
            rangeEnd: formattedRangeEnd,
            rangeStart: formattedRangeStart
          }
        }
      );

      return response.data.emissionList;
    } catch (error) {
      console.error(error);
    }
  }
}

export default ReportingService;
