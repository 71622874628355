import { Col, Divider, Row } from 'antd';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import { useModal } from '../../hooks';
import { Anchor, Icon, Paragraph, Title } from '../../stories/atoms';
import CreditsOffsetsExplaination from '../CreditsOffsetsExplaination';
import './CreditsProject.scss';

function CreditsProject({ project }) {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const {
    Modal: InformationModal,
    handleShowModal: handleShowInformationModal
  } = useModal({
    width: '80%'
  });
  return (
    <Row className="credits-project" justify="center">
      <div
        className="credits-project__masthead"
        style={{ backgroundImage: `url(${project.photos[0]?.url})` }}
      />
      <Col xs={20} xl={15}>
        <Row className="credits-project__copy">
          <Col span={24}>
            <Title align="center" size="xl">
              {project.name}
            </Title>
          </Col>
          <Col className="credits-project__fact-list" span={24}>
            {project.technology_type.name && (
              <Row className="credits-project__fact-list__item">
                <Col span={10}>
                  <Paragraph includeSpacing={false}>Technology</Paragraph>
                </Col>
                <Col span={14}>
                  <Paragraph includeSpacing={false}>
                    {project.technology_type.name}
                  </Paragraph>
                </Col>
              </Row>
            )}
            <Row className="credits-project__fact-list__item">
              <Col span={10}>
                <Paragraph includeSpacing={false}>Mechanism</Paragraph>
              </Col>
              <Col span={14}>
                <Paragraph bottomSpacing={8} className="text-bd">
                  Removals
                </Paragraph>
                <div
                  className="display-flex justify-space-between align-center credits-project__fact-list__item--link"
                  onClick={handleShowInformationModal}
                >
                  <Paragraph includeSpacing={false}>
                    Learn more about our removal only approach
                  </Paragraph>

                  <Icon
                    name="ArrowRight"
                    color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                  />
                </div>
              </Col>
            </Row>
            {project.standard && (
              <Row className="credits-project__fact-list__item">
                <Col span={10}>
                  <Paragraph includeSpacing={false}>Standard</Paragraph>
                </Col>
                <Col span={14}>
                  <Paragraph bottomSpacing={8} className="text-bd">
                    {project.standard?.acronym}
                  </Paragraph>

                  <Paragraph>{project.standard?.description}</Paragraph>
                </Col>
              </Row>
            )}
            <Row className="credits-project__fact-list__item">
              <Col span={10}>
                <Paragraph includeSpacing={false}>Developer</Paragraph>
              </Col>
              <Col span={14}>
                <Paragraph includeSpacing={false}>
                  {project.developer}
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Row gutter={[0, 32]} className="credits-project__indent ">
            <Col span={24}>
              <Paragraph>{project.tagline}</Paragraph>
            </Col>
            <Col span={24}>
              <Paragraph>{project.description}</Paragraph>
            </Col>
            <Col span={24}>
              <Title size="xl">Project highlights </Title>
              <Row gutter={[0, 8]} className="credits-project__highlights">
                {project.highlights.map((hl) => (
                  <Col span={24}>
                    <Paragraph
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      includeSpacing={false}
                      className="credits-project__highlights__item text-md"
                    >
                      {hl.title}
                    </Paragraph>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={24}>
              <Title size="xl">UN Sustainable Development Goals</Title>
              <Row gutter={[16, 16]} className="credits-project__goal-list">
                {project.sdgs.map((sdg) => (
                  <Col
                    flex="0 1 0"
                    className="credits-project__goal-list__item"
                  >
                    <div>
                      <img
                        src={`https://storage.googleapis.com/${process.env.REACT_APP_GCP_PUBLIC_BUCKET}/sustainable-development-goals/${sdg.number}.png`}
                        alt={`Sustainable Development Goal ${sdg.number}: ${sdg.title}`}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
              <Divider />
            </Col>
            <Col span={24}>
              <Title size="xl">Where is it located?</Title>
              <Paragraph>{regionNames.of(project.country)}</Paragraph>
            </Col>
            <Col span={24}>
              <Title size="xl">Questions?</Title>
              <Paragraph>
                Visit our{' '}
                <Anchor
                  href="//carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4?pvs=4"
                  className="text-bd"
                >
                  FAQ
                </Anchor>
                , or{' '}
                <Anchor
                  href="//meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
                  className="text-bd"
                >
                  get in touch with us
                </Anchor>
              </Paragraph>
            </Col>
          </Row>
        </Row>
      </Col>
      <InformationModal>
        <CreditsOffsetsExplaination />
      </InformationModal>
    </Row>
  );
}
export default CreditsProject;
