import React from 'react';
import { Logo } from '../../stories/atoms';
import './significanceCard.scss';

function SignificanceCard({ showLogo = true }) {
  const significanceDetails = [
    {
      title: 'Increase your revenue',
      description:
        'Access growing sustainably marketed product and service markets through our certifications'
    },
    {
      title: 'Decrease your costs',
      description:
        'Our carbon reduction initiatives help reduce operational costs by 23% on average'
    },
    {
      title: 'Attract and retain talent',
      description:
        'Values based organizations recruit talent less expensively and retain them longer'
    }
  ];

  return (
    <div className="significance-card">
      {showLogo ? (
        <Logo className="significance-card__logo" />
      ) : (
        <div style={{ marginBottom: '100px' }} />
      )}
      {significanceDetails.map(({ title, description }, i) => (
        <div className="significance-card__significance-item" key={i}>
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      ))}
    </div>
  );
}

export default SignificanceCard;
