import { Layout } from 'antd';
import classNames from 'classnames';
import './Content.scss';

const { Content: AntContent } = Layout;

export function Content({ children, className }) {
  return (
    <AntContent
      className={classNames('ch-layout-content', {
        [className]: !!className
      })}
    >
      {children}
    </AntContent>
  );
}
