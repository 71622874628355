import { Notification } from '../components';
import { TargetType } from '../constants';
import { formatDecimal } from '../helpers';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class TargetService {
  static async getTargetDetails({ companySlug, targetDetailsId }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );

      const response = await axiosInstance.get(`${API_URL}/${targetDetailsId}`);

      return { success: true, data: response.data || {} };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async listTargetDetails({ companySlug, ...params }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );

      const response = await axiosInstance.post(
        `${API_URL}/list`,
        {},
        { params }
      );
      return { success: true, data: response.data?.details || {} };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async createTargetDetails({ companySlug, ...params }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      const { response } = error;
      if (response?.status === 409) {
        const message =
          params.targetDetails?.type === TargetType.ABSOLUTE
            ? 'You can only have one Absolute target.'
            : 'You can only have one of each type of Intensity target.';

        Notification({
          type: 'error',
          message
        });
      }

      return { success: false, error };
    }
  }

  static async updateTargetDetails({ companySlug, ...params }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.put(`${API_URL}/${params.id}`, {
        ...params
      });

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async clearTargetDetails({ companySlug }) {
    const API_URL = ApiRoutes.COMPANY_TARGET.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = await axiosInstance.delete(`${API_URL}/clear`);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async commitAllTargets(companySlug) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.put(`${API_URL}/commit/all`);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async fetchTargetDetailsSummary({ companySlug, ...params }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.get(`${API_URL}/emissions/chart`, {
        params
      });

      const respArray = response.data?.targetSummary.map((summary) => {
        if (summary.type === TargetType.ABSOLUTE) {
          summary.targetEmissions = summary.targetEmissions?.map(
            (emissions) => {
              emissions.targetEmissions = formatDecimal(
                Number(emissions.targetEmissions) / 1000
              );
              return emissions;
            }
          );
        }
        return summary;
      });
      return { success: true, data: respArray || [] };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async createOrUpdateTargetItem({
    companySlug,
    targetDetailsId,
    items
  }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(
        `${API_URL}/${targetDetailsId}/item/bulk`,
        { items }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async listTargetItems({ companySlug, targetDetailsId }) {
    try {
      const API_URL = ApiRoutes.COMPANY_TARGET.replace(
        ':companySlug',
        companySlug
      );

      const response = await axiosInstance.post(
        `${API_URL}/${targetDetailsId}/item/list`
      );
      return { success: true, data: response.data?.details || [] };
    } catch (error) {
      return { success: false, error };
    }
  }
}

export default TargetService;
