import React from 'react';
import {
  UserProfileCommute,
  UserProfileDetails
} from '../../features/UserProfile';
import { TabPane, Tabs } from '../../stories/atoms';
import './userProfile.scss';

function UserProfile() {
  return (
    <div className="user-profile">
      <h4 className="text-bd">My Account</h4>
      <Tabs>
        <TabPane tab="My Profile" key="1">
          <UserProfileDetails />
        </TabPane>
        <TabPane tab="Commute and Work from Home in My Profile" key="2">
          <UserProfileCommute />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserProfile;
