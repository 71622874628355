function ChLogoIconText({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 810 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.38 56.1266C100.354 56.1266 94.6669 61.8867 94.6669 68.9892C94.6669 76.0901 100.354 81.8499 107.38 81.8499C114.391 81.8499 120.076 76.0901 120.076 68.9892C120.076 61.8867 114.391 56.1266 107.38 56.1266Z"
        fill="url(#paint0_linear_233_699)"
      />
      <path
        d="M41.2139 68.9892C41.2139 61.8867 35.5267 56.1266 28.5096 56.1266C21.4874 56.1266 15.8014 61.8867 15.8014 68.9892C15.8014 76.0901 21.4874 81.8499 28.5096 81.8499C35.5267 81.8499 41.2139 76.0901 41.2139 68.9892Z"
        fill="url(#paint1_linear_233_699)"
      />
      <path
        d="M48.234 54.3504C56.8174 54.3504 63.7726 47.3053 63.7726 38.6203C63.7726 29.9288 56.8174 22.8865 48.234 22.8865C39.6529 22.8865 32.6954 29.9288 32.6954 38.6203C32.6954 47.3053 39.6529 54.3504 48.234 54.3504Z"
        fill="url(#paint2_linear_233_699)"
      />
      <path
        d="M85.7094 59.5929C87.9143 55.4964 92.5204 54.0231 95.035 52.4593C99.8894 49.7981 103.183 44.5982 103.183 38.6202C103.183 29.9288 96.2273 22.8865 87.6455 22.8865C79.0633 22.8865 72.1053 29.9288 72.1053 38.6202C72.1053 41.9917 73.1636 45.1086 74.9499 47.67L74.9349 47.6661C78.0081 52.4825 78.5911 56.1241 78.2257 58.3463C77.6829 61.6348 76.0866 65.1615 67.7105 65.1615H67.7129L52.6851 65.1607L37.2695 92.1929L52.6851 119.227H83.5191L98.9352 92.1929L86.9956 71.2577C83.6413 65.5618 85.0291 60.8519 85.7094 59.5929V59.5929Z"
        fill="url(#paint3_linear_233_699)"
      />
      <g clipPath="url(#clip0_233_699)">
        <path
          d="M180.544 94.0289C183.04 94.0289 185.376 93.5314 187.552 92.5322C189.728 91.5373 191.814 89.9654 193.809 87.8264L202.581 96.9184C199.726 100.057 196.304 102.498 192.314 104.243C188.319 105.991 184.183 106.864 179.905 106.864C175.842 106.864 172.006 106.114 168.405 104.619C164.804 103.121 161.704 101.085 159.099 98.5212C156.496 95.9569 154.447 92.9048 152.949 89.3767C151.451 85.8444 150.704 82.0501 150.704 77.9853C150.704 73.9196 151.451 70.1211 152.949 66.593C154.447 63.0634 156.496 59.9964 159.099 57.3934C161.704 54.7903 164.804 52.7398 168.405 51.242C172.006 49.7455 175.842 48.9966 179.905 48.9966C184.323 48.9966 188.568 49.8692 192.635 51.6171C196.699 53.3636 200.157 55.8069 203.009 58.9437L194.022 68.4632C192.028 66.2527 189.888 64.5774 187.605 63.4374C185.325 62.2971 182.862 61.7242 180.225 61.7242C176.017 61.7242 172.471 63.296 169.581 66.4315C166.696 69.5711 165.248 73.418 165.248 77.9853C165.248 82.5475 166.728 86.3661 169.689 89.4277C172.647 92.4972 176.268 94.0288 180.544 94.0288L180.544 94.0289Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.821 106.757C221.832 106.757 216.948 105.169 213.168 101.998C209.387 98.8248 207.499 94.7092 207.499 89.6456C207.499 84.3654 209.53 80.2295 213.597 77.235C217.66 74.2421 223.259 72.7457 230.387 72.7457C232.67 72.7457 234.951 72.9246 237.236 73.2768C239.516 73.6371 241.762 74.1681 243.972 74.881V70.9252C243.972 67.7156 242.974 65.2941 240.98 63.6482C238.982 62.0106 236.058 61.1905 232.208 61.1905C229.855 61.1905 227.304 61.5628 224.56 62.3132C221.814 63.0634 218.694 64.2211 215.202 65.7916L209.85 54.9852C214.199 52.9913 218.427 51.492 222.525 50.4944C226.627 49.4953 230.673 48.9966 234.665 48.9966C242.155 48.9966 247.986 50.7982 252.158 54.3978C256.326 57.9998 258.415 63.0794 258.415 69.6398V105.794H243.972V101.943C241.619 103.585 239.144 104.798 236.539 105.581C233.936 106.367 231.032 106.757 227.821 106.757L227.821 106.757ZM221.513 89.3228C221.513 91.4621 222.437 93.1547 224.291 94.4026C226.149 95.6529 228.605 96.2756 231.672 96.2756C234.098 96.2756 236.329 95.9731 238.359 95.3666C240.391 94.7616 242.264 93.8891 243.973 92.7474V84.4016C242.19 83.6892 240.319 83.1701 238.359 82.8513C236.394 82.5313 234.347 82.3688 232.209 82.3688C228.856 82.3688 226.235 82.9953 224.346 84.2403C222.455 85.488 221.513 87.1835 221.513 89.3228Z"
          fill="black"
        />
        <path
          d="M269.544 105.794V50.068H284.197V56.1616C285.978 53.7402 288.135 51.9006 290.67 50.653C293.198 49.4066 295.999 48.7826 299.064 48.7826C300.488 48.8526 301.685 48.9802 302.648 49.1563C303.61 49.3338 304.449 49.639 305.162 50.068V62.9021C304.093 62.4019 302.915 62.0268 301.634 61.778C300.35 61.5291 299.029 61.4055 297.675 61.4055C294.822 61.4055 292.2 62.1182 289.812 63.5447C287.422 64.9713 285.551 67.0366 284.197 69.7472V105.794L269.544 105.794Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M371.684 77.9849C371.684 81.9782 370.935 85.7189 369.436 89.2149C367.939 92.7107 365.908 95.7373 363.338 98.3109C360.774 100.874 357.724 102.892 354.194 104.351C350.666 105.812 346.901 106.545 342.911 106.545C340.13 106.545 337.42 106.15 334.782 105.367C332.143 104.581 329.721 103.479 327.511 102.05V105.793H313.073V30.9183L327.723 27.7102V54.4509C329.931 52.8105 332.374 51.5466 335.046 50.6527C337.72 49.7651 340.484 49.3174 343.338 49.3174C347.26 49.3174 350.948 50.0677 354.407 51.5641C357.868 53.0592 360.879 55.0937 363.45 57.6591C366.014 60.2258 368.029 63.2579 369.492 66.7537C370.951 70.2457 371.684 73.9889 371.684 77.9849L371.684 77.9849ZM340.989 61.8327C338.348 61.8327 335.889 62.2791 333.61 63.168C331.327 64.0593 329.365 65.3609 327.726 67.0726V89.0039C329.296 90.5701 331.238 91.8207 333.555 92.7471C335.87 93.6749 338.348 94.136 340.989 94.136C345.551 94.136 349.402 92.5857 352.54 89.4797C355.676 86.3805 357.249 82.5472 357.249 77.985C357.249 73.4176 355.657 69.5856 352.485 66.485C349.311 63.383 345.479 61.8327 340.989 61.8327Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.531 77.9853C378.531 73.9196 379.296 70.1211 380.828 66.593C382.365 63.0634 384.466 59.9964 387.14 57.3934C389.817 54.7903 392.972 52.7398 396.609 51.242C400.24 49.7455 404.13 48.9966 408.265 48.9966C412.402 48.9966 416.289 49.7455 419.925 51.242C423.563 52.7398 426.716 54.7903 429.392 57.3934C432.065 59.9964 434.171 63.0635 435.703 66.593C437.234 70.121 438.001 73.9196 438.001 77.9853C438.001 82.0501 437.234 85.8444 435.703 89.3767C434.171 92.9048 432.065 95.9568 429.392 98.5212C426.716 101.085 423.563 103.121 419.925 104.619C416.289 106.114 412.402 106.864 408.265 106.864C404.13 106.864 400.24 106.114 396.609 104.619C392.972 103.121 389.817 101.085 387.14 98.5212C384.466 95.9569 382.365 92.9048 380.828 89.3767C379.296 85.8444 378.531 82.0501 378.531 77.9853ZM408.261 94.1367C412.541 94.1367 416.179 92.5689 419.172 89.428C422.169 86.2952 423.666 82.4767 423.666 77.9856C423.666 73.4183 422.169 69.5714 419.172 66.4318C416.179 63.2963 412.54 61.7245 408.261 61.7245C403.983 61.7245 400.344 63.2963 397.35 66.4318C394.359 69.5714 392.859 73.4183 392.859 77.9856C392.859 82.4767 394.359 86.2952 397.35 89.428C400.344 92.5689 403.982 94.1367 408.261 94.1367Z"
          fill="black"
        />
        <path
          d="M446.988 105.794V50.0681H461.639V54.4514C463.783 52.6712 466.152 51.3145 468.754 50.387C471.359 49.4631 474.191 48.9966 477.26 48.9966C483.675 48.9966 488.953 51.0646 493.087 55.2017C497.222 59.3378 499.291 64.6513 499.291 71.1376V105.794H484.638V73.2768C484.638 69.7823 483.589 66.9655 481.485 64.8262C479.38 62.687 476.579 61.6167 473.087 61.6167C470.66 61.6167 468.47 62.0982 466.509 63.0634C464.547 64.0263 462.923 65.3977 461.639 67.1805V105.794H446.988Z"
          fill="black"
        />
        <path
          d="M511.38 105.794V30.9192L519.294 29.1015V58.515C521.362 56.0935 523.824 54.2567 526.675 53.0077C529.526 51.7613 532.703 51.136 536.198 51.136C542.256 51.136 547.211 53.0603 551.063 56.911C554.916 60.7619 556.84 65.7516 556.84 71.8881V105.794H549.032V73.4896C549.032 68.7863 547.675 65.0416 544.967 62.2596C542.256 59.479 538.619 58.0888 534.059 58.0888C530.845 58.0888 527.976 58.8015 525.447 60.2269C522.916 61.6548 520.863 63.6863 519.294 66.3245V105.794L511.38 105.794Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M567.642 78.9462C567.642 75.0939 568.355 71.4583 569.779 68.0324C571.205 64.613 573.148 61.6523 575.606 59.1553C578.071 56.6614 580.974 54.7021 584.326 53.2728C587.679 51.8478 591.244 51.1339 595.023 51.1339C598.802 51.1339 602.351 51.8478 605.669 53.2728C608.981 54.7021 611.87 56.6614 614.331 59.1553C616.789 61.6523 618.733 64.613 620.162 68.0324C621.584 71.4583 622.302 75.0939 622.302 78.9462C622.302 82.8654 621.584 86.5431 620.162 89.9635C618.733 93.3855 616.789 96.3449 614.331 98.8391C611.87 101.337 608.981 103.298 605.669 104.723C602.351 106.148 598.802 106.862 595.023 106.862C591.244 106.862 587.679 106.148 584.326 104.723C580.974 103.298 578.071 101.337 575.606 98.8391C573.148 96.3449 571.205 93.3855 569.779 89.9635C568.355 86.5431 567.642 82.8654 567.642 78.9462ZM595.025 99.8032C597.734 99.8032 600.284 99.2693 602.676 98.2005C605.06 97.1276 607.13 95.6512 608.878 93.762C610.622 91.8716 611.997 89.6614 612.998 87.1266C613.997 84.5976 614.496 81.8692 614.496 78.9463C614.496 76.0219 613.997 73.291 612.998 70.7606C611.997 68.2287 610.622 66.0396 608.878 64.1815C607.13 62.3288 605.06 60.8672 602.676 59.7981C600.284 58.7266 597.734 58.1941 595.025 58.1941C592.313 58.1941 589.765 58.7266 587.381 59.7981C584.985 60.8672 582.921 62.3287 581.173 64.1815C579.426 66.0396 578.035 68.2287 577.002 70.7606C575.967 73.291 575.452 76.0219 575.452 78.9463C575.452 81.8692 575.95 84.5976 576.949 87.1266C577.945 89.6614 579.319 91.8716 581.068 93.762C582.815 95.6512 584.899 97.1276 587.324 98.2005C589.749 99.2693 592.313 99.8032 595.025 99.8032Z"
          fill="black"
        />
        <path
          d="M640.914 52.2042V84.5075C640.914 89.2134 642.267 92.9607 644.98 95.736C647.69 98.5193 651.327 99.9081 655.889 99.9081C659.101 99.9081 661.966 99.1793 664.499 97.7191C667.03 96.2562 669.084 94.2058 670.652 91.5651V52.2042H678.566V105.792H670.652V99.5894C668.586 102.015 666.141 103.832 663.326 105.043C660.505 106.257 657.353 106.862 653.86 106.862C647.725 106.862 642.734 104.934 638.883 101.083C635.032 97.2393 633.107 92.2482 633.107 86.1115V52.2042L640.914 52.2042Z"
          fill="black"
        />
        <path
          d="M692.035 105.791V52.2036H699.951V58.5124C702.019 56.0907 704.478 54.2542 707.331 53.005C710.183 51.7585 713.358 51.1335 716.851 51.1335C722.908 51.1335 727.866 53.0575 731.719 56.9083C735.569 60.7592 737.495 65.7487 737.495 71.8854V105.791H729.686V73.4869C729.686 68.7835 728.331 65.0388 725.623 62.257C722.908 59.4764 719.277 58.0861 714.713 58.0861C711.502 58.0861 708.63 58.7986 706.101 60.2241C703.572 61.6519 701.52 63.6836 699.951 66.3216V105.791L692.035 105.791Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M792.591 105.795V100.123C790.308 102.267 787.686 103.888 784.728 104.995C781.768 106.098 778.613 106.653 775.26 106.653C771.483 106.653 767.952 105.937 764.67 104.512C761.391 103.085 758.539 101.142 756.116 98.6832C753.69 96.224 751.784 93.2792 750.395 89.8587C749.002 86.4342 748.309 82.7971 748.309 78.9491C748.309 75.0967 749.002 71.4787 750.395 68.0901C751.784 64.7033 753.69 61.7788 756.116 59.3196C758.539 56.8603 761.411 54.9163 764.727 53.4893C768.041 52.0642 771.589 51.3503 775.369 51.3503C778.437 51.3503 781.446 51.8692 784.411 52.9008C787.365 53.9347 790.059 55.4513 792.484 57.4479V30.9196L800.401 29.1019V105.795H792.591ZM756.119 78.8372C756.119 81.7644 756.636 84.5101 757.67 87.0766C758.703 89.6462 760.111 91.8567 761.895 93.7108C763.677 95.5677 765.781 97.024 768.207 98.0955C770.629 99.1643 773.23 99.6996 776.014 99.6996C779.367 99.6996 782.488 99.0407 785.375 97.7216C788.263 96.4028 790.63 94.566 792.487 92.213V65.6848C790.63 63.4017 788.242 61.6013 785.319 60.2795C782.393 58.9631 779.292 58.303 776.014 58.303C770.453 58.303 765.745 60.2646 761.895 64.1842C758.046 68.1076 756.119 72.9964 756.119 78.8372L756.119 78.8372Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_233_699"
          x1="114.889"
          y1="147.35"
          x2="150.674"
          y2="74.0349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_233_699"
          x1="50.9045"
          y1="116.113"
          x2="86.6804"
          y2="42.7947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_233_699"
          x1="55.1682"
          y1="118.202"
          x2="90.9571"
          y2="44.8882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_233_699"
          x1="80.8112"
          y1="144.055"
          x2="135.478"
          y2="90.3221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <clipPath id="clip0_233_699">
          <rect
            width="658"
            height="83"
            fill="white"
            transform="translate(148 29.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default ChLogoIconText;
