import { RightOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import { Statistic } from '../../../components';
import { BulkUploadType, UploadStatus } from '../../../constants';
import { useBulkUploadContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync, useModal } from '../../../hooks';
import { EmployeeService, NotificationService } from '../../../services';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import ErrorList from '../ErrorList';
import { Footer } from '../Footer';
import { useEffect } from 'react';

function Summary() {
  const { me, getMyGroupSettings } = useMeContext();
  const { groupSettings } = me;
  const { Modal: ErrorModal, handleShowModal: handleShowErrorModal } = useModal(
    { width: '90%' }
  );
  const { bulkUploadState, setStep, updateNotification } =
    useBulkUploadContext();
  const { missingCount, notification, type, companySlug } = bulkUploadState;
  const { errorRowCount, upsertSuccessRowCount, uploadType } =
    bulkUploadState.notification?.details;
  useEffect(() => {
    if (!groupSettings) {
      getMyGroupSettings();
    }
  }, []);

  const handleCompleteNotification = async () => {
    await NotificationService.updateNotification(
      {
        status: UploadStatus.COMPLETE,
        notificationId: notification.id
      },
      async (notification) => {
        if (uploadType === BulkUploadType.USER) {
          await EmployeeService.bulkInvitePendingEmployeeToCompany({
            companySlug,
            uploadProcessId: notification.id,
            groupSlug: groupSettings?.slug
          });
        }
        updateNotification({ notification });
        analytics.track('Step Completed', me, {
          level1: 'Bulk Uploads',
          level2: 'Complete',
          notification
        });
        if (
          bulkUploadState.notificationReqParams.emissionSourceDetailsId ||
          !!type
        ) {
          setStep({ activeStep: 3 });
        } else {
          setStep({ activeStep: 4 });
        }
      },
      () => {},
      () => {}
    );
  };

  const { isLoading: isUpdatingNotification, execute: updateNotificationFn } =
    useAsync({ asyncFunction: handleCompleteNotification, immediate: false });

  const handleNext = async () => {
    await updateNotificationFn();
  };
  return (
    <div className="bulk-upload-form-summary text-center">
      <Space size={40}>
        <Statistic
          className="text-center"
          type="success"
          title="Success"
          value={upsertSuccessRowCount}
          suffix="items processed"
        />
        {errorRowCount > 0 && (
          <Statistic
            className="text-center"
            type="error"
            title="Errors"
            value={errorRowCount}
            suffix="lines"
          />
        )}
        {missingCount > 0 && (
          <Statistic
            className="text-center"
            type="info"
            title="Missing data"
            value={missingCount}
            suffix="items"
          />
        )}
      </Space>
      <Row justify="center" className="mt-6 mb-6">
        {errorRowCount > 0 && (
          <Button onClick={handleShowErrorModal} type={BUTTON_TYPES.TEXT}>
            View errors
            <RightOutlined className="ml-2" />
          </Button>
        )}
      </Row>
      <Footer
        isLoading={isUpdatingNotification}
        onNextFn={missingCount === 0 && handleNext}
        analyticsStepContext="Summary"
        nextCopy={missingCount > 0 ? 'Update missing data' : 'Complete'}
        canGoBack={false}
      />
      <ErrorModal title="Errors">
        <ErrorList />
      </ErrorModal>
    </div>
  );
}
export default Summary;
