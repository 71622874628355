import classNames from 'classnames';
import {
  LegendIndicatorComplete,
  LegendIndicatorData,
  LegendIndicatorIncomplete
} from '../../assets/svgs';
import { LEGEND_INDICATOR_TYPES } from './LegendIndicator.types';

function LegendIndicator({ type, className }) {
  let Indicator;
  switch (type) {
    case LEGEND_INDICATOR_TYPES.COMPLETE:
      Indicator = LegendIndicatorComplete;
      break;
    case LEGEND_INDICATOR_TYPES.INCOMPLETE:
      Indicator = LegendIndicatorIncomplete;
      break;
    case LEGEND_INDICATOR_TYPES.DATA:
      Indicator = LegendIndicatorData;
      break;
    default:
      Indicator = null;
      break;
  }
  return (
    <span
      className={classNames('ch-legend-indicator', {
        [className]: !!className
      })}
    >
      {Indicator && <Indicator />}
    </span>
  );
}
export default LegendIndicator;
