import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class InitiativeNotificationTemplateService {
  static async listInitiativeNotificationTemplates(
    { companyInitiativeId, initiativeTemplateId, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE_TEMPLATE_NOTIFICATION.replace(
        ':initiativeTemplateId',
        initiativeTemplateId
      ).replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/list`;
      const response = await AxiosInstance.post(API_URL, pagination, {
        params
      });

      onSuccess?.({
        list: response.data?.notificationTemplates,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.notificationTemplates,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default InitiativeNotificationTemplateService;
