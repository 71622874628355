import { Col, Row } from 'antd';
import React from 'react';
import createAccount from '../../assets/images/create-account.png';
import { useMeContext } from '../../context';
import { Logo } from '../../stories/atoms';
import { Loader } from '../index';
import './graphicFormCard.scss';

function GraphicFormCard({ imgSrc, children }) {
  const { isGroupSettingsMember, isLoadingGroupSettings } = useMeContext();
  return (
    <div className="graphic-form-card">
      <Row align="middle" gutter={[20, 0]}>
        <Col span={12}>
          {isGroupSettingsMember ? (
            <Loader isLoading={isLoadingGroupSettings}>
              <Logo className="graphic-form-card__logo" />
            </Loader>
          ) : (
            <img
              src={imgSrc || createAccount}
              alt="Create account"
              className="graphic-form-card__img"
            />
          )}
        </Col>
        <Col span={12}>{children}</Col>
      </Row>
    </div>
  );
}

export default GraphicFormCard;
