import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import IntensityMetricService from '../../../services/intensityMetricService';
import { ModalFooter } from '../../../stories/atoms';
import { EditFields } from './EditFields';
import { EditFieldsValidation } from './EditFields/EditFieldsValidation';
import { StepsContainer } from './StepsContainer';
import { StepOne } from './StepsContainer/StepOne';
import { StepOneValidation } from './StepsContainer/StepOne/StepOneValidation';
import { StepThree } from './StepsContainer/StepThree';
import { StepTwo } from './StepsContainer/StepTwo';
import { StepTwoValidation } from './StepsContainer/StepTwo/StepTwoValidation';

const steps = [
  {
    title: 'Template',
    content: StepOne,
    validationSchema: StepOneValidation,
    key: '0'
  },
  {
    title: 'Configure',
    content: StepTwo,
    validationSchema: StepTwoValidation,
    key: '1'
  },
  {
    title: 'Complete',
    content: StepThree,
    key: '2'
  }
];
export function IntensityMetricForm({ metric = {}, onSuccess }) {
  const { me } = useMeContext();
  const { company } = me;
  const { slug: companySlug } = company;
  const {
    details,
    id: intensityMetricDetailsId,
    intensityMetricTemplateId,
    name,
    description
  } = metric ?? {};
  const { collectionId } = details ?? {};
  const [current, setCurrent] = useState(0);
  const [successProps, setSuccessProps] = useState({intensityMetricDetailsId: (intensityMetricDetailsId || null), name: (name || null)});
  const isEditingMetric = !!metric.id;
  const isCreateSubmitStep = current === steps.length - 2;
  const isCreateCompleteStep = current === steps.length - 1;
  const currentStep = steps[current];
  const isModalFooterVisible = isEditingMetric || !isCreateCompleteStep;
  const nextButtonCopy = isEditingMetric ? 'Save' : 'Next';

  const currentFormValidation = isEditingMetric
    ? EditFieldsValidation
    : currentStep.validationSchema;
  const handleNextStep = () => {
    setCurrent(current + 1);
  };

  const createOrUpdateMetric = async (values) => {
    const serviceMethod = isEditingMetric
      ? IntensityMetricService.updateIntensityMetricDetails
      : IntensityMetricService.createIntensityMetricDetails;
    const onSuccessMethod = isEditingMetric ? onSuccess : handleNextStep;
    const res = await serviceMethod({
      intensityMetricDetailsId,
      companySlug,
      ...values
    });
    setSuccessProps({
      name: res?.data?.name,
      intensityMetricDetailsId: res?.data?.id
    });
    onSuccessMethod({
      name: res?.data?.name,
      intensityMetricDetailsId: res?.data?.id
    });
  };

  const { execute: handleCreateOrUpdateMetric, isLoading } = useAsync({
    asyncFunction: createOrUpdateMetric,
    immediate: false
  });

  const handleSubmit = async (values) => {
    if (!isEditingMetric && !isCreateSubmitStep) {
      handleNextStep();
    } else {
      await handleCreateOrUpdateMetric(values);
    }
  };

  return (
    <Formik
      initialValues={{
        name,
        intensityMetricTemplateId,
        collectionId,
        description
      }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={currentFormValidation}
    >
      {({ isValid }) => {
        const saveButtonProps = isCreateCompleteStep
          ? null
          : {
              disabled: isLoading || !isValid,
              loading: isLoading,
              htmlType: 'submit'
            };

        return (
          <Form>
            {!!isEditingMetric ? (
              <EditFields />
            ) : (
              <StepsContainer
                onSuccess={onSuccess}
                stepProps={{ current, steps }}
                successProps={successProps}
              />
            )}
            {isModalFooterVisible && (
              <ModalFooter
                saveButtonCopy={nextButtonCopy}
                saveButtonProps={saveButtonProps}
                showHelpLink={false}
                showCancelButton={false}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
