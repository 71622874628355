import { useState } from 'react';
import {
  AGGREGATE_TYPE,
  REPORTING_PERIOD_VALUES,
  TIMEFRAME_VALUES
} from '../../../constants';
import { useMeContext } from '../../../context';
import { DataContextProvider } from '../../../context/DataContext';
import { DataInputContextProvider } from '../../../context/DataImportContext';
import { DataOverview } from '../../../features/Data';
import { ReportingOverview } from '../../../features/Reporting';
import YourExportsOverview from '../../../features/YourExportsOverview';
import { analytics } from '../../../helpers';
import { Page, PageHeader, TabPane, Tabs } from '../../../stories/atoms';
import './DataExplorer.scss';
import { BenchmarkTable } from '../../../features/Benchmark/Table';

const analyticsContext = {
  level1: 'Data Explorer',
  level2: 'Overview'
};

const tabNames = {
  1: 'Overview',
  2: 'Reporting',
  3: 'Your Exports',
  4: 'Benchmarking'
};
export function DataExplorer() {
  const { me } = useMeContext();
  const [activeKey, setActiveKey] = useState();
  const changeTab = (tab, isExternal) => {
    if (!isExternal) {
      analytics.track('Tab Clicked', me, {
        ...analyticsContext,
        tab: tabNames[tab]
      });
    }
    setActiveKey(tab);
  };
  return (
    <Page className="data-explorer">
      <PageHeader title="Data Explorer" />
      <Tabs
        activeKey={activeKey}
        secondary
        onChange={(tab) => changeTab(tab, false)}>
        <TabPane
          onClick={() => changeTab('1', false)}
          tab={tabNames[1]}
          key="1">
          <DataContextProvider>
            <DataOverview />
          </DataContextProvider>
        </TabPane>
        <TabPane
          onClick={() => changeTab('2', false)}
          tab={tabNames[2]}
          key="2">
          <DataContextProvider
            defaultFilterOverrides={{
              aggregateType: AGGREGATE_TYPE.BUSINESS_UNIT,
              reportingPeriod: REPORTING_PERIOD_VALUES.CURRENT_FISCAL_YEAR,
              showMonthsWithoutData: true,
              timeUnit: TIMEFRAME_VALUES.QUARTER
            }}>
            <ReportingOverview changeTab={changeTab} />
          </DataContextProvider>
        </TabPane>
        <TabPane
          onClick={() => changeTab('3', false)}
          tab={tabNames[3]}
          key="3">
          <DataInputContextProvider>
            <YourExportsOverview activeKey={activeKey} />
          </DataInputContextProvider>
        </TabPane>
        <TabPane
          onClick={() => changeTab('4', false)}
          tab={tabNames[4]}
          key="4">
          <BenchmarkTable />
        </TabPane>
      </Tabs>
    </Page>
  );
}
