import { Col, Row } from 'antd';
import { Chart, registerables } from 'chart.js';
import classNames from 'classnames';
import * as dateFns from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, InputNumberField, Slider } from '../../../../../components';
import { COLOURS_VIOLET } from '../../../../../constants/colours';
import { useTargetContext } from '../../../../../context';
import { Paragraph, Title } from '../../../../../stories/atoms';
import './CompanyInitiativeBaselineReductionGoal.scss';

Chart.register(...registerables);
export const getChartLabels = (baseYear, endYear) => {
  const options = [];
  for (let i = baseYear; i <= endYear; i++) {
    options.push(i);
  }
  return options;
};
const plugin = {
  id: 'increase-legend-spacing',
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 32;
    };
  }
};

export function CompanyInitiativeBaselineReductionGoal({ emissionsSum }) {
  const { values, setFieldValue } = useFormikContext();
  const { targetState } = useTargetContext();
  const { targetDetail } = targetState;
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const isNew = !targetDetail.baseYear;
  const chartConfig = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Avoided Emissions',
        data: chartData,
        backgroundColor: '#5C29C714',
        borderColor: COLOURS_VIOLET.VIOLET_500,
        fill: true
      }
    ]
  };
  const chartRange = 10;
  const initialDate = new Date(targetDetail?.baseYear ?? new Date());
  const endDate = dateFns.addYears(initialDate, chartRange);
  // Convert to Tonnes
  emissionsSum /= 1000;
  const chartXAxisMaximum = chartRange * emissionsSum;
  const handleOnChangeSlider = (value) => {
    setFieldValue('percentReduction', value);
  };
  const handleSetChartData = () => {
    setChartData(
      chartLabels?.map((year) => {
        if (values?.percentReduction === 0) {
          return 0;
        }
        const yearsDifference = year - initialDate.getUTCFullYear();
        const emissions =
          yearsDifference *
          emissionsSum *
          ((values?.percentReduction ?? 0) / 100);
        return yearsDifference >= 0 ? emissions : 0;
      })
    );
  };

  useEffect(() => {
    if (!!emissionsSum) {
      setChartLabels(
        getChartLabels(initialDate.getUTCFullYear(), endDate.getUTCFullYear())
      );
    }
  }, [emissionsSum, targetDetail.percentReduction, targetDetail?.baseYear]);

  useEffect(() => {
    if (!!targetDetail.percentReduction) {
      setFieldValue('percentReduction', targetDetail.percentReduction);
      handleSetChartData();
    }
  }, [chartLabels]);

  useEffect(handleSetChartData, [values?.percentReduction]);

  return (
    <Card className="company-initiative-baseline-reduction-goal">
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Title bottomSpacing={12}>Reduction Goal</Title>
          <Paragraph bottomSpacing={0}>
            What percentage of emissions do you plan to avoid with this
            initiative
          </Paragraph>
        </Col>
        <Col span={24}>
          <Row gutter={70} align="bottom">
            <Col flex="140px">
              <InputNumberField
                disabled={isNew}
                className="m-0"
                onChange={handleOnChangeSlider}
                controls={false}
                min={0}
                max={100}
                value={values?.percentReduction ?? 0}
                name="percentReduction"
                addonAfter="%"
                title="Percentage"
              />
            </Col>
            <Col flex={1}>
              <Row gutter={12} wrap={false} align="middle">
                <Col>0%</Col>
                <Col flex={1}>
                  <Slider
                    disabled={isNew}
                    tooltip={{ formatter: null }}
                    className="purple"
                    min={0}
                    max={100}
                    value={values?.percentReduction}
                    onChange={handleOnChangeSlider}
                  />
                </Col>
                <Col>100%</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={classNames(
            'company-initiative-baseline-reduction-goal__table',
            {
              'company-initiative-baseline-reduction-goal__table__empty': isNew
            }
          )}
        >
          {isNew && (
            <Paragraph className="company-initiative-baseline-reduction-goal__table__empty">
              Please set your baseline year to reduce targets!
            </Paragraph>
          )}
          <Line
            height={340}
            plugins={[plugin]}
            options={{
              maintainAspectRatio: false,
              hover: {
                mode: 'nearest',
                intersect: true
              },
              plugins: {
                legend: {
                  align: 'start',
                  labels: {
                    font: {
                      family: 'MontserratMedium',
                      size: 12
                    },
                    boxWidth: 8,
                    boxHeight: 8,
                    usePointStyle: true,
                    pointStyle: 'circle'
                  }
                }
              },
              elements: {
                line: {
                  borderWidth: 4
                },

                point: {
                  radius: 0,
                  hitRadius: 1,
                  hoverRadius: 4
                },
                hover: {
                  mode: 'nearest',
                  intersect: true
                }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Goal',
                    fullSize: true,
                    align: 'start'
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Annual Carbon Emissions (tonnes CO2e)'
                  },
                  min: 0,
                  max: chartXAxisMaximum
                }
              }
            }}
            data={chartConfig}
          />
        </Col>
      </Row>
    </Card>
  );
}
