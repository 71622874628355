import produce from 'immer';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { BulkUploadType, UploadStatus } from '../constants';
import { useAsync, usePagination } from '../hooks';
import {
  EnergyService,
  NotificationService,
  TravelExpenseService
} from '../services';
import EmissionSourceService from '../services/emissionSourceService';
import { useMeContext } from './MeContext';

// Create the context
const DataImportContext = createContext({});

// Define the default context state
const DataImportState = {
  activeNotification: {},
  completeDataImportList: [],
  incompleteDataImportList: [],
  completeRowCount: 0,
  incompleteRowCount: 0
};

// Create method to use context

function useDataImportContext() {
  const context = useContext(DataImportContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error('useCHCContext must be used within a MetaContextProvider');
  }
  const [dataImportState, setDataImportStateState] = context;

  // Context Methods //
  const {
    handleSetPaginationParams: handleSetCompletePaginationParams,
    paginationParams: completePagination
  } = usePagination({
    pageSize: 30,
    paginationArray: dataImportState.completeDataImportList,
    orderBy: ['createdAt', 'id']
  });

  const {
    handleSetPaginationParams: handleSetIncompletePaginationParams,
    paginationParams: incompletePagination
  } = usePagination({
    pageSize: 30,
    paginationArray: dataImportState.incompleteDataImportList,
    orderBy: ['createdAt', 'id']
  });

  const searchImports = (query) => {
    handleSetCompletePaginationParams({
      ...completePagination,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['title']
          }
        : undefined
    });
    handleSetIncompletePaginationParams({
      ...incompletePagination,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['title']
          }
        : undefined
    });
  };

  const loadCompleteDataImportList = useCallback(async () => {
    const searchTerm =
      completePagination.search?.queryString ||
      incompletePagination.search?.queryString ||
      '';
    const completeResponse = await NotificationService.fetchNotificationActions(
      {
        pagination: {
          ...completePagination,
          search: { ...completePagination.search, queryString: searchTerm }
        },
        isComplete: true,
        isDataImport: true
      }
    );

    const incompleteResponse =
      await NotificationService.fetchNotificationActions({
        pagination: {
          ...incompletePagination,
          search: { ...incompletePagination.search, queryString: searchTerm }
        },
        isComplete: false,
        isDataImport: true
      });

    setDataImportStateState(
      produce(dataImportState, (draft) => {
        draft.completeDataImportList = completeResponse?.list ?? [];
        draft.completeRowCount = completeResponse?.count ?? 0;
        draft.incompleteDataImportList = incompleteResponse?.list ?? [];
        draft.incompleteRowCount = incompleteResponse?.count ?? 0;
        draft.index = undefined;
      })
    );
  }, [
    completePagination.pageNumber,
    completePagination.search?.queryString,
    incompletePagination.pageNumber,
    incompletePagination.search?.queryString
  ]);

  const { isLoading, execute: refreshList } = useAsync({
    asyncFunction: loadCompleteDataImportList,
    defaultValue: []
  });

  const downloadFile = async (notification) => {
    const fileName =
      notification.details?.originalFileName ?? notification?.details?.fileName;
    const downloadedFile = await NotificationService.authorizeDownload({
      notificationId: notification.id,
      companySlug: me.company?.slug,
      fileName,
      type: notification.category
    });

    // Adapted from https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([downloadedFile], { type: 'text/csv' });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a');

    // Passing the blob downloading url
    a.setAttribute('href', url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', fileName);

    // Performing a download with click
    a.click();
  };

  const { execute: handleDownloadFile } = useAsync({
    asyncFunction: downloadFile,
    immediate: false
  });

  const deleteNotification = async (notification) => {
    if (notification.details?.uploadType === BulkUploadType.TRAVEL) {
      await TravelExpenseService.hardDeleteTravelItems(
        me.company?.slug,
        { uploadProcessId: notification.id },
        () => {
          NotificationService.updateNotification(
            {
              notificationId: notification.id,
              status: UploadStatus.COMPLETE,
              isCancelled: true,
              rowErrors: []
            },
            () => {},
            () => {},
            () => {}
          );
          refreshList();
        },
        () => {},
        () => {}
      );
    } else if (notification.details?.uploadType === BulkUploadType.ENERGY) {
      await EnergyService.hardDeleteEnergyItems(
        notification.details?.energyDetailsId,
        { uploadProcessId: notification.id },
        () => {
          NotificationService.updateNotification(
            {
              notificationId: notification.id,
              status: UploadStatus.COMPLETE,
              isCancelled: true,
              rowErrors: []
            },
            () => {},
            () => {},
            () => {}
          );
          refreshList();
        },
        () => {},
        () => {}
      );
    } else if (notification.details?.uploadType === BulkUploadType.CUSTOM) {
      await EmissionSourceService.hardDeleteEmissionSourceItem(
        notification.details?.emissionSourceDetailsId,
        { uploadProcessId: notification.id },
        () => {
          NotificationService.updateNotification(
            {
              notificationId: notification.id,
              status: UploadStatus.COMPLETE,
              isCancelled: true,
              rowErrors: []
            },
            () => {},
            () => {},
            () => {}
          );
          refreshList();
        },
        () => {},
        () => {}
      );
    }
  };

  const { execute: handleDeleteNotification } = useAsync({
    asyncFunction: deleteNotification,
    immediate: false
  });

  const setActiveNotification = (activeNotification) => {
    setDataImportStateState({ ...dataImportState, activeNotification });
  };

  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    completePagination,
    dataImportState,
    handleDeleteNotification,
    handleDownloadFile,
    incompletePagination,
    isLoading,
    refreshList,
    searchImports,
    setActiveNotification
  };
}

// Create the context provider
function DataInputContextProvider(props) {
  const [CHCState, setCHCState] = useState(DataImportState);
  const value = useMemo(() => [CHCState, setCHCState], [CHCState]);
  return <DataImportContext.Provider value={value} {...props} />;
}

export { DataInputContextProvider, useDataImportContext };
