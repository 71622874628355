import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { Loader } from '../../../components';
import NestedCollapse from '../../../components/NestedCollapse';
import { AGGREGATE_TYPE } from '../../../constants';
import { useMeContext } from '../../../context';
import { useDataContext } from '../../../context/DataContext';
import {
  capitalizeText,
  conditionalCapitalizeText,
  formatDecimal
} from '../../../helpers';
import { getFormattedAddress } from '../../../helpers/generators';
import { AppRoutes } from '../../../routes';
import { EnergyService, TravelExpenseService } from '../../../services';
import EmissionSourceService from '../../../services/emissionSourceService';
import { Link, Tag, Title } from '../../../stories/atoms';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import './DataDrilldown.scss';

const scopeMapping = {
  scope1Emissions: 'Scope 1',
  scope2Emissions: 'Scope 2',
  scope3Emissions: 'Scope 3'
};

const energyCategories = ['Stationary Combustion', 'Imported Energy'];
const travelCategories = ['Business Travel'];

const getOnChangeFn = (
  isEnergyDrilldown,
  isTravelDrilldown,
  isCustomDrilldown
) => {
  if (isEnergyDrilldown) {
    return EnergyService.fetchEnergyItems;
  }
  if (isTravelDrilldown) {
    return TravelExpenseService.fetchTravelExpenses;
  }
  if (isCustomDrilldown) {
    return EmissionSourceService.listEmissionSourceItems;
  }
};

export function DataDrilldown({ drilldownData, isLoading }) {
  const { data } = useDataContext();
  const { filters } = data;
  const { aggregateType, selectedDateRange } = filters;
  const [activePanels, setActivePanels] = useState([]);
  const [formattedData, setFormattedData] = useState();
  const [totalEmissionsSum, setTotalEmissionsSum] = useState(0);
  // Helper function to format the emission into a string for the panel header
  const formatPanelExtra = (emissions) =>
    `${formatDecimal(emissions / 1000, 3)} tonnes CO2e`;
  const { me } = useMeContext();
  const handleLoadNextLevel = async (
    panelIndex,
    currentActivePanels,
    isEnergyDrilldown,
    isTravelDrilldown,
    isCustomDrilldown,
    onChangeParams,
    onChangeFn
  ) => {
    let children = [];
    if (isEnergyDrilldown) {
      const { energyDetailsId, ...params } = onChangeParams;
      const resp = await onChangeFn(energyDetailsId, params);
      const { list } = resp;
      children = list.map((item) => ({
        header: (
          <Title size="sm" bottomSpacing={0}>
            {`${formatInTimeZone(
              new Date(item.billingStart),
              'UTC',
              'MMMM, do yyyy'
            )} - ${formatInTimeZone(
              new Date(item.billingEnd),
              'UTC',
              'MMMM, do yyyy'
            )}`}
          </Title>
        ),
        panelExtra: formatPanelExtra(item.emissionTotal)
      }));
    }
    if (isTravelDrilldown || isCustomDrilldown) {
      const resp = await onChangeFn(onChangeParams);
      const { list } = resp;
      let headerText = '';
      children = list.map((item) => {
        if (item.date) {
          headerText = formatInTimeZone(
            new Date(item.date),
            'UTC',
            'MMMM, do, yyyy'
          );
        } else if (item.startDate && item.endDate) {
          headerText = `${formatInTimeZone(
            new Date(item.startDate),
            'UTC',
            'MMMM, do yyyy'
          )} - ${formatInTimeZone(
            new Date(item.endDate),
            'UTC',
            'MMMM, do yyyy'
          )}`;
        }
        return {
          header: headerText,
          panelExtra: formatPanelExtra(item.emissionTotal)
        };
      });
    }
    setFormattedData((prev) =>
      produce(prev, (draft) => {
        if (aggregateType === AGGREGATE_TYPE.CATEGORY) {
          draft[currentActivePanels[0]].children[panelIndex].children =
            children;
        } else {
          draft[currentActivePanels[0]].children[
            currentActivePanels[1]
          ].children[panelIndex].children = children;
        }
        return draft;
      })
    );
  };

  const formatCategoryLevels = (levels) => {
    let emissionsSum = 0;
    const catLevels = Object.keys(levels).reduce((acc, curr) => {
      const currentSource = levels[curr];
      if (currentSource.totalEmissions) {
        emissionsSum += currentSource.totalEmissions;
      }
      const sourceChildLevel = currentSource.sources?.map((childSource) => {
        const { isInternal, internalDetails } = childSource;
        const { energyDetailsId } = internalDetails ?? {};
        const isEnergyDrilldown =
          isInternal && energyDetailsId && energyCategories.indexOf(curr) > -1;
        const isTravelDrilldown =
          isInternal && travelCategories.indexOf(curr) > -1;
        const isCustomDrilldown = !isInternal;
        const shouldLoadDataOnChange =
          isEnergyDrilldown || isTravelDrilldown || isCustomDrilldown;
        let onChangeFn = () => {};
        let onChangeParams = {};
        if (shouldLoadDataOnChange) {
          onChangeFn = getOnChangeFn(
            isEnergyDrilldown,
            isTravelDrilldown,
            isCustomDrilldown
          );
          onChangeParams = {
            ...selectedDateRange,
            companySlug: me.company.slug,
            ...(isTravelDrilldown
              ? {
                  emissionSourceDetailsId: childSource.id,
                  pagination: {
                    orderBy: ['date', 'id']
                  }
                }
              : {}),
            ...(isEnergyDrilldown
              ? {
                  energyDetailsId,
                  emissionSourceDetailsId: childSource.id,
                  pagination: {
                    orderBy: ['startDate', 'id']
                  }
                }
              : {}),
            ...(isCustomDrilldown
              ? {
                  isComplete: true,
                  emissionSourceDetailsId: childSource.id,
                  pagination: {
                    orderBy: ['id']
                  }
                }
              : {})
          };
        }
        return {
          onChangeFn: shouldLoadDataOnChange
            ? (panelIndex, currentActivePanels) =>
                handleLoadNextLevel(
                  panelIndex,
                  currentActivePanels,
                  isEnergyDrilldown,
                  isTravelDrilldown,
                  isCustomDrilldown,
                  onChangeParams,
                  onChangeFn
                )
            : undefined,
          header: (
            <Title bottomSpacing={0} size="rg">
              {childSource.internalDetails?.commuteVehicleName ??
                conditionalCapitalizeText(
                  childSource.name,
                  childSource.isInternal
                )}
            </Title>
          ),
          panelExtra: formatPanelExtra(childSource.totalEmissions),
          panelDetails: (
            <Row gutter={[24, 8]} align="middle">
              {!childSource.isInternal && (
                <Col>
                  <Tag color={TAG_COLOURS.BLUE}>Custom</Tag>
                </Col>
              )}
              <Col>
                <Title bottomSpacing={0} size="sm">
                  {getFormattedAddress({
                    location: childSource.location,
                    name: childSource.name
                  })}
                </Title>
              </Col>
              {(!childSource.isInternal || childSource.name !== 'WFH') && (
                <Col>
                  <Link
                    fontWeight={600}
                    to={AppRoutes.SOURCES_DETAILS.replace(
                      ':emissionSourceDetailsId',
                      childSource.id
                    )}>
                    View source
                  </Link>
                </Col>
              )}
            </Row>
          )
        };
      });
      acc.push({
        header: (
          <Title bottomSpacing={0} size="md">
            {capitalizeText(curr)}
          </Title>
        ),
        children: sourceChildLevel,
        panelExtra: formatPanelExtra(currentSource.totalEmissions)
      });
      return acc;
    }, []);
    setTotalEmissionsSum(emissionsSum);
    return catLevels;
  };

  const formatScopeLevels = (levels) => {
    let emissionsSum = 0;
    const scopeLevels = Object.entries(levels)
      .map(([level, currentSource]) => {
        const { totalEmissions, ...childLevels } = currentSource;
        if (currentSource.totalEmissions) {
          emissionsSum += totalEmissions;
        }
        return {
          header: (
            <Title bottomSpacing={0} size="md">
              {capitalizeText(scopeMapping[level])}
            </Title>
          ),
          children: formatCategoryLevels(childLevels),
          panelExtra: formatPanelExtra(totalEmissions)
        };
      })
      .reverse();
    setTotalEmissionsSum(emissionsSum);
    return scopeLevels;
  };

  useEffect(() => {
    if (!!drilldownData) {
      const fd =
        aggregateType === AGGREGATE_TYPE.CATEGORY
          ? formatCategoryLevels(drilldownData)
          : formatScopeLevels(drilldownData);
      setFormattedData(fd);
    }
  }, [aggregateType, drilldownData]);

  const handleSetActiveLevel = ({ panelIndex, nestingLevel }) => {
    setActivePanels((prev) =>
      produce(prev, (draft) => {
        if (panelIndex) {
          if (!draft[nestingLevel]) {
            draft.push(panelIndex);
          } else {
            draft[nestingLevel] = panelIndex;
          }
        } else {
          // close all panels below the selected panel
          draft.length = nestingLevel;
        }
      })
    );
  };

  useEffect(() => {
    setActivePanels([]);
  }, [aggregateType, selectedDateRange]);

  return (
    <Loader isLoading={isLoading}>
      <Row
        justify-content="space-between"
        className="data-drilldown__total-row">
        <Col flex="1">
          <Title bottomSpacing={0}>Total Emissions</Title>
        </Col>
        <Col>
          <Title size="rg" bottomSpacing={0}>
            {formatPanelExtra(totalEmissionsSum)}
          </Title>
        </Col>
      </Row>
      <NestedCollapse
        handleActiveLevelPanel={handleSetActiveLevel}
        activePanels={activePanels}
        schemaArray={formattedData}
        handleSetActivePanel={setActivePanels}
      />
    </Loader>
  );
}
