import { createModelSchema, object, primitive } from 'serializr';
import { getFullName } from '../helpers';
import Company from './company';

export class Employee {
  user = new User();

  status;

  role;

  id;
}

export class User {
  company = new Company();

  firstName;

  lastName;

  email;

  getFullName() {
    return getFullName(this);
  }
}

createModelSchema(User, {
  id: primitive(),
  token: primitive(),
  avatar: primitive(),
  displayName: primitive(),
  firstName: primitive(),
  lastName: primitive(),
  email: primitive(),
  verificationCode: primitive(),
  password: primitive(),
  confirmPassword: primitive(),
  createdAt: primitive(),
  isVerified: primitive(),
  userType: primitive(),
  loggedInAs: primitive(),
  company: object(Company)
});

createModelSchema(Employee, {
  id: primitive(),
  user: object(User),
  status: primitive(),
  role: primitive()
});
