import { Col, Row, Space } from 'antd';
import React from 'react';
import { COLOURS_CARBONHOUND, COLOURS_GRAY } from '../../../constants/colours';
import { Button, Icon, Title } from '../../atoms';
import './blurb.scss';

export function Blurb({ children, titleProps, iconProps, buttonProps }) {
  const {
    name = 'LightBulb',
    color = COLOURS_CARBONHOUND.PRIMARY_ORANGE,
    size = 24,
    ...otherIconProps
  } = {
    ...iconProps
  };
  const { text: buttonText, ...otherButtonProps } = { ...buttonProps };
  const {
    title,
    size: titleSize = 'rg',
    weight = 500,
    color: titleColor = COLOURS_GRAY.GRAY_800
  } = { ...titleProps };
  return (
    <Row className="blurb__container">
      <Col span={24}>
        <Space size="small" align="start" className="blurb-header">
          <Icon size={size} color={color} name={name} {...otherIconProps} />
          <Space direction="vertical">
            {!!title && (
              <Title
                bottomSpacing={0}
                size={titleSize}
                color={titleColor}
                fontWeight={weight}
              >
                {title}
              </Title>
            )}
            <div className="blurb-description">{children}</div>
          </Space>
        </Space>
        {!!buttonProps && <Button {...otherButtonProps}>{buttonText}</Button>}
      </Col>
    </Row>
  );
}
export default Blurb;
