import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Alert, AttachmentInput, Loader } from '../../../../../components';
import { useMeContext } from '../../../../../context';
import { useAsync } from '../../../../../hooks';
import groupService from '../../../../../services/groupService';
import {
  Anchor,
  ModalFooter,
  Paragraph,
  Title
} from '../../../../../stories/atoms';
import { userCompanyDetailsLogoUploadFormValidation } from './UserCompanyDetailsLogoUploadFormValidation';

export function UserCompanyDetailsLogoUploadForm({ onSuccess, onCancel }) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const {
    me: { company, groupSettings }
  } = useMeContext();

  const uploadGroupLogo = async ({ files }) => {
    if (!groupSettings) return;
    setShowSuccessAlert(false);
    const file = files?.[0];
    if (!file) {
      return;
    }
    const { id: groupId } = groupSettings;
    const { name: fileName } = file;
    const logoUrl = await groupService.authorizeGroupUpload({
      groupId,
      fileName,
      file,
      privacyType: 'PUBLIC'
    });
    if (logoUrl) {
      await groupService.updateGroup({
        groupId,
        companySlug: company?.slug,
        logoUrl
      });

      onSuccess?.();
    }
  };

  const { execute: handleSubmit, isLoading } = useAsync({
    asyncFunction: uploadGroupLogo,
    immediate: false
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ files: [] }}
      validationSchema={userCompanyDetailsLogoUploadFormValidation}
    >
      {({ setFieldValue, values: { files }, isValid, errors, setErrors }) => {
        const { name } = files?.[0] ?? {};
        const fileFormError = errors.files;
        const handleOnDropError = () => {
          setErrors({ errors: {} });
          setShowErrorAlert(true);
          setShowSuccessAlert(false);
        };
        const handleOnDropSuccess = () => {
          setErrors({ errors: {} });
          setShowErrorAlert(false);
          setShowSuccessAlert(true);
        };
        return (
          <Form>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Title align="center" size="lg" bottomSpacing={8}>
                  Prepare your upload
                </Title>
                <Paragraph align="center" bottomSpacing={0}>
                  File type must be .png or .jpg
                </Paragraph>

                <Paragraph align="center" bottomSpacing={0}>
                  File type must be .png or .jpg If you are having trouble with
                  your logo upload please use{' '}
                  <Anchor href="https://carbonhound.notion.site/White-Labelling-Guide-52f2fabbed004043a755e7c8bece2438?pvs=4">
                    this guide
                  </Anchor>
                  .
                </Paragraph>
              </Col>
              <Col span={24}>
                <AttachmentInput
                  setFieldValue={setFieldValue}
                  multiple={false}
                  handleOnDropError={handleOnDropError}
                  handleOnDropSuccess={handleOnDropSuccess}
                  accept={{
                    'image/png': ['.png', '.jpg']
                  }}
                />
              </Col>
              <Col span={24}>
                <Alert
                  showIcon
                  icon={<Loader isLoading size={16} />}
                  isVisible={isLoading}
                  type="info"
                  message="Uploading file"
                />
                <Alert
                  showIcon
                  isVisible={showSuccessAlert}
                  type="success"
                  message={
                    <span>
                      <span className="text-bd">{name}</span> successfully
                      uploaded
                    </span>
                  }
                />
                <Alert
                  showIcon
                  isVisible={fileFormError || showErrorAlert}
                  type="error"
                  message={
                    !!fileFormError
                      ? fileFormError
                      : 'That is the wrong file format, please upload only supported file types (.jpg, .png)'
                  }
                />
              </Col>
              <Col span={24}>
                <ModalFooter
                  cancelButtonProps={{
                    onClick: onCancel
                  }}
                  saveButtonProps={{
                    htmlType: 'submit',
                    disabled: isLoading || !isValid
                  }}
                  showHelpLink={false}
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
