import { Modal as AntModal, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useState } from 'react';
import { Loader } from '../../../../components';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS,
  EmissionSourceItemOrigin,
  EmissionSourceItemOriginCopy
} from '../../../../constants';
import { COLOURS_GRAY } from '../../../../constants/colours';
import DateFormats from '../../../../constants/dateFormats';
import { useMeContext } from '../../../../context';
import { analytics, capitalizeText, formatDecimal } from '../../../../helpers';
import { useAsync, useModal, usePagination } from '../../../../hooks';
import { TravelExpenseService } from '../../../../services';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { TravelItemForm } from '../../../../stories/molecules';
import DataRequestTemplate from '../../../../stories/molecules/DataRequestTemplate';
import TableTitle from '../../../../stories/molecules/TableTitle';
import BulkUploadForm from '../../../BulkUploadForm';

function EmissionSourceTravelItemTable({
  emissionSourceDetails,
  analyticsContext,
  refreshChart
}) {
  const { me } = useMeContext();
  const [itemList, setItemList] = useState([]);
  const [activeItem, setActiveItem] = useState();
  const [dateRange, setDateRange] = useState(null);
  const isEstimate = emissionSourceDetails?.internalDetails?.isEstimate;
  const { paginationParams } = usePagination({
    pageSize: 30,
    paginationArray: itemList,
    orderBy: ['date', 'id']
  });
  const handleLoadTableData = useCallback(async () => {
    if (emissionSourceDetails?.id) {
      const { list, count } = await TravelExpenseService.fetchTravelExpenses({
        emissionSourceDetailsId: emissionSourceDetails?.id,
        companySlug: me.company?.slug,
        pagination: paginationParams,
        ...(dateRange?.length > 1 && {
          rangeStart: formatInTimeZone(
            new Date(dateRange?.[0]),
            'UTC',
            DateFormats.API
          ),
          rangeEnd: formatInTimeZone(
            new Date(dateRange?.[1]),
            'UTC',
            DateFormats.API
          )
        })
      });
      setItemList(list);
      return count;
    }
  }, [
    emissionSourceDetails?.id,
    me.company?.slug,
    paginationParams.pageNumber,
    dateRange
  ]);
  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData
  });
  const handleDeleteItem = async (travelItem) => {
    await TravelExpenseService.deleteTravelExpense(
      travelItem,
      () => {
        refreshList();
      },
      () => {},
      () => {}
    );
  };

  const {
    Modal: CreateItemModal,
    handleShowModal: handleShowItemModal,
    handleCloseModal: handleCloseItemModal
  } = useModal({
    width: '60%',
    onCloseModal: () => {
      refreshList();
      setActiveItem(undefined);
    }
  });

  const handleShowNewItemModal = () => {
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      level4: 'Item Modal'
    });
    handleShowItemModal();
  };
  const handleItemModalSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      travelItem: activeItem,
      level4: 'Item Modal'
    });
    refreshChart();
    handleCloseItemModal();
  };

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  const {
    Modal: BUModal,
    handleShowModal: handleShowBUModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Upload data clicked', me, {
        ...analyticsContext
      });
    },
    width: '90%'
  });
  const handleOpenDeleteItemModal = async (travelItem) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      closable: true,
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: { className: 'ch-button-new primary', shape: 'round' },
      cancelButtonProps: {
        className: 'ch-button-new secondary',
        shape: 'round'
      },
      content: 'Are you sure you want to delete this?',
      onOk: async () => {
        analytics.track('Delete item clicked', me, {
          ...analyticsContext,
          travelItem
        });
        await handleDeleteItem(travelItem);
        await refreshChart();
      }
    });
  };
  const handleEditItemModal = (travelItem) => {
    analytics.track('Edit Item Clicked', me, {
      ...analyticsContext,
      travelItem
    });
    setActiveItem(travelItem);
    handleShowItemModal();
  };
  const columns = [
    {
      title: 'Period',
      dataIndex: 'date',
      key: 'date',
      render: (date) =>
        date ? formatInTimeZone(new Date(date), 'utc', 'MM/dd/yyyy') : '-'
    },
    {
      title: 'Usage',
      dataIndex: 'distance',
      key: 'distance',
      render: (distance, travelItem) =>
        travelItem.isComplete ? (
          formatDecimal(distance)
        ) : (
          <Space>
            <Loader size={16} isLoading />
            <span>Processing</span>
          </Space>
        )
    },
    {
      title: 'Unit',
      render: (_, item) => capitalizeText(item.activityUnit?.name)
    },
    {
      key: 'emissionTotal',
      dataIndex: 'emissionTotal',
      title: <ColumnTitle>Impact (kg Co2e)</ColumnTitle>,
      render: (emissionTotal) => formatDecimal(Number(emissionTotal), 2)
    },
    {
      key: 'dateSource',
      title: <ColumnTitle>Data Source</ColumnTitle>,
      render: (_, item) => {
        if (
          item.sourceDetails?.origin ===
          EmissionSourceItemOrigin.CARBONHOUND_CONNECT
        ) {
          if (!!item.isEstimate)
            return EmissionSourceItemOriginCopy.CARBONHOUND_CONNECT_ESTIMATE;
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.CARBONHOUND_CONNECT
          ];
        }
        if (!!item.isEstimate) {
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.ESTIMATION
          ];
        }
        return EmissionSourceItemOriginCopy.BILL;
      }
    },
    {
      width: '50px',
      render: (_, travelItem) => (
        <Space size={24}>
          <ButtonIcon
            onClick={() => handleEditItemModal(travelItem)}
            name="Pencil"
            color={COLOURS_GRAY.GRAY_500}
            backgroundColour="transparent"
          />
          <ButtonIcon
            onClick={() => handleOpenDeleteItemModal(travelItem)}
            name="MinusCircle"
            backgroundColour="transparent"
            color={COLOURS_GRAY.GRAY_400}
          />
        </Space>
      )
    }
  ];

  const handleDateRangeChange = (date) => {
    setDateRange(date ?? null);
  };

  return (
    <div className="energy-item-table">
      {isEstimate ? (
        <Empty
          icon="Calculator"
          title="This source is estimated"
          description="You have set that you do not know the energy types for this location so we have estimated this source based off of your regional averages. If you now know the energy types please edit them in the Location Settings"
        />
      ) : (
        <>
          <Table
            isLoading={isLoading}
            isHeaderBorder
            dataSource={itemList}
            pagination={paginationParams}
            showPagination
            columns={columns}
            total={count}
            title={() => (
              <TableTitle
                isHeaderBorder
                buttons={[
                  {
                    type: BUTTON_TYPES.LINK,
                    children: 'Data Request Template',
                    onClick: handleShowTemplateModal
                  },
                  {
                    type: BUTTON_TYPES.SECONDARY,
                    children: 'Data Import',
                    onClick: handleShowBUModal
                  },
                  {
                    children: 'Add Item',
                    onClick: handleShowNewItemModal
                  }
                ]}
                dateFilterProps={{
                  value: dateRange,
                  onChange: handleDateRangeChange
                }}
              />
            )}
          />
          <DataRequestTemplateModal title="Data request template">
            <DataRequestTemplate />
          </DataRequestTemplateModal>
          <CreateItemModal
            title={`Enter Usage Data: ${
              capitalizeText(emissionSourceDetails?.name) ?? ''
            }`}
          >
            <TravelItemForm
              emissionSourceDetails={emissionSourceDetails}
              travelItem={activeItem}
              onSuccess={handleItemModalSuccess}
            />
          </CreateItemModal>
          <BUModal title="Upload your file" className="bulk-upload-modal">
            <BulkUploadForm
              notificationReqParams={{
                emissionSourceDetailsId: emissionSourceDetails?.id,
                travelCategoryId:
                  emissionSourceDetails?.internalDetails?.travelCategoryId
              }}
              notification={{
                details: {
                  uploadType: BulkUploadType.TRAVEL
                }
              }}
              internalSpec={EMISSION_SOURCE_INTERNAL_SPECS.TRAVEL}
              handleCloseBulkUploadsModal={handleCloseBUModal}
            />
          </BUModal>
        </>
      )}
    </div>
  );
}
export default EmissionSourceTravelItemTable;
