import { Col, Divider, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { generatePath } from 'react-router-dom';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useLayoutContext, useMeContext } from '../../../context';
import { analytics, capitalizeText, formatDecimal } from '../../../helpers';
import { getDistanceUnit, getRegionalValue } from '../../../helpers/generators';
import { AppRoutes } from '../../../routes';
import { CommuteService } from '../../../services';
import { Popover } from '../../../components';
import { ButtonIcon, Icon, Paragraph, Tag, Title } from '../../atoms';
import { TRANSPORTATION_TYPE_COLOURS } from '../../atoms/Tag/tag.types';
import './CommuteItem.scss';

function CommuteItem({
  commuteItem,
  emissionType,
  handleOnDeleteSuccess,
  handleProcessingData
}) {
  const { me } = useMeContext();
  const { navStep } = useLayoutContext();

  const {
    officeLocation,
    homeLocation,
    emissionSummary,
    transportationTypes,
    id,
    startDate,
    endDate,
    distanceSummary,
    averageCommuteDaysPerWeek,
    isWorkFromHome
  } = commuteItem ?? {};
  const editLink = isWorkFromHome
    ? AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME_EDIT
    : AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH_EDIT;

  const handleDelete = () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this commute?',
      onOk: async () => {
        await CommuteService.deleteCommute(commuteItem);
        handleOnDeleteSuccess?.(commuteItem);
      }
    });
  };
  const handleEditAnalytics = () => {
    analytics.track('Edit Item Clicked', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Overview',
      commuteDetails: commuteItem
    });
  };
  const handleEdit = () => {
    handleEditAnalytics();
    navStep(generatePath(editLink, { commuteId: id }));
  };

  const handleCommuteRefresh = () => {
    handleProcessingData();
  };

  const processingSection = () => (
    <div className="processingSection">
      <span>Processing... </span>
      <ButtonIcon
        size={24}
        color={COLOURS_GRAY.GRAY_400}
        name="Refresh"
        onClick={handleCommuteRefresh}
      />
      <Popover content="The data takes a few seconds to process, click refresh to see if processing has finished." />
    </div>
  );

  return (
    <Row className="commute-item" gutter={[]}>
      <Col span={24}>
        <Row align="middle" gutter={[20, 12]}>
          <Col flex="0 1 0">
            <Icon
              size={24}
              color={COLOURS_GRAY.GRAY_400}
              name={isWorkFromHome ? 'Home' : 'Briefcase'}
            />
          </Col>
          <Col flex="1 1 auto">
            <Title bottomSpacing={0} size="md">
              {(emissionType === 'wfh' && emissionSummary === 0) ||
              (emissionType === 'commute' && distanceSummary === 0)
                ? processingSection()
                : `${formatDecimal(emissionSummary)} kg CO2e/Year`}
            </Title>
            <Paragraph bottomSpacing={0} size="sm">
              {`${homeLocation?.address1 ?? ''}${
                !!officeLocation?.address1
                  ? ` - ${officeLocation.address1}`
                  : ''
              }`}
            </Paragraph>
          </Col>
          <Col flex="0 1 1">
            <Row>
              <Col flex="0 1 1">
                <Space size={24}>
                  <Space
                    wrap
                    size={transportationTypes?.length > 1 ? 8 : 0}
                    align="end"
                  >
                    {transportationTypes
                      ?.filter((transport) => transport !== 'WFH')
                      .map((transport) => (
                        <Tag
                          key={transport}
                          color={TRANSPORTATION_TYPE_COLOURS[transport]}
                        >
                          {capitalizeText(transport)}
                        </Tag>
                      ))}
                  </Space>
                  <ButtonIcon
                    color={COLOURS_GRAY.GRAY_500}
                    backgroundColour="transparent"
                    name="Pencil"
                    onClick={handleEdit}
                  />
                  <ButtonIcon
                    color={COLOURS_GRAY.GRAY_500}
                    onClick={handleDelete}
                    backgroundColour="transparent"
                    name="MinusCircle"
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Title bottomSpacing={4} size="xs">
              Time Period
            </Title>
            <Title bottomSpacing={0} size="rg">
              {`${formatInTimeZone(new Date(startDate), 'UTC', 'M/d/yyyy')} - ${
                !!endDate
                  ? formatInTimeZone(new Date(endDate), 'UTC', 'M/d/yyyy')
                  : 'Current'
              }`}
            </Title>
          </Col>
          {isWorkFromHome ? (
            <>
              <Col flex="0 1 0">
                <Divider type="vertical" />
              </Col>
              <Col flex={1}>
                <Title bottomSpacing={4} size="xs">
                  Days WFH
                </Title>
                <Title bottomSpacing={0} size="rg">
                  {averageCommuteDaysPerWeek}
                </Title>
              </Col>
            </>
          ) : (
            <>
              <Col flex="0 1 0">
                <Divider type="vertical" />
              </Col>
              <Col flex={1}>
                <Title bottomSpacing={4} size="xs">
                  Distance weekly
                </Title>
                <Title bottomSpacing={0} size="rg">
                  {`${formatDecimal(
                    getRegionalValue(
                      distanceSummary,
                      me.company?.headQuartersCountry?.isoName
                    ),
                    0
                  )} ${getDistanceUnit(
                    me.company?.headQuartersCountry?.isoName
                  )}`}
                </Title>
              </Col>
              <Col flex="0 1 0">
                <Divider type="vertical" />
              </Col>
              <Col flex={1}>
                <Title bottomSpacing={4} size="xs">
                  Days weekly
                </Title>
                <Title bottomSpacing={0} size="rg">
                  {averageCommuteDaysPerWeek}
                </Title>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default CommuteItem;
