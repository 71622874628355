/** Analytics
 * This helper to send analytics events is designed to avoid missing important context (IDs) when
 * creasting this events.
 * More verbose is always better. Send ALL IDs that are relevant to the event when possible. Note that
 * eventIds (asides meId) refer to the action an event is being perform on, NOT the currently authenticated user
 */

/** Primary Tracker/Default Tracker * */
export function track(eventName, me, context = {}) {
  const sanitizedContext = sanitizeAdditionalEventIds({ context });
  window.analytics.track(eventName, {
    environment: process.env.REACT_APP_ENV,
    meId: me?.id,
    companyId: me?.company?.id, // Override this field when accessing a company that is not currently the logged-in one
    ...sanitizedContext
  });
}

/** Helpers * */
// This sanitizer ensures that fields are sent to the analytics event, and that object fields are broken down into their relevant IDs
function sanitizeAdditionalEventIds({ context }) {
  const sanitizedContext = {};
  for (const [key, val] of Object.entries(context)) {
    // We do the check this way to ignore null+undefined, but allow intentional 0/empty string/etc.
    // additionally, arrays are not supported
    if (val == null || val instanceof Array) continue;

    // Check for pre-supported fields or non-object fields (don't want to miss data just cause it's not in the field list)
    // This assumes that explicitly sent IDs take priority over any found in object deconstruction
    if (coreAnalyticsFields[key] || typeof val !== 'object') {
      sanitizedContext[key] = val;
      // For objects, see if a pre-built object destructure is available, then add the extra context
    } else if (typeof val === 'object') {
      switch (key) {
        case 'notification': {
          sanitizedContext.notificationId ??= val.id ?? val.notificationId;
          sanitizedContext.energyDetailsId ??= val.details?.energyDetailsId;
          sanitizedContext.emissionSourceDetailsId ??=
            val.details?.emissionSourceDetailsId;
          sanitizedContext.energyTypeId ??= val.details?.energyTypeId;
          sanitizedContext.companyInitiativeId ??= val.companyInitiativeId;
          sanitizedContext.transportationId ??= val.transportationId;
          break;
        }
        case 'energyDetails': {
          sanitizedContext.energyDetailsId ??= val.id ?? val.energyDetailsId;
          sanitizedContext.locationId ??=
            val.locationId ??
            val.location?.id ??
            val.collection?.locationId ??
            val.collection?.location?.id;
          sanitizedContext.collectionId ??=
            val.collectionId ?? val.collection?.id;
          break;
        }
        case 'energyItem': {
          sanitizedContext.energyItemId ??= val.id ?? val.energyItemId;
          sanitizedContext.energyDetailsId = val.energyDetailsId;
          sanitizedContext.energyTypeId ??=
            val.energyTypeId ?? val.energyType?.id ?? val.type?.id;
          sanitizedContext.locationId ??=
            val.locationId ??
            val.location?.id ??
            val.collection?.locationId ??
            val.collection?.location?.id;
          sanitizedContext.collectionId ??=
            val.collectionId ?? val.collection?.id;
          break;
        }
        case 'commuteDetails': {
          sanitizedContext.commuteDetailsId ??= val.id ?? val.commuteDetailsId;
          sanitizedContext.officeCollectionId ??=
            val.officeCollectionId ?? val.officeCollection?.id;
          sanitizedContext.homeLocationId ??=
            val.homeLocationId ?? val.homeLocation?.id;
          break;
        }
        case 'travelItem': {
          sanitizedContext.travelItemId ??= val.id ?? val.travelItemId;
          sanitizedContext.travelDetailsId ??= val.travelDetailsId;
          sanitizedContext.travelCategoryId ??=
            val.travelCategoryId ?? val.travelCategory?.id ?? val.category;
          break;
        }
        case 'emissionSourceDetails': {
          sanitizedContext.emissionSourceDetailsId ??=
            val.id ?? val.emissionSourceDetailsId;
          sanitizedContext.emissionFactorDetailsId ??=
            val.emissionFactorDetailsId ?? val.emissionFactor?.id;
          break;
        }
        case 'emissionSourceItem': {
          sanitizedContext.emissionSourceItemId ??=
            val.id ?? val.emissionSourceItemId;
          sanitizedContext.emissionSourceDetailsId ??=
            val.emissionSourceDetailsId;
          break;
        }
        case 'targetDetails': {
          sanitizedContext.targetDetailsId ??= val.id ?? val.targetDetailsId;
          break;
        }
        case 'targetItem': {
          sanitizedContext.targetItemId ??= val.id ?? val.targetItemId;
          sanitizedContext.targetDetailsId ??= val.targetDetailsId;
          break;
        }
        case 'integration': {
          sanitizedContext.integrationName ??= val.name ?? val.integrationName;
          sanitizedContext.integrationStatus ??=
            val.status ?? val.integrationStatus;
          break;
        }
        case 'utilityProvider': {
          sanitizedContext.utilityProviderId ??= val.utilityProviderId;
          sanitizedContext.companyUtilityProviderId ??=
            val.companyUtilityProviderId;
          break;
        }
        case 'companyInitiative': {
          sanitizedContext.companyInitiativeId ??= val.id;
          sanitizedContext.initiativeTemplateId ??= val.initiativeTemplate?.id;
          break;
        }
        default: {
          // eslint-disable-next-line no-console
          console.log(
            `ANALYTICS: Object with key ${key} not supported in analytics hook.`
          );
          break;
        }
      }
    }
  }
  return sanitizedContext;
}

// The list of commonly supported analytics fields. Use (and update) this list to help confirm
// that you are sending enough contextual data to the analytics tracker
const coreAnalyticsFields = {
  // Descriptor Fields
  level1: true,
  level2: true,
  level3: true,
  level4: true,
  // Base Fields
  userCompanyId: true,
  companyRoleId: true,
  userId: true,
  groupId: true,
  locationId: true,
  countryId: true,
  // Emission Source Fields
  collectionId: true,
  emissionSourceDetailsId: true, // emissionSourceDetailsId is a critical field. Triple check if its needed!
  emissionSourceItemId: true,
  // Emission Factor Fields
  emissionFactorDetailsId: true,
  emissionFactorCollectionId: true,
  emissionFactorItemId: true,
  // Energy Specific Fields
  energyDetailsId: true,
  energyItemId: true,
  energyTypeId: true,
  // Commute Specific Fields
  commuteDetailsId: true,
  commuteItemId: true,
  officeCollectionId: true,
  homeLocationId: true,
  // Business Travel Specific Fields
  travelDetailsId: true,
  travelItemId: true,
  // Initiative Fields
  initiativeTemplateId: true,
  companyInitiativeId: true,
  // Action(notification) Fields
  notificationId: true,
  notificationTemplateId: true,
  // Targets (goals) Fields
  targetDetailsId: true,
  targetItemId: true,
  // Payments/Subscriptions
  companySubscriptionId: true,
  // CHC
  utilityProviderId: true,
  companyUtilityProviderId: true,
  // Your Exports
  CSVExportId: true,
  // Etc
  transportationId: true,
  tagId: true,
  industryId: true,
  assetId: true,
  integrationName: true,
  verificationDetailsId: true,
  verificationItemId: true,
  tab: true,
  editField: true,
  email: true
};
