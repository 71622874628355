import { InputNumber } from 'antd';
import classNames from 'classnames';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import Error from '../../../components/Error';
import Label from '../Label';
import './inputNumberField.scss';

function InputNumberField(props) {
  const { name, title, className } = props;
  const { setFieldValue } = useFormikContext();
  return (
    <div
      className={classNames('ch-input-number-field-wrapper-new', {
        [className]: !!className
      })}
    >
      <div style={{ position: 'relative' }}>
        {title && <Label>{title}</Label>}
        <Field
          className="ch-input-number-field-new"
          as={InputNumber}
          {...props}
          stringMode={false}
          controls={false}
          onChange={(value) => {
            setFieldValue(name, value);
          }}
          onWheel={(e) => e.target.blur()}
        />
      </div>
      <ErrorMessage name={name}>
        {(message) => <Error className={`${name}__error`} message={message} />}
      </ErrorMessage>
    </div>
  );
}

export default InputNumberField;
