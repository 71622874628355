import classNames from 'classnames';
import { Loader } from '../../../components';
import { useMeContext } from '../../../context';
import LogoIconText from '../../assets/svgs/LogoIconText.svg';
import { Img } from '../Img';
import './Logo.scss';

export function Logo({ width, height, className }) {
  const { me, isLoadingGroupSettings } = useMeContext();
  const { groupSettings } = me;
  const { logoUrl, name = 'Carbonhound' } = groupSettings ?? {};
  return (
    <Loader isLoading={isLoadingGroupSettings}>
      <Img
        className={classNames('ch-logo', { [className]: !!className })}
        width={width}
        height={height}
        src={logoUrl || LogoIconText}
        alt={`${name} logo`}
      />
    </Loader>
  );
}
