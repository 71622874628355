import * as Yup from 'yup';

export const officeLocationValidation = Yup.object().shape({
  moveInDate: Yup.string().nullable().required('Move-in date is required'),
  address1: Yup.string().nullable(),
  address2: Yup.string()
    .nullable()
    .max(35, 'Should not contain more than 35 characters'),
  countryId: Yup.string().nullable().required('Country is required'),
  postalCode: Yup.string()
    .nullable()
    .max(10, 'Should not contain more than 10 characters'),
  isOwner: Yup.boolean().required('Required'),
  moveOutDate: Yup.string()
    .nullable()
    .when('isCurrentOffice', {
      is: false,
      then: Yup.string().nullable().required('Move-out date is required'),
      otherwise: Yup.string().nullable()
    })
});
