import { Col, Row } from 'antd';
import { Popover } from '../../../../components';
import { COLOURS_GRAY, COLOURS_GREEN } from '../../../../constants/colours';
import Icon from '../../Icon';
import { Paragraph } from '../../index';

export function PricingCardListItem({ item }) {
  const { title, included, popover } = item ?? {};
  return (
    <Row gutter={16} className="ch-pricing-card__list-item">
      <Col flex="1">
        <Paragraph bottomSpacing={0} size="rg" weight={600}>
          {title}
          {popover && (
            <Popover title={popover.title} content={popover.content} />
          )}
        </Paragraph>
        {typeof included === 'object' ? (
          <Paragraph weight={400} bottomSpacing={0}>
            {included.title}
            {included.popover && (
              <Popover
                title={included.popover.title}
                content={included.popover.content}
              />
            )}
          </Paragraph>
        ) : (
          <Icon
            color={!!included ? COLOURS_GREEN.GREEN_500 : COLOURS_GRAY.GRAY_400}
            size={24}
            name={!!included ? 'Check' : 'X'}
          />
        )}
      </Col>
    </Row>
  );
}
