import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class TagService {
  static async listTags(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.TAGS_LIST;
      const response = await AxiosInstance.post(
        API_URL.replace(':companySlug', companySlug),
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.tags,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.tags,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default TagService;
