import { Col, Row } from 'antd';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useInitiativeContext } from '../../../../context/InitiativeContext';
import { AppRoutes } from '../../../../routes';
import { Icon, Link, Title } from '../../../../stories/atoms';
import { InitiativeDetailSidebarMenu } from './CompanyInitiativeSidebarMenu';

export function CompanyInitiativeSidebar() {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;

  const { initiativeTemplate } = companyInitiative;
  return (
    <Row gutter={[0, 36]}>
      <Col span={24}>
        <Row gutter={[0, 28]}>
          <Col span={24}>
            <Link flex fontSize={16} fontWeight={600} to={AppRoutes.DASHBOARD}>
              <Icon
                size={16}
                color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                name="ChevronLeft"
                className="mb-2 mr-2"
              />
              Save & Exit
            </Link>
          </Col>
          <Col span={24}>
            <Title size="xs" bottomSpacing={8}>
              Initiative Name
            </Title>
            <Title size="xl" bottomSpacing={0}>
              {companyInitiative.name}
            </Title>
          </Col>
          <Col span={24}>
            <Title size="xs" bottomSpacing={8}>
              Template
            </Title>
            <Link
              fontWeight={600}
              flex
              to={AppRoutes.INITIATIVE_TEMPLATE.replace(
                ':initiativeTemplateId',
                initiativeTemplate.id
              )}
            >
              {initiativeTemplate.name}
              <Icon className="ml-2" name="ChevronRight" />
            </Link>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <InitiativeDetailSidebarMenu />
      </Col>
    </Row>
  );
}
