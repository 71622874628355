import { Col, Row, Steps as AntSteps } from 'antd';
import { Card } from '../../components';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import { VerificationLearnMore } from '../../features/Verification/VerificationLearnMore';
import { useModal } from '../../hooks';
import BrightspotLogo from '../../stories/assets/BrightspotLogo.png';
import { PawBlue, PawContractAlt } from '../../stories/assets/svgs';
import { BadgeList } from '../../stories/assets/svgs/Badges';
import ContractYellow from '../../stories/assets/svgs/ContractYellow';
import {
  Anchor,
  Button,
  Icon,
  Page,
  Paragraph,
  Steps,
  Title,
  VerificationBadge
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import VerificationTiers from '../../stories/molecules/VerificationTiers';
import './Verification.scss';

const verificationSteps = [
  {
    description: (
      <>
        <Title>Verification Consultation</Title>
        <Paragraph>
          To create your quote we will need to asses your verification needs
          including:
        </Paragraph>
        <ul>
          <li>Select verification tier</li>
          <li>Place deposit</li>
        </ul>
      </>
    )
  },
  {
    description: (
      <>
        <Title>Accept Quote</Title>
        <Paragraph>
          We’ll prepare a quote based on your needs and then we’ll require you
          to:
        </Paragraph>
        <ul>
          <li>Reporting Timeline</li>
          <li>Sources</li>
          <li>Budget</li>
        </ul>
      </>
    )
  },
  {
    description: (
      <>
        <Title>Prepare data</Title>
        <Paragraph>
          In order to accurately process your verification we’ll need:
        </Paragraph>
        <ul>
          <li>Supporting Documentation</li>
          <li>Attestation</li>
        </ul>
      </>
    )
  },
  {
    description: (
      <>
        <Title>Get verified</Title>
        <Paragraph>
          We will keep you updated so you can follow closely the progress of
          your verification.
        </Paragraph>
        <ul>
          <li>Duration: 1-2 weeks</li>
          <li>Additional data may be required</li>
        </ul>
      </>
    )
  }
];
function Verification() {
  const { Modal: LearnMoreModal, handleShowModal } = useModal({ width: '90%' });
  const { Step } = AntSteps;
  return (
    <Page className="verification p-0 mt-10">
      <Row>
        <Col
          className="verification__section verification__section__header "
          span={24}
        >
          <Row justify="center" gutter={[0, 24]}>
            <Col span={24} className="display-flex justify-center">
              <PawContractAlt />
            </Col>
            <Col span={24}>
              <Row gutter={[0, 24]} justify="center">
                <Col span={24} className="display-flex justify-center">
                  <Title bottomSpacing={0} size="xl">
                    Credible Verifications
                  </Title>
                </Col>
                <Col span={14} className="display-flex justify-center">
                  <Paragraph align="center" bottomSpacing={0}>
                    With a third party verified footprint you can stand out in
                    your industry and differentiate yourself.
                  </Paragraph>
                </Col>
                <Col span={24} className="display-flex justify-center">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://meetings.hubspot.com/sanders5/cabronhound-verification-inbound"
                  >
                    <Button>Book a free verification consultation</Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="verification__section" span={24}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={24} lg={6} xl={6} className="display-flex">
              <Card>
                <Row gutter={[0, 16]}>
                  <Col span={24} className="justify-center display-flex">
                    <Icon
                      name="CreditCard"
                      bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                  <Col span={24}>
                    <Title align="center" size="md">
                      Cost effective verifications
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Paragraph align="center">
                      Our quantification platform allows for efficient and
                      cost-effective verifications. We have verification options
                      for every budget.
                    </Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} className="display-flex">
              <Card>
                <Row gutter={[0, 16]}>
                  <Col span={24} className="justify-center display-flex">
                    <Icon
                      name="Flag"
                      bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                  <Col span={24}>
                    <Title align="center" size="md">
                      Be an industry leader
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Paragraph align="center">
                      A verified footprint is a way for you to stand out in your
                      industry, win new business, and meet the same standards as
                      Fortune 100 companies.
                    </Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} className="display-flex">
              <Card>
                <Row gutter={[0, 16]}>
                  <Col span={24} className="justify-center display-flex">
                    <Icon
                      name="BadgeCheck"
                      bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                  <Col span={24}>
                    <Title align="center" size="md">
                      Gain more credibility
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Paragraph align="center">
                      We verify through a third party,{' '}
                      <Anchor href="https://brightspot.co/">Brightspot</Anchor>{' '}
                      who applies ISO 14064-3 and provides a verification
                      opinion outlined in a statement or report.
                    </Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="verification__section full-width white">
          <Row justify="center" align="middle" gutter={[120, 24]} wrap>
            <Col flex="0 1 250" className="display-flex justify-center">
              <img
                className="verification__brightspot-logo"
                src={BrightspotLogo}
                alt="Brightspot"
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Row>
                <Col span={24}>
                  <Title bottomSpacing={8} size="lg">
                    Our Verification Partner
                  </Title>
                  <Paragraph>
                    Brightspot is an accredited verification body with the
                    Standards Council of Canada. <br />
                    <br />
                    Their experts have completed over 200 independent
                    verifications of industrial facility annual greenhouse gas
                    emission reports. Verification of these reports for
                    regulatory compliance requires in-depth knowledge of the
                    applicable quantification methodologies.
                  </Paragraph>
                  <Anchor fontWeight={600} href="https://brightspot.co/">
                    View More
                  </Anchor>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="verification__section mt-10">
          <Title bottomSpacing={40} align="center" size="lg">
            What comes with a Carbonhound verification
          </Title>
          <Row gutter={[0, 40]}>
            <Col span={24}>
              <Card>
                <Row justify="center">
                  <Col span={24}>
                    <Row justify="center">
                      <Col flex="0 1 0">
                        <ContractYellow />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={16}>
                    <Title align="center" size="lg">
                      Verification Statement or Report
                    </Title>
                    <Paragraph align="center">
                      Depending on which tier makes the most sense for your
                      carbon inventory you will either receive a verification
                      statement or a report. You can view the example to get an
                      idea of what you will be receiving from Brightspot.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Row justify="center" gutter={[24, 16]}>
                      <Col flex="0 1 auto">
                        <Anchor
                          href="https://storage.googleapis.com/carbonhound-prod-public/verification/brightspot/Carbonhound%20Verification%20Statement%20-%20Sample.pdf"
                          fontWeight={600}
                        >
                          Example Statement
                        </Anchor>
                      </Col>
                      <Col flex="0 1 auto">
                        <Anchor
                          href="https://storage.googleapis.com/carbonhound-prod-public/verification/brightspot/Carbonhound%20Verification%20Report%20-%20Sample.pdf"
                          fontWeight={600}
                        >
                          Example Report
                        </Anchor>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="verification__section__badges">
                <Row justify="center">
                  <Col span={24}>
                    <Title bottomSpacing={16} align="center" size="lg">
                      Badges
                    </Title>
                  </Col>
                  <Col span={12} className="mb-10">
                    <Paragraph bottomSpacing={24} align="center">
                      Once you’ve completed a successful verification, get
                      rewarded with badges that you can use on your website and
                      other marketing materials.
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Row justify="center">
                      <Col span={16}>
                        <Row gutter={[48, 48]} wrap justify="center">
                          {BadgeList.map((Badge) => (
                            <Col
                              color="verification__section__badge-list"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={8}
                              xl={8}
                              className="display-flex"
                            >
                              <VerificationBadge Badge={Badge} />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="verification__section full-width white">
          <Row justify="center" align="middle" gutter={[0, 160]}>
            <Col span={24}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <VerificationTiers />
                </Col>
              </Row>
            </Col>
            <Col span={20}>
              <Title bottomSpacing={56} align="center" size="lg">
                Verification steps
              </Title>
              <Steps responsive labelPlacement="vertical">
                {verificationSteps.map(({ description }) => (
                  <Step status="process" description={description} />
                ))}
              </Steps>
            </Col>
            <Col span={24}>
              <Row justify="center" align="middle" gutter={[0, 40]}>
                <Col span={24} className="display-flex justify-center">
                  <PawBlue />
                </Col>
                <Col span={24}>
                  <Title align="center" size="lg">
                    Not sure if a verification is right for you?
                  </Title>
                  <Paragraph align="center">
                    Book a consultation with our team to understand the
                    strategic benefits of verification for you.
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://meetings.hubspot.com/sanders5/cabronhound-verification-inbound"
                  >
                    <Button className="m-auto mb-6">
                      Book a free verification consultation
                    </Button>
                  </a>
                  <Button
                    className="m-auto"
                    type={BUTTON_TYPES.LINK}
                    onClick={handleShowModal}
                  >
                    Learn more about verification
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <LearnMoreModal className="no-padding">
        <VerificationLearnMore />
      </LearnMoreModal>
    </Page>
  );
}

export default Verification;
