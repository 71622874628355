import { Col, Row } from 'antd';
import { yupToFormErrors } from 'formik';
import produce from 'immer';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { EnergyItemForm, Table } from '../../../components';
import { BulkUploadType, UploadStatus } from '../../../constants';
import { useBulkUploadContext, useMeContext } from '../../../context';
import { analytics, capitalizeText, getFullName } from '../../../helpers';
import { useAsync, useModal, usePagination } from '../../../hooks';
import {
  EnergyService,
  NotificationService,
  TravelExpenseService
} from '../../../services';
import EmissionSourceService from '../../../services/emissionSourceService';
import { Button, Switcher } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { TravelItemForm } from '../../../stories/molecules';
import EmissionSourceItemForm from '../../EmissionSource/EmissionSourceItem/EmissionSourceItemForm';
import { EmissionSourceItemFormValidation } from '../../EmissionSource/EmissionSourceItem/EmissionSourceItemForm/EmissionSourceItemForm';
import { Footer } from '../Footer';
import { energyItemValidation } from './energyItemValidation';
import './MissingData.scss';
import { travelItemValidation } from './travelItemValidation';

const handleSetRowsForDisplay = ({
  unresolvedRowsResp,
  setResolvedRows,
  handleSetRowCount,
  setUnresolvedRows,
  resolvedRowsResp
}) => {
  const { list: unresolvedRowsRespList, count: unresolvedRowsRespCount } =
    unresolvedRowsResp;
  const { list: resolvedRowsRespList, count: resolvedRowsRespCount } =
    resolvedRowsResp;
  const { unresolved, resolved } = unresolvedRowsRespList.reduce(
    (acc, curr) => {
      if (curr.missingFields.length > 0) {
        acc.unresolved.list.push(curr);
      } else if (
        !resolvedRowsRespList.find((listItem) => listItem.id === curr.id)
      ) {
        acc.resolved.list.push(curr);
        acc.resolved.count += 1;
        acc.unresolved.count -= 1;
      }
      return acc;
    },
    {
      unresolved: { list: [], count: unresolvedRowsRespCount },
      resolved: {
        list: resolvedRowsRespList,
        count: parseInt(resolvedRowsRespCount, 10)
      }
    }
  );

  handleSetRowCount(unresolved.count, 'unResolved');
  setUnresolvedRows(unresolved.list);
  handleSetRowCount(resolved.count, 'resolved');
  setResolvedRows(resolved.list);
};

function MissingData() {
  const { me } = useMeContext();
  const { bulkUploadState, updateNotification, nextStep } =
    useBulkUploadContext();
  const { notification, missingCount, companySlug } = bulkUploadState;
  const [showResolved, setShowResolve] = useState(false);
  const [resolvedRows, setResolvedRows] = useState([]);
  const [unresolvedRows, setUnresolvedRows] = useState([]);
  const [rowTotals, setRowTotals] = useState({
    resolved: undefined,
    unResolved: undefined
  });
  const [selectedItem, setSelectedItem] = useState({
    index: undefined,
    data: {}
  });
  const emissionSourceDetailsId =
    notification.details?.emissionSourceDetailsId ??
    bulkUploadState.notificationReqParams.emissionSourceDetailsId;
  const uploadType = BulkUploadType[notification.details?.uploadType];
  const dateType =
    bulkUploadState.notification?.details?.dateType ||
    bulkUploadState.notificationReqParams.dateType;
  const isTravelView = uploadType === 'TRAVEL';
  const collectionId = notification.details?.collectionId;
  const handleFetchEmissionSourceDetails = useCallback(
    async () =>
      await EmissionSourceService.getEmissionSourceDetails(
        emissionSourceDetailsId
      ),
    [emissionSourceDetailsId]
  );
  const { value: emissionSourceDetails } = useAsync({
    asyncFunction: handleFetchEmissionSourceDetails,
    defaultValue: {}
  });
  const { paginationParams: resolvedPagination } = usePagination({
    pageSize: 5,
    paginationArray: resolvedRows,
    orderBy: ['id']
  });
  const {
    paginationParams: unResolvedPagination,
    handleSetPaginationParams: handleSetUnresolvedPaginationParams
  } = usePagination({
    pageSize: 5,
    paginationArray: unresolvedRows,
    orderBy: ['id']
  });
  const handleEditItem = () => {
    analytics.track('Edit Item Clicked', me, {
      level1: 'Bulk Uploads',
      level2: 'Missing Data'
    });
  };
  const {
    Modal: CustomFormModal,
    handleShowModal: handleShowCustomFormModal,
    handleCloseModal: handleCloseCustomFormModal
  } = useModal({ width: '60%', onShowModal: handleEditItem });
  const {
    Modal: EnergyFormModal,
    handleCloseModal: handleCloseEnergyFormModal,
    handleShowModal: handleShowEnergyFormModal
  } = useModal({ width: '60%', onShowModal: handleEditItem });
  const {
    Modal: TravelFormModal,
    handleShowModal: handleShowTravelFormModal,
    handleCloseModal: handleCloseTravelFormModal
  } = useModal({ width: '60%', onShowModal: handleEditItem });

  const columns = [
    {
      title: 'Date',
      width: showResolved ? '50%' : '33%',
      render: (_, item) => {
        const { billingStart, billingEnd, date, startDate, endDate } = item;
        const sDate = billingStart || startDate;
        const eDate = billingEnd || endDate;
        if (!!sDate && !!eDate) {
          return (
            <span>
              {`${moment.utc(sDate).format('MMM DD, YYYY')}  - ${moment
                .utc(eDate)
                .format('MMM DD, YYYY')}`}
            </span>
          );
        }
        if (!!sDate) {
          return <span>{moment.utc(sDate).format('MMM DD, YYYY')}</span>;
        }
        if (!!date) {
          return <span>{moment.utc(date).format('MMM DD, YYYY')}</span>;
        }
        return <span />;
      }
    },
    {
      title: isTravelView ? 'Owner' : 'Usage',
      dataIndex: isTravelView ? 'total' : 'activity',
      width: showResolved ? '50%' : '33%',
      render: (_, item) => {
        if (!!isTravelView) {
          return <span>{getFullName(item.user)}</span>;
        }
        return (
          <span>
            {`${item.activity} ${
              capitalizeText(item.activityUnit?.unit) ?? ''
            }`}
          </span>
        );
      }
    },

    {
      title: 'Missing',
      dataIndex: 'missingFields',
      width: '33%',
      render: (_, item) => item.missingFields?.join(', ')
    },
    {
      title: '',
      width: '110px',
      render: (_, item, index) => (
        <span className="text-right">
          <Button
            onClick={() => {
              setSelectedItem((prev) =>
                produce(prev, (draft) => {
                  draft.data = item;
                  draft.index = index;
                })
              );
              if (
                bulkUploadState.notification.details?.uploadType ===
                BulkUploadType.TRAVEL
              ) {
                handleShowTravelFormModal();
              } else if (
                bulkUploadState.notification.details?.uploadType ===
                BulkUploadType.ENERGY
              ) {
                handleShowEnergyFormModal();
              } else if (
                bulkUploadState.notification.details?.uploadType ===
                BulkUploadType.CUSTOM
              ) {
                handleShowCustomFormModal();
              }
            }}
            disabled={showResolved}
            type={BUTTON_TYPES.TEXT}
            icon="ChevronRight"
          >
            {showResolved ? 'Resolved' : <>Update</>}
          </Button>
        </span>
      )
    }
  ];

  const handleResolveItem = async () => {
    await loadDataFn();
    setSelectedItem(
      produce(selectedItem, (draft) => {
        draft.data = {};
        draft.index = undefined;
      })
    );

    if (uploadType === BulkUploadType.TRAVEL) {
      handleCloseTravelFormModal();
    } else if (uploadType === BulkUploadType.ENERGY) {
      handleCloseEnergyFormModal();
    } else if (uploadType === BulkUploadType.CUSTOM) {
      handleCloseCustomFormModal();
    }
  };

  const handleSetRowCount = (rowCount, state) => {
    setRowTotals((prevState) =>
      produce(prevState, (draft) => {
        draft[state] = rowCount;
      })
    );
  };

  const handleLoadCustomItems = useCallback(async () => {
    if (unresolvedRows.length === 1 && rowTotals.unResolved > 1) {
      handleSetUnresolvedPaginationParams({
        ...unResolvedPagination,
        pageNumber: 1,
        prevPageNumber: 1,
        previousValue: undefined
      });
    }
    const unresolvedRowsResp =
      await EmissionSourceService.listEmissionSourceItems({
        pagination: unResolvedPagination,
        uploadProcessId: notification.id,
        isComplete: false,
        emissionSourceDetailsId
      });
    const resolvedRowsResp =
      await EmissionSourceService.listEmissionSourceItems({
        pagination: unResolvedPagination,
        uploadProcessId: notification.id,
        isComplete: true,
        emissionSourceDetailsId
      });
    unresolvedRowsResp.list?.forEach(async (r) => {
      r.missingFields = [];
      r.dateType = dateType;

      if (r.isComplete) {
        return;
      }
      try {
        await EmissionSourceItemFormValidation.validateSync(r, {
          abortEarly: false
        });
      } catch (err) {
        r.missingFields = [
          ...r.missingFields,
          ...Object.values(yupToFormErrors(err))
        ];
      }
    });
    handleSetRowsForDisplay({
      unresolvedRowsResp,
      setResolvedRows,
      handleSetRowCount,
      setUnresolvedRows,
      resolvedRowsResp
    });
  }, [resolvedPagination.pageNumber, unResolvedPagination.pageNumber]);

  const handleLoadTravelItems = useCallback(async () => {
    if (unresolvedRows.length === 1 && rowTotals.unResolved > 1) {
      handleSetUnresolvedPaginationParams({
        ...unResolvedPagination,
        pageNumber: 1,
        prevPageNumber: 1,
        previousValue: undefined
      });
    }
    const unresolvedRowsResp = await TravelExpenseService.fetchTravelExpenses({
      pagination: unResolvedPagination,
      companySlug,
      uploadProcessId: notification.id,
      isComplete: false
    });
    const resolvedRowsResp = await TravelExpenseService.fetchTravelExpenses({
      pagination: resolvedPagination,
      companySlug,
      uploadProcessId: notification.id,
      isComplete: true
    });

    unresolvedRowsResp.list.map(async (r) => {
      if (r.isComplete) {
        return;
      }
      r.missingFields = [];
      try {
        await travelItemValidation.validateSync(
          {
            ...r,
            travelCategoryId: r.travelCategory?.id,
            travelCategoryName: r.travelCategory?.name,
            conversionUnitName: r.conversionUnitName,
            departureLocationId: r.departureLocation?.id,
            destinationLocationId: r.destinationLocation?.id,
            userId: r.user?.id,
            isRoundTrip: r.details?.isRoundTrip
          },
          { abortEarly: false }
        );
      } catch (err) {
        r.missingFields = [
          ...r.missingFields,
          ...Object.values(yupToFormErrors(err))
        ];
      }
    });

    handleSetRowsForDisplay({
      unresolvedRowsResp,
      setResolvedRows,
      handleSetRowCount,
      setUnresolvedRows,
      resolvedRowsResp
    });
  }, [resolvedPagination.pageNumber, unResolvedPagination.pageNumber]);

  const handleLoadEnergyItems = useCallback(async () => {
    if (unresolvedRows.length === 1 && rowTotals.unResolved > 1) {
      handleSetUnresolvedPaginationParams({
        ...unResolvedPagination,
        pageNumber: 1,
        prevPageNumber: 1,
        previousValue: undefined
      });
    }
    const unresolvedRowsResp = await EnergyService.fetchEnergyItems(
      collectionId,
      {
        pagination: unResolvedPagination,
        companySlug,
        uploadProcessId: notification.id,
        isComplete: false,
        emissionSourceDetailsId
      }
    );
    const resolvedRowsResp = await EnergyService.fetchEnergyItems(
      collectionId,
      {
        pagination: resolvedPagination,
        companySlug,
        uploadProcessId: notification.id,
        isComplete: true,
        emissionSourceDetailsId
      }
    );
    unresolvedRowsResp.list.forEach(async (r) => {
      r.missingFields = [];
      if (r.isComplete) {
        return;
      }
      try {
        await energyItemValidation.validateSync(
          {
            ...r,
            energyTypeName: r.energyType?.name,
            conversionUnitName: r.activityUnit?.name,
            activity: r.activity,
            billingStart: r.billingStart,
            billingEnd: r.billingEnd
          },
          { abortEarly: false }
        );
      } catch (err) {
        r.missingFields = [
          ...r.missingFields,
          ...Object.values(yupToFormErrors(err))
        ];
      }
    });
    handleSetRowsForDisplay({
      unresolvedRowsResp,
      setResolvedRows,
      handleSetRowCount,
      setUnresolvedRows,
      resolvedRowsResp
    });
  }, [
    resolvedPagination.pageNumber,
    unResolvedPagination.pageNumber,
    collectionId,
    emissionSourceDetailsId
  ]);

  const loadDataFn =
    uploadType === 'TRAVEL'
      ? handleLoadTravelItems
      : uploadType === 'ENERGY'
      ? handleLoadEnergyItems
      : handleLoadCustomItems;

  const { isLoading: isListLoading } = useAsync({
    asyncFunction: loadDataFn,
    defaultValue: []
  });

  const handleCompleteNotification = async () => {
    await NotificationService.updateNotification(
      {
        status: UploadStatus.COMPLETE,
        notificationId: notification.id
      },
      (notification) => {
        updateNotification({ notification });
        nextStep();
      },
      () => {},
      () => {}
    );
  };

  const { isLoading: isUpdatingNotification, execute: updateNotificationFn } =
    useAsync({ asyncFunction: handleCompleteNotification, immediate: false });

  const handleCompleteNext = async () => {
    await updateNotificationFn();

    analytics.track('Step Completed', me, {
      level1: 'Bulk Uploads',
      level2: 'Complete'
    });
  };

  return (
    <div className="bulk-upload-form-missing-data">
      <Row align="middle">
        <Col span={16}>
          <h6 className="text-bd">Resolve Missing Data Items</h6>
          <p>
            Complete the items we were unable to fully process before continuing
          </p>
        </Col>
        <Col span={8} className="text-right">
          <Switcher
            onClick={(value) => {
              setShowResolve(value);
            }}
            activeValue={showResolved}
            options={[
              {
                label: `In Progress (${rowTotals.unResolved || 0})`,
                value: false
              },
              { label: `Resolved (${rowTotals.resolved || 0})`, value: true }
            ]}
          />
        </Col>
      </Row>
      <Row className="bulk-upload-form-missing-data__table">
        <Col span={24}>
          {showResolved ? (
            <Table
              showPagination
              paginationParams={resolvedPagination}
              loading={isListLoading}
              rowKey="id"
              primary
              columns={columns.filter((c) => c.dataIndex !== 'missing')}
              dataSource={resolvedRows}
              total={rowTotals.resolved}
            />
          ) : (
            <Table
              total={rowTotals.unResolved}
              showPagination
              paginationParams={unResolvedPagination}
              loading={isListLoading}
              rowKey="id"
              primary
              columns={columns}
              dataSource={unresolvedRows}
            />
          )}
        </Col>
      </Row>
      <Footer
        analyticsStepContext="Missing Data"
        nextCopy={missingCount > 0 && 'Complete'}
        isLoading={isUpdatingNotification}
        onNextFn={handleCompleteNext}
        isStepInvalid={unresolvedRows.length > 0}
      />
      <EnergyFormModal>
        <EnergyItemForm
          isEnergyTypeFieldDisabled={false}
          analyticsContext={{
            level1: 'Bulk Uploads',
            level2: 'Missing Data'
          }}
          energyItem={{ ...selectedItem.data, energyDetailsId: collectionId }}
          onSuccess={handleResolveItem}
          onCancel={handleCloseEnergyFormModal}
        />
      </EnergyFormModal>{' '}
      <TravelFormModal>
        <TravelItemForm
          isTravelCategoryFieldDisabled={false}
          analyticsContext={{
            level1: 'Bulk Uploads',
            level2: 'Missing Data'
          }}
          travelItem={selectedItem.data}
          onSuccess={handleResolveItem}
          onClose={handleCloseTravelFormModal}
          emissionSourceDetails={{
            id: selectedItem.data.id,
            details: selectedItem.data.details
          }}
        />
      </TravelFormModal>
      <CustomFormModal>
        <EmissionSourceItemForm
          emissionSourceDetails={{
            id: selectedItem.data.id,
            dateType
          }}
          emissionSourceItem={selectedItem.data}
          unitFamilyName={emissionSourceDetails?.emissionFactor?.unitFamilyName}
          onSuccess={handleResolveItem}
        />
      </CustomFormModal>
    </div>
  );
}
export default MissingData;
