import { Link, Outlet } from 'react-router-dom';
import { TrailSegmentsSidebar } from '../../../components';
import {
  LayoutContextProvider,
  OfficeOnboardingContextProvider
} from '../../../context';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './trailsSegments.scss';

function TrailsSegments() {
  return (
    <div className="trails-segments-forms">
      <LayoutContextProvider>
        <OfficeOnboardingContextProvider>
          <TrailSegmentsSidebar />
          <div className="footprint-segment-form">
            <div className="justify-end display-flex mb-5">
              <Link to={AppRoutes.DASHBOARD}>
                <Button type={BUTTON_TYPES.SECONDARY}>
                  Save & return to dashboard
                </Button>
              </Link>
            </div>
            <Outlet />
          </div>
        </OfficeOnboardingContextProvider>
      </LayoutContextProvider>
    </div>
  );
}

export default TrailsSegments;
