import * as Yup from 'yup';

export const GenerateCollectionEstimateFormValidation = Yup.object().shape({
  hasSeparateMeter: Yup.boolean().nullable().required('Required'),
  estimationMethod: Yup.string().nullable().required('Required'),
  officeSquareFootage: Yup.number().nullable().required('Required'),
  buildingSquareFootage: Yup.number().when('hasSeparateMeter', {
    is: false,
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable()
  })
});
