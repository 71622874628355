import { Col, Row, Space } from 'antd';

import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ApiRoutes } from '../../../routes';
import { axiosInstance } from '../../../interceptors';
import { Popover } from '../../../components';
import { VehicleCategories, YesOrNoOptions } from '../../../constants';
import FuelType from '../../../constants/fuelType';
import TransportationType from '../../../constants/transportationType';
import { useMeContext, useMetaContext } from '../../../context';
import { getDropdownOptions, capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { VehicleService } from '../../../services';

import { Button, DatePicker, Input, Radio, Select, Title } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import { vehicleFormValidation } from './vehicleFormValidation';

function VehicleForm({ baseVehicleType, onClose, onSuccess }) {
  const [formValues, setFormValues] = useState({});
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);

  const { DrivingVehicles } = VehicleCategories;
  const { me } = useMeContext();
  const { meta, setVehicleTypes } = useMetaContext();
  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};

  const isFuelTypeDisabled = baseVehicleType === TransportationType.MOTORBIKE;

  useEffect(() => {
    const getFuelTypes = async () => {
      const response = await axiosInstance.get(ApiRoutes.FUEL_TYPE_METAS);
      const fuelTypeData = response.data.fuelTypes.map((fuelType) => ({
        ...fuelType,
        friendlyName:
          fuelType.name === 'HYBRID ELECTRIC'
            ? 'Hybrid'
            : capitalizeText(fuelType.name)
      }));

      const unleadedIdx = fuelTypeData.findIndex(
        (el) => el.name === 'UNLEADED GAS'
      );
      fuelTypeData.splice(0, 1, fuelTypeData[unleadedIdx]);
      fuelTypeData.pop();
      setFuelTypeOptions(
        getDropdownOptions(fuelTypeData, 'friendlyName', 'id')
      );
    };

    getFuelTypes();
  }, []);

  const unleadedOpt = fuelTypeOptions?.find(
    (opt) => opt.label === FuelType.UNLEADED_GAS
  );

  const handleLoadVehicleTypes = useCallback(async () => {
    await setVehicleTypes();
    return getDropdownOptions(meta.vehicleTypes, 'friendlyName', 'id');
  }, [meta.vehicleTypes]);

  const { value: vehicleTypeOptions } = useAsync({
    asyncFunction: handleLoadVehicleTypes,
    defaultValue: []
  });

  const handleSubmit = (vehicle) => {
    vehicle.baseVehicleType = baseVehicleType;
    if (vehicle.modelYear)
      vehicle.modelYear = moment.utc(vehicle.modelYear).format('YYYY');
    VehicleService.createVehicle(
      { companySlug: me.company.slug, loggedInUserId },
      vehicle,
      (vehicle) => {
        onSuccess(vehicle);
      },
      () => {},
      () => {
        onClose();
      }
    );
  };

  useEffect(() => {
    if (unleadedOpt && baseVehicleType === TransportationType.MOTORBIKE) {
      setFormValues({
        ...formValues,
        fuelTypeId: unleadedOpt.value
      });
    }
  }, [isFuelTypeDisabled, fuelTypeOptions, baseVehicleType]);

  return (
    <Row>
      <Col span={24}>
        <Title size="lg">Add Vehicle</Title>
      </Col>
      <Col span={24}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={vehicleFormValidation}
        >
          {({ values, _errors, isValid, _dirty, setFieldValue }) => (
            <Form>
              <Row gutter={[20, 24]}>
                <Col span={24}>
                  <Input
                    title="Vehicle name"
                    type="text"
                    name="friendlyName"
                    placeholder="Enter Vehicle name"
                  />
                </Col>
                <Col span={24}>
                  <Radio
                    raised
                    title="Company Owned?"
                    value={values?.isCompanyOwned}
                    name="isCompanyOwned"
                    options={YesOrNoOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <Select
                    key={unleadedOpt?.value}
                    title={
                      <span>
                        Fuel Type
                        {baseVehicleType === TransportationType.CAR && (
                          <Popover content="Transmission and Distribution losses on energy used by electric vehicles are currently not included in our calculations." />
                        )}
                      </span>
                    }
                    name="fuelTypeId"
                    value={formValues?.fuelTypeId}
                    options={fuelTypeOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Select Fuel type"
                    disabled={isFuelTypeDisabled}
                  />
                </Col>
                <Col span={24}>
                  <Select
                    title={
                      <div>
                        Vehicle Type
                        {DrivingVehicles.includes(baseVehicleType) && (
                          <Popover
                            title={
                              baseVehicleType === TransportationType.CAR ? (
                                <ul>
                                  <li>Small: Up to 1.4L</li>
                                  <li>Medium: Up to 2L</li>
                                  <li>Large: More than 2L</li>
                                  <li>Average: Unknown Engine size</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>Small: Up to 125 cc</li>
                                  <li>Medium: 126-499 cc</li>
                                  <li>Large: 500 cc+</li>
                                  <li>Average: Unknown Engine size</li>
                                </ul>
                              )
                            }
                          />
                        )}
                      </div>
                    }
                    name="vehicleTypeId"
                    value={formValues?.vehicleTypeId}
                    options={vehicleTypeOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Select Vehicle type"
                  />
                </Col>
                <Col span={24}>
                  <DatePicker
                    defaultPickerValue={moment().subtract(10, 'years')}
                    name="modelYear"
                    defaultValue={values?.modelYear}
                    picker="year"
                    title="Manufacture year"
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 20]} className="mt-4 mb-4">
                    <Col span={24} className="text-right">
                      <Space>
                        <Button
                          type={BUTTON_TYPES.SECONDARY}
                          htmlType="button"
                          small
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="ml-2"
                          htmlType="submit"
                          disabled={!isValid}
                        >
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

export default VehicleForm;
