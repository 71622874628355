function LegendIndicatorData() {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7" height="7" rx="3.5" fill="#2563EB" />
    </svg>
  );
}
export default LegendIndicatorData;
