import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class EmissionFactorService {
  static async listEmissionFactorDetails(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_FACTORS.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.emissionFactorDetails,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.emissionFactorDetails,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listEmissionFactorDetailsById(
    { efDetailsId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_FACTORS_COLLECTION_LIST.replace(
        ':efDetailsId',
        efDetailsId
      )}`;
      const response = await AxiosInstance.get(API_URL);
      onSuccess?.(response.data?.collections);
      return response.data?.collections;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default EmissionFactorService;
