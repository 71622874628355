import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class CollectionService {
  static async listCollections(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COLLECTION_COMPANY.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        {
          params
        }
      );
      onSuccess?.({
        list: response.data?.collections,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.collections,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getCollection(
    { collectionId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COLLECTION}/${collectionId}`;
      const response = await AxiosInstance.get(API_URL, {
        params
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createCollection({ companySlug, ...collectionDetails }) {
    try {
      const BASE_URL = `${ApiRoutes.CREATE_COLLECTION}`;
      const API_URL = BASE_URL.replace(':companySlug', companySlug);
      const response = await AxiosInstance.post(API_URL, {
        ...collectionDetails
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  static async createCollectionBulk(
    { companySlug, ...collectionDetails },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.CREATE_COLLECTION_BULK.replace(
        ':companySlug',
        companySlug
      );
      const response = await AxiosInstance.post(API_URL, {
        ...collectionDetails
      });
      onSuccess?.();
      return response;
    } catch (err) {
      onError?.();
      console.error(err);
    } finally {
      onFinal?.();
    }
  }

  static async updateCollection(
    { collectionId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const BASE_URL = `${ApiRoutes.UPDATE_COLLECTION}`;
      const API_URL = BASE_URL.replace(':collectionId', collectionId);

      const response = await AxiosInstance.put(API_URL, { ...params });
      onSuccess?.();
      return response;
    } catch (err) {
      console.error(err);
      onError?.();
      throw Error(err);
    } finally {
      onFinal?.();
    }
  }

  static async deleteCollection({ collectionId }, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.COLLECTION}/${collectionId}`;
      await AxiosInstance.delete(API_URL);
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createCollectionEmissionSource(
    { collectionId, emissionSourceDetailsId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const BASE_URL = `${ApiRoutes.COLLECTION_EMISSION_SOURCE}`.replace(
        ':collectionId',
        collectionId
      );
      const API_URL = BASE_URL.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      );
      const response = await AxiosInstance.post(API_URL);
      onSuccess?.();
      return response;
    } catch (err) {
      console.error(err);
      onError?.();
    } finally {
      onFinal?.();
    }
  }

  static async deleteCollectionEmissionSource(
    { collectionId, emissionSourceDetailsId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const BASE_URL = `${ApiRoutes.COLLECTION_EMISSION_SOURCE}`.replace(
        ':collectionId',
        collectionId
      );
      const API_URL = BASE_URL.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      );

      const response = await AxiosInstance.delete(API_URL);
      onSuccess?.();
      return response;
    } catch (err) {
      console.error(err);
      onError?.();
    } finally {
      onFinal?.();
    }
  }

  static async createCollectionEmissionSourceBulk(
    { collectionId, emissionSourceDetailsIds },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const BASE_URL = `${ApiRoutes.CREATE_COLLECTION_EMISSION_SOURCE_BULK}`;
      const API_URL = BASE_URL.replace(':collectionId', collectionId);
      const response = await AxiosInstance.post(API_URL, {
        emissionSourceDetailsIds
      });
      onSuccess?.();
      return response;
    } catch (err) {
      onError?.();
      console.error(err);
    } finally {
      onFinal?.();
    }
  }

  static async processCollectionEstimations({ collectionId, ...params }) {
    try {
      const BASE_URL = `${ApiRoutes.COLLECTION_ESTIMATION}`;
      const API_URL = BASE_URL.replace(':collectionId', collectionId);
      const response = await AxiosInstance.post(API_URL, params);
      return response;
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
  }
}

export default CollectionService;
