import * as Yup from 'yup';

export const employeeDetailsCommuteValidation = Yup.object().shape({
  averageCommuteDaysPerWeek: Yup.number()
    .typeError('Value is not valid')
    .nullable()
    .required('Average number of days is required')
    .min(1, 'Number of days should be between 1 and 7')
    .max(7, 'Number of days should be between 1 and 7'),
  isCurrentCommute: Yup.boolean()
    .nullable()
    .required('Please select any one option'),
  startDate: Yup.string().nullable().required('Start date is required'),
  endDate: Yup.string()
    .nullable()
    .when('isCurrentCommute', {
      is: false,
      then: Yup.string().nullable().required('End date is required'),
      otherwise: Yup.string().nullable()
    }),
  vehicleId: Yup.string()
    .nullable()
    .when('isVehicleEnabled', {
      is: true,
      then: Yup.string().nullable().required('Please select any vehicle'),
      otherwise: Yup.string().nullable()
    }),
  isCarpooling: Yup.boolean()
    .nullable()
    .when('isVehicleEnabled', {
      is: true,
      then: Yup.boolean().nullable().required('Please select any one option'),
      otherwise: Yup.boolean().nullable()
    }),
  carpoolingCount: Yup.number()
    .typeError('Value is not valid')
    .nullable()
    .when('isCarpooling', {
      is: true,
      then: Yup.number()
        .typeError('Value is not valid')
        .nullable()
        .required('Carpooling count is required')
        .min(1, 'Value should be atleast 1'),
      otherwise: Yup.number()
        .typeError('Value is not valid')
        .nullable()
        .min(1, 'Value should be atleast 1')
    })
});
