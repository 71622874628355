import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { Popover } from '../../../../../components';
import { Input, Select } from '../../../../../stories/atoms';
import { targetIntensityAggregateOptions } from '../../TargetSettingStrategy.constants';

export function IntensityFormRowCustom() {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Select
          title={
            <span>
              Average or Total Metric
              <Popover
                className="ml-2"
                title="Average vs. Total"
                content="Select whether you want us to measure against the total of your base year data, or if you want us to use the average. "
              >
                <InfoCircleOutlined />
              </Popover>
            </span>
          }
          name="aggType"
          placeholder="Select"
          options={targetIntensityAggregateOptions}
          setFieldValue={setFieldValue}
          value={values?.aggType}
        />
      </Col>
      <Col span={12}>
        <Input
          title="Custom intensity metric"
          name="customName"
          value={values?.customName}
          placeholder="Enter metric title"
        />
      </Col>
      <Col span={12}>
        <Input
          title={
            <span>
              Description (optional)
              <Popover
                className="ml-2"
                title="Custom metrics"
                content="We will calculate the reductions needed for you to meet your goals based off of your custom metrics. Try to be detailed so your team is clearly aligned on the goal. "
              >
                <InfoCircleOutlined />
              </Popover>
            </span>
          }
          placeholder="Describe your custom metric"
          name="description"
          value={values?.description}
        />
      </Col>
    </Row>
  );
}
