import { Col, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import { NotificationStatus } from '../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import { useAsync } from '../../../../hooks';
import { analytics } from '../../../../helpers';
import { NotificationService } from '../../../../services';
import { Button, Icon, Paragraph } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';

function FooterActions({ action, footerButtonProps, isDataIncomplete }) {
  const { updatedAt, status } = action;
  const isComplete = status === NotificationStatus.COMPLETE;
  if (isComplete) {
    return (
      <Paragraph bottomSpacing={0}>
        Completed{' '}
        {!!updatedAt && formatInTimeZone(updatedAt, 'UTC', 'MMM dd, yyyy')}
      </Paragraph>
    );
  }
  if (isDataIncomplete) {
    return (
      <Paragraph bottomSpacing={0}>
        Data must be completed to dismiss action
      </Paragraph>
    );
  }
  return <Button {...footerButtonProps} />;
}

export function ActionsModalFooter({
  action,
  onSuccess,
  onClose,
  analyticsContext,
  isDataIncomplete
}) {
  const { me } = useMeContext();
  const { id } = action;

  const handleCompleteNotification = async () => {
    await NotificationService.updateNotificationAction({
      status: NotificationStatus.COMPLETE,
      id
    });

    analytics.track('Action Completed', me, {
      ...analyticsContext,
      notification: action
    });
    onSuccess?.();
    onClose?.();
  };
  const { execute: editNotification } = useAsync({
    asyncFunction: handleCompleteNotification,
    immediate: false
  });
  const footerButtonProps = {
    type: BUTTON_TYPES.SECONDARY,
    onClick: editNotification,
    children: 'Mark as Complete'
  };
  return (
    <Row justify="end">
      {!id && (
        <Col flex={1} className="display-flex align-center">
          <Space size={12}>
            <Icon
              color={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
              name="Exclamation"
            />
            <Paragraph bottomSpacing={0}>
              This action hasn't been added to the project yet, once it has been
              added you can edit all the fields
            </Paragraph>
          </Space>
        </Col>
      )}
      <Col>
        <FooterActions
          isDataIncomplete={isDataIncomplete}
          footerButtonProps={footerButtonProps}
          action={action}
        />
      </Col>
    </Row>
  );
}
