import { Col, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Card, Popover } from '../../../components';
import { TransportationType } from '../../../constants';
import {
  useEmployeeCommuteContext,
  useLayoutContext,
  useMeContext
} from '../../../context';
import { analytics } from '../../../helpers';
import { AppRoutes } from '../../../routes';
import { CommuteService, VehicleService } from '../../../services';
import {
  Button,
  DatePicker,
  Input,
  InputNumberField,
  Radio,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../stories/atoms/Layout';
import './EmployeeDetailsCommute.scss';
import { employeeDetailsCommuteValidation } from './EmployeeDetailsCommuteValidation';

function EmployeeDetailsCommute() {
  const {
    setActiveCommute,
    employeeCommuteDetails: { activeCommute }
  } = useEmployeeCommuteContext();

  const params = useParams();
  const { navStep } = useLayoutContext();

  const { commuteId } = params;

  const [formValues, setFormValues] = useState({});

  const [formLoading, setFormLoading] = useState(false);

  const [_commuteVehicleOptions, setCommuteVehicleOptions] = useState([]);

  const { me } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};

  const handleNext = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
  };

  const drivingVehicles = [
    TransportationType.CAR,
    TransportationType.MOTORBIKE
  ];

  const yesOrNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const handlePrevious = () => {
    navStep(
      generatePath(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS, {
        commuteId
      })
    );
  };

  const handleSubmit = (commute) => {
    commute.id = commuteId;
    setFormLoading(true);
    CommuteService.updateCommute(
      { companySlug: me?.company?.slug, commute },
      () => {
        handleNext();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (commuteId) {
      CommuteService.getCommuteDetails(
        commuteId,
        (commute) => {
          CommuteService.fetchCommuteItems(
            commuteId,
            (commuteItems) => {
              const vehicles = commuteItems.filter((commuteItem) =>
                drivingVehicles.includes(commuteItem?.transportationType?.name)
              );
              const isVehicleEnabled = vehicles.length > 0;
              // If relevant, filter by the type of vehicle
              if (isVehicleEnabled) {
                VehicleService.fetchVehicles(
                  { companySlug: me?.company?.slug, loggedInUserId },
                  { baseVehicleType: vehicles[0].transportationType.name },
                  (vehicles) => {
                    setCommuteVehicleOptions(
                      vehicles.map((vehicle) => ({
                        ...vehicle,
                        label: vehicle.friendlyName,
                        value: vehicle.id
                      }))
                    );
                  },
                  () => {},
                  () => {}
                );
              }
              setActiveCommute(commute);
              const {
                averageCommuteDaysPerWeek,
                startDate,
                endDate,
                commuteVehicleId,
                vehicleAssetId,
                isCarpooling,
                carpoolingCount
              } = commute;
              setFormValues({
                isVehicleEnabled,
                vehicles,
                averageCommuteDaysPerWeek,
                startDate: startDate
                  ? moment.utc(startDate).format('YYYY-MM-DD')
                  : undefined,
                endDate: endDate
                  ? moment.utc(endDate).format('YYYY-MM-DD')
                  : undefined,
                isCurrentCommute: !endDate,
                vehicleId: commuteVehicleId ?? vehicleAssetId,
                isCarpooling,
                carpoolingCount
              });
            },
            () => {},
            () => {}
          );
        },
        () => {},
        () => {}
      );
    }
  }, []);
  return (
    <div className="employee-commute-details">
      <Title bottomSpacing={56} size="xl">
        Commute Details
      </Title>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={employeeDetailsCommuteValidation}
      >
        {({ values, _errors, isValid, _dirty, setFieldValue }) => (
          <Form>
            <Card className="employee-commute-details__card">
              <h5 className="text-bd">Your commute</h5>
              <Row gutter={[20, 10]} align="middle">
                <Col span={12} className="employee-commute-details__no-of-days">
                  <InputNumberField
                    title="Average no of days per week you commute"
                    type="number"
                    name="averageCommuteDaysPerWeek"
                    placeholder="Enter no of days"
                    addonAfter="Days"
                  />
                </Col>
                <Col span={12}>
                  <Radio
                    raised
                    title="Is this your current commute?"
                    value={values?.isCurrentCommute}
                    name="isCurrentCommute"
                    options={yesOrNoOptions}
                    onChange={(e) => {
                      analytics.track('Current commute', me, {
                        level1: 'Office-Based Operations',
                        level2: 'Employee Details',
                        level3: 'Commute details',
                        commuteDetails: activeCommute
                      });
                      setFieldValue('isCurrentCommute', e.target?.value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  {values?.isCurrentCommute ? (
                    <DatePicker
                      name="startDate"
                      defaultValue={values?.startDate}
                      title="When did you start taking this commute?"
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <DatePicker
                      fromName="startDate"
                      toName="endDate"
                      isRange
                      defaultValue={
                        values?.startDate && values?.endDate
                          ? [values?.startDate, values?.endDate]
                          : []
                      }
                      title="When did you start taking this commute?"
                      setFieldValue={setFieldValue}
                      name="date"
                    />
                  )}
                </Col>

                {values?.isVehicleEnabled && (
                  <Col span={24}>
                    <Row gutter={[20, 10]}>
                      <Col span={12}>
                        <Radio
                          title="Do you carpool with anybody to get to work?"
                          value={values?.isCarpooling}
                          name="isCarpooling"
                          options={yesOrNoOptions}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      {values?.isCarpooling && (
                        <Col span={12}>
                          <Input
                            title={
                              <span>
                                How many people do you carpool with?
                                <Popover content="Include yourself!" />
                              </span>
                            }
                            type="number"
                            name="carpoolingCount"
                            placeholder="Enter no of people"
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
            </Card>
            <Footer className="display-flex justify-end">
              <Space>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  htmlType="button"
                  onClick={handlePrevious}
                  prefixIcon="ArrowLeft"
                >
                  Previous
                </Button>
                <Button
                  htmlType="submit"
                  icon="ArrowRight"
                  disabled={!isValid || formLoading}
                  loading={formLoading}
                >
                  Next
                </Button>
              </Space>
            </Footer>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EmployeeDetailsCommute;
