function ArrowRight() {
  return (
    <path
      d="M14 5L21 12M21 12L14 19M21 12H3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowRight;
