import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import './Loader.scss';

function Loader({ isLoading, children = null, size = 40, className }) {
  if (isLoading) {
    return (
      <div
        className={classNames('"ch-loader"', {
          [className]: !!className
        })}
      >
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: size,
                color: COLOURS_CARBONHOUND.PRIMARY_PURPLE
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  return children || null;
}

export default Loader;
