import { Modal as AntModal } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './modal.scss';

Modal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  width: PropTypes.string
};

function Modal({
  className,
  visible,
  children,
  onCancel,
  info,
  width,
  maskClosable = true,
  ...props
}) {
  return info ? (
    AntModal.info({
      ...props
    })
  ) : (
    <AntModal
      width={width}
      destroyOnClose
      className={classNames('ch-modal', { [className]: !!className })}
      visible={visible}
      onCancel={onCancel}
      maskClosable={maskClosable}
      keyboard={false}
      footer={null}
      {...props}
    >
      {children}
    </AntModal>
  );
}

export default Modal;
