import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import produce from 'immer';
import { useState } from 'react';
import { Card, Popover } from '../../../../components';
import { CHART_KEY_NAMES } from '../../../../constants';
import {
  COLOURS_CARBONHOUND,
  COLOURS_GRAY
} from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import {
  getUnitFriendlyName,
  getYearDropdownOptions,
  analytics
} from '../../../../helpers';
import { getDefaultYearForChart } from '../../../../helpers/generators';
import { BarChart, ButtonIcon, Select, Title } from '../../../../stories/atoms';
import './EmissionSourceDetailSummary.scss';

function EmissionSourceDetailSummaryChart({
  emissionSourceDetails,
  analyticsContext,
  chartData,
  refreshChart,
  chartKeyName,
  setChartKeyName,
  setSelectedDateRange
}) {
  const { me, selectCarbonYearRange } = useMeContext();
  // This is the current carbon year calculated based on user starting month/year
  const currentCarbonYear = selectCarbonYearRange({
    activeYear: getDefaultYearForChart({
      startingMonth: me.company?.startingMonth
    })
  });
  const defaultRange = {
    rangeStart: currentCarbonYear.rangeStart,
    rangeEnd: currentCarbonYear.rangeEnd
  };
  const [chartDateYear, setChartDateYear] = useState(
    new Date(defaultRange.rangeStart).getUTCFullYear()
  );
  const activityUnit = emissionSourceDetails?.emissionFactor?.activityUnit;
  // the formatted currently selected date range to send to the BE

  const handleChangeYear = (value) => {
    analytics.track('Chart Filter Selected', me, {
      ...analyticsContext
    });
    setChartDateYear(value);
    let rangeStart;
    let rangeEnd;

    if (value === -1) {
      rangeStart = 2006;
      ({ rangeEnd } = currentCarbonYear);
    } else if (!rangeStart && !rangeEnd) {
      ({ rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: value
      }));
    }
    setSelectedDateRange((prev) =>
      produce(prev, (draft) => {
        draft.rangeStart = rangeStart;
        draft.rangeEnd = rangeEnd;
      })
    );
  };
  const handleChangeKeyName = (value) => {
    setChartKeyName(value);
  };

  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];
  const chartKeyNameOptions = [
    {
      label: 'Usage',
      value: CHART_KEY_NAMES.TOTAL_ACTIVITY
    },
    {
      label: 'Emissions',
      value: CHART_KEY_NAMES.TOTAL_EMISSIONS
    }
  ];

  const handleRefreshChart = () => {
    analytics.track('Refresh Clicked', me, {
      ...analyticsContext
    });
    refreshChart();
  };
  return (
    <Card className="emission-sources-detail-summary">
      <Row gutter={16} className="mb-6 align-center">
        <Col span={15}>
          <Title color={COLOURS_GRAY.GRAY_500} size="md">
            Overview
          </Title>
        </Col>
        <Col span={3} className="emission-sources-detail-summary__refresh ">
          {chartKeyName === 'totalEmissions' ? (
            <>
              <span>
                Refresh chart
                <Popover content="Recent data entries not showing on the chart due to processing are shown here. Click the refresh button to see if they are ready." />
              </span>
              <span>
                <ButtonIcon
                  scaleIcon={false}
                  name="Refresh"
                  onClick={handleRefreshChart}
                />
              </span>
            </>
          ) : null}
        </Col>
        <Col span={3}>
          <Select
            value={chartKeyName}
            disableForm
            placeholder="Usage"
            options={chartKeyNameOptions}
            onChange={handleChangeKeyName}
          />
        </Col>
        <Col span={3}>
          <Select
            value={chartDateYear}
            disableForm
            placeholder="Select Year"
            options={footprintRangeDropdownOptions}
            onChange={handleChangeYear}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BarChart
            yAxisText={
              chartKeyName === CHART_KEY_NAMES.TOTAL_EMISSIONS
                ? 'Tonnes CO2 Equivalent'
                : `Usage - ${getUnitFriendlyName(activityUnit)}`
            }
            bgColors={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
            bgColorHover={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
            labels={chartData?.map((summary) =>
              summary.date
                ? formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
                : ''
            )}
            data={chartData?.map((summary) =>
              chartKeyName === CHART_KEY_NAMES.TOTAL_EMISSIONS
                ? Number(summary[chartKeyName]) / 1000
                : Number(summary[chartKeyName])
            )}
          />
        </Col>
      </Row>
    </Card>
  );
}
export default EmissionSourceDetailSummaryChart;
