import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useState } from 'react';
import { EnergyItemForm } from '../../../components';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS
} from '../../../constants';
import { useMeContext } from '../../../context';
import {
  formatDecimal,
  getCarbonYear,
  getUnitFriendlyName,
  analytics
} from '../../../helpers';
import { getTimeFormat } from '../../../helpers/generators';
import { useAsync, useModal, usePagination } from '../../../hooks';
import { EmissionSourceService, EnergyService } from '../../../services';
import { Button, ButtonIcon, Table } from '../../../stories/atoms';
import CompletionTimeline from '../../../stories/molecules/CompletionTimeline';
import { COMPLETION_TIMELINE_MODES } from '../../../stories/molecules/CompletionTimeline/CompletetionTimeline.constants';
import { DataImportButton } from '../../../stories/molecules/DataImportButton';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import DataRequestTemplate from '../../../stories/molecules/DataRequestTemplate';

export function ActionsEnergyForm({
  action,
  analyticsContext,
  setIsDataIncomplete
}) {
  const [activeEnergyItem, setActiveEnergyItem] = useState({});
  const { details } = action;
  const { collectionId, energyTypeId, emissionSourceDetailsId, date } = details;
  const { me } = useMeContext();

  const { rangeStart, rangeEnd } = getCarbonYear({
    startingMonth: me?.company?.startingMonth,
    year: date ? new Date(date).getUTCFullYear() : null
  });
  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );

  const {
    Modal: EnergyItemModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onCloseModal: () => {
      setActiveEnergyItem({});
    }
  });

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  const [dataSource, setDataSource] = useState([]);
  const { paginationParams } = usePagination({
    pageSize: 10,
    paginationArray: dataSource,
    orderBy: ['id']
  });

  const handleLoadTableData = useCallback(async () => {
    const { list, count } = await EnergyService.fetchEnergyItems(collectionId, {
      isComplete: true,
      energyTypeId,
      pagination: paginationParams,
      rangeStart,
      rangeEnd
    });
    setDataSource(list);
    return count;
  }, [collectionId, energyTypeId, paginationParams.pageNumber]);

  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData,
    defaultValue: []
  });

  const fetchOfficeTimelineSummary = useCallback(async () => {
    if (emissionSourceDetailsId) {
      const tl = await EmissionSourceService.fetchEmissionSourceDetailsTimeline(
        {
          emissionSourceDetailsId,
          rangeStart,
          rangeEnd
        }
      );

      setIsDataIncomplete(tl.length === 0 || tl?.find((t) => !t.isComplete));
      return tl;
    }
    return [];
  }, [emissionSourceDetailsId]);

  const handlePromptForDelete = (energyItem) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        handleOnDelete(energyItem);
      }
    });
  };

  const deleteEnergy = async (energyItem) => {
    await EnergyService.deleteEnergyItem(energyItem);
    analytics.track('Delete item clicked', me, {
      ...analyticsContext,
      energyItem,
      notification: action
    });

    refreshList();
    refreshTimeline();
  };
  const { execute: handleOnDelete } = useAsync({
    asyncFunction: deleteEnergy,
    immediate: false
  });

  const handleEditEnergyItem = (energyItem) => {
    analytics.track(
      energyItem.id ? 'Edit Item Clicked' : 'Add new Selected',
      me,
      {
        ...analyticsContext,
        energyItem,
        notification: action
      }
    );
    if (energyItem.id) {
      setActiveEnergyItem(energyItem);
    }
    handleShowModal();
  };

  const columns = [
    {
      title: 'Billing Period',
      onCell: () => ({
        className: 'text-bd'
      }),
      render: (id, record) =>
        record?.billingStart && record?.billingEnd
          ? `${formatInTimeZone(
              new Date(record?.billingStart),
              'UTC',
              dateDisplayFormat
            )} - ${formatInTimeZone(
              new Date(record?.billingEnd),
              'UTC',
              dateDisplayFormat
            )}`
          : ''
    },
    {
      title: 'Usage',
      dataIndex: 'activity',
      render: (activity) => formatDecimal(activity)
    },
    {
      title: 'Unit',
      dataIndex: 'activityUnit',
      render: (activityUnit) => getUnitFriendlyName(activityUnit)
    },
    {
      render: (_, energyItem) => (
        <Space className="energy-item-list__actions justify-end">
          <ButtonIcon
            scaleIcon={false}
            name="Pencil"
            size={18}
            onClick={() => handleEditEnergyItem(energyItem)}
          />
          <ButtonIcon
            scaleIcon={false}
            name="MinusCircle"
            size={18}
            onClick={() => handlePromptForDelete(energyItem)}
          />
        </Space>
      )
    }
  ];
  const {
    value: timeline,
    isLoading: isLoadingTimeline,
    execute: refreshTimeline
  } = useAsync({
    asyncFunction: fetchOfficeTimelineSummary,
    defaultValue: []
  });

  const handleRefreshView = () => {
    refreshList();
    refreshTimeline();
  };

  const handleEnergyItemFormSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      level3: 'Add Bill',
      energyItem: activeEnergyItem,
      notification: action
    });
    handleRefreshView();
    handleCloseModal();
  };

  const dataImportSettings = {
    onSuccess: handleEnergyItemFormSuccess,
    notificationReqParams: {
      collectionId,
      emissionSourceDetailsId
    },
    notification: {
      details: {
        uploadType: BulkUploadType.ENERGY
      }
    },
    internalSpec: EMISSION_SOURCE_INTERNAL_SPECS.ENERGY
  };
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <CompletionTimeline
          mode={COMPLETION_TIMELINE_MODES.DARK}
          timeline={timeline}
          isLoading={isLoadingTimeline}
        />
      </Col>
      <Col span={24}>
        <Row justify="end" gutter={[0, 16]}>
          <Col span={24} className="justify-end display-flex">
            <Space>
              <Button
                type={BUTTON_TYPES.LINK}
                onClick={handleShowTemplateModal}
                className="mr-5"
              >
                Data Request Template
              </Button>
              <DataImportButton
                onSuccess={handleRefreshView}
                analyticsContext={analyticsContext}
                {...dataImportSettings}
              />
              <Button onClick={handleEditEnergyItem}>Add Item</Button>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              isHeaderBorder={false}
              isLoading={isLoading}
              total={count}
              showPagination
              pagination={paginationParams}
              dataSource={dataSource}
              columns={columns}
            />
          </Col>
        </Row>
      </Col>
      <EnergyItemModal>
        <EnergyItemForm
          timeline={timeline}
          onCancel={handleCloseModal}
          energyDetailsId={collectionId}
          energyTypeId={energyTypeId}
          energyItem={activeEnergyItem}
          onSuccess={handleEnergyItemFormSuccess}
        />
      </EnergyItemModal>
      <DataRequestTemplateModal title="Data request template">
        <DataRequestTemplate />
      </DataRequestTemplateModal>
    </Row>
  );
}
