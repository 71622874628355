import { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useMeContext, useOfficeTrailContext } from '../../../context';
import { CompanyInitiativeList } from '../../../features/Initiative/CompanyInitiative';
import { InitiativeTemplateList } from '../../../features/Initiative/InitiativeTemplate';
import { CompanyOnboardingWidget } from '../../../features/DashboardOnboardingWidgets';

export function InitiativeOverview() {
  const { me, isAdmin } = useMeContext();
  const { refreshOfficeTrails } = useOfficeTrailContext();

  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Library'
  };

  useEffect(() => {
    if (me?.id) {
      window.pendo.initialize({
        visitor: {
          id: me.id,
          email: me.email
        }
      });
    }
    if (me?.company?.slug) {
      refreshOfficeTrails();
    }
  }, [me]);
  return (
    <Row gutter={[0, 48]}>
      <Col span={24}>
        <Row gutter={[24, 16]} className="onboarding-widgets">
          {!!isAdmin && <CompanyOnboardingWidget />}
        </Row>
      </Col>
      <Col span={24}>
        <CompanyInitiativeList
          analyticsContext={{
            ...analyticsContext,
            level3: 'Active Initiatives'
          }}
        />
      </Col>
      <Col span={24}>
        <InitiativeTemplateList
          analyticsContext={{ ...analyticsContext, level3: 'Templates' }}
        />
      </Col>
    </Row>
  );
}
