import { Checkbox as AntCheckbox } from 'antd';
import { ErrorMessage, Field } from 'formik';
import Paragraph from '../../stories/atoms/Paragraph';
import Error from '../Error';
import './checkbox.scss';

Checkbox.propTypes = {};

function Checkbox({
  name,
  title,
  className,
  onChange,
  setFieldValue,
  options,
  disableForm,
  checked,
  children
}) {
  const handleChange = (value) => {
    setFieldValue && setFieldValue(name, value.target.checked);
  };
  if (disableForm) {
    return (
      <div className={`ch-checkbox-field ${className}`}>
        <AntCheckbox onChange={onChange}>
          {title}
          {children}
        </AntCheckbox>
      </div>
    );
  }
  return (
    <Field name={name} validateOnChange>
      {({ _field, form: { _touched, _errors, _setFieldTouched }, _meta }) => (
        <div className={`ch-checkbox-field ${className}`}>
          <AntCheckbox
            checked={checked}
            options={options}
            onChange={onChange || handleChange}
            setFieldValue={setFieldValue}
          >
            {title && (
              <Paragraph
                bottomSpacing={0}
                size="rg"
                className="ch-checkbox-field__title"
              >
                {title}
              </Paragraph>
            )}

            {children}
          </AntCheckbox>
          <ErrorMessage name={name}>
            {(message) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default Checkbox;
