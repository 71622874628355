import { Col, Row } from 'antd';
import {
  COLOURS_CARBONHOUND,
  COLOURS_GREEN,
  COLOURS_RED
} from '../../../constants/colours';
import { Anchor, Paragraph, Title } from '../../../stories/atoms';
import { Blurb } from '../../../stories/molecules';
import './DashboardDataAccuracyInformation.scss';

export function DashboardDataAccuracyInformation() {
  return (
    <Row gutter={[0, 40]} className="dashboard-data-accuracy-information">
      <Col span={24}>
        <Title size="xl" bottomSpacing={32}>
          Your Data Quality
        </Title>
        <Paragraph>
          Depending on the source of your data it has different quality. It is
          important that you consider your data quality when measuring your
          footprint, and setting reduction goals. We have based our data scoring
          off of{' '}
          <Anchor href="https://carbonaccountingfinancials.com/files/Importance-of-data-quality-CDP-PCAF.pdf">
            PCAF’s
          </Anchor>{' '}
          recommendation for data scoring.
        </Paragraph>
      </Col>
      <Col span={24} className="blurb-bg-gray">
        <table className="dashboard-data-accuracy-information__table">
          <thead className="dashboard-data-accuracy-information__table__header">
            <tr className="dashboard-data-accuracy-information__table__header__row dashboard-data-accuracy-information__table__row">
              <th>
                <Title>Data Type</Title>
              </th>
              <th>
                <Title>Data Score</Title>
              </th>
            </tr>
          </thead>
          <tbody className="dashboard-data-accuracy-information__table__body">
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  Verified Data
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  Data that has been audited and verified through a
                  verification.
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_GREEN.GREEN_500}
                  weight={600}
                >
                  Great (8-10)
                </Paragraph>
              </td>
            </tr>
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  Carbonhound Connect Data
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  This data is audited by our team so is 3rd party verified
                  data.
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_GREEN.GREEN_500}
                  weight={600}
                >
                  Great (8-10)
                </Paragraph>
              </td>
            </tr>
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  Manual Data Entered
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  e.g data entered directly from usage bills
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
                  weight={600}
                >
                  Good (6-8)
                </Paragraph>
              </td>
            </tr>
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  System Estimated Data
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  Data generated by our system is audited
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_RED.RED_400}
                  weight={600}
                >
                  Poor (4-6)
                </Paragraph>
              </td>
            </tr>
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  User Estimated Data
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  Data you indicate is estimated
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_RED.RED_400}
                  weight={600}
                >
                  Poor (4-6)
                </Paragraph>
              </td>
            </tr>
            <tr className="dashboard-data-accuracy-information__table__body__row dashboard-data-accuracy-information__table__row">
              <td>
                <Paragraph weight={500} bottomSpacing={8}>
                  Spend-Based Estimated Data
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  Data estimated based off of amount spent on asset
                </Paragraph>
              </td>
              <td>
                <Paragraph
                  bottomSpacing={0}
                  color={COLOURS_RED.RED_800}
                  weight={600}
                >
                  Bad (-4)
                </Paragraph>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col span={24}>
        <Title size="xl" bottomSpacing={16}>
          How to improve your score
        </Title>
        <Paragraph bottomSpacing={16}>
          If your data quality score is low there are many ways you can improve
          your data. The highest score of data is verified data that has been
          audited by a 3rd party. You can perform a verification through
          Carbonhound, or start automating your data entries through Carbonhound
          Connect.
        </Paragraph>
        <Blurb>
          <Paragraph bottomSpacing={12}>
            Our climate team can give you specialized advice on the best
            approach for you to improve your data score.
          </Paragraph>
          <Anchor href="https://meetings.hubspot.com/carbonhound-erin/carbonhound-data-accuracy-inbound-">
            Schedule a call
          </Anchor>
        </Blurb>
      </Col>
      <Col span={24}>
        <Title size="xl" bottomSpacing={16}>
          Questions?
        </Title>
        <Paragraph>
          Visit our{' '}
          <Anchor href="https://carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4">
            FAQ
          </Anchor>
          , or{' '}
          <Anchor href="https://meetings.hubspot.com/carbonhound-erin/carbonhound-data-accuracy-inbound-">
            get in touch with us
          </Anchor>
        </Paragraph>
      </Col>
    </Row>
  );
}
