/** InitiativeContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import produce from 'immer';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from '../hooks';
import {
  InitiativeNotificationTemplateService,
  NotificationService
} from '../services';
import InitiativeService from '../services/InitiativeService';
import InitiativeTemplateService from '../services/initiativeTemplateService';
import { useMeContext } from './MeContext';

// Create the context
const InitiativeContext = createContext({});

// Define the default context state
const defaultInitiativeState = {
  companyInitiative: { initiativeTemplate: {} },
  companyInitiativeId: undefined,
  companyInitiativeRelatedActions: [],
  companyInitiativeRelatedActionsCount: 0,
  companyInitiativeImpactedSources: [],
  companyInitiativeImpactedSourcesCount: 0,
  initiativeList: [],
  initiativeRelatedActions: [],
  initiativeRelatedActionsCount: 0,
  initiativeTemplate: {},
  initiativeTemplateId: undefined,
  initiativeTemplateList: [],
  totalInitiativeCount: 0,
  totalInitiativeTemplateCount: 0,
  initiativeOverview: {
    baseline: 0,
    chartSummary: [],
    reductions: 0,
    targetSummary: []
  }
};

// Create method to use context
function useInitiativeContext() {
  const context = useContext(InitiativeContext);
  const { me } = useMeContext();
  const params = useParams();
  const { company } = me;
  const { slug: companySlug } = company;
  const { initiativeTemplateId, companyInitiativeId } = params;

  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useInitiativeContext must be used within a InitiativeContextProvider'
    );
  }
  const [initiativeState, setInitiativeState] = context;

  const handleSetInitiativeState = (updatedState) => {
    setInitiativeState((prev) => ({
      ...prev,
      ...updatedState,
      companyInitiative: {
        ...prev.companyInitiative,
        ...updatedState.companyInitiative,
        initiativeTemplate: {
          ...prev.companyInitiative?.initiativeTemplate,
          ...updatedState.companyInitiative?.initiativeTemplate
        }
      }
    }));
  };

  const handleLoadInitiativeTemplate = useCallback(async () => {
    if (!!initiativeTemplateId) {
      const initiativeTemplate =
        await InitiativeTemplateService.getInitiativeTemplate(
          initiativeTemplateId
        );

      if (!!initiativeTemplate) {
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.initiativeTemplate = initiativeTemplate;
          })
        );
      }
    }
  }, [initiativeTemplateId]);

  const handleLoadInitiativeOverview = useCallback(
    async (params) => {
      if (!!companyInitiativeId) {
        const initiativeOverview =
          await InitiativeService.getCompanyInitiativeOverview({
            companyInitiativeId,
            ...params
          });
        if (!(initiativeOverview instanceof Error)) {
          setInitiativeState((prev) =>
            produce(prev, (draft) => {
              draft.initiativeOverview = initiativeOverview;
            })
          );
        }
      }
    },
    [companyInitiativeId]
  );

  const handleLoadInitiativeTemplateList = useCallback(
    async (params = {}) => {
      const response = await InitiativeTemplateService.listInitiativeTemplates({
        companySlug,
        ...params
      });
      setInitiativeState((prev) =>
        produce(prev, (draft) => {
          draft.initiativeTemplateList = response.list;
          draft.totalInitiativeTemplateCount = response.count;
        })
      );
    },
    [companySlug]
  );

  const handleLoadInitiativeList = useCallback(
    async (params = {}) => {
      if (!!companySlug) {
        const response = await InitiativeService.listInitiatives({
          companySlug,
          ...params
        });
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.initiativeList = response.list;
            draft.totalInitiativeCount = response.count;
          })
        );
      }
    },
    [companySlug]
  );

  const handleLoadInitiativeDetail = useCallback(async () => {
    if (!!companyInitiativeId) {
      const companyInitiative = await InitiativeService.getInitiative(
        companyInitiativeId
      );
      if (!!companyInitiative) {
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.companyInitiative = companyInitiative;
          })
        );
      }
    }
  }, [companyInitiativeId]);

  const { paginationParams: initiativeRelatedActionsPagination } =
    usePagination({
      paginationArray: initiativeState.companyInitiativeRelatedActions,
      pageSize: 30,
      orderBy: ['createdAt', 'title']
    });

  const handleLoadInitiativeRelatedActions = useCallback(
    async (params) => {
      if (
        !!companyInitiativeId &&
        !!initiativeState.companyInitiative.initiativeTemplate.id &&
        !!Object.keys(initiativeRelatedActionsPagination).length
      ) {
        const companyInitiativeRelatedActions =
          await NotificationService.fetchNotificationActions({
            pagination: initiativeRelatedActionsPagination,
            companyInitiativeId,
            ...params
          });
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.initiativeRelatedActions =
              companyInitiativeRelatedActions.list;
            draft.initiativeRelatedActionsCount =
              companyInitiativeRelatedActions.count;
          })
        );
      }
    },
    [
      companyInitiativeId,
      initiativeState.companyInitiative?.initiativeTemplate?.id,
      initiativeRelatedActionsPagination.pageNumber
    ]
  );

  const { paginationParams: companyInitiativeRelatedActionsPagination } =
    usePagination({
      paginationArray: initiativeState.companyInitiativeRelatedActions,
      pageSize: 30,
      orderBy: ['createdAt', 'title']
    });

  const handleLoadCompanyInitiativeRelatedActions = useCallback(
    async (params) => {
      if (
        !!companyInitiativeId &&
        !!initiativeState.companyInitiative.initiativeTemplate.id &&
        !!Object.keys(companyInitiativeRelatedActionsPagination).length
      ) {
        const companyInitiativeRelatedActions =
          await InitiativeNotificationTemplateService.listInitiativeNotificationTemplates(
            {
              pagination: companyInitiativeRelatedActionsPagination,
              initiativeTemplateId:
                initiativeState.companyInitiative.initiativeTemplate.id,
              companyInitiativeId,
              ...params
            }
          );
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.companyInitiativeRelatedActionsCount =
              companyInitiativeRelatedActions.count;
            draft.companyInitiativeRelatedActions =
              companyInitiativeRelatedActions.list;
          })
        );
      }
    },
    [
      companyInitiativeId,
      initiativeState.companyInitiative?.initiativeTemplate?.id,
      companyInitiativeRelatedActionsPagination.pageNumber
    ]
  );

  const { paginationParams: initiativeImpactedSourcesPagination } =
    usePagination({
      paginationArray: initiativeState.initiativeRelatedActions,
      pageSize: 30,
      orderBy: ['createdAt', 'title']
    });

  const handleLoadInitiativeImpactedServices = useCallback(
    async (params) => {
      if (
        !!companyInitiativeId &&
        !!Object.keys(initiativeImpactedSourcesPagination).length
      ) {
        const companyInitiativeRelatedActions =
          await InitiativeService.listInitiativeImpactedSources({
            pagination: initiativeImpactedSourcesPagination,
            companyInitiativeId,
            ...params
          });
        setInitiativeState((prev) =>
          produce(prev, (draft) => {
            draft.companyInitiativeImpactedSources =
              companyInitiativeRelatedActions.list;
            draft.companyInitiativeImpactedSourcesCount =
              companyInitiativeRelatedActions.count;
          })
        );
      }
    },
    [companyInitiativeId, initiativeImpactedSourcesPagination.pageNumber]
  );

  const handleRemoveRelatedSource = async (emissionSourceDetailsId) => {
    if (!!companyInitiativeId) {
      await InitiativeService.deleteRelatedEmissionSource({
        companyInitiativeId,
        emissionSourceDetailsId
      });
      handleLoadInitiativeImpactedServices();
    }
  };

  const handleAddRelatedSource = async (emissionSourceDetailsIds) => {
    if (!!companyInitiativeId) {
      await InitiativeService.addRelatedEmissionSourceBulk({
        companyInitiativeId,
        emissionSourceDetailsIds
      });
      handleLoadInitiativeImpactedServices();
    }
  };

  useEffect(() => {
    setInitiativeState((prev) =>
      produce(prev, (draft) => {
        draft.initiativeTemplateId = initiativeTemplateId;
      })
    );
  }, [initiativeTemplateId, initiativeState.initiativeTemplateId]);

  useEffect(() => {
    setInitiativeState((prev) =>
      produce(prev, (draft) => {
        draft.companyInitiativeId = companyInitiativeId;
      })
    );
  }, [companyInitiativeId, initiativeState.companyInitiativeId]);

  // Context Methods //
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    companyInitiativeRelatedActionsPagination,
    handleAddRelatedSource,
    handleLoadCompanyInitiativeRelatedActions,
    handleLoadInitiativeDetail,
    handleLoadInitiativeImpactedServices,
    handleLoadInitiativeList,
    handleLoadInitiativeOverview,
    handleLoadInitiativeRelatedActions,
    handleLoadInitiativeTemplate,
    handleLoadInitiativeTemplateList,
    handleRemoveRelatedSource,
    handleSetInitiativeState,
    initiativeImpactedSourcesPagination,
    initiativeRelatedActionsPagination,
    initiativeState
  };
}

// Create the context provider
function InitiativeContextProvider(props) {
  const [initiativeState, setInitiativeState] = useState(
    defaultInitiativeState
  );
  const value = useMemo(
    () => [initiativeState, setInitiativeState],
    [initiativeState]
  );
  return <InitiativeContext.Provider value={value} {...props} />;
}

export { InitiativeContextProvider, useInitiativeContext };
