const CHCAdminIntegrationStatus = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  PENDING: 'PENDING',
  PENDING_CONNECTION: 'PENDING CONNECTION',
  PENDING_HISTORICAL_IMPORT: 'PENDING HISTORICAL IMPORT',
  PENDING_DISCONNECT: 'PENDING DISCONNECT'
};

export default CHCAdminIntegrationStatus;
