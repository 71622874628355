import { Row, Col, Space } from 'antd';
import { useFormikContext } from 'formik';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { Anchor, Button, Icon } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './onboardingModalFooter.scss';

function OnboardingModalFooterButtons({
  isGreenTeamScreen,
  isCompleteScreen,
  onSkipHandler,
  onCloseModalHandler
}) {
  const { isValid } = useFormikContext();

  if (isGreenTeamScreen) {
    return (
      <Space size={16}>
        <Button
          htmlType="submit"
          type={BUTTON_TYPES.SECONDARY}
          onClick={onSkipHandler}
        >
          Skip
        </Button>
        <Button htmlType="submit" disabled={!isValid}>
          Next
        </Button>
      </Space>
    );
  }
  if (isCompleteScreen) {
    return (
      <Button
        htmlType="submit"
        type={BUTTON_TYPES.SECONDARY}
        onClick={onCloseModalHandler}
      >
        Close
      </Button>
    );
  }
  return (
    <Button htmlType="submit" disabled={!isValid}>
      Next
    </Button>
  );
}

function OnboardingModalFooter({
  isGreenTeamScreen,
  isCompleteScreen,
  onSkipHandler,
  onCloseModalHandler,
  currentSegmentPage
}) {
  const { me } = useMeContext();
  return (
    <Row
      align="middle"
      justify="space-between"
      className="onboarding-footer__container"
    >
      <Col>
        <Anchor
          showLinkIcon={false}
          href="//carbonhound.notion.site/Admins-Getting-Started-for-Admins-505d580e9ed74db6baf9ee46d27e750c?pvs=4"
          onClick={() =>
            analytics.track('Help Clicked', me, {
              level1: 'Company Onboarding',
              level2: currentSegmentPage,
              ...(isCompleteScreen && {
                level3: 'Complete'
              })
            })
          }
        >
          <Icon
            className="mr-2"
            size={24}
            name="InformationCircle"
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
          />
          Get help
        </Anchor>
      </Col>
      <Col>
        <OnboardingModalFooterButtons
          isGreenTeamScreen={isGreenTeamScreen}
          isCompleteScreen={isCompleteScreen}
          onSkipHandler={onSkipHandler}
          onCloseModalHandler={onCloseModalHandler}
        />
      </Col>
    </Row>
  );
}

export default OnboardingModalFooter;
