import { Col, Layout, Row } from 'antd';
import React from 'react';
import { COLOURS_GRAY } from '../../../../constants/colours';
import { InitiativeActionContextProvider } from '../../../../context';
import { ButtonIcon } from '../../../../stories/atoms';
import { InitiativeActionContent } from '../InitiativeActionContent';
import { InitiativeActionFooter } from '../InitiativeActionFooter';
import { InitiativeActionSidebar } from '../InitiativeActionSidebar';
import './InitiativeActionDetail.scss';

const { Sider, Header, Footer, Content } = Layout;
export function InitiativeActionDetail({
  analyticsContext,
  initiativeAction,
  initiativeTemplate,
  onClose,
  onSuccess,
  companyInitiativeId
}) {
  return (
    <InitiativeActionContextProvider
      initiativeTemplate={initiativeTemplate}
      initiativeAction={initiativeAction}
      companyInitiativeId={companyInitiativeId}
    >
      <Layout className="initiative-action-detail">
        <Layout>
          <Sider theme="light" width={344}>
            <InitiativeActionSidebar
              onClose={onClose}
              onSuccess={onSuccess}
              analyticsContext={analyticsContext}
            />
          </Sider>
          <Layout>
            <Header theme="light">
              <Row justify="end">
                <Col>
                  <ButtonIcon
                    backgroundColour="transparent"
                    name="Times"
                    color={COLOURS_GRAY.GRAY_400}
                    onClick={onClose}
                  />
                </Col>
              </Row>
            </Header>
            <Content className="initiative-action-detail__content">
              <InitiativeActionContent
                onSuccess={onSuccess}
                analyticsContext={analyticsContext}
              />
            </Content>
          </Layout>
        </Layout>
        <Footer>
          <InitiativeActionFooter
            onClose={onClose}
            onSuccess={onSuccess}
            analyticsContext={analyticsContext}
          />
        </Footer>
      </Layout>
    </InitiativeActionContextProvider>
  );
}
