import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import React from 'react';
import {
  DISTANCE_CALCULATION_TYPE,
  UnitTypeFamilies,
  YesOrNoOptions
} from '../../../../constants';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { DatePicker, ModalFooter, Radio } from '../../../../stories/atoms';
import Input from '../../../../stories/atoms/Input';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import InputNumberField from '../../../../stories/atoms/InputNumberField';
import { DistanceCalculationTypeFields } from '../../../../stories/molecules/DistanceCalculationTypeFields';
import UnitSelect from '../../../../stories/molecules/UnitSelect';
import { EmissionSourceItemFormValidation } from './EmissionSourceItemForm';

function EmissionSourceItemForm({
  emissionSourceDetails,
  emissionSourceItem,
  unitFamilyName,
  onSuccess
}) {
  const isDistanceBasedSource = unitFamilyName === UnitTypeFamilies.DISTANCE;
  const { dateType } = emissionSourceDetails;
  const { departureLocation, destinationLocation } = emissionSourceItem ?? {};
  const handleOnSubmit = (values) => {
    const method = emissionSourceItem?.id
      ? EmissionSourceService.updateEmissionSourcesItem
      : EmissionSourceService.createEmissionSourcesItem;
    method(
      {
        emissionSourceDetailsId: emissionSourceDetails.id,
        sourceItem: values,
        esItemId: emissionSourceItem?.id
      },
      (sourceItem) => {
        onSuccess(sourceItem);
      }
    );
  };

  const {
    id: departureLocationId,
    lat: departureLocationLat,
    lng: departureLocationLon
  } = departureLocation ?? {};
  const {
    id: destinationLocationId,
    lat: destinationLocationLat,
    lng: destinationLocationLon
  } = destinationLocation ?? {};
  const googleType = 'DRIVING';
  return (
    <Formik
      validationSchema={EmissionSourceItemFormValidation}
      onSubmit={handleOnSubmit}
      initialValues={{
        dateType,
        activity: undefined,
        conversionUnitName: undefined,
        expenseNote: undefined,
        isDistanceBasedSource,
        ...emissionSourceItem,
        googleType,
        distanceCalculationType:
          emissionSourceItem?.details?.distanceCalculationType ??
          DISTANCE_CALCULATION_TYPE.NONE,
        departureLocationId,
        departureGeometry: {
          lat: departureLocationLat,
          lng: departureLocationLon
        },
        destinationLocationId,
        destinationGeometry: {
          lat: destinationLocationLat,
          lng: destinationLocationLon
        },
        date: emissionSourceItem?.date
          ? formatInTimeZone(emissionSourceItem?.date, 'UTC', 'yyyy-MM-dd')
          : undefined,
        startDate: emissionSourceItem?.startDate
          ? formatInTimeZone(emissionSourceItem?.startDate, 'UTC', 'yyyy-MM-dd')
          : undefined,
        endDate: emissionSourceItem?.endDate
          ? formatInTimeZone(emissionSourceItem?.endDate, 'UTC', 'yyyy-MM-dd')
          : undefined,
        isEstimate: emissionSourceItem?.isEstimate
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, isValid }) => (
        <Form>
          <Row gutter={[24, 24]}>
            {isDistanceBasedSource ? (
              <Col span={24}>
                <DistanceCalculationTypeFields
                  distanceFieldKey="activity"
                  isEmissionSourceBased
                />
              </Col>
            ) : (
              <>
                <Col span={8}>
                  <InputNumberField
                    placeholder="Enter"
                    title="Usage"
                    name="activity"
                    value={values?.activity}
                  />
                </Col>
                <Col span={8}>
                  <UnitSelect
                    setFieldValue={setFieldValue}
                    unitFamilyName={unitFamilyName}
                    placeholder="Select"
                    title="Units"
                    name="conversionUnitName"
                    value={values?.conversionUnitName}
                  />
                </Col>
              </>
            )}

            <Col span={8}>
              {dateType === 'RANGE' ? (
                <DatePicker
                  fromName="startDate"
                  toName="endDate"
                  isRange
                  title="Date"
                  name="date"
                  defaultValue={[values?.startDate, values?.endDate]}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <DatePicker
                  title="Date"
                  name="date"
                  defaultValue={values?.date}
                  setFieldValue={setFieldValue}
                />
              )}
            </Col>
            <Col span={24}>
              <Radio
                title="Is this data estimated?"
                raised
                value={values?.isEstimate}
                name="isEstimate"
                options={YesOrNoOptions}
                setFieldValue={setFieldValue}
              />
            </Col>
          </Row>
          <Row className="mt-6">
            <Input
              type={INPUT_TYPES.TEXT_AREA}
              title="Notes (Optional)"
              name="expenseNote"
              defaultValue={values?.expenseNote}
              placeholder="Enter"
            />
          </Row>
          <ModalFooter
            saveButtonProps={{
              htmlType: 'submit',
              disabled: !isValid
            }}
            showHelpLink={false}
            showCancelButton={false}
            saveButtonCopy="Save"
          />
        </Form>
      )}
    </Formik>
  );
}
export default EmissionSourceItemForm;
