import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { useAsync } from '../../../hooks';
import AppRoutes from '../../../routes/appRoutes';
import { IntensityMetricService } from '../../../services';
import { Button, Link, Paragraph, Title } from '../../../stories/atoms';
import CompletionTimeline from '../../../stories/molecules/CompletionTimeline';
import { COMPLETION_TIMELINE_MODES } from '../../../stories/molecules/CompletionTimeline/CompletetionTimeline.constants';
import './ActionsGapIntensityForm.scss';

export function ActionsGapIntensityForm({ action, setIsDataIncomplete }) {
  const { details } = action;
  const { intensityMetricDetailsId, rangeStart, rangeEnd } = details;

  const fetchIntensityMetricTimelineSummary = useCallback(async () => {
    if (intensityMetricDetailsId) {
      const { success, data: timeline } =
        await IntensityMetricService.getIntensityMetricTimeline({
          intensityMetricDetailsId,
          ...(rangeStart && { rangeStart }),
          ...(rangeEnd && { rangeEnd })
        });
      if (!success) {
        console.error(timeline.error); //TODO: real error handling
      } else {
        setIsDataIncomplete(
          timeline.length === 0 || timeline?.find((t) => !t.isComplete)
        );
        return timeline;
      }
    }
    return [];
  }, [rangeStart, rangeEnd, intensityMetricDetailsId]);

  const { value: timeline, isLoading: isLoadingTimeline } = useAsync({
    asyncFunction: fetchIntensityMetricTimelineSummary,
    defaultValue: []
  });

  return (
    <Row gutter={[0, 24]} className="actions-gap-intensity-form">
      <Col span={24}>
        <CompletionTimeline
          mode={COMPLETION_TIMELINE_MODES.DARK}
          timeline={timeline}
          isLoading={isLoadingTimeline}
        />
      </Col>
      <Col span={24} className="actions-gap-intensity-form__cta">
        <Row wrap={false} gutter={[0, 16]} align="middle">
          <Col flex={1}>
            <Title bottomSpacing={8} size="rg">
              Configure your intensity metrics
            </Title>
            <Paragraph bottomSpacing={0}>
              Add data and manage your intensity metrics.
            </Paragraph>
          </Col>
          <Col>
            <Link to={`${AppRoutes.SOURCES_OVERVIEW}?tab=5`}>
              <Button>Add Data</Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
