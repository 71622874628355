import { Col, Row, Statistic } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import mountain from '../../../assets/images/mountain.svg';
import TargetChart from '../../../assets/images/target-chart.svg';
import { StatisticGroup } from '../../../components';
import { TargetType } from '../../../constants';
import { useMeContext } from '../../../context';
import { capitalizeText, analytics } from '../../../helpers';
import { AppRoutes } from '../../../routes';
import { Button, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './TargetSummaryCard.scss';

function TargetSummaryCard({ target, className, showSummaryItems }) {
  const { me } = useMeContext();
  return (
    <Row
      className={classNames('target-summary-card', {
        [className]: !!className,
        dark: !showSummaryItems
      })}
    >
      <Col span={24}>
        <Row align="middle" className="target-summary-card__title-row">
          <Col className="mr-6" flex="45px">
            <img
              src={
                target.type === TargetType.INTENSITY ? TargetChart : mountain
              }
              alt={
                target.type === TargetType.INTENSITY
                  ? 'target chart'
                  : 'mountain'
              }
            />
          </Col>
          <Col flex="auto">
            <sup>
              <span>{capitalizeText(target.type)}</span> target
            </sup>
            <Title size="rg" bottomSpacing={0}>
              {target.type === TargetType.INTENSITY
                ? target.name
                : 'Your overall reduction target'}
            </Title>
          </Col>
          {showSummaryItems && (
            <Col flex="110px" className="justify-end display-flex">
              <Link
                to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
                  ':targetDetailsId',
                  target.id
                )}
                state={{ type: target.type }}
              >
                <Button
                  onClick={() => {
                    analytics.track('Edit Item Clicked', me, {
                      level1: 'Office-Based Operations',
                      level2: 'Set Targets',
                      level3: 'Overview',
                      targetDetails: target
                    });
                  }}
                  type={BUTTON_TYPES.SECONDARY}
                >
                  Edit
                </Button>
              </Link>
            </Col>
          )}
        </Row>

        <StatisticGroup>
          <Row className="target-setting-overview__statistic-container">
            <Col flex="auto">
              <Row>
                <Col
                  className="target-setting-overview__statistic-container__column"
                  span={8}
                >
                  <Statistic
                    className="text-center"
                    title="Reduction %"
                    value={target.percentReduction ?? '-'}
                  />
                </Col>
                <Col
                  span={8}
                  className="target-setting-overview__statistic-container__column"
                >
                  <Statistic
                    className="text-center"
                    groupSeparator=""
                    title="Target Year"
                    value={
                      target.endYear
                        ? new Date(target.endYear).getUTCFullYear()
                        : '-'
                    }
                  />
                </Col>
                <Col
                  span={8}
                  className="target-setting-overview__statistic-container__column"
                >
                  <Statistic
                    className="text-center"
                    title="Timeline"
                    value={
                      target.durationYears
                        ? `${target.durationYears} Years`
                        : '-'
                    }
                  />
                </Col>
              </Row>
            </Col>
            {!target.percentReduction && (
              <Col>
                <Row>
                  <Statistic
                    className="text-center"
                    title="Next Step"
                    formatter={() => (
                      <Link
                        to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
                          ':targetDetailsId',
                          target.id
                        )}
                        className="text-center text-primary statistic-link"
                        state={{ type: target.type }}
                      >
                        <Button
                          className="p-0"
                          type={BUTTON_TYPES.TEXT}
                          onClick={() => {
                            analytics.track('Edit Item Clicked', me, {
                              level1: 'Office-Based Operations',
                              level2: 'Set Targets',
                              level3: 'Overview',
                              targetDetails: target
                            });
                          }}
                        >
                          Set Reduction %
                        </Button>
                      </Link>
                    )}
                  />
                </Row>
              </Col>
            )}
          </Row>
        </StatisticGroup>
      </Col>
    </Row>
  );
}
export default TargetSummaryCard;
