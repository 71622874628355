import { formatInTimeZone } from 'date-fns-tz';
import produce from 'immer';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AGGREGATE_TYPE, TargetType, TIMEFRAME_VALUES } from '../constants';
import DateFormats from '../constants/dateFormats';
import { FormatEmissionsForChart } from '../helpers/formatters';
import { getDefaultYearForChart } from '../helpers/generators';
import {
  DashboardService,
  EmissionSourceService,
  ReportingService
} from '../services';
import { useMeContext } from './MeContext';

// Create the context
const dataContext = createContext({});
// Define the default context state
const dataState = {
  filters: {
    aggregateType: AGGREGATE_TYPE.CATEGORY,
    chartDateYear: undefined,
    collectionIds: undefined,
    emissionScopes: undefined,
    reportingPeriod: undefined,
    selectedDateRange: { rangeEnd: undefined, rangeStart: undefined },
    showMonthsWithoutData: false,
    tagIds: undefined,
    timeUnit: TIMEFRAME_VALUES.MONTH,
    visibleTarget: {}
  },
  isDataFiltersInit: false
};

// Create method to use context
function useDataContext() {
  const context = useContext(dataContext);

  const { me, selectCarbonYearRange } = useMeContext();

  const { startingMonth, slug: companySlug } = me.company ?? {};
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error('useCHCContext must be used within a DataContextProvider');
  }

  const [data, setData] = context;

  const { filters, isDataFiltersInit } = data;
  const {
    aggregateType,
    selectedDateRange,
    showMonthsWithoutData,
    timeUnit,
    visibleTarget,
    chartDateYear,
    collectionIds,
    emissionScopes,
    tagIds
  } = filters;

  // Context Methods //
  const updateData = (newData = {}) => {
    const newFilters = newData.filters ?? {};
    const newSelectedDateRange = newFilters.selectedDateRange ?? {};
    setData((d) => ({
      ...d,
      ...newData,
      ...(!!newFilters && {
        filters: {
          ...d.filters,
          ...newFilters,
          selectedDateRange: {
            ...d.filters.selectedDateRange,
            ...newSelectedDateRange
          }
        }
      })
    }));
  };

  const updateDataFilters = (newDataFilters) => {
    const newSelectedDateRange = newDataFilters.selectedDateRange ?? {};
    setData((d) => ({
      ...d,
      filters: {
        ...d.filters,
        ...newDataFilters,
        selectedDateRange: {
          ...d.filters.selectedDateRange,
          ...newSelectedDateRange
        }
      }
    }));
  };

  const updateDisplayTypeFilter = (newDisplayIds, key) => {
    setData((prevState) =>
      produce(prevState, (draft) => {
        const newType =
          newDisplayIds[newDisplayIds.length - 1] === null
            ? undefined
            : newDisplayIds.filter((val) => !!val);
        draft.filters[key] = newType;
      })
    );
  };

  // context filter methods

  // function to handle setting the showEmptyPeriods state
  const handleSetShowMonthsWithoutData = (smwd) => {
    updateDataFilters({ showMonthsWithoutData: smwd });
  };
  // function to handle setting active year being displayed in the tracker
  const handleSetChartYear = (cdy) => {
    let rangeStart;
    let rangeEnd;
    if (cdy === -1) {
      const earliestYear = 2006;
      rangeStart = `${earliestYear}-${String(
        me?.company?.startingMonth
      ).padStart(2, '0')}-01`;
      rangeEnd = formatInTimeZone(new Date(), 'UTC', DateFormats.API);
    } else {
      ({ rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: cdy
      }));
    }
    updateDataFilters({
      chartDateYear: cdy,
      selectedDateRange: { rangeStart, rangeEnd }
    });
  };
  // function to handle changing between tracker views. currently, supports category and scope.
  const handleSetChartType = (at) => {
    updateData({
      isDataFiltersInit: true,
      filters: { aggregateType: at }
    });
  };
  // function to handle changing between tracker views. currently, supports category and scope.
  const handleSetSelectedDateRange = (sdr) => {
    updateData({
      isDataFiltersInit: true,
      filters: { selectedDateRange: sdr }
    });
  };
  // function to handle displaying a target on the chart
  const handleSetVisibleTarget = (vt) => {
    updateData({
      isDataFiltersInit: true,
      filters: { visibleTarget: vt }
    });
  };
  // function to handle displaying a target on the chart
  const handleSetTimeUnit = (tu) => {
    updateData({
      isDataFiltersInit: true,
      filters: { timeUnit: tu }
    });
  };

  const handleFetchDataExplorerData = useCallback(async () => {
    if (
      !selectedDateRange.rangeEnd ||
      !selectedDateRange.rangeStart ||
      !isDataFiltersInit ||
      !aggregateType ||
      !companySlug ||
      !timeUnit
    )
      return;
    const params = {
      companySlug,
      ...selectedDateRange,
      timeUnit,
      aggregateType,
      ...(visibleTarget?.type === TargetType.INTENSITY && {
        targetDetailsId: visibleTarget?.id
      })
    };

    const response = await DashboardService.fetchCarbonTrackerData(params);
    const formattedChartData = FormatEmissionsForChart({
      emissionsArray: response,
      params: {
        ...params,
        showMonthsWithoutData
      },
      startingMonth
    });
    return formattedChartData;
  }, [companySlug, filters, selectedDateRange]);

  const handleFetchReportingData = useCallback(async () => {
    const params = {
      companySlug: me?.company?.slug,
      ...selectedDateRange,
      timeUnit,
      aggregateType,
      ...(visibleTarget?.type === TargetType.INTENSITY && {
        targetDetailsId: visibleTarget?.id
      }),
      emissionScopes,
      tagIds,
      collectionIds
    };

    const response = await ReportingService.getEmissionSourceSummary(params);
    const formattedChartData = FormatEmissionsForChart({
      emissionsArray: response,
      params: {
        ...params,
        showMonthsWithoutData
      },
      startingMonth
    });
    return formattedChartData;
  }, [me?.company?.slug, selectedDateRange, selectedDateRange, filters]);

  const handleFetchReportingList = useCallback(async () => {
    const params = {
      companySlug: me?.company?.slug,
      ...selectedDateRange,
      timeUnit,
      aggregateType,
      ...(visibleTarget?.type === TargetType.INTENSITY && {
        targetDetailsId: visibleTarget?.id
      }),
      emissionScopes,
      tagIds,
      collectionIds
    };

    const response = await ReportingService.listEmissionSourceDetails(params);
    return response;
  }, [me?.company?.slug, filters, selectedDateRange]);

  const handleLoadDrilldownData = useCallback(async () => {
    if (selectedDateRange.rangeStart && selectedDateRange.rangeEnd) {
      const carbonTrackerDrilldown =
        await EmissionSourceService.fetchEmissionSourceCarbonTrackerDrilldown({
          companySlug: me?.company?.slug,
          aggregateType,
          ...selectedDateRange,
          timeUnit
        });
      return carbonTrackerDrilldown;
    }
  }, [me?.company?.slug, filters]);

  useEffect(() => {
    if (
      !selectedDateRange.rangeStart &&
      !selectedDateRange.rangeEnd &&
      !chartDateYear
    ) {
      const currentCarbonYear = selectCarbonYearRange({
        activeYear: getDefaultYearForChart({
          startingMonth
        })
      });
      updateData({
        isDataFiltersInit: true,
        filters: {
          selectedDateRange: {
            rangeStart: currentCarbonYear.rangeStart,
            rangeEnd: currentCarbonYear.rangeEnd
          },
          chartDateYear: new Date(currentCarbonYear.rangeStart).getUTCFullYear()
        }
      });
    }
  }, []);
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    data,
    handleFetchDataExplorerData,
    handleLoadDrilldownData,
    handleSetChartType,
    handleSetChartYear,
    handleSetSelectedDateRange,
    handleSetShowMonthsWithoutData,
    handleSetTimeUnit,

    handleSetVisibleTarget,
    updateData,
    updateDataFilters,
    handleFetchReportingData,
    handleFetchReportingList,
    updateDisplayTypeFilter
  };
}

// Create the context provider
function DataContextProvider({ defaultFilterOverrides = {}, ...props }) {
  const dataStateFilters = dataState.filters;
  const defaultFilterOverridesFilters = defaultFilterOverrides ?? {};
  const initialDataState = {
    ...dataState,
    filters: {
      ...dataStateFilters,
      ...defaultFilterOverridesFilters,
      selectedDateRange: {
        ...dataStateFilters.selectedDateRange,
        ...defaultFilterOverridesFilters.selectedDateRange
      }
    }
  };
  const [data, setData] = useState(initialDataState);
  const value = useMemo(() => [data, setData], [data]);
  return <dataContext.Provider value={value} {...props} />;
}

export { DataContextProvider, useDataContext };
