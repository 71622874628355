import classNames from 'classnames';
import './Title.scss';

const getTitleElement = (size) => {
  let TitleElement;
  switch (size) {
    case 'xl':
      TitleElement = 'h1';
      break;
    case 'lg':
      TitleElement = 'h2';
      break;
    case 'md':
      TitleElement = 'h3';
      break;
    case 'rg':
      TitleElement = 'h4';
      break;
    case 'sm':
      TitleElement = 'h5';
      break;
    case 'xs':
      TitleElement = 'h6';
      break;
    default:
      TitleElement = 'h3';
      break;
  }
  return TitleElement;
};
const getFontWeightFamily = (weight) => {
  let family;
  switch (weight) {
    case 100:
      family = 'MontserratThin, sans-serif';
      break;
    case 200:
      family = 'MontserratExtraLight, sans-serif';
      break;
    case 300:
      family = 'MontserratLight, sans-serif';
      break;
    case 400:
      family = 'MontserratRegular, sans-serif';
      break;
    case 500:
      family = 'MontserratMedium, sans-serif';
      break;
    case 600:
      family = 'MontserratSemiBold, sans-serif';
      break;
    case 700:
      family = 'MontserratBold, sans-serif';
      break;
    case 800:
      family = 'MontserratExtraBold, sans-serif';
      break;
    case 900:
      family = 'MontserratBlack, sans-serif';
      break;
    default:
      family = undefined;
      break;
  }
  return family;
};
function Title({
  color,
  fontSize,
  fontWeight,
  size,
  wordBreak,
  children,
  className,
  bottomSpacing = 16,
  align,
  includeSpacing = true,
  showTooltip,
  title
}) {
  const TitleElement = getTitleElement(size);
  const fontFamily = getFontWeightFamily(fontWeight);
  let tooltipText;
  if (title) {
    tooltipText = title;
  } else if (showTooltip && typeof children === 'string') {
    tooltipText = children;
  }
  return (
    <TitleElement
      className={classNames('ch-title', {
        [className]: !!className,
        [align]: !!align,
        'no-space': !includeSpacing
      })}
      title={tooltipText}
      style={{
        fontWeight,
        color,
        textAlign: align,
        fontSize: `${fontSize}px`,
        marginBottom: includeSpacing ? `${bottomSpacing}px` : undefined,
        wordBreak: wordBreak || '',
        fontFamily
      }}
    >
      {children}
    </TitleElement>
  );
}

export default Title;
