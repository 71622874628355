function CloudUpload() {
  return (
    <path
      d="M7 16C6.01501 16.0011 5.06425 15.6387 4.32987 14.9823C3.59548 14.3259 3.12911 13.4216 3.02011 12.4427C2.9111 11.4637 3.16713 10.479 3.73913 9.67711C4.31114 8.87523 5.1589 8.3126 6.12 8.097C5.84192 6.80009 6.09042 5.44583 6.81084 4.33215C7.53127 3.21846 8.66459 2.43658 9.9615 2.1585C11.2584 1.88042 12.6127 2.12892 13.7263 2.84934C14.84 3.56976 15.6219 4.70309 15.9 6H16C17.2399 5.99875 18.4361 6.45828 19.3563 7.28937C20.2765 8.12046 20.8551 9.26383 20.9797 10.4975C21.1043 11.7312 20.7661 12.9671 20.0307 13.9655C19.2953 14.9638 18.2152 15.6533 17 15.9M15 13L12 10M12 10L9 13M12 10V22"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default CloudUpload;
