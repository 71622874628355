import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useMeContext } from '../../../../context';
import { useAsync } from '../../../../hooks';
import { analytics } from '../../../../helpers';
import InitiativeService from '../../../../services/InitiativeService';
import { Button, DatePicker, Paragraph } from '../../../../stories/atoms';
import './CompanyInitiativeCompleteForm.scss';
import { CompanyInitiativeCompleteFormValidation } from './CompanyInitiativeCompleteFormValidation';

export function CompanyInitiativeCompleteForm({
  companyInitiative,
  onSuccess
}) {
  const { completeAt, initiativeTemplate } = companyInitiative ?? {};
  const { me } = useMeContext();
  const handleEditCompanyInitiative = async (params) => {
    await InitiativeService.editInitiative({
      companyInitiativeId: companyInitiative.id,
      ...params
    });

    analytics.track('Initiative Completed', me, {
      level1: 'Initiatives',
      level2: 'Overview',
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });

    onSuccess?.(params.completeAt);
  };
  const { execute: handleSubmit } = useAsync({
    asyncFunction: handleEditCompanyInitiative,
    immediate: false
  });
  return (
    <Formik
      className="company-initiative-complete-form"
      validationSchema={CompanyInitiativeCompleteFormValidation}
      onSubmit={handleSubmit}
      initialValues={{ completeAt }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row gutter={[0, 24]}>
            <Col
              span={24}
              className="company-initiative-complete-form__content"
            >
              <Paragraph bottomSpacing={0}>
                Great work! Setting this initiative as complete allows you to
                benchmark your reductions, celebrate your milestones and
                continue on to new initiatives.
              </Paragraph>
            </Col>
            <Col
              span={24}
              className="company-initiative-complete-form__content"
            >
              <DatePicker
                name="completeAt"
                defaultValue={values?.completeAt}
                title="Completion date"
                setFieldValue={setFieldValue}
              />
            </Col>
            <Col span={24} className="company-initiative-complete-form__footer">
              <Button htmlType="submit">Mark as complete 🎉</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
