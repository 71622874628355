import * as Yup from 'yup';

export const employeeDetailsValidation = Yup.object().shape({
  vehicleId: Yup.string()
    .nullable()
    .when('isVehicleEnabled', {
      is: true,
      then: Yup.string().nullable().required('Please select any vehicle'),
      otherwise: Yup.string().nullable()
    })
});
