import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback } from 'react';
import {
  useInitiativeContext,
  useMeContext,
  useTargetContext
} from '../../../../context';
import {
  CompanyInitiativeBaselineReductionGoal,
  CompanyInitiativeBaselineYear
} from '../../../../features/Initiative/CompanyInitiative/CompanyInitiativeBaseline';
import { InitiativeTemplateRelatedActions } from '../../../../features/Initiative/InitiativeTemplate';
import { useAsync } from '../../../../hooks';
import { InitiativeService } from '../../../../services';

export function CompanyInitiativeBaseline() {
  const { initiativeState } = useInitiativeContext();
  const { getTargetFromList, targetState } = useTargetContext();
  const { targetDetail } = targetState;
  const { companyInitiativeId } = initiativeState;
  const {
    me: {
      company: { slug: companySlug, startingMonth }
    }
  } = useMeContext();
  const handleTargetChartDetails = useCallback(async () => {
    if (!companyInitiativeId) return;
    await getTargetFromList({
      companySlug,
      companyInitiativeId
    });
  }, [companySlug, companyInitiativeId]);

  const { isLoading: isLoadingDetails } = useAsync({
    asyncFunction: handleTargetChartDetails,
    defaultValue: {}
  });

  const handleFetchEmissionSums = useCallback(async () => {
    if (!companyInitiativeId) return;
    const baseYearVal = new Date(targetDetail.baseYear).getUTCFullYear();
    const month = startingMonth ? startingMonth - 1 : 0;
    const emissions = await InitiativeService.fetchEmissionsSum({
      companyInitiativeId,
      rangeStart: formatInTimeZone(
        new Date(baseYearVal, month, 1),
        'UTC',
        'yyyy-MM-dd'
      ),
      rangeEnd: formatInTimeZone(
        new Date(baseYearVal + 1, month, 0),
        'UTC',
        'yyyy-MM-dd'
      )
    });

    return emissions;
  }, [companyInitiativeId, targetDetail.baseYear, startingMonth]);
  const { value: emissionsSum } = useAsync({
    asyncFunction: handleFetchEmissionSums,
    defaultValue: ''
  });

  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Forecasting',
    level3: 'Baseline and Targets'
  };
  return (
    <Row gutter={[0, 48]}>
      <Col span={24}>
        <CompanyInitiativeBaselineYear
          isLoadingDetails={isLoadingDetails}
          emissionsSum={emissionsSum}
        />
      </Col>
      <Col span={24}>
        <CompanyInitiativeBaselineReductionGoal emissionsSum={emissionsSum} />
      </Col>
      <Col span={24}>
        <InitiativeTemplateRelatedActions
          analyticsContext={{ ...analyticsContext, level4: 'Actions Table' }}
        />
      </Col>
    </Row>
  );
}
