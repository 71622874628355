import * as Yup from 'yup';

export const setClimateGoalsValidations = Yup.object().shape({
  sustainabilityGoal: Yup.string().required(
    'Please select a sustainability goal'
  ),
  reportingStandards: Yup.array().min(
    1,
    'Please have choose at least one value'
  ),
  calculatedCarbonFootprint: Yup.string().required(
    'Please indicate if you have ever calculate carbon footprint'
  )
});
