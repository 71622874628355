function ArrowDown() {
  return (
    <path
      d="M19 14L12 21M12 21L5 14M12 21V3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowDown;
