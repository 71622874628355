function ChLogoIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 230 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M201.759 73.232C186.279 73.232 173.75 85.9223 173.75 101.57C173.75 117.214 186.279 129.904 201.759 129.904C217.206 129.904 229.729 117.214 229.729 101.57C229.729 85.9223 217.206 73.232 201.759 73.232Z"
        fill="url(#paint0_linear_134_333)"
      />
      <path
        d="M55.9868 101.57C55.9868 85.9223 43.4572 73.232 27.9977 73.232C12.527 73.232 -3.05176e-05 85.9223 -3.05176e-05 101.57C-3.05176e-05 117.214 12.527 129.904 27.9977 129.904C43.4572 129.904 55.9868 117.214 55.9868 101.57Z"
        fill="url(#paint1_linear_134_333)"
      />
      <path
        d="M71.453 69.3188C90.3633 69.3188 105.687 53.7977 105.687 34.6634C105.687 15.515 90.3633 0 71.453 0C52.5478 0 37.2195 15.515 37.2195 34.6634C37.2195 53.7977 52.5478 69.3188 71.453 69.3188Z"
        fill="url(#paint2_linear_134_333)"
      />
      <path
        d="M154.016 80.8688C158.874 71.8437 169.021 68.5979 174.562 65.1526V65.1526C185.256 59.2896 192.513 47.8336 192.513 34.6634C192.513 15.5149 177.188 0 158.281 0C139.374 0 124.044 15.5149 124.044 34.6634C124.044 42.0911 126.376 48.958 130.311 54.6012L130.278 54.5924C137.049 65.2036 138.333 73.2265 137.528 78.1224C136.333 85.3674 132.816 93.1371 114.362 93.1371H114.367L81.2594 93.1354L47.2968 152.691L81.2594 212.25H149.191L183.154 152.691L156.85 106.568C149.46 94.0189 152.517 83.6425 154.016 80.8688V80.8688Z"
        fill="url(#paint3_linear_134_333)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_134_333"
          x1="218.303"
          y1="274.208"
          x2="297.14"
          y2="112.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_134_333"
          x1="77.3366"
          y1="205.389"
          x2="156.155"
          y2="43.8603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_134_333"
          x1="86.7299"
          y1="209.992"
          x2="165.577"
          y2="48.4724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_134_333"
          x1="143.225"
          y1="266.949"
          x2="263.663"
          y2="148.569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#EE2962" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default ChLogoIcon;
