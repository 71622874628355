import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { CARBONHOUND_CONNECT_TYPES } from '../../../constants';
import { useMeContext } from '../../../context';
import { analytics, capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { IntegrationService } from '../../../services';
import { Radio, Title } from '../../../stories/atoms';
import ModalFooter from '../../../stories/atoms/ModalFooter';
import { CarbonhoundConnectTypeValidation } from './CarbonhoundConnectTypeValidation';

export function CarbonhoundConnectType({
  handleNext,
  setActiveIntegration,
  analyticsContext
}) {
  const { me } = useMeContext();
  const loadCompanyIntegrations = useCallback(async () => {
    const response = await IntegrationService.listCompanyIntegrations({
      companySlug: me.company?.slug,
      parentIntegrationSlug: 'carbonhound-connect'
    });
    return response?.list ?? [];
  }, []);

  const handleSubmit = ({ integration }) => {
    analytics.track('Step Completed', me, {
      ...analyticsContext,
      level3: 'Type'
    });
    setActiveIntegration(integration);
    handleNext();
  };
  const { value: integrationList, Loader: IntegrationLoader } = useAsync({
    asyncFunction: loadCompanyIntegrations,
    defaultValue: []
  });

  const integrationListOptions = integrationList
    .filter(
      (integration) =>
        integration.slug !== CARBONHOUND_CONNECT_TYPES.VALUE_CHAIN
    )
    .map((integration) => ({
      ...integration,
      description: integration.description,
      label: (
        <Title bottomSpacing={0}>{capitalizeText(integration.slug)}</Title>
      ),
      value: integration,
      icon: integration.logoUrl
    }));
  return (
    <Formik
      initialValues={{ integration: undefined }}
      validationSchema={CarbonhoundConnectTypeValidation}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Title bottomSpacing={24}>
            Select which type of data you are connecting{' '}
          </Title>
          <IntegrationLoader>
            <Radio
              name="integration"
              value={values.integration}
              setFieldValue={setFieldValue}
              options={integrationListOptions}
              descriptive
            />
          </IntegrationLoader>
          <ModalFooter
            showHelpLink={false}
            showCancelButton={false}
            saveButtonCopy="Continue"
            saveButtonProps={{
              htmlType: 'submit'
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
