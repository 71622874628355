import { SearchOutlined } from '@ant-design/icons';
import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { GoogleApiLibraries } from '../../constants';
import { useMetaContext } from '../../context';
import { disableAutocomplete, getAddressValues } from '../../helpers';
import './googlePlacesAutoComplete.scss';

function GooglePlacesAutoComplete({
  id,
  onSelect,
  disabled,
  placeholder,
  title,
  defaultValue,
  currentCountry = {},
  isEdit = true
}) {
  const [searchText, setSearchText] = useState();

  const { meta } = useMetaContext();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GCP_PLACES_API_KEY,
    id: `${id}-script-loader`,
    libraries: googlePlacesLibraries
  });

  const [searchBoxRef, setSearchBoxRef] = useState();

  const [errorMessage, setErrorMessage] = useState();

  const [places, setPlaces] = useState([]);

  const [isFocusedOut, setFocusedOut] = useState(false);

  const onLoad = (ref) => {
    setSearchBoxRef(ref);
  };

  const onPlacesChanged = () => {
    setErrorMessage(undefined);
    if (searchBoxRef) {
      const places = searchBoxRef.getPlaces();
      if (places.length > 0) {
        const address = getAddressValues(places[0], meta.countries);
        setSearchText(places[0].formatted_address);
        if (!address.countryId && isEdit) {
          setErrorMessage('This country is currently not supported.');
          return;
        }
        if (
          !!isEdit &&
          Object.keys(currentCountry).length > 0 &&
          currentCountry?.id !== address.countryId
        ) {
          setErrorMessage(
            'You can’t edit the country for this location, please create a new location'
          );
          return;
        }
        if (!!address.address1) {
          onSelect(places);
          setPlaces(places);
        } else {
          setErrorMessage('Please enter a location with an exact address');
        }
      }
    }
  };

  useEffect(() => {
    if (!!defaultValue && !!Object.keys(defaultValue).length)
      setSearchText(defaultValue);
  }, [defaultValue]);

  return (
    isLoaded && (
      <div data-standalone-searchbox="" className="google-places-autocomplete">
        {title && (
          <div className="google-places-autocomplete__title">{title}</div>
        )}
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <Input
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
            disabled={disabled}
            suffix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder={placeholder ?? 'Search address'}
            className="google-places-autocomplete__input-search"
            onFocus={disableAutocomplete}
            onBlur={() => setFocusedOut(true)}
            autoComplete="off"
          />
        </StandaloneSearchBox>
        {errorMessage ? (
          <div className="mt-2 text-danger">{errorMessage}</div>
        ) : (
          isFocusedOut &&
          places.length === 0 && (
            <div className="mt-2 text-danger">Please select any address</div>
          )
        )}
      </div>
    )
  );
}
const googlePlacesLibraries = GoogleApiLibraries;

export default GooglePlacesAutoComplete;
