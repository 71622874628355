import { AGGREGATE_TYPE, AGGREGATE_TYPE_LABELS } from '../aggregateType';

export const AGGREGATE_TYPE_OPTIONS = [
  {
    label: AGGREGATE_TYPE_LABELS.SCOPE,
    value: AGGREGATE_TYPE.SCOPE
  },
  {
    label: AGGREGATE_TYPE_LABELS.CATEGORY,
    value: AGGREGATE_TYPE.CATEGORY
  }
];

export const REPORTING_AGGREGATE_TYPE_OPTIONS = [
  ...AGGREGATE_TYPE_OPTIONS,
  {
    label: AGGREGATE_TYPE_LABELS.BUSINESS_UNIT,
    value: AGGREGATE_TYPE.BUSINESS_UNIT
  },
  {
    label: AGGREGATE_TYPE_LABELS.COLLECTION,
    value: AGGREGATE_TYPE.COLLECTION
  },
  {
    label: AGGREGATE_TYPE_LABELS.FACILITY,
    value: AGGREGATE_TYPE.FACILITY
  }
];
