import React from 'react';

function LightningBolt() {
  return (
    <path
      d="M13 3V10H20L11 21V14H4L13 3Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default LightningBolt;
