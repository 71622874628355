function LegendIndicatorComplete() {
  return (
    <svg
      width="7"
      height="20"
      viewBox="0 0 7 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: '100px' }}
    >
      <path
        d="M0 3.5C0 1.567 1.567 0 3.5 0V0C5.433 0 7 1.567 7 3.5V16.5C7 18.433 5.433 20 3.5 20V20C1.567 20 0 18.433 0 16.5V3.5Z"
        fill="#ECFDF5"
      />
      <path
        d="M5 3.5V16.5H9V3.5H5ZM2 16.5V3.5H-2V16.5H2ZM3.5 18C2.67157 18 2 17.3284 2 16.5H-2C-2 19.5376 0.462434 22 3.5 22V18ZM5 16.5C5 17.3284 4.32843 18 3.5 18V22C6.53757 22 9 19.5376 9 16.5H5ZM3.5 2C4.32843 2 5 2.67157 5 3.5H9C9 0.462434 6.53757 -2 3.5 -2V2ZM3.5 -2C0.462434 -2 -2 0.462434 -2 3.5H2C2 2.67157 2.67157 2 3.5 2V-2Z"
        fill="#10B981"
      />
    </svg>
  );
}
export default LegendIndicatorComplete;
