import { Space } from 'antd';
import React, { useCallback, useState } from 'react';
import {
  Card,
  Notification,
  ProfileImage,
  Tooltip
} from '../../../../../components';
import { NotificationCategory } from '../../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../../constants/colours';
import {
  useCompanyInitiativeLayoutContext,
  useInitiativeContext,
  useMeContext
} from '../../../../../context';
import { getFullName, analytics } from '../../../../../helpers';
import { useAsync, useModal } from '../../../../../hooks';
import { NotificationService } from '../../../../../services';
import {
  Button,
  ButtonIcon,
  ColumnTitle,
  Empty,
  Icon,
  Table,
  Tag
} from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { TAG_COLOURS } from '../../../../../stories/atoms/Tag/tag.types';
import { InitiativeActionDetail } from '../../../InitiativeAction';

export function InitiativeTemplateRelatedActionsTable({ analyticsContext }) {
  const { me } = useMeContext();
  const [activeNotificationAction, setActiveNotificationAction] = useState();
  const {
    handleLoadCompanyInitiativeRelatedActions,
    initiativeState,
    companyInitiativeRelatedActionsPagination
  } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { companyInitiativeLayoutState } = useCompanyInitiativeLayoutContext();
  const { currentStep } = companyInitiativeLayoutState;
  const {
    companyInitiativeRelatedActions,
    companyInitiativeRelatedActionsCount,
    companyInitiativeId
  } = initiativeState;
  const handleLoadInitiativesForSteps = useCallback(async () => {
    if (
      !!currentStep &&
      !!initiativeState?.companyInitiative?.initiativeTemplate?.id
    ) {
      await handleLoadCompanyInitiativeRelatedActions({
        stepName: currentStep
      });
    }
  }, [currentStep, initiativeState?.companyInitiative?.initiativeTemplate?.id]);

  const {
    Modal: InitiativeActionModal,
    handleShowModal: handleShowInitiativeActionModal,
    handleCloseModal: handleCloseInitiativeActionModal
  } = useModal({ width: '80%' });
  const { isLoading, execute: reloadList } = useAsync({
    asyncFunction: handleLoadInitiativesForSteps
  });
  const handleCreateNotification = async (template) => {
    const notification = await NotificationService.createNotification({
      ...(template?.details ?? {}),
      title: template.title,
      message: template.message,
      notes: template.notes,
      category: NotificationCategory.INITIATIVE,
      companySlug: me?.company?.slug,
      stepName: currentStep,
      companyInitiativeId,
      notificationTemplateId: template.id
    });
    analytics.track('Add Action to Initiative', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id,
      notificationId: notification.id
    });
    if (!!notification) {
      Notification({
        type: 'success',
        message: 'Action added',
        description: 'You can see it on the actions page'
      });
    }
    reloadList();
  };

  const handleShowActionItemForm = (actionItem) => {
    setActiveNotificationAction(actionItem);
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      notificationId: actionItem.id,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
    handleShowInitiativeActionModal();
  };
  const columns = [
    {
      title: <ColumnTitle>Name</ColumnTitle>,
      dataIndex: 'title',
      onCell: () => ({
        className: 'text-bd'
      })
    },
    {
      title: <ColumnTitle>Assignee</ColumnTitle>,
      width: '32px',
      dataIndex: 'notification',
      onCell: () => ({
        align: 'center'
      }),
      render: (notification, actionItem) =>
        !!notification?.owner ? (
          <Tooltip title={getFullName(notification?.owner)}>
            <ProfileImage size={32} name={getFullName(notification?.owner)} />
          </Tooltip>
        ) : (
          <ButtonIcon
            disabled={!actionItem.notification}
            name="Plus"
            backgroundColour={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            onClick={() => {
              handleShowActionItemForm(actionItem);
            }}
          />
        )
    },
    {
      title: <ColumnTitle>Type</ColumnTitle>,
      width: '120px',
      render: () => <Tag color={TAG_COLOURS.GREEN}>Recommended</Tag>
    },
    {
      width: '100px',
      render: (dueDate, actionItem) => (
        <Space size={32}>
          <Button
            type={BUTTON_TYPES.LINK}
            onClick={() => {
              handleShowActionItemForm(actionItem);
            }}
          >
            View
          </Button>
          {!actionItem.notification ? (
            <ButtonIcon
              backgroundColour={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
              color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
              name="Plus"
              onClick={() => {
                handleCreateNotification(actionItem);
              }}
            />
          ) : (
            <Icon color={COLOURS_CARBONHOUND.PRIMARY_PURPLE} name="Check" />
          )}
        </Space>
      )
    }
  ];
  const onSuccess = () => {
    reloadList();
  };

  return (
    <Card>
      <Table
        isLoading={isLoading}
        dataSource={companyInitiativeRelatedActions}
        pagination={companyInitiativeRelatedActionsPagination}
        showPagination
        columns={columns}
        total={companyInitiativeRelatedActionsCount}
        locale={{
          emptyText: (
            <Empty
              title="No related actions"
              description="Add your own custom actions"
            />
          )
        }}
      />
      <InitiativeActionModal
        showHeader={false}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <InitiativeActionDetail
          onClose={handleCloseInitiativeActionModal}
          initiativeAction={activeNotificationAction?.notification}
          companyInitiative={companyInitiative}
          companyInitiativeId={companyInitiativeId}
          initiativeTemplate={activeNotificationAction}
          onSuccess={onSuccess}
          analyticsContext={analyticsContext}
        />
      </InitiativeActionModal>
    </Card>
  );
}
