function Tada({ size = 54 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0234 22.5L1.85336 53.0102C1.72061 53.4085 2.10804 53.7831 2.5016 53.6371L32.5234 42.5C22.5234 41 14.5234 33.5 12.0234 22.5Z"
        fill="#FBAB1D"
      />
      <ellipse
        cx="22.5746"
        cy="32.0641"
        rx="5.41618"
        ry="14.4641"
        transform="rotate(-44.9431 22.5746 32.0641)"
        fill="#ED2861"
      />
      <path
        d="M16.5253 26.6624L20.2643 23.8461C21.5489 22.8785 22.2195 21.2989 22.0233 19.7027L21.658 16.7303C21.4583 15.1058 22.1564 13.5011 23.4809 12.5396L25.3511 11.1821C26.7379 10.1755 27.4326 8.46857 27.1429 6.7796L26.323 1.99963"
        stroke="#F0ECFA"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M26.5233 37.1064L31.18 37.5833C32.7799 37.7471 34.3455 37.0447 35.2869 35.7409L37.04 33.3128C37.998 31.9858 39.6011 31.2837 41.226 31.4792L43.5204 31.7553C45.2218 31.96 46.8917 31.1806 47.8276 29.7451L50.4763 25.6825"
        stroke="#3B82F6"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.5238 29.3792L29.1398 28.6015C30.7257 28.3343 32.0484 27.2411 32.6095 25.7339L33.6543 22.9273C34.2253 21.3935 35.5841 20.2906 37.2026 20.0472L39.4878 19.7037C41.1824 19.449 42.5852 18.2538 43.106 16.6213L44.5799 12.0009"
        stroke="#6EE7B7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="19.0234" cy="5.5" r="1.5" fill="#5C29C7" />
      <circle cx="37.0234" cy="28.5" r="1.5" fill="#10B981" />
      <circle cx="48.0234" cy="36.5" r="1.5" fill="#F87171" />
      <circle cx="34.0234" cy="8.5" r="1.5" fill="#3B82F6" />
    </svg>
  );
}
export default Tada;
