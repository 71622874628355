import { Outlet } from 'react-router-dom';

function Sources() {
  return (
    <div className="sources">
      <Outlet />
    </div>
  );
}
export default Sources;
