import { Empty as AntEmpty } from 'antd';
import React from 'react';
import { COLOURS_CARBONHOUND, COLOURS_GRAY } from '../../../constants/colours';
import Button from '../Button';
import { Icon, Paragraph, Title } from '../index';

function Empty({ title, icon, imageSrc, buttonProps, description }) {
  return (
    <AntEmpty
      image={
        icon ? (
          <Icon
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
            name={icon}
          />
        ) : (
          imageSrc || null
        )
      }
      imageStyle={{
        justifyContent: 'center',
        height: 'auto',
        display: 'flex',
        marginBottom: '32px'
      }}
      description={
        <div
          style={{
            margin: '0 auto',
            maxWidth: '545px'
          }}
        >
          <Title color={COLOURS_GRAY.GRAY_500} bottomSpacing={8}>
            {title}
          </Title>
          <Paragraph size="sm" color={COLOURS_GRAY.GRAY_600}>
            {description}
          </Paragraph>
          {buttonProps && (
            <div className="mt-8 display-flex justify-center">
              <Button {...buttonProps} />
            </div>
          )}
        </div>
      }
    />
  );
}

export default Empty;
