import {
  COLOURS_BLUE,
  COLOURS_FUSCHIA,
  COLOURS_GREEN,
  COLOURS_ORANGE,
  COLOURS_YELLOW
} from './colours';

const NotificationCategory = {
  GAP_ENERGY: 'GAP_ENERGY',
  GAP_INTENSITY_METRIC: 'GAP_INTENSITY_METRIC',
  GAP_TRAVEL: 'GAP_TRAVEL',
  GAP_COMMUTE: 'GAP_COMMUTE',
  GAP_CUSTOM_SOURCE: 'GAP_CUSTOM_SOURCE',
  DATA_IMPORT: 'DATA_IMPORT',
  INITIATIVE: 'INITIATIVE',
  CUSTOM: 'CUSTOM'
};

export const NotificationCategoryLabel = {
  MONTHLY_INPUT: 'Monthly Input',
  DATA_QUALITY_ALERT: 'Data quality alert',
  DATA_INPUT: 'Data Input',
  DATA_IMPORT: 'Data Import',
  INITIATIVE: 'Initiative Task',
  CUSTOM: 'Custom'
};

export const ActionMonthlyInputCategories = [
  NotificationCategory.GAP_ENERGY,
  NotificationCategory.GAP_CUSTOM_SOURCE
];
export const ActionDataQualityInputCategories = [
  NotificationCategory.GAP_TRAVEL
];
export const ActionDataInputCategories = [
  NotificationCategory.GAP_COMMUTE,
  NotificationCategory.GAP_INTENSITY_METRIC
];

export const ActionDataImportInputCategories = [
  NotificationCategory.DATA_IMPORT
];

export const ActionInitiativeCategories = [NotificationCategory.INITIATIVE];

export const ActionCustomCategories = [NotificationCategory.CUSTOM];

export const NotificationCategoryValueMapping = {
  [NotificationCategoryLabel.MONTHLY_INPUT]: ActionMonthlyInputCategories,
  [NotificationCategoryLabel.DATA_QUALITY_ALERT]:
    ActionDataQualityInputCategories,
  [NotificationCategoryLabel.DATA_INPUT]: ActionDataInputCategories,
  [NotificationCategoryLabel.DATA_IMPORT]: ActionDataImportInputCategories,
  [NotificationCategoryLabel.INITIATIVE]: ActionInitiativeCategories,
  [NotificationCategoryLabel.CUSTOM]: ActionCustomCategories
};

export const NotificationCategoryLabelMapping = {
  [NotificationCategory.GAP_ENERGY]: NotificationCategoryLabel.MONTHLY_INPUT,
  [NotificationCategory.GAP_CUSTOM_SOURCE]:
    NotificationCategoryLabel.MONTHLY_INPUT,
  [NotificationCategory.GAP_TRAVEL]:
    NotificationCategoryLabel.DATA_QUALITY_ALERT,
  [NotificationCategory.GAP_COMMUTE]: NotificationCategoryLabel.DATA_INPUT,
  [NotificationCategory.GAP_INTENSITY_METRIC]:
    NotificationCategoryLabel.DATA_INPUT,
  [NotificationCategory.DATA_IMPORT]: NotificationCategoryLabel.DATA_IMPORT,
  [NotificationCategory.INITIATIVE]: NotificationCategoryLabel.INITIATIVE,
  [NotificationCategory.CUSTOM]: NotificationCategoryLabel.CUSTOM
};

export const NotificationCategoryColours = {
  [NotificationCategoryLabel.INITIATIVE]: COLOURS_GREEN.GREEN_500,
  [NotificationCategoryLabel.MONTHLY_INPUT]: COLOURS_FUSCHIA.FUCHSIA_400,
  [NotificationCategoryLabel.DATA_INPUT]: COLOURS_BLUE.BLUE_500,
  [NotificationCategoryLabel.DATA_QUALITY_ALERT]: COLOURS_YELLOW.YELLOW_400,
  [NotificationCategoryLabel.CUSTOM]: COLOURS_ORANGE.ORANGE_400
};

export const ActionOverviewListTuple = [
  NotificationCategoryLabel.INITIATIVE,
  NotificationCategoryLabel.MONTHLY_INPUT,
  NotificationCategoryLabel.DATA_INPUT,
  NotificationCategoryLabel.DATA_QUALITY_ALERT,
  NotificationCategoryLabel.CUSTOM
];

export default NotificationCategory;
