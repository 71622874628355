function ChartPie() {
  return (
    <>
      <path
        d="M6.39244 4.95923C7.71836 3.90331 9.31549 3.24325 11 3.05499V13H20.945C20.7567 14.6845 20.0967 16.2816 19.0408 17.6076C17.9849 18.9335 16.576 19.9343 14.9764 20.4949C13.3768 21.0554 11.6514 21.1529 9.99877 20.7761C8.34618 20.3993 6.83357 19.5635 5.63502 18.365C4.43648 17.1664 3.60071 15.6538 3.22391 14.0012C2.84711 12.3486 2.9446 10.6232 3.50514 9.02361C4.06569 7.42399 5.06652 6.01514 6.39244 4.95923Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.99999H20.488C20.0391 7.73461 19.3135 6.58534 18.3641 5.63593C17.4147 4.68653 16.2654 3.96092 15 3.51199V8.99999Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
export default ChartPie;
