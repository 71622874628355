import * as Yup from 'yup';

export const officeDetailsFormValidation = Yup.object().shape(
  {
    disableEnergyTypes: Yup.boolean()
      .nullable()
      .when('energyTypeIds', {
        is: (energyTypeIds) => energyTypeIds?.length === 0,
        then: Yup.boolean().oneOf([true], 'Select atleast one energy types')
      }),
    energyTypeIds: Yup.array().when('disableEnergyTypes', {
      is: true,
      then: Yup.array().min(0, ''),
      otherwise: Yup.array().min(
        1,
        'Please select either "I don\'t know" or atleast one energy type'
      )
    }),
    officeSquareFootage: Yup.number()
      .nullable()
      .when(['energyTypes', 'disableEnergyTypes'], {
        is: (energyTypes, disableEnergyTypes) =>
          disableEnergyTypes ||
          energyTypes.filter(
            (energyType) =>
              energyType.hasAccessToBills === false ||
              energyType.hasSeparateMeter === false
          ).length > 0,
        then: Yup.number()
          .nullable()
          .required('Office square footage is required')
          .min(1, 'Value should be atleast 1'),
        otherwise: Yup.number().nullable()
      }),
    buildingSquareFootage: Yup.number()
      .nullable()
      .when('energyTypes', {
        is: (energyTypes) =>
          energyTypes.filter(
            (energyType) => energyType.hasSeparateMeter === false
          ).length > 0,
        then: Yup.number()
          .nullable()
          .required('Building square footage is required')
          .min(1, 'Value should be atleast 1')
          .moreThan(
            Yup.ref('officeSquareFootage'),
            'Value should be greater than office square footage'
          ),
        otherwise: Yup.number().nullable()
      }),
    // isGreenEnergyProgrammeSubscribed: Yup.boolean()
    //   .nullable()
    //   .when("energyTypes", {
    //     is: (energyTypes) => {
    //       return (
    //         energyTypes.filter(
    //           (energyType) =>
    //             energyType.energyType === EnergyTypeConstants.ELECTRICITY
    //         ).length > 0
    //       );
    //     },
    //     then: Yup.boolean().nullable().required("Please select any one option"),
    //     otherwise: Yup.boolean().nullable(),
    //   }),
    electricityEnergyPercentage: Yup.number()
      .nullable()
      .min(1, 'Value should be 1-100')
      .max(100, 'Value should be 1-100'),
    // .when(["energyTypes", "isGreenEnergyProgrammeSubscribed"], {
    //   is: (energyTypes, isGreenEnergyProgrammeSubscribed) => {
    //     return (
    //       energyTypes.filter(
    //         (energyType) =>
    //           energyType.energyType === EnergyTypeConstants.ELECTRICITY
    //       ).length > 0 && isGreenEnergyProgrammeSubscribed
    //     );
    //   },
    //   then: Yup.number().nullable().required("Energy percentage is required"),
    //   otherwise: Yup.number().nullable(),
    // }),
    electricityStartMonth: Yup.string().nullable(),
    // .when(["energyTypes", "isGreenEnergyProgrammeSubscribed"], {
    //   is: (energyTypes, isGreenEnergyProgrammeSubscribed) => {
    //     return (
    //       energyTypes.filter(
    //         (energyType) =>
    //           energyType.energyType === EnergyTypeConstants.ELECTRICITY
    //       ).length > 0 && isGreenEnergyProgrammeSubscribed
    //     );
    //   },
    //   then: Yup.string().nullable().required("Start month is required"),
    //   otherwise: Yup.string().nullable(),
    // }),
    electricityStartYear: Yup.string().nullable()
    // .when(["energyTypes", "isGreenEnergyProgrammeSubscribed"], {
    //   is: (energyTypes, isGreenEnergyProgrammeSubscribed) => {
    //     return (
    //       energyTypes.filter(
    //         (energyType) =>
    //           energyType.energyType === EnergyTypeConstants.ELECTRICITY
    //       ).length > 0 && isGreenEnergyProgrammeSubscribed
    //     );
    //   },
    //   then: Yup.string().required("Start year is required"),
    // }),
  },
  [['disableEnergyTypes', 'energyTypeIds']]
);
