import { Col, Divider, Row } from 'antd';
import { Card } from '../../../components';
import LogoIconText from '../../assets/svgs/LogoIconText.svg';
import { Img } from '../Img';
import './VerificationBadge.scss';

function VerificationBadge({ Badge }) {
  return (
    <Card className="verification-badge">
      <Row align="middle" justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <Row justify="center" gutter={[18, 12]}>
            <Col span={24} className="verification-badge__ch-logo">
              <Img src={LogoIconText} width={95} />
            </Col>
            <Col flex="0 1 65px">
              <Divider className="m-0" />
            </Col>
          </Row>
        </Col>
        <Col span={24} className="verification-badge__ch-logo">
          <Badge />
        </Col>
      </Row>
    </Card>
  );
}

export default VerificationBadge;
