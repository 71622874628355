import { Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import DateFormats from '../../../constants/dateFormats';
import { useMeContext } from '../../../context';
import { useAsync, useModal, usePagination } from '../../../hooks';
import IntensityMetricService from '../../../services/intensityMetricService';
import {
  Button,
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { ItemDataForm } from '../../../stories/molecules';
import TableTitle from '../../../stories/molecules/TableTitle';
import { createIntensityItems } from '../../Target/TargetSettingData';
import { IntensityMetricForm } from '../Form';
import { generateItemFieldArray } from '../../../helpers/generators';

function TableTitleComponent({ handleAddNewMetric }) {
  return (
    <TableTitle
      title="Intensity Metrics"
      buttons={[
        {
          children: 'Add New',
          onClick: handleAddNewMetric
        }
      ]}
    />
  );
}

function TableActionColumnRender({
  handleAddNewMetric,
  metric,
  handleAddMetricData
}) {
  const handleEditMetricDetails = () => {
    handleAddNewMetric(metric);
  };
  const handleAddIntensityMetricItems = () => {
    handleAddMetricData(metric);
  };

  return (
    <Space size={24}>
      <div>
        <ButtonIcon name="Pencil" onClick={handleEditMetricDetails} />
      </div>
      <div>
        <Button
          type={BUTTON_TYPES.LINK}
          onClick={handleAddIntensityMetricItems}>
          Add Data
        </Button>
      </div>
    </Space>
  );
}

export function IntensityMetricTable() {
  const { me } = useMeContext();
  const { company } = me ?? {};
  const [selectedMetric, setSelectedMetric] = useState({});
  const fetchIntensityMetrics = useCallback(async () => {
    const response = await IntensityMetricService.listIntensityMetricDetails({
      companySlug: company.slug,
      pagination
    });
    return response.data;
  }, [company.slug]);
  const {
    value: intensityMetricList,
    isLoading,
    execute: refreshIntensityMetricList
  } = useAsync({ defaultValue: [], asyncFunction: fetchIntensityMetrics });
  const { paginationParams: pagination } = usePagination({
    paginationArray: intensityMetricList,
    orderBy: ['updatedAt'],
    pageSize: ''
  });
  const onCloseModal = () => {
    setSelectedMetric({});
  };
  const {
    Modal: EditMetricModal,
    handleShowModal: handleShowEditModal,
    handleCloseModal: handleCloseEditModal
  } = useModal({ onCloseModal });

  const {
    Modal: AddDataModal,
    handleShowModal: handleShowDataModal,
    handleCloseModal: handleCloseDataModal
  } = useModal({ onCloseModal, width: '90%' });

  const handleMetricModalSuccess = () => {
    handleCloseEditModal();
    refreshIntensityMetricList();
  };

  const loadIntensityMetricItemList = async (id) => {
    const items = createIntensityItems(
      me.company?.baseYear,
      me.company?.startingMonth
    );
    const { data: listResponse } =
      await IntensityMetricService.listIntensityMetricItems({
        companySlug: me?.company?.slug,
        intensityMetricDetailsId: id
      });
    generateItemFieldArray(listResponse, items);
    return items;
  };

  const {
    value: itemList,
    isLoading: isLoadingList,
    execute: loadSelectedMetricItems
  } = useAsync({
    asyncFunction: loadIntensityMetricItemList,
    defaultValue: {},
    immediate: false
  });

  const createOrUpdateIntensityMetricItem = async (formValues) => {
    await IntensityMetricService.createOrUpdateIntensityMetricItem({
      companySlug: me?.company?.slug,
      intensityMetricDetailsId: selectedMetric.id,
      items: formValues.filter((year) => !!year.values.length)
    });
    handleCloseDataModal();
  };
  const { execute: onSaveFn, isLoading: isSavingForm } = useAsync({
    asyncFunction: createOrUpdateIntensityMetricItem,
    immediate: false
  });
  const columns = [
    {
      dataIndex: 'name',
      title: <ColumnTitle>Name</ColumnTitle>
    },
    {
      dataIndex: 'description',
      title: <ColumnTitle>Description</ColumnTitle>
    },
    {
      dataIndex: 'updatedAt',
      title: <ColumnTitle>Last Edited</ColumnTitle>,
      render: (updatedAt) =>
        formatInTimeZone(new Date(updatedAt), 'UTC', DateFormats.NORTH_AMERICA)
    },
    {
      width: 15,
      render: (_, metric) => (
        <TableActionColumnRender
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          metric={metric}
          handleAddNewMetric={() => {
            setSelectedMetric(metric);
            handleShowEditModal();
          }}
          handleAddMetricData={() => {
            setSelectedMetric(metric);
            loadSelectedMetricItems(metric.id);
            handleShowDataModal();
          }}
        />
      )
    }
  ];

  return (
    <>
      <Table
        isLoading={isLoading}
        dataSource={intensityMetricList}
        columns={columns}
        title={() =>
          TableTitleComponent({
            handleAddNewMetric: handleShowEditModal
          })
        }
        locale={{
          emptyText: (
            <Empty
              icon="Calculator"
              title="No intensity metrics created"
              description="Intensity metrics allow you to centralize all your business's about info in one place."
            />
          )
        }}
      />
      <EditMetricModal
        title={`${selectedMetric.id ? 'Edit' : 'Create'} Intensity Metric`}>
        <IntensityMetricForm
          onSuccess={handleMetricModalSuccess}
          metric={selectedMetric}
        />
      </EditMetricModal>
      <AddDataModal title="Intensity Data Management">
        <ItemDataForm
          formType="INTENSITY_METRIC"
          details={selectedMetric}
          list={itemList}
          onSaveFn={onSaveFn}
          isSavingForm={isSavingForm}
          isLoadingList={isLoadingList}
        />
      </AddDataModal>
    </>
  );
}
