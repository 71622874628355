import * as Yup from 'yup';

export const setYourOrganizationValidation = Yup.object().shape({
  businessUnitFields: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
      })
    )
    .test({
      name: 'one-true',
      test: (values, ctx) => {
        const isValid =
          values.length === 0 ||
          values.some((value) => value?.name?.length > 0);
        if (isValid) return true;
        return ctx.createError({
          message: 'At least one business unit needs to be filled out',
          path: 'businessUnitFields[0].name'
        });
      }
    })
});
