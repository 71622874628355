export function ChevronRight() {
  return (
    <path
      d="M9 5L16 12L9 19"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default ChevronRight;
