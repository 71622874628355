import { InputNumber, Modal as AntModal, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import moment from 'moment';
import { useState } from 'react';
import { useMeContext } from '../../context';
import { capitalizeText, formatDecimal, analytics } from '../../helpers';
import { getTimeFormat } from '../../helpers/generators';
import { EnergyService } from '../../services';
import { Button, ButtonIcon, ColumnTitle, Table } from '../../stories/atoms';
import EnergyItemForm from '../EnergyItemForm';
import { Modal } from '../index';
import './energyItemList.scss';

function EnergyItemList({
  completed,
  energyItemList,
  energyDetailsId,
  energyTypeId,
  timeline,
  paginationParams,
  total,
  onSuccess,
  onChangeEnergyItem,
  analyticsContext,
  analyticsSaveContext
}) {
  const [showEnergyForm, setShowEnergyForm] = useState(false);

  const [activeEnergyItem, setActiveEnergyItem] = useState();

  const [updateLoading, setUpdateLoading] = useState(false);

  const { me } = useMeContext();
  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );

  const columns = [
    {
      title: <ColumnTitle>Energy Type</ColumnTitle>,
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (id, energyItem) => capitalizeText(energyItem?.energyType?.name)
    },
    {
      title: <ColumnTitle>Billing period</ColumnTitle>,
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: (id, record) =>
        record?.billingStart && record?.billingEnd
          ? `${formatInTimeZone(
              new Date(record?.billingStart),
              'UTC',
              dateDisplayFormat
            )} - ${formatInTimeZone(
              new Date(record?.billingEnd),
              'UTC',
              dateDisplayFormat
            )}`
          : ''
    },
    {
      title: <ColumnTitle>Energy Usage</ColumnTitle>,
      dataIndex: 'activity',
      key: 'activity',
      width: 150,
      render: (activity, energyItem) =>
        completed ? (
          formatDecimal(activity)
        ) : (
          <InputNumber
            className="energy-item-list__input-number"
            defaultValue={activity}
            onChange={(value) => {
              onChangeEnergyItem(energyItem, 'activity', value);
            }}
          />
        )
    },
    {
      title: <ColumnTitle>Units</ColumnTitle>,
      dataIndex: 'id',
      key: 'id',
      render: (id, energyItem) => energyItem?.activityUnit?.friendlyName,
      width: 200
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: (id, energyItem) =>
        completed ? (
          <Space className="energy-item-list__actions justify-end">
            <Button onClick={handleEditEnergyItem(energyItem)}>Edit</Button>
            <ButtonIcon
              scaleIcon={false}
              name="MinusCircle"
              size={18}
              onClick={handleDeleteEnergyItem(energyItem)}
            />
          </Space>
        ) : (
          <Button
            disabled={
              energyItem?.activity === null || !energyItem?.conversionUnitName
            }
            loading={activeEnergyItem?.id === energyItem?.id && updateLoading}
            onClick={handleUpdateEnergyItem(energyItem)}
          >
            Update
          </Button>
        )
    }
  ];

  const handleToggleEnergyForm = () => setShowEnergyForm(!showEnergyForm);

  const handleFormSuccess = () => {
    analytics.track('Save item clicked', me, {
      energyTypeId,
      energyDetailsId,
      ...analyticsSaveContext
    });
    setShowEnergyForm(false);
    onSuccess();
  };

  const handleEditEnergyItem = (energyItem) => () => {
    analytics.track(
      energyItem.id ? 'Edit Item Clicked' : 'Add new Selected',
      me,
      {
        ...analyticsContext,
        energyTypeId,
        energyDetailsId,
        energyItem
      }
    );
    setActiveEnergyItem(energyItem);
    setShowEnergyForm(true);
  };

  const handleDeleteEnergyItem = (energyItem) => () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        EnergyService.deleteEnergyItem(
          energyItem,
          () => {
            analytics.track('Delete item clicked', me, {
              ...analyticsContext,
              energyTypeId,
              energyDetailsId,
              energyItem
            });
            onSuccess();
          },
          () => {},
          () => {}
        );
      }
    });
  };

  const handleUpdateEnergyItem = (energyItem) => () => {
    energyItem.billingStart = moment
      .utc(energyItem.billingStart)
      .format('YYYY-MM-DD');
    energyItem.billingEnd = moment
      .utc(energyItem.billingEnd)
      .format('YYYY-MM-DD');
    setActiveEnergyItem(energyItem);
    setUpdateLoading(true);
    EnergyService.updateEnergyItem(
      energyItem,
      () => {
        onSuccess();
      },
      () => {},
      () => {
        setActiveEnergyItem(undefined);
        setUpdateLoading(false);
      }
    );
  };

  return (
    <div className="energy-item-list">
      <Table
        isHeaderBorder
        columns={columns}
        rowKey="id"
        dataSource={energyItemList}
        total={total}
        showPagination
        pagination={paginationParams}
      />
      <Modal
        width="60%"
        visible={showEnergyForm}
        onCancel={handleToggleEnergyForm}
      >
        {activeEnergyItem && (
          <EnergyItemForm
            analyticsSaveContext={analyticsSaveContext}
            timeline={timeline}
            energyDetailsId={energyDetailsId}
            energyTypeId={energyTypeId}
            onCancel={handleToggleEnergyForm}
            energyItem={activeEnergyItem}
            onSuccess={handleFormSuccess}
          />
        )}
      </Modal>
    </div>
  );
}

export default EnergyItemList;
