import * as Yup from 'yup';
import {
  confirmPasswordValidator,
  nameValidator,
  passwordValidator
} from '../../../../helpers';

export const createAccountFormValidation = Yup.object().shape({
  firstName: nameValidator(true, 'First name is required'),
  lastName: nameValidator(false),
  password: passwordValidator,
  confirmPassword: confirmPasswordValidator,
  isAcceptTermsAndConditions: Yup.bool().oneOf(
    [true],
    'Please accept Terms and Conditions'
  )
});
