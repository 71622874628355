import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loader as BaseLoader } from '../components/index';

const useAsync = ({ asyncFunction, immediate = true, defaultValue = null }) => {
  const unmounted = useRef(false);

  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...props) => {
      setStatus('pending');
      setValue(defaultValue);
      setError(null);
      return asyncFunction(...props)
        .then((response) => {
          if (!!response) {
            setValue(response);
          }
          setStatus('success');
        })
        .catch((err) => {
          setError(err);
          setStatus('error');
        });
    },
    [asyncFunction]
  );

  const Loader = useMemo(
    () =>
      function ({ children, ...props }) {
        return (
          <BaseLoader isLoading={status === 'pending'} {...props}>
            {children}
          </BaseLoader>
        );
      },
    [status]
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate && !unmounted.current) {
      execute();
    }
  }, [execute, immediate, unmounted.current]);

  useEffect(
    () =>
      //  This prevents unintentional memory leaks by preventing state updates when the hook is unmounted
      () => {
        unmounted.current = true;
      },
    []
  );
  return {
    execute,
    status,
    value,
    error,
    Loader,
    isLoading: status === 'pending',
    setValue
  };
};
export default useAsync;
