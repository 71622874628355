import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { Notification } from '../../../../components';
import { NotificationCategory } from '../../../../constants';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { useAsync } from '../../../../hooks';
import { analytics } from '../../../../helpers';
import { NotificationService } from '../../../../services';
import {
  Button,
  DatePicker,
  Input,
  Paragraph,
  Title
} from '../../../../stories/atoms';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import OwnerSelect from '../../../../stories/molecules/OwnerSelect';
import './CompanyInitiativeCustomActionForm.scss';
import { CompanyInitiativeCustomActionFormValidation } from './CompanyInitiativeCustomActionFormValidation';

export function CompanyInitiativeCustomActionForm({
  onSuccess,
  analyticsContext
}) {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiativeId, companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { me } = useMeContext();
  const handleCreateNotification = async (values) => {
    await NotificationService.createNotification({
      companyInitiativeId,
      category: NotificationCategory.INITIATIVE,
      initiativeTemplateId: initiativeTemplate.id,
      companySlug: me?.company?.slug,
      ...values
    });
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
    Notification({
      type: 'success',
      message: 'Action created',
      description: 'You can see it on the actions page'
    });
    onSuccess?.();
  };

  const { execute: handleSubmit, isLoading } = useAsync({
    asyncFunction: handleCreateNotification,
    immediate: false
  });
  return (
    <Row>
      <Col span={24}>
        <Title bottomSpacing={12}>Create action</Title>
        <Paragraph size="sm" bottomSpacing={32}>
          This is a task you can connect to an initiative.
        </Paragraph>
      </Col>
      <Col span={24}>
        <Formik
          validationSchema={CompanyInitiativeCustomActionFormValidation}
          className="company-initiative-custom-action-form"
          onSubmit={handleSubmit}
          initialValues={{
            title: null,
            message: null,
            ownerId: null,
            dueDate: null
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row className="company-initiative-custom-action-form__wrapper">
                <Col span={24}>
                  <Row gutter={[0, 16]}>
                    <Col span={24}>
                      <Input
                        title="Title (required)"
                        name="title"
                        placeholder="Enter"
                      />
                    </Col>
                    <Col span={24}>
                      <Input
                        type={INPUT_TYPES.TEXT_AREA}
                        title="Description"
                        name="message"
                        placeholder="Enter"
                      />
                    </Col>
                    <Col span={24}>
                      <OwnerSelect
                        value={values?.ownerId}
                        setFieldValue={setFieldValue}
                        title="Assignee"
                        name="ownerId"
                        placeholder="Enter"
                      />
                    </Col>
                    <Col span={24}>
                      <DatePicker
                        title="Due Date"
                        name="dueDate"
                        defaultValue={values?.dueDate}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  className="company-initiative-custom-action-form__footer"
                >
                  <Button htmlType="submit" isLoading={isLoading}>
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
