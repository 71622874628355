import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { InputField } from '../../../components';
import { MeService } from '../../../services';
import { Button } from '../../../stories/atoms';
import './changePasswordForm.scss';
import { changePasswordFormValidation } from './changePasswordFormValidation';

function ChangePasswordForm({ onSuccess }) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values) => {
    setFormLoading(true);
    MeService.updatePassword(
      values,
      () => {
        onSuccess();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="change-password-form">
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={changePasswordFormValidation}
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
      >
        {({ values, _errors, _isValid, _dirty, _setFieldValue }) => (
          <Form className="change-password-form__form">
            <h4 className="text-bd"> Change Password</h4>
            <Row>
              <Col span={18}>
                <InputField
                  title="Password"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  required
                />
                {values?.password?.length > 10 && (
                  <div className="change-password-form__password-note">
                    {values?.password?.length < 13
                      ? 'Password is okay'
                      : 'Password is strong'}
                  </div>
                )}
              </Col>
              <Col span={18}>
                <InputField
                  title="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  placeholder="Enter Password"
                  required
                />
              </Col>
              <Col span={24} className="text-right">
                <Button htmlType="submit" loading={formLoading}>
                  Update Password
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordForm;
