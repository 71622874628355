import axios from 'axios';
import { Notification } from '../components';

const apiAxiosInstance = axios.create({
  timeout: 200000
});

apiAxiosInstance.interceptors.response.use(
  (response) => ({
    data: response.data,
    message: response.statusText,
    status: response.status
  }),
  (error) => {
    const { response } = error;
    let errorMessage;
    if (response?.data?.errors?.length > 0) {
      errorMessage = response?.data?.errors
        .map((error) => error.friendlyMessage ?? error.msg)
        .join('\n');
    }
    Notification({
      type: 'error',
      message: errorMessage || 'Something went wrong. Please try again'
    });

    return Promise.reject(error);
  }
);

export default apiAxiosInstance;
