import { createModelSchema, primitive } from 'serializr';

class Industry {}

createModelSchema(Industry, {
  id: primitive(),
  name: primitive()
});

export default Industry;
