import { Col, Divider, Row, Space } from 'antd';
import { useState } from 'react';
import biochar from '../../assets/images/biochar.png';
import kelp from '../../assets/images/kelp.png';
import truck from '../../assets/images/truck.png';
import { Card } from '../../components';
import { COLOURS_CARBONHOUND, COLOURS_GRAY } from '../../constants/colours';
import PatchSampleProjects from '../../constants/PatchSampleProjects';
import CreditsBasicExplanation from '../../features/CreditsBasicExplaination';
import CreditsOffsetsExplaination from '../../features/CreditsOffsetsExplaination';
import CreditsProject from '../../features/CreditsProject';
import { useModal } from '../../hooks';
import { GreenPaintbrush } from '../../stories/assets/svgs';
import ChLogoIcon from '../../stories/assets/svgs/CHLogoIcon';
import PawContract from '../../stories/assets/svgs/PawContract';
import {
  Anchor,
  Button,
  Icon,
  Page,
  Paragraph,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import './Credits.scss';

function Credits() {
  const [visibleInfoModalPage, setVisibleInfoModalPage] = useState();
  const [visibleModalProject, setVisibleModalProject] = useState();
  const {
    Modal: InformationModal,
    handleShowModal: handleShowInformationModal
  } = useModal({
    onCloseModal: () => {
      setVisibleInfoModalPage(undefined);
    },
    width: '90%'
  });

  const { Modal: ProjectModal, handleShowModal: handleShowProjectModal } =
    useModal({
      onCloseModal: () => {
        setVisibleModalProject(undefined);
      },
      width: '90%'
    });

  const handleSetInformationModal = (context) => {
    setVisibleInfoModalPage(context);
    handleShowInformationModal();
  };
  const handleSetProjectModal = (context) => {
    setVisibleModalProject(context);
    handleShowProjectModal();
  };
  return (
    <Page className="credits">
      <Row gutter={[24, 24]} align="middle" justify="center">
        <Col xs={18} xl={9}>
          <Title fontSize={32} className="mb-4" size="xl">
            What are carbon credits?
          </Title>
          <Paragraph size="md" color={COLOURS_GRAY.GRAY_600} className="mb-8">
            Carbon credits allow a company to invest in a project in order to
            compensate for the emissions made in their regular business
            operations.
          </Paragraph>
          <Button
            type={BUTTON_TYPES.LINK}
            icon="ChevronRight"
            onClick={() => handleSetInformationModal('basic')}
          >
            Learn more about carbon credits
          </Button>
        </Col>
        <Col xs={18} xl={9}>
          <Card>
            <Title size="rg" color={COLOURS_GRAY.GRAY_400}>
              Example Projects
            </Title>
            <Row gutter={[16, 16]} className="credits__projects mt-4">
              <Col span={24}>
                <Row
                  className="mb-2 credits__projects__item"
                  gutter={16}
                  align="middle"
                  onClick={() => handleSetProjectModal('biochar')}
                >
                  <Col flex="0 1">
                    <img src={biochar} alt="A glass of Biochar" />
                  </Col>
                  <Col flex="1 1">
                    <Title size="md">Carbo Culture Biochar</Title>
                    <Paragraph color={COLOURS_GRAY.GRAY_500}>
                      Converting forestry and food production residues into
                      biochar.
                    </Paragraph>
                  </Col>
                  <Col flex="0 1">
                    <Icon
                      size={24}
                      name="ChevronRight"
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                </Row>
                <Row
                  className="mb-2 credits__projects__item"
                  gutter={16}
                  align="middle"
                  onClick={() => handleSetProjectModal('concreteCure')}
                >
                  <Col flex="0 1">
                    <img src={truck} alt="An orange concrete mixing truck" />
                  </Col>
                  <Col flex="1 1">
                    <Title size="md">CarbonCure Concrete Mineralization</Title>
                    <Paragraph color={COLOURS_GRAY.GRAY_500}>
                      Injecting CO₂ into cement to make super-strong low-carbon
                      concrete.
                    </Paragraph>
                  </Col>
                  <Col flex="0 1">
                    <Icon
                      size={24}
                      name="ChevronRight"
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                </Row>
                <Row
                  className="mb-2 credits__projects__item"
                  gutter={16}
                  align="middle"
                  onClick={() => handleSetProjectModal('kelpFarming')}
                >
                  <Col flex="0 1">
                    <img src={kelp} alt="kelp" />
                  </Col>
                  <Col flex="1 1">
                    <Title size="md">Running Tide Kelp Sequestration</Title>
                    <Paragraph color={COLOURS_GRAY.GRAY_500}>
                      Growing and sinking kelp forests.
                    </Paragraph>
                  </Col>
                  <Col flex="0 1">
                    <Icon
                      size={24}
                      name="ChevronRight"
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[0, { xs: 12, xl: 40 }]}
        className="bg-white"
        justify="center"
      >
        <Col span={24}>
          <Title
            align="center"
            size="lg"
            fontSize={24}
            color={COLOURS_GRAY.GRAY_800}
          >
            Why <em>forward-thinking</em> businesses use credits
          </Title>
        </Col>
        <Col xs={18} xl={8} className="credits__testimony">
          <Title color={COLOURS_GRAY.GRAY_800} className="mb-6" size="lg">
            “There are emissions my company can’t avoid”
          </Title>
          <Paragraph>
            A lot of teams want to be climate pioneers but can’t reduce their
            operational footprint any more, credits are a great way to support
            your carbon neutral efforts.
          </Paragraph>
        </Col>
        <Col xs={18} xl={8} className="credits__testimony">
          <Title color={COLOURS_GRAY.GRAY_800} className="mb-6" size="lg">
            “I want our company to take immediate action to fight against the
            climate crisis”
          </Title>
          <Paragraph>
            Credits allow for your efforts to have an immediate impact as you
            support projects that are already on the ground running.
          </Paragraph>
        </Col>
        <Col xs={18} xl={8} className="credits__testimony">
          <Title color={COLOURS_GRAY.GRAY_800} className="mb-6" size="lg">
            "We want to be planet positive and remove more carbon from the
            atmosphere than we emit as a company"
          </Title>
          <Paragraph>
            Carbon credits allow you to go beyond neutral if you wish and become
            a carbon positive company.
          </Paragraph>
        </Col>
        <Col span={24} className="display-flex justify-center">
          <Divider />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col span={24} className="mb-4">
              <Title size="lg" fontSize={24} align="center">
                We offer free carbon credit consultations
              </Title>
            </Col>
            <Col span={12}>
              <Paragraph
                className="mb-8"
                align="center"
                color={COLOURS_GRAY.GRAY_600}
              >
                We will be sure your portfolio of projects fits with your
                companies mission. In this consultation session we will help you
                build out a credit strategy and give you options to choose from
                for portfolios.
              </Paragraph>
            </Col>
            <Col span={24} className="display-flex justify-center">
              <a
                href="https://meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Book Now</Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} className="display-flex justify-center mb-10">
          <div className="credits__logo credits__logo--orange credits__logo--size-72">
            <ChLogoIcon width={40} />
          </div>
        </Col>
        <Col span={24} className="mb-6">
          <Title
            size="lg"
            align="center"
            fontSize={24}
            color={COLOURS_GRAY.GRAY_800}
          >
            Our Approach
          </Title>
        </Col>
        <Col span={14} className="mb-6">
          <Paragraph size="lg" align="center">
            Carbonhound rigorously reviews all of the projects in its portfolios
            and incorporates guidance from 3rd party experts like{' '}
            <Anchor href="https://carbonplan.org/">Carbonplan</Anchor> and the{' '}
            <Anchor href="https://www.smithschool.ox.ac.uk/sites/default/files/2022-01/Oxford-Offsetting-Principles-2020.pdf">
              The Oxford Principles for Net Zero Aligned Carbon Offsetting
            </Anchor>{' '}
            to provide carbon credits that result in real climate impact.
          </Paragraph>
        </Col>
        <Row gutter={[16, { xs: 24, sm: 24 }]} justify="center">
          <Col xs={15} sm={15} lg={15} xl={5}>
            <Card>
              <Space align="center" direction="vertical" size={16}>
                <div className="credits__logo credits__logo--purple credits__logo--size-40">
                  <Icon
                    name="Globe"
                    color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                  />
                </div>
                <Title size="md">Removals Only</Title>
                <Paragraph align="center" color={COLOURS_GRAY.GRAY_500}>
                  Purchasing avoidance credits doesn't change the amount of
                  carbon in the atmosphere from your emissions. Supporting
                  removal projects means your impact is neutralized.
                </Paragraph>
              </Space>
            </Card>
          </Col>
          <Col xs={15} sm={15} lg={15} xl={5} className="display-flex">
            <Card>
              <Space align="center" direction="vertical" size={16}>
                <div className="credits__logo credits__logo--purple credits__logo--size-40">
                  <Icon
                    name="Beaker"
                    color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                  />
                </div>
                <Title size="md">Science based</Title>
                <Paragraph align="center" color={COLOURS_GRAY.GRAY_500}>
                  We use the most recent scientific modelling to ensure that
                  your offsets have the real-world impact you expect.
                </Paragraph>
              </Space>
            </Card>
          </Col>
          <Col xs={15} sm={15} lg={15} xl={5} className="display-flex">
            <Card>
              <Space align="center" direction="vertical" size={16}>
                <div className="credits__logo credits__logo--purple credits__logo--size-40">
                  <Icon
                    name="Weight"
                    color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                  />
                </div>
                <Title size="md">We do the heavy lifting </Title>
                <Paragraph align="center" color={COLOURS_GRAY.GRAY_500}>
                  We source high quality projects and manage payment,
                  registration, certifications, reporting and auditing.
                </Paragraph>
              </Space>
            </Card>
          </Col>
          <Col span={15}>
            <a
              href="https://meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card className="credits__contact-us">
                <Row align="middle" justify="center" gutter={24}>
                  <Col flex="shrink">
                    <div className="credits__logo credits__logo--purple credits__logo--size-40">
                      <Icon
                        name="Phone"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </div>
                  </Col>
                  <Col flex="1">
                    <Title includeSpacing={false} size="md">
                      Contact us to build a customized carbon credit plan with
                      you
                    </Title>
                  </Col>
                  <Col flex="shrink">
                    <Icon name="ChevronRight" />
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
        </Row>
      </Row>
      <Row className="bg-white" justify="center">
        <Col span={24} className="mb-20">
          <Title className="mb-4" align="center" size="xl">
            How we manage your risk
          </Title>
          <Paragraph className="mb-0" align="center">
            Credits vary widely in quality which introduces risk to buyers.
          </Paragraph>
          <Paragraph className="mb-0" align="center">
            We take great care to protect you.
          </Paragraph>
        </Col>
        <Col span={24} className="mb-20">
          <Row gutter={[60, 80]} justify="center" align="middle">
            <Col xs={16} xl={3}>
              <div className="credits__logo">
                <GreenPaintbrush />
              </div>
            </Col>
            <Col xs={16} xl={13}>
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Title className="mb-2" size="lg">
                    Accusations of Greenwashing
                  </Title>
                  <Paragraph>
                    Many companies make claims to be environmentally conscious
                    but treat credits as a green light to continue to emit at
                    their current rate and avoid their responsibility to make
                    reductions.
                  </Paragraph>
                </Col>
                <Col span={24} className="credits__gray-bg">
                  <Title className="mb-2" size="lg">
                    Our approach
                  </Title>
                  <Paragraph>
                    <ul>
                      <li>A reductions-first model.</li>
                      <li>
                        We advise our clients to only neutralize what part of
                        their footprint is unavoidable.
                      </li>
                      <li>
                        We rely on guidance from 3rd party experts to ensure the
                        projects you invest in are making a real difference.
                      </li>
                    </ul>
                  </Paragraph>
                </Col>
              </Row>
            </Col>
            <Col xs={16} xl={13}>
              <Row gutter={[16, 16]} className="mb-6">
                <Col span={24}>
                  <Title className="mb-2" size="lg">
                    Credit Quality
                  </Title>
                  <Paragraph>
                    It is difficult to evaluate the quality of a credit and feel
                    confident that you are getting what you paid for. Some of
                    the key problem areas are:
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <Title className="mb-2" size="rg">
                    Permanence
                  </Title>
                  <Paragraph>
                    Storing carbon permanently is critical to combatting climate
                    change. Current standards assign the same value to credits
                    regardless of how permanent the project is.
                  </Paragraph>
                </Col>
                <Col span={24}>
                  <Title className="mb-2" size="rg">
                    Additionality
                  </Title>
                  <Paragraph>
                    Proving that climate action is being taken solely because of
                    your credit purchase can be difficult for certain projects.
                  </Paragraph>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col span={24} className="credits__gray-bg">
                  <Title className="mb-4" size="lg">
                    Our approach
                  </Title>
                  <Paragraph>
                    <ul>
                      <li>
                        We source and recommend projects with 1000+ years of
                        permanence.
                      </li>
                      <li>
                        Projects must only take into account real world
                        conditions.
                      </li>
                      <li>
                        Projects must be quantified by reputable third party
                        verifiers.
                      </li>
                    </ul>
                  </Paragraph>
                </Col>
              </Row>
              <Row
                className="credits__gray-bg credits__offsets-read-more"
                align="middle"
                onClick={() => handleSetInformationModal('offsets')}
              >
                <Col flex="0 1" className="mr-5">
                  <Icon
                    name="Libra"
                    color={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
                  />
                </Col>
                <Col flex="1 1" className="mr-5">
                  <Paragraph includeSpacing={false}>
                    Read more about offset quality and our approach
                  </Paragraph>
                </Col>
                <Col flex="0 1">
                  <Icon name="ChevronRight" />
                </Col>
              </Row>
            </Col>
            <Col xs={16} xl={5}>
              <div className="credits__logo">
                <PawContract />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 8]} justify="center">
            <Col span={24}>
              <Title align="center" size="lg">
                Build a custom carbon credit portfolio with us
              </Title>
            </Col>
            <Col span={8} className="mb-10">
              <Paragraph align="center">
                We understand that each team has unique goals with carbon
                credits so we take our time to craft a strategy that makes the
                most sense for you.
              </Paragraph>
            </Col>

            <Col span={24} className="display-flex justify-center">
              <a
                href="https://meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Book Now</Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <InformationModal>
        {visibleInfoModalPage === 'basic' ? (
          <CreditsBasicExplanation />
        ) : (
          <CreditsOffsetsExplaination />
        )}
      </InformationModal>
      <ProjectModal maskClosable className="no-padding">
        {visibleModalProject && (
          <CreditsProject project={PatchSampleProjects[visibleModalProject]} />
        )}
      </ProjectModal>
    </Page>
  );
}

export default Credits;
