import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../components';
import { OfficeTrail, OfficeTrailSegment } from '../../../../constants';
import { useMeContext, useOfficeTrailContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { CompanyTrailService } from '../../../../services';
import truckIcon from '../../../../stories/assets/svgs/truckIcon.svg';
import { Anchor, Button, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import './CustomSources.scss';

export function CustomSources() {
  const { me } = useMeContext();
  const navigate = useNavigate();
  const { refreshOfficeTrails } = useOfficeTrailContext();
  const completeStep = async () => {
    await CompanyTrailService.createCompanyTrail(me.company.slug, {
      trailName: OfficeTrail.OFFICE_ONBOARDING,
      segmentName: OfficeTrailSegment.CUSTOM_SOURCES,
      isComplete: true
    });
    refreshOfficeTrails();
  };
  const { execute: handleCompleteStep } = useAsync({
    asyncFunction: completeStep,
    immediate: false
  });

  const handleSkipStep = () => {
    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Custom Sources'
    });
    handleCompleteStep();
    navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING);
  };
  const handleManageSources = () => {
    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Custom Sources'
    });
    handleCompleteStep();
    navigate(AppRoutes.SOURCES);
  };

  return (
    <Row className="custom-sources" gutter={[0, 32]}>
      <Col span={24}>
        <Title size="xl" bottomSpacing={0}>
          Other emission sources
        </Title>
      </Col>
      <Col span={24}>
        <Card>
          <Row justify="center" className="mb-8">
            <Col>
              <img src={truckIcon} alt="An illustration of a truck" />
            </Col>
          </Row>
          <Row justify="center" className="mb-6">
            <Col flex="510px">
              <Title align="center" bottomSpacing={8} size="xl">
                Map your other emission sources
              </Title>
              <Paragraph align="center" size="sm" bottomSpacing={0}>
                Now that we have captured the most common sources of emissions,
                it’s time to add any missing sources to ensure you have a
                complete view of your emissions.
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" gutter={[16, 16]}>
            <Col>
              <Button type={BUTTON_TYPES.TEXT} onClick={handleSkipStep}>
                Skip
              </Button>
            </Col>
            <Col>
              <Button onClick={handleManageSources}>Create Source</Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24} className="custom-sources__information">
        <Card>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Title bottomSpacing={24} size="lg">
                What is an emission source?
              </Title>
              <Paragraph bottomSpacing={24}>
                Every business process has a carbon footprint, we track all of
                these processes as Emission sources.
              </Paragraph>
              <Paragraph bottomSpacing={24}>
                There are over 15 categories of emission sources covered by the
                GHG Protocol, these help us bucket emissions and identify areas
                of business operations where we can target reduction
                initiatives.
              </Paragraph>
              <Paragraph bottomSpacing={24}>
                Some examples of sources include: Landfill waste, Office
                Supplies, Business flights and Company owned vehicles
              </Paragraph>
              <Paragraph bottomSpacing={16}>
                We have a guide to help you discover your companies emission
                sources, and ensure you have covered all the additional
                categories of emissions.
              </Paragraph>
              <Anchor
                href="https://carbonhound.notion.site/Day-3-Other-Emission-Sources-32739b41b77045fcbd27d5927710f5e4?pvs=4"
                fontWeight={600}
              >
                Read the full guide
              </Anchor>
            </Col>
            <Col span={24} className="custom-sources__information--blurb">
              <Row wrap={false} className="blurb-bg-gray" align="middle">
                <Col flex={1}>
                  <Title bottomSpacing={4} size="lg">
                    Need support?
                  </Title>
                  <Paragraph bottomSpacing={0}>
                    Our climate team can support you as you configure the
                    platform to fit your needs.
                  </Paragraph>
                </Col>
                <Col>
                  <Anchor
                    href="https://meetings.hubspot.com/carbonhound-erin/carbonhound-sources-onboarding-"
                    fontWeight="600"
                  >
                    Talk to our Climate Team
                  </Anchor>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
