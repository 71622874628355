import { Input as AntInput } from 'antd';
import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import Error from '../../../components/Error';
import { COLOURS_GRAY } from '../../../constants/colours';
import { Icon } from '../index';
import Label from '../Label';
import './Input.scss';
import { INPUT_TYPES } from './Input.types';

const getInputType = (type) => {
  switch (type) {
    case INPUT_TYPES.TEXT_AREA:
      const { TextArea } = AntInput;
      return TextArea;
    case INPUT_TYPES.SEARCH:
      const { Search } = AntInput;
      return Search;
    case INPUT_TYPES.PASSWORD:
      const { Password } = AntInput;
      return Password;
    case INPUT_TYPES.TEXT:
    default:
      return AntInput;
  }
};

function Input({
  inputClassName,
  wrapperClassName,
  isFormikField = true,
  type = INPUT_TYPES.TEXT,
  name,
  icon,
  title,
  enterButton,
  placeholder,
  allowClear,
  ...inputProps
}) {
  const InputType = getInputType(type);
  const isClearable =
    !!allowClear ||
    (typeof allowClear === 'undefined' && type === INPUT_TYPES.SEARCH);
  const clearIcon = isClearable
    ? {
        clearIcon: <Icon color={COLOURS_GRAY.GRAY_800} name="Times" />
      }
    : undefined;
  return (
    <div
      className={classNames('ch-input-wrapper', {
        [wrapperClassName]: !!wrapperClassName,
        [type]: type ?? INPUT_TYPES.TEXT,
        disabled: inputProps.disabled,
        'allow-clear': isClearable
      })}
    >
      {title && <Label>{title}</Label>}
      <div className="ch-input-wrapper__icon-wrapper">
        {isFormikField ? (
          <>
            <Field
              className={classNames('ch-input ch-input-field', {
                [inputClassName]: !!inputClassName,
                [`input-${type}`]: !!type
              })}
              name={name}
              as={InputType}
              placeholder={placeholder || 'Enter'}
              {...inputProps}
              allowClear={clearIcon}
            />

            <ErrorMessage name={name}>
              {(message) => (
                <Error
                  className={`ch-input__error ${name}__error`}
                  message={message}
                />
              )}
            </ErrorMessage>
          </>
        ) : (
          <InputType
            className={classNames('ch-input', {
              [inputClassName]: !!inputClassName,
              [`input-${type}`]: type ?? INPUT_TYPES.TEXT
            })}
            placeholder={placeholder || 'Enter'}
            enterButton={enterButton}
            {...inputProps}
            allowClear={clearIcon}
          />
        )}
        {!!icon && <Icon size={16} name={icon} />}
      </div>
    </div>
  );
}
export default Input;
