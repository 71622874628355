import React from 'react';
import { ChcAdminCompanyList } from '../../../features/ChcAdmin/ChcAdminCompanyList';
import { Page } from '../../../stories/atoms';

export function ChcAdminCompanyOverview() {
  return (
    <Page className="chc-admin-company-list">
      <ChcAdminCompanyList />
    </Page>
  );
}
