import * as Yup from 'yup';

export const setGreenTeamValidations = Yup.object().shape({
  adminFields: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string().matches(/(^$|^.*@.*\..*$)/, {
          message: 'Please include either an email on an empty field'
        }),
        userRole: Yup.string()
      })
    )
    .test({
      name: 'one-true',
      message: 'At least one email needs to be added',
      test: (values) => values.some((value) => value.email)
    })
});
