import { Col, Collapse as AntCollapse, Row } from 'antd';
import { Link } from 'react-router-dom';
import achievementIcon from '../../assets/images/achievementIcon.png';
import { Card, Collapse, Progress } from '../../components';
import AppRoutes from '../../routes/appRoutes';
import { Button } from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import './achievements.scss';

function Achievements() {
  return (
    <div className="achievements">
      <Row align="middle">
        <Col span={12}>
          <h5 className="text-bd">Achievements Earned</h5>
        </Col>
        <Col span={12} className="text-right">
          <Link to={AppRoutes.EXTERNAL_DASHBOARD}>
            <Button type={BUTTON_TYPES.SECONDARY}>
              Manage external Dashboard
            </Button>
          </Link>
        </Col>
      </Row>
      <div className="achievements__badges">
        {[...Array(4)].map((x, i) => (
          <div key={i} className="achievements__badge">
            <img
              src={achievementIcon}
              className="achievements__badge-icon"
              alt="Achievements Badge"
            />
          </div>
        ))}
      </div>
      <Card className="achievements__recent-achievements">
        <div className="mt-2 mb-2 text-center">
          <Button prefixIcon={<i className="icon-achievements" />}>
            Recent Achievements
          </Button>
          <div className="mt-4 text-left">
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">House Trained</span>
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">
                  WRI Office based organization standard
                </span>
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="achievements__collapse-wrapper">
        <Collapse>
          <AntCollapse.Panel
            header={
              <div className="achievements__collapse-row">
                <Row align="middle">
                  <Col span={5}>
                    <h6 className="text-bd mb-0">Footprint Accuracy</h6>
                    <p>Achievements Earned 3/5</p>
                  </Col>
                  <Col span={10}>
                    <Progress
                      className="achievements__header-progress"
                      percent={0}
                      fillColor="#5c29c7"
                      showBadge={false}
                    />
                    <span className="text-sbd ml-4">Top 100%</span>
                  </Col>
                </Row>
              </div>
            }
            key="1"
          >
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">House Trained</span>
                {/* <Progress
                  className="achievements__item-progress"
                  percent={0}
                  success={false}
                  format={(percent) => percent + "%"}
                  bgColor="#d9d9d9"
                  fillColor="#fcab1d"
                  showBadge={false}
                /> */}
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">
                  WRI Office based organization standard
                </span>
                {/* <Progress
                  className="achievements__item-progress"
                  percent={0}
                  success={false}
                  format={(percent) => percent + "%"}
                  bgColor="#d9d9d9"
                  fillColor="#fcab1d"
                  showBadge={false}
                /> */}
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">
                  Meet Greenhouse Gas Protocol accounting standard
                </span>
                {/* <Progress
                  className="achievements__item-progress"
                  percent={0}
                  success={false}
                  format={(percent) => percent + "%"}
                  bgColor="#d9d9d9"
                  fillColor="#fcab1d"
                  showBadge={false}
                /> */}
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
            <div className="achievements__item">
              <div className="achievements__item__badge">
                <img
                  src={achievementIcon}
                  className="achievements__item__icon"
                  alt="Achievements Badge"
                />
              </div>
              <div className="achievements__item__content">
                <span className="achievements__item__title">
                  Engage your team and build a culture of sustainability
                </span>
                {/* <Progress
                  className="achievements__item-progress"
                  percent={0}
                  success={false}
                  format={(percent) => percent + "%"}
                  bgColor="#d9d9d9"
                  fillColor="#fcab1d"
                  showBadge={false}
                /> */}
                <span className="achievements__item__date">06/03/2021</span>
              </div>
            </div>
          </AntCollapse.Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default Achievements;
