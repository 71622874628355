import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class IntensityMetricService {
  static async listIntensityMetricDetails({ companySlug, pagination }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_DETAILS.LIST.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(API_URL, { pagination });

      const { totalRowCount, details } = response.data ?? {};
      return { success: true, data: details, totalRowCount };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async readIntensityMetricDetails({
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_DETAILS.ID.replace(
        ':intensityMetricDetailsId',
        intensityMetricDetailsId
      )}`;
      const response = await AxiosInstance.get(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async createIntensityMetricDetails({ companySlug, ...params }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_DETAILS.COMPANY_SLUG.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async updateIntensityMetricDetails({
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_DETAILS.ID.replace(
        ':intensityMetricDetailsId',
        intensityMetricDetailsId
      )}`;
      const response = await AxiosInstance.put(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async listIntensityMetricItems({
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_ITEM.LIST.replace(
        ':intensityMetricDetailsId',
        intensityMetricDetailsId
      )}`;
      const response = await AxiosInstance.post(API_URL, params);

      return { success: true, data: response.data?.details };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async readIntensityMetricItem({
    intensityMetricItemId,
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_ITEM.ID.replace(
        ':intensityMetricItemId',
        intensityMetricItemId
      ).replace(':intensityMetricDetailsId', intensityMetricDetailsId)}`;
      const response = await AxiosInstance.get(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async createOrUpdateIntensityMetricItem({
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.INTENSITY_METRIC_ITEM.BULK.replace(
        ':intensityMetricDetailsId',
        intensityMetricDetailsId
      )}`;
      const response = await AxiosInstance.post(API_URL, params);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async listIntensityMetricTemplates({ companySlug, ...params }) {
    try {
      const API_URL = ApiRoutes.INTENSITY_METRIC_TEMPLATE.LIST.replace(
        ':companySlug',
        companySlug
      );
      const response = await AxiosInstance.post(API_URL, {}, { params });
      return { success: true, data: response.data?.rows };
    } catch (error) {
      return { success: false, error };
    }
  }

  /** Aggregates **/
  static async getIntensityMetricTimeline({
    intensityMetricDetailsId,
    ...params
  }) {
    try {
      const API_URL = ApiRoutes.INTENSITY_METRIC_DETAILS.TIMELINE.replace(
        ':intensityMetricDetailsId',
        intensityMetricDetailsId
      );
      const response = await AxiosInstance.get(API_URL, { params });

      return { success: true, data: response.data?.timeline };
    } catch (error) {
      return { success: false, error };
    }
  }
}

export default IntensityMetricService;
