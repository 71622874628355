import { PopupButton } from '@typeform/embed-react';
import { Col, Row } from 'antd';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import Error from '../../../../../components/Error';
import { capitalizeText } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import IntensityMetricService from '../../../../../services/intensityMetricService';
import { Input, Paragraph, Table, Title } from '../../../../../stories/atoms';
import { INPUT_TYPES } from '../../../../../stories/atoms/Input/Input.types';
import './StepOne.scss';
import { useMeContext } from '../../../../../context';

const columns = [
  {
    title: 'Template Name',
    dataIndex: 'name',
    width: '100%',
    render: (name, { description }) => (
      <div>
        <Title fontWeight={500} bottomSpacing={0}>
          {name}
        </Title>
        {description && <Paragraph bottomSpacing={0}>{description}</Paragraph>}
      </div>
    )
  },
  {
    title: 'Unit',
    dataIndex: 'intensityUnit',
    render: (intensityUnit) => `tCO2e per ${capitalizeText(intensityUnit)}`
  }
];

export function StepOne() {
  const { setValues, values } = useFormikContext();
  const { me } = useMeContext();
  const { company } = me;
  const [searchInput, setSearchInput] = useState('');
  const { intensityMetricTemplateId } = values;

  const handleLoadMetricTemplateList = useCallback(async () => {
    return await IntensityMetricService.listIntensityMetricTemplates({
      companySlug: company.slug,
      ignoreUsedTemplates: true
    });
  }, [company.slug]);

  const { value: templateResp, isLoading } = useAsync({
    asyncFunction: handleLoadMetricTemplateList,
    defaultValue: { templates: [] }
  });
  const { data: templates } = templateResp;
  const handleSelectTemplate = (selectedTemplate) => {
    const {
      name,
      description,
      id: selectedIntensityMetricTemplateId
    } = selectedTemplate;

    setValues({
      intensityMetricTemplateId: selectedIntensityMetricTemplateId,
      name,
      description
    });
  };

  const onSelectRowRadio = (selectedRow) => {
    handleSelectTemplate(selectedRow);
  };

  const handleOnSearch = ({ target }) => {
    const { value } = target;
    setSearchInput(value);
  };

  const onRowFn = (selectedTemplate) => ({
    onClick: () => {
      handleSelectTemplate(selectedTemplate);
    },
    className: 'cursor-pointer'
  });

  const dataSource = templates?.filter(({ name }) =>
    name.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <Row gutter={[24, 24]} className="intensity-metric-form-create-step-one">
      <Col span={24}>
        <Row wrap={false} gutter={16} align="middle">
          <Col flex="1">
            <Input
              isFormikField={false}
              allowClear
              onChange={handleOnSearch}
              placeholder="Search metric templates"
              type={INPUT_TYPES.SEARCH}
            />
          </Col>
          <Col>
            <PopupButton
              className="ch-typeform-button--secondary"
              id="FiOW7QIo"
              buttonProps={{
                type: 'button'
              }}
            >
              Request
            </PopupButton>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          isLoading={isLoading}
          onRow={onRowFn}
          rowSelection={{
            selectedRowKeys: [intensityMetricTemplateId],
            type: 'radio',
            onSelect: onSelectRowRadio
          }}
          columns={columns}
          dataSource={dataSource}
        />
      </Col>
      <Col span={24}>
        <ErrorMessage name="intensityMetricTemplateId">
          {(message) => <Error message={message} />}
        </ErrorMessage>
      </Col>
    </Row>
  );
}
