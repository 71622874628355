import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppLoader } from '../../components';
import { useMeContext } from '../../context';
import { AppRoutes } from '../../routes';
import { AuthService } from '../../services';
import authService from '../../services/authService';
import './verifyEmail.scss';

function VerifyEmail() {
  const navigate = useNavigate();

  const location = useLocation();

  const { setEmail } = useMeContext();

  useEffect(() => {
    localStorage.clear();

    const verificationCode = new URLSearchParams(location.search).get('code');
    AuthService.validateEmail(
      verificationCode,
      (data) => {
        const { isVerified, origin, email } = data;
        setEmail(email);

        if (!isVerified) {
          navigate({
            pathname: AppRoutes.CREATE_ACCOUNT,
            search: `?${new URLSearchParams({
              origin,
              code: verificationCode
            }).toString()}`
          });
        } else {
          authService.addUserToCompany({ verificationCode }, () => {
            navigate({
              pathname: AppRoutes.LOGIN,
              search: `?${new URLSearchParams({
                origin
              }).toString()}`
            });
          });
        }
      },
      () => {},
      () => {}
    );
  }, []);
  return (
    <div className="verify-email">
      <AppLoader loading />
    </div>
  );
}

export default VerifyEmail;
