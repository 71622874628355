export function ChevronLeft() {
  return (
    <path
      d="M15 19L8 12L15 5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default ChevronLeft;
