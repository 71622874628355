function ArrowNarrowUp() {
  return (
    <path
      d="M8 7L12 3M12 3L16 7M12 3V21"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowNarrowUp;
