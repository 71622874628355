function Speakerphone() {
  return (
    <path
      d="M9 3.882V17.24C8.9997 17.6545 8.85314 18.0555 8.58614 18.3725C8.31914 18.6896 7.94883 18.9022 7.54044 18.9729C7.13206 19.0437 6.71181 18.968 6.35372 18.7593C5.99564 18.5506 5.7227 18.2222 5.583 17.832L3.436 11.682M3.436 11.682C2.58722 11.3211 1.88918 10.6791 1.46003 9.86267C1.03087 9.04627 0.896954 8.10668 1.08093 7.2029C1.26491 6.29912 1.75549 5.48665 2.46965 4.903C3.18381 4.31935 4.07768 4.00035 5 4H6.832C10.932 4 14.457 2.766 16 1V15C14.457 13.234 10.933 12 6.832 12H5C4.46263 12.0008 3.93065 11.892 3.436 11.682ZM16 11C16.7956 11 17.5587 10.6839 18.1213 10.1213C18.6839 9.55871 19 8.79565 19 8C19 7.20435 18.6839 6.44129 18.1213 5.87868C17.5587 5.31607 16.7956 5 16 5V11Z"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default Speakerphone;
