import { useRef } from 'react';
import { Row, Col } from 'antd';
import { Title, Paragraph, List, CopyToClipboard } from '../../atoms';
import './landlordLetter.scss';
import { COLOURS_GRAY } from '../../../constants/colours';

function DataRequestTemplate() {
  const landlordLetterRef = useRef();

  return (
    <>
      <Row ref={landlordLetterRef} className="landlord-letter__container">
        <Col span={24}>
          <Title color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Dear [Name]
          </Title>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Hi [Name],
          </Paragraph>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            My company is a tenant at [office address] and we’re looking to
            gather energy-use information to calculate our carbon footprint. In
            order to get an idea of our office footprint we’d like to know and
            confirm the following:
          </Paragraph>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Office Details
          </Paragraph>
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Total Building Square Footage:
          </Paragraph>
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Our Office Square Footage:
          </Paragraph>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Utilities
          </Paragraph>
          <List type="ul" className="mb-0 pb-0">
            <li>Natural Gas</li>
            <li>Electricity</li>
            <li>District Heat (Steam)</li>
            <li>Diesel</li>
            <li>Propane Gas</li>
            <li>Heavy Gas Oils</li>
          </List>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Invoices or Consumption Data
          </Paragraph>
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            For each utility, please provide invoices covering the following
            years:
          </Paragraph>
          <List type="ul" className="mb-0 pb-0">
            <li>[e.g. 2019]</li>
            <li>[e.g. 2020]</li>
            <li>[e.g. 2021]</li>
          </List>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            We’re hoping to complete our carbon footprint by [date], please
            provide the requested utility data by [date].
          </Paragraph>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900} bottomSpacing={0}>
            Thank you,
          </Paragraph>
          <br />
          <Paragraph color={COLOURS_GRAY.GRAY_900}>[Name]</Paragraph>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="end"
        className="landlord-letter-footer__container"
      >
        <Col>
          <CopyToClipboard
            ref={landlordLetterRef}
            copyFormatted
            name="Data Request Template"
          />
        </Col>
      </Row>
    </>
  );
}

export default DataRequestTemplate;
