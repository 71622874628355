import { Col, Row } from 'antd';
import { getMonthName } from '../../../../helpers/generators';
import { Button, Select, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';

export function UserCompanyDetailsReporting({
  showWarningModal,
  startingMonth,
  footprintRange,
  reportingStartYear
}) {
  return (
    <Row gutter={[24, 32]} align="top">
      <Col span={24}>
        <Row justify="space-between">
          <Col span={12}>
            <Title bottomSpacing={0} size="rg">
              Your Reporting Details
            </Title>
          </Col>

          <Col span={12} className="display-flex justify-end">
            <Button type={BUTTON_TYPES.SECONDARY} onClick={showWarningModal}>
              Manage
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Select
              disabled
              name="startingMonth"
              title="Start of Fiscal Year"
              value={getMonthName(startingMonth)}
              className="climate-goals-dropdown"
              placeholder="e.g January"
            />
          </Col>
          <Col span={8}>
            <Select
              disabled
              mode="multiple"
              name="footprintRange"
              title="Baseline year(s)"
              value={footprintRange}
              className="climate-goals-dropdown"
              placeholder="e.g 2019"
            />
          </Col>
          <Col span={8}>
            <Select
              disabled
              name="reportingStartYear"
              title="First reporting year"
              value={reportingStartYear}
              className="climate-goals-dropdown"
              placeholder="e.g 2020"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
