import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import { ProfileImage, Tooltip } from '../../../../../components';
import { COLOURS_CARBONHOUND } from '../../../../../constants/colours';
import NotificationStatus from '../../../../../constants/notificationStatus';
import {
  useCompanyInitiativeLayoutContext,
  useInitiativeContext,
  useMeContext
} from '../../../../../context';
import { capitalizeText, getFullName, analytics } from '../../../../../helpers';
import { useAsync, useModal } from '../../../../../hooks';
import {
  COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS,
  CompanyInitiativeStepsTuple
} from '../../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import { getNextStepUrl } from '../../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.generators';
import emptyCards from '../../../../../stories/assets/svgs/emptyCards.svg';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table,
  Tag
} from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { TAG_COLOURS } from '../../../../../stories/atoms/Tag/tag.types';
import { InitiativeActionDetail } from '../../../InitiativeAction';

export function CompanyInitiativeRelatedActionsTable({ analyticsContext }) {
  const [activeNotificationAction, setActiveNotificationAction] = useState();
  const {
    handleLoadInitiativeRelatedActions,
    initiativeState,
    initiativeRelatedActionsPagination
  } = useInitiativeContext();
  const { companyInitiativeLayoutState, navStep } =
    useCompanyInitiativeLayoutContext();
  const { me } = useMeContext();
  const { currentStep, companyTrailOverviewList } =
    companyInitiativeLayoutState;
  const {
    initiativeRelatedActions,
    initiativeRelatedActionsCount,
    companyInitiative
  } = initiativeState;
  const { initiativeTemplate } = companyInitiative;

  const handleInitiativeRelatedActionsForStep = useCallback(async () => {
    await handleLoadInitiativeRelatedActions({ currentStep });
  }, [currentStep]);

  const { isLoading } = useAsync({
    asyncFunction: handleInitiativeRelatedActionsForStep
  });

  const {
    Modal: InitiativeActionModal,
    handleShowModal: handleShowInitiativeActionModal,
    handleCloseModal: handleCloseInitiativeActionModal
  } = useModal({ width: '80%' });
  const handleShowActionItemForm = (actionItem) => {
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      notificationId: actionItem.id,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative?.id
    });
    setActiveNotificationAction(actionItem);
    handleShowInitiativeActionModal();
  };
  const onSuccess = () => {
    handleLoadInitiativeRelatedActions();
  };

  const columns = [
    {
      title: <ColumnTitle className="text-bd">Name</ColumnTitle>,
      dataIndex: 'title'
    },
    {
      title: <ColumnTitle>Assignee</ColumnTitle>,
      dataIndex: 'owner',
      width: '32px',
      onCell: () => ({
        align: 'center'
      }),
      render: (owner, actionItem) =>
        !!owner ? (
          <Tooltip title={getFullName(owner)}>
            <ProfileImage size={32} name={getFullName(owner)} />
          </Tooltip>
        ) : (
          <ButtonIcon
            name="Plus"
            backgroundColour={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            onClick={() => {
              handleShowActionItemForm(actionItem);
            }}
          />
        )
    },
    {
      title: <ColumnTitle>Due Date</ColumnTitle>,
      dataIndex: 'dueDate',
      width: '200px',
      render: (dueDate, actionItem) =>
        dueDate ? (
          formatInTimeZone(new Date(dueDate), 'UTC', 'MMM d, yyyy')
        ) : (
          <ButtonIcon
            name="Plus"
            onClick={() => {
              handleShowActionItemForm(actionItem);
            }}
          />
        )
    },
    {
      title: <ColumnTitle>Status</ColumnTitle>,
      dataIndex: 'status',
      width: '120px',
      render: (status) => (
        <Tag
          color={
            status === NotificationStatus.COMPLETE
              ? TAG_COLOURS.GREEN
              : TAG_COLOURS.YELLOW
          }
        >
          {capitalizeText(status)}
        </Tag>
      )
    }
  ];

  return (
    <>
      <Table
        onRow={(actionItem) => ({
          className: 'cursor-pointer',
          onClick: () => {
            handleShowActionItemForm(actionItem);
          }
        })}
        isLoading={isLoading}
        isHeaderBorder
        dataSource={initiativeRelatedActions}
        pagination={initiativeRelatedActionsPagination}
        showPagination
        columns={columns}
        total={initiativeRelatedActionsCount}
        locale={{
          emptyText: (
            <Empty
              imageSrc={emptyCards}
              title="It’s quiet in here..."
              description="Once you get started on this initiative and start creating actions you can track progress here."
              buttonProps={{
                type: BUTTON_TYPES.LINK,
                children: !!companyTrailOverviewList?.length
                  ? 'Continue'
                  : 'Get Started',
                onClick: () => {
                  let nextStepUrl =
                    COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS[
                      CompanyInitiativeStepsTuple[1]
                    ];
                  if (!!companyTrailOverviewList?.length) {
                    nextStepUrl = getNextStepUrl(companyTrailOverviewList);
                  }
                  navStep(
                    nextStepUrl.replace(
                      ':companyInitiativeId',
                      companyInitiative?.id
                    )
                  );
                }
              }}
            />
          )
        }}
      />
      <InitiativeActionModal
        showHeader={false}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <InitiativeActionDetail
          onClose={handleCloseInitiativeActionModal}
          initiativeAction={activeNotificationAction}
          onSuccess={onSuccess}
          analyticsContext={analyticsContext}
          companyInitiativeId={initiativeState?.companyInitiativeId}
        />
      </InitiativeActionModal>
    </>
  );
}
