export const createLocationOptionList = (array) =>
  array.reduce((acc, curr) => {
    curr.locations?.forEach((location) => {
      const existingOption = acc.findIndex((a) => a.value === location);

      if (existingOption === -1) {
        acc.push({
          label: location,
          value: location
        });
      }
    });
    return acc;
  }, []);

export const createLocationList = (array, locationFilters) =>
  array.filter((partner) => {
    if (locationFilters.length) {
      return (
        partner.locations?.findIndex(
          (location) => locationFilters.indexOf(location) > -1
        ) > -1
      );
    }
    return partner;
  });
