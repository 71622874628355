import { useState } from 'react';

const usePagination = ({
  paginationArray,
  orderBy,
  order = 'DESC',
  orderNulls, // Sort null values to the start (FIRST) or end (LAST) of the list
  previousValueKeys,
  pageSize = 10,
  search
}) => {
  const initialPaginationState = {
    pageSize,
    pageNumber: 1,
    prevPageNumber: 1,
    order,
    orderBy,
    orderNulls,
    search
  };
  const [paginationParams, setPaginationParams] = useState(
    initialPaginationState
  );

  const handleSetPaginationParams = (params) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const handleOnPaginationChange = (pageNumber, prevPageNumber) => {
    const reverse = prevPageNumber > pageNumber;
    const paginationNotifier = reverse
      ? paginationArray[0]
      : paginationArray[paginationArray.length - 1];

    const previousValue = paginationParams?.orderBy?.reduce((acc, curr, i) => {
      if (previousValueKeys) {
        //  In the case where the orderby key is different from the value being passed to the BE.
        //  we can pass in alternate corresponding keys.
        //  If we need to access a nested value in the object, this split/reduce logic will get the
        //  correct value
        //  ex: {user: {id: 123}} would use a previousValue key of `user.id`, and return a value of 123
        acc[curr] = previousValueKeys[i]
          .split('.')
          .reduce((o, i) => o[i], paginationNotifier);
      } else {
        acc[curr] = paginationNotifier[curr];
      }
      return acc;
    }, {});

    setPaginationParams({
      ...paginationParams,
      pageNumber,
      prevPageNumber,
      previousValue,
      reverse
    });
  };

  return {
    paginationParams: {
      ...paginationParams,
      onChange: handleOnPaginationChange
    },
    handleSetPaginationParams
  };
};

export default usePagination;
