import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Modal } from '../../../components';
import { useMeContext } from '../../../context';
import ChangePasswordForm from '../../../pages/UserProfile/ChangePasswordForm';
import { Button, Input } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

export function UserProfileDetails() {
  const { me } = useMeContext();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const handleToggleChangePassword = () =>
    setShowChangePasswordForm(!showChangePasswordForm);

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Formik enableReinitialize initialValues={me}>
          {({ _values, _errors, _isValid, _dirty, _setFieldValue }) => (
            <Form className="user-profile__profile-form">
              <Row gutter={[24, 32]}>
                <Col span={12}>
                  <Input
                    disabled
                    title="First name"
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                    required
                  />
                </Col>
                <Col span={12}>
                  <Input
                    disabled
                    title="Last name"
                    type="text"
                    name="lastName"
                    placeholder="Enter Last name"
                  />
                </Col>
                <Col span={12}>
                  <Input
                    disabled
                    title="Email Address"
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    required
                  />
                </Col>
                <Col span={12}>
                  <Input
                    disabled
                    title="Role"
                    name="company.role.name"
                    required
                  />
                </Col>
                <Col span={24}>
                  <div className="user-profile__change-password-wrapper">
                    <Button
                      type={BUTTON_TYPES.SECONDARY}
                      onClick={handleToggleChangePassword}
                    >
                      Change Password
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
      <Modal
        destroyOnClose
        width="50%"
        visible={showChangePasswordForm}
        onCancel={handleToggleChangePassword}
      >
        <ChangePasswordForm onSuccess={handleToggleChangePassword} />
      </Modal>
    </Row>
  );
}
