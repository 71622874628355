import { Col, Input, Modal as AntModal, Row, Space } from 'antd';
import { useCallback, useState } from 'react';
import { useMeContext } from '../../context';
import { analytics } from '../../helpers';
import { useAsync, useModal, usePagination } from '../../hooks';
import { CommuteService } from '../../services';
import { Button, Title } from '../../stories/atoms';
import BulkUploadForm from '../../features/BulkUploadForm';
import { Notification, Table } from '../index';
import './inviteList.scss';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { BulkUploadType } from '../../constants';

function InviteList() {
  const { me } = useMeContext();

  const [selectedNewEmployeeIds, setSelectedNewEmployeeIds] = useState([]);

  const [invites, setInvites] = useState([]);

  const [newInvites, setNewInvites] = useState([]);

  const { paginationParams } = usePagination({
    paginationArray: invites,
    orderBy: ['userId'],
    previousValueKeys: ['user.id']
  });

  const {
    pageSize,
    pageNumber,
    prevPageNumber,
    orderBy,
    previousValue,
    reverse
  } = paginationParams;

  const {
    Modal: BUModal,
    handleShowModal: handleShowBUModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Upload data clicked', me, {
        level1: 'Office-Based Operations',
        level2: 'Employee Details',
        level3: 'Add and Invite Employees'
      });
    },
    width: '90%'
  });

  const handleCancelInvite = (userId, deactivate, onSuccess) => {
    CommuteService.cancelInvite(
      me.company.slug,
      {
        userId,
        deactivate
      },
      () => {
        onSuccess && onSuccess();
      },
      () => {},
      () => {}
    );
  };

  const handleChange =
    (index) =>
    ({ target: { name, value } }) => {
      newInvites[index] = {
        ...newInvites[index],
        user: {
          [name]: value
        }
      };
      setNewInvites([...newInvites]);
    };

  const handleAddEmployee = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Add and Invite Employees'
    });
    setNewInvites([...newInvites, ...[{ isEdit: true, user: {} }]]);
  };

  const handleSubmit = (employee, index) => () => {
    CommuteService.createInvite(
      me.company.slug,
      {
        email: employee?.user?.email
      },
      (userId) => {
        analytics.track('Save item clicked', me, {
          userId: employee?.user?.id,
          level1: 'Office-Based Operations',
          level2: 'Employee Details',
          level3: 'Add and Invite Employees'
        });
        Notification({
          type: 'success',
          message: 'User Invite',
          description: 'Your invite has been queued.'
        });
        newInvites[index] = {
          ...newInvites[index],
          user: {
            ...newInvites[index].user,
            id: userId
          },
          isEdit: false
        };
        selectedNewEmployeeIds.push(userId);
        setSelectedNewEmployeeIds([...selectedNewEmployeeIds]);
        setNewInvites([...newInvites]);
      },
      () => {},
      () => {}
    );
  };

  const handleInviteDelete = (employee) => () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        handleCancelInvite(employee?.user?.id, true, () => {
          CommuteService.fetchEmployees(
            {
              companySlug: me?.company?.slug,
              isInvited: false,
              pagination: {
                pageSize,
                pageNumber,
                prevPageNumber,
                orderBy,
                previousValue,
                reverse
              }
            },
            ({ list: employees }) => {
              const newEmployees = employees.filter(
                (employee) => employee.companyStatus === 'PENDING'
              );
              setNewInvites(newEmployees);
              setSelectedNewEmployeeIds(
                newEmployees.map((employee) => employee.user.id)
              );
            },
            () => {},
            () => {}
          );
        });
      }
    });
  };

  const isEmailValid = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const newUsersColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email, employee, i) =>
        employee.isEdit ? (
          <Input
            name="email"
            onChange={handleChange(i)}
            value={employee?.user?.email}
          />
        ) : (
          employee?.user?.email
        )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id, employee, index) => (
        <div className="invite-list__new-users-actions text-center">
          {employee?.isEdit ? (
            <Button
              primary
              small
              onClick={handleSubmit(employee, index)}
              disabled={!isEmailValid(employee?.user?.email)}
            >
              Save
            </Button>
          ) : (
            <Button onClick={handleInviteDelete(employee)}>
              <i className="icon-delete" />
            </Button>
          )}
        </div>
      )
    }
  ];

  const handleFetchEmployees = useCallback(async () => {
    CommuteService.fetchEmployees(
      {
        companySlug: me?.company?.slug,
        isInvited: false,
        pagination: {
          pageSize,
          pageNumber,
          prevPageNumber,
          orderBy,
          previousValue,
          reverse
        }
      },
      ({ list: employees }) => {
        const currentEmployees = employees.filter(
          (employee) => employee.companyStatus !== 'PENDING'
        );
        const newEmployees = employees.filter(
          (employee) => employee.companyStatus === 'PENDING'
        );
        setInvites(currentEmployees);
        setNewInvites(newEmployees);
      },
      () => {},
      () => {}
    );
  }, [
    me?.company?.slug,
    pageSize,
    pageNumber,
    prevPageNumber,
    orderBy,
    previousValue,
    reverse
  ]);

  useAsync({
    asyncFunction: handleFetchEmployees
  });

  return (
    <div className="invite-list">
      <Row>
        <Col span={24} className="display-flex">
          <Row className="full-width">
            <Col
              span={24}
              className="display-flex align-center justify-space-between"
            >
              <Title bottomSpacing={0}>New Users</Title>
              <Space size={16}>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  onClick={handleShowBUModal}
                >
                  Import Users
                </Button>
                <Button onClick={handleAddEmployee}>Add User</Button>
              </Space>
            </Col>
            <Col span={24}>
              <Table
                showPagination
                paginationParams={paginationParams}
                rowKey={(invite) => invite.user.id}
                columns={newUsersColumns}
                dataSource={newInvites}
                className="invite-list__new-users-table no-shadow"
              />
            </Col>
            <BUModal title="Upload your file" className="bulk-upload-modal">
              <BulkUploadForm
                notificationReqParams={{
                  type: BulkUploadType.USER
                }}
                notification={{
                  details: {
                    uploadType: BulkUploadType.USER
                  }
                }}
                handleCloseBulkUploadsModal={handleCloseBUModal}
              />
            </BUModal>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default InviteList;
