import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  DropdownField,
  ProfileImage,
  Table,
  Tooltip
} from '../../../../../components';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS,
  OfficeTrail,
  OfficeTrailSegment,
  TransportationType,
  UserStatus
} from '../../../../../constants';
import {
  useLayoutContext,
  useMeContext,
  useOfficeTrailContext
} from '../../../../../context';
import {
  analytics,
  capitalizeText,
  formatDecimal,
  getFullName,
  getYearDropdownOptions
} from '../../../../../helpers';
import { getTimeFormat } from '../../../../../helpers/generators';
import { usePagination } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import {
  CompanyService,
  CompanyTrailService,
  EmployeeService,
  TravelExpenseService
} from '../../../../../services';
import { Button, StackedBarChart, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../../../stories/atoms/Layout';
import { TransportationTypeColours } from '../../../../../stories/atoms/StackedBarChart/StackedBarChart.types';
import { TravelItemFormButton } from '../../../../../stories/molecules';
import { DataImportButton } from '../../../../../stories/molecules/DataImportButton';
import './travelExpenseOverview.scss';

function TravelExpenseOverview() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isEmployeeRoute = pathname.includes(AppRoutes.ONBOARDING_EMPLOYEE);

  const [emissionSummary, setEmissionSummary] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [travelItems, setTravelItems] = useState([]);

  const [employeeOptions, setEmployeeOptions] = useState([]);

  const [activeTravelItem, setActiveTravelItem] = useState();

  const { navStep } = useLayoutContext();

  const { paginationParams } = usePagination({
    paginationArray: travelItems,
    orderBy: ['date', 'id']
  });
  const {
    pageSize,
    pageNumber,
    prevPageNumber,
    orderBy,
    previousValue,
    reverse
  } = paginationParams;

  const { me, selectCarbonYearRange, isAdmin } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInAsUserId } = loggedInAs ?? {};

  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );
  const { refreshOfficeTrails, refreshEmployeeOnboardingTrails } =
    useOfficeTrailContext();

  const chartData = {
    labels: emissionSummary.map((summary) => summary.month),
    datasets: [
      {
        label: 'Flights',
        barPercentage: 0.25,
        data: emissionSummary.map(
          (summary) => summary[TransportationType.PLANE] / 1000
        ),
        backgroundColor: '#5C29C7',
        borderRadius: 50
      },
      {
        label: 'Motorbike',
        barPercentage: 0.25,
        data: emissionSummary.map(
          (summary) => summary[TransportationType.MOTORBIKE] / 1000
        ),
        backgroundColor: '#ED2861',
        borderRadius: 50
      },
      {
        label: 'Car',
        barPercentage: 0.25,
        data: emissionSummary.map(
          (summary) => summary[TransportationType.CAR] / 1000
        ),
        backgroundColor: '#FBAB1D',
        borderRadius: 50
      },
      {
        label: 'Intercity Train',
        barPercentage: 0.25,
        data: emissionSummary.map(
          (summary) => summary[TransportationType.INTERCITY_TRAIN] / 1000
        ),
        backgroundColor: '#17D7DA',
        borderRadius: 50
      },
      {
        label: 'Local Bus',
        barPercentage: 0.25,
        data: emissionSummary.map(
          (summary) => summary[TransportationType.LOCAL_BUS] / 1000
        ),
        backgroundColor: '#74A57F',
        borderRadius: 50
      }
    ]
  };

  const handleEditTravelExpense = (travelItem) => {
    analytics.track('Edit Item Clicked', me, {
      level1: 'Office-Based Operations',
      level2: 'Travel Expenses',
      level3: 'Overview',
      travelItem
    });
    setActiveTravelItem(travelItem);
  };

  const handleDeleteTravelExpense = (travelItem) => () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        TravelExpenseService.deleteTravelExpense(
          travelItem,
          () => {
            analytics.track('Delete item clicked', me, {
              meId: me.id,
              companyId: me.company.id,
              level1: 'Office-Based Operations',
              level2: 'Travel Expenses',
              level3: 'Overview',
              travelItem
            });
            setTravelItems(
              travelItems.filter((item) => item.id !== travelItem.id)
            );
          },
          () => {},
          () => {}
        );
      }
    });
  };

  const handleAddTravelItem = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Travel Expenses',
      level3: 'Overview'
    });
  };

  const handleNext = () => {
    CompanyTrailService.createCompanyTrail(
      me.company.slug,
      {
        trailName: isEmployeeRoute
          ? OfficeTrail.EMPLOYEE_ONBOARDING
          : OfficeTrail.OFFICE_ONBOARDING,
        segmentName: OfficeTrailSegment.BUSINESS_TRAVEL,
        isComplete: true,
        ...(isEmployeeRoute && { userId: loggedInAsUserId || me.id })
      },
      () => {
        analytics.track('Segment Completed', me, {
          level1: 'Office-Based Operations',
          level2: 'Travel Expenses',
          level3: 'Overview'
        });
        isEmployeeRoute
          ? refreshEmployeeOnboardingTrails()
          : refreshOfficeTrails();

        if (isEmployeeRoute) {
          navStep(AppRoutes.ONBOARDING_EMPLOYEE_YOUR_IMPACT);
        } else {
          navigate(AppRoutes.ONBOARDING_ADMIN_SOURCES);
        }
      },
      () => {},
      () => {}
    );
  };

  const handlePrevious = () => {
    if (isEmployeeRoute) {
      navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
    } else {
      navigate(AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES);
    }
  };

  const handleFetchTravelExpenses = useCallback(() => {
    TravelExpenseService.fetchTravelExpenses(
      {
        companySlug: me?.company?.slug,
        userCompanyIds: selectedUserIds,
        pagination: {
          pageSize,
          pageNumber,
          prevPageNumber,
          orderBy,
          previousValue,
          reverse
        }
      },
      ({ list: ti, count: trc }) => {
        setTravelItems(ti);
        setTotalRowsCount(trc);
      },
      () => {},
      () => {}
    );
  }, [
    me?.company?.slug,
    orderBy,
    pageNumber,
    pageSize,
    prevPageNumber,
    previousValue,
    reverse,
    selectedUserIds
  ]);

  const handleFormSuccess = () => {
    handleFetchTravelExpenses();
  };
  const handleFetchEmployees = useCallback(() => {
    EmployeeService.fetchEmployees(
      { companySlug: me?.company?.slug, status: UserStatus.ACTIVE },
      (employees) => {
        setEmployeeOptions(
          employees.map((employee) => ({
            label: employee?.user?.getFullName(),
            value: employee?.id
          }))
        );
      },
      () => {},
      () => {}
    );
  }, [me?.company?.slug]);

  const handleFetchTravelExpenseSummary = useCallback(() => {
    const { rangeStart, rangeEnd } = selectCarbonYearRange({
      activeYear: selectedYear
    });
    TravelExpenseService.fetchTravelExpenseSummary(
      {
        companySlug: me?.company?.slug,
        rangeStart,
        rangeEnd,
        userIds: selectedUserIds
      },
      (es) => {
        setEmissionSummary(es);
      },
      () => {},
      () => {}
    );
  }, [me?.company?.slug, selectedUserIds, selectedYear]);

  const handleShowCompanyDetails = useCallback(() => {
    CompanyService.showCompany(
      me.company.slug,
      ({ footprintRange }) => {
        setSelectedYear(
          footprintRange?.length > 0
            ? footprintRange[0]
            : new Date().getUTCFullYear()
        );
      },
      () => {},
      () => {}
    );
  }, [me.company.slug]);

  const analyticsContext = {
    level1: 'Office-Based Operations',
    level2: 'Travel Expenses',
    level3: 'Overview',
    meId: me.id,
    companyId: me.company.id,
    travelDetailsId: activeTravelItem?.travelDetailsId,
    travelCategoryId: activeTravelItem?.travelCategory?.id,
    conversionUnitName: activeTravelItem?.activityUnit?.name,
    destinationLocationId: activeTravelItem?.destinationLocation?.id,
    departureLocationId: activeTravelItem?.departureLocation?.id,
    emissionCalculationModel: activeTravelItem?.emissionCalculationModel
  };
  const columns = [
    {
      title: <div className="text-center">Owner</div>,
      dataIndex: 'id',
      key: 'id',
      render: (_, travelItem) => (
        <div className="text-center">
          <Tooltip title={getFullName(travelItem?.user)}>
            <ProfileImage size="small" name={getFullName(travelItem?.user)} />
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => (
        <span>
          {date
            ? formatInTimeZone(new Date(date), 'UTC', dateDisplayFormat)
            : '-'}
        </span>
      )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category, travelItem) => (
        <span> {capitalizeText(travelItem?.travelCategory?.name)}</span>
      )
    },
    {
      title: 'Impact (kg Co2e)',
      dataIndex: 'impact',
      key: 'impact',
      render: (impact, travelItem) => (
        <span>
          {' '}
          {travelItem?.emissionTotal
            ? formatDecimal(travelItem?.emissionTotal)
            : '-'}
        </span>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (id, travelItem) => (
        <div className="travel-expense-overview_actions">
          <Space>
            <TravelItemFormButton
              travelItem={travelItem}
              onOpen={handleEditTravelExpense}
              onSuccess={handleFormSuccess}
              analyticsContext={analyticsContext}
              buttonProps={{
                children:
                  travelItem?.calculationStatus === 'SUCCESS'
                    ? 'Edit'
                    : 'Update',
                type:
                  travelItem?.calculationStatus === 'SUCCESS'
                    ? BUTTON_TYPES.SECONDARY
                    : BUTTON_TYPES.PRIMARY
              }}
            />
            <i
              className="icon-delete ml-3"
              onClick={handleDeleteTravelExpense(travelItem)}
            />
          </Space>
        </div>
      )
    }
  ];

  useEffect(() => {
    refreshOfficeTrails();
    handleFetchTravelExpenses();
    handleFetchEmployees();
  }, [handleFetchEmployees, handleFetchTravelExpenses]);

  useEffect(() => {
    handleShowCompanyDetails();
  }, [handleShowCompanyDetails]);

  useEffect(() => {
    if (selectedYear) {
      handleFetchTravelExpenseSummary();
    }
  }, [selectedYear, handleFetchTravelExpenseSummary]);

  useEffect(() => {
    handleFetchTravelExpenses();
  }, [handleFetchTravelExpenses]);
  const dataImportSettings = {
    onSuccess: handleFormSuccess,
    internalSpec: EMISSION_SOURCE_INTERNAL_SPECS.TRAVEL,
    notification: {
      details: {
        uploadType: BulkUploadType.TRAVEL
      }
    }
  };
  return (
    <div className="travel-expense-overview">
      <Row align="middle">
        <Col span={12}>
          <Title bottomSpacing={56} size="xl">
            Business Travel Impact
          </Title>
        </Col>
      </Row>
      <Card className="travel-expense-overview__card">
        <div className="travel-expense-overview__pending-entries">
          <Row>
            <Col span={10} className="travel-expense-overview__filters">
              <DropdownField
                title="Filter by users"
                disableForm
                mode="multiple"
                options={employeeOptions}
                showSearch
                placeholder="Search"
                onChange={(userIds) => setSelectedUserIds(userIds)}
              />
            </Col>
            <Col offset={10} span={4}>
              <div className="travel-expense-overview__date-range-wrapper">
                <div className="text-rg text">Date Range</div>
                <DropdownField
                  className="travel-expense-overview__select-year"
                  value={selectedYear}
                  disableForm
                  options={getYearDropdownOptions()}
                  onChange={(year) => setSelectedYear(year)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <StackedBarChart
          colorSchema={TransportationTypeColours}
          data={chartData}
          yAxisText="Tonnes CO2 Equivalent"
        />
        <div className="travel-expense-overview__items">
          <div className="justify-end display-flex mt-4 mb-4">
            <Space>
              {isAdmin && <DataImportButton {...dataImportSettings} />}
              <TravelItemFormButton
                buttonProps={{
                  type: BUTTON_TYPES.PRIMARY,
                  prefixIcon: 'Plus',
                  children: 'Add new'
                }}
                onOpen={handleAddTravelItem}
                onSuccess={handleFormSuccess}
                analyticsContext={analyticsContext}
              />
            </Space>
          </div>
          <div className="mt-4 travel-expense-overview__table">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={travelItems}
              showPagination
              total={totalRowsCount}
              paginationParams={paginationParams}
              locale={{
                emptyText: (
                  <div className="travel-expense-overview__empty-text">
                    <div> No data yet</div>
                    <div className="mt-2 justify-center display-flex">
                      <TravelItemFormButton
                        buttonProps={{
                          type: BUTTON_TYPES.PRIMARY,
                          prefixIcon: 'Plus',
                          children: 'Add new'
                        }}
                        onOpen={handleAddTravelItem}
                        onSuccess={handleFormSuccess}
                        analyticsContext={analyticsContext}
                      />
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </div>
      </Card>
      {isEmployeeRoute ? (
        <Footer className="justify-end display-flex">
          <Space>
            <Button type={BUTTON_TYPES.SECONDARY} onClick={handlePrevious}>
              Previous
            </Button>

            <Button onClick={handleNext}>Next</Button>
          </Space>
        </Footer>
      ) : (
        <Row className="mt-5 mb-5">
          <Col span={12}>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              prefixIcon="ArrowLeft"
              onClick={handlePrevious}
            >
              Previous
            </Button>
          </Col>
          <Col span={12} className="justify-end display-flex">
            <Button onClick={handleNext} icon="ArrowRight">
              Next
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default TravelExpenseOverview;
