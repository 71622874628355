import { useEffect } from 'react';
import { useMetaContext } from '../../../context';
import { Select } from '../../atoms';

export function TransportTypeSelect({ ...props }) {
  const { meta, setTravelCategories } = useMetaContext();
  const { travelCategories } = meta;

  const transportationTypeList = travelCategories.map((cat) => ({
    ...cat,
    label: cat.friendlyName,
    value: cat.id
  }));
  useEffect(() => {
    setTravelCategories();
  }, []);
  return <Select options={transportationTypeList} {...props} />;
}
