import { Col, Row } from 'antd';
import './PanelHeader.scss';

function PanelHeader({ details, title, nestingLevel, ...props }) {
  return (
    <Row
      align="middle"
      className="nested-collapse__panel__header"
      style={{
        marginLeft: 32 * nestingLevel
      }}
      gutter={[16, 16]}
      {...props}
    >
      <Col flex="0 1 1" className="display-flex">
        {title}
      </Col>
      <Col flex="1 1 0" className="display-flex">
        {details}
      </Col>
    </Row>
  );
}

export default PanelHeader;
