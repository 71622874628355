function ArrowLeft() {
  return (
    <path
      d="M10 19L3 12M3 12L10 5M3 12H21"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowLeft;
