import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import produce from 'immer';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { COLOURS_GRAY } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import {
  formatSingleEmission,
  getDefaultYearForChart,
  getYearDropdownOptions
} from '../../../../helpers/generators';
import { useAsync } from '../../../../hooks';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { Select, StackedBarChart, Title } from '../../../../stories/atoms';
import { CategoryColours } from '../../../../stories/atoms/StackedBarChart/StackedBarChart.types';

function EmissionSourceLocationDetailSummaryChart({ analyticsContext }) {
  const { me, selectCarbonYearRange } = useMeContext();
  const { collectionId } = useParams();

  const [selectedDateRange, setSelectedDateRange] = useState(
    selectCarbonYearRange({
      activeYear: getDefaultYearForChart({
        startingMonth: me.company?.startingMonth
      })
    })
  );

  const currentCarbonYear = selectCarbonYearRange({
    activeYear: getDefaultYearForChart({
      startingMonth: me.company?.startingMonth
    })
  });

  const [chartDateYear, setChartDateYear] = useState(
    new Date(selectedDateRange.rangeStart).getUTCFullYear()
  );

  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];

  const handleFetchEmissionSourceLocationDetailSummaryData =
    useCallback(async () => {
      const data = await EmissionSourceService.getEmissionSourceLocationDetails(
        {
          companySlug: me.company.slug,
          collectionId,
          rangeStart: selectedDateRange.rangeStart,
          rangeEnd: selectedDateRange.rangeEnd,
          aggregateType: 'CATEGORY'
        }
      );
      return data;
    }, [
      me.company.slug,
      collectionId,
      selectedDateRange.rangeStart,
      selectedDateRange.rangeEnd
    ]);

  const { isLoading, value: chartData } = useAsync({
    asyncFunction: handleFetchEmissionSourceLocationDetailSummaryData
  });

  const labels = chartData?.emissionSummary?.map((summary) =>
    formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
  );

  const outputDataSets = () => {
    const dataMap = {};
    if (!chartData?.emissionSummary?.length) {
      return [];
    }
    const { ...legendLabels } = chartData?.emissionSummary?.[0];
    chartData?.emissionSummary?.forEach((summary) => {
      Object.keys(legendLabels).forEach((legendLabel) => {
        if (!dataMap[legendLabel]) {
          dataMap[legendLabel] = {
            label: legendLabel,
            backgroundColor: CategoryColours[legendLabel],
            data: []
          };
        }
        dataMap[legendLabel].data.push(
          formatSingleEmission(summary[legendLabel])
        );
      });
    });
    return Object.values(dataMap);
  };

  const data = {
    labels,
    datasets: outputDataSets()
  };

  const handleChangeYear = (value) => {
    analytics.track('Chart Filter Selected', me, {
      ...analyticsContext
    });
    setChartDateYear(value);
    let rangeStart;
    let rangeEnd;

    if (value === -1) {
      rangeStart = 2006;
      ({ rangeEnd } = currentCarbonYear);
    } else {
      ({ rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: value
      }));
    }
    setSelectedDateRange((prev) =>
      produce(prev, (draft) => {
        draft.rangeStart = rangeStart;
        draft.rangeEnd = rangeEnd;
      })
    );
  };

  return (
    <>
      <Row gutter={16} className="mb-6 align-center justify-space-between">
        <Col span={15}>
          <Title color={COLOURS_GRAY.GRAY_500} size="md">
            Overview
          </Title>
        </Col>
        <Col span={3}>
          <Select
            value={chartDateYear}
            disableForm
            placeholder="Select Year"
            options={footprintRangeDropdownOptions}
            onChange={handleChangeYear}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StackedBarChart
            colorSchema={CategoryColours}
            stacked
            isLoading={isLoading}
            labels={chartData?.emissionSummary?.map((summary) =>
              summary.date
                ? formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
                : ''
            )}
            data={data}
          />
        </Col>
      </Row>
    </>
  );
}

export default EmissionSourceLocationDetailSummaryChart;
