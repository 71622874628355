import { Avatar } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { COLOURS_INDIGO } from '../../../constants/colours';
import './profileImage.scss';

function ProfileImage({
  src,
  _size,
  name,
  alt = 'Profile Picture',
  className,
  backgroundColor = COLOURS_INDIGO.INDIGO_100,
  color = COLOURS_INDIGO.INDIGO_500,
  ...props
}) {
  const [imageText, setImageText] = useState();

  useEffect(() => {
    if (name) {
      setImageText(name.split(' ').map((name) => name[0]));
    }
  }, [name]);

  return (
    <Avatar
      className={classNames('ch-profile-image', {
        [className]: !!className
      })}
      style={{
        backgroundColor,
        color
      }}
      src={src}
      alt={alt}
      {...props}
    >
      <span className="text-bd">{imageText}</span>
    </Avatar>
  );
}

export default ProfileImage;
