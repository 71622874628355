import { Space } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import targetChart from '../../../assets/images/target-chart.svg';
import { Card } from '../../../components';
import { OfficeTrailSegment, StepStatus } from '../../../constants';
import { useMeContext, useOfficeTrailContext } from '../../../context';
import { analytics } from '../../../helpers';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../stories/atoms';
import './TargetSettingEmptyState.scss';

export function TargetSettingEmptyState({ isReadOnly }) {
  const { officeTrailDetails } = useOfficeTrailContext();
  const { me } = useMeContext();
  const navigate = useNavigate();
  const { completedSteps, officeTrailStatus } = officeTrailDetails;
  const requiredSteps = Object.keys(officeTrailStatus).filter((key) => {
    if (
      key !== OfficeTrailSegment.COMMUTE &&
      officeTrailStatus[key] === StepStatus.COMPLETE
    )
      return key;
  }).length;
  const onClickGetStarted = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Set Targets',
      level3: 'Overview',
      level4: 'Baseline Year'
    });
    navigate(
      `${AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_BASELINE.replace(
        ':targetDetailsId',
        'new'
      )}`
    );
  };
  const onClickViewTrails = () => {
    navigate(AppRoutes.TRAILS_OVERVIEW);
  };

  const canCreateTargets = completedSteps >= requiredSteps;
  const isButtonDisabled = !isReadOnly && !canCreateTargets;
  const title = canCreateTargets ? 'Set your targets' : 'Targets locked ';
  const byline = canCreateTargets
    ? 'You are about to em(bark!) on a journey towards a healthier planet. We are so excited to be a part of your path towards a greener future.'
    : 'You need to complete the previous steps of your trail in order to get started with your targets.';
  const buttonCopy = isReadOnly ? 'View Trails' : 'Get Started';
  const buttonFn = isReadOnly ? onClickViewTrails : onClickGetStarted;
  return (
    <div className="target-setting-empty-state">
      <Card className="target-setting-empty-state__container">
        <Space align="center" direction="vertical">
          <div
            className={classNames('target-setting-empty-state__icon', {
              locked: !canCreateTargets
            })}
          >
            {!canCreateTargets && (
              <span className="target-setting-empty-state__icon--locked-indicator" />
            )}
            <img
              src={targetChart}
              alt="An icon of a green bar chart going up in value"
            />
          </div>

          <h3>{title}</h3>
          <p className="text-center">{byline}</p>
          <Button onClick={buttonFn} disabled={isButtonDisabled}>
            {buttonCopy}
          </Button>
        </Space>
      </Card>
    </div>
  );
}
