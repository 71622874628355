import { Card, Col, Row } from 'antd';
import { useEffect } from 'react';
import { useDataContext } from '../../../context/DataContext';
import { useAsync } from '../../../hooks';
import { Anchor, Title } from '../../../stories/atoms';
import { DataChart } from '../DataChart';
import { DataChartFilters } from '../DataChart/DataChartFilters';
import { DataDrilldown } from '../DataDrilldown';

export function DataOverview() {
  const {
    handleFetchDataExplorerData,
    handleLoadDrilldownData,
    data,
    updateData
  } = useDataContext();
  const { isDataFiltersInit } = data;
  const {
    value: chartData,
    isLoadingChart,
    execute: loadChartData
  } = useAsync({
    asyncFunction: handleFetchDataExplorerData,
    defaultValue: {
      datasets: [],
      labels: []
    },
    immediate: false
  });

  const {
    isLoading: isLoadingDrilldown,
    value: drilldownData,
    execute: loadDrilldownData
  } = useAsync({
    asyncFunction: handleLoadDrilldownData,
    immediate: false
  });

  const handleLoadExplorer = () => {
    loadChartData();
    loadDrilldownData();
  };

  const showChartLoadingState = isLoadingDrilldown || isLoadingChart;

  useEffect(() => {
    if (isDataFiltersInit) {
      handleLoadExplorer();
      updateData({ isDataFiltersInit: false });
    }
  }, [isDataFiltersInit]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Card>
          <Title size="rg">Data Filters</Title>
          <DataChartFilters />
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <Row>
            <Col flex="1">
              <Title size="rg">Emissions Overview</Title>
            </Col>
            <Col>
              <Anchor
                fontWeight={500}
                href="//carbonhound.notion.site/How-to-get-the-most-out-of-your-Carbon-Explorer-98b6f571c9d449298ae9aa2ca05cdaf7?pvs=4
                "
              >
                Help
              </Anchor>
            </Col>
          </Row>
          <DataChart chartData={chartData} isLoading={showChartLoadingState} />
          {!isLoadingDrilldown && (
            <DataDrilldown
              isLoading={showChartLoadingState}
              drilldownData={drilldownData}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}
