import { Col, Row } from 'antd';
import { COLOURS_CARBONHOUND } from '../../../../../constants/colours';
import { AppRoutes } from '../../../../../routes';
import { Icon, Link, Title } from '../../../../../stories/atoms';
import { OnboardingEmployeeLayoutSidebarMenu } from './OnboardingEmployeeLayoutSidebarMenu';

export function OnboardingEmployeeLayoutSidebar() {
  return (
    <Row gutter={[0, 36]}>
      <Col span={24}>
        <Row gutter={[0, 28]}>
          <Col span={24}>
            <Link flex fontSize={16} fontWeight={600} to={AppRoutes.DASHBOARD}>
              <Icon
                size={16}
                color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                name="ChevronLeft"
                className="mb-2 mr-2"
              />
              Save & Exit
            </Link>
          </Col>
          <Col span={24}>
            <Title size="xl" bottomSpacing={0}>
              Employee Onboarding
            </Title>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <OnboardingEmployeeLayoutSidebarMenu />
      </Col>
    </Row>
  );
}
