import * as Yup from 'yup';
import {
  DISTANCE_CALCULATION_TYPE,
  TransportationType
} from '../../../constants';

const drivingVehicles = [TransportationType.CAR, TransportationType.MOTORBIKE];

const roundTripVehicles = [
  TransportationType.LOCAL_BUS,
  TransportationType.INTERCITY_TRAIN,
  TransportationType.PLANE
];
export const travelItemValidation = Yup.object().shape({
  userId: Yup.string().nullable().required('Owner'),
  date: Yup.string().nullable().required('Date'),
  travelCategoryId: Yup.string()
    .nullable()
    .notOneOf(['UNKNOWN'], 'Travel category')
    .required('Travel category'),
  travelCategoryName: Yup.string().nullable(),
  distance: Yup.number().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
    then: Yup.number()
      .nullable()
      .required('Distance')
      .min(1, 'Distance too short'),
    otherwise: Yup.number().nullable().min(1, 'distance too short')
  }),
  conversionUnitName: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
    then: Yup.string().nullable().required('Unit'),
    otherwise: Yup.string().nullable()
  }),
  departureLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  destinationLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  vehicleTypeId: Yup.string().when('isRental', {
    is: true,
    then: Yup.string().nullable().required('Vehicle size'),
    otherwise: Yup.string().nullable()
  }),
  fuelTypeId: Yup.string().when('isRental', {
    is: true,
    then: Yup.string().nullable().required('Fuel type'),
    otherwise: Yup.string().nullable()
  }),
  traveClassExists: Yup.string().when('travelClass', {
    is: null,
    then: Yup.string().nullable().required('Travel Class'),
    otherwise: Yup.string().nullable()
  }),
  isRoundTrip: Yup.boolean().when('travelCategoryName', {
    is: (travelCategoryName) => roundTripVehicles.includes(travelCategoryName),
    then: Yup.boolean().nullable().required('Round trip'),
    otherwise: Yup.boolean().nullable()
  }),
  vehicleId: Yup.string().when(['travelCategoryName', 'isRental'], {
    is: (travelCategoryName, isRental) =>
      drivingVehicles.includes(travelCategoryName) &&
      typeof isRental !== 'undefined' &&
      !isRental,
    then: Yup.string().nullable().required('Vehicle'),
    otherwise: Yup.string().nullable()
  })
});
