import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useMetaContext } from '../../context';
import { getAddressValues, getDropdownOptions } from '../../helpers';
import GooglePlacesAutoComplete from '../GooglePlacesAutoComplete';
import { DropdownField, InputField } from '../index';
import './addressField.scss';

function AddressField({
  setFieldValue,
  formValues,
  setFormValues,
  disabled,
  isEdit = true
}) {
  const { meta } = useMetaContext();
  const [countryOptions, setCountryOptions] = useState([]);

  const handleSelect = (places) => {
    if (places?.length > 0) {
      const address = getAddressValues(places[0], meta.countries);
      setFormValues({
        ...formValues,
        ...(formValues?.energyTypes?.length > 0 &&
          !address?.isEstimationSupported && {
            energyTypes: formValues.energyTypes.map((energyType) => ({
              ...energyType,
              hasAccessToBills: true
            }))
          }),
        ...address,
        placeId: places[0].place_id,
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng()
      });
    }
  };

  useEffect(() => {
    setCountryOptions(getDropdownOptions(meta.countries, 'name', 'id'));
  }, [meta]);

  return (
    <div className="address-field">
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <GooglePlacesAutoComplete
            title="Select Address"
            isEdit={isEdit}
            currentCountry={formValues?.country || { id: formValues.countryId }}
            onSelect={handleSelect}
            disabled={disabled}
            defaultValue={
              formValues?.destinationLocation?.formattedAddress ??
              formValues?.destinationLocation?.address1
            }
            placeholder="Enter address"
          />
        </Col>
        {(formValues?.placeId || formValues.address1) && (
          <>
            <Col span={12}>
              <InputField
                disabled
                title="Address line 1"
                type="text"
                name="address1"
                placeholder="Enter address"
              />
            </Col>
            <Col span={12}>
              <InputField
                disabled={disabled}
                title="Address line 2"
                type="text"
                name="address2"
                placeholder="Enter address"
              />
            </Col>
            <Col span={12}>
              <InputField
                disabled
                title="City"
                type="text"
                name="city"
                placeholder="Enter city"
              />
            </Col>
            <Col span={12}>
              <InputField
                disabled
                title="State/Province"
                type="text"
                name="state"
                placeholder="Enter State/Province"
              />
            </Col>
            <Col span={12}>
              <InputField
                disabled
                title="Zip/Postal Code"
                type="text"
                name="postalCode"
                placeholder="Enter Zip/Postal Code"
              />
            </Col>
            <Col span={12}>
              <DropdownField
                disabled
                suffixIcon={<SearchOutlined />}
                showSearch
                title="Country"
                name="countryId"
                value={formValues?.countryId}
                options={countryOptions}
                setFieldValue={setFieldValue}
                placeholder="Select Country"
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default AddressField;
