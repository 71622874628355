import { Col, Modal as AntModal, Row } from 'antd';
import React, { useCallback } from 'react';
import { COLOURS_GRAY } from '../../../constants/colours';
import { IntegrationStatus } from '../../../constants/status';
import { DisconnectIntegrationStatuses } from '../../../constants/status/IntegrationStatus';
import { useMeContext } from '../../../context';
import { analytics, capitalizeText } from '../../../helpers';
import { useAsync, useModal } from '../../../hooks';
import { ChcProviderService, IntegrationService } from '../../../services';
import {
  ButtonIcon,
  Paragraph,
  Table,
  Tag,
  Title
} from '../../../stories/atoms';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import TableTitle from '../../../stories/molecules/TableTitle';
import { CarbonhoundConnectSteps } from '../../CarbonhoundConnect/CarbonhoundConnectSteps';

export function IntegrationProviderList({ integrationDetail }) {
  const { urlSlug, slug } = integrationDetail;
  const { me } = useMeContext();
  const handleLoadProviderList = useCallback(async () => {
    if (urlSlug || slug) {
      try {
        const response =
          await IntegrationService.listCompanyIntegrationProviders({
            integrationSlug: urlSlug || slug,
            companySlug: me.company?.slug
          });
        return response;
      } catch (e) {
        return [];
      }
    }
  }, [slug, urlSlug, me.company?.slug]);
  const {
    value: providerList,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadProviderList,
    defaultValue: []
  });

  const {
    Modal: CarbonhoundConnectModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '70%',
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        level1: 'Carbonhound Connect',
        level2: 'Overview',
        level3: 'Providers',
        integration: integrationDetail
      });
    },
    onCloseModal: refreshList
  });

  const removeProvider = async (utilityProviderId) => {
    await ChcProviderService.updateCompanyCHCProvider({
      companySlug: me.company?.slug,
      utilityProviderId,
      status: IntegrationStatus.PENDING_DISCONNECT
    });
    refreshList();
  };
  const { execute: handleRemoveProvider, isLoading: isRemovingProvider } =
    useAsync({
      asyncFunction: removeProvider,
      immediate: false
    });

  const confirmRemoveProvider = (utilityProviderId) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      title: <Title size="md">Are you sure you’d like to disconnect?</Title>,
      okText: 'Disconnect',
      cancelText: 'Cancel',
      content: 'You will no longer be able to utlize this integration.',
      onOk: async () => {
        analytics.track('Delete item clicked', me, {
          level1: 'Carbonhound Connect',
          level2: 'Overview',
          level3: 'Providers',
          integration: integrationDetail,
          utilityProviderId
        });
        handleRemoveProvider(utilityProviderId);
      }
    });
  };

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: (status) => {
        let tagColour;
        let tagLabel;
        switch (status) {
          case IntegrationStatus.CONNECTED: {
            tagColour = TAG_COLOURS.GREEN;
            tagLabel = IntegrationStatus.CONNECTED;
            break;
          }
          case IntegrationStatus.PENDING:
          case IntegrationStatus.PENDING_HISTORICAL_IMPORT:
          case IntegrationStatus.PENDING_CONNECTION:
          default: {
            tagColour = TAG_COLOURS.YELLOW;
            tagLabel = IntegrationStatus.PENDING;
            break;
          }
        }
        return DisconnectIntegrationStatuses.indexOf(status) > -1 ? (
          <Paragraph
            color={COLOURS_GRAY.GRAY_500}
            weight={600}
            size="sm"
            bottomSpacing={0}
          >
            {capitalizeText(IntegrationStatus.DISCONNECTED)}
          </Paragraph>
        ) : (
          <Tag color={tagColour}>{capitalizeText(tagLabel)}</Tag>
        );
      }
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '32px',
      render: (_, provider) => {
        if (DisconnectIntegrationStatuses.indexOf(provider.status) <= -1) {
          return (
            <ButtonIcon
              name="MinusCircle"
              onClick={() => {
                confirmRemoveProvider(provider.id);
              }}
            />
          );
        }
      }
    }
  ];

  return (
    <Row className="integration-provider-list">
      <Col span={24}>
        <Table
          dataSource={providerList}
          columns={columns}
          isLoading={isLoading || isRemovingProvider}
          showHeader={false}
          title={() => (
            <TableTitle
              title="Service Providers"
              buttons={[
                {
                  children: 'Add',
                  onClick: handleShowModal
                }
              ]}
              isHeaderBorder
            />
          )}
        />
      </Col>
      <CarbonhoundConnectModal title="Connect your utilities with Carbonhound Connect">
        <CarbonhoundConnectSteps
          integration={integrationDetail}
          onClose={handleCloseModal}
        />
      </CarbonhoundConnectModal>
    </Row>
  );
}
