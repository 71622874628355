import { useLoadScript } from '@react-google-maps/api';
import { GoogleApiLibraries } from '../constants';

export const useLoadGooglePlaces = () => {
  /*
   * This hook adds methods for easy integration with the google places api under window.google
   * */
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GCP_PLACES_API_KEY,
    id: 'google-script-loader',
    libraries: GoogleApiLibraries
  });

  return { isGoogleLoaded: isLoaded };
};
