import { Col, Row } from 'antd';
import { AGGREGATE_TYPE, TargetType } from '../../../constants';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { useDataContext } from '../../../context/DataContext';
import {
  Icon,
  Paragraph,
  StackedBarChart,
  Title
} from '../../../stories/atoms';
import {
  CategoryColours,
  ScopeColours
} from '../../../stories/atoms/StackedBarChart/StackedBarChart.types';
import './DataChart.scss';
import { DataChartLoadingState } from './DataChartLoadingState';

export function DataChart({ chartData, isLoading, selectedChartType }) {
  const { data } = useDataContext();
  const { filters } = data;
  const { visibleTarget, aggregateType } = filters;
  const chartType = selectedChartType || aggregateType;
  // target Data
  const targetChartLine = visibleTarget?.targetEmissions?.map(
    (te) => te.targetEmissions
  );
  const targetLineChart = [
    {
      label: 'Target Line',
      data: targetChartLine,
      type: 'line',
      borderColor: '#6EE7B7',
      stack: 'combined',
      order: 1
    }
  ];

  const combinedData = {
    ...chartData,
    datasets: [...(chartData?.datasets ?? []), ...targetLineChart]
  };
  return (
    <Row className="data-chart">
      {isLoading ? (
        <DataChartLoadingState isLoading={isLoading} />
      ) : (
        <Col span={24}>
          {chartData?.datasets.length > 0 ? (
            <StackedBarChart
              colorSchema={
                chartType === AGGREGATE_TYPE.CATEGORY
                  ? CategoryColours
                  : ScopeColours
              }
              stacked
              excludedTooltipDatasets={['Target line']}
              data={combinedData}
              yAxisText={
                visibleTarget?.type === TargetType.INTENSITY
                  ? 'Kilograms CO2 Equivalent'
                  : 'Tonnes CO2 Equivalent'
              }
            />
          ) : (
            <Row gutter={[0, 32]}>
              <Col span={24} className="justify-center display-flex">
                <Icon
                  bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
                  showBackground
                  color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                  size={24}
                  name="ChartBar"
                />
              </Col>
              <Col span={24}>
                <Title align="center" size="md" bottomSpacing={8}>
                  No data to display
                </Title>
                <Paragraph align="center" bottomSpacing={0}>
                  You have no data to display for the selected date range.
                </Paragraph>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
}
