import { useEffect, useState } from 'react';
import AnimatedProgress from '../../../components/AnimatedProgress';
import { UploadStatus } from '../../../constants';
import { useBulkUploadContext } from '../../../context';
import useInterval from '../../../hooks/useInterval';
import { NotificationService } from '../../../services';
import { Footer } from '../Footer';
import './ProcessUpload.scss';

function ProcessUpload() {
  const { bulkUploadState, updateNotification } = useBulkUploadContext();
  const { notification } = bulkUploadState;
  const [percentComplete, setPercentComplete] = useState(0);
  useInterval(
    async () => {
      const notificationResp = await NotificationService.getNotification({
        notificationId: notification?.id
      });
      updateNotification({ notification: notificationResp });
      setPercentComplete(
        Math.floor(
          ((notificationResp?.details?.parseProgress ?? 0) +
            (notificationResp?.details?.upsertProgress ?? 0)) /
            2
        )
      );
    },
    bulkUploadState.notification.status === UploadStatus.PROCESSING
      ? 6000
      : null
  );

  useEffect(() => {
    setPercentComplete(
      Math.round(
        ((notification.details?.parseProgress ?? 0) +
          (notification.details?.upsertProgress ?? 0)) /
          2
      )
    );
  }, [
    notification.details?.parseProgress,
    notification.details?.upsertProgress,
    setPercentComplete
  ]);

  return (
    <div>
      <div className="process-upload mt-30 mb-30">
        <AnimatedProgress
          percent={percentComplete}
          strokeWidth={72}
          status={
            bulkUploadState.notification.status === UploadStatus.PROCESSING &&
            'active'
          }
          showPercent
        />
      </div>
      <Footer
        analyticsStepContext="Processing"
        canGoBack={false}
        isStepInvalid={
          bulkUploadState.notification.status !== UploadStatus.READY_FOR_REVIEW
        }
      />
    </div>
  );
}
export default ProcessUpload;
