/** OfficeTrailContext is responsible for storing the Global State
 * for office trail details.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';
import { OfficeTrail, OfficeTrailSegment } from '../constants';
import { getTrailStatus } from '../helpers';
import { CompanyTrailService } from '../services';
import { useMeContext } from './MeContext';
// Create the context
const OfficeTrailContext = createContext({});

// Define the default context state
const officeTrailState = {
  activeStep: 0,
  officeTrailStatus: {},
  employeeTrailStatus: {},
  completedSteps: 0
};

// Create method to use context
function useOfficeTrailContext() {
  const context = useContext(OfficeTrailContext);

  const { me, isAdmin } = useMeContext();

  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useOfficeTrailContext must be used within a OfficeTrailContextProvider'
    );
  }
  const [officeTrailDetails, setOfficeTrailDetails] = context;

  // Context Methods //
  const refreshOfficeTrails = async () => {
    if (!me?.company?.slug || !isAdmin) return;
    const officeTrails = await CompanyTrailService.fetchCompanyTrails({
      companySlug: me?.company?.slug,
      trailName: OfficeTrail.OFFICE_ONBOARDING
    });
    const officeTrailStatus = {
      [OfficeTrailSegment.ENERGY]: getTrailStatus(
        officeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.ENERGY
        )
      ),
      [OfficeTrailSegment.COMMUTE]: getTrailStatus(
        officeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.COMMUTE
        )
      ),
      [OfficeTrailSegment.BUSINESS_TRAVEL]: getTrailStatus(
        officeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.BUSINESS_TRAVEL
        )
      ),
      [OfficeTrailSegment.CUSTOM_SOURCES]: getTrailStatus(
        officeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.CUSTOM_SOURCES
        )
      )
    };
    const companyTrails = await CompanyTrailService.fetchCompanyTrails({
      companySlug: me?.company?.slug,
      trailName: OfficeTrail.COMPANY
    });
    officeTrailStatus[OfficeTrailSegment.TARGET] = getTrailStatus(
      companyTrails.find(
        (trail) => trail.segmentName === OfficeTrailSegment.TARGET
      )
    );
    setOfficeTrailDetails((officeTrailDetails) => {
      let [startedSteps, completedSteps] = Array(2).fill(0);
      for (const value of Object.values(officeTrails)) {
        if (value?.isStarted) startedSteps++;
        if (value?.isComplete) completedSteps++;
      }
      return {
        ...officeTrailDetails,
        startedSteps,
        officeTrailStatus,
        completedSteps
      };
    });
  };

  const refreshEmployeeOnboardingTrails = async () => {
    if (!me?.company?.slug) return;
    const { loggedInAs } = me;
    const { userId: loggedInAsUserId } = loggedInAs ?? {};

    const employeeTrails = await CompanyTrailService.fetchCompanyTrails({
      companySlug: me?.company?.slug,
      trailName: OfficeTrail.EMPLOYEE_ONBOARDING,
      userId: loggedInAsUserId || me.id
    });
    const employeeTrailStatus = {
      [OfficeTrailSegment.COMMUTE]: getTrailStatus(
        employeeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.COMMUTE
        )
      ),
      [OfficeTrailSegment.BUSINESS_TRAVEL]: getTrailStatus(
        employeeTrails.find(
          (trail) => trail.segmentName === OfficeTrailSegment.BUSINESS_TRAVEL
        )
      )
    };
    setOfficeTrailDetails((officeTrailDetails) => {
      return {
        ...officeTrailDetails,
        employeeTrailStatus
      };
    });
  };

  // Return state and Context Methods
  // Note: DO NOT return "setMe".State updates should be managed through context methods
  return {
    officeTrailDetails,
    refreshOfficeTrails,
    refreshEmployeeOnboardingTrails
  };
}

// Create the context provider
function OfficeTrailContextProvider(props) {
  const [officeTrailDetails, setOfficeTrailDetails] =
    useState(officeTrailState);
  const value = useMemo(
    () => [officeTrailDetails, setOfficeTrailDetails],
    [officeTrailDetails]
  );
  return <OfficeTrailContext.Provider value={value} {...props} />;
}

export { OfficeTrailContextProvider, useOfficeTrailContext };
