import { Col, Divider, Row } from 'antd';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  DISTANCE_CALCULATION_TYPE,
  TransportationType,
  YesOrNoOptions
} from '../../../constants';
import { useMeContext, useMetaContext } from '../../../context';
import { getDropdownOptions } from '../../../helpers';
import { setVehicleIdKey } from '../../../helpers/setVehicleIdKey';
import { useAsync } from '../../../hooks';
import { TravelExpenseService, VehicleService } from '../../../services';
import { Button, DatePicker, Input, Radio, Select } from '../../atoms';
import { INPUT_TYPES } from '../../atoms/Input/Input.types';
import {
  defaultDistanceMetrics,
  defaultToFromAirportMetrics,
  defaultToFromMetrics,
  DistanceCalculationTypeFields
} from '../DistanceCalculationTypeFields';
import OwnerSelect from '../OwnerSelect';
import { TransportTypeSelect } from '../TransportTypeSelect';
import { travelItemFormValidation } from './TravelItemFormValidation';

export function TravelItemForm({
  travelItem = {},
  onSuccess,
  emissionSourceDetails = {},
  isTravelCategoryFieldDisabled = true
}) {
  const { meta, setTravelClasses } = useMetaContext();
  const { travelClasses, travelCategories } = meta;
  const { me } = useMeContext();
  const { details: internalDetails, id: emissionSourceDetailsId } =
    emissionSourceDetails;
  const {
    travelCategoryId: emissionSourceTravelCategoryId,
    commuteVehicleId: emissionSourceCommuteVehicleId,
    rentalVehicleId: emissionSourceRentalVehicleId
  } = internalDetails ?? {};
  const {
    details = {},
    activityUnit,
    commuteVehicle = {},
    date,
    departureLocation,
    destinationLocation,
    distance,
    expenseNote,
    travelCategory,
    travelClass,
    userCompanyId,
    isEstimate
  } = travelItem;
  const {
    distanceCalculationType,
    flightNumber,
    isRoundTrip,
    departureAirport,
    destinationAirport,
    departureGeometry,
    destinationGeometry
  } = details ?? {};
  const {
    fuelType,
    isRental,
    vehicleType,
    id: commuteVehicleId,
    vehicleAssetId
  } = commuteVehicle ?? {};
  const { id: fuelTypeId } = fuelType ?? {};
  const {
    id: departureLocationId,
    lat: departureLocationLat,
    lng: departureLocationLon
  } = departureLocation ?? {};
  const {
    id: destinationLocationId,
    lat: destinationLocationLat,
    lng: destinationLocationLon
  } = destinationLocation ?? {};
  const { id: travelClassId } = travelClass ?? {};
  const { id: vehicleTypeId } = vehicleType ?? {};
  const { name: conversionUnitName } = activityUnit ?? {};
  const {
    id: travelItemCategoryId,
    name: travelCategoryName,
    googleType: travelItemGoogleType
  } = travelCategory ?? {};
  const vehicleId =
    commuteVehicleId ||
    vehicleAssetId ||
    emissionSourceCommuteVehicleId ||
    emissionSourceRentalVehicleId;
  const isRentalVehicle = !!emissionSourceRentalVehicleId || isRental;
  const travelCategoryId =
    travelItemCategoryId || emissionSourceTravelCategoryId;
  const googleType =
    travelItemGoogleType ||
    travelCategories.find(
      (transportationType) => transportationType.id === travelCategoryId
    )?.googleType;

  const initialFormValues = {
    flightNumber,
    commuteVehicleId,
    conversionUnitName,
    date,
    departureAirport,
    departureLocationId,
    departureLocation,
    departureGeometry: {
      lat:
        departureLocationLat ||
        departureGeometry?.lat ||
        departureAirport?.latitude,
      lng:
        departureLocationLon ||
        departureGeometry?.lng ||
        departureAirport?.longitute
    },
    destinationAirport,
    destinationLocationId,
    destinationGeometry: {
      lat:
        destinationLocationLat ||
        destinationGeometry?.lat ||
        destinationAirport?.latitude,
      lng:
        destinationLocationLon ||
        destinationGeometry?.lng ||
        destinationAirport?.longitute
    },
    destinationLocation,
    distance,
    distanceCalculationType,
    existingCommuteVehicle: !vehicleAssetId,
    expenseNote,
    fuelTypeId,
    googleType,
    isEstimate,
    isRental: isRentalVehicle,
    isRoundTrip,
    travelCategoryId,
    travelCategoryName,
    travelClassId,
    userCompanyId,
    vehicleId,
    vehicleTypeId,
    emissionSourceDetailsId
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const isEditingForm = !!travelItem.id;
  const isEmissionSourceBased = !!emissionSourceDetails.id;
  const handleOnChangeTravelCategory = (opt, setFieldValue) => {
    let defaultDistanceCalculationType =
      opt.name === TransportationType.PLANE
        ? DISTANCE_CALCULATION_TYPE.CIRCLE_GEOMETRY
        : DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX;

    if (defaultDistanceMetrics.indexOf(opt.name) > -1) {
      defaultDistanceCalculationType = DISTANCE_CALCULATION_TYPE.NONE;
    }

    setFieldValue('distanceCalculationType', defaultDistanceCalculationType);
    setFieldValue('travelCategoryId', opt.value);
    setFieldValue('travelCategoryName', opt.name);
    setFieldValue('googleType', opt.googleType);
  };

  const handleSubmit = async (values) => {
    const travelApiMethod = travelItem.id
      ? TravelExpenseService.updateTravelExpense
      : TravelExpenseService.createTravelExpense;
    const {
      existingCommuteVehicle,
      commuteVehicleId: commuteVehicleIdValue,
      ...paramValues
    } = values;
    let params = { ...paramValues, id: travelItem.id };

    //  only send ESDetailsID for new items
    if (!travelItem.id) {
      params.emissionSourceDetailsId = emissionSourceDetails.id;
    }
    params = setVehicleIdKey(
      existingCommuteVehicle,
      params,
      params.vehicleId,
      commuteVehicleIdValue ?? params.vehicleId
    );

    if (values.isRental && !emissionSourceDetailsId) {
      const newVehicle = await VehicleService.createVehicle(
        { companySlug: me.company.slug },
        values
      );

      // Set the commute vehicle ID to the newly created rental car
      params.commuteVehicleId = newVehicle.id;
    }

    const resp = await travelApiMethod(me?.company?.slug, params);
    onSuccess?.(resp);
  };
  const { execute: handleSubmitTravelItemForm, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  const travelClassOptions = getDropdownOptions(travelClasses, 'name', 'id');
  useEffect(() => {
    setTravelClasses();
  }, []);

  useEffect(() => {
    if (!!travelCategoryId && !formValues.travelCategoryName) {
      const newTravelCategoryName = travelCategories.find(
        (cat) => cat.id === travelCategoryId
      )?.name;
      setFormValues((prev) => ({
        ...prev,
        travelCategoryName: newTravelCategoryName
      }));
    }
  }, [travelCategoryId, formValues.travelCategoryName, travelCategories]);

  useEffect(() => {
    setFormValues((prev) => ({ ...prev, googleType }));
  }, [googleType]);
  return (
    <Formik
      validationSchema={travelItemFormValidation}
      initialValues={formValues}
      onSubmit={handleSubmitTravelItemForm}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const isCar = values.travelCategoryName === TransportationType.CAR;
        const isPlane = values.travelCategoryName === TransportationType.PLANE;

        return (
          <Form>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <OwnerSelect
                  setFieldValue={setFieldValue}
                  title="Traveller"
                  name="userCompanyId"
                  value={values.userCompanyId}
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  setFieldValue={setFieldValue}
                  title="Travel Date"
                  name="date"
                  defaultValue={values.date}
                />
              </Col>
              <Col span={isCar ? 12 : 24}>
                <TransportTypeSelect
                  setFieldValue={setFieldValue}
                  title="Transport Type"
                  name="travelCategoryId"
                  value={values.travelCategoryId}
                  onChange={(_, opt) =>
                    handleOnChangeTravelCategory(opt, setFieldValue)
                  }
                  disabled={
                    (isTravelCategoryFieldDisabled && isEditingForm) ||
                    !!emissionSourceTravelCategoryId
                  }
                />
              </Col>
              {isCar && !isEmissionSourceBased && (
                <Col span={12}>
                  <Radio
                    setFieldValue={setFieldValue}
                    value={values?.isRental}
                    options={YesOrNoOptions}
                    name="isRental"
                    raised
                    title="Rental Car?"
                  />
                </Col>
              )}
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <DistanceCalculationTypeFields
                  isEmissionSourceBased={isEmissionSourceBased}
                  distanceFieldKey="distance"
                />
              </Col>
              {(defaultToFromAirportMetrics.includes(
                values.travelCategoryName
              ) ||
                defaultToFromMetrics.includes(values.travelCategoryName)) && (
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    {isPlane && (
                      <Col flex="1">
                        <Input
                          setFieldValue={setFieldValue}
                          value={values.flightNumber}
                          title="Flight Number (optional)"
                          name="flightNumber"
                          placeholder="Enter flight number"
                        />
                      </Col>
                    )}
                    {(values.travelCategoryName === TransportationType.PLANE ||
                      values.travelCategoryName ===
                        TransportationType.INTERCITY_TRAIN) && (
                      <Col flex="1">
                        <Select
                          title="Class"
                          name="travelClassId"
                          value={values?.travelClassId}
                          setFieldValue={setFieldValue}
                          options={travelClassOptions}
                          placeholder="Select Class"
                        />
                      </Col>
                    )}
                    <Col flex="1">
                      <Radio
                        setFieldValue={setFieldValue}
                        value={values?.isRoundTrip}
                        options={YesOrNoOptions}
                        name="isRoundTrip"
                        raised
                        title="Round Trip?"
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col>
                <Radio
                  title="Is this data estimated?"
                  raised
                  value={values?.isEstimate}
                  name="isEstimate"
                  options={YesOrNoOptions}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Input
                  title="Notes (Optional)"
                  name="expenseNote"
                  type={INPUT_TYPES.TEXT_AREA}
                  value={values.expenseNote}
                />
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Col>
                    <Button
                      disabled={isLoading}
                      loading={isLoading}
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
