import { Col, Row } from 'antd';
import React from 'react';
import { Card } from '../../../../components';
import { useInitiativeContext } from '../../../../context';
import { InitiativeResource } from '../../../../features/Initiative/InitiativeResource';
import { InitiativeTemplateRelatedActions } from '../../../../features/Initiative/InitiativeTemplate';
import { Paragraph, Title } from '../../../../stories/atoms';

export function CompanyInitiativeRecommendedActions() {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { details } = initiativeTemplate;
  const { initiativeResourceUrls } = details ?? {};
  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Recommended Action'
  };
  return (
    <Row gutter={[0, 40]} className="Company-Initiative-recommended-actions">
      <Col span={24}>
        <Card>
          <Title bottomSpacing={8}>Project Implementation</Title>
          <Paragraph>
            You have completed your planning and it is time to create actions
            based off of your plan. For this step we will give you recommended
            actions but the reigns are in your hands.
          </Paragraph>
        </Card>
      </Col>
      {!!initiativeResourceUrls && (
        <Col span={24}>
          <Title bottomSpacing={4}>Resources</Title>
          <Paragraph bottomSpacing={32}>
            This library has been compiled to help you research and communicate
            your plan to internal and external stakeholders
          </Paragraph>
          {initiativeResourceUrls?.map((resource, i) => (
            <div className="mb-4" key={i}>
              <InitiativeResource
                resource={resource}
                theme="light"
                analyticsContext={{
                  ...analyticsContext,
                  level3: 'Resources'
                }}
                companyInitiative={companyInitiative}
              />
            </div>
          ))}
        </Col>
      )}
      <Col span={24}>
        <InitiativeTemplateRelatedActions
          analyticsContext={{
            ...analyticsContext,
            level3: 'Actions Table'
          }}
        />
      </Col>
    </Row>
  );
}
