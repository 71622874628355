import { PopupButton } from '@typeform/embed-react';
import { Checkbox, Col, Divider, Modal as AntModal, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { AddressField, InputField } from '../../../components';
import { EnergyTypeConstants, YesOrNoOptions } from '../../../constants';
import { COLOURS_CARBONHOUND, COLOURS_GRAY } from '../../../constants/colours';
import { useMeContext, useMetaContext } from '../../../context';
import { analytics, getDropdownOptions } from '../../../helpers';
import { getIsCountryEstimationsSupported } from '../../../helpers/generators';
import { useAsync, useModal } from '../../../hooks';
import { Energy } from '../../../models';
import { EnergyService, LocationService, MetaService } from '../../../services';
import {
  Button,
  DatePicker,
  Icon,
  Paragraph,
  Radio,
  Select,
  Title
} from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import InputNumberField from '../../atoms/InputNumberField';
import { Blurb } from '../Blurb';
import DataRequestTemplate from '../DataRequestTemplate';
import { EnergyInputs } from './EnergyInputs';
import './LocationForm.scss';
import { LocationFormValidation } from './LocationFormValidation';

function LocationForm({
  isOffice = false,
  isEdit = true,
  onSuccess,
  collectionId,
  analyticsContext,
  showAdditionalFields
}) {
  const { me } = useMeContext();
  const { meta } = useMetaContext();
  const [energyTypes, setEnergyTypes] = useState([]);
  const [energyTypeCheckboxOptions, setEnergyTypeCheckboxOptions] = useState(
    []
  );
  const [energyTypeDropdownOptions, setEnergyTypeDropdownOptions] = useState(
    []
  );

  const onPopup = () => {
    const { tracking, ...analyticsOthers } = { ...analyticsContext };
    analytics.track(tracking, me, analyticsOthers);
  };

  const defaultEnergyItem = new Energy();
  const [formValues, setFormValues] = useState({
    electricityTypeCheckboxIds: [],
    electricityTypeDropdownIds: [],
    energyTypes: [],
    energyTypeIds: [],
    isOwner: undefined,
    moveInDate: undefined,
    moveOutDate: undefined,
    isCurrentOffice: undefined,
    isMonthlyInput: undefined,
    name: undefined,
    ...defaultEnergyItem
  });

  const handleSetEnergyTypes = (
    values,
    setFieldValue,
    selectedEnergyTypeIds
  ) => {
    const existingEnergyTypes = values.energyTypes;
    const energyTypeValues = selectedEnergyTypeIds.map((energyTypeId) => {
      const energyType = energyTypes.find(
        (energyType) => energyType.id === energyTypeId
      );
      const existingEnergyType = existingEnergyTypes.find(
        (energyType) => energyType.energyTypeId === energyTypeId
      );
      if (existingEnergyType) return existingEnergyType;

      return {
        energyTypeId: energyType.id,
        energyType: energyType.name,
        ...(!values.isEstimationSupported && { hasAccessToBills: true })
      };
    });
    setFieldValue('energyTypes', [...energyTypeValues]);
  };

  const loadLocationDetails = useCallback(async () => {
    if (collectionId && showAdditionalFields) {
      const energies = await EnergyService.fetchEnergies(collectionId);
      if (!!energies?.[0]) {
        let [energyDetails] = energies;
        if (energyDetails?.energyTypes) {
          energyDetails.energyTypes.forEach((energyType) => {
            if (!energyType.hasAccessToBills) {
              energyType.hasSeparateMeter = null;
            }
          });
        } else {
          energyDetails.energyTypes = [];
        }
        const isEstimationSupported = getIsCountryEstimationsSupported({
          countries: meta.countries,
          countryName: energyDetails.location?.country?.name
        });
        energyDetails = {
          ...energyDetails,
          collectionId: formValues?.id,
          countryId: energyDetails.location?.country?.id,
          moveInDate: !!energyDetails.moveInDate
            ? formatInTimeZone(energyDetails.moveInDate, 'UTC', 'yyyy-MM-dd')
            : undefined,
          moveOutDate: !!energyDetails.moveOutDate
            ? formatInTimeZone(energyDetails.moveOutDate, 'UTC', 'yyyy-MM-dd')
            : undefined,
          isCurrentOffice: !energyDetails.moveOutDate,
          isMonthlyInput: energyDetails.energyTypes?.length > 0,
          energyTypeIds: energyDetails.energyTypes.map((type) => type.id),
          isEstimationSupported,
          ...energyDetails.location
        };
        setFormValues(energyDetails);

        return energyDetails;
      }
    }
  }, [formValues?.id, collectionId, showAdditionalFields]);

  const { value: InitialEnergyDetails, setValue } = useAsync({
    asyncFunction: loadLocationDetails
  });

  const handleSaveEnergyDetails = async (values, createdCollectionId) => {
    const { energyTypes, isEnergyTypeUnknown, officeSquareFootage } =
      values ?? {};

    setFormValues({ ...values, isEnergyTypeUnknown, officeSquareFootage });

    if (!isEnergyTypeUnknown) {
      const energyTypeIndex = energyTypes.findIndex(
        (energyType) =>
          energyType.energyType === EnergyTypeConstants.ELECTRICITY
      );
      if (energyTypeIndex >= 0) {
        energyTypes[energyTypeIndex] = {
          ...energyTypes[energyTypeIndex]
        };
      }
    }

    const initialMoveInDate = InitialEnergyDetails?.moveInDate
      ? formatInTimeZone(
          new Date(InitialEnergyDetails.moveInDate),
          'UTC',
          'yyyy-MM-dd'
        )
      : null;
    const changedMoveInDate = values?.moveInDate
      ? formatInTimeZone(new Date(values.moveInDate), 'UTC', 'yyyy-MM-dd')
      : null;
    const initialMoveOutDate = InitialEnergyDetails?.moveOutDate
      ? formatInTimeZone(
          new Date(InitialEnergyDetails.moveOutDate),
          'UTC',
          'yyyy-MM-dd'
        )
      : null;
    const changedMoveOutDate = values?.moveOutDate
      ? formatInTimeZone(new Date(values.moveOutDate), 'UTC', 'yyyy-MM-dd')
      : null;

    const hasMoveDatesChanged =
      (!!initialMoveInDate && initialMoveInDate !== changedMoveInDate) ||
      (!!initialMoveOutDate && initialMoveOutDate !== changedMoveOutDate);

    const energyMethod = !!values?.energyDetailsId
      ? EnergyService.updateEnergy
      : EnergyService.createEnergy;
    await energyMethod(collectionId || createdCollectionId, {
      ...values,
      ...(!!hasMoveDatesChanged && { clearEstimations: true }),
      energyDetailsId: formValues?.energyDetailsId
    });
    if (!!values?.energyDetailsId && !!hasMoveDatesChanged) {
      setValue((prevState) => ({
        ...prevState,
        moveInDate: values?.moveInDate,
        moveOutDate: values?.moveOutDate
      }));
    }
  };
  const handleSaveLocation = async (values) => {
    const locationMethod = !!values?.location?.id
      ? LocationService.updateLocation
      : LocationService.createLocation;
    let upsertLocation = {
      lng: values.lng,
      lat: values.lat,
      address1: values.address1,
      address2: values.address2,
      countryId: values.countryId,
      countryName: values.country?.name,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      collectionId: values.collectionId,
      companySlug: me.company?.slug,
      ...(values.location?.id && { locationId: values?.location?.id }),
      district: values.district,
      isOwner: values.isOwner,
      moveInDate: values.moveInDate,
      moveOutDate: values.isCurrentOffice ? null : values.moveOutDate,
      isOffice: values?.isOffice ? true : isOffice,
      name: values?.name
    };
    const createdLocation = await locationMethod({
      companySlug: me.company?.slug,
      location: upsertLocation,
      isCollection: true
    });
    upsertLocation = {
      ...upsertLocation,
      ...createdLocation
    };
    return upsertLocation;
  };
  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });
  const handleConfirmationPopup = (values) =>
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: (
        <div>
          We will now estimate your office impact using regional averages, but
          it is more accurate to enter your utility bill information if you have
          access.
          <br />
          <br />
          Are you sure you wish to proceed?
        </div>
      ),
      onOk: async () => {
        const upsertLocation = await handleSaveLocation(values);
        await handleSaveEnergyDetails(
          values,
          upsertLocation?.collection?.id ?? formValues?.collection?.id
        );
        onSuccess?.(upsertLocation);
      }
    });
  const handleMoveOutConfirmationPopup = (values) =>
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Continue move-out',
      cancelText: 'Cancel',
      title: (
        <Title bottomSpacing={0} size="md">
          We will stop estimating your data
        </Title>
      ),
      content: (
        <Paragraph bottomSpacing={0}>
          We will no longer generate estimated data from the move out date you
          have selected onward.
        </Paragraph>
      ),
      onOk: async () => {
        const upsertLocation = await handleSaveLocation(values);
        await handleSaveEnergyDetails(
          values,
          upsertLocation?.collection?.id ?? formValues?.collection?.id
        );
        onSuccess?.(upsertLocation);
      }
    });

  const handleSubmit = async (values) => {
    const showConfirmationPopup =
      (values?.isEnergyTypeUnknown ||
        values?.energyTypes?.filter(
          (energyType) => !energyType.hasAccessToBills
        ).length > 0) &&
      !values.id;

    // If initially the location was active, AND it is currently set to false
    const showMoveOutConfirmationPopup =
      !!InitialEnergyDetails?.isCurrentOffice && !values.isCurrentOffice;
    if (showConfirmationPopup) {
      handleConfirmationPopup(values);
    } else if (showMoveOutConfirmationPopup) {
      handleMoveOutConfirmationPopup(values);
    } else if (values.isMonthlyInput) {
      const upsertLocation = await handleSaveLocation(values);
      await handleSaveEnergyDetails(
        values,
        upsertLocation?.collection?.id ?? formValues?.collection?.id
      );
      onSuccess?.(upsertLocation);
    } else {
      const upsertLocation = await handleSaveLocation(values);
      onSuccess?.(upsertLocation);
    }
  };
  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  useEffect(() => {
    if (!formValues?.countryId) return;
    MetaService.fetchEnergyTypes(
      { countryId: formValues.countryId },
      (energyTypes) => {
        setEnergyTypes(energyTypes);
        const checkboxEnergyTypes =
          energyTypes?.filter(
            (energyType) =>
              energyType.name === EnergyTypeConstants.ELECTRICITY ||
              energyType.name === EnergyTypeConstants.NATURAL_GAS
          ) ?? [];
        const dropdownEnergyTypes =
          energyTypes?.filter(
            (energyType) =>
              !(
                energyType.name === EnergyTypeConstants.ELECTRICITY ||
                energyType.name === EnergyTypeConstants.NATURAL_GAS
              )
          ) ?? [];
        setEnergyTypeDropdownOptions(
          getDropdownOptions(dropdownEnergyTypes, 'friendlyName', 'id')
        );
        setEnergyTypeCheckboxOptions(
          getDropdownOptions(checkboxEnergyTypes, 'friendlyName', 'id')
        );
      },
      () => {},
      () => {}
    );
  }, [formValues?.countryId]);

  return (
    <div className="location-form">
      <Formik
        initialValues={formValues}
        validationSchema={LocationFormValidation}
        onSubmit={execute}
        enableReinitialize
      >
        {({ setFieldValue, values, isValid }) => {
          const isEstimationSupported =
            values?.isEstimationSupported ??
            getIsCountryEstimationsSupported({
              countries: meta.countries,
              countryName: values?.country?.name
            });
          return (
            <Form>
              <Row>
                <Col span={24}>
                  <InputField
                    title="Nickname (optional)"
                    type="text"
                    name="name"
                    placeholder="Enter"
                  />
                </Col>
              </Row>
              <Row>
                <AddressField
                  isEdit={isEdit}
                  setFormValues={setFormValues}
                  setFieldValue={setFieldValue}
                  formValues={values}
                />
              </Row>
              {showAdditionalFields && (
                <Row gutter={[16, 24]}>
                  <Col span={12}>
                    <Radio
                      title="Company owned building?"
                      raised
                      value={values?.isOwner}
                      name="isOwner"
                      options={YesOrNoOptions}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <Radio
                      title="Is this location currently in use?"
                      raised
                      value={values?.isCurrentOffice}
                      name="isCurrentOffice"
                      options={YesOrNoOptions}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col
                    span={
                      typeof values?.isCurrentOffice !== 'undefined' &&
                      !values?.isCurrentOffice
                        ? 12
                        : 24
                    }
                  >
                    <DatePicker
                      name="moveInDate"
                      defaultValue={values?.moveInDate}
                      title="Location Move-in Date"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  {typeof values?.isCurrentOffice !== 'undefined' &&
                    !values?.isCurrentOffice && (
                      <Col span={12}>
                        <DatePicker
                          name="moveOutDate"
                          defaultValue={values?.moveOutDate}
                          title="Location Move-out Date"
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    )}

                  <Col span={24}>
                    <Radio
                      title="Does this location have energy inputs? (e.g monthly electricity bills)"
                      raised
                      value={values?.isMonthlyInput}
                      name="isMonthlyInput"
                      options={YesOrNoOptions}
                      setFieldValue={setFieldValue}
                      disabled={InitialEnergyDetails?.isMonthlyInput}
                    />
                  </Col>
                </Row>
              )}
              {!!values?.isMonthlyInput && (
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <Divider />
                    <Title size="rg">
                      Select all the types of energy your offices uses
                    </Title>
                    <Title size="xs">
                      This can be modified at any time in your locations
                    </Title>
                    {!!isEstimationSupported && (
                      <Checkbox
                        name="isEnergyTypeUnknown"
                        checked={values?.isEnergyTypeUnknown}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setFieldValue('energyTypeIds', []);
                            handleSetEnergyTypes(values, setFieldValue, []);
                          }
                          setFieldValue(
                            'isEnergyTypeUnknown',
                            event.target.checked
                          );
                        }}
                      >
                        I don’t know
                      </Checkbox>
                    )}
                  </Col>
                  {typeof values?.isEnergyTypeUnknown !== 'undefined' &&
                  values?.isEnergyTypeUnknown ? (
                    <Col span={24}>
                      <InputNumberField
                        placeholder="Enter"
                        type="number"
                        title="Office Square Footage"
                        name="officeSquareFootage"
                        value={values?.officeSquareFootage}
                      />
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Row className="mb-6">
                        <Col span={24}>
                          <Select
                            setFieldValue={setFieldValue}
                            onChange={(energyTypeIds) => {
                              setFieldValue('energyTypeIds', energyTypeIds);
                              handleSetEnergyTypes(
                                values,
                                setFieldValue,
                                energyTypeIds
                              );
                            }}
                            name="energyTypeIds"
                            value={values?.energyTypeIds}
                            placeholder="Search and select"
                            options={[
                              ...energyTypeCheckboxOptions,
                              ...energyTypeDropdownOptions
                            ]}
                            mode="multiple"
                          />
                        </Col>
                      </Row>
                      {values?.energyTypes?.length > 0 && (
                        <Row className="location-form__header-row">
                          <Col span={8}>
                            <Title
                              bottomSpacing={0}
                              size="sm"
                              className="text-sbd"
                            >
                              Energy Type
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Paragraph bottomSpacing={0} size="sm">
                              Access to bills?
                            </Paragraph>
                          </Col>
                          <Col span={8}>
                            <Paragraph bottomSpacing={0} size="sm">
                              Separately metered?
                            </Paragraph>
                          </Col>
                        </Row>
                      )}
                      {!!values?.energyTypes.length && (
                        <EnergyInputs energyTypes={values?.energyTypes} />
                      )}
                    </Col>
                  )}
                </Row>
              )}
              <Row className="data-request-template__container">
                <Col span={24} className="">
                  <div className="display-flex">
                    <div className="mr-2">
                      <Icon
                        name="ClipboardCopy"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </div>
                    <div>
                      <Title
                        color={COLOURS_GRAY.GRAY_800}
                        size="sm"
                        fontWeight={500}
                        bottomSpacing={0}
                      >
                        Need to request data?
                      </Title>
                      <Paragraph
                        color={COLOURS_GRAY.GRAY_600}
                        size="sm"
                        bottomSpacing={0}
                      >
                        We’ve created a data request template to make it easier
                        for you to get the data you need from landlords, or
                        other team members.
                      </Paragraph>
                      <Button
                        type={BUTTON_TYPES.LINK}
                        onClick={handleShowTemplateModal}
                      >
                        View data request template
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              {!isEstimationSupported && (
                <Row>
                  <Col span={24} className="mb-6">
                    <Blurb
                      titleProps={{
                        title: 'Estimates locked for international locations',
                        size: 'sm'
                      }}
                      iconProps={{
                        name: 'GlobeAlt',
                        color: COLOURS_CARBONHOUND.PRIMARY_PURPLE,
                        className: 'mt-1',
                        size: 16
                      }}
                    >
                      <Paragraph size="sm" bottomSpacing={0}>
                        We currently don’t support estimations for the country
                        you have selected. If you would like us to support
                        estimations for this location in the future please send
                        us a request.
                      </Paragraph>
                      <Button onClick={onPopup} type={BUTTON_TYPES.LINK}>
                        <PopupButton
                          className="ch-typeform-button--link ch-typeform-button--link__blurb-popup"
                          id="a3KzDEDf"
                        >
                          Request estimations for this country
                        </PopupButton>
                      </Button>
                    </Blurb>
                  </Col>
                </Row>
              )}
              <Row justify="end">
                <Button
                  disabled={isLoading || !isValid}
                  loading={isLoading}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <DataRequestTemplateModal title="Data request template">
        <DataRequestTemplate />
      </DataRequestTemplateModal>
    </div>
  );
}
export default LocationForm;
