import React from 'react';
import './officeOnboarding.scss';
import { Outlet } from 'react-router-dom';

function OfficeOnboarding() {
  return (
    <div className="office-onboarding">
      <div className="mb-5">
        <Outlet />
      </div>
    </div>
  );
}

export default OfficeOnboarding;
