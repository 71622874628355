import { Col, Row } from 'antd';
import achievementSegmentIcon from '../../assets/images/acheivementSegment.png';
import employeeCommute from '../../assets/images/employee-commute.png';
import officeOnboarding from '../../assets/images/office-onboarding.png';
import setGoals from '../../assets/images/set-goals.png';
import travelExpenses from '../../assets/images/travel-expenses.png';
import wriLogo from '../../assets/images/wri-logo-white.png';
import './trailSegmentCompletedSteps.scss';

function TrailSegmentCompletedSteps() {
  const segments = [
    {
      id: 1,
      title: 'Office Onboarding',
      description: 'To understand the footprint of your office presence',
      icon: officeOnboarding
    },
    {
      id: 2,
      title: 'Employee Details',
      description:
        'Accurately capture the impact of employee commuting in your footprint',
      icon: employeeCommute
    },
    {
      id: 3,
      title: 'Travel Expenses',
      description: 'Understand the impact of business travel on your footprint',
      icon: travelExpenses
    },
    {
      id: 4,
      title: 'Set Targets',
      description:
        'Set realistic targets so we can reduce our impact on the environment, reduce costs, attract new employees and increase revenue',
      icon: setGoals
    }
  ];

  return (
    <div className="trail-segment-completed-steps">
      {segments.map((x, i) => (
        <Row
          align="middle"
          className="trail-segment-completed-steps__row"
          key={i}
        >
          {i % 2 === 0 ? (
            <Col span={10} />
          ) : (
            <Col span={10}>
              <div className="trail-segment-completed-steps__content-wrapper left-pointed">
                <Row gutter={[20, 0]} align="middle">
                  <Col span={7}>
                    <img
                      src={x.icon}
                      className="trail-segment-completed-steps__img"
                      alt="close"
                    />
                  </Col>
                  <Col span={17}>
                    <div className="trail-segment-completed-steps__title">
                      {x.title}
                    </div>
                    <div>Goal</div>
                    <div className="trail-segment-completed-steps__description">
                      {x.description}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
          <Col span={4}>
            <div className="trail-segment-completed-steps__number-wrapper">
              <div>Segment</div>
              <div className="trail-segment-completed-steps__number">
                0{i + 1}
              </div>
            </div>
          </Col>
          {i % 2 === 0 ? (
            <Col span={10}>
              <div className="trail-segment-completed-steps__content-wrapper right-pointed">
                <Row gutter={[20, 0]} align="middle">
                  <Col span={7}>
                    <img
                      src={x.icon}
                      className="trail-segment-completed-steps__img"
                      alt="close"
                    />
                  </Col>
                  <Col span={17}>
                    <div className="trail-segment-completed-steps__title">
                      {x.title}
                    </div>
                    <div>Goal</div>
                    <div className="trail-segment-completed-steps__description">
                      {x.description}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : (
            <Col span={10} />
          )}
        </Row>
      ))}
      <div className="trail-segment-completed-steps__achievement-segment">
        <img
          src={achievementSegmentIcon}
          className="trail-segment-completed-steps__achievement-bg"
          alt="stars"
        />
        <div style={{ marginTop: '10px' }}>
          Office-based operations completed
        </div>
        <div className="trail-segment-completed-steps__achievement-earned">
          <div className="trail-segment-completed-steps__achievement-earned-title">
            <i className="icon-achievements" />
            <span>Achievement Earned</span>
          </div>
          <div className="trail-segment-completed-steps__achievement-earned-subtitle">
            <img src={wriLogo} alt="Logo white" />
            WRI Office based organization standard
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrailSegmentCompletedSteps;
