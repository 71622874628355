import { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'antd';
import { Title, Paragraph, Select, Icon } from '../../../stories/atoms';
import './reportingDetails.scss';
import { useFormikContext } from 'formik';
import { analytics, getYearDropdownOptions } from '../../../helpers';
import { useMeContext } from '../../../context';
import { COLOURS_GRAY } from '../../../constants/colours';

const monthDropdownOptions = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

function ReportingDetails() {
  const { values, setFieldValue, setValues } = useFormikContext();
  const { me } = useMeContext();

  const [yearDropdownOptions, setYearDropdownOptions] = useState([]);

  function handleSetYearDropdownOptions(startingMonth) {
    const today = new Date();
    const years = getYearDropdownOptions();
    setYearDropdownOptions(
      startingMonth
        ? years.filter(
            (y) =>
              y.value !== today.getFullYear() ||
              startingMonth <= today.getMonth() + 1
          )
        : years
    );
  }
  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      footprintRange: [],
      reportingStartYear: undefined
    }));
    handleSetYearDropdownOptions(values.startingMonth);
  }, [values?.startingMonth]);

  const reportingStartYearDropdownOptions = useCallback(() => {
    const minFootprintYear = Math.min(...values?.footprintRange);
    const years = [...getYearDropdownOptions().reverse()];
    const reportingStartYearOptions = [
      ...years.slice(years.findIndex((year) => year.value === minFootprintYear))
    ];
    return reportingStartYearOptions;
  }, [values?.footprintRange]);

  return (
    <div className="reporting-details__container">
      <Row>
        <Col span={24}>
          <Title size="lg">Your reporting details</Title>
        </Col>
        <Col span={24}>
          <Select
            name="startingMonth"
            title="Start of Fiscal Year"
            value={values?.startingMonth}
            onChange={(value) => {
              setFieldValue('startingMonth', value);
              handleSetYearDropdownOptions(value);
              analytics.track('Carbon Footprint Month Selected', me, {
                level1: 'Office-Based Operations',
                level2: 'Set baseline period',
                level3: 'Carbon footprint month',
                meId: me.id,
                companyId: me.company.id
              });
            }}
            options={monthDropdownOptions}
            placeholder="e.g January"
          />
        </Col>
      </Row>
      <Row className="baseline-year__container">
        <Col span={24}>
          <Title size="md" bottomSpacing={8}>
            Your baseline year
          </Title>
          <Paragraph size="sm">
            Select a baseline year that reflects a typical year for you as we
            use this to measure all your emissions and reductions against.
          </Paragraph>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Select
                mode="multiple"
                name="footprintRange"
                title="Baseline Year"
                value={values?.footprintRange}
                onChange={(value) => {
                  setFieldValue('footprintRange', value);
                  analytics.track('Carbon Footprint Year Selected', {
                    level1: 'Office-Based Operations',
                    level2: 'Set baseline period',
                    level3: 'Carbon footprint year',
                    meId: me.id,
                    companyId: me.company.id
                  });
                }}
                options={yearDropdownOptions}
                disabled={!values?.startingMonth}
                placeholder="e.g 2019"
              />
            </Col>
            <Col xs={24} sm={12}>
              <Select
                name="reportingStartYear"
                title="First reporting year"
                value={values?.reportingStartYear}
                onChange={(value) => {
                  setFieldValue('reportingStartYear', value);
                  window.analytics.track(
                    'Carbon Footprint Reporting Start Year Selected',
                    {
                      level1: 'Office-Based Operations',
                      level2: 'Set baseline period',
                      level3: 'Reporting start year',
                      meId: me.id,
                      companyId: me.company.id
                    }
                  );
                }}
                options={reportingStartYearDropdownOptions()}
                disabled={
                  !values?.startingMonth || !values?.footprintRange?.length
                }
                placeholder="e.g 2020"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} className="covid-19__container">
          <div className="covid-19__wrapper">
            <div className="speakerphone__icon">
              <Icon name="Speakerphone" color="#FBAB1D" />
            </div>
            <div>
              <Title color={COLOURS_GRAY.GRAY_800} size="sm" fontWeight={500}>
                COVID-19 Note
              </Title>
              <Paragraph color={COLOURS_GRAY.GRAY_600} size="sm">
                We are trying to get an accurate understanding of what "business
                as usual" is for you. So ensure you are mindful of the impact of
                COVID-19 has had, if you have been significantly affected, then
                ensure your accounting goes back before COVID so that the
                targets you set later will be achievable.
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ReportingDetails;
