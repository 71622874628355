import { Col, Row } from 'antd';
import { InitiativeContentCard } from '../../../../stories/molecules';

export function InitiativeTemplateCaseStudy({ caseStudyList }) {
  return (
    <Row gutter={[24, 24]}>
      {caseStudyList.map((card) => (
        <Col className="display-flex" span={24}>
          <InitiativeContentCard size="large" card={card} />
        </Col>
      ))}
    </Row>
  );
}
