/** LayoutContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import produce from 'immer';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../routes';

// Create the context
const LayoutContext = createContext({});

// Define the default context state
const defaultLayoutState = {
  currentStep: undefined,
  prevRoute: AppRoutes.HOME,
  completedSteps: undefined,
  startedSteps: undefined,
  data: [],
  routeParams: {}
};

// Create method to use context
function useLayoutContext() {
  const context = useContext(LayoutContext);
  const [layoutState, setLayoutState] = context;
  const { stepsTuple, routeMapping, routeParams } = layoutState;
  const { id: routeIdParam } = routeParams;
  const location = useLocation();
  const navigate = useNavigate();
  const { [routeIdParam]: routeId } = useParams();
  const { pathname, state } = location;
  const { prevRoute } = state ?? {};

  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useLayoutContext must be used within a LayoutContextProvider'
    );
  }

  // Context Methods //
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  const setCurrentStep = (step) => {
    setLayoutState((prevState) =>
      produce(prevState, (draft) => {
        draft.currentStep = step;
      })
    );
  };

  const setCompletedSteps = (steps) => {
    setLayoutState((prevState) =>
      produce(prevState, (draft) => {
        draft.completedSteps = steps;
      })
    );
  };

  const nextStep = () => {
    const currentStepIndex = stepsTuple.indexOf(layoutState.currentStep);
    if (currentStepIndex > -1 && currentStepIndex !== stepsTuple.length - 1) {
      const nextStepValue = stepsTuple[currentStepIndex + 1];
      let navigationRoute;
      Object.entries(routeMapping).forEach(([key, value]) => {
        if (value === nextStepValue) {
          navigationRoute = key.replace(`:${routeId}`, routeId);
        }
      });
      setCurrentStep(stepsTuple[nextStepValue]);
      if (navigationRoute) {
        navigate(navigationRoute);
      }
    }
  };

  const prevStep = () => {
    const currentStepIndex = stepsTuple.indexOf(layoutState.currentStep);
    if (currentStepIndex > -1 && currentStepIndex !== 0) {
      const prevStepValue = stepsTuple[currentStepIndex - 1];
      let navigationRoute;
      Object.entries(routeMapping).forEach(([key, value]) => {
        if (value === prevStepValue) {
          navigationRoute = key.replace(`:${routeId}`, routeId);
        }
      });
      setCurrentStep(prevStepValue);

      if (navigationRoute) {
        navigate(navigationRoute);
      }
    }
  };

  // Navigate the menu directly without using the Next/Back helpers
  const navStep = (url, urlId = routeId) => {
    const pathWithoutParams = !!urlId
      ? url?.replace(urlId, `:${routeIdParam}`)
      : url;
    const step = routeMapping[pathWithoutParams];
    setCurrentStep(step);
    if (url) {
      navigate(url);
    }
  };

  useEffect(() => {
    if (!!prevRoute) {
      setLayoutState((prevState) =>
        produce(prevState, (draft) => {
          draft.prevRoute = prevRoute;
        })
      );
    }
  }, [prevRoute]);

  useEffect(() => {
    if (!layoutState.currentStep) {
      navStep(pathname);
    }
  }, [pathname, layoutState.currentStep]);
  return {
    layoutState,
    setCurrentStep,
    nextStep,
    prevStep,
    navStep,
    setCompletedSteps
  };
}

// Create the context provider
function LayoutContextProvider({
  stepsTuple = [],
  routeMapping = {},
  routeParams = {},
  ...props
}) {
  const [layoutState, setLayoutState] = useState({
    ...defaultLayoutState,
    stepsTuple,
    routeMapping,
    routeParams
  });
  const value = useMemo(() => [layoutState, setLayoutState], [layoutState]);
  return <LayoutContext.Provider value={value} {...props} />;
}

export { LayoutContextProvider, useLayoutContext };
