import { createModelSchema, primitive } from 'serializr';

class UserRole {}

createModelSchema(UserRole, {
  name: primitive(),
  permissionType: primitive()
});

export default UserRole;
