import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '../../../../components';
import {
  AGGREGATE_TYPE_OPTIONS,
  TIME_UNIT_OPTIONS
} from '../../../../constants/options';
import { useMeContext } from '../../../../context';
import { useDataContext } from '../../../../context/DataContext';
import {
  analytics,
  capitalizeText,
  getYearDropdownOptions
} from '../../../../helpers';
import { useAsync } from '../../../../hooks';
import { TargetService } from '../../../../services';
import { Button, Select } from '../../../../stories/atoms';

const analyticsContext = {
  level1: 'Data Explorer',
  level2: 'Overview'
};

export function DataChartFilters() {
  const { me, selectCarbonYearRange } = useMeContext();
  const { data, handleSetSelectedDateRange, updateDataFilters, updateData } =
    useDataContext();
  const { filters } = data;
  const { chartDateYear, selectedDateRange, timeUnit, aggregateType } = filters;

  const [selectedTarget, setSelectedTarget] = useState({});
  const [selectedChartType, setChartType] = useState(aggregateType);
  const [selectedTimeUnit, setTimeUnit] = useState(timeUnit);
  const formValues = {
    ...filters,
    aggregateType: selectedChartType,
    timeUnit: selectedTimeUnit
  };
  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];

  const handleTargetSummaryFilter = useCallback(async () => {
    if (
      me.company?.slug &&
      selectedDateRange.rangeStart &&
      selectedDateRange.rangeEnd
    ) {
      const { data: targetSummaries } =
        await TargetService.fetchTargetDetailsSummary({
          companySlug: me.company?.slug,
          rangeStart: selectedDateRange.rangeStart,
          rangeEnd: selectedDateRange.rangeEnd,
          timeUnit
        });

      return targetSummaries;
    }
    return [];
  }, [
    me.company?.slug,
    selectedDateRange.rangeEnd,
    selectedDateRange.rangeStart,
    timeUnit
  ]);

  const { value: targetSummaries } = useAsync({
    asyncFunction: handleTargetSummaryFilter,
    defaultValue: []
  });
  const targetDropdownOptions = [
    { label: 'No Target', value: -1 },
    ...(targetSummaries ?? []).map((t) => ({
      label: capitalizeText(t.name),
      value: t.name
    }))
  ];

  const handleFilterChart = () => {
    analytics.track('Run Report Clicked', me, analyticsContext);
    let newSelectedDateRange = selectCarbonYearRange({
      activeYear: chartDateYear
    });

    if (chartDateYear === -1) {
      newSelectedDateRange = {
        rangeStart: new Date('2006-01-01'),
        rangeEnd: new Date()
      };
    }
    updateData({
      isDataFiltersInit: true,
      filters: {
        aggregateType: selectedChartType,
        visibleTarget: selectedTarget,
        timeUnit: selectedTimeUnit,
        selectedDateRange: newSelectedDateRange
      }
    });
  };

  const handleUpdateFilter = (key, value) => {
    switch (key) {
      case 'timeUnit': {
        setTimeUnit(value);
        break;
      }
      case 'visibleTarget': {
        setSelectedTarget(
          targetSummaries.find((td) => td.name === value) ?? {}
        );
        break;
      }
      case 'aggregateType': {
        setChartType(value);
        break;
      }
      default:
        updateDataFilters({ [key]: value });
        break;
    }
  };

  useEffect(() => {
    handleSetSelectedDateRange(chartDateYear);
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleFilterChart}>
      {({ values }) => (
        <Form>
          <Row justify="space-between" align="bottom" gutter={16}>
            <Col flex="1">
              <Row gutter={[16, 16]} align="middle">
                <Col flex="20%">
                  <Select
                    title="Year"
                    name="chartDateYear"
                    options={footprintRangeDropdownOptions}
                    placeholder="Current Fiscal Year"
                    value={values.chartDateYear}
                    disableForm
                    onChange={(val) => handleUpdateFilter('chartDateYear', val)}
                  />
                </Col>
                <Col flex="20%">
                  <Select
                    name="aggregateType"
                    options={AGGREGATE_TYPE_OPTIONS}
                    value={values.aggregateType}
                    disableForm
                    onChange={(val) => handleUpdateFilter('aggregateType', val)}
                    title="Display"
                  />
                </Col>
                <Col flex="20%">
                  <Select
                    title="Target"
                    name="visibleTarget"
                    options={targetDropdownOptions}
                    placeholder="No Target Selected"
                    disableForm
                    onChange={(val) => handleUpdateFilter('visibleTarget', val)}
                    value={values.selectedTarget}
                  />
                </Col>
                <Col flex="20%">
                  <Select
                    title="View by"
                    options={TIME_UNIT_OPTIONS}
                    disableForm
                    onChange={(val) => handleUpdateFilter('timeUnit', val)}
                    value={values.timeUnit}
                    name="timeUnit"
                  />
                </Col>
                <Col flex="20%" className="mt-6">
                  <Checkbox
                    name="showMonthsWithoutData"
                    title="View months without data"
                    checked={values.showMonthsWithoutData}
                    disableForm
                    onChange={(val) =>
                      handleUpdateFilter('showMonthsWithoutData', val)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button htmlType="onSubmit">Run Report</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
