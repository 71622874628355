import { Col, Row, Statistic } from 'antd';
import classNames from 'classnames';
import { StatisticGroup } from '../../../../components';
import { useMeContext } from '../../../../context';
import './TargetSettingOverviewSummary.scss';

export function TargetSettingOverviewSummary({
  emissionsSum,
  className,
  showSummaryItems
}) {
  const { me } = useMeContext();
  return (
    <div
      className={classNames('target-setting-overview-summary', {
        [className]: !!className,
        dark: !showSummaryItems
      })}
    >
      <StatisticGroup>
        <Row>
          <Col span={12}>
            <Statistic
              className="text-center"
              groupSeparator=""
              title="Year"
              value={me?.company?.baseYear}
            />
          </Col>
          <Col span={12}>
            <Statistic
              className="text-center"
              title="Base Year Footprint"
              value={`${emissionsSum} tonnes of CO2e`}
            />
          </Col>
        </Row>
      </StatisticGroup>
    </div>
  );
}
