import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { Input } from '../../../../stories/atoms';

export function EditFields() {
  const { setFieldValue, values } = useFormikContext();
  const { name } = values;

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Input
          value={name}
          title="Name"
          name="name"
          setFieldValue={setFieldValue}
        />
      </Col>
    </Row>
  );
}
