const AppRoutes = Object.freeze({
  HOME: '/',
  SUBSCRIPTION: '/subscription',
  TERMS: '/terms',
  AUTH: '/auth',
  HELP: '/help',
  CONSULTANTS: '/consultants',
  LOGIN: '/login',
  SELECT_COMPANY: '/select-company',
  VERIFY_EMAIL: '/verify',
  CREATE_EMAIL: '/create-email',
  CHECK_EMAIL: '/check-email',
  COMPANY_INFORMATION: '/company-information',
  CREDITS: '/credits',
  CREATE_ACCOUNT: '/create-account',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onboarding',
  ONBOARDING_ADMIN: '/onboarding/admin',
  ONBOARDING_ADMIN_LOCATION: '/trails-segments/office-onboarding',
  ONBOARDING_ADMIN_LOCATION_OVERVIEW:
    '/onboarding/admin/office-onboarding/overview',
  ONBOARDING_ADMIN_LOCATION_ADDRESS:
    '/onboarding/admin/office-onboarding/office-location',
  ONBOARDING_ADMIN_LOCATION_ADDRESS_EDIT:
    '/onboarding/admin/office-onboarding/office-location/:collectionId',
  ONBOARDING_ADMIN_LOCATION_OFFICE:
    '/onboarding/admin/office-onboarding/office-details',
  ONBOARDING_ADMIN_LOCATION_OFFICE_DETAILS:
    '/onboarding/admin/office-onboarding/office-details/:collectionId',
  ONBOARDING_ADMIN_LOCATION_BILLS:
    '/onboarding/admin/office-onboarding/energy-usage',
  ONBOARDING_ADMIN_LOCATION__MANAGE_BILLS:
    '/onboarding/admin/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  ONBOARDING_ADMIN_LOCATION__MANAGE_BILLS_ENERGY_TYPE:
    '/onboarding/admin/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  ONBOARDING_ADMIN_INVITE_EMPLOYEES: '/onboarding/admin/invite-employees',
  ONBOARDING_ADMIN_INVITE_TRAVEL_EXPENSE: '/onboarding/admin/travel-expense',
  ONBOARDING_ADMIN_SOURCES: '/onboarding/admin/sources',
  ONBOARDING_ADMIN_TARGET_SETTING: '/onboarding/admin/target-setting',
  ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW:
    '/onboarding/admin/target-setting/overview',
  ONBOARDING_ADMIN_TARGET_SETTING_BASELINE:
    '/onboarding/admin/target-setting/baseline',
  ONBOARDING_ADMIN_TARGET_SETTING_REDUCTIONS:
    '/onboarding/admin/target-setting/reductions/:targetDetailsId',
  ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY:
    '/onboarding/admin/target-setting/strategy/:targetDetailsId',
  ONBOARDING_ADMIN_TARGET_SETTING_DATA:
    '/onboarding/admin/target-setting/data/:targetDetailsId',
  ONBOARDING_ADMIN_COMPLETE: '/onboarding/admin/complete',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING: '/onboarding/admin/office-onboarding',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_OVERVIEW:
    '/onboarding/admin/office-onboarding/overview',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION:
    '/onboarding/admin/office-onboarding/office-location',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION_EDIT:
    '/onboarding/admin/office-onboarding/office-location/:collectionId',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_OFFICE:
    '/onboarding/admin/office-onboarding/office-details',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_OFFICE_DETAILS:
    '/onboarding/admin/office-onboarding/office-details/:collectionId',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_BILLS:
    '/onboarding/admin/office-onboarding/energy-usage',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS:
    '/onboarding/admin/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS_ENERGY_TYPE:
    '/onboarding/admin/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  ONBOARDING_EMPLOYEE: '/onboarding/employee',
  ONBOARDING_EMPLOYEE_OVERVIEW: '/onboarding/employee/overview',
  ONBOARDING_EMPLOYEE_DETAILS: '/onboarding/employee/details',
  ONBOARDING_EMPLOYEE_DETAILS_COMMUTE: '/onboarding/employee/details/commute',
  ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH:
    '/onboarding/employee/details/commute/start-finish-address',
  ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH_EDIT:
    '/onboarding/employee/details/commute/start-finish-address/:commuteId',
  ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS:
    '/onboarding/employee/details/commute/steps/:commuteId',
  ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_DETAILS:
    '/onboarding/employee/details/commute/details/:commuteId',
  ONBOARDING_EMPLOYEE_DETAILS_HOME:
    '/onboarding/employee/details/work-from-home',
  ONBOARDING_EMPLOYEE_DETAILS_HOME_EDIT:
    '/onboarding/employee/details/work-from-home/:commuteId',
  ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL: '/onboarding/employee/business-travel',
  ONBOARDING_EMPLOYEE_YOUR_IMPACT: '/onboarding/employee/your-impact',
  ACTIONS: '/actions',
  TRAILS_OVERVIEW: '/trails-overview',
  ACHIEVEMENTS: '/achievements',
  USER_PROFILE: '/profile',
  USER_COMPANY: '/company',
  EXTERNAL_DASHBOARD: '/external-dashboard',
  TRAILS_SEGMENTS: '/trails-segments',
  OFFICE_ONBOARDING: '/trails-segments/office-onboarding',
  OFFICE_ONBOARDING_OVERVIEW: '/trails-segments/office-onboarding/overview',
  OFFICE_ONBOARDING_LOCATION:
    '/trails-segments/office-onboarding/office-location',
  OFFICE_ONBOARDING_LOCATION_EDIT:
    '/trails-segments/office-onboarding/office-location/:collectionId',
  OFFICE_ONBOARDING_OFFICE: '/trails-segments/office-onboarding/office-details',
  OFFICE_ONBOARDING_OFFICE_DETAILS:
    '/trails-segments/office-onboarding/office-details/:collectionId',
  OFFICE_ONBOARDING_BILLS: '/trails-segments/office-onboarding/energy-usage',
  OFFICE_ONBOARDING_MANAGE_BILLS:
    '/trails-segments/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  OFFICE_ONBOARDING_MANAGE_BILLS_ENERGY_TYPE:
    '/trails-segments/office-onboarding/energy-usage/:collectionId/:energyDetailsId/:energyTypeId',
  EMPLOYEE_COMMUTE: '/trails-segments/employee-commute',
  EMPLOYEE_COMMUTE_OVERVIEW: '/trails-segments/employee-commute/overview',
  EMPLOYEE_COMMUTE_INVITE_TEAM: '/trails-segments/employee-commute/invite-team',
  EMPLOYEE_COMMUTE_MANAGE_EMPLOYEES:
    '/trails-segments/employee-commute/manage-employees',
  EMPLOYEE_COMMUTE_ADDRESS_NEW:
    '/trails-segments/employee-commute/start-finish-address',
  EMPLOYEE_COMMUTE_ADDRESS_EDIT:
    '/trails-segments/employee-commute/start-finish-address/:commuteId',
  EMPLOYEE_COMMUTE_STEPS_NEW: '/trails-segments/employee-commute/commute-steps',
  EMPLOYEE_COMMUTE_DETAILS_NEW:
    '/trails-segments/employee-commute/commute-details',
  EMPLOYEE_COMMUTE_STEPS:
    '/trails-segments/employee-commute/commute-steps/:commuteId',
  EMPLOYEE_COMMUTE_DETAILS:
    '/trails-segments/employee-commute/commute-details/:commuteId',
  BUSINESS_TRAVEL_OVERVIEW: '/trails-segments/travel-expenses-overview',
  TARGET_SETTING: '/trails-segments/target-setting',
  TARGET_SETTING_OVERVIEW: '/trails-segments/target-setting/overview',
  TARGET_SETTING_BASELINE: '/trails-segments/target-setting/baseline',
  TARGET_SETTING_REDUCTIONS:
    '/trails-segments/target-setting/reductions/:targetDetailsId',
  TARGET_SETTING_STRATEGY:
    '/trails-segments/target-setting/strategy/:targetDetailsId',
  TARGET_SETTING_DATA: '/trails-segments/target-setting/data/:targetDetailsId',
  CUSTOM_SOURCES_TRAIL: '/trails-segments/sources',
  TRAIL_COMPLETE: '/trails-segments/complete',
  SOURCES: '/sources',
  SOURCES_OVERVIEW: '/sources/overview',
  SOURCES_DETAILS: '/sources/:emissionSourceDetailsId',
  SOURCES_LOCATION_DETAILS: '/sources/location/:collectionId',
  SOURCES_LOCATION_DETAILS_EDIT: '/sources/location/:collectionId/edit',
  EMPLOYEE_DETAILS_WFH_EDIT:
    '/trails-segments/employee-commute/work-from-home/:commuteId',
  EMPLOYEE_DETAILS_WFH_NEW: '/trails-segments/employee-commute/work-from-home',
  VERIFICATION: '/verification',
  INTEGRATION_DETAIL: '/integration/:parentIntegrationSlug/:integrationSlug',
  CHC_ADMIN: '/carbonhound-connect/admin',
  CHC_ADMIN_COMPANY_DETAILS: '/carbonhound-connect/admin/:companySlug',
  DATA_IMPORTS: '/data-import',
  INITIATIVE: '/initiatives',
  INITIATIVE_TEMPLATE: '/initiatives/template/:initiativeTemplateId',
  COMPANY_INITIATIVE_ROOT: '/initiatives/detail',
  COMPANY_INITIATIVE_BASELINE:
    '/initiatives/detail/:companyInitiativeId/baseline',
  COMPANY_INITIATIVE: '/initiatives/detail/:companyInitiativeId',
  COMPANY_INITIATIVE_BUSINESS_PLAN:
    '/initiatives/detail/:companyInitiativeId/business-plan',
  COMPANY_INITIATIVE_FUNDING:
    '/initiatives/detail/:companyInitiativeId/funding',
  COMPANY_INITIATIVE_RECOMMENDED_ACTIONS:
    '/initiatives/detail/:companyInitiativeId/recommended-actions',
  COMPANY_INITIATIVE_IMPACTED_SOURCES:
    '/initiatives/detail/:companyInitiativeId/impacted-sources',
  DATA_EXPLORER: '/data/explorer',
  BUSINESS_UNIT: '/businessUnit',
  BUSINESS_UNIT_DETAILS: '/businessUnit/:collectionId',
  COLLECTION_DETAILS: '/collection/:collectionId'
});

export default AppRoutes;
