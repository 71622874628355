export const CollectionType = {
  LOCATION: 'LOCATION',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  COLLECTION: 'COLLECTION'
};

export const CollectionRoute = {
  [CollectionType.BUSINESS_UNIT]: 'businessUnit',
  [CollectionType.COLLECTION]: 'collection'
};

export const CollectionDetailsRoute = {
  [CollectionType.BUSINESS_UNIT]: '/businessUnit/:collectionId',
  [CollectionType.COLLECTION]: '/collection/:collectionId'
};
