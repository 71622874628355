import { Space } from 'antd';
import { useState } from 'react';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS
} from '../../constants';
import { useMeContext, useOfficeOnboardingContext } from '../../context';
import { analytics } from '../../helpers';
import { Button } from '../../stories/atoms';
import {
  CarbonhoundConnectCta,
  CompletionTimeline
} from '../../stories/molecules';
import { DataImportButton } from '../../stories/molecules/DataImportButton';
import EnergyItemForm from '../EnergyItemForm';
import EnergyItemList from '../EnergyItemList';
import { Card, Modal } from '../index';
import './energyUsage.scss';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { useModal } from '../../hooks';
import DataRequestTemplate from '../../stories/molecules/DataRequestTemplate';

function EnergyUsage({
  timeline,
  energyDetailsId,
  energyTypeId,
  energyItemList,
  paginationParams,
  total,
  onSuccess,
  analyticsContext,
  analyticsSaveContext,
  activeEnergyType,
  collectionId
}) {
  const [showEnergyForm, setShowEnergyForm] = useState(false);
  const { handleFetchEnergies } = useOfficeOnboardingContext();
  const { me, isAdmin } = useMeContext();
  const handleToggleEnergyForm = () => {
    if (!showEnergyForm) {
      analytics.track('Add new Selected', me, {
        energyTypeId,
        energyDetailsId,
        ...analyticsContext
      });
    }
    setShowEnergyForm(!showEnergyForm);
  };

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  const handleFormSuccess = () => {
    analytics.track('Save item clicked', me, {
      energyTypeId,
      energyItem: null, // TODO: we should have this but idk where
      energyDetailsId,
      ...analyticsSaveContext
    });
    handleToggleEnergyForm();
    onSuccess?.();
  };

  const dataImportSettings = {
    onSuccess,
    notificationReqParams: {
      collectionId,
      energyDetailsId
    },
    analyticsContext,
    internalSpec: EMISSION_SOURCE_INTERNAL_SPECS.ENERGY,
    notification: {
      details: {
        uploadType: BulkUploadType.ENERGY
      }
    }
  };
  return (
    <div className="energy-usage">
      <div className="energy-usage__data-coverage-chart">
        <Card>
          <CompletionTimeline timeline={timeline} />
        </Card>
      </div>
      <CarbonhoundConnectCta
        integration={activeEnergyType?.integration}
        emissionSourceDetailsId={activeEnergyType?.emissionSourceDetailsId}
        isSourceLevel
        onSuccess={handleFetchEnergies}
      />
      <div className="energy-usage__tab-wrapper mt-8">
        <div className="energy-usage__add-button-wrapper justify-end">
          <Space>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={handleShowTemplateModal}
              className="mr-5"
            >
              Data Request Template
            </Button>
            {isAdmin && <DataImportButton {...dataImportSettings} />}
            <Button prefixIcon="Plus" onClick={handleToggleEnergyForm}>
              Add New
            </Button>
          </Space>
        </div>
        <EnergyItemList
          paginationParams={paginationParams}
          total={total}
          energyDetailsId={energyDetailsId}
          timeline={timeline}
          energyTypeId={energyTypeId}
          completed
          energyItemList={energyItemList}
          onSuccess={onSuccess}
          analyticsContext={analyticsContext}
          analyticsSaveContext={analyticsSaveContext}
        />
        <DataRequestTemplateModal title="Data request template">
          <DataRequestTemplate />
        </DataRequestTemplateModal>
        <Modal
          width="60%"
          visible={showEnergyForm}
          onCancel={handleToggleEnergyForm}
        >
          <EnergyItemForm
            energyDetailsId={energyDetailsId}
            timeline={timeline}
            energyTypeId={energyTypeId}
            onSuccess={handleFormSuccess}
            onCancel={handleToggleEnergyForm}
          />
        </Modal>
      </div>
    </div>
  );
}

export default EnergyUsage;
