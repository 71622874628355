import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import React from 'react';
import { Card, Loader } from '../../../components';
import { useEmployeeCommuteContext, useMeContext } from '../../../context';
import { getTimeFormat } from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { EmployeeService } from '../../../services';
import { DatePicker, Paragraph, Title } from '../../../stories/atoms';

function EmployeeInformation({ onSuccess }) {
  const { me, updateCompany, updateLoggedInAs } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInAsUserId, startDate: loggedInAsStartDate } =
    loggedInAs ?? {};
  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );
  const { employeeCommuteDetails, setProfile } = useEmployeeCommuteContext();
  const { profile, isDetailsLoading } = employeeCommuteDetails;
  const handleChangeEmployeeStartDate = async (values) => {
    const updateMethod = loggedInAsUserId
      ? EmployeeService.updateEmployee
      : EmployeeService.updateEmployeeMe;
    await updateMethod({
      companySlug: me.company?.slug,
      startDate: formatInTimeZone(new Date(values?.date), 'UTC', 'yyyy-MM-dd'),
      employeeId: loggedInAsUserId
    });
    const startDate = formatInTimeZone(
      new Date(values?.date),
      'UTC',
      dateDisplayFormat
    );
    setProfile({
      company: { startDate }
    });
    if (!!loggedInAs) {
      updateLoggedInAs({
        loggedInAs: { ...loggedInAs, startDate }
      });
    } else {
      updateCompany({
        startDate
      });
    }

    onSuccess?.();
  };
  const { isLoading: isSavingProfile, execute } = useAsync({
    asyncFunction: handleChangeEmployeeStartDate,
    immediate: false
  });

  const startDate = !!loggedInAsUserId
    ? loggedInAsStartDate
    : profile?.company?.startDate;
  return (
    <Card>
      <Row gutter={[24, 40]} align="middle">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Title bottomSpacing={4} size="rg">
            My company start date
          </Title>
          <Paragraph bottomSpacing={0}>
            You only need to add data for your company's baseline years
          </Paragraph>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Formik
            initialValues={{
              date: !!startDate
                ? formatInTimeZone(new Date(startDate), 'UTC', 'yyyy-MM-dd')
                : undefined
            }}
            onSubmit={execute}
            enableReinitialize
          >
            {({ values, submitForm, setFieldValue }) => (
              <Form>
                <Loader isLoading={isSavingProfile || isDetailsLoading}>
                  <DatePicker
                    defaultValue={values?.date}
                    name="date"
                    onChange={(date) => {
                      setFieldValue('date', date);
                      submitForm();
                    }}
                  />
                </Loader>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Card>
  );
}

export default EmployeeInformation;
