import React from 'react';
import { Popover } from '../../../components';
import { CollectionType } from '../../../constants';
import { useMeContext } from '../../../context';
import CollectionSettings from '../../../features/Collections/CollectionsSettings';
import EmissionSourceDetailList from '../../../features/EmissionSource/EmissionSourceDetail/EmissionSourceDetailList';
import EmissionSourceLocationList from '../../../features/EmissionSource/EmissionSourceLocation/EmissionSourceLocationList';
import { IntensityMetricTab } from '../../../features/IntensityMetric/IntensityMetricTab';
import { analytics } from '../../../helpers';
import { Anchor, Tabs, Title } from '../../../stories/atoms';
import Page from '../../../stories/atoms/Page';
import './EmissionSourceOverview.scss';

const tabItems = [
  { label: 'Locations', key: '1', children: <EmissionSourceLocationList /> },
  { label: 'Sources', key: '2', children: <EmissionSourceDetailList /> },
  {
    label: 'Business Units',
    key: '3',
    children: <CollectionSettings type={CollectionType.BUSINESS_UNIT} />
  },
  {
    label: 'Collections',
    key: '4',
    children: <CollectionSettings type={CollectionType.COLLECTION} />
  },
  {
    label: 'Intensity Metrics',
    key: '5',
    children: <IntensityMetricTab />
  }
];
function EmissionSourceOverview() {
  const { me } = useMeContext();
  return (
    <Page className="emission-source-overview">
      <Title size="xl" className="mb-8">
        Sources
        <Popover
          title="Sources"
          readMoreLink={
            <Anchor
              showLinkIcon={false}
              fontSize={12}
              fontWeight={600}
              href="//carbonhound.notion.site/How-do-I-create-a-custom-source-cdb5c4add34a448ab35cf788b14beb72?pvs=4">
              Help Docs
            </Anchor>
          }
          content={
            <span>
              <p>
                This is a physical source or process that releases greenhouse
                gas into the air.
              </p>
              <p>
                If you want to learn more about the emission admin go to our
                help docs.
              </p>
            </span>
          }
        />
      </Title>
      <Tabs
        items={tabItems}
        onChange={(value) => {
          analytics.track('Tab Clicked', me, {
            tab: value === 1 ? 'Locations' : 'Sources',
            level1: 'Emission Sources',
            level2: 'Overview'
          });
        }}
      />
    </Page>
  );
}
export default EmissionSourceOverview;
