import classNames from 'classnames';
import './Label.scss';

function Label({ children, className, ...props }) {
  return (
    <label
      className={classNames('ch-label', {
        [className]: !!className
      })}
      {...props}
    >
      {children}
    </label>
  );
}
export default Label;
