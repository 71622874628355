import moment from 'moment';
import { EnergyTypeConstants } from '../constants';

class Energy {
  energyTypes = [];

  energyTypeIds = [];

  isElectricitySelected = false;

  disableEnergyTypes = false;

  isEnergyTypeUnknown = false;

  setFormValues() {
    this.disableEnergyTypes = this.isEnergyTypeUnknown;
    const energyTypeIds = [];
    if (this.energyTypes) {
      this.energyTypes = this.energyTypes.filter(
        (energyType) => energyType.energyType
      );
      this.energyTypes.forEach((energyType) => {
        energyTypeIds.push(energyType.energyTypeId);
        if (energyType.energyType === EnergyTypeConstants.ELECTRICITY) {
          this.isElectricitySelected = true;
          this.isGreenEnergyProgrammeSubscribed =
            energyType.hasGreenEnergyProgram;
          this.electricityEnergyPercentage =
            energyType.greenEnergyProgramPercent;
          if (energyType.greenEnergyProgramStartDate) {
            this.electricityStartMonth = Number(
              moment.utc(energyType.greenEnergyProgramStartDate).format('MM')
            );
            this.electricityStartYear = Number(
              moment.utc(energyType.greenEnergyProgramStartDate).format('YYYY')
            );
          }
        }
      });
      this.energyTypeIds = energyTypeIds;
    } else {
      this.energyTypes = [];
    }
  }
}

export default Energy;
