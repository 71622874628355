import React from 'react';
import { Card } from '../../../../../components';
import { COLOURS_GRAY } from '../../../../../constants/colours';
import { useInitiativeContext, useMeContext } from '../../../../../context';
import { analytics } from '../../../../../helpers';
import { formatDecimal } from '../../../../../helpers/formatters';
import {
  getCollectionLocation,
  getEmissionSourceDetailsName,
  getFormattedAddress
} from '../../../../../helpers/generators';
import { useAsync } from '../../../../../hooks';
import { ButtonIcon, Empty, Table } from '../../../../../stories/atoms';

export function InitiativeTemplateImpactedSourcesTable({ analyticsContext }) {
  const {
    handleLoadInitiativeImpactedServices,
    initiativeState,
    initiativeImpactedSourcesPagination,
    handleRemoveRelatedSource
  } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { me } = useMeContext();
  const {
    companyInitiativeImpactedSources,
    companyInitiativeImpactedSourcesCount
  } = initiativeState;

  const { isLoading } = useAsync({
    asyncFunction: handleLoadInitiativeImpactedServices
  });

  const removeSourceWithAnalytics = async (id) => {
    analytics.track('Delete item clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
    handleRemoveRelatedSource(id);
  };

  const { isLoading: isRemovingSource, execute: removeSource } = useAsync({
    asyncFunction: removeSourceWithAnalytics,
    immediate: false
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, source) => getEmissionSourceDetailsName(source)
    },
    {
      title: 'Location',
      render: (_, source) => {
        let location;
        if (source.collections?.length) {
          location = getCollectionLocation(source)?.location;
        }
        return getFormattedAddress({ location, name: location?.name });
      }
    },
    {
      title: '2021 Impact (kg CO2e)',
      dataIndex: 'totalEmissions',
      render: (totalEmissions) => formatDecimal(totalEmissions)
    },
    {
      dataIndex: 'id',
      width: '24px',
      render: (id) => (
        <ButtonIcon
          onClick={() => {
            removeSource(id);
          }}
          isLoading={isRemovingSource}
          name="MinusCircle"
          color={COLOURS_GRAY.GRAY_400}
          backgroundColour="transparent"
        />
      )
    }
  ];
  return (
    <Card>
      <Table
        isLoading={isLoading}
        dataSource={companyInitiativeImpactedSources}
        pagination={initiativeImpactedSourcesPagination}
        showPagination
        columns={columns}
        total={companyInitiativeImpactedSourcesCount}
        locale={{
          emptyText: (
            <Empty
              title="No sources selected"
              description="Selecting sources will allow you to measure your reductions against existing emissions."
            />
          )
        }}
      />
    </Card>
  );
}
