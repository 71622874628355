import { Col, Dropdown, Menu, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../context';
import { getFullName } from '../../../helpers';
import { AppRoutes } from '../../../routes';
import { AuthService } from '../../../services';
import ChLogoIconText from '../../../stories/assets/svgs/ChLogoIconText';
import { Button, Icon } from '../../../stories/atoms';
import { SubscriptionStatusIndicator } from '../../../stories/molecules';
import './SubscriptionHeader.scss';

export function SubscriptionHeader({ subscriptionStatus }) {
  const { me } = useMeContext();
  const { company } = me;
  const fullName = getFullName(me);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { clearUser } = useMeContext();

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    AuthService.logout(() => {
      clearUser();
      navigate(AppRoutes.LOGIN);
    });
  };

  return (
    <Row className="subscription-header" align="middle">
      <Col className="display-flex align-center">
        <ChLogoIconText height={37} width={135} />
        {company?.id && (
          <SubscriptionStatusIndicator
            subscriptionStatus={subscriptionStatus}
          />
        )}
      </Col>
      <Col flex={1} className="display-flex justify-end">
        <Dropdown
          className="chc-admin-header-menu"
          trigger="click"
          onVisibleChange={handleMenuClick}
          overlay={
            <Menu>
              <Menu.Item onClick={handleLogout}>
                <span className="display-flex align-center">
                  <Icon className="mr-2" name="Logout" /> Logout
                </span>
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            className="chc-admin-header-menu__toggle"
            onClick={handleMenuClick}
            icon={isMenuOpen ? 'ChevronUp' : 'ChevronDown'}
            prefixIcon="User"
          >
            {fullName}
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
}
