import { Select } from 'antd';
import { ErrorMessage, Field } from 'formik';
import { disableAutocomplete } from '../../helpers';
import Error from '../Error';
import './dropdownField.scss';

function DropdownField({
  name,
  title,
  setFieldValue,
  optionLabelProp,
  options,
  placeHolder,
  onChange,
  value,
  showSearch,
  onSearch,
  onSelect,
  disabled,
  showArrow = true,
  mode,
  loading = false,
  defaultValue,
  className,
  defaultOpen,
  suffixIcon,
  disableForm,
  children,
  ...props
}) {
  const handleChange = (value) => {
    if (setFieldValue) setFieldValue(name, value);
  };

  const handleFilter = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return !disableForm ? (
    <Field name={name}>
      {({ _field, form: { _touched, _errors, setFieldTouched }, _meta }) => (
        <div className={`dropdown-field ${className}`}>
          {title && <div className="dropdown-field__title">{title}</div>}
          <Select
            autoComplete="none"
            defaultOpen={defaultOpen}
            defaultValue={defaultValue}
            disabled={disabled}
            dropdownMatchSelectWidth={false}
            filterOption={showSearch ? handleFilter : undefined}
            loading={loading}
            mode={mode}
            onBlur={() => setFieldTouched(name)}
            onChange={onChange || handleChange}
            onFocus={disableAutocomplete}
            onSearch={onSearch || undefined}
            onSelect={onSelect || undefined}
            optionLabelProp={optionLabelProp}
            options={options}
            placeholder={placeHolder}
            showArrow={showArrow}
            showSearch={showSearch}
            suffixIcon={suffixIcon}
            value={value}
            {...props}
          >
            {children}
          </Select>
          <ErrorMessage name={name}>
            {(message) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        </div>
      )}
    </Field>
  ) : (
    <div className={`dropdown-field ${className}`}>
      {title && <div className="dropdown-field__title">{title}</div>}
      <Select
        suffixIcon={suffixIcon}
        options={options}
        placeholder={placeHolder}
        mode={mode}
        onChange={onChange || handleChange}
        onSearch={onSearch || undefined}
        onSelect={onSelect || undefined}
        showSearch={showSearch}
        optionLabelProp={optionLabelProp}
        filterOption={showSearch ? handleFilter : undefined}
        disabled={disabled}
        showArrow={showArrow}
        dropdownMatchSelectWidth={false}
        value={value}
        loading={loading}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
}

export default DropdownField;
