import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { UnitTypeFamilies } from '../../../../constants';
import { InputNumberField } from '../../../atoms';
import UnitSelect from '../../UnitSelect';

export function DistanceFields({ distanceFieldKey = 'distance' }) {
  const { setFieldValue, values } = useFormikContext();
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <InputNumberField
          placeholder="Enter"
          title="Amount"
          name={distanceFieldKey}
          value={values[distanceFieldKey]}
        />
      </Col>
      <Col span={12}>
        <UnitSelect
          unitFamilyName={UnitTypeFamilies.DISTANCE}
          placeholder="Select"
          title="Unit"
          name="conversionUnitName"
          setFieldValue={setFieldValue}
          value={values.conversionUnitName}
        />
      </Col>
    </Row>
  );
}
