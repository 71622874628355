import { Col, Modal as AntModal, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import {
  GraphicFormCard,
  InfoModalContent,
  InputField
} from '../../../components';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { AuthService } from '../../../services';
import { Button } from '../../../stories/atoms';
import './resetPassword.scss';
import { resetPasswordValidation } from './resetPasswordValidation';

function ResetPassword() {
  const navigate = useNavigate();

  const location = useLocation();

  const [formLoading, setFormLoading] = useState(false);

  const [verificationCode, setVerificationCode] = useState();

  const [formValues] = useState({
    password: '',
    confirmPassword: ''
  });

  const handleSubmit = (values) => {
    setFormLoading(true);
    AuthService.resetPassword(
      {
        ...values,
        verificationCode
      },
      () => {
        AntModal.info({
          className: 'ch-modal',
          icon: null,
          okText: 'Continue',
          onOk: () => {
            navigate(AppRoutes.LOGIN);
          },
          content: (
            <InfoModalContent title="Success" description="Password updated!" />
          )
        });
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const validateVerificationCode = useCallback(async () => {
    const query = new URLSearchParams(location.search);
    const verificationCode = query.get('code');
    if (verificationCode) {
      const data = await AuthService.validateEmail(verificationCode);
      if (!data.isVerified) {
        navigate(`${AppRoutes.CREATE_ACCOUNT}?code=${verificationCode}`);
      }
    }
    setVerificationCode(verificationCode);
  }, [location.search]);

  const { Loader } = useAsync({ asyncFunction: validateVerificationCode });

  return (
    <div className="reset-password">
      <img src={logo} alt="Logo" className="reset-password__logo" />
      <GraphicFormCard>
        <h3 className="text-bd">Set password</h3>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordValidation}
        >
          {({ values, _errors, isValid, _dirty, _setFieldValue }) => (
            <Form>
              <Loader>
                <Row>
                  <Col span={24}>
                    <InputField
                      title="Password"
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      required
                    />
                    {values?.password?.length > 10 && (
                      <div className="reset-password__password-note">
                        {values?.password?.length < 13
                          ? 'Password is okay'
                          : 'Password is strong'}
                      </div>
                    )}
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter Password"
                      required
                    />
                  </Col>
                  <Col span={24} className="text-center">
                    <Button
                      htmlType="submit"
                      disabled={!isValid || formLoading}
                      loading={formLoading}
                    >
                      Set Password
                    </Button>
                  </Col>
                </Row>
              </Loader>
            </Form>
          )}
        </Formik>
      </GraphicFormCard>
    </div>
  );
}

export default ResetPassword;
