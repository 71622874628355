import { Button } from 'antd';
import classNames from 'classnames';
import { Icon } from '../index';
import './ButtonIcon.scss';

function ButtonIcon({
  disabled,
  size = 24,
  scaleIcon = true,
  color,
  name,
  backgroundColour,
  onClick,
  isLoading
}) {
  return (
    <Button
      className={classNames('ch-button-icon', {
        disabled: !!disabled
      })}
      disabled={disabled}
      shape="circle"
      style={{
        backgroundColor: backgroundColour ?? 'transparent',
        minWidth: size,
        height: size
      }}
      onClick={onClick}
      isLoading={isLoading}
    >
      <Icon color={color} size={scaleIcon ? size / 1.2 : size} name={name} />
    </Button>
  );
}
export default ButtonIcon;
