import { Col, DatePicker, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import moment from 'moment';
import React, { useState } from 'react';
import { COLOURS_GRAY } from '../../../../constants/colours';
import DateFormats from '../../../../constants/dateFormats';
import NotificationStatus from '../../../../constants/notificationStatus';
import { useInitiativeActionContext, useMeContext } from '../../../../context';
import { analytics, capitalizeText } from '../../../../helpers';
import { getActionCategoryCopy } from '../../../../helpers/generators';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { Link, Paragraph, Tag, Title } from '../../../../stories/atoms';
import { TAG_COLOURS } from '../../../../stories/atoms/Tag/tag.types';
import OwnerSelect from '../../../../stories/molecules/OwnerSelect';
import './InitiativeActionSidebar.scss';

export function InitiativeActionSidebar({
  onSuccess,
  analyticsContext,
  onClose
}) {
  const {
    initiativeActionState,
    handleEditNotification,
    handleFetchCompanyInitiative,
    isDisabledOrComplete
  } = useInitiativeActionContext();
  const { me } = useMeContext();
  const { initiativeAction, initiativeTemplate, companyInitiative } =
    initiativeActionState;

  const { status, ownerId, category, dueDate } = initiativeAction;
  const { execute: editNotification } = useAsync({
    asyncFunction: handleEditNotification,
    immediate: false
  });
  const [selectedOwnerId, setSelectedOwnerId] = useState(ownerId);
  const title = initiativeTemplate.title || initiativeAction.title;
  const isComplete = status === NotificationStatus.COMPLETE;
  const handleUpdateNotification = async (value, formKey) => {
    if (formKey === 'dueDate') {
      value = formatInTimeZone(new Date(value), 'UTC', DateFormats.API);
    }

    analytics.track('Edit Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative?.id,
      notificationId: initiativeAction?.id,
      editField: formKey
    });
    await editNotification({
      [formKey]: value,
      id: initiativeAction.id
    });
    onSuccess?.();
  };
  useAsync({
    asyncFunction: handleFetchCompanyInitiative
  });

  return (
    <Row className="initiative-action-sidebar">
      <Col span={24}>
        <Title flex={1} bottomSpacing={8} size="sm">
          Title
        </Title>
        <Title bottomSpacing={0} size="rg">
          {title}
        </Title>
      </Col>
      <Col span={24}>
        <Row>
          {!!companyInitiative?.name && (
            <Col span={24} className="initiative-action-sidebar__bordered-row">
              <Row gutter={[16, 12]} align="middle">
                <Col flex={1}>
                  <Title bottomSpacing={0} size="sm">
                    Initiative
                  </Title>
                </Col>
                <Col className="display-flex justify-end text-sbd">
                  <Link
                    fontWeight={500}
                    fontSize={14}
                    to={AppRoutes.COMPANY_INITIATIVE.replace(
                      ':companyInitiativeId',
                      companyInitiative?.id
                    )}
                    onClick={() => {
                      onClose?.();
                    }}
                  >
                    {companyInitiative?.name}
                  </Link>
                </Col>
              </Row>
            </Col>
          )}

          <Col span={24} className="initiative-action-sidebar__bordered-row">
            <Row gutter={[16, 12]} align="middle">
              <Col flex={1}>
                <Title bottomSpacing={0} size="sm">
                  Status
                </Title>
              </Col>
              <Col className="display-flex justify-end">
                <Tag
                  color={
                    isDisabledOrComplete
                      ? TAG_COLOURS.GRAY
                      : status === NotificationStatus.COMPLETE
                      ? TAG_COLOURS.GREEN
                      : TAG_COLOURS.YELLOW
                  }
                >
                  {status ? capitalizeText(status) : 'Inactive'}
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="initiative-action-sidebar__bordered-row">
            <Row gutter={[16, 12]} align="middle">
              <Col span={24}>
                <Title bottomSpacing={0} size="sm">
                  Assignee
                </Title>
              </Col>
              <Col span={24}>
                <OwnerSelect
                  disabled={isDisabledOrComplete}
                  value={selectedOwnerId}
                  disableForm
                  name="ownerId"
                  placeholder="Enter"
                  onChange={(value) => {
                    setSelectedOwnerId(value);
                    handleUpdateNotification(value, 'ownerId');
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="actions-modal-sidebar__bordered-row">
            <Row gutter={[16, 12]}>
              <Col span={24}>
                <Title bottomSpacing={0} size="sm">
                  Due Date
                </Title>
              </Col>
              <Col span={24}>
                <DatePicker
                  disabled={isComplete}
                  defaultValue={dueDate ? moment.utc(dueDate) : undefined}
                  name="dueDate"
                  title="Due date"
                  onChange={(value) => {
                    handleUpdateNotification(value, 'dueDate');
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="initiative-action-sidebar__bordered-row">
            <Row gutter={[16, 12]} align="middle">
              <Col flex={1}>
                <Title bottomSpacing={0} size="sm">
                  Type
                </Title>
              </Col>
              <Col className="display-flex justify-end">
                <Paragraph
                  bottomSpacing={0}
                  size="sm"
                  weight={500}
                  color={<COLOURS_GRAY className="GRAY_600" />}
                >
                  {getActionCategoryCopy(category)}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
