import { Tag as AntTag } from 'antd';
import classNames from 'classnames';
import './Tag.scss';

function Tag({ className, children, color, ...props }) {
  return (
    <AntTag
      className={classNames('ch-tag', {
        [className]: !!className,
        [`tag-${color}`]: !!color
      })}
      {...props}
    >
      {children}
    </AntTag>
  );
}
export default Tag;
