/** MetaContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';
import { IntegrationStatus } from '../constants/status';
import { ChcProviderService, IntegrationService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const CHCContext = createContext({});

// Define the default context state
const carbonhoundConnectState = {
  companyIntegration: {
    id: null,
    integrationName: null,
    status: null,
    settings: null
  },
  companyUtilityProviderId: null
};

// Create method to use context
function useCHCContext() {
  const context = useContext(CHCContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error('useCHCContext must be used within a MetaContextProvider');
  }
  const [CHCState, setCHCState] = context;

  // Context Methods //
  const createCompanyIntegration = async ({
    integrationSlug,
    ...companyIntegrationValues
  }) => {
    try {
      const companyIntegration =
        await IntegrationService.createCompanyIntegration({
          companySlug: me.company?.slug,
          integrationSlug,
          ...companyIntegrationValues
        });
      setCHCState((prevState) => ({
        ...prevState,
        companyIntegration
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const createCompanyCHCProvider = async (companyIntegrationValues) => {
    try {
      const chcProvider = await ChcProviderService.createCompanyCHCProvider({
        companySlug: me.company?.slug,
        status: IntegrationStatus.PENDING_CONNECTION,
        ...companyIntegrationValues
      });

      setCHCState((prevState) => ({
        ...prevState,
        companyUtilityProviderId: chcProvider?.id
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const updateCompanyIntegration = async ({
    integrationSlug,
    utilityProviderId,
    ...companyIntegrationValues
  }) => {
    try {
      const companyIntegration =
        await IntegrationService.updateCompanyIntegration({
          companySlug: me.company?.slug,
          integrationSlug,
          utilityProviderId,
          ...companyIntegrationValues
        });
      setCHCState((prev) => ({
        ...prev,
        companyIntegration: {
          ...prev.companyIntegration,
          ...companyIntegration
        }
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const updateCompanyProvider = async ({
    integrationSlug,
    companyUtilityProviderId,
    ...companyIntegrationValues
  }) => {
    try {
      await IntegrationService.updateCompanyIntegrationProvider({
        companySlug: me.company?.slug,
        integrationSlug,
        companyUtilityProviderId,
        ...companyIntegrationValues
      });
    } catch (error) {
      console.error(error);
    }
  };
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    CHCState,
    createCompanyCHCProvider,
    createCompanyIntegration,
    updateCompanyIntegration,
    updateCompanyProvider
  };
}

// Create the context provider
function CHCContextProvider(props) {
  const [CHCState, setCHCState] = useState(carbonhoundConnectState);
  const value = useMemo(() => [CHCState, setCHCState], [CHCState]);
  return <CHCContext.Provider value={value} {...props} />;
}

export { CHCContextProvider, useCHCContext };
