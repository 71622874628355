import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class ChcAdminService {
  static async listCompanies(onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/list`;
      const response = await AxiosInstance.post(API_URL);
      onSuccess?.(response.data?.companies);
      return response.data?.companies;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getCompany(companySlug, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/${companySlug}`;
      const response = await AxiosInstance.get(API_URL);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompany(
    companySlug,
    company,
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/${companySlug}`;
      const response = await AxiosInstance.put(API_URL, { ...company });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listCompanySources(companySlug, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/${companySlug}/emissionSource/list`;
      const response = await AxiosInstance.post(API_URL);
      onSuccess?.(response.data?.emissionSourceDetails);
      return response.data?.emissionSourceDetails;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listCompanyProviders(companySlug, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/${companySlug}/utilityProvider/list`;
      const response = await AxiosInstance.post(API_URL);
      onSuccess?.(response.data?.utilityProviders);
      return response.data?.utilityProviders;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompanyProvider(
    { companyUtilityProviderId, companySlug, ...provider },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN_COMPANY}/${companySlug}/utilityProvider/${companyUtilityProviderId}`;
      const response = await AxiosInstance.put(API_URL, { ...provider });
      onSuccess?.(response.data?.utilityProviders);
      return response.data?.utilityProviders;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateEmissionSource(
    { emissionSourceDetailsId, _companySlug, ...emissionSource },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_ADMIN}/emissionSource/${emissionSourceDetailsId}`;
      const response = await AxiosInstance.put(API_URL, { ...emissionSource });
      onSuccess?.(response.data?.emissionSources);
      return response.data?.emissionSources;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default ChcAdminService;
