import { Layout } from 'antd';
import classNames from 'classnames';
import './Header.scss';

const { Header: AntHeader } = Layout;

export function Header({ children, className }) {
  return (
    <AntHeader
      className={classNames('ch-layout-header', {
        [className]: !!className
      })}
    >
      {children}
    </AntHeader>
  );
}
