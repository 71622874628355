import * as Yup from 'yup';

export const employeeDetailsWFHValidation = Yup.object().shape({
  homeOfficeSquareFootage: Yup.number()
    .nullable()
    .required('Home office square footage is required'),
  isCurrentCommute: Yup.boolean().nullable(),
  postalCode: Yup.string()
    .nullable()
    .max(10, 'Should not contain more than 10 characters'),
  averageCommuteDaysPerWeek: Yup.number()
    .typeError('Value is not valid')
    .nullable()
    .required('Average number of days is required')
    .min(1, 'Number of days should be between 1 and 7')
    .max(7, 'Number of days should be between 1 and 7'),
  countryId: Yup.string().nullable().required('Country is required'),
  buildingTypeId: Yup.string()
    .nullable()
    .required('Home office address is required'),
  startDate: Yup.string().nullable().required('Start Date is required')
});
