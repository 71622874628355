export const REPORTING_PERIOD_VALUES = {
  ALL: 'ALL',
  CURRENT_CALENDAR_YEAR: 'CURRENT_CALENDAR_YEAR',
  CURRENT_FISCAL_YEAR: 'CURRENT_FISCAL_YEAR',
  CURRENT_QUARTER: 'CURRENT_QUARTER',
  CUSTOM: 'CUSTOM',
  PREVIOUS_CALENDAR_YEAR: 'PREVIOUS_CALENDAR_YEAR',
  PREVIOUS_FISCAL_YEAR: 'PREVIOUS_FISCAL_YEAR',
  PREVIOUS_QUARTER: 'PREVIOUS_QUARTER'
};
