import { Col, Row } from 'antd';
import { Steps } from '../../../../stories/atoms';

export function StepsContainer({ stepProps, onSuccess, successProps = {} }) {
  const { current, steps } = stepProps;

  const CurrentStepComponent = steps[current].content;
  return (
    <Row className="intensity-metric-form-create-step" gutter={[24, 24]}>
      <Col span={24}>
        <Steps
          current={current}
          items={steps}
          labelPlacement="vertical"
          size="small"
        />
      </Col>
      <Col span={24}>
        <CurrentStepComponent onSuccess={() => onSuccess(successProps)} />
      </Col>
    </Row>
  );
}
