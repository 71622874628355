import * as Yup from 'yup';

export const vehicleFormValidation = Yup.object().shape({
  friendlyName: Yup.string().nullable().required('Vehicle name is required'),
  isCompanyOwned: Yup.boolean()
    .nullable()
    .required('Please select any one option'),
  fuelTypeId: Yup.string().nullable().required('Fuel type is required'),
  vehicleTypeId: Yup.string().nullable().required('Vehicle type is required'),
  modelYear: Yup.string().nullable().required('Manufacturing year is required')
});
