import { createModelSchema, list, object, primitive } from 'serializr';
import UserRole from './userRole';

class Company {}

createModelSchema(Company, {
  id: primitive(),
  logo: primitive(),
  name: primitive(),
  userCompanyId: primitive(),
  startDate: primitive(),
  endDate: primitive(),
  role: object(UserRole),
  slug: primitive(),
  footprintRange: list(primitive()),
  startingMonth: primitive(),
  subscription: primitive(),
  baseYear: primitive()
});

export default Company;
