import React, { useCallback } from 'react';
import { Row, Col, Space, Modal as AntModal } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useMeContext } from '../../context';
import { useAsync } from '../../hooks';
import { CollectionService } from '../../services';
import {
  Page,
  PageHeader,
  Button,
  Title,
  Paragraph,
  Icon,
  ButtonIcon
} from '../../stories/atoms';
import { useDrawer } from '../../hooks/useDrawer';
import './businessUnitDetailsPage.scss';
import CollectionEmissionSourcesChart from '../../features/Collections/CollectionEmissionSourcesChart';
import CollectionEmissionSourcesTable from '../../features/Collections/CollectionEmissionSourcesTable';
import { Card } from '../../components';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { AppRoutes } from '../../routes';
import { COLOURS_BW, COLOURS_CARBONHOUND } from '../../constants/colours';
import { analytics } from '../../helpers';
import { collectionSettingsValidation } from '../../features/Collections/CollectionsSettings/collectionsSettingsValidation';
import CollectionSettingsForm from '../../features/Collections/CollectionSettingsForm';
import { CollectionType } from '../../constants';
import { useCollectionContext } from '../../context/CollectionContext';

function BusinessUnitDetails() {
  const { me } = useMeContext();
  const { collectionId } = useParams();
  const {
    collectionState,
    getCollectionId,
    TableLoader,
    fetchChart,
    fetchTable
  } = useCollectionContext();

  const { collectionDetails: businessUnitDetails } = collectionState;

  const navigate = useNavigate();

  const fetchCollectionId = useCallback(async () => {
    getCollectionId(collectionId);
  }, []);
  useAsync({
    asyncFunction: fetchCollectionId
  });

  const {
    Drawer: CollectionsSettingDrawer,
    handleShowDrawer,
    handleCloseDrawer
  } = useDrawer({
    width: '50%'
  });

  const handleSubmitBusinessUnit = async (values) => {
    await CollectionService.updateCollection({
      collectionId,
      name: values.collectionName
    });
    if (values.emissionSourceDetailsIds.length > 0) {
      await CollectionService.createCollectionEmissionSourceBulk({
        collectionId,
        emissionSourceDetailsIds: values.emissionSourceDetailsIds
      });
    }
    fetchChart();
    fetchTable();
    handleCloseDrawer();
  };

  const handleBusinessUnitDelete = async () => {
    await CollectionService.deleteCollection({
      collectionId
    });
    analytics.track('Delete item clicked', me, {
      level1: 'Business Units',
      level2: 'Details',
      level3: 'Edit Modal',
      collectionId
    });
    navigate(AppRoutes.SOURCES_OVERVIEW, { state: { defaultKey: '3' } });
  };

  const showWarningModal = () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Delete Business Unit',
      cancelButtonProps: { style: { display: 'none' } },
      title: (
        <div className="display-flex align-center justify-space-between">
          <Title size="lg" bottomSpacing={0}>
            Delete Business Unit
          </Title>
          <ButtonIcon
            name="Times"
            color={COLOURS_BW.BLACK}
            onClick={AntModal.destroyAll}
          />
        </div>
      ),
      content: (
        <Paragraph size="rg">
          Deleting a business unit will ungroup all of the facilities you have
          assigned to this business unit. The facilities will not be removed.
          This action can’t be undone.{' '}
        </Paragraph>
      ),
      onOk: handleBusinessUnitDelete
    });
  };

  const handleEditBusinessUntDetails = () => {
    analytics.track('Edit Item Clicked', me, {
      level1: 'Business Units',
      level2: 'Details',
      collectionId
    });
    handleShowDrawer();
  };

  const routes = [
    {
      path: AppRoutes?.SOURCES_OVERVIEW,
      breadcrumbName: 'Sources'
    },
    {
      breadcrumbName: businessUnitDetails?.name
    }
  ];

  return (
    <Page className="emission-sources-details">
      <TableLoader>
        <PageHeader
          breadcrumb={{
            routes,
            itemRender: (route) =>
              !!route.path ? (
                <Link
                  key={route.breadcrumbName}
                  to={route.path}
                  state={{ defaultKey: '3' }}
                >
                  {route.breadcrumbName === 'Sources' && (
                    <Icon
                      size={16}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      name="ChevronLeft"
                    />
                  )}
                  {route.breadcrumbName}
                </Link>
              ) : (
                <span key={route.breadcrumbName}>{route.breadcrumbName}</span>
              )
          }}
          title={businessUnitDetails?.name}
          extra={
            <Space>
              <Button onClick={handleEditBusinessUntDetails}>Edit</Button>
            </Space>
          }
        />
        <Row>
          <Col span={24}>
            <Card>
              <CollectionEmissionSourcesChart collectionId={collectionId} />
            </Card>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col span={24}>
            <CollectionEmissionSourcesTable
              type={CollectionType.BUSINESS_UNIT}
            />
          </Col>
        </Row>
      </TableLoader>
      <CollectionsSettingDrawer
        onCancel={handleCloseDrawer}
        buttons={[
          {
            type: BUTTON_TYPES.DELETE,
            children: 'Delete Business Unit',
            onClick: showWarningModal
          },
          {
            htmlType: 'submit',
            children: 'Save'
          }
        ]}
        title="Edit Business Unit"
        formikContext={{
          className: 'actions-form',
          onSubmit: handleSubmitBusinessUnit,
          initialValues: {
            collectionName: businessUnitDetails?.name ?? '',
            emissionSourceDetailsIds: [],
            collectionNameSearch: '',
            collectionId: undefined
          },
          validationSchema: collectionSettingsValidation
        }}
      >
        <CollectionSettingsForm type={CollectionType.BUSINESS_UNIT} />
      </CollectionsSettingDrawer>
    </Page>
  );
}

export default BusinessUnitDetails;
