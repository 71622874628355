function AcademicCap() {
  return (
    <>
      <path d="M11 11L20 6L11 1L2 6L11 11Z" />
      <path d="M11 11L17.16 7.578C17.9705 9.63609 18.2005 11.8772 17.825 14.057C15.2886 14.3032 12.8972 15.3536 11 17.055C9.10305 15.3538 6.71208 14.3034 4.176 14.057C3.8002 11.8772 4.03023 9.63603 4.841 7.578L11 11Z" />
      <path
        d="M11 11L20 6L11 1L2 6L11 11ZM11 11L17.16 7.578C17.9705 9.63609 18.2005 11.8772 17.825 14.057C15.2886 14.3032 12.8972 15.3536 11 17.055C9.10305 15.3538 6.71208 14.3034 4.176 14.057C3.8002 11.8772 4.03023 9.63603 4.841 7.578L11 11ZM7 17V9.5L11 7.278"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
export default AcademicCap;
