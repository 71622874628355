const ApiRoutes = Object.freeze({
  BASE_URL: process.env.REACT_APP_API_URL,
  HOME: '/',
  LOGIN: '/auth/login',
  VALIDATE_EMAIL: '/auth/validateEmailCode',
  SIGNUP: '/auth/signup',
  RESET_PASSWORD: '/auth/resetPassword',
  SEND_RESET_PASSWORD_EMAIL: 'auth/resetPassword/email',
  UPDATE_PASSWORD: '/me/password',
  CHANGE_ACTIVE_COMPANY: '/me/company/change',
  ACCEPT_TERMS: '/me/termsAndConditions/accept',
  COMPANY: '/company',
  COLLECTION_LIST: '/collection/company/:companySlug/list',
  COMPANY_TRAILS: '/company/:companySlug/trail',
  LOCATION: '/location',
  ME: '/me',
  MY_PROFILE: '/me/profile',
  MY_COMPANIES: '/me/company/list',
  MY_TERMS_AND_CONDITIONS: '/me/termsAndConditions',
  INDUSTRY_METAS: '/enums/list/industries',
  COUNTRY_METAS: '/enums/list/countries',
  UNIT_LIST: '/enums/list/units',
  ENERGY_TYPE_METAS: '/enums/list/trail/energyTypes',
  ENERGY_UNIT_METAS: '/enums/list/units/energy',
  FUEL_TYPE_METAS: '/enums/list/trail/fuelTypes',
  BUILDING_TYPE_METAS: '/enums/list/buildingTypes',
  VEHICLE_TYPE_METAS: '/enums/list/trail/vehicleTypes',
  TRANSPORTATION_TYPE_METAS: '/enums/list/trail/transportationTypes',
  DISTANCE_UNIT_METAS: '/enums/list/units/distance',
  TRAVEL_CATEGORY_METAS: '/enums/list/trail/travelCategories',
  TRAVEL_CLASS_METAS: '/enums/list/trail/travelClasses',
  GOOGLE_PLACES_API_URL:
    'https://maps.googleapis.com/maps/api/place/autocomplete/json',
  COMPANY_TRAIL_ENERGIES: '/trail/energy/company/:companySlug/list',
  OFFICE_TRAIL_OVERVIEW_SUMMARY: '/trail/energy/summary/drilldown',
  OFFICE_ENERGY_ACTIVITY_SUMMARY: '/trail/energy/summary/activity',
  OFFICE_ENERGY_TIMELINE_SUMMARY: '/trail/energy/summary/timeline',
  TRAIL_ENERGIES: '/trail/energy',
  TRIAL_ENERGY_COMPANY_LOCATION: '/trail/energy/company/:companySlug/location',
  TRIAL_ENERGY_ITEM_COUNT: '/trail/energy/item/count',
  ENERGY_ITEM: '/trail/energy/:energyDetailsId/item',
  INDIVIDUAL_ENERGY_ITEM: '/trail/energy/item',
  CARBON_TRACKER_CHART: '/trail/emissions/chart',
  EMISSIONS_SUM: '/trail/emissions/sum',
  EMISSIONS_DATA_ACCURACY: '/trail/emissions/accuracy/drilldown',
  RELATIVE_EMISSIONS: '/trail/emissions/relative/month',
  RELATIVE_EMISSIONS_BASELINE: '/trail/emissions/relative/baseline',
  COMMUTE_INVITES_COUNT: '/trail/commute/:companySlug/employee/count',
  COMMUTE_EMPLOYEES: '/trail/commute/:companySlug/employee/list',
  COMMUTE_CREATE_INVITE: '/trail/commute/:companySlug/employee/invite/create',
  COMMUTE_SEND_INVITE: '/trail/commute/:companySlug/employee/invite/send',
  COMMUTE_SEND_INVITE_BULK:
    '/trail/commute/:companySlug/employee/invite/send/bulk',
  COMMUTE_CANCEL_INVITE: '/trail/commute/:companySlug/employee/invite/cancel',
  COMMUTE: '/trail/commute',
  COMMUTE_LIST: '/trail/commute/:companySlug/list',
  COMMUTE_DETAIL: '/trail/commute/:companySlug',
  COMMUTE_ITEM: '/trail/commute/:commuteId/item',
  COMMUTE_VEHICLE: '/trail/commute/:companySlug/vehicle',
  COMMUTE_SUMMARY_TIMELINE: '/trail/commute/:companySlug/summary/timeline',
  CLEAR_COMMUTE_ITEMS: '/trail/commute/:commuteId/item/clear',
  TRAVEL_EXPENSE: '/trail/travel/:companySlug/item',
  TRAVEL_ITEM: '/trail/travel/item',
  TRAVEL_SUMMARY: '/trail/travel/:companySlug/summary/drilldown',
  TRAVEL_EMPLOYEE: '/trail/travel/:companySlug/employee',
  COMPANY_EMPLOYEE: '/company/:companySlug/employee',
  ADD_USER_TO_COMPANY: '/auth/addUserToCompany',
  MY_NOTIFICATION_ACTIONS: '/notification/action/list/me',
  NOTIFICATION_ACTION: '/notification/action',
  NOTIFICATION_ACTION_ITEM: '/notification/:notificationId/action',
  NOTIFICATION_ACTION_DATA_EXPORT: '/notification/action/dataExport',
  NOTIFICATION_UPLOAD: '/notification/:notificationId/upload',
  NOTIFICATION_DOWNLOAD: '/notification/:notificationId/download/authorize',
  NOTIFICATION_OVERVIEW: '/notification/overview/all/me',
  COMMUTE_ALERT: '/trail/commute/:companySlug/commuteAlert',
  LIST_COMPANIES: '/company/list',
  TRAVEL_EMISSION_SUMMARIES: '/trail/travel/summary/drilldown/list',
  TRAVEL_EMISSION_SUMMARIES_COMPANY:
    '/trail/travel/:companySlug/summary/drilldown',
  COMMUTE_EMISSION_SUMMARIES: '/trail/commute/summary/drilldown/list',
  ENERGY_EMISSION_SUMMARIES: '/trail/energy/summary/drilldown/list',
  ENERGY_EMISSION_SUMMARIES_COMPANY: '/trail/energy/summary/drilldown',
  ENERGY_ITEM_HARD_CLEAR: '/trail/energy/:energyDetailsId/item/clear/hard',
  TRAVEL_ITEM_HARD_CLEAR: '/trail/travel/:companySlug/item/clear/hard',
  COMPANY_TARGET: '/company/:companySlug/target',
  EMISSION_SOURCES_LIST: '/emissionSource/:companySlug',
  EMISSION_SOURCES_ITEM_LIST:
    '/emissionSource/:emissionSourceDetailsId/item/list',
  EMISSION_SOURCES_DETAILS: '/emissionSource/:emissionSourceDetailsId',
  EMISSION_SOURCES_DETAILS_TIMELINE:
    '/emissionSource/details/:emissionSourceDetailsId/summary/timeline',
  EMISSION_SOURCES_DETAILS_DRILLDOWN:
    '/emissionSource/details/:esDetailsId/summary/drilldown',
  EMISSION_SOURCE_COLLECTION_SUMMARY_DRILLDOWN:
    '/emissionSource/:companySlug/collection/:collectionId/summary/drilldown',
  EMISSION_SOURCES_ITEM: '/emissionSource/:emissionSourceDetailsId/item',
  EMISSION_SOURCES_CARBON_TRACKER:
    '/emissionSource/:companySlug/summary/drilldown/list',
  EMISSION_SOURCES_ITEM_UPDATE: '/emissionSource/item/:esItemId',
  EMISSION_SOURCES_UPLOAD_CLEAR: '/emissionSource/:detailsId/item/clear/hard',
  EMISSION_SOURCES_IMPORT_LIST: '/emissionSource/:companySlug/import/list',
  EMISSION_FACTORS: '/emissionFactor/:companySlug',
  EMISSION_FACTORS_COLLECTION_LIST:
    '/emissionFactor/:efDetailsId/collection/list',
  TAGS_LIST: '/tag/:companySlug/list',
  VERIFICATION: '/company/:companySlug/verification',
  INTEGRATION: '/integration',
  COMPANY_INTEGRATION: '/integration/:integrationSlug/company/:companySlug',
  CHC_UTILITY_PROVIDER: '/integration/:integrationSlug/utilityProvider',
  CHC_COMPANY_UTILITY_PROVIDER:
    '/integration/carbonhound-connect/company/:companySlug/utilityProvider',
  CHC_COMPANY_UTILITY_PROVIDER_CONNECTED:
    '/integration/carbonhound-connect/company/:companySlug/utilityProvider/:utilityProviderId',
  CHC_ADMIN_COMPANY: '/integration/carbonhound-connect/admin/company',
  CHC_ADMIN: '/integration/carbonhound-connect/admin',
  PAYMENTS_STRIPE_CUSTOMER_PORTAL:
    '/integration/payments-stripe/company/:companySlug/customer/portal',
  PAYMENTS_STRIPE_PRICES:
    '/integration/payments-stripe/company/:companySlug/price',
  PAYMENTS_STRIPE: '/integration/payments-stripe',
  INITIATIVE_TEMPLATES_ROOT: '/initiative/template',
  INITIATIVE_TEMPLATE_NOTIFICATION:
    '/initiative/template/:initiativeTemplateId/notification/:companyInitiativeId',
  INITIATIVE_COMPANY: '/initiative/company/:companySlug',
  INITIATIVE: '/initiative',
  COMPANY_INITIATIVE: '/initiative/:companyInitiativeId',
  ONBOARDING_COMPANY_GOALS: '/company/:companySlug/settings/climateGoals',
  ONBOARDING_GREEN_TEAM_UPLOAD_BULK:
    '/company/:companySlug/employee/inviteToCompany/bulk',
  COLLECTION_COMPANY: '/collection/company/:companySlug',
  COLLECTION: '/collection',
  CREATE_COLLECTION: '/collection/company/:companySlug/',
  CREATE_COLLECTION_BULK: '/collection/company/:companySlug/bulk',
  COLLECTION_EMISSION_SOURCE:
    '/collection/:collectionId/emissionSource/:emissionSourceDetailsId',
  CREATE_COLLECTION_EMISSION_SOURCE_BULK:
    '/collection/:collectionId/emissionSource/bulk',
  UPDATE_COLLECTION: '/collection/:collectionId',
  REPORTING: '/reporting',
  REPORTING_CHART: '/reporting/company/:companySlug/emissions/chart',
  REPORTING_SUMMARY: '/reporting/company/:companySlug/emissions/drilldown/list',
  COLLECTION_ESTIMATION: '/collection/:collectionId/estimations/process',
  EMISSION_SOURCE_COLLECTION_ESTIMATION_CLEAR:
    '/emissionSource/:emissionSourceDetailsId/estimations/clear',
  GROUP_CONTENT_LIST: '/group/:groupId/content/list',
  GROUP: '/group/:groupId',
  GROUP_SETTINGS: '/auth/group/:groupSlug/settings',
  GROUP_CONTENT_AUTHORIZE: '/group/:groupId/upload/authorize',
  // Trying to get away from this being a giant flat enum
  // Basing routes on BE associated models
  INTENSITY_METRIC_DETAILS: {
    BASE: '/intensityMetric',
    COMPANY_SLUG: '/intensityMetric/:companySlug',
    ID: '/intensityMetric/:intensityMetricDetailsId',
    LIST: '/intensityMetric/:companySlug/list',
    TIMELINE: '/intensityMetric/:intensityMetricDetailsId/summary/timeline'
  },
  INTENSITY_METRIC_ITEM: {
    BASE: '/intensityMetric/:intensityMetricDetailsId/item',
    BULK: '/intensityMetric/:intensityMetricDetailsId/item/bulk',
    LIST: '/intensityMetric/:intensityMetricDetailsId/item/list'
  },
  INTENSITY_METRIC_TEMPLATE: {
    LIST: '/intensityMetric/:companySlug/template/list'
  }
});

export default ApiRoutes;
