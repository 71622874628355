import { Outlet } from 'react-router-dom';

function TargetSetting() {
  return (
    <div className="target-setting">
      <div className="mb-5">
        <Outlet />
      </div>
    </div>
  );
}
export default TargetSetting;
