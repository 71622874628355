import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownField,
  GraphicFormCard,
  InputField,
  Notification
} from '../../../../components';
import { useMeContext, useMetaContext } from '../../../../context';
import { analytics, getDropdownOptions } from '../../../../helpers';
import { rerouteHelper } from '../../../../helpers/rerouteHelper';
import { AppRoutes } from '../../../../routes';
import { MeService } from '../../../../services';
import CompanyService from '../../../../services/companyService';
import gusGlasses from '../../../../stories/assets/svgs/Gus/gusGlasses.svg';
import { Button, Logo } from '../../../../stories/atoms';
import './companyInformation.scss';
import { companyInformationValidation } from './companyInformationValidation';

const employeeCountOptions = [
  { label: '1-19 Employees', value: '1-19' },
  { label: '20-99 Employees', value: '20-99' },
  { label: '100-249 Employees', value: '100-249' },
  { label: '250-499 Employees', value: '250-499' },
  { label: '500+ Employees', value: '500+' }
];

function CompanyInformation() {
  const navigate = useNavigate();

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const [industryOptions, setIndustryOptions] = useState([]);

  const [countryOptions, setCountryOptions] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const { updateMe, setJWTToken, isGroupSettingsMember } = useMeContext();
  const { meta } = useMetaContext();

  const handleSubmit = (company) => {
    const origin = query.get('origin');
    setFormLoading(true);
    CompanyService.createCompany(
      company,
      (slug) => {
        MeService.changeActiveCompany(
          slug,
          (token) => {
            Notification({
              type: 'success',
              message: 'Your Company',
              description: 'Your company information has been updated!'
            });
            setJWTToken(token);
            MeService.getMyDetails(
              (user) => {
                analytics.track('Company Creation completed', user, {
                  level1: 'Create Account',
                  level2: 'Signup',
                  level3: 'Enter company information'
                });
                updateMe(user);
                const rerouteUrl = rerouteHelper(user);
                if (!!rerouteUrl) {
                  navigate(rerouteUrl);
                } else {
                  navigate(
                    origin === 'commute'
                      ? AppRoutes.EMPLOYEE_COMMUTE
                      : AppRoutes.DASHBOARD
                  );
                }
              },
              () => {
                Notification({
                  type: 'success',
                  message: 'Your Company',
                  description: 'Your company information has been updated!'
                });
              },
              () => {}
            );
          },
          () => {},
          () => {}
        );
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const [formValues, _setFormValues] = useState({
    companyName: '',
    industryId: null,
    reportedEmployeeCount: null,
    headQuartersCountry: null
  });

  useEffect(() => {
    setIndustryOptions(getDropdownOptions(meta.industries, 'name', 'id'));
    setCountryOptions(getDropdownOptions(meta.countries, 'name', 'id'));
  }, [meta]);

  return (
    <div className="company-information">
      {!isGroupSettingsMember && <Logo className="company-information__logo" />}
      <GraphicFormCard imgSrc={gusGlasses}>
        <h3 className="text-bd">Enter your company information</h3>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={companyInformationValidation}
        >
          {({ _values, _errors, isValid, _dirty, setFieldValue }) => (
            <Form>
              <Row>
                <Col span={24}>
                  <InputField
                    title="Company name"
                    type="text"
                    name="name"
                    placeholder="Enter Company"
                    required
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    showSearch
                    title="Industry"
                    name="industryId"
                    options={industryOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Search and select industry"
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    showSearch
                    title="Full time employee count"
                    name="reportedEmployeeCount"
                    options={employeeCountOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Search and select employee count"
                  />
                </Col>
                <Col span={24}>
                  <DropdownField
                    showSearch
                    title="Headquarters Country"
                    name="headQuartersCountry"
                    options={countryOptions}
                    setFieldValue={setFieldValue}
                    placeholder="Search and select country"
                  />
                </Col>
                <Col
                  span={24}
                  className="company-information__submit display-flex justify-end"
                >
                  <Button
                    fullWidth
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </GraphicFormCard>
    </div>
  );
}

export default CompanyInformation;
