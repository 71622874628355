import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Card } from '../../../../components';
import { InitiativeFinancePartnershipTypeColours } from '../../../../constants/InitiativeFinancePartnerships';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { Anchor, Paragraph, Tag, Title } from '../../../atoms';
import './InitiativeContentCard.scss';

function InitiativeContentCard({ card, size, analyticsContext }) {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { me } = useMeContext();
  const handleOnClickResource = () => {
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
  };
  return (
    <Card
      className={classNames('ch-initiative-content-card', {
        [size]: !!size
      })}
    >
      <Row align="middle" className="mb-5">
        {card.logoSrc && (
          <Col flex="120px">
            <img src={card.logoSrc} alt={`${card.title} Logo`} />
          </Col>
        )}
        <Col flex="1" className="display-flex justify-end">
          {card.tags?.map((tag) => (
            <Tag color={InitiativeFinancePartnershipTypeColours[tag]}>
              {tag}
            </Tag>
          ))}
        </Col>
      </Row>
      <Row className="display-flex  ch-initiative-content-card__content">
        <Col span={24}>
          <Title bottomSpacing={16}>{card.title}</Title>
          <Paragraph bottomSpacing={16}>{card.description}</Paragraph>
        </Col>
      </Row>
      {card.url && (
        <Row>
          <Col span={24}>
            <button className="reset-button" onClick={handleOnClickResource}>
              <Anchor fontWeight={600} href={card.url}>
                {card.urlText ?? 'Source'}
              </Anchor>
            </button>
          </Col>
        </Row>
      )}
    </Card>
  );
}
export default InitiativeContentCard;
