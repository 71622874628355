import * as Yup from 'yup';

export const CarbonhoundConnectHistoricalImportValidation = Yup.object().shape({
  includeHistoricalData: Yup.boolean()
    .nullable()
    .required('Please indicate if you would like to import historical data.'),
  includeOngoingData: Yup.boolean()
    .nullable()
    .required('Please indicate if you would like to import ongoing data.'),
  carbonYears: Yup.array()
    .nullable()
    .when('includeHistoricalData', {
      is: true,
      then: Yup.array().min(1, 'Please indicate which years to import.')
    })
});
