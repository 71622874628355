import React from 'react';
import { Notification } from '../../../components';
import { NotificationCategory } from '../../../constants';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { useDrawer } from '../../../hooks/useDrawer';
import { NotificationService } from '../../../services';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { ActionsFormFields } from './ActionsFormFields';
import { ActionsFormValidation } from './ActionsFormValidation';

export function ActionsForm({ onSuccess, analyticsContext }) {
  const { me } = useMeContext();
  const {
    Drawer: ItemDrawer,
    handleShowDrawer,
    handleCloseDrawer
  } = useDrawer({
    width: '30%'
  });
  const handleCreateNotification = async (values) => {
    await NotificationService.createNotification({
      category: NotificationCategory.CUSTOM,
      companySlug: me?.company?.slug,
      ...values
    });

    analytics.track('Save item clicked', me, {
      level1: 'Actions',
      ...analyticsContext
    });
    Notification({
      type: 'success',
      message: 'Action created',
      description: 'You can see it on the actions page'
    });
    onSuccess?.();
    handleCloseDrawer();
  };

  const { execute: handleSubmit, isLoading } = useAsync({
    asyncFunction: handleCreateNotification,
    immediate: false
  });
  return (
    <>
      <Button onClick={handleShowDrawer} type={BUTTON_TYPES.SECONDARY}>
        Create Action
      </Button>
      <ItemDrawer
        onCancel={handleCloseDrawer}
        buttons={[
          {
            htmlType: 'submit',
            children: 'Create',
            loading: isLoading
          }
        ]}
        title="Create action"
        formikContext={{
          validationSchema: ActionsFormValidation,
          className: 'actions-form',
          onSubmit: handleSubmit,
          initialValues: {
            title: null,
            message: null,
            ownerId: null,
            dueDate: null
          }
        }}
      >
        <ActionsFormFields />
      </ItemDrawer>
    </>
  );
}
