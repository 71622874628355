import { Select as AntSelect } from 'antd';
import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import Error from '../../../components/Error';
import { COLOURS_GRAY } from '../../../constants/colours';
import { Icon, Label } from '../index';
import './Select.scss';

function Select({
  children,
  className,
  disableAutocomplete,
  disableForm = false,
  _loading = false,
  name,
  onChange,
  onSearch,
  optionLabelProp,
  _setFieldTouched,
  setFieldValue,
  mode,
  _showArrow = true,
  showSearch,
  title,
  placeholder = 'Select',
  ...props
}) {
  const handleChange = (value) => {
    if (setFieldValue) setFieldValue(name, value);
  };

  const handleFilter = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return !disableForm ? (
    <Field name={name}>
      {({ form: { setFieldTouched } }) => (
        <div
          className={classNames('ch-select-wrapper', {
            [className]: !!className
          })}
        >
          {title && <Label className="ch-select__title">{title}</Label>}
          <AntSelect
            placeholder={placeholder}
            autoComplete="none"
            dropdownMatchSelectWidth={false}
            filterOption={showSearch ? handleFilter : undefined}
            onBlur={() => setFieldTouched(name)}
            onChange={onChange || handleChange}
            onFocus={disableAutocomplete}
            onSearch={onSearch || undefined}
            showSearch={showSearch}
            optionLabelProp={optionLabelProp}
            className="ch-select"
            mode={mode}
            showArrow
            suffixIcon={
              <Icon
                color={COLOURS_GRAY.GRAY_800}
                size={16}
                name="ChevronDown"
              />
            }
            {...props}
          >
            {children}
          </AntSelect>
          <ErrorMessage name={name}>
            {(message) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        </div>
      )}
    </Field>
  ) : (
    <div
      className={classNames('ch-select-wrapper', {
        [className]: !!className
      })}
    >
      {title && <Label className="ch-select__title">{title}</Label>}
      <AntSelect
        placeholder={placeholder}
        autoComplete="none"
        dropdownMatchSelectWidth={false}
        filterOption={showSearch ? handleFilter : undefined}
        onFocus={disableAutocomplete}
        optionLabelProp={optionLabelProp}
        className="ch-select"
        mode={mode}
        onChange={onChange}
        showArrow
        suffixIcon={
          <Icon color={COLOURS_GRAY.GRAY_800} size={16} name="ChevronDown" />
        }
        {...props}
      />
    </div>
  );
}

export default Select;
