import { AppRoutes } from '../routes';

export const EMPLOYEE_ONBOARDING_STEPS = {
  BUSINESS_TRAVEL: 'BUSINESS_TRAVEL',
  COMMUTE: 'COMMUTE',
  COMMUTE_OVERVIEW: 'COMMUTE_OVERVIEW',
  COMMUTE_OFFICE: 'COMMUTE_OFFICE',
  COMMUTE_HOME: 'COMMUTE_HOME',
  IMPACT: 'IMPACT',
  INTRODUCTION: 'INTRODUCTION'
};
export const EMPLOYEE_ONBOARDING_STEPS_LABEL = {
  [EMPLOYEE_ONBOARDING_STEPS.INTRODUCTION]: 'Introduction',
  [EMPLOYEE_ONBOARDING_STEPS.COMMUTE]: '1. Employee Details',
  [EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OVERVIEW]: 'Overview',
  [EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE]: 'Add  Commute',
  [EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME]: 'Add Home Office ',
  [EMPLOYEE_ONBOARDING_STEPS.BUSINESS_TRAVEL]: '2. Business Travel',
  [EMPLOYEE_ONBOARDING_STEPS.IMPACT]: '3. Your Impact'
};

export const EMPLOYEE_ONBOARDING_STEPS_ROUTE_MAPPINGS = {
  [EMPLOYEE_ONBOARDING_STEPS.INTRODUCTION]:
    AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW,
  [EMPLOYEE_ONBOARDING_STEPS.COMMUTE]: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS,
  [EMPLOYEE_ONBOARDING_STEPS.BUSINESS_TRAVEL]:
    AppRoutes.ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL,
  [EMPLOYEE_ONBOARDING_STEPS.IMPACT]: AppRoutes.ONBOARDING_EMPLOYEE_YOUR_IMPACT
};
export const EMPLOYEE_ONBOARDING_STEPS_ROUTES = {
  [AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW]:
    EMPLOYEE_ONBOARDING_STEPS.INTRODUCTION,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS]: EMPLOYEE_ONBOARDING_STEPS.COMMUTE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_DETAILS]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH_EDIT]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME_EDIT]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME,
  [AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME]:
    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME,
  [AppRoutes.ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL]:
    EMPLOYEE_ONBOARDING_STEPS.BUSINESS_TRAVEL,
  [AppRoutes.ONBOARDING_EMPLOYEE_YOUR_IMPACT]: EMPLOYEE_ONBOARDING_STEPS.IMPACT
};

export const EMPLOYEE_ONBOARDING_STEPS_TUPLE = Object.freeze([
  EMPLOYEE_ONBOARDING_STEPS.INTRODUCTION,
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE,
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  EMPLOYEE_ONBOARDING_STEPS.BUSINESS_TRAVEL,
  EMPLOYEE_ONBOARDING_STEPS.IMPACT,
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME
]);

export const EMPLOYEE_ONBOARDING_COMMUTE_STEPS = [
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OVERVIEW,
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE,
  EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME
];
