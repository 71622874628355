import { useState } from 'react';
import { WarningOutlined, CloseOutlined } from '@ant-design/icons';
import './note.scss';

function Note({
  info,
  warning,
  title,
  description,
  className,
  allowClose = true
}) {
  const [showContent, setShowContent] = useState(true);

  const handleClose = () => setShowContent(false);

  return showContent ? (
    <div
      className={`ch-note ${info ? ' info ' : ''}${
        warning ? ' warning ' : ''
      } ${className ?? ''}`}
    >
      {allowClose && (
        <CloseOutlined className="ch-note__close-icon" onClick={handleClose} />
      )}
      <div className="ch-note__icon-wrapper">
        {info && <i className="icon-info" />}
        {warning && <WarningOutlined />}
      </div>
      <div className="ch-note__content">
        <h5 className="text-sbd">{title}</h5>
        <p className="text-light-black"> {description}</p>
      </div>
    </div>
  ) : null;
}

export default Note;
