import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { debounce, getDropdownOptions } from '../../../helpers';
import { Select } from '../index';

function AsyncSelect({
  debounceTimeout = 400,
  searchMethod,
  optionKeys,
  getOptionsMethod = getDropdownOptions,
  shouldReturnWholeValueOnSelect = false,
  value,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (term) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      if (term.length < 3) {
        setFetching(false);
        setOptions([]);
      } else {
        setFetching(true);
        searchMethod(
          { term },
          (newOptions) => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            let baseOptions = getOptionsMethod(
              newOptions,
              optionKeys.label,
              optionKeys.value
            );
            if (shouldReturnWholeValueOnSelect) {
              baseOptions = baseOptions.reduce((acc, curr, i) => {
                if (
                  newOptions[i] &&
                  newOptions[i][optionKeys.value] &&
                  newOptions[i][optionKeys.value] == curr.value
                ) {
                  acc.push({
                    ...newOptions[i],
                    ...curr
                  });
                }
                return acc;
              }, []);
            }
            setOptions([...baseOptions]);
            setFetching(false);
          },
          () => {},
          () => {}
        );
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [searchMethod, debounceTimeout]);

  useEffect(() => {
    if (value) {
      debounceFetcher(value);
    }
  }, []);

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      {...props}
      value={value}
      options={options}
      notFoundContent={
        fetching ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color: COLOURS_CARBONHOUND.PRIMARY_PURPLE
                }}
                spin
              />
            }
            size="small"
          />
        ) : null
      }
    />
  );
}
export default AsyncSelect;
