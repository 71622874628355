function Check() {
  return (
    <path
      d="M5 13L9 17L19 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Check;
