import { Col, Divider, Modal as AntModal, Row, Space } from 'antd';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Notification } from '../../../components';
import {
  CHART_KEY_NAMES,
  CollectionType,
  EMISSION_SOURCE_INTERNAL_SPECS
} from '../../../constants';
import { CollectionDetailsRoute } from '../../../constants/collectionTypes';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { useMeContext, useMetaContext } from '../../../context';
import EmissionSourceDetailFactorForm from '../../../features/EmissionSource/EmissionSourceDetail/EmissionSourceDetailFactorForm';
import EmissionSourceDetailForm from '../../../features/EmissionSource/EmissionSourceDetail/EmissionSourceDetailForm';
import EmissionSourceDetailSummaryChart from '../../../features/EmissionSource/EmissionSourceDetail/EmissionSourceDetailSummaryChart';
import EmissionSourceEnergyItemTable from '../../../features/EmissionSource/EmissionSourceEnergyItem/EmissionSourceEnergyItemTable';
import EmissionSourceItemTable from '../../../features/EmissionSource/EmissionSourceItem/EmissionSourceItemTable';
import EmissionSourceTravelItemTable from '../../../features/EmissionSource/EmissionSourceTravelItem/EmissionSourceTravelItemTable';
import RelatedEmissionFactorList from '../../../features/RelatedEmissionFactorList';
import { analytics, getFullName } from '../../../helpers';
import {
  getDefaultYearForChart,
  getEmissionSourceDetailsName,
  getFormattedAddress,
  getIsCountryEstimationsSupported
} from '../../../helpers/generators';
import { useAsync, useModal } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import EmissionSourceService from '../../../services/emissionSourceService';
import {
  Button,
  Empty,
  Icon,
  Page,
  PageHeader,
  Paragraph,
  TabPane,
  Tabs,
  Tag,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../stories/atoms/Tag/tag.types';
import {
  CarbonhoundConnectCta,
  GenerateCollectionEstimateForm
} from '../../../stories/molecules';
import './EmissionSourceDetails.scss';

function EmissionSourcesDetails() {
  const { me, selectCarbonYearRange } = useMeContext();
  const { meta } = useMetaContext();
  const { emissionSourceDetailsId } = useParams();
  const location = useLocation();
  const locationDetails = location.state ?? {};

  const {
    Modal: EditEmissionSourceModal,
    handleCloseModal: handleCloseEmissionSourceModal,
    handleShowModal: handleShowEmissionSourceModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Edit item clicked', me, {
        level1: 'Emission Sources',
        level2: 'Details',
        emissionSourceDetailsId
      });
    },
    onCloseModal: () => {
      reloadSourceView();
    }
  });

  const {
    Modal: GenerateEstimatesModal,
    handleCloseModal: handleCloseEstimatesModal,
    handleShowModal: handleShowEstimatesModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Generate Estimates Clicked', me, {
        level1: 'Emission Sources',
        level2: 'Details',
        emissionSourceDetailsId
      });
    },
    onCloseModal: () => {
      reloadSourceView();
    }
  });

  const getEmissionSourceDetails = useCallback(async () => {
    let esDetails;

    esDetails = await EmissionSourceService.getEmissionSourceDetails(
      emissionSourceDetailsId
    );

    return esDetails ?? {};
  }, [emissionSourceDetailsId]);
  const {
    Loader: EmissionSourceDetailsLoader,
    value: emissionSourceDetails,
    execute: loadSourceDetails
  } = useAsync({
    asyncFunction: getEmissionSourceDetails,
    defaultValue: {}
  });

  // This is the current carbon year calculated based on user starting month/year
  const currentCarbonYear = selectCarbonYearRange({
    activeYear: getDefaultYearForChart({
      startingMonth: me.company?.startingMonth
    })
  });
  const defaultRange = {
    rangeStart: currentCarbonYear.rangeStart,
    rangeEnd: currentCarbonYear.rangeEnd
  };

  const [selectedDateRange, setSelectedDateRange] = useState(defaultRange);
  const [chartKeyName, setChartKeyName] = useState(
    CHART_KEY_NAMES.TOTAL_ACTIVITY
  );

  const fetchOfficeTimelineSummary = useCallback(async () => {
    if (emissionSourceDetailsId && (isCustomSource || isEnergySpec)) {
      const tl = await EmissionSourceService.fetchEmissionSourceDetailsTimeline(
        {
          emissionSourceDetailsId,
          rangeStart: selectedDateRange.rangeStart,
          rangeEnd: selectedDateRange.rangeEnd
        }
      );

      return tl;
    }
    return [];
  }, [selectedDateRange, emissionSourceDetailsId]);

  const {
    value: timeline,
    isLoading: isLoadingTimeline,
    execute: refreshTimeline
  } = useAsync({
    asyncFunction: fetchOfficeTimelineSummary,
    defaultValue: []
  });
  const handleLoadChartData = useCallback(async () => {
    if (emissionSourceDetails?.id) {
      return await EmissionSourceService.fetchEmissionSourceDetailDrilldown({
        emissionSourceDetailsId: emissionSourceDetails?.id,
        rangeStart: selectedDateRange.rangeStart,
        rangeEnd: selectedDateRange.rangeEnd,
        companySlug: me.company?.slug,
        keyName: chartKeyName
      });
    }
  }, [
    me.company?.slug,
    selectedDateRange.rangeStart,
    selectedDateRange.rangeEnd,
    chartKeyName,
    emissionSourceDetails?.id
  ]);

  const { value: chartData, execute: refreshChart } = useAsync({
    asyncFunction: handleLoadChartData
  });

  const isEnergySpec = emissionSourceDetails?.tags?.find(
    (t) => t.internalSpec === EMISSION_SOURCE_INTERNAL_SPECS.ENERGY
  );
  const isTravelSpec = emissionSourceDetails?.tags?.find(
    (t) => t.internalSpec === EMISSION_SOURCE_INTERNAL_SPECS.TRAVEL
  );
  const isCommuteSpec = emissionSourceDetails?.tags?.find(
    (t) => t.internalSpec === EMISSION_SOURCE_INTERNAL_SPECS.COMMUTE
  );

  const isCustomSource = !emissionSourceDetails?.isInternal;

  const analyticsContext = {
    level1: 'Emission Sources',
    level2: 'Details',
    emissionSourceDetails
  };
  const {
    Modal: EditRelatedFactorModal,
    handleShowModal: handleShowRelatedFactorModal,
    handleCloseModal: handlecloseRelatedFactorModal
  } = useModal({
    width: '60%',
    onShowModal: () => {
      analytics.track('Edit Item Clicked', me, {
        level1: 'Emission Sources',
        level2: 'Details',
        level3: 'Related Emission Factors',
        emissionSourceDetails
      });
    },
    onCloseModal: () => {
      reloadSourceView();
    }
  });
  const handleEditEFSuccess = (emissionFactorDetailsId) => {
    analytics.track('Save item clicked', me, {
      level1: 'Emission Sources',
      level2: 'Details',
      level3: 'Related Emission Factors',
      emissionSourceDetails,
      oldEmissionFactorDetailsId: emissionSourceDetails.emissionFactor?.id,
      emissionFactorDetailsId
    });
    reloadSourceView();
    handlecloseRelatedFactorModal();
  };

  const routes = [
    {
      path: AppRoutes.SOURCES_OVERVIEW,
      breadcrumbName: 'Sources'
    },
    ...(locationDetails.isCollection
      ? [
          {
            path: CollectionDetailsRoute[locationDetails?.type]?.replace(
              ':collectionId',
              locationDetails.collectionId
            ),
            breadcrumbName: locationDetails.collectionName
          }
        ]
      : []),
    {
      breadcrumbName: getEmissionSourceDetailsName(emissionSourceDetails)
    }
  ];

  const collectionNameList = emissionSourceDetails?.collections
    ?.filter?.((cl) => cl.type === CollectionType.COLLECTION)
    .map((cl) => cl.name)
    .join(', ');
  const collectionBusinessUnit = emissionSourceDetails?.collections?.find?.(
    (cl) => cl.type === CollectionType.BUSINESS_UNIT
  );
  const collectionLocation = emissionSourceDetails?.collections?.find?.(
    (cl) => cl.type === CollectionType.LOCATION
  );
  const emissionSourceDetailsName = getEmissionSourceDetailsName(
    emissionSourceDetails
  );

  const handleClearEstimates = async () => {
    try {
      await EmissionSourceService.clearSourceEstimations({
        emissionSourceDetailsId: emissionSourceDetails?.id
      });
      loadSourceDetails();
      refreshTimeline();
      refreshChart();
    } catch (e) {
      Notification({
        type: 'error',
        message: 'Unable to clear estimations. Please try again'
      });
    }
  };
  const { execute: clearEstimates } = useAsync({
    asyncFunction: handleClearEstimates,
    immediate: false
  });

  const handleConfirmClearEstimates = () => {
    analytics.track('Delete Estimates Clicked', me, {
      level1: 'Emission Sources',
      level2: 'Details',
      emissionSourceDetailsId
    });
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Clear Estimates',
      cancelText: 'Cancel',
      content: (
        <Paragraph size="rg">
          This will clear any system generated estimated data and any estimated
          data you have entered.
        </Paragraph>
      ),
      title: (
        <Title size="md" bottomSpacing={0}>
          Are you sure you want to clear all estimates?
        </Title>
      ),
      onOk: () => {
        clearEstimates();
      }
    });
  };
  const reloadSourceView = () => {
    loadSourceDetails();
    refreshTimeline();
    refreshChart();
  };

  const { internalDetails } = emissionSourceDetails ?? {};
  const { internalCategory } = internalDetails ?? {};
  const isEstimationSupported = getIsCountryEstimationsSupported({
    countries: meta.countries,
    countryName: collectionLocation?.location?.country?.name
  });
  return (
    <Page className="emission-sources-details">
      <EmissionSourceDetailsLoader>
        <PageHeader
          className={classNames({
            'ant-page-header-heading-with-sub-title':
              !!emissionSourceDetails?.description
          })}
          breadcrumb={{
            routes,
            itemRender: (route) =>
              !!route.path ? (
                <Link
                  key={route.breadcrumbName}
                  to={route.path}
                  state={{
                    ...(route.path === AppRoutes.USER_COMPANY
                      ? { defaultKey: '3' }
                      : {})
                  }}
                >
                  {route.breadcrumbName === 'My Company' ||
                    (route.breadcrumbName === 'Sources' && (
                      <Icon
                        size={16}
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                        name="ChevronLeft"
                      />
                    ))}
                  {route.breadcrumbName}
                </Link>
              ) : (
                <span key={route.breadcrumbName}>{route.breadcrumbName}</span>
              )
          }}
          title={emissionSourceDetailsName}
          subTitle={emissionSourceDetails?.description}
          extra={
            <Space>
              <Button
                type={BUTTON_TYPES.DELETE}
                onClick={handleConfirmClearEstimates}
              >
                Clear Estimates
              </Button>
              {internalCategory === EMISSION_SOURCE_INTERNAL_SPECS.ENERGY &&
                isEstimationSupported && (
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    onClick={handleShowEstimatesModal}
                  >
                    Generate Estimates
                  </Button>
                )}
              <Button onClick={handleShowEmissionSourceModal}>Edit</Button>
            </Space>
          }
          footer={
            <>
              <Divider />
              <Row gutter={[24, 4]} justify="space-between">
                <Col span={6}>
                  <Space direction="vertical" size={0}>
                    <Title size="xs" weight={500} bottomSpacing={8}>
                      Business Unit
                    </Title>
                    <Title
                      fontWeight={500}
                      showTooltip
                      className="emission-sources-details__footer__item-detail"
                      size="md"
                      bottomSpacing={0}
                      wordBreak="break-word"
                    >
                      {collectionBusinessUnit?.name || '--'}
                    </Title>
                  </Space>
                </Col>
                <Col span={6}>
                  <Space direction="vertical" size={0}>
                    <Title size="xs" weight={500} bottomSpacing={8}>
                      Collection(s)
                    </Title>
                    <Title
                      fontWeight={500}
                      showTooltip
                      className="emission-sources-details__footer__item-detail"
                      size="md"
                      bottomSpacing={0}
                      wordBreak="break-word"
                    >
                      {collectionNameList || '--'}
                    </Title>
                  </Space>
                </Col>
                <Col span={6}>
                  <Space direction="vertical" size={0}>
                    <Title size="xs" weight={500} bottomSpacing={8}>
                      Location
                    </Title>
                    <Title
                      fontWeight={500}
                      showTooltip
                      className="emission-sources-details__footer__item-detail"
                      size="md"
                      bottomSpacing={0}
                      wordBreak="break-word"
                    >
                      {getFormattedAddress({
                        location: collectionLocation?.location,
                        name: collectionLocation?.name
                      }) || '--'}
                    </Title>
                  </Space>
                </Col>
                <Col span={6}>
                  <Space direction="vertical" size={0}>
                    <Title size="xs" weight={500} bottomSpacing={8}>
                      Created
                    </Title>
                    <Title
                      fontWeight={500}
                      showTooltip
                      className="emission-sources-details__footer__item-detail"
                      size="md"
                      bottomSpacing={0}
                      wordBreak="break-word"
                    >
                      {emissionSourceDetails?.createdAt &&
                        `${formatInTimeZone(
                          new Date(emissionSourceDetails?.createdAt),
                          'UTC',
                          'MMM dd, yyyy'
                        )} ${
                          !!emissionSourceDetails?.createdBy
                            ? `by ${getFullName(
                                emissionSourceDetails?.createdBy
                              )}`
                            : ''
                        }`}
                    </Title>
                  </Space>
                </Col>
              </Row>
            </>
          }
          tags={
            <Space>
              {emissionSourceDetails?.tags &&
                emissionSourceDetails?.tags.map((tag, i) => (
                  <Tag
                    key={i}
                    color={INTERNAL_TAG_COLOURS[tag.name] ?? TAG_COLOURS.GREEN}
                  >
                    {tag.name}
                  </Tag>
                ))}
            </Space>
          }
        />
      </EmissionSourceDetailsLoader>

      <Row className="mb-6" gutter={[0, 24]}>
        <Col span={24}>
          <EmissionSourceDetailSummaryChart
            emissionSourceDetails={emissionSourceDetails}
            analyticsContext={{
              level3: 'Overview',
              ...analyticsContext
            }}
            chartData={chartData}
            refreshChart={refreshChart}
            chartKeyName={chartKeyName}
            setChartKeyName={setChartKeyName}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </Col>
        <Col span={24}>
          <CarbonhoundConnectCta
            integration={emissionSourceDetails.integration}
            isSourceLevel
            emissionSourceDetailsId={emissionSourceDetails.id}
            onSuccess={loadSourceDetails}
          />
        </Col>
      </Row>

      <Tabs secondary>
        <TabPane tab="Activity Data" key="1">
          {isCustomSource ? (
            <EmissionSourceItemTable
              analyticsContext={{
                level3: 'Activity Data',
                ...analyticsContext
              }}
              emissionSourceDetails={emissionSourceDetails}
              refreshChart={refreshChart}
              timeline={timeline}
              isLoadingTimeline={isLoadingTimeline}
              refreshTimeline={refreshTimeline}
            />
          ) : isTravelSpec ? (
            <EmissionSourceTravelItemTable
              analyticsContext={{
                level3: 'Activity Data',
                ...analyticsContext
              }}
              emissionSourceDetails={emissionSourceDetails}
              refreshChart={refreshChart}
            />
          ) : isEnergySpec ? (
            <EmissionSourceEnergyItemTable
              analyticsContext={{
                level3: 'Activity Data',
                ...analyticsContext
              }}
              emissionSourceDetails={emissionSourceDetails}
              refreshChart={refreshChart}
              timeline={timeline}
              isLoadingTimeline={isLoadingTimeline}
              refreshTimeline={refreshTimeline}
            />
          ) : isCommuteSpec ? (
            <Empty
              icon="Truck"
              title="Commute Source"
              description="This source is only used for commuting and doesn't have any activity items to display. Commutes can be viewed in your trail."
            />
          ) : null}
        </TabPane>
        )
        <TabPane tab="Related Emission Factor" key="2">
          <Row>
            <Col flex="1">
              <Title size="med" className="mb-7">
                Related Emission Factor
              </Title>
            </Col>
            <Col flex="shrink">
              <Button
                onClick={handleShowRelatedFactorModal}
                type={BUTTON_TYPES.PRIMARY}
              >
                Edit
              </Button>
            </Col>
          </Row>
          {!!emissionSourceDetails?.emissionFactor?.id && (
            <RelatedEmissionFactorList
              efDetailsId={emissionSourceDetails?.emissionFactor?.id}
            />
          )}
        </TabPane>
      </Tabs>
      <EditRelatedFactorModal title="Edit Related Factor">
        <EmissionSourceDetailFactorForm
          emissionSourceDetails={emissionSourceDetails}
          onSuccess={handleEditEFSuccess}
        />
      </EditRelatedFactorModal>
      <EditEmissionSourceModal title="Edit Emission Source">
        <EmissionSourceDetailForm
          onSuccess={handleCloseEmissionSourceModal}
          source={emissionSourceDetails}
          loadSourceDetails={loadSourceDetails}
        />
      </EditEmissionSourceModal>
      <GenerateEstimatesModal title="Generate Estimates">
        <GenerateCollectionEstimateForm
          onCancel={handleCloseEstimatesModal}
          onSuccess={handleCloseEstimatesModal}
          source={emissionSourceDetails}
          loadSourceDetails={loadSourceDetails}
        />
      </GenerateEstimatesModal>
    </Page>
  );
}
export default EmissionSourcesDetails;
