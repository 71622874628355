import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { COLOURS_GRAY } from '../../../constants/colours';
import { IntegrationStatus } from '../../../constants/status';
import { useCHCContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import {
  Anchor,
  Button,
  Drawer,
  Icon,
  Input,
  Paragraph,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { INPUT_TYPES } from '../../../stories/atoms/Input/Input.types';
import ModalFooter from '../../../stories/atoms/ModalFooter';
import ProviderForm from '../../../stories/molecules/ProviderForm';
import ProviderSelect from '../../../stories/molecules/ProviderSelect';
import './CarbonhoundConnectProviderCredentials.scss';
import { CarbonhoundConnectProviderCredentialsValidation } from './CarbonhoundConnectProviderCredentialsValidation';

export function CarbonhoundConnectProviderCredentials({
  handleNext,
  analyticsContext,
  integration
}) {
  const [isProviderFormOpen, setIsProviderFormOpen] = useState(false);
  const { me } = useMeContext();
  const { createCompanyIntegration, createCompanyCHCProvider } =
    useCHCContext();

  const handleAddNewProvider = () => {
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      level3: 'Credentials',
      level4: 'Add new modal'
    });
    setIsProviderFormOpen(true);
  };
  const handleAddNewProviderSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      level3: 'Credentials',
      level4: 'Add new modal'
    });
    setIsProviderFormOpen(false);
  };

  const handleCreateCompanyIntegration = async (values) => {
    await createCompanyIntegration({
      ...values,
      integrationSlug: integration.slug,
      companySlug: me.company?.slug,
      status: IntegrationStatus.PENDING
    });
    // Map Utility provider
    await createCompanyCHCProvider(values);
    analytics.track('Step Completed', me, {
      ...analyticsContext,
      level3: 'Credentials'
    });
    handleNext();
  };

  const { execute: handleSubmit, isLoading: isSavingCompanyIntegration } =
    useAsync({
      asyncFunction: handleCreateCompanyIntegration,
      immediate: false
    });
  return (
    <Formik
      initialValues={{ utilityProviderId: null, username: '', password: '' }}
      validationSchema={CarbonhoundConnectProviderCredentialsValidation}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row
            className="carbonhound-connect-provider-credentials"
            gutter={[0, 40]}
          >
            <Col span={24}>
              <Row align="middle" gutter={[0, 24]}>
                <Col span={24}>
                  <Title bottomSpacing={0}>Your credential details</Title>
                </Col>
                <Col span={24}>
                  <Row gutter={24} align="middle">
                    <Col flex={1}>
                      <ProviderSelect
                        setFieldValue={setFieldValue}
                        title="Provider"
                        name="utilityProviderId"
                        value={values?.utilityProviderId}
                        placeholder="Search and select"
                        integration={integration}
                      />
                    </Col>
                    <Col className="mt-6">
                      <Button
                        onClick={handleAddNewProvider}
                        type={BUTTON_TYPES.SECONDARY}
                      >
                        Add new
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Input
                    title="Email Address/Username"
                    name="username"
                    placeholder="Enter"
                  />
                </Col>
                <Col span={24}>
                  <Input
                    title="Password"
                    name="password"
                    placeholder="Enter"
                    type={INPUT_TYPES.PASSWORD}
                    visibilityToggle
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Row
                    className="carbonhound-connect-provider-credentials__gray-bg"
                    align="middle"
                    gutter={16}
                  >
                    <Col>
                      <Icon name="LockClosed" color={COLOURS_GRAY.GRAY_600} />
                    </Col>
                    <Col flex={1}>
                      <Paragraph bottomSpacing={0} weight={600}>
                        Read how we protect your credentials
                      </Paragraph>
                    </Col>
                    <Col>
                      <Anchor
                        href="//carbonhound.notion.site/How-we-protect-your-credentials-1372b5bf288e48898e92106a92db2c69?pvs=4"
                        showLinkIcon={false}
                        fontWeight={600}
                        onClick={() => {
                          analytics.track('View Article', me, {
                            ...analyticsContext,
                            level3: 'Credentials'
                          });
                        }}
                      >
                        View
                      </Anchor>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row
                    className="carbonhound-connect-provider-credentials__gray-bg"
                    align="middle"
                    gutter={16}
                  >
                    <Col>
                      <Icon name="Key" color={COLOURS_GRAY.GRAY_600} />
                    </Col>
                    <Col flex={1}>
                      <Paragraph bottomSpacing={0} weight={600}>
                        Do you have 2 Factor Authentication turned on?
                      </Paragraph>
                    </Col>
                    <Col>
                      <Anchor
                        showLinkIcon={false}
                        onClick={() => {
                          analytics.track('Help clicked', me, {
                            ...analyticsContext,
                            level3: 'Credentials'
                          });
                        }}
                        fontWeight={600}
                        href="//share.hsforms.com/1M_pB95cDQKqAObfrE3gOqAbu7mr"
                      >
                        Contact Us
                      </Anchor>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <ModalFooter
            showHelpLink={false}
            showCancelButton={false}
            saveButtonCopy="Next"
            saveButtonProps={{
              isLoading: isSavingCompanyIntegration,
              htmlType: 'submit'
            }}
          />
          <Drawer
            title="Add new provider"
            width="33%"
            closable={false}
            destroyOnClose
            visible={isProviderFormOpen}
            onCancel={() => setIsProviderFormOpen(false)}
          >
            <ProviderForm
              onSuccess={handleAddNewProviderSuccess}
              integration={integration}
            />
          </Drawer>
        </Form>
      )}
    </Formik>
  );
}
