export const COLOURS_CARBONHOUND = {
  PRIMARY_ORANGE: '#FBAB1D',
  PRIMARY_RED: '#ED2861',
  PRIMARY_PURPLE: '#5C29C7',
  PRIMARY_PURPLE_HOVER: '#3D0DA2',
  PRIMARY_PURPLE_ACTIVE: '#2B047B',
  PRIMARY_PURPLE_ACCENT: '#F0ECFA'
};

export const COLOURS_BW = {
  BLACK: '#000',
  WHITE: '#fff'
};

export const COLOURS_GRAY = {
  GRAY_50: '#fafafa',
  GRAY_100: '#f4f4f5',
  GRAY_200: '#e4e4e7',
  GRAY_300: '#d4d4d8',
  GRAY_400: '#a1a1aa',
  GRAY_500: '#71717a',
  GRAY_600: '#52525b',
  GRAY_700: '#3f3f46',
  GRAY_800: '#27272a',
  GRAY_900: '#18181b'
};

export const COLOURS_ROSE = {
  ROSE_50: '#FFF1F2',
  ROSE_100: '#FFE4E6',
  ROSE_200: '#FECDD3',
  ROSE_300: '#FDA4AF',
  ROSE_400: '#FB7185',
  ROSE_500: '#F43F5E',
  ROSE_600: '#E11D48',
  ROSE_700: '#BE123C',
  ROSE_800: '#9F1239',
  ROSE_900: '#881337'
};

export const COLOURS_PINK = {
  PINK_50: '#FDF2F8',
  PINK_100: '#FCE7F3',
  PINK_200: '#FBCFE8',
  PINK_300: '#F9A8D4',
  PINK_400: '#F472B6',
  PINK_500: '#EC4899',
  PINK_600: '#DB2777',
  PINK_700: '#BE185D',
  PINK_800: '#9D174D',
  PINK_900: '#831843'
};

export const COLOURS_FUSCHIA = {
  FUCHSIA_50: '#fdf4ff',
  FUCHSIA_100: '#fae8ff',
  FUCHSIA_200: '#f5d0fe',
  FUCHSIA_300: '#f0abfc',
  FUCHSIA_400: '#e879f9',
  FUCHSIA_500: '#d946ef',
  FUCHSIA_600: '#c026d3',
  FUCHSIA_700: '#a21caf',
  FUCHSIA_800: '#86198f',
  FUCHSIA_900: '#701a75'
};

export const COLOURS_PURPLE = {
  PURPLE_50: '#faf5ff',
  PURPLE_100: '#f3e8ff',
  PURPLE_200: '#e9d5ff',
  PURPLE_300: '#d8b4fe',
  PURPLE_400: '#c084fc',
  PURPLE_500: '#a855f7',
  PURPLE_600: '#9333ea',
  PURPLE_700: '#7e22ce',
  PURPLE_800: '#6b21a8',
  PURPLE_900: '#581c87'
};

export const COLOURS_VIOLET = {
  VIOLET_50: '#f5f3ff',
  VIOLET_100: '#ede9fe',
  VIOLET_200: '#ddd6fe',
  VIOLET_300: '#c4b5fd',
  VIOLET_400: '#a78bfa',
  VIOLET_500: '#8b5cf6',
  VIOLET_600: '#7c3aed',
  VIOLET_700: '#6d28d9',
  VIOLET_800: '#5b21b6',
  VIOLET_900: '#4c1d95'
};

export const COLOURS_INDIGO = {
  INDIGO_50: '#eef2ff',
  INDIGO_100: '#e0e7ff',
  INDIGO_200: '#c7d2fe',
  INDIGO_300: '#a5b4fc',
  INDIGO_400: '#818cf8',
  INDIGO_500: '#6366f1',
  INDIGO_600: '#4f46e5',
  INDIGO_700: '#4338ca',
  INDIGO_800: '#3730a3',
  INDIGO_900: '#312e81'
};
export const COLOURS_BLUE = {
  BLUE_50: '#eff6ff',
  BLUE_100: '#dbeafe',
  BLUE_200: '#bfdbfe',
  BLUE_300: '#93c5fd',
  BLUE_400: '#60a5fa',
  BLUE_500: '#3b82f6',
  BLUE_600: '#2563eb',
  BLUE_700: '#1d4ed8',
  BLUE_800: '#1e40af',
  BLUE_900: '#1e3a8a'
};

export const COLOURS_LIGHT_BLUE = {
  LIGHT_BLUE_50: '#f0f9ff',
  LIGHT_BLUE_100: '#e0f2fe',
  LIGHT_BLUE_200: '#bae6fd',
  LIGHT_BLUE_300: '#7dd3fc',
  LIGHT_BLUE_400: '#38bdf8',
  LIGHT_BLUE_500: '#0ea5e9',
  LIGHT_BLUE_600: '#0284c7',
  LIGHT_BLUE_700: '#0369a1',
  LIGHT_BLUE_800: '#075985',
  LIGHT_BLUE_900: '#0c4a6e'
};
export const COLOURS_CYAN = {
  CYAN_50: '#ecfeff',
  CYAN_100: '#cffafe',
  CYAN_200: '#a5f3fc',
  CYAN_300: '#67e8f9',
  CYAN_400: '#22d3ee',
  CYAN_500: '#06b6d4',
  CYAN_600: '#0891b2',
  CYAN_700: '#0e7490',
  CYAN_800: '#155e75',
  CYAN_900: '#164e63'
};

export const COLOURS_TEAL = {
  TEAL_50: '#f0fdfa',
  TEAL_100: '#ccfbf1',
  TEAL_200: '#99f6e4',
  TEAL_300: '#5eead4',
  TEAL_400: '#2dd4bf',
  TEAL_500: '#14b8a6',
  TEAL_600: '#0d9488',
  TEAL_700: '#0f766e',
  TEAL_800: '#115e59',
  TEAL_900: '#134e4a'
};

export const COLOURS_EMERALD = {
  EMERALD_50: ' #ecfdf5',
  EMERALD_100: ' #d1fae5',
  EMERALD_200: ' #a7f3d0',
  EMERALD_300: ' #6ee7b7',
  EMERALD_400: ' #34d399',
  EMERALD_500: ' #10b981',
  EMERALD_600: ' #059669',
  EMERALD_700: ' #047857',
  EMERALD_800: ' #065f46',
  EMERALD_900: ' #064e3b'
};

export const COLOURS_GREEN = {
  GREEN_50: '#f0fdf4',
  GREEN_100: '#dcfce7',
  GREEN_200: '#bbf7d0',
  GREEN_300: '#86efac',
  GREEN_400: '#4ade80',
  GREEN_500: '#22c55e',
  GREEN_600: '#16a34a',
  GREEN_700: '#15803d',
  GREEN_800: '#166534',
  GREEN_900: '#14532d'
};

export const COLOURS_LIME = {
  LIME_50: '#f7fee7',
  LIME_100: '#ecfccb',
  LIME_200: '#d9f99d',
  LIME_300: '#bef264',
  LIME_400: '#a3e635',
  LIME_500: '#84cc16',
  LIME_600: '#65a30d',
  LIME_700: '#4d7c0f',
  LIME_800: '#3f6212',
  LIME_900: '#365314'
};

export const COLOURS_YELLOW = {
  YELLOW_50: '#fefce8',
  YELLOW_100: '#fef9c3',
  YELLOW_200: '#fef08a',
  YELLOW_300: '#fde047',
  YELLOW_400: '#facc15',
  YELLOW_500: '#eab308',
  YELLOW_600: '#ca8a04',
  YELLOW_700: '#a16207',
  YELLOW_800: '#854d0e',
  YELLOW_900: '#854d0e'
};

export const COLOURS_AMBER = {
  AMBER_50: '#fffbeb',
  AMBER_100: '#fef3c7',
  AMBER_200: '#fde68a',
  AMBER_300: '#fcd34d',
  AMBER_400: '#fbbf24',
  AMBER_500: '#f59e0b',
  AMBER_600: '#d97706',
  AMBER_700: '#b45309',
  AMBER_800: '#92400e',
  AMBER_900: '#78350f'
};

export const COLOURS_ORANGE = {
  ORANGE_50: '#fff7ed',
  ORANGE_100: '#ffedd5',
  ORANGE_200: '#fed7aa',
  ORANGE_300: '#fdba74',
  ORANGE_400: '#fb923c',
  ORANGE_500: '#f97316',
  ORANGE_600: '#ea580c',
  ORANGE_700: '#c2410c',
  ORANGE_800: '#9a3412',
  ORANGE_900: '#7c2d12'
};

export const COLOURS_RED = {
  RED_50: ' #fef2f2',
  RED_100: ' #fee2e2',
  RED_200: ' #fecaca',
  RED_300: ' #fca5a5',
  RED_400: ' #f87171',
  RED_500: ' #ef4444',
  RED_600: ' #dc2626',
  RED_700: ' #b91c1c',
  RED_800: ' #991b1b',
  RED_900: ' #7f1d1d'
};

export const COLOURS_COOL_GRAY = {
  COOL_GRAY_50: ' #f9fafb',
  COOL_GRAY_100: ' #f3f4f6',
  COOL_GRAY_200: ' #e5e7eb',
  COOL_GRAY_300: ' #d1d5db',
  COOL_GRAY_400: ' #9ca3af',
  COOL_GRAY_500: ' #6b7280',
  COOL_GRAY_600: ' #4b5563',
  COOL_GRAY_700: ' #374151',
  COOL_GRAY_800: ' #1f2937',
  COOL_GRAY_900: ' #111827'
};
