function Times({ color }) {
  return (
    <path
      d="M6 6L18 18M6 18L18 6L6 18Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default Times;
