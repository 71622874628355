import { Segmented as AntSegmented } from 'antd';
import classNames from 'classnames';
import './Segmented.scss';

function Segmented({ children, className, ...props }) {
  return (
    <AntSegmented
      className={classNames('ch-segmented', { [className]: !!className })}
      {...props}
    >
      {children}
    </AntSegmented>
  );
}
export default Segmented;
