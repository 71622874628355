import {
  COMPANY_INITIATIVE_STEPS,
  COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS,
  CompanyInitiativeStepsTuple
} from './CompanyInitiative.constants';

export const getIsSetupComplete = (companyTrailOverviewList) =>
  !!companyTrailOverviewList?.length &&
  // Check that the correct number of steps has been at least started. We -1 to account for the Overview not having a saved step
  companyTrailOverviewList.length >= CompanyInitiativeStepsTuple.length - 1 &&
  // Check that of all the steps, they're all complete
  !companyTrailOverviewList.find((cto) => !cto.isComplete);

export const getNextStep = (trailOverview) =>
  (
    trailOverview?.findLast((trail) => trail.isStarted && !trail.isComplete) ||
    trailOverview[trailOverview.length - 1]
  )?.segmentName;

export const getNextStepUrl = (trailOverview) => {
  const nextSegmentName =
    (
      trailOverview?.findLast(
        (trail) => trail.isStarted && !trail.isComplete
      ) || trailOverview[trailOverview.length - 1]
    )?.segmentName ?? COMPANY_INITIATIVE_STEPS.OVERVIEW;
  return COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS[nextSegmentName];
};
