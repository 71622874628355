import classNames from 'classnames';
import Icon from '../Icon';
import './Anchor.scss';

function Anchor({
  children,
  className,
  align,
  flex,
  fontWeight,
  fontSize,
  showLinkIcon,
  ...props
}) {
  return (
    <a
      className={classNames('ch-anchor', {
        [className]: !!className,
        flex: !!flex
      })}
      style={{
        textAlign: align,
        fontWeight,
        fontSize
      }}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
      {showLinkIcon && <Icon size={14} name="ExternalLink" className="ml-2" />}
    </a>
  );
}

export default Anchor;
