function SwitchHorizontal() {
  return (
    <path
      d="M1 15L5 11M5 5H17H5ZM17 5L13 1L17 5ZM17 5L13 9L17 5ZM13 15H1H13ZM1 15L5 19L1 15Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default SwitchHorizontal;
