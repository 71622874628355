import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class ChcProviderService {
  static async listCHCProviders(params, onSuccess, onError, onFinal) {
    try {
      const { integrationSlug } = params;
      const API_URL = `${ApiRoutes.CHC_UTILITY_PROVIDER}/list`.replace(
        ':integrationSlug',
        integrationSlug
      );
      const response = await AxiosInstance.get(API_URL, { params });
      onSuccess?.(response.data?.utilityProviders);
      return response.data?.utilityProviders;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createCHCProvider({ params }, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_UTILITY_PROVIDER}`;
      await AxiosInstance.post(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getCHCProvider({ params }, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.CHC_UTILITY_PROVIDER}`;
      await AxiosInstance.post(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createCompanyCHCProvider(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_COMPANY_UTILITY_PROVIDER.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(API_URL, { ...params });
      onSuccess?.(response?.data);
      return response?.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompanyCHCProvider(
    { companySlug, utilityProviderId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_COMPANY_UTILITY_PROVIDER_CONNECTED.replace(
        ':companySlug',
        companySlug
      ).replace(':utilityProviderId', utilityProviderId)}`;
      await AxiosInstance.put(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default ChcProviderService;
