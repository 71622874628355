import { Col, Row, Steps } from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from '../../../../components';
import { COLOURS_VIOLET } from '../../../../constants/colours';
import { INITIATIVE_TYPE } from '../../../../constants/InitiativeType';
import { useInitiativeContext } from '../../../../context/InitiativeContext';
import { CompanyInitiativeList } from '../../CompanyInitiative';
import { AppRoutes } from '../../../../routes';
import { Paragraph, Title } from '../../../../stories/atoms';
import { InitiativeCard } from '../../../../stories/molecules/Initiative';
import './InitiativeTemplateOverview.scss';

export function InitiativeTemplateOverview({ analyticsContext }) {
  const { initiativeState } = useInitiativeContext();
  const { initiativeTemplate } = initiativeState;
  const { Step } = Steps;
  const location = useLocation();
  return (
    <Row gutter={[0, 40]} className="initiative-template-overview">
      <Col span={24}>
        <Title bottomSpacing={16}>Initiatives using this template</Title>
        <CompanyInitiativeList
          analyticsContext={{
            ...analyticsContext,
            level3: 'Active Initiatives'
          }}
          isComplete={false}
        />
      </Col>
      {initiativeTemplate.relatedInitiatives && (
        <Col span={24}>
          <Title bottomSpacing={16}>Prerequisite initiatives</Title>

          <Row>
            <Col>
              {initiativeTemplate.relatedInitiatives?.map((template) => (
                <Link
                  key={template.id}
                  to={
                    template.type === INITIATIVE_TYPE.MEASUREMENT
                      ? AppRoutes.TRAILS_OVERVIEW
                      : AppRoutes.INITIATIVE_TEMPLATE.replace(
                          ':initiativeTemplateId',
                          template.id
                        )
                  }
                  state={{ prevRoute: location.pathname }}
                >
                  <InitiativeCard card={template} />
                </Link>
              ))}
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Title bottomSpacing={16}>How do reduction initiatives work?</Title>
        <Card>
          <Title bottomSpacing={0}>Planning</Title>
          <Paragraph bottomSpacing={24}>
            Select sources impacted by your initiatives and view/modify the
            estimated reductions
          </Paragraph>
          <Steps
            className="initiative-template-overview__steps"
            direction="vertical"
            size="small"
          >
            <Step
              status="process"
              title={
                <Title bottomSpacing={4} color={COLOURS_VIOLET.VIOLET_700}>
                  Forecasting
                </Title>
              }
              description={
                <Paragraph color={COLOURS_VIOLET.VIOLET_700} bottomSpacing={0}>
                  Select sources impacted by your initiatives and view/modify
                  the estimated reductions.
                </Paragraph>
              }
            />
            <Step
              status="process"
              title={
                <Title bottomSpacing={4} color={COLOURS_VIOLET.VIOLET_700}>
                  Business plan
                </Title>
              }
              description={
                <Paragraph color={COLOURS_VIOLET.VIOLET_700} bottomSpacing={0}>
                  Plan around the financial impact of the reduction initiative.
                </Paragraph>
              }
            />
            <Step
              status="process"
              title={
                <Title bottomSpacing={4} color={COLOURS_VIOLET.VIOLET_700}>
                  Funding
                </Title>
              }
              description={
                <Paragraph color={COLOURS_VIOLET.VIOLET_700} bottomSpacing={0}>
                  Browse & Select your potential grants & financial
                  partnerships.
                </Paragraph>
              }
            />
          </Steps>
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <Title bottomSpacing={0}>Recommended Actions</Title>
          <Paragraph bottomSpacing={24}>
            You’ve done your planning & forecasting and it’s time to action your
            plan.
          </Paragraph>
          <Steps
            className="initiative-template-overview__steps"
            direction="vertical"
            size="small"
          >
            <Step
              status="process"
              title={
                <Title bottomSpacing={4} color={COLOURS_VIOLET.VIOLET_700}>
                  Initiative Implementation
                </Title>
              }
              description={
                <Paragraph color={COLOURS_VIOLET.VIOLET_700} bottomSpacing={0}>
                  We will give you a set of actions recommended for this
                  initiative to go well, but we will also allow you to add and
                  assign your own custom actions.
                </Paragraph>
              }
            />
          </Steps>
        </Card>
      </Col>
    </Row>
  );
}
