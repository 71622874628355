import { Checkbox, Col, Row, Space } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { COLOURS_GRAY } from '../../../../constants/colours';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { getEmissionSourceDetailsName } from '../../../../helpers';
import {
  getCollectionLocation,
  getFormattedAddress
} from '../../../../helpers/generators';
import { useAsync } from '../../../../hooks';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { Button, Paragraph, Tag } from '../../../../stories/atoms';
import Input from '../../../../stories/atoms/Input';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import { TAG_COLOURS } from '../../../../stories/atoms/Tag/tag.types';
import './InitiativeTemplateImpactedSourcesForm.scss';

export function InitiativeTemplateImpactedSourcesForm({ onSuccess }) {
  const { initiativeState, handleAddRelatedSource } = useInitiativeContext();
  const { companyInitiativeImpactedSources } = initiativeState;

  const { me } = useMeContext();

  const [filteredEmissionSources, setFilteredEmissionSources] = useState([]);

  const loadEmissionSources = useCallback(async () => {
    const response = await EmissionSourceService.listEmissionSourceDetails({
      companySlug: me.company?.slug
    });
    setFilteredEmissionSources(response.list);
    return response.list.filter(
      (item) =>
        companyInitiativeImpactedSources.findIndex(
          (source) => source.id === item.id
        ) === -1
    );
  }, [me.company?.slug]);

  const { Loader, value: emissionSources } = useAsync({
    asyncFunction: loadEmissionSources,
    defaultValue: []
  });

  const handleSearchFactors = (e) => {
    setFilteredEmissionSources(
      emissionSources.filter((source) =>
        getEmissionSourceDetailsName(source)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleAddFactor = async ({ emissionSourceDetailsIds }) => {
    await handleAddRelatedSource(emissionSourceDetailsIds);
    onSuccess?.();
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleAddFactor,
    immediate: false
  });

  return (
    <Row className="initiative-template-impacted-sources-form">
      <Col span={24}>
        <Input
          isFormikField={false}
          type={INPUT_TYPES.SEARCH}
          onChange={handleSearchFactors}
          placeholder="Search Emission Sources"
        />
        <Formik
          onSubmit={execute}
          initialValues={{
            emissionSourceDetailsIds: []
          }}
        >
          {({ values, setFieldValue }) => {
            const handleOnChange = (value) => {
              setFieldValue('emissionSourceDetailsIds', value);
            };
            return (
              <Form>
                <Field name="emissionSourceDetailsIds">
                  {() => (
                    <Loader>
                      <Checkbox.Group onChange={handleOnChange}>
                        <Space direction="vertical">
                          {filteredEmissionSources.map((source) => {
                            const emissionSourceDetailsName =
                              getEmissionSourceDetailsName(source);
                            const collection = getCollectionLocation(source);
                            const location = collection?.location;
                            return (
                              <Checkbox key={source.id} value={source.id}>
                                <Row
                                  gutter={16}
                                  align="middle"
                                  wrap={false}
                                  justify="space-between"
                                >
                                  <Col flex={1}>
                                    {emissionSourceDetailsName}
                                  </Col>
                                  {!!location && (
                                    <Col>
                                      <Row
                                        gutter={16}
                                        wrap={false}
                                        justify="end"
                                      >
                                        <Col>
                                          {!source.isInternal && (
                                            <Tag color={TAG_COLOURS.BLUE}>
                                              Custom
                                            </Tag>
                                          )}
                                        </Col>
                                        <Col>
                                          <Paragraph
                                            bottomSpacing={0}
                                            size="sm"
                                            color={COLOURS_GRAY.GRAY_500}
                                            className="initiative-template-impacted-sources-form__address"
                                          >
                                            {getFormattedAddress({
                                              location,
                                              name: collection.name
                                            })}
                                          </Paragraph>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </Row>
                              </Checkbox>
                            );
                          })}
                        </Space>
                      </Checkbox.Group>
                    </Loader>
                  )}
                </Field>
                <Row
                  justify="end"
                  className="initiative-template-impacted-sources-form__footer"
                >
                  <Col>
                    <Button
                      isLoading={isLoading}
                      disabled={
                        isLoading || !values.emissionSourceDetailsIds.length
                      }
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
}
