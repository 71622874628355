import { Progress } from 'antd';
import { useEffect, useRef, useState } from 'react';
import doghouse from '../../assets/images/doghouse.svg';
import runningDog from '../../assets/images/runningDog.gif';
import { Paragraph } from '../../stories/atoms';
import './AnimatedProgress.scss';

function AnimatedProgress({
  strokeColor = '#FBAB1D',
  trailColor = '#F4F4F5',
  percent,
  showPercent,
  ...props
}) {
  const animatedProgressRef = useRef(null);
  const animatedIconRef = useRef(null);
  const [progressIconLeftPosition, setProgressIconLeftPosition] = useState(0);
  const [progressIconTranslateOffset, setProgressIconTranslateOffset] =
    useState(0);

  useEffect(() => {
    if (!!animatedIconRef?.current) {
      setProgressIconLeftPosition(
        (percent * animatedProgressRef?.current?.offsetWidth) / 100
      );
    }
  }, [percent, progressIconLeftPosition, animatedProgressRef?.current]);

  return (
    <div className="ch-animated-progress">
      {showPercent && (
        <Paragraph weight={600} color={strokeColor} bottomSpacing={0}>
          {`${percent}%`}
        </Paragraph>
      )}
      <div className="ch-animated-progress__bar" ref={animatedProgressRef}>
        <Progress
          showInfo={false}
          className=""
          strokeColor={strokeColor}
          trailColor={trailColor}
          percent={percent}
          {...props}
        />
        {percent < 100 && (
          <div
            style={{
              left: `${progressIconLeftPosition}px`,
              transform: `translate(-${progressIconTranslateOffset}px, -50%)`
            }}
            className="ch-animated-progress__bar__pending-icon ch-animated-progress__bar--icon"
          >
            <img
              onLoad={() => {
                // This needs to happen in the onLoad handler because the image width is 0 until the image loads in
                setProgressIconTranslateOffset(
                  animatedIconRef?.current?.offsetWidth
                );
              }}
              ref={animatedIconRef}
              src={runningDog}
              alt="running dog"
            />
          </div>
        )}
        <div className="ch-animated-progress__bar__end-icon ch-animated-progress__bar--icon">
          <img src={doghouse} alt="" />
        </div>
      </div>
    </div>
  );
}
export default AnimatedProgress;
