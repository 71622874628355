import { Steps as AntSteps } from 'antd';
import React from 'react';
import lockIcon from '../../assets/images/lock.svg';
import { Tooltip } from '../index';
import './steps.scss';

function Steps({ children, steps, ...props }) {
  return (
    <AntSteps {...props} className="ch-steps">
      {children && children}
      {steps &&
        steps.map((step, i) => (
          <AntSteps.Step
            key={i}
            className={`${step.isLocked ? 'ch-steps__locked' : ''}`}
            title={
              <Tooltip
                overlayClassName="ch-steps__tooltip"
                title={
                  <div>
                    <div className="text-sbd">
                      {i > props.current || step.isLocked
                        ? 'Coming Soon'
                        : `Segment ${i + 1}`}
                    </div>
                    <div>{step.description}</div>
                  </div>
                }
              >
                <span>
                  {i > props.current || step.isLocked ? (
                    <img src={lockIcon} alt="Lock icon" />
                  ) : (
                    i + 1
                  )}
                </span>
              </Tooltip>
            }
          />
        ))}
    </AntSteps>
  );
}

export default Steps;
