import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import { BulkUploadType } from '../../../constants';
import { useMeContext } from '../../../context';
import {
  formatDecimal,
  getCarbonYear,
  getUnitFriendlyName,
  analytics
} from '../../../helpers';
import { getTimeFormat } from '../../../helpers/generators';
import { useAsync, useModal, usePagination } from '../../../hooks';
import { EmissionSourceService } from '../../../services';
import { Button, ButtonIcon, Table } from '../../../stories/atoms';
import CompletionTimeline from '../../../stories/molecules/CompletionTimeline';
import { COMPLETION_TIMELINE_MODES } from '../../../stories/molecules/CompletionTimeline/CompletetionTimeline.constants';
import { DataImportButton } from '../../../stories/molecules/DataImportButton';
import EmissionSourceItemForm from '../../EmissionSource/EmissionSourceItem/EmissionSourceItemForm';

export function ActionsCustomEmissionSourceForm({
  action,
  analyticsContext,
  setIsDataIncomplete
}) {
  const [activeItem, setActiveItem] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [emissionSourceDetails, setEmissionSourceDetails] = useState({});
  const { details } = action;
  const { emissionSourceDetailsId } = details;
  const { date } = details;
  const { me } = useMeContext();

  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );
  const { rangeStart, rangeEnd } = getCarbonYear({
    startingMonth: me?.company?.startingMonth,
    year: date ? new Date(date).getUTCFullYear() : null
  });

  const {
    Modal: ItemModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onCloseModal: () => {
      setActiveItem({});
    }
  });

  const { paginationParams } = usePagination({
    pageSize: 10,
    paginationArray: dataSource,
    orderBy: ['id']
  });

  const handleLoadTableData = useCallback(async () => {
    const emissionSourceDetailsRes =
      await EmissionSourceService.getEmissionSourceDetails(
        emissionSourceDetailsId
      );
    setEmissionSourceDetails(emissionSourceDetailsRes);
    const { list, count } = await EmissionSourceService.listEmissionSourceItems(
      {
        emissionSourceDetailsId,
        isComplete: true,
        pagination: paginationParams,
        rangeStart,
        rangeEnd
      }
    );
    setDataSource(list);
    return count;
  }, [emissionSourceDetailsId, paginationParams.pageNumber]);

  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData,
    defaultValue: []
  });

  const fetchOfficeTimelineSummary = useCallback(async () => {
    if (emissionSourceDetailsId) {
      const tl = await EmissionSourceService.fetchEmissionSourceDetailsTimeline(
        {
          emissionSourceDetailsId,
          rangeStart,
          rangeEnd
        }
      );

      setIsDataIncomplete(tl.length === 0 || tl?.find((t) => !t.isComplete));
      return tl;
    }
    return [];
  }, [emissionSourceDetailsId]);

  const handlePromptForDelete = (item) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        handleOnDelete(item);
      }
    });
  };

  const deleteItem = async (item) => {
    await EmissionSourceService.deleteEmissionSourceItem(item.id);
    analytics.track('Delete item clicked', me, {
      ...analyticsContext,
      notification: action,
      emissionSourceItem: item
    });

    refreshList();
    refreshTimeline();
  };
  const { execute: handleOnDelete } = useAsync({
    asyncFunction: deleteItem,
    immediate: false
  });

  const handleEditItem = (item) => {
    analytics.track(item.id ? 'Edit Item Clicked' : 'Add new Selected', me, {
      ...analyticsContext,
      notification: action,
      emissionSourceItem: item
    });
    if (item.id) {
      setActiveItem(item);
    }
    handleShowModal();
  };

  const columns = [
    {
      title: emissionSourceDetails?.dateType === 'SINGLE' ? 'Date' : 'Period',
      onCell: () => ({
        className: 'text-bd'
      }),
      render: (id, record) => {
        if (emissionSourceDetails?.dateType === 'SINGLE') {
          return record?.date
            ? `${formatInTimeZone(
                new Date(record?.date),
                'UTC',
                dateDisplayFormat
              )}`
            : '';
        }
        return record?.startDate && record?.endDate
          ? `${formatInTimeZone(
              new Date(record?.startDate),
              'UTC',
              dateDisplayFormat
            )} - ${formatInTimeZone(
              new Date(record?.endDate),
              'UTC',
              dateDisplayFormat
            )}`
          : '';
      }
    },
    {
      title: 'Usage',
      dataIndex: 'activity',
      render: (activity) => formatDecimal(activity)
    },
    {
      title: 'Unit',
      dataIndex: 'activityUnit',
      render: (activityUnit) => getUnitFriendlyName(activityUnit)
    },
    {
      render: (_, item) => (
        <Space className="energy-item-list__actions justify-end">
          <ButtonIcon
            scaleIcon={false}
            name="Pencil"
            size={18}
            onClick={() => handleEditItem(item)}
          />
          <ButtonIcon
            scaleIcon={false}
            name="MinusCircle"
            size={18}
            onClick={() => handlePromptForDelete(item)}
          />
        </Space>
      )
    }
  ];
  const {
    value: timeline,
    isLoading: isLoadingTimeline,
    execute: refreshTimeline
  } = useAsync({
    asyncFunction: fetchOfficeTimelineSummary,
    defaultValue: []
  });

  const handleRefreshView = () => {
    refreshList();
    refreshTimeline();
  };

  const handleItemFormSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      level3: 'Add Activity Item',
      notification: action,
      emissionSourceItem: activeItem
    });
    handleRefreshView();
    handleCloseModal();
  };

  const dataImportSettings = {
    onSuccess: handleItemFormSuccess,
    notificationReqParams: {
      emissionSourceDetailsId
    },
    notification: {
      details: {
        uploadType: BulkUploadType.CUSTOM
      }
    }
  };
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <CompletionTimeline
          mode={COMPLETION_TIMELINE_MODES.DARK}
          timeline={timeline}
          isLoading={isLoadingTimeline}
        />
      </Col>
      <Col span={24}>
        <Row justify="end" gutter={[0, 16]}>
          <Col span={24} className="justify-end display-flex">
            <Space>
              <DataImportButton
                onSuccess={handleRefreshView}
                analyticsContext={analyticsContext}
                {...dataImportSettings}
              />
              <Button onClick={handleEditItem}>Add Item</Button>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              isHeaderBorder={false}
              isLoading={isLoading}
              total={count}
              showPagination
              pagination={paginationParams}
              dataSource={dataSource}
              columns={columns}
            />
          </Col>
        </Row>
      </Col>
      <ItemModal>
        <EmissionSourceItemForm
          unitFamilyName={emissionSourceDetails?.emissionFactor?.unitFamilyName}
          emissionSourceDetails={emissionSourceDetails}
          emissionSourceItem={activeItem}
          onSuccess={handleItemFormSuccess}
        />
      </ItemModal>
    </Row>
  );
}
