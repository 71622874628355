import { Col, Row } from 'antd';
import { Form, Formik, useFormikContext } from 'formik';
import React from 'react';
import { useAsync } from '../../../hooks';
import { IntegrationService } from '../../../services';
import { Button, Input, Paragraph } from '../../atoms';
import { ProviderFormValidation } from './ProviderFormValidation';
import { useMeContext } from "../../../context";

function ProviderForm({ onSuccess, integration }) {
  const { setFieldValue } = useFormikContext();
  const { me } = useMeContext();
  const { slug, urlSlug } = integration;
  const handleSubmit = async (values) => {
    const upsertLocation = await IntegrationService.createProvider({
      ...values,
      type: slug,
      integrationSlug: urlSlug,
      companySlug: me.company?.slug,
    });

    setFieldValue('utilityProviderId', upsertLocation.id);
    onSuccess?.(upsertLocation);
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  return (
    <div className="location-form">
      <Paragraph size="sm">
        We may be in touch if this provider requires more information
      </Paragraph>
      <Formik
        initialValues={{ name: '', authUrl: '' }}
        validationSchema={ProviderFormValidation}
        onSubmit={execute}
        enableReinitialize
      >
        {({ isValid }) => (
          <Form>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Input title="Provider Name" name="name" />
              </Col>
              <Col span={24}>
                <Input title="Login Site URL (Optional)" name="authUrl" />
              </Col>
            </Row>
            <Row justify="end">
              <Button
                disabled={isLoading || !isValid}
                loading={isLoading}
                className="mt-16"
                htmlType="submit"
              >
                Save
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default ProviderForm;
