import { useBulkUploadContext } from '../../../context';
import { Button } from '../../../stories/atoms';
import { Footer } from '../Footer';
import './Complete.scss';

function Complete() {
  const { bulkUploadState } = useBulkUploadContext();
  const { onSuccess } = bulkUploadState;
  return (
    <div className="bulk-upload-form-complete">
      <div className="bulk-upload-form-complete__button">
        <Button onClick={onSuccess}>Done</Button>
      </div>
      <Footer
        canGoForward={false}
        canGoBack={false}
        analyticsStepContext="Completed"
      />
    </div>
  );
}
export default Complete;
