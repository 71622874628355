import { Layout } from 'antd';
import classNames from 'classnames';
import './Footer.scss';

const { Footer: AntFooter } = Layout;

export function Footer({ children, className }) {
  return (
    <AntFooter
      className={classNames('ch-layout-footer', {
        [className]: !!className
      })}
    >
      {children}
    </AntFooter>
  );
}
