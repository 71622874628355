import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class LocationService {
  static async fetchCompanyLocations(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.COLLECTION_LIST.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.collections || [],
        count: response.data?.totalRowCount || 0
      });
      return {
        list: response.data?.collections || [],
        count: response.data?.totalRowCount || 0
      };
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createLocation(
    { companySlug, location, isCollection },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const response = await axiosInstance.post(
        `${ApiRoutes.LOCATION}/company/${companySlug}`,
        {
          isCollection,
          ...location
        }
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateLocation(
    { location, isCollection },
    onSuccess,
    onError,
    onFinal
  ) {
    if (location.isCurrentOffice) {
      location.moveOutDate = null;
    }
    try {
      const response = await axiosInstance.put(
        `${ApiRoutes.LOCATION}/${location.locationId}`,
        {
          isCollection,
          ...location
        }
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static deleteCompanyLocation(
    _companySlug,
    collectionId,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.COLLECTION}/${collectionId}`;
    axiosInstance
      .delete(API_URL)
      .then((_response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}

export default LocationService;
