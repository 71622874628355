import { Col, Row } from 'antd';
import { Anchor, Paragraph, Title } from '../../../stories/atoms';
import { IntensityMetricTable } from '../IntensityMetricTable';
import './IntensityMetricTab.scss';

export function IntensityMetricTab() {
  return (
    <Row className="intensity-metric-tab" gutter={[0, 16]}>
      <Col span={24} className="intensity-metric-tab__callout">
        <Row align="middle" wrap={false} gutter={24}>
          <Col flex={1}>
            <Title size="lg" bottomSpacing={4}>
              What are intensity metrics?
            </Title>
            <Paragraph bottomSpacing={0}>
              Your intensity metrics are details about your business you can
              scale your data by, such as number of employees, annual revenue,
              sq footage etc.
            </Paragraph>
          </Col>
          <Col>
            <Anchor
              fontWeight={600}
              href="https://www.notion.so/carbonhound/Intensity-Metrics-802732158e35479f9bf681abaf46175a?pvs=4"
            >
              Read more
            </Anchor>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <IntensityMetricTable />
      </Col>
    </Row>
  );
}
