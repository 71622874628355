import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class EmissionSourceService {
  static async listEmissionSourceDetails(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_LIST.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.emissionSourceDetails,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.emissionSourceDetails,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listMinimalEmissionSourceDetails(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_LIST.replace(
        ':companySlug',
        companySlug
      )}/list/minimal`;
      const response = await AxiosInstance.post(API_URL, {}, { params });
      onSuccess?.({
        list: response.data?.emissionSourceDetails,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.emissionSourceDetails,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listEmissionSourceItems(
    { emissionSourceDetailsId, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_ITEM_LIST.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      )}`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.emissionSourceItems,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.emissionSourceItems,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getEmissionSourceDetails(id, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_DETAILS.replace(
        ':emissionSourceDetailsId',
        id
      )}`;
      const response = await AxiosInstance.get(API_URL);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createEmissionSourcesDetails(
    { companySlug, source },
    onSuccess,
    onError,
    onFinal
  ) {
    const tagIds = [source.tagIds];
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_LIST.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(API_URL, {
        ...source,
        tagIds
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateEmissionSourcesDetails(
    source,
    onSuccess,
    onError,
    onFinal
  ) {
    const tagIds = source.tagIds ? [source.tagIds] : undefined;
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_DETAILS.replace(
        ':emissionSourceDetailsId',
        source.id
      )}`;
      const response = await AxiosInstance.put(API_URL, {
        ...source,
        tagIds
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async deleteEmissionSourcesDetails(
    source,
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_DETAILS.replace(
        ':emissionSourceDetailsId',
        source.id
      )}`;
      const response = await AxiosInstance.delete(API_URL, {});
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getEmissionSourceLocationDetails(
    { companySlug, collectionId, rangeStart, rangeEnd, aggregateType },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCE_COLLECTION_SUMMARY_DRILLDOWN.replace(
        ':companySlug',
        companySlug
      ).replace(':collectionId', collectionId)}`;
      const response = await AxiosInstance.get(API_URL, {
        params: { rangeStart, rangeEnd, aggregateType }
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createEmissionSourcesItem(
    { emissionSourceDetailsId, sourceItem },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_ITEM.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      )}`;
      const response = await AxiosInstance.post(API_URL, { ...sourceItem });

      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateEmissionSourcesItem(
    { esItemId, sourceItem },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_ITEM_UPDATE.replace(
        ':esItemId',
        esItemId
      )}`;
      const response = await AxiosInstance.put(API_URL, { ...sourceItem });

      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async deleteEmissionSourceItem(esItemId, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_ITEM_UPDATE.replace(
        ':esItemId',
        esItemId
      )}`;
      await AxiosInstance.delete(API_URL);

      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  // SUPER FOR REAL DELETE
  static async hardDeleteEmissionSourceItem(
    detailsId,
    { uploadProcessId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_UPLOAD_CLEAR.replace(
        ':detailsId',
        detailsId
      )}`;
      await AxiosInstance.delete(API_URL, {
        data: {
          uploadProcessId
        }
      });

      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionSourceDetailsTimeline(
    { emissionSourceDetailsId, rangeStart, rangeEnd },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_DETAILS_TIMELINE.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      )}`;
      const response = await AxiosInstance.get(API_URL, {
        params: { rangeStart, rangeEnd }
      });
      onSuccess?.(response.data?.timeline);
      return response.data?.timeline;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionSourceDetailDrilldown(
    { emissionSourceDetailsId, rangeStart, rangeEnd, keyName },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_DETAILS_DRILLDOWN.replace(
        ':esDetailsId',
        emissionSourceDetailsId
      )}`;
      const response = await AxiosInstance.get(API_URL, {
        params: { rangeStart, rangeEnd, keyName }
      });

      onSuccess?.(response.data?.emissionSummary);
      return response.data?.emissionSummary;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionSourceCarbonTrackerDrilldown(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_CARBON_TRACKER.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.get(API_URL, {
        params
      });
      onSuccess?.(response.data?.summary);
      return response.data?.summary;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionSourceImportList(
    { companySlug, origin },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.EMISSION_SOURCES_IMPORT_LIST.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(
        API_URL,
        {},
        { params: { ...(origin && { origin }) } }
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async clearSourceEstimations({ emissionSourceDetailsId }) {
    try {
      const BASE_URL = `${ApiRoutes.EMISSION_SOURCE_COLLECTION_ESTIMATION_CLEAR}`;
      const API_URL = BASE_URL.replace(
        ':emissionSourceDetailsId',
        emissionSourceDetailsId
      );
      const response = await AxiosInstance.delete(API_URL);
      return response;
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
  }
}

export default EmissionSourceService;
