import React, { useState } from 'react';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { useDrawer } from '../../../hooks/useDrawer';
import { Button } from '../../../stories/atoms';
import { analytics } from '../../../helpers';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { InviteEmailPreviewMessage } from './InviteEmailPreviewMessage';
import { InviteEmailPreviewSuccess } from './InviteEmailPreviewSuccess';

function InviteEmailPreviewButton({ buttonProps }) {
  const { me } = useMeContext();
  const [showEmailSuccessScreen, setShowEmailSuccessScreen] = useState(false);
  const { Drawer, handleShowDrawer, handleCloseDrawer } = useDrawer({
    onShowDrawer: () => {
      analytics.track('Preview invite clicked', me, {
        level1: 'Office-Based Operations',
        level2: 'Employee Details',
        level3: 'Add and Invite Employees'
      });
    },
    onCloseDrawer: () => {
      setShowEmailSuccessScreen(false);
    }
  });

  const sendInvite = async () => {
    try {
      await buttonProps.onSubmit();
      analytics.track('Step Completed', me, {
        level1: 'Office-Based Operations',
        level2: 'Employee Details',
        level3: 'Add and Invite Employees',
        level4: 'Email Preview'
      });
      setShowEmailSuccessScreen(true);
    } catch (e) {
      console.error(e);
    }
  };
  const { execute: handleSendInvite } = useAsync({
    asyncFunction: sendInvite,
    immediate: false
  });

  return (
    <>
      <Button onClick={handleShowDrawer} type={buttonProps.type}>
        {buttonProps.text}
      </Button>
      <Drawer
        title="Email Preview"
        buttons={
          showEmailSuccessScreen
            ? []
            : [
                {
                  onClick: handleCloseDrawer,
                  children: 'Back',
                  type: BUTTON_TYPES.SECONDARY
                },
                {
                  onClick: handleSendInvite,
                  children: 'Send Invitation(s)'
                }
              ]
        }
      >
        {showEmailSuccessScreen ? (
          <InviteEmailPreviewSuccess onClose={handleCloseDrawer} />
        ) : (
          <InviteEmailPreviewMessage />
        )}
      </Drawer>
    </>
  );
}

export default InviteEmailPreviewButton;
