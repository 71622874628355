import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Alert as AntAlert } from 'antd';
import classNames from 'classnames';
import './Alert.scss';

const setAlertIcon = ({ type }) => {
  switch (type) {
    case 'success':
      return <CheckCircleOutlined />;
    case 'error':
      return <CloseCircleOutlined />;
    default:
      return null;
  }
};
function Alert({ isVisible, handleCloseAlert, ...props }) {
  const icon = props.icon || setAlertIcon({ type: props.type });
  return isVisible ? (
    <AntAlert
      className={classNames('ch-alert text-left mt-3', {
        success: props.type === 'success',
        error: props.type === 'error',
        warning: props.type === 'warning',
        info: props.type === 'info'
      })}
      {...props}
      onClose={handleCloseAlert}
      icon={icon}
    />
  ) : null;
}

export default Alert;
