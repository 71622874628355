import { Col } from 'antd';
import { isAfter } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useFormikContext } from 'formik';
import { Button, InputNumberField } from '../../../atoms';
import { BUTTON_TYPES } from '../../../atoms/Button/Button.types';

function DataRow({ list, year }) {
  const { values, setValues } = useFormikContext();
  const applyValueToYear = (value, year) => {
    setValues({
      ...values,
      [year]: list[year].map((month) => ({
        ...month,
        value
      }))
    });
  };
  return (
    <>
      {list[year].map((val, i) => {
        const fieldMonth = formatInTimeZone(
          val.date,
          'UTC',

          'MMM'
        );
        const isMonthDisabled = isAfter(val.date, new Date());
        return (
          <Col
            key={formatInTimeZone(val.date, 'UTC', 'yyyy-MM')}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={6}>
            <InputNumberField
              controls={false}
              disabled={isMonthDisabled}
              placeholder="Enter"
              className="target-setting-data__month-input"
              title={fieldMonth}
              name={`${year}.${i}.value`}
              stringMode={false}
              value={values?.[year]?.[i]?.value}
            />
            {i === 0 && !!values?.[year]?.[i]?.value && (
              <Button
                className="mt-3"
                type={BUTTON_TYPES.TEXT}
                onClick={() =>
                  applyValueToYear(values?.[year]?.[i]?.value, year)
                }>
                Apply to all
              </Button>
            )}
          </Col>
        );
      })}
    </>
  );
}

export default DataRow;
