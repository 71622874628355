import { EMISSION_SOURCE_ITEM_ORIGIN } from '../constants/emissionSourceItem';
import { formatDecimal } from '../helpers';
import { formatEmissions, formatSingleEmission } from '../helpers/generators';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class DashboardService {
  static async fetchCarbonTrackerData(params, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.CARBON_TRACKER_CHART, {
        params
      });

      onSuccess?.(response.data.emissionSummary);
      return response.data.emissionSummary;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchRelativeEmissions(params, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.RELATIVE_EMISSIONS, {
        params
      });
      onSuccess?.(response.data?.relativeEmissions);
      return response.data?.relativeEmissions;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchBaselineRelativeEmissions(
    { params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const response = await axiosInstance.get(
        ApiRoutes.RELATIVE_EMISSIONS_BASELINE,
        {
          params
        }
      );
      onSuccess?.(response.data?.relativeEmissions);
      return response.data?.relativeEmissions;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionsSum(params, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.EMISSIONS_SUM, {
        params
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchDataAccuracyDrilldown(params) {
    try {
      const response = await axiosInstance.get(
        ApiRoutes.EMISSIONS_DATA_ACCURACY,
        {
          params
        }
      );
      const emissionsDrilldownInternalIndex =
        response?.data?.emissionsDrilldown.findIndex(
          (edi) => edi.key === EMISSION_SOURCE_ITEM_ORIGIN.INTERNAL_ESTIMATION
        );
      if (emissionsDrilldownInternalIndex !== -1) {
        response?.data?.emissionsDrilldown.splice(
          emissionsDrilldownInternalIndex,
          1
        );
      }
      const formattedResponse = {
        total: formatDecimal(formatSingleEmission(response?.data?.total)),
        emissionsDrilldown: formatEmissions({
          emissions: response?.data?.emissionsDrilldown,
          decimalPlaces: 2
        })
      };
      return formattedResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default DashboardService;
