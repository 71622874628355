import { Collapse as AntCollapse } from 'antd';
import { Collapse } from '../index';
import './NestedCollapse.scss';
import PanelHeader from './PanelHeader';

/**
 * A component that recursively generates markup based on a provided schema.
 * @param schemaArray Data structure to be mapped through.
 * @param index The index for the panel. This is required for the accordion functionality to work. The structure should
 * be in the following format:
 *   {
 *     header: "Scope 2",
 *     children: []
 *     panelExtra: "0 tonnes Co2"
 *   }
 *
 *   For recursive nesting, place like objects under the children array
 * @param nestingLevel This prop should always start at one. You can actually not even pass it. The main use of this
 *     parameter is internal for the recursion
 * @param props Any props passed down from the ant component.
 * @returns {JSX.Element}
 * @constructor
 */
function NestedCollapse({
  schemaArray,
  nestingLevel = 0,
  handleActiveLevelPanel,
  activePanels,
  handleSetActivePanel
}) {
  const { Panel } = AntCollapse;
  const isEven = nestingLevel % 2 == 0;
  return (
    <Collapse
      accordion
      expandIconPosition="right"
      ghost
      className={`nested-collapse nested-collapse__${
        isEven ? 'even' : 'odd'
      } nested-collapse__level--${nestingLevel}`}
      activeKey={activePanels[nestingLevel]}
      onChange={(panelIndex) => {
        handleActiveLevelPanel({
          panelIndex,
          nestingLevel,
          handleSetActivePanel
        });
        if (!schemaArray[panelIndex]?.children) {
          schemaArray[panelIndex]?.onChangeFn?.(panelIndex, activePanels);
        }
      }}
    >
      {schemaArray?.map((schema, index) => (
        <Panel
          className="nested-collapse__panel"
          header={
            <PanelHeader
              details={schema.panelDetails}
              nestingLevel={nestingLevel}
              title={schema.header}
            />
          }
          key={index}
          // I don't understand why, but this needs to be a self-invoking function
          extra={(() => (
            <span className="nested-collapse__panel__extra">
              {`${schema.panelExtra}`}
            </span>
          ))()}
          showArrow={schema.showArrow || !!schema.children?.length}
        >
          {!!schema.children?.length && (
            <NestedCollapse
              handleActiveLevelPanel={handleActiveLevelPanel}
              nestingLevel={nestingLevel + 1}
              schemaArray={schema.children}
              activePanels={activePanels}
              handleSetActivePanel={handleSetActivePanel}
            />
          )}
        </Panel>
      ))}
    </Collapse>
  );
}

export default NestedCollapse;
