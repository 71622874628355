import { Col, Row } from 'antd';
import { Input, Title } from '../../../../stories/atoms';

export function UserCompanyDetailsFields() {
  return (
    <Row gutter={[24, 32]}>
      <Col span={24}>
        <Title bottomSpacing={32} size="rg">
          Company Details
        </Title>
      </Col>
      <Col span={12}>
        <Input disabled title="Company name" type="text" name="name" required />
      </Col>
      <Col span={12}>
        <Input disabled title="Industry" type="text" name="industry" required />
      </Col>
      <Col span={12}>
        <Input
          disabled
          title="Headquarters Country"
          type="text"
          name="headQuartersCountryName"
          required
        />
      </Col>
      <Col span={12}>
        <Input
          disabled
          title="Company Size"
          type="text"
          name="companySize"
          required
        />
      </Col>
    </Row>
  );
}
