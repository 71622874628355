export function setVehicleIdKey(
  isCommuteVehicle,
  vehicleObj,
  vehicleId,
  commuteVehicleId
) {
  if (isCommuteVehicle) {
    vehicleObj.commuteVehicleId = commuteVehicleId;
  } else {
    vehicleObj.vehicleAssetId = vehicleId;
  }

  return vehicleObj;
}
