import { Col, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { Card, DataWidget, Popover } from '../../components';
import { DATA_ACCURACY_WIDGET_COLOURS } from '../../constants/dataAccuracy';
import {
  DataContextProvider,
  useInitiativeContext,
  useMeContext,
  useOfficeTrailContext
} from '../../context';
import { CarbonExplorer } from '../../features/CarbonExplorer';
import { DashboardDataAccuracyInformation } from '../../features/Dashboard/DashboardDataAccuracyInformation';
import {
  CompanyOnboardingWidget,
  YourOnboardingWidget
} from '../../features/DashboardOnboardingWidgets';
import { CompanyInitiativeList } from '../../features/Initiative/CompanyInitiative';
import { formatDecimal } from '../../helpers';
import { useAsync, useModal } from '../../hooks';
import { AppRoutes } from '../../routes';
import { DashboardService, VerificationService } from '../../services';
import poweredByCh from '../../stories/assets/svgs/poweredByCh.svg';
import {
  Button,
  DonutChart,
  Icon,
  Img,
  Link,
  Paragraph,
  Tag,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { TAG_COLOURS } from '../../stories/atoms/Tag/tag.types';

import './dashboard.scss';

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: '75%',
  elements: {
    arc: {
      borderWidth: 4
    }
  }
};
export const DATA_ACCURACY_LABELS = {
  'CARBONHOUND CONNECT': 'Audited Data',
  BILL: 'Unaudited Data',
  ESTIMATION: 'Estimated Data'
};

function Dashboard() {
  const { me, isAdmin, isGroupSettingsMember } = useMeContext();
  const { refreshOfficeTrails, refreshEmployeeOnboardingTrails } =
    useOfficeTrailContext();
  const { initiativeState } = useInitiativeContext();
  const { totalInitiativeCount } = initiativeState;
  const [emissionsSum, setEmissionsSum] = useState();

  const [relativeEmissions, setRelativeEmissions] = useState();
  const [baselineRelativeEmissions, setBaselineRelativeEmissions] = useState();

  const handleFetchVerifications = useCallback(async () => {
    if (!!me.company?.slug) {
      const { list } = await VerificationService.listVerificationItems({
        companySlug: me.company?.slug,
        pagination: { orderBy: ['updatedAt'] }
      });
      if (list.length) {
        return list[0];
      }
    }
  }, [me.company?.slug]);
  const { value: verification } = useAsync({
    asyncFunction: handleFetchVerifications,
    defaultValue: undefined
  });
  const handleFetchEmissionsSum = () => {
    DashboardService.fetchEmissionsSum(
      {
        companySlug: me?.company?.slug
      },
      ({ emissions }) => {
        setEmissionsSum(emissions || 0);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchRelativeEmissions = () => {
    DashboardService.fetchRelativeEmissions(
      {
        companySlug: me?.company?.slug
      },
      (relativeEmissions) => {
        setRelativeEmissions(relativeEmissions);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchBaselineRelativeEmissions = () => {
    DashboardService.fetchBaselineRelativeEmissions(
      {
        params: { companySlug: me?.company?.slug }
      },
      (relativeEmissions) => {
        setBaselineRelativeEmissions(relativeEmissions);
      },
      () => {},
      () => {}
    );
  };

  const handleFetchDataAccuracyDrilldown = useCallback(async () => {
    try {
      const response = await DashboardService.fetchDataAccuracyDrilldown({
        companySlug: me?.company?.slug
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  }, []);

  const { Loader: DataAccuracyLoader, value: DataAccuracyDrilldownValues } =
    useAsync({
      asyncFunction: handleFetchDataAccuracyDrilldown,
      defaultValue: { total: 0, emissionsDrilldown: [] }
    });
  const dataAccuracyDataset =
    DataAccuracyDrilldownValues.emissionsDrilldown.reduce(
      (acc, edi) => {
        acc.data.push(edi.emissions);
        acc.dataPercent[edi.key] = `${formatDecimal(
          ((edi.emissions || 0) / (DataAccuracyDrilldownValues.total || 1)) *
            100,
          2
        )}%`;
        acc.backgroundColor.push(DATA_ACCURACY_WIDGET_COLOURS[edi.key]);
        return acc;
      },
      {
        data: [],
        dataPercent: {},
        backgroundColor: []
      }
    );
  const dataAccuracyLabels = [
    DATA_ACCURACY_LABELS['CARBONHOUND CONNECT'],
    DATA_ACCURACY_LABELS.BILL,
    DATA_ACCURACY_LABELS.ESTIMATION
  ];
  const dataAccuracyData = {
    labels: dataAccuracyLabels,
    datasets: [dataAccuracyDataset],
    total: formatDecimal(DataAccuracyDrilldownValues.total, 2)
  };
  const { Modal: DataAccuracyModal, handleShowModal } = useModal({
    width: '90%'
  });
  useEffect(() => {
    if (me?.id) {
      window.pendo.initialize({
        visitor: {
          id: me.id,
          email: me.email
        }
      });
    }
    if (me?.company?.slug) {
      refreshOfficeTrails();
      refreshEmployeeOnboardingTrails();
      handleFetchEmissionsSum();
      handleFetchRelativeEmissions();
      handleFetchBaselineRelativeEmissions();
      handleFetchDataAccuracyDrilldown();
    }
  }, [me]);

  const colSpan = 6;
  const emissions = (emissionsSum || 0) / 1000;
  const emissionsCarsPerYear = Math.round(emissions / 4.6);

  return (
    <div className="dashboard">
      <div>
        <Row
          gutter={[8, 8]}
          className="onboarding-widgets onboarding-widgets-container mb-8"
        >
          <YourOnboardingWidget />
          {!!isAdmin && <CompanyOnboardingWidget />}
        </Row>

        <Row className="mb-8" gutter={[8, 8]}>
          <Col span={colSpan}>
            <DataWidget
              emoji="📈"
              title="Last vs. previous month"
              value={relativeEmissions}
            />
          </Col>

          <Col span={colSpan}>
            <DataWidget
              emoji="📅"
              title={
                <span>
                  Last 12 months vs baseline
                  <Popover
                    content={
                      <>
                        <p>
                          The base year we compare against is the year(s) you
                          selected in your onboarding or set in My Company.
                        </p>
                        <p>
                          If you select multiple years we will create an average
                          of those years, rather than an aggregate.
                        </p>
                      </>
                    }
                  />
                </span>
              }
              value={baselineRelativeEmissions}
            />
          </Col>

          <Col span={colSpan}>
            <DataWidget
              emoji="💰"
              title={
                <span>
                  Current Carbon balance
                  <Popover content="Your carbon balance represents all of the environmental impact your business has made since you started tracking it with Carbonhound less any offsets purchased." />
                </span>
              }
              value={`${formatDecimal((emissionsSum || 0) / 1000)} Tonnes`}
            />
          </Col>

          {isAdmin ? (
            <Col span={colSpan}>
              <DataWidget
                emoji={verification?.status === 'PENDING' ? '⏳' : '✅'}
                title={
                  <span>
                    Last Verification
                    {!verification && (
                      <Popover
                        title="Verification"
                        content="A verification will allow you to ensure your footprint and reporting are accurate"
                      />
                    )}
                  </span>
                }
                value={
                  <div className="dashboard__widget-content">
                    <span>
                      {verification
                        ? verification.status === 'PENDING'
                          ? 'Pending'
                          : formatInTimeZone(
                              verification.updatedAt || verification.createdAt,
                              'UTC',
                              'MMMM, do yyyy'
                            )
                        : 'Unverified'}
                    </span>
                  </div>
                }
                actions={
                  !verification && (
                    <Link fontWeight={600} to={AppRoutes.VERIFICATION}>
                      Verify
                    </Link>
                  )
                }
              />
            </Col>
          ) : (
            <Col span={colSpan}>
              <DataWidget
                emoji="🚗"
                title={<span>Company emissions are equal to</span>}
                value={`${emissionsCarsPerYear} cars/year`}
              />
            </Col>
          )}
        </Row>

        <Row className="mb-8" gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Space size={16} align="center" className="mb-3">
              <Title size="md" bottomSpacing={0}>
                Data Quality
              </Title>
              <Tag color={TAG_COLOURS.RED}>Beta</Tag>
            </Space>
            <Card className="dashboard__data-accuracy__card">
              <DataAccuracyLoader>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={24} wrap={false}>
                      <Col>
                        <DonutChart
                          options={chartOptions}
                          data={dataAccuracyData}
                          width={156}
                          totalCopy="tonnes"
                        />
                      </Col>
                      <Col flex={1}>
                        <Row gutter={[19, 19]}>
                          <Col span={24}>
                            <Title size="rg" bottomSpacing={0}>
                              Data Quality - All Time
                            </Title>
                          </Col>

                          {Object.entries(DATA_ACCURACY_LABELS).map(
                            ([key, label]) => (
                              <Col span={24}>
                                <Row wrap={false} gutter={12}>
                                  <Col flex={1}>{label}</Col>
                                  <Col>
                                    <Space>
                                      <span className="text-sbd">
                                        {
                                          dataAccuracyData.datasets[0]
                                            .dataPercent[key]
                                        }
                                      </span>
                                      <Icon
                                        size={24}
                                        name="CircleFilled"
                                        color={
                                          DATA_ACCURACY_WIDGET_COLOURS[key]
                                        }
                                      />
                                    </Space>
                                  </Col>
                                </Row>
                              </Col>
                            )
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Row
                      align="middle"
                      wrap={false}
                      className="dashboard__data-accuracy__card--blurb"
                    >
                      <Col flex={1}>
                        <Paragraph bottomSpacing={0}>
                          Learn about your data and how to improve your score
                        </Paragraph>
                      </Col>
                      <Col>
                        <Button
                          type={BUTTON_TYPES.TEXT}
                          onClick={handleShowModal}
                        >
                          Learn more
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DataAccuracyLoader>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="dashboard__initiatives__card--inner--not-empty">
              <Row>
                <Col flex={1}>
                  <Title size="md" bottomSpacing={16}>
                    Active Initiatives
                  </Title>
                </Col>
                {!!parseInt(totalInitiativeCount, 10) && (
                  <Col>
                    <Link
                      fontWeight={600}
                      className="ch-link"
                      to={AppRoutes.INITIATIVE}
                    >
                      View All
                    </Link>
                  </Col>
                )}
              </Row>
              <CompanyInitiativeList isComplete={false} showCard />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DataContextProvider>
              <CarbonExplorer />
            </DataContextProvider>
          </Col>
        </Row>
        {isGroupSettingsMember && (
          <Row justify="center" className="mt-9">
            <Col>
              <Img src={poweredByCh} alt="Powered by Carbonhound" />
            </Col>
          </Row>
        )}
      </div>
      <DataAccuracyModal>
        <DashboardDataAccuracyInformation />
      </DataAccuracyModal>
    </div>
  );
}

export default Dashboard;
