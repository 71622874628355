/** MetaContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { Notification } from '../components';
import { NotificationStatus } from '../constants';
import { InitiativeService, NotificationService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const InitiativeActionContext = createContext({});

// Define the default context state
const InitiativeActionState = {
  initiativeAction: {},
  initiativeTemplate: {},
  companyInitiative: {},
  companyInitiativeId: ''
};

// Create method to use context
function useInitiativeActionContext() {
  const context = useContext(InitiativeActionContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useInitiativeActionContext must be used within an InitiativeActionContextProvider'
    );
  }
  const [initiativeActionState, setInitiativeActionState] = context;

  // Context Methods //

  const handleEditNotification = useCallback(
    async (values) => {
      await NotificationService.updateNotification({
        notificationId: values.id,
        ...values
      });
    },
    [me.company?.slug]
  );

  const handleCreateNotification = async (params) => {
    const notification = await NotificationService.createNotification({
      companySlug: me?.company?.slug,
      ...params
    });
    if (!!notification) {
      Notification({
        type: 'success',
        message: 'Action added',
        description: 'You can see it on the actions page'
      });
      setInitiativeActionState({
        ...initiativeActionState,
        initiativeAction: notification
      });
    }
    return notification;
  };

  const handleFetchCompanyInitiative = useCallback(async () => {
    if (
      !!initiativeActionState?.companyInitiative &&
      Object.keys(initiativeActionState.companyInitiative).length
    ) {
      return initiativeActionState;
    }
    if (!initiativeActionState?.companyInitiativeId) return;
    try {
      const companyInitiativeResponse = await InitiativeService.getInitiative(
        initiativeActionState?.companyInitiativeId
      );
      if (companyInitiativeResponse) {
        setInitiativeActionState((prevState) => ({
          ...prevState,
          companyInitiative: companyInitiativeResponse
        }));
      }
    } catch (err) {
      throw Error(err);
    }
  }, [initiativeActionState?.companyInitiativeId]);

  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    handleEditNotification,
    initiativeActionState,
    handleCreateNotification,
    handleFetchCompanyInitiative,
    isDisabledOrComplete:
      !initiativeActionState.initiativeAction.id ||
      initiativeActionState.initiativeAction.status ===
        NotificationStatus.COMPLETE
  };
}

// Create the context provider
function InitiativeActionContextProvider({
  initiativeAction,
  initiativeTemplate,
  companyInitiativeId,
  ...props
}) {
  const { ...initiativeTemplateBase } = initiativeTemplate ?? {};
  const [initiativeActionState, setInitiativeActionState] = useState({
    ...InitiativeActionState,
    initiativeAction: {
      ...initiativeAction,
      details: {
        ...initiativeAction?.details
      }
    },
    companyInitiativeId,
    initiativeTemplate: initiativeTemplateBase
  });
  const value = useMemo(
    () => [initiativeActionState, setInitiativeActionState],
    [initiativeActionState]
  );
  return <InitiativeActionContext.Provider value={value} {...props} />;
}

export { InitiativeActionContextProvider, useInitiativeActionContext };
