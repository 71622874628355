import { Col, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  AddressField,
  Card,
  DatePicker,
  InputField,
  Radio
} from '../../../../../components';
import {
  useMeContext,
  useOfficeOnboardingContext
} from '../../../../../context';
import { analytics } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import { Energy } from '../../../../../models';
import { AppRoutes } from '../../../../../routes';
import { EnergyService, LocationService } from '../../../../../services';
import { Button } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import './officeLocation.scss';
import { officeLocationValidation } from './officeLocationValidation';

const yesOrNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];
function CurrentOptionRadioBtn({ values, setFieldValue }) {
  return (
    <Radio
      title="Company owned building?"
      raised
      value={values?.isOwner}
      name="isOwner"
      options={yesOrNoOptions}
      setFieldValue={setFieldValue}
    />
  );
}

function OfficeLocation({ isOffice = true }) {
  const params = useParams();

  const { collectionId } = params;

  const { state } = useLocation();

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({});

  const [formLoading, setFormLoading] = useState(false);

  const { me } = useMeContext();

  const { officeOnboardingDetails, setActiveCompanyLocation } =
    useOfficeOnboardingContext();

  const handleFormValuesActiveCompanyLocation = useCallback(async () => {
    if (officeOnboardingDetails?.activeCompanyLocation) {
      const { location, name, isOwner, moveInDate, moveOutDate } =
        officeOnboardingDetails.activeCompanyLocation;
      if (location) {
        setFormValues({
          ...formValues,
          address1: location.address1,
          address2: location.address2,
          countryId: location.country?.id,
          city: location.city,
          state: location.state,
          postalCode: location.postalCode,
          locationId: location.id,
          name,
          isOwner,
          moveInDate,
          moveOutDate,
          isCurrentOffice: !moveOutDate
        });
        return {
          moveInDate,
          moveOutDate
        };
      }
    }
  }, [officeOnboardingDetails?.activeCompanyLocation]);

  const { value: initialMoveDates } = useAsync({
    asyncFunction: handleFormValuesActiveCompanyLocation
  });

  const handlePrevious = () => navigate(-1);
  const handleSubmit = (values) => {
    const initialMoveInDate = initialMoveDates?.moveInDate
      ? formatInTimeZone(
          new Date(initialMoveDates.moveInDate),
          'UTC',
          'yyyy-MM-dd'
        )
      : null;
    const changedMoveInDate = values?.moveInDate
      ? formatInTimeZone(new Date(values.moveInDate), 'UTC', 'yyyy-MM-dd')
      : null;
    const initialMoveOutDate = initialMoveDates?.moveOutDate
      ? formatInTimeZone(
          new Date(initialMoveDates.moveOutDate),
          'UTC',
          'yyyy-MM-dd'
        )
      : null;
    const changedMoveOutDate = values?.moveOutDate
      ? formatInTimeZone(new Date(values.moveOutDate), 'UTC', 'yyyy-MM-dd')
      : null;

    const hasMoveDatesChanged =
      (!!initialMoveInDate && initialMoveInDate !== changedMoveInDate) ||
      (!!initialMoveOutDate && initialMoveOutDate !== changedMoveOutDate);

    const companyLocation = {
      ...values,
      companySlug: me.company.slug,
      moveOutDate: values?.isCurrentOffice ? null : values?.moveOutDate,
      isOffice,
      ...(!!hasMoveDatesChanged && { clearEstimations: true }),
      triggerEnergyRecalc: true // This is set to true when updating location info (like movein/out date) and updating energy types happen in different places
    };
    setFormLoading(true);
    const locationMethod = values?.locationId
      ? LocationService.updateLocation
      : LocationService.createLocation;
    locationMethod(
      {
        companySlug: me.company?.slug,
        location: companyLocation,
        isCollection: true
      },
      (location) => {
        const collectionId = location?.collectionId;

        analytics.track('Step Completed', me, {
          level1: 'Office-Based Operations',
          level2: 'Office Onboarding',
          level3: 'Office Location',
          energyDetails: officeOnboardingDetails.activeCompanyLocation
        });
        if (values?.locationId || collectionId) {
          setActiveCompanyLocation({
            location: {
              ...values
            },
            collectionId:
              collectionId ??
              officeOnboardingDetails?.activeCompanyLocation?.collectionId
          });
          navigate(
            generatePath(
              AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OFFICE_DETAILS,
              {
                collectionId:
                  collectionId ??
                  officeOnboardingDetails?.activeCompanyLocation?.collectionId
              }
            )
          );
        }
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const fetchEnergies = useCallback(async () => {
    if (collectionId) {
      EnergyService.fetchEnergies(
        collectionId,
        (energies) => {
          if (energies.length > 0) {
            const energyDetails = Object.assign(new Energy(), energies[0]);
            setActiveCompanyLocation(energyDetails);
            setFormValues({
              ...energyDetails,
              isCurrentOffice: !energyDetails.moveOutDate,
              address1: energyDetails.location?.address1,
              address2: energyDetails.location?.address2,
              countryId: energyDetails.location?.country?.id,
              city: energyDetails.location?.city,
              state: energyDetails.location?.state,
              postalCode: energyDetails.location?.postalCode,
              locationId: energyDetails.location?.id
            });
          }
        },
        () => {},
        () => {}
      );
    }
  }, []);

  useAsync({
    asyncFunction: fetchEnergies
  });

  return (
    <div className="office-location">
      <h3 className="text-bd">
        Hi there! Let's begin, enter your office details
      </h3>
      <div className="office-location__description">
        If you have more than one location, you may add these later
      </div>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={officeLocationValidation}
        initialValues={formValues}
      >
        {({ values, _errors, isValid, _dirty, setFieldValue }) => (
          <Form className="office-location__form">
            <Card className="office-location__card">
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <InputField
                    title="Nickname (optional)"
                    type="text"
                    name="name"
                    placeholder="Enter"
                  />
                </Col>
                <Col span={24}>
                  <AddressField
                    setFormValues={setFormValues}
                    setFieldValue={setFieldValue}
                    formValues={values}
                    isEdit={!!state?.isEdit}
                  />
                </Col>
                <Col span={12} className="mb-4">
                  <DatePicker
                    name="moveInDate"
                    defaultValue={values?.moveInDate}
                    title="Office Move-in Date"
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={12}>
                  <Radio
                    title="Is this your current office?"
                    raised
                    value={values?.isCurrentOffice}
                    name="isCurrentOffice"
                    options={yesOrNoOptions}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              {typeof values?.isCurrentOffice !== 'undefined' && (
                <Row gutter={[20, 10]}>
                  <Col span={12} className="mb-4">
                    {!!values?.isCurrentOffice ? (
                      <CurrentOptionRadioBtn
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <DatePicker
                        name="moveOutDate"
                        defaultValue={values?.moveOutDate}
                        title="Office Move-Out Date"
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Col>
                  {!values?.isCurrentOffice && (
                    <Col span={12}>
                      <CurrentOptionRadioBtn
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Card>
            <Row gutter={[20, 20]} className="mt-4">
              <Col span={12}>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  onClick={handlePrevious}
                  prefixIcon="ArrowLeft"
                >
                  Previous
                </Button>
              </Col>
              <Col span={12} className="justify-end display-flex">
                <Button
                  htmlType="submit"
                  icon="ArrowRight"
                  disabled={!isValid || formLoading}
                  loading={formLoading}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OfficeLocation;
