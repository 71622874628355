import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../../context';
import { CollectionContextProvider } from '../../../../context/CollectionContext';
import {
  analytics,
  conditionalCapitalizeText,
  getEmissionSourceDetailsName
} from '../../../../helpers';
import {
  capitalizeText,
  getCollectionBusinessUnit,
  getCollectionLocation,
  getFormattedAddress
} from '../../../../helpers/generators';
import { useAsync, useModal, usePagination } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { ColumnTitle, Paragraph, Table, Tag } from '../../../../stories/atoms';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../../stories/atoms/Tag/tag.types';
import TableTitle from '../../../../stories/molecules/TableTitle';
import { EmissionSourceDetailForm } from '../../../index';
import './EmissionSourceDetailList.scss';

function EmissionSourceDetailList() {
  const { me } = useMeContext();
  const [sourcesList, setSourcesList] = useState([]);
  const [sourcesInactiveList, setSourcesInactiveList] = useState([]);
  const [showActiveList, setShowActiveList] = useState(true);
  const navigate = useNavigate();

  const {
    Modal,
    handleShowModal: handleShowCreateSourceDetailModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        meId: me.id,
        level1: 'Emission Sources',
        level2: 'Overview',
        level3: 'Sources'
      });
    }
  });
  const defaultOrderBy = ['updatedAt', 'id'];
  const defaultSearch = ['displayName', 'name'];
  const {
    paginationParams,
    handleSetPaginationParams: handleSetActivePaginationParams
  } = usePagination({
    pageSize: 30,
    paginationArray: sourcesList,
    orderBy: defaultOrderBy,
    order: 'DESC'
  });
  const {
    paginationParams: paginationInactiveParams,
    handleSetPaginationParams: handleSetInactivePaginationParams
  } = usePagination({
    pageSize: 30,
    paginationArray: sourcesInactiveList,
    orderBy: defaultOrderBy,
    order: 'DESC',
    search: {
      queryString: '',
      queryFields: defaultSearch
    }
  });
  const loadSourcesList = useCallback(async () => {
    const { list: activeList = [], count: activeCount } =
      await EmissionSourceService.listEmissionSourceDetails({
        companySlug: me.company?.slug,
        isInactive: false,
        pagination
      });
    const { list: inactiveList = [], count: inactiveCount } =
      await EmissionSourceService.listEmissionSourceDetails({
        companySlug: me.company?.slug,
        isInactive: true,
        pagination
      });

    setSourcesList(activeList);
    setSourcesInactiveList(inactiveList);
    return { activeCount, inactiveCount };
  }, [
    me.company?.slug,
    paginationInactiveParams.pageNumber,
    paginationInactiveParams.orderBy,
    paginationInactiveParams.search?.queryString,
    paginationParams.pageNumber,
    paginationParams.orderBy,
    paginationParams.search?.queryString
  ]);

  const {
    isLoading,
    value: { activeCount, inactiveCount },
    execute: refreshList
  } = useAsync({
    asyncFunction: loadSourcesList,
    defaultValue: { activeCount: 0, inactiveCount: 0 }
  });
  const handleSortColumn = (sorter) => {
    handleSetPaginationParams({
      ...paginationParams,
      paginationArray: sourcesList,
      pageNumber: 1,
      prevPageNumber: 1,
      previousValue: null,
      orderBy: sorter.column?.key ? [sorter.column?.key, 'id'] : defaultOrderBy,
      order: sorter.order,
      reverse: false
    });
  };
  const handleSourceDetailsModalSuccess = (emissionSourceDetails) => {
    analytics.track('Save item clicked', me, {
      level1: 'Emission Sources',
      level2: 'Overview',
      level3: 'Sources',
      level4: 'Item Modal',
      emissionSourceDetails
    });
  };

  const handleCloseSourceModal = () => {
    refreshList();
    handleCloseModal();
  };
  const columns = [
    {
      key: 'name',
      title: ({ sortColumns }) => (
        <ColumnTitle columnTitle="name" sortColumns={sortColumns} isSortable>
          Name
        </ColumnTitle>
      ),
      render: (_, item) => {
        const emissionSourceDetailsName = getEmissionSourceDetailsName(item);
        return <span className="text-sbd">{emissionSourceDetailsName}</span>;
      },
      dataIndex: 'name',
      width: '250px',
      sorter: true
    },
    {
      title: <ColumnTitle>Description</ColumnTitle>,
      render: (_, item) => (
        <Paragraph bottomSpacing={0} className="description-text">
          {item?.description ?? '--'}
        </Paragraph>
      )
    },
    {
      title: <ColumnTitle>Location</ColumnTitle>,
      render: (_, item) => {
        const collection = getCollectionLocation(item);
        const location = collection?.location;
        const name = collection?.name;
        return getFormattedAddress({
          location,
          name,
          defaultReturnString: '--'
        });
      },
      width: '200px'
    },
    {
      title: <ColumnTitle>Business Unit</ColumnTitle>,
      render: (_, item) =>
        capitalizeText(getCollectionBusinessUnit(item)?.name) || '--',
      width: '150px'
    },
    {
      title: <ColumnTitle>Emission Factor</ColumnTitle>,
      render: (_, item) => (
        <Paragraph bottomSpacing={0} className="description-text">
          {conditionalCapitalizeText(
            item.emissionFactor?.name,
            item.emissionFactor?.isInternal
          )}
        </Paragraph>
      )
    },
    {
      title: <ColumnTitle>Category</ColumnTitle>,
      render: (_, item) =>
        !!item.tags?.length ? (
          <div className="flex-direction-column">
            {item.tags.map((tag, i) => (
              <Tag
                key={i}
                color={INTERNAL_TAG_COLOURS[tag.name] ?? TAG_COLOURS.GREEN}
                className="mb-1"
              >
                {tag.name}
              </Tag>
            ))}
          </div>
        ) : (
          '--'
        )
    }
  ];

  const handleSwitchListViews = (value) => {
    setShowActiveList(value);
  };

  const switcherProps = {
    onClick: handleSwitchListViews,
    activeValue: showActiveList,
    options: [
      {
        label: `Active (${activeCount})`,
        value: true
      },
      { label: `Inactive (${inactiveCount})`, value: false }
    ]
  };

  const pagination = showActiveList
    ? paginationParams
    : paginationInactiveParams;
  const total = showActiveList ? activeCount : inactiveCount;
  const handleSetPaginationParams = showActiveList
    ? handleSetActivePaginationParams
    : handleSetInactivePaginationParams;
  const dataSource = showActiveList ? sourcesList : sourcesInactiveList;

  const handleOnSearch = (query) => {
    handleSetPaginationParams({
      ...paginationParams,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: defaultSearch
          }
        : undefined
    });
  };

  const handleOnRow = (emissionSourceDetails) => ({
    onClick: () => {
      analytics.track('Item Clicked', me, {
        level1: 'Emission Sources',
        level2: 'Overview',
        level3: 'Sources',
        emissionSourceDetails
      });
      navigate(
        AppRoutes.SOURCES_DETAILS.replace(
          ':emissionSourceDetailsId',
          emissionSourceDetails.id
        )
      );
    }
  });

  return (
    <>
      <Table
        title={() => (
          <TableTitle
            switcherProps={switcherProps}
            searchProps={{
              type: INPUT_TYPES.SEARCH,
              isFormikField: false,
              enterButton: true,
              placeholder: 'Search by name',
              onSearch: handleOnSearch
            }}
            buttons={[
              {
                children: 'Create New',
                onClick: handleShowCreateSourceDetailModal
              }
            ]}
          />
        )}
        isLoading={isLoading}
        pagination={pagination}
        className="emission-source-detail-list"
        rowClassName={() =>
          'emission-source-detail-list__row emission-source-detail-list__row--clickable'
        }
        showPagination
        dataSource={dataSource}
        onRow={handleOnRow}
        columns={columns}
        total={total}
        onChange={(_, filters, sorter, { action }) => {
          if (action === 'sort') {
            handleSortColumn(sorter);
          }
        }}
      />

      <Modal footer={null} title="Create New Source">
        <CollectionContextProvider>
          <EmissionSourceDetailForm
            onClose={handleCloseSourceModal}
            onSuccess={handleSourceDetailsModalSuccess}
          />
        </CollectionContextProvider>
      </Modal>
    </>
  );
}

export default EmissionSourceDetailList;
