import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EMPLOYEE_ONBOARDING_STEPS,
  EMPLOYEE_ONBOARDING_STEPS_LABEL,
  EMPLOYEE_ONBOARDING_STEPS_ROUTE_MAPPINGS
} from '../../../../../../constants';
import {
  EMPLOYEE_ONBOARDING_COMMUTE_STEPS,
  EMPLOYEE_ONBOARDING_STEPS_TUPLE
} from '../../../../../../constants/EmployeeOnboarding';
import {
  useLayoutContext,
  useOfficeTrailContext
} from '../../../../../../context';
import { AppRoutes } from '../../../../../../routes';
import { Menu, MenuItem, SubMenu } from '../../../../../../stories/atoms';

export function OnboardingEmployeeLayoutSidebarMenu() {
  const { layoutState, navStep, setCompletedSteps } = useLayoutContext();
  const { currentStep, completedSteps } = layoutState;
  const [openKeys, setOpenKeys] = useState([]);
  const { pathname } = useLocation();

  const { officeTrailDetails, refreshEmployeeOnboardingTrails } =
    useOfficeTrailContext();
  const { employeeTrailStatus } = officeTrailDetails;

  useEffect(() => {
    refreshEmployeeOnboardingTrails();
  }, []);

  useEffect(() => {
    const completedTrailSteps = EMPLOYEE_ONBOARDING_STEPS_TUPLE.filter(
      (step) => employeeTrailStatus[step] === 'complete'
    );
    setCompletedSteps(completedTrailSteps);
  }, [employeeTrailStatus]);
  const handleNavigate = ({ key }) => {
    if (EMPLOYEE_ONBOARDING_COMMUTE_STEPS.indexOf(key) > -1) return;
    navStep(EMPLOYEE_ONBOARDING_STEPS_ROUTE_MAPPINGS[key]);
  };
  const handleToggleDetailsMenu = ({ key }) => {
    const newKey = key === openKeys?.[0] ? [] : [key];
    setOpenKeys(newKey);
  };
  useEffect(() => {
    if (
      currentStep === EMPLOYEE_ONBOARDING_STEPS.COMMUTE ||
      EMPLOYEE_ONBOARDING_COMMUTE_STEPS.indexOf(currentStep) > -1
    ) {
      setOpenKeys([EMPLOYEE_ONBOARDING_STEPS.COMMUTE]);
    }
  }, [currentStep]);

  return (
    <Menu
      onClick={handleNavigate}
      mode="inline"
      selectedKeys={[currentStep]}
      openKeys={openKeys}
    >
      {EMPLOYEE_ONBOARDING_STEPS_TUPLE.map((step, index) => {
        const isFirstStep = index === 0;
        const isAddingCommute =
          pathname.indexOf(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE) > -1;
        const isAddingWorkFromHome =
          pathname.indexOf(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME) > -1;
        const isCommuteChildNode =
          EMPLOYEE_ONBOARDING_COMMUTE_STEPS.indexOf(step) > -1;

        if (isCommuteChildNode) {
          return null;
        }
        if (step === EMPLOYEE_ONBOARDING_STEPS.COMMUTE) {
          return (
            <SubMenu
              onTitleClick={handleToggleDetailsMenu}
              key={step}
              title="1. Employee Details"
            >
              <MenuItem
                currentStep={currentStep}
                completedSteps={completedSteps}
                key={step}
                stepName={step}
              >
                {
                  EMPLOYEE_ONBOARDING_STEPS_LABEL[
                    EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OVERVIEW
                  ]
                }
              </MenuItem>
              {isAddingCommute && (
                <MenuItem
                  currentStep={currentStep}
                  completedSteps={completedSteps}
                  key={EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE}
                  stepName={EMPLOYEE_ONBOARDING_STEPS.COMMUTE_OFFICE}
                >
                  {EMPLOYEE_ONBOARDING_STEPS_LABEL.COMMUTE_OFFICE}
                </MenuItem>
              )}
              {isAddingWorkFromHome && (
                <MenuItem
                  currentStep={currentStep}
                  completedSteps={completedSteps}
                  key={EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME}
                  stepName={EMPLOYEE_ONBOARDING_STEPS.COMMUTE_HOME}
                >
                  {EMPLOYEE_ONBOARDING_STEPS_LABEL.COMMUTE_HOME}
                </MenuItem>
              )}
            </SubMenu>
          );
        }
        return (
          <>
            {isFirstStep && <Divider />}

            <MenuItem
              currentStep={currentStep}
              completedSteps={completedSteps}
              key={step}
              stepName={step}
            >
              {EMPLOYEE_ONBOARDING_STEPS_LABEL[step]}
            </MenuItem>

            {isFirstStep && <Divider />}
          </>
        );
      })}
    </Menu>
  );
}
