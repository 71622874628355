const TransportationType = {
  CAR: 'CAR',
  MOTORBIKE: 'MOTORBIKE',
  INTERCITY_TRAIN: 'INTERCITY TRAIN',
  LOCAL_BUS: 'LOCAL BUS',
  SUBWAY: 'SUBWAY',
  STREETCAR_LRT: 'STREETCAR/LRT',
  PLANE: 'PLANE',
  WALKING: 'WALKING',
  BICYCLE: 'BICYCLE'
};

export default TransportationType;
