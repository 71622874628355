import { Modal as AntModal, Space } from 'antd';
import { useCallback, useState } from 'react';
import { useMeContext } from '../../../context';
import { useAsync, useModal, usePagination } from '../../../hooks';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Paragraph,
  Table,
  Title
} from '../../../stories/atoms';
import TableTitle from '../../../stories/molecules/TableTitle';
import { InitiativeService } from '../../../services';
import { capitalizeText } from '../../../helpers';
import BenchmarkForm from '../Form';
import './benchmarkTable.scss';

function TableTitleComponent({ handleAddNewBenchmark }) {
  return (
    <TableTitle
      className="headerButtons"
      buttons={[
        {
          children: 'Add New',
          onClick: handleAddNewBenchmark
        }
      ]}
    />
  );
}

function TableActionColumnRender({
  handleEditBenchmark,
  benchmark,
  handlePromptForDelete
}) {
  const handleEditMetricDetails = () => {
    handleEditBenchmark(benchmark);
  };
  const handleDeleteBenchmark = () => {
    handlePromptForDelete(benchmark.id);
  };

  return (
    <Space size={24}>
      <div>
        <ButtonIcon name="Pencil" onClick={handleEditMetricDetails} />
      </div>
      <div>
        <ButtonIcon name="MinusCircle" onClick={handleDeleteBenchmark} />
      </div>
    </Space>
  );
}

export function BenchmarkTable() {
  const { me } = useMeContext();
  const { company } = me ?? {};
  const [selectedBenchmark, setSelectedBenchmark] = useState({});

  const fetchBenchmark = useCallback(async () => {
    const response = await InitiativeService.listInitiatives({
      companySlug: company.slug,
      type: 'BENCHMARK',
      pagination
    });
    return response.list;
  }, [company.slug]);

  const {
    value: benchmarkList,
    isLoading,
    execute: refreshBenchmarkList
  } = useAsync({ defaultValue: [], asyncFunction: fetchBenchmark });

  const { paginationParams: pagination } = usePagination({
    paginationArray: benchmarkList,
    orderBy: ['updatedAt'],
    pageSize: ''
  });

  const onCloseModal = () => {
    setSelectedBenchmark({});
  };

  const {
    Modal: EditBenchmarkModal,
    handleShowModal: handleShowEditModal,
    handleCloseModal: handleCloseEditModal
  } = useModal({ onCloseModal });

  const handleBenchmarkModalSuccess = () => {
    handleCloseEditModal();
    refreshBenchmarkList();
  };
  const deleteInitiative = async (id) => {
    await InitiativeService.deleteInitiative(id);
    refreshBenchmarkList();
  };

  const { execute: handleDeleteInitiative, isLoading: isDeleting } = useAsync({
    asyncFunction: deleteInitiative,
    immediate: false
  });

  const handlePromptForDelete = (id) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      title: (
        <Title bottomSpacing={0}>
          Are you sure you would like to delete this benchmark?
        </Title>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      content: (
        <Paragraph bottomSpacing={0}>
          If you delete this benchmark it cannot be undone, you will no longer
          be able to generate reports for this benchmark.
        </Paragraph>
      ),
      onOk: () => handleDeleteInitiative(id),
      okButtonProps: {
        loading: isDeleting
      }
    });
  };
  const columns = [
    {
      dataIndex: 'name',
      title: <ColumnTitle>Name</ColumnTitle>
    },
    {
      dataIndex: 'initiativeTemplate',
      title: <ColumnTitle>Region</ColumnTitle>,
      render: ({ details: { region } }) => <ColumnTitle>{region}</ColumnTitle>
    },
    {
      dataIndex: 'initiativeTemplate',
      title: <ColumnTitle>Template</ColumnTitle>,
      render: ({ name }) => <ColumnTitle>{name}</ColumnTitle>
    },
    {
      dataIndex: 'initiativeTemplate',
      title: <ColumnTitle>Industry</ColumnTitle>,
      render: ({ industry }) => <ColumnTitle>{industry}</ColumnTitle>
    },
    {
      dataIndex: 'initiativeTemplate',
      title: <ColumnTitle>Type</ColumnTitle>,
      render: ({ type }) => {
        return capitalizeText(type);
      }
    },
    {
      width: 15,
      render: (_, benchmark) => (
        <TableActionColumnRender
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          benchmark={benchmark}
          handleEditBenchmark={() => {
            setSelectedBenchmark(benchmark);
            handleShowEditModal();
          }}
          handlePromptForDelete={handlePromptForDelete}
        />
      )
    }
  ];
  return (
    <>
      <Table
        isLoading={isLoading}
        dataSource={benchmarkList}
        columns={columns}
        title={() =>
          TableTitleComponent({
            handleAddNewBenchmark: handleShowEditModal
          })
        }
        locale={{
          emptyText: (
            <Empty
              icon="Calculator"
              title="No Benchmarks Added"
              description="Benchmarks help you to measure your impact against competitors and industry averages and communicate the impact of your emissions."
            />
          )
        }}
      />
      <EditBenchmarkModal
        title={`${selectedBenchmark.id ? 'Edit' : 'Create New'} Benchmark`}>
        <BenchmarkForm
          benchmark={selectedBenchmark}
          companySlug={company.slug}
          onSuccess={handleBenchmarkModalSuccess}
        />
      </EditBenchmarkModal>
    </>
  );
}
