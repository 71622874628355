import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useMeContext, useMetaContext } from '../../../../context';
import { analytics, getDropdownOptions } from '../../../../helpers';
import { useAsync } from '../../../../hooks';
import { useDrawer } from '../../../../hooks/useDrawer';
import { VehicleService } from '../../../../services';
import { Button, Select } from '../../../atoms';
import { BUTTON_TYPES } from '../../../atoms/Button/Button.types';
import { VehicleForm } from '../../index';

export function PersonalVehicleFields({ analyticsContext }) {
  const { me } = useMeContext();
  const { meta, setFuelTypes, setVehicleTypes } = useMetaContext();

  const vehicleTypeOptions = getDropdownOptions(
    meta.vehicleTypes,
    'friendlyName',
    'id'
  );
  const fuelTypeOptions = getDropdownOptions(
    meta.fuelTypes,
    'friendlyName',
    'id'
  );
  const { setFieldValue, values } = useFormikContext();
  const { isRental, travelCategoryName } = values;

  const { handleCloseDrawer, handleShowDrawer, Drawer } = useDrawer({
    onShowDrawer: () =>
      analytics.track('Save item clicked', me, analyticsContext)
  });

  const handleFetchVehicles = useCallback(async () => {
    const vehicles = await VehicleService.fetchVehicles(
      { companySlug: me?.company?.slug },
      {
        baseVehicleType: travelCategoryName
      }
    );
    return vehicles.map((vehicle) => ({
      ...vehicle,
      label: vehicle.friendlyName,
      value: vehicle.id
    }));
  }, [me?.company?.slug, travelCategoryName]);
  const {
    value: commuteVehicleOptions,
    isLoading,
    setValue
  } = useAsync({
    asyncFunction: handleFetchVehicles
  });
  const handleAddVehicleSuccess = (vehicle) => {
    setValue?.((prev) => {
      if (!prev) {
        return [
          {
            ...vehicle,
            label: vehicle.friendlyName,
            value: vehicle.id
          }
        ];
      }
      return [
        ...prev,
        {
          ...vehicle,
          label: vehicle.friendlyName,
          value: vehicle.id
        }
      ];
    });
    setFieldValue('vehicleId', vehicle.id);
  };

  const handleSelectVehicle = (opt) => {
    setFieldValue('vehicleId', opt.value);
    setFieldValue('commuteVehicleId', opt.id);
    setFieldValue('existingCommuteVehicle', opt.existingCommuteVehicle);
  };
  useEffect(() => {
    setFuelTypes();
    setVehicleTypes();
  }, []);
  return (
    <>
      <Row align="middle" gutter={[16, 16]}>
        {isRental ? (
          <>
            <Col span={12}>
              <Select
                title="Vehicle Type"
                name="vehicleTypeId"
                value={values?.vehicleTypeId}
                options={vehicleTypeOptions}
                setFieldValue={setFieldValue}
                loading={isLoading}
              />
            </Col>
            <Col span={12}>
              <Select
                title="Fuel Type"
                name="fuelTypeId"
                value={values?.fuelTypeId}
                options={fuelTypeOptions}
                setFieldValue={setFieldValue}
                loading={isLoading}
              />
            </Col>
          </>
        ) : (
          <>
            <Col flex="1">
              <Select
                title="Select Vehicle"
                name="vehicleId"
                value={values?.vehicleId}
                options={commuteVehicleOptions}
                onChange={(_, opt) => handleSelectVehicle(opt)}
                placeholder="Select Vehicle"
                loading={isLoading}
              />
            </Col>
            <Col className="mt-6">
              <Button
                type={BUTTON_TYPES.SECONDARY}
                prefixIcon="Plus"
                onClick={handleShowDrawer}
              >
                Add new vehicle
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Drawer showHeader={false}>
        <VehicleForm
          baseVehicleType={values?.travelCategoryName}
          onSuccess={handleAddVehicleSuccess}
          onClose={handleCloseDrawer}
        />
      </Drawer>
    </>
  );
}
