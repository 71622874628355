import { DatePicker as AntDatePicker } from 'antd';
import { ErrorMessage, Field } from 'formik';
import moment from 'moment';
import Error from '../../../components/Error';
import { Label } from '../index';
import './datepicker.scss';

const getFormattedDefaultValue = ({
  defaultValue,
  isDisplayUTCFormat,
  isRange
}) => {
  if (defaultValue) {
    const momentFn = isDisplayUTCFormat ? moment.utc : moment;
    if (isRange) {
      if (!!defaultValue[0] && !!defaultValue[1]) {
        return [momentFn(defaultValue[0]), momentFn(defaultValue[1])];
      }
      return [];
    }
    return momentFn(defaultValue ?? '');
  }
  return isRange ? [] : undefined;
};

function DatePicker({
  isRange,
  title,
  name,
  fromName,
  toName,
  format,
  picker,
  mode,
  disabledDate,
  setFieldValue,
  defaultValue,
  onChange,
  disabled,
  defaultPickerValue = '',
  type = 'date',
  isDisplayUTCFormat = true
}) {
  const formattedDefaultValue = getFormattedDefaultValue({
    defaultValue,
    isDisplayUTCFormat,
    isRange
  });
  const handleChange = (date) => {
    if (setFieldValue) {
      if (isRange) {
        if (type === 'date') {
          if (date[0]) {
            setFieldValue(fromName, date[0]?.format('YYYY-MM-DD'));
          }
          if (date[1]) {
            setFieldValue(toName, date[1]?.format('YYYY-MM-DD'));
          }
        } else {
          setFieldValue(fromName, date[0].format('YYYY-MM-DD HH:mm:ss'));
          setFieldValue(toName, date[1].format('YYYY-MM-DD HH:mm:ss'));
        }
      } else {
        type === 'date'
          ? setFieldValue(name, date.format('YYYY-MM-DD'))
          : setFieldValue(name, date.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  };

  return (
    <Field name={name}>
      {({ _field, form: { _touched, _errors }, _meta }) => (
        <div className="ch-datepicker-field-new">
          {title && <Label>{title}</Label>}
          {isRange ? (
            <AntDatePicker.RangePicker
              allowEmpty={[true, true]}
              className={`ch-datepicker-new date-picker-${name}`}
              disabled={disabled}
              allowClear={false}
              picker={picker}
              onChange={onChange || handleChange}
              placeholder={['mm/dd/yyyy', 'mm/dd/yyyy']}
              disabledDate={disabledDate || undefined}
              value={formattedDefaultValue}
              format={format || (picker === 'year' ? 'YYYY' : 'MM/DD/YYYY')}
              name={name}
            />
          ) : (
            <AntDatePicker
              className={`ch-datepicker-new date-picker-${name}`}
              disabled={disabled}
              allowClear={false}
              placeholder="mm/dd/yyyy"
              mode={mode}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate || undefined}
              value={formattedDefaultValue}
              defaultPickerValue={defaultPickerValue}
              format={format || (picker === 'year' ? 'YYYY' : 'MM/DD/YYYY')}
              name={name}
            />
          )}

          <ErrorMessage name={name}>
            {(message) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default DatePicker;
