import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import classNames from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import Paragraph from '../Paragraph';
import { emptyDoughnutPlugin } from './DonutChart.plugins';
import './DonutChart.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DonutChart({
  className,
  options,
  width,
  height,
  totalCopy = 'Total',
  ...props
}) {
  const {
    data: { total = 0 }
  } = props;

  return (
    <div
      className={classNames('ch-donut-chart', {
        [className]: !!className
      })}
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      <div className="ch-donut-chart__total">
        <Paragraph
          bottomSpacing={0}
          size={16}
          weight={800}
          className="ch-donut-chart__total--bold"
        >
          {total}
        </Paragraph>
        <Paragraph bottomSpacing={0} size={10} weight={400}>
          {totalCopy}
        </Paragraph>
      </div>
      <Doughnut
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            legend: { display: false, ...options.plugins?.legend },
            tooltip: { boxPadding: 5, ...options.plugins?.tooltip },
            emptyDoughnut: {
              ...options.plugins?.emptyDoughnut,
              width: 40,
              radiusDecrease: 20
            }
          }
        }}
        plugins={[emptyDoughnutPlugin]}
        {...props}
      />
    </div>
  );
}
