import * as Yup from 'yup';

export const energyItemValidation = Yup.object().shape({
  energyTypeName: Yup.string()
    .notOneOf(['UNKNOWN'], 'Energy type')
    .required('Energy type'),
  conversionUnitName: Yup.string().nullable().required('Unit'),
  activity: Yup.number().typeError('Activity').nullable(),
  billingStart: Yup.string().nullable().required('Billing start date'),
  billingEnd: Yup.string().nullable().required('Billing end date')
});
