const OfficeTrailSegment = {
  BASELINE_PERIOD: 'BASELINE_PERIOD',
  ENERGY: 'ENERGY',
  COMMUTE: 'COMMUTE',
  BUSINESS_TRAVEL: 'BUSINESS_TRAVEL',
  TARGET: 'TARGET',
  CUSTOM_SOURCES: 'CUSTOM_SOURCES'
};

export default OfficeTrailSegment;
