import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import {
  CALCULATED_CABRON_FOOTPRINT,
  REPORTING_STANDARDS,
  SUSTAINABILITY_GOALS
} from '../../../constants/onboardingClimateGoals';
import { Radio, Select, Title } from '../../../stories/atoms';
import './climateGoals.scss';

export function ClimateGoals() {
  const sustainabilityGoalsOptions = [
    {
      label: 'Reduce my footprint',
      value: SUSTAINABILITY_GOALS.REDUCE_FOOTPRINT
    },
    {
      label: 'I want to make sustainability claims',
      value: SUSTAINABILITY_GOALS.SUSTAINABILITY_CLAIMS
    },
    {
      label: 'I want to create a sustainability culture',
      value: SUSTAINABILITY_GOALS.SUSTAINABILITY_CULTURE
    },
    {
      label: 'Im creating an account for another company',
      value: SUSTAINABILITY_GOALS.ANOTHER_COMPANY
    },
    {
      label: 'My investors are asking for reports',
      value: SUSTAINABILITY_GOALS.INVESTOR_REPORTS
    },
    {
      label: 'Other',
      value: SUSTAINABILITY_GOALS.OTHER
    }
  ];

  const reportingStandardsOptions = [
    {
      label: 'B Corp',
      value: REPORTING_STANDARDS.B_CORP
    },
    {
      label: 'CDP',
      value: REPORTING_STANDARDS.CDP
    },
    {
      label: 'GRI',
      value: REPORTING_STANDARDS.GRI
    },
    {
      label: 'ISSB (IFRS)',
      value: REPORTING_STANDARDS.ISSB_IFRS
    },
    {
      label: 'ISO 14064-1',
      value: REPORTING_STANDARDS.ISO_14064_1
    },
    {
      label: 'MSCI',
      value: REPORTING_STANDARDS.MSCI
    },
    {
      label: 'TCFD',
      value: REPORTING_STANDARDS.TCFD
    },
    {
      label: 'The GHG Indicator: UNEP',
      value: REPORTING_STANDARDS.GHG_UNEP
    },
    {
      label: 'Not Sure',
      value: REPORTING_STANDARDS.UNSURE
    },
    {
      label: 'Other',
      value: REPORTING_STANDARDS.OTHER
    }
  ];

  const calculatedCarbonFootprintOptions = [
    {
      label: 'Never',
      value: CALCULATED_CABRON_FOOTPRINT.NEVER,
      description: 'This is my first time ever calculating a carbon footprint'
    },
    {
      label: 'Once or twice',
      value: CALCULATED_CABRON_FOOTPRINT.RARELY,
      description:
        'I have some previous experience calculating carbon footprints'
    },
    {
      label: 'Regularly',
      value: CALCULATED_CABRON_FOOTPRINT.OFTEN,
      description: 'I have calculated many carbon footprints'
    }
  ];

  const { values, setFieldValue } = useFormikContext();
  return (
    <div className="climate-goals__container">
      <Row>
        <Col span={24}>
          <Title fontSize={20} bottomSpacing={48}>
            Your Climate Goals
          </Title>
        </Col>
        <Col span={24} className="climate-goals-dropdown__wrapper">
          <Title>What are your sustainability goals?</Title>
          <Select
            name="sustainabilityGoal"
            title="Sustainability goals"
            value={values?.sustainabilityGoal}
            setFieldValue={setFieldValue}
            options={sustainabilityGoalsOptions}
            className="climate-goals-dropdown"
            placeholder="e.g I want to make sustainability claims"
          />
          <Select
            mode="multiple"
            name="reportingStandards"
            title="What reporting standards are you hoping to meet?"
            value={values?.reportingStandards}
            setFieldValue={setFieldValue}
            options={reportingStandardsOptions}
            className="climate-goals-dropdown"
            placeholder="e.g B Corp"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title>Have you ever calculated a carbon footprint before?</Title>
          <Radio
            value={values.calculatedCarbonFootprint}
            name="calculatedCarbonFootprint"
            options={calculatedCarbonFootprintOptions}
            className="climate-goals-buttons__container"
            setFieldValue={setFieldValue}
            descriptive
          />
        </Col>
      </Row>
    </div>
  );
}
