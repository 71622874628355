function ChevronUp() {
  return (
    <path
      d="M5 15L12 8L19 15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default ChevronUp;
