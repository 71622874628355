import { Col, Row } from 'antd';
import { Anchor, Paragraph, Title } from '../../stories/atoms';
import './CreditsOffsetsExplaination.scss';

function CreditsOffsetsExplaination() {
  return (
    <Row className="credits-offsets-explanation mb-8" justify="center">
      <Col xs={20} xl={15}>
        <Col span={24} className="mb-8">
          <Title size="xl">How we evaluate credits</Title>
        </Col>

        <Row gutter={[0, 40]}>
          <Col span={24}>
            <Paragraph>
              We work with 3rd party verifiers and standards like the Gold
              Standard and the Verified Carbon Standard(VCS) to ensure the
              offsets you are investing in are verified, legal, and sustainable.
            </Paragraph>
            <Paragraph>
              In addition to this, Carbonhound rigorously reviews all of the
              projects in its portfolios and incorporates guidance from 3rd
              party experts like Carbonplan and the The Oxford Principles for
              Net Zero Aligned Carbon Offsetting to provide offsets that result
              in real climate impact.
            </Paragraph>
            <Paragraph>
              Here are all the different components of the vetting process we
              employ to ensure the projects we support are high quality:
            </Paragraph>
          </Col>
          <Col
            span={24}
            className="credits-offsets-explanation__table-container"
          >
            <table>
              <thead>
                <th>Criteria</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>Additionality</td>
                  <td>
                    Net new carbon must be removed, rather than 'business as
                    usual'.
                  </td>
                </tr>
                <tr>
                  <td>Accurate quantification</td>
                  <td>
                    For a carbon offset to be real, it is essential that the
                    emission reductions it represents be quantified accurately.
                  </td>
                </tr>
                <tr>
                  <td>Auditing</td>
                  <td>
                    The project must be tested before and during it’s progress
                    to ensure credibility.
                  </td>
                </tr>
                <tr>
                  <td>Ownership</td>
                  <td>
                    Clear ownerships must be established for the greenhouse gas
                    reductions that the credit represents.
                  </td>
                </tr>
                <tr>
                  <td>Permanence</td>
                  <td>
                    The climate benefit from the project must be durable, for us
                    we define “permanent” as 1000 years.
                  </td>
                </tr>
                <tr>
                  <td>No Leakage</td>
                  <td>
                    Greenhouse gas reduction in one region cannot cause an
                    increase in emissions somewhere else.
                  </td>
                </tr>
                <tr>
                  <td>Timing</td>
                  <td>
                    Credits must represent removals that are actively happening,
                    not removals that will happen in the future.
                  </td>
                </tr>
                <tr>
                  <td>Sustainability</td>
                  <td>
                    At a minimum, carbon offset projects should comply with all
                    relevant social and environmental regulations.
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col span={24}>
            <Title className="mb-8">How do credits go wrong?</Title>
            <Paragraph>
              Offsets have had a rather rocky journey from their initial
              creation, with some very high profile cases of projects not
              delivering on their promises.
            </Paragraph>
            <Paragraph>Here are some of the issues that can arise:</Paragraph>
          </Col>
          <Col span={24}>
            <Title size="md" includeSpacing={false}>
              Quantification
            </Title>
            <Paragraph>
              Avoided emissions require an accurate baseline in order to
              quantify the impact of project. But there are challenges
              establishing this baseline and project managers are incentivised
              to inflate the baseline to optimize their project’s credit
              creation. This is why we look at removals instead as they are more
              easily quantified objectively.
            </Paragraph>
          </Col>
          <Col span={24}>
            <Title size="md" includeSpacing={false}>
              Leakages
            </Title>
            <Paragraph>
              Unintended impacts that come from carbon projects, i.e. protecting
              one area may increase the rates of deforestation elsewhere
            </Paragraph>
          </Col>
          <Col span={24}>
            <Title size="md" includeSpacing={false}>
              Additionality
            </Title>
            <Paragraph>
              Projects may have occurred without the funding from the carbon
              credits. So investment in these credits didn’t actually create
              climate action.
            </Paragraph>
          </Col>

          <Col span={24}>
            <Paragraph>
              Despite their controversies there is a lot of benefits that
              supporting a true high quailty project can have. The impact these
              projects are making to remove carbon out of the air is the kind of
              immediate action that we need to have right now, and it gives your
              company an opportunity to invest in those changes.
            </Paragraph>
            <Paragraph>
              You can read more about the risks{' '}
              <Anchor href="https://features.propublica.org/brazil-carbon-offsets/inconvenient-truth-carbon-credits-dont-work-deforestation-redd-acre-cambodia/">
                here
              </Anchor>
              .
            </Paragraph>
          </Col>
          <Col span={24}>
            <Title size="xl">Questions?</Title>
            <Paragraph>
              Visit our{' '}
              <Anchor
                href="//carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4?pvs=4"
                className="text-bd"
              >
                FAQ
              </Anchor>
              , or{' '}
              <Anchor
                href="//meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
                className="text-bd"
              >
                get in touch with us
              </Anchor>
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CreditsOffsetsExplaination;
