import { Col, Row } from 'antd';
import classNames from 'classnames';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { formatCurrency } from '../../../helpers/formatters';
import { Button, Paragraph } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import './SubscriptionCard.scss';

function SubscriptionCard({ subscription, subscriptionDetail }) {
  const { isoCurrency, employeeCount, cost, isActiveSubscription } =
    subscription;

  if (!subscriptionDetail?.imageUrl) return;
  return (
    <Row
      gutter={[0, 24]}
      className={classNames('ch-subscription-card', {
        'ch-subscription-card__active': isActiveSubscription
      })}
    >
      {isActiveSubscription && (
        <span className="ch-subscription-card__current-indicator">
          Current Plan
        </span>
      )}
      <Col span={24}>
        <img src={subscriptionDetail.imageUrl} alt="subscription detail" />
      </Col>
      <Col span={24}>
        <Paragraph weight={500} bottomSpacing={0}>
          {employeeCount} employees
        </Paragraph>
      </Col>
      <Col span={24}>
        <Paragraph size="sm" bottomSpacing={16}>
          <span className="ch-subscription-card__price">
            {formatCurrency(cost, isoCurrency, {
              includeCurrencySymbol: true
            })}
          </span>
          <span className="ch-subscription-card__currency">
            {isoCurrency.toUpperCase()}
          </span>
        </Paragraph>
        <Paragraph size="sm" bottomSpacing={0}>
          Per month
        </Paragraph>
        <Paragraph size="sm" bottomSpacing={8}>
          Billed annually
        </Paragraph>
      </Col>
      <Col span={24} className="display-flex align-center">
        <a href={subscription.externalUrl}>
          <Button type={BUTTON_TYPES.SECONDARY} showLinkIcon={false}>
            Upgrade
          </Button>
        </a>
        {isActiveSubscription && (
          <Paragraph
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            className="ch-subscription-card__paragraph--italic"
            bottomSpacing={0}
          >
            Current
          </Paragraph>
        )}
      </Col>
    </Row>
  );
}
export default SubscriptionCard;
