import { Col, Row } from 'antd';
import { Loader } from '../../../../components';
import { Paragraph, Title } from '../../../../stories/atoms';

export function DataChartLoadingState({ isLoading }) {
  return (
    <Row align="middle" justify="middle" className="flex-direction-column">
      <Col className="mb-4">
        <Loader isLoading={isLoading} />
      </Col>
      <Col className="mb-2">
        <Title size="rg" bottomSpacing={0}>
          Fetching your data
        </Title>
      </Col>
      <Col>
        <Paragraph bottomSpacing={0}>Just give us a moment...</Paragraph>
      </Col>
    </Row>
  );
}
