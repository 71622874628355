import { Tooltip as AntTooltip } from 'antd';
import classNames from 'classnames';
import './Tooltip.scss';

function Tooltip({ children, ...props }) {
  return (
    <AntTooltip
      overlayClassName={classNames('ch-tooltip__inner', {
        white: props.color === 'white'
      })}
      {...props}
    >
      {children}
    </AntTooltip>
  );
}
export default Tooltip;
