import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { Checkbox } from '../../../../../../components';
import { useMetaContext } from '../../../../../../context';
import { getAddressValues } from '../../../../../../helpers';
import { DatePicker } from '../../../../../../stories/atoms';
import GoogleSelect from '../../../../../../stories/atoms/GoogleSelect';
import InputNumberField from '../../../../../../stories/atoms/InputNumberField';
import BuildingTypeSelect from '../../../../../../stories/molecules/BuildingTypeSelect';

export function WFHFields() {
  const { meta } = useMetaContext();
  const { values, setValues, setFieldValue } = useFormikContext();

  return (
    <Row gutter={[28, 24]} align="center">
      <Col span={24}>
        <InputNumberField
          title="Average number of days a week you work from home"
          name="averageCommuteDaysPerWeek"
          defaultValue={values?.name}
          placeholder="Enter a number between 1-7"
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={!values?.isCurrentCommute ? 8 : 12}
        xl={!values?.isCurrentCommute ? 8 : 12}
      >
        <DatePicker
          title="When did you start working from home?"
          name="startDate"
          defaultValue={values?.startDate}
          setFieldValue={setFieldValue}
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={!values?.isCurrentCommute ? 8 : 12}
        xl={!values?.isCurrentCommute ? 8 : 12}
        className="display-flex align-end"
      >
        <Checkbox
          className="mb-3"
          setFieldValue={setFieldValue}
          name="isCurrentCommute"
          title="Currently working from home"
          checked={values?.isCurrentCommute}
        />
      </Col>
      {!values?.isCurrentCommute && (
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <DatePicker
            title="When did you stop working from home?"
            name="endDate"
            defaultValue={values?.endDate}
            setFieldValue={setFieldValue}
          />
        </Col>
      )}
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <GoogleSelect
          isEdit
          placholder="Search and select"
          defaultValue={values?.formattedAddress || values?.address1}
          title="Home Office Address"
          onSelect={(places) => {
            if (places[0]) {
              const address = getAddressValues(places[0], meta.countries);

              const country = meta.countries.find(
                (countryItem) => countryItem.name === address.country
              );
              const { isEstimationSupported } = country;

              setValues({
                ...values,
                ...address,
                placeId: places[0].place_id,
                lat: places[0].geometry.location.lat(),
                lng: places[0].geometry.location.lng(),
                isEstimationSupported
              });
            }
          }}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={6} className="">
        <InputNumberField
          title="Home Office Square Footage"
          name="homeOfficeSquareFootage"
          defaultValue={values?.homeOfficeSquareFootage}
          placeholder="Enter"
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={6} className="">
        <BuildingTypeSelect
          setFieldValue={setFieldValue}
          value={values?.buildingTypeId}
          name="buildingTypeId"
          title="Home Building Type"
          countryId={values?.countryId}
          disabled={!values?.countryId || !values?.isEstimationSupported}
        />
      </Col>
    </Row>
  );
}
