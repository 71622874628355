import { axiosInstance } from '../interceptors';
import { successValidator } from '../interceptors/statusValidators';
import { ApiRoutes } from '../routes';

class CompanyService {
  static showCompany(slug, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.COMPANY}/${slug}`;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCompany(company, onSuccess, onError, onFinal) {
    axiosInstance
      .post(ApiRoutes.COMPANY, company)
      .then((response) => {
        onSuccess(response.data.company.slug);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async updateCompany(company, onSuccess) {
    try {
      const API_URL = `${ApiRoutes.COMPANY}/${company.slug}`;
      const response = await axiosInstance.put(API_URL, company, {
        validateStatus: successValidator
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  static getCompanyProfile({ slug }, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.COMPANY}/${slug}/profile`;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }

  // This route requires user type INTERNAL to access, and returns all companies available
  static listCompanies(onSuccess, onError, onFinal) {
    axiosInstance
      .get(ApiRoutes.LIST_COMPANIES)
      .then((response) => {
        onSuccess(response.data.companies);
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }
}

export default CompanyService;
