import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import {
  analytics,
  getEmissionSourceDetailsName,
  getFullName
} from '../../../../helpers';
import { useAsync, useModal, usePagination } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { ColumnTitle, Icon, Paragraph, Table } from '../../../../stories/atoms';
import TableTitle from '../../../../stories/molecules/TableTitle';
import { EmissionSourceDetailForm } from '../../../index';
import { CollectionType } from '../../../../constants';

function EmissionSourceLocationSourceTable({
  locationDetails,
  collectionId,
  analyticsContext
}) {
  const [sourceItemList, setSourceItemList] = useState([]);

  const { me } = useMeContext();

  const { paginationParams } = usePagination({
    pageSize: 30,
    paginationArray: sourceItemList,
    orderBy: ['createdAt', 'id']
  });

  const handleLoadTableData = useCallback(async () => {
    const { list, count } =
      await EmissionSourceService.listEmissionSourceDetails({
        companySlug: me.company?.slug,
        collectionIds: [collectionId],
        pagination: paginationParams
      });
    setSourceItemList(list);
    return count;
  }, [
    collectionId,
    paginationParams.orderBy,
    paginationParams.order,
    paginationParams.pageNumber
  ]);
  const navigate = useNavigate();
  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData
  });

  const { Modal, handleShowModal, handleCloseModal } = useModal({
    width: '60%',
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        ...analyticsContext,
        collectionId: null // TODO after collections
      });
    },
    onCloseModal: () => {
      refreshList();
    }
  });

  const columns = [
    {
      key: 'Source Name',
      title: <ColumnTitle>Source Name</ColumnTitle>,
      render: (_, item) => getEmissionSourceDetailsName(item),
      onCell: () => ({
        className: 'text-bd'
      }),
      width: '300px'
    },
    {
      key: 'Description',
      title: <ColumnTitle>Description</ColumnTitle>,
      render: (_, item) => (
        <Paragraph bottomSpacing={0} className="description-text">
          {item?.description ?? '--'}
        </Paragraph>
      )
    },
    {
      key: 'Created',
      title: <ColumnTitle>Created</ColumnTitle>,
      render: (_, item) =>
        `${formatInTimeZone(
          new Date(item.createdAt),
          'UTC',
          'MMM dd, yyyy'
        )} by ${getFullName(item.createdBy)}`
    },
    {
      width: '50px',
      onCell: () => ({
        className: 'justify-end'
      }),
      render: () => (
        <Icon name="ChevronRight" color={COLOURS_CARBONHOUND.PRIMARY_PURPLE} />
      )
    }
  ];

  const handleSourceDetailsModalSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      collectionId: null // TODO after collections
    });
  };

  const handleCloseSourceModal = () => {
    refreshList();
    handleCloseModal();
  };
  return (
    <div className="emission-source-location-source-table">
      <Table
        isLoading={isLoading}
        isHeaderBorder
        dataSource={sourceItemList}
        pagination={paginationParams}
        showPagination
        columns={columns}
        total={count}
        onRow={(emissionSourceDetail) => ({
          className: 'cursor-pointer',
          onClick: () => {
            analytics.track('Item Clicked', me, {
              ...analyticsContext,
              collectionId: null, // TODO after collections
              emissionSourceDetails: emissionSourceDetail
            });
            navigate(
              AppRoutes.SOURCES_DETAILS.replace(
                ':emissionSourceDetailsId',
                emissionSourceDetail.id
              ),
              {
                state: {
                  collectionId,
                  address1: locationDetails?.location?.address1,
                  isMovedOut: locationDetails?.isMovedOut
                }
              }
            );
          }
        })}
        title={() => (
          <TableTitle
            title="Sources"
            isHeaderBorder
            buttons={[
              {
                children: 'Create New Source',
                onClick: handleShowModal
              }
            ]}
          />
        )}
      />
      <Modal footer={null} title="Create New Source">
        <EmissionSourceDetailForm
          collectionProps={{
            [CollectionType.LOCATION]: collectionId
          }}
          onClose={handleCloseSourceModal}
          onSuccess={handleSourceDetailsModalSuccess}
        />
      </Modal>
    </div>
  );
}
export default EmissionSourceLocationSourceTable;
