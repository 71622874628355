import { useCallback } from 'react';
import { useMeContext } from '../../../context';
import { getFullName } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { EmployeeService } from '../../../services';
import { Select } from '../../atoms';

function OwnerSelect(props) {
  const { me } = useMeContext();
  const handleLoadEmployees = useCallback(async () => {
    const { list } = await EmployeeService.fetchEmployees({
      companySlug: me?.company.slug
    });
    const adminList = list.filter((emp) => emp.role.permissionType === 'ADMIN');
    return adminList.map((employee) => ({
      label: getFullName(employee.user),
      value: employee.id
    }));
  }, [me?.company.slug]);
  const { value: employees } = useAsync({
    asyncFunction: handleLoadEmployees,
    defaultValue: []
  });
  return <Select {...props} options={employees} />;
}
export default OwnerSelect;
