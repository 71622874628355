import { Col, Row, Statistic } from 'antd';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback } from 'react';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { DashboardService } from '../../../services';
import { formatDecimal } from '../../../helpers';

function BaseYearInfo({ className }) {
  const {
    me: {
      company: {
        slug: companySlug,
        baseYear,
        startingMonth,
        baseYearEmissionsSum
      }
    }
  } = useMeContext();

  const handleFetchEmissionsSum = useCallback(async () => {
    if (baseYearEmissionsSum) return baseYearEmissionsSum;

    const month = startingMonth ? startingMonth - 1 : 0;
    const { emissions } = await DashboardService.fetchEmissionsSum(
      {
        companySlug,
        rangeStart: formatInTimeZone(
          new Date(baseYear, month, 1),
          'UTC',
          'yyyy-MM-dd'
        ),
        rangeEnd: formatInTimeZone(
          new Date(baseYear + 1, month, 0),
          'UTC',
          'yyyy-MM-dd'
        )
      },
      () => {},
      () => {},
      () => {}
    );
    return formatDecimal((emissions || 0) / 1000);
  }, [baseYear, companySlug, startingMonth]);

  const { Loader, value: emissionsSum } = useAsync({
    asyncFunction: handleFetchEmissionsSum
  });

  return (
    <Row
      className={classNames('target-setting-base-year-info', {
        [className]: !!className
      })}
    >
      <Loader>
        <Col span={12}>
          <Statistic
            className="text-center"
            groupSeparator=""
            title="Year"
            value={baseYear}
          />
        </Col>
        <Col span={12}>
          <Statistic
            className="text-center"
            title="Base Year Footprint"
            value={`${emissionsSum} tonnes of CO2e`}
          />
        </Col>
      </Loader>
    </Row>
  );
}

export default BaseYearInfo;
