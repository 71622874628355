import * as Yup from 'yup';

export const AddDetailsValidation = Yup.object().shape({
  name: Yup.string().nullable().required('Please enter a name'),
  requireNotifications: Yup.boolean()
    .nullable()
    .required('Please indicate your notification preferences'),
  hasOperationalControl: Yup.boolean()
    .nullable()
    .required('Operational control required'),
  isInactive: Yup.boolean().nullable().required('Please select an option.'),
  startDate: Yup.string().nullable().required('Please select an option.'),
  endDate: Yup.string()
    .nullable()
    .when('isInactive', {
      is: true,
      then: Yup.string().nullable().required('Please select an option.')
    }),
  dateType: Yup.string().nullable().required('Please select an option.'),
  tagIds: Yup.string().nullable().required('Please select a source category')
});
