import { Table as AntTable } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './table.scss';

function Table({
  className,
  secondary,
  noPadding,
  showPagination,
  total,
  rowClassName,
  paginationParams: { pageNumber, pageSize, onChange } = {
    pageNumber: 1,
    pageSize: 15,
    onChange: () => {}
  },
  ...props
}) {
  return (
    <AntTable
      {...props}
      className={classNames('ch-table-old', {
        'ch-table-old-secondary': !!secondary,
        'no-padding': !!noPadding,
        [className]: !!className
      })}
      rowClassName={(actionItem, index) => {
        const rowCN = rowClassName?.(actionItem, index);
        return classNames('ch-table__row', {
          [rowCN]: !!rowCN
        });
      }}
      pagination={
        showPagination
          ? {
              position: ['none', 'bottomCenter'],
              pageSize,
              current: pageNumber,
              onChange: (targetPageNumber) =>
                onChange(targetPageNumber, pageNumber),
              total,
              showSizeChanger: false,
              simple: true,
              hideOnSinglePage: true
            }
          : false
      }
    />
  );
}

export default Table;
