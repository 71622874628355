import { Col, Divider, Row, Space } from 'antd';
import { COLOURS_GREEN } from '../../../../constants/colours';
import {
  INITIATIVE_TYPE,
  INITIATIVE_TYPE_BENEFIT,
  INITIATIVE_TYPE_TAG_COLOURS
} from '../../../../constants/InitiativeType';
import { useMeContext } from '../../../../context';
import { capitalizeText } from '../../../../helpers';
import { Recommended } from '../../../assets/svgs';
import { Icon, Paragraph, Tag, Title } from '../../../atoms';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../atoms/Tag/tag.types';
import './InitiativeCard.scss';

export function InitiativeCard({ card }) {
  const { me } = useMeContext();
  const tags = [
    ...(card.tags ?? []).map((tag) => ({ ...tag, key: tag.name })),
    { name: card.type, key: card.type },
    ...(card.industries?.findIndex(
      (industry) => industry.id === me.company?.industry?.id
    ) > -1
      ? [{ icon: <Recommended />, key: 'Recommended' }]
      : [])
  ];
  return (
    <Row className="ch-initiative-card" gutter={[0, 24]}>
      <Col>
        <Space size={[8, 8]} wrap>
          {tags.map((tag) => (
            <Tag
              key={tag.key}
              color={
                INITIATIVE_TYPE_TAG_COLOURS[tag.name] ||
                INTERNAL_TAG_COLOURS[tag.name] ||
                TAG_COLOURS.ORANGE
              }
            >
              {tag.icon && tag.icon}
              {capitalizeText(tag.name)}
            </Tag>
          ))}
        </Space>
      </Col>
      <Col flex="1 1 auto">
        <Title className="ch-initiative-card__title" bottomSpacing={16}>
          {card.name}
        </Title>
        <Paragraph
          className="ch-initiative-card__description"
          bottomSpacing={0}
        >
          {card.shortDescription || card.description}
        </Paragraph>
      </Col>
      {card.details?.estimatedReduction && (
        <>
          <Col>
            <Divider />
          </Col>
          <Col className="display-flex align-center">
            <Icon
              name={INITIATIVE_TYPE_BENEFIT[card.type]?.icon}
              color={COLOURS_GREEN.GREEN_500}
              className="mr-4"
            />
            {card.type === INITIATIVE_TYPE.REDUCTION ? (
              <Paragraph weight={500} bottomSpacing={0}>
                Estimated reduction: {card.details.estimatedReduction}
              </Paragraph>
            ) : (
              <Paragraph weight={500} bottomSpacing={0}>
                {card.details.estimatedReduction}
              </Paragraph>
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
