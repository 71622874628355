import AxiosInstance from '../interceptors/axiosInstance';
import { successValidator } from '../interceptors/statusValidators';
import { ApiRoutes } from '../routes';

class IntegrationService {
  static async listCompanyIntegrations(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INTEGRATION}/company/${companySlug}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      onSuccess?.({
        list: response.data?.integrations,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.integrations,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getCompanyIntegration(
    { companySlug, integrationSlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(':integrationSlug', integrationSlug)}`;

      const response = await AxiosInstance.get(API_URL, { params });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listCompanyIntegrationProviders({
    companySlug,
    integrationSlug,
    pagination,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':integrationSlug',
        integrationSlug
      ).replace(':companySlug', companySlug)}/utilityProvider/list`;
      const response = await AxiosInstance.get(API_URL, {
        pagination,
        validateStatus: successValidator,
        params
      });
      return response.data?.utilityProviders;
    } catch (error) {
    } finally {
    }
  }

  static async deleteCompanyIntegration(
    { companySlug, integrationSlug },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(':integrationSlug', integrationSlug)}`;
      await AxiosInstance.delete(API_URL);
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompanyIntegration(
    { companySlug, integrationSlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(':integrationSlug', integrationSlug)}`;
      await AxiosInstance.put(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createCompanyIntegration({
    companySlug,
    integrationSlug,
    ...params
  }) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(':integrationSlug', integrationSlug)}`;
      const response = await AxiosInstance.post(API_URL, { ...params });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async createProvider(
    { integrationSlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_UTILITY_PROVIDER.replace(
        ':integrationSlug',
        integrationSlug
      )}`;
      const response = await AxiosInstance.post(API_URL, { ...params });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getProvider(
    { integrationSlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.CHC_UTILITY_PROVIDER.replace(
        ':integrationSlug',
        integrationSlug
      )}`;
      await AxiosInstance.post(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompanyIntegrationProvider(
    { companySlug, integrationSlug, companyUtilityProviderId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(
        ':integrationSlug',
        integrationSlug
      )}/utilityProvider/${companyUtilityProviderId}`;
      await AxiosInstance.put(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async updateCompanyIntegrationProvider(
    { companySlug, integrationSlug, companyUtilityProviderId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INTEGRATION.replace(
        ':companySlug',
        companySlug
      ).replace(
        ':integrationSlug',
        integrationSlug
      )}/utilityProvider/${companyUtilityProviderId}`;
      await AxiosInstance.put(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default IntegrationService;
