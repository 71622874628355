import { Col, Space } from 'antd';
import { useNavigate } from 'react-router';

import { useOfficeTrailContext } from '../../../context';
import { capitalizeText } from '../../../helpers';
import { OfficeTrailSegment } from '../../../constants';
import { AppRoutes } from '../../../routes';
import { Card } from '../../../components';
import { Button, Link, Title } from '../../../stories/atoms';

import '../DashboardOnboardingWidgets.scss';

export function CompanyOnboardingWidget() {
  const { officeTrailDetails } = useOfficeTrailContext();
  const navigate = useNavigate();
  const { officeTrailStatus } = officeTrailDetails;

  const adminTrailStatus = [
    {
      key: OfficeTrailSegment.ENERGY,
      value: officeTrailStatus[OfficeTrailSegment.ENERGY]
    },
    {
      key: OfficeTrailSegment.COMMUTE,
      value: officeTrailStatus[OfficeTrailSegment.COMMUTE]
    },
    {
      key: OfficeTrailSegment.BUSINESS_TRAVEL,
      value: officeTrailStatus[OfficeTrailSegment.BUSINESS_TRAVEL]
    },
    {
      key: OfficeTrailSegment.CUSTOM_SOURCES,
      value: officeTrailStatus[OfficeTrailSegment.CUSTOM_SOURCES]
    },
    {
      key: OfficeTrailSegment.TARGET,
      value: officeTrailStatus[OfficeTrailSegment.TARGET]
    }
  ];

  const currentAdminStep = adminTrailStatus.find(
    (elem) => elem.value === 'started' || elem.value === 'incomplete'
  );
  const step = currentAdminStep?.key;
  const isStarted = adminTrailStatus.some((elem) => elem.value === 'complete');

  const orderedTrailSteps = {
    [OfficeTrailSegment.ENERGY]: {
      trailName: !!isStarted ? 'Office Onboarding' : 'Get Started',
      trailRoute: AppRoutes.ONBOARDING_ADMIN_LOCATION
    },
    [OfficeTrailSegment.COMMUTE]: {
      trailName: 'Invite Employees',
      trailRoute: AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES
    },
    [OfficeTrailSegment.BUSINESS_TRAVEL]: {
      trailName: 'Business Travel',
      trailRoute: AppRoutes.ONBOARDING_ADMIN_INVITE_TRAVEL_EXPENSE
    },
    [OfficeTrailSegment.CUSTOM_SOURCES]: {
      trailName: 'Custom Sources',
      trailRoute: AppRoutes.ONBOARDING_ADMIN_SOURCES
    },
    [OfficeTrailSegment.TARGET]: {
      trailName: 'Set Targets',
      trailRoute: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING
    }
  };

  const { trailName, trailRoute = '' } = orderedTrailSteps[step] ?? {};

  const handleNavigateWidgetButton = () => navigate(trailRoute);

  if (!!currentAdminStep) {
    return (
      <Col flex="1 0 50%" className="display-flex">
        <Card className="onboarding-card">
          <Space align="center" className="display-flex justify-space-between">
            <div className="onboarding-icon__section">
              <div className="onboarding-icon onboarding-icon-yellow mr-4">
                💼
              </div>
              <Title bottomSpacing={0}>Company Onboarding</Title>
            </div>
            <Space direction="vertical" size={0}>
              {!!isStarted && (
                <Title bottomSpacing={0} size="sm" align="right">
                  Next Step
                </Title>
              )}
              {!isStarted ? (
                <Button onClick={handleNavigateWidgetButton}>
                  {capitalizeText(trailName)}
                </Button>
              ) : (
                <Link fontWeight={600} to={trailRoute}>
                  {capitalizeText(trailName)}
                </Link>
              )}
            </Space>
          </Space>
        </Card>
      </Col>
    );
  }
  return null;
}
