import * as Yup from 'yup';
import { DISTANCE_CALCULATION_TYPE } from '../../../../constants';

export const EmissionSourceItemFormValidation = Yup.object().shape({
  date: Yup.string()
    .when('dateType', {
      is: 'SINGLE',
      then: Yup.string().nullable().required('Please select a date'),
      otherwise: Yup.string().nullable()
    })
    .when(['dateType', 'startDate', 'endDate'], {
      is: (dateType, startDate, endDate) =>
        dateType === 'RANGE' && (!startDate || !endDate),
      then: Yup.string()
        .nullable()
        .required('Please select a start and end date'),
      otherwise: Yup.string().nullable()
    }),
  conversionUnitName: Yup.string().when(
    ['distanceCalculationType', 'isDistanceBasedSource'],
    {
      is: (distanceCalculationType, isDistanceBasedSource) =>
        !isDistanceBasedSource ||
        distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
      then: Yup.string().nullable().required('Please select a unit'),
      otherwise: Yup.string().nullable()
    }
  ),
  activity: Yup.string().when(
    ['distanceCalculationType', 'isDistanceBasedSource'],
    {
      is: (distanceCalculationType, isDistanceBasedSource) =>
        !isDistanceBasedSource ||
        distanceCalculationType === DISTANCE_CALCULATION_TYPE.NONE,
      then: Yup.string().nullable().required('Required'),
      otherwise: Yup.string().nullable()
    }
  ),
  departureLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  destinationLocationId: Yup.string().when('distanceCalculationType', {
    is: (distanceCalculationType) =>
      distanceCalculationType ===
      DISTANCE_CALCULATION_TYPE.GOOGLE_DISTANCE_MATRIX,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().nullable()
  }),
  isEstimate: Yup.boolean().nullable().required('Required')
});
