import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class OnboardingService {
  static async setClimateGoals({ companySlug, climateGoals }) {
    try {
      const API_URL = `${ApiRoutes.ONBOARDING_COMPANY_GOALS.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await axiosInstance.post(API_URL, {
        ...climateGoals
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  static async setGreenTeam({ companySlug, users }) {
    try {
      const API_URL = `${ApiRoutes.ONBOARDING_GREEN_TEAM_UPLOAD_BULK.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await axiosInstance.post(API_URL, { users });
      return response;
    } catch (err) {
      console.error(err);
    }
  }
}

export default OnboardingService;
