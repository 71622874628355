const InitiativeCaseStudies = [
  {
    title: 'MMM Group',
    caseStudyFilters: ['MMM GROUP'],
    description:
      'MMM Group has taken advantage of this hands-free approach to reducing their office’s electricity use through lighting. Each occupancy sensor installed in a problem area typically results in savings of 40-50% on electricity used to power that particular light fixture, with an average payback of only 2.3 years.',
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/case-study-partners/mmmGroup.png',
    url: 'https://www.reminetwork.com/articles/mmm-group-achieves-leed-ci-thrice/',
    urlText: 'View More'
  },
  {
    title: 'Veriform',
    caseStudyFilters: ['VERIFORM'],
    description:
      'Removed 72 of their 88 (highly inefficient) office lights after their employees complained it was too bright. The initiative resulted in an approximate savings of $680 on their electricity bills every year, even after taking the cost of the bulbs into account, and prevented roughly 0.95 tonnes of yearly CO2 emissions.',
    logoSrc: ''
  },
  {
    title: 'Community Support Connections',
    caseStudyFilters: ['COMMUNITY SUPPORT CONNECTIONS'],
    description:
      'Removed 63 bulbs for annual savings of approximately 3572 kwh and $427.',
    logoSrc: ''
  },
  {
    title: 'Conestoga Mall',
    caseStudyFilters: ['CONESTOGA MALL'],
    description:
      'Replaced its metal halide parking lot pole heads and exterior building mounted lights with LED bulbs for an initial investment of $186k, resulting in $28,000 (156,000 kWh) per year in electricity savings with an expected 6.5 year payback period. With work performed by Tradeforce Tech (a Regional Sustainability Initiative member), Canadian General Tower replaced their bollards, wall packs, floodlights and pole tops with LED lights, achieving a 51% reduction in electricity use and heightened visibility for their security team.',
    logoSrc: ''
  },
  {
    title: 'Dazor Lighting Technology',
    caseStudyFilters: ['DAZOR LIGHTING TECHNOLOGY'],
    description: `The use of a lighting system which integrates task and overhead lighting can have a direct impact on the bottom line by lowering utility dollars.

Instead of trying to maintain proper lighting levels on desktops from overhead fixtures, task lights can do a better job in providing adequate foot-candles. A task light using an 18-watt compact fluorescent will consume far less energy than a typical overhead lighting fixture.

A work environment can maintain lower levels of overhead light by illuminating desktops with energy-efficient task lights. For examples consider an office with 16 workstations illuminated by 16 overhead fixtures each with four T8 32-watt fluorescents. The total wattage when all fixtures are operating is 2,048. If each fixture used two T8 instead of four, and each workstation was equipped with an 18-watt task light, energy consumption would be reduced by 36 percent!

A recent example demonstrates the energy waste that occurs all too frequently. An office manager remarked that, ""we don't need those (task lights) to control glare on our computer screens. We installed these filters on the screens to block out glare."" This makes no sense in terms of cost reduction and energy conservation. First flood the room with light and then block it out with filters. It is like turning the heat up to 90 degrees in the winter and then opening the window to maintain the right temperature.

Other ways in which the use of task lighting can help control costs are by reducing maintenance costs. Task lights are easy to install, keep clean, and change bulbs. When offices made from wall partition furniture systems are rearranged, task lights are easily moved. Proper lighting is achieved without much worry about the location of overhead fixtures.`,
    logoSrc: '',
    url: 'https://www.dazor.com/task-lighting-benefits.html',
    urlText: 'View'
  },
  {
    title: 'Benefits of Transit Incentives ',
    caseStudyFilters: ['BENEFITS OF TRANSIT INCENTIVES'],
    description: `While providing transportation benefits may require upfront investments, employers could come out ahead in the long term by reducing re-hiring and retraining costs.

Commuter benefits come in all shapes and sizes. Motivated by its commitment to environmental sustainability, Vancouver City Savings Credit Union offers employees a 25 per cent reimbursement on the cost of transit passes, $500 to purchase a bicycle, up to $1,000 to buy an electric bike, $200 for annual tune-ups and $5,000 to purchase an electric car, says Sean Raible, the organization’s director of total rewards, health and well-being.

While the credit union also pays to offset its carbon footprint and sees an immediate financial impact by encouraging its staff to choose sustainable commuting options, Raible says saving money isn’t the real motivator of the program. “It’s really tied to environmental sustainability as one of Vancity’s three guiding principles, most recently typified by our [United Nations] sustainable development goal commitment to reduce our carbon footprint to 1.5 tonnes of CO2 equivalent per employee. It’s just who we are and what we do — saving money is only a pleasant co-benefit.”

Parking was the key issue for the Massachusetts Institute of Technology. One garage on campus was reaching the end of its structural life and had to be demolished. The university would have had to replace it with underground parking, at a cost between US$50,000 and US$100,000 per space.

“At the end of the day, it comes down to cost and benefit,” says Adam Rosenfield, a transportation planner based in London, U.K., who studied MIT’s commuter benefits while he was a graduate student at the university. “It was costly to rebuild parking garages and to secure more land, and it makes more sense to spend a fraction of that to get people to take transit, to carpool.”

MIT introduced a multi-pronged commuter benefits program. It moved from annual to daily parking pricing, which was capped at the former annual amount to ensure no one spent more money on parking after the change. It also offered employees free passes for the local transit system, a discounted pass for commuter rail and discounted parking at commuter rail stations.

“The most important aspect of this was the parking pricing,” says Rosenfield. “That approach was really important to getting people thinking about their day-to-day mode choice.”

The concept of salience from behavioural economics was also key — to what degree would people feel the cost? Once MIT employees were paying US$10 for daily parking instead of US$1,760 automatically taken off their paycheques once a year, parking felt a lot more expensive and employees no longer had to pay for days they didn’t park.

At the same time, the free transit pass made the subway commute much less expensive. Two years after the changes were introduced, demand for employee parking decreased by 10 per cent, which was enough to skip building the new parking garage.`,
    logoSrc: '',
    url: 'https://www.benefitscanada.com/news/bencan/using-transportation-benefits-to-help-ease-employees-commutes/',
    urlText: 'Read More'
  },
  {
    title: 'EPA Demand Reduction and Energy Savings using Occupancy Sensors',
    caseStudyFilters: [
      'EPA DEMAND REDUCTION AND ENERGY SAVINGS USING OCCUPANCY SENSORS'
    ],
    description: `Lighting is one of the single largest users of electrical energy in a typical commercial building. While occupancy sensors have become a mainstream solution for eliminating wasted lighting energy in these applications, there continues to be a need for research documenting both the magnitude of the savings by application and the impact these controls have on demand. A study by the Environmental ProtectionAgency and the
Lighting Research Center of Rensselaer Polytechnic Institute presented at the IESNAAnnual Conference in Washington, DC (August 2000) provides unique and valuable data about occupancy sensor demand reduction and energy savings potential.

Study Highlights
Sixty organizations, which were active participants in the EPA’s Green Lights Program, provided a total of 158 rooms falling into 5 occupancy types: 42 restrooms, 37 private offices, 35 classrooms, 33 conference rooms and 11 break rooms. Each room was monitored for occupancy and lighting status over a 14-day period using Watt Stopper’s Intellitimer Pro light logger. The light logger data were converted to one-minute intervals, which made it possible to evaluate occupancy patterns, calculate energy savings and estimate the demand reduction potential using simulated occupancy sensor time delays. Occupancy sensor time delays of 5-, 10-,15-, and 20- minutes simulated in the study, although data for the minimum (5-minute) and maximum (20-minute) time delay simulations are presented here. The data presented in this paper was derived from studies with fluorescent lighting.

Energy Savings
The percentage of energy waste that actually occurred for the 14-day period and the calculated energy savings for the 5- and 20-minute time delay simulations are between 39%-60% depending on the room type. Not all of the wasted lighting energy is captured when occupancy sensors are used because lights remain on for the duration of the time delay setting. Similarly, the energy savings decreases as the timeout setting increases because lights remain on in the unoccupied room for a longer time period. Shorter time delays also increase the switching frequency of the lamps and ballasts or drivers, which may reduce lamp light source life."`,
    logoSrc: ''
  },
  {
    title: 'Sun Microsystems',
    caseStudyFilters: [
      'EPA DEMAND REDUCTION AND ENERGY SAVINGS USING OCCUPANCY SENSORS'
    ],
    description: `"Sun Microsystems’s Open Work Energy Measurement Project, an internal study comparing the home and office energy use of more than 100 participants in its flexible work program, found that working at home an average of 2.5 days/week saved energy, time and money.

Key findings of the study include:

Employees saved more than $1,700 per year in gasoline and wear and tear on their vehicles by working at home an average of 2.5 days a week.

Office equipment energy consumption rate at a Sun office was two times that of home office equipment energy consumption, from approximately 64 watts per hour at home to 130 watts per hour at a Sun office.

Commuting was more than 98% of each employee’s carbon footprint for work, compared to less than 1.7% of total carbon emissions to power office equipment.

By eliminating commuting just 2.5 days per week, an employee reduces energy used for work by the equivalent of 5,400 Kilowatt hours/year.

Working from home 2.5 days per week saved the employees in the study an average of 2.5 weeks of commute time (8 hours/day, 5 days/week).

Sun’s Open Work platform is an integrated suite of technologies, tools and workplace practices that enable Sun employees to work effectively anywhere, anytime, using any device. Through this program, nearly 19,000 employees around the world work from home or in a flexible office, representing more than 56% of Sun’s employee population.`,
    logoSrc: ''
  }
];

export default InitiativeCaseStudies;
