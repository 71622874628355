import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Navigate, useRoutes } from 'react-router-dom';

import { AppLoader, ScrollToTop, TrailComplete } from '../components';
import RequireAuth from '../components/HOC/requireAuth';
import RequireService from '../components/HOC/requireService';
import { UserRole } from '../constants';
import {
  InitiativeContextProvider,
  useMeContext,
  useMetaContext
} from '../context';
import { CollectionContextProvider } from '../context/CollectionContext';
import { DataInputContextProvider } from '../context/DataImportContext';
import { EmissionSourceOverview } from '../features';
import EmployeeDetailsCommute from '../features/EmployeeDetail/EmployeeDetailsCommute';
import EmployeeDetailSteps from '../features/EmployeeDetail/EmployeeDetailSteps';
import Onboarding from '../features/Onboarding';
import {
  TargetSettingBaselineYear,
  TargetSettingData,
  TargetSettingOverview,
  TargetSettingReductions,
  TargetSettingStrategy
} from '../features/Target';
import { history } from '../helpers';
import Achievements from '../pages/Achievements';
import Actions from '../pages/Actions';
import { CompanyInformation, CreateAccount } from '../pages/Auth/Register';
import ResetPassword from '../pages/Auth/ResetPassword';
import SelectCompany from '../pages/Auth/SelectCompany';
import BusinessUnitDetails from '../pages/BusinessUnit';
import { ChcAdminCompanyDetails } from '../pages/ChcAdmin/ChcAdminCompanyDetails';
import { ChcAdminCompanyOverview } from '../pages/ChcAdmin/ChcAdminCompanyList';
import CollectionDetails from '../pages/Collection';
import Consultants from '../pages/Consultants';
import Credits from '../pages/Credits';
import Dashboard from '../pages/Dashboard';
import { DataExplorer } from '../pages/Data/DataExplorer';
import { DataImport } from '../pages/DataImport';
import Sources from '../pages/EmissionSource';
import EmissionSourcesDetails from '../pages/EmissionSource/EmissionSourceDetails';
import EmissionSourcesLocationDetails from '../pages/EmissionSource/EmissionSourceLocationDetails';
import ExternalDashboard from '../pages/ExternalDashboard';
import Help from '../pages/Help';
import {
  CompanyInitiativeOverview,
  InitiativeDetailLayout
} from '../pages/Initiative';
import { CompanyInitiativeBaseline } from '../pages/Initiative/CompanyInitiative/CompanyInitiativeBaseline';
import { CompanyInitiativeBusinessPlan } from '../pages/Initiative/CompanyInitiative/CompanyInitiativeBusinessPlan';
import { CompanyInitiativeFunding } from '../pages/Initiative/CompanyInitiative/CompanyInitiativeFunding';
import { CompanyInitiativeRecommendedActions } from '../pages/Initiative/CompanyInitiative/CompanyInitiativeRecommendedActions';
import { CompanyInitiativeSources } from '../pages/Initiative/CompanyInitiative/CompanyInitiativeSources';
import { InitiativeOutlet } from '../pages/Initiative/InitiativeOutlet';
import { InitiativeOverview } from '../pages/Initiative/InitiativeOverview';
import { InitiativeTemplate } from '../pages/Initiative/InitiativeTemplate';
import { IntegrationDetail } from '../pages/IntegrationDetail';
import ForgotPassword from '../pages/Login/ForgotPassword';
import Login from '../pages/Login/LoginPage';
import {
  OnboardingAdminInviteEmployees,
  OnboardingAdminLayout
} from '../pages/Onboarding/OnboardingAdmin';
import {
  OnboardingEmployeeLayout,
  OnboardingEmployeeOverview
} from '../pages/Onboarding/OnboardingEmployee';
import { OnboardingEmployeeImpact } from '../pages/Onboarding/OnboardingEmployee/OnboardingEmployeeImpact';
import SignUp from '../pages/SignUp';
import { Subscription } from '../pages/Subscription';
import { Terms } from '../pages/Terms';
import TrailsOverview from '../pages/Trails/TrailsOverview';
import TrailsSegments from '../pages/Trails/TrailsSegments';
import { CustomSources } from '../pages/Trails/TrailsSegments/CustomSources';
import EmployeeDetails from '../pages/Trails/TrailsSegments/EmployeeDetails';
import EmployeeDetailsAddress from '../pages/Trails/TrailsSegments/EmployeeDetails/EmployeeDetailsAddress';
import EmployeeDetailsOverview from '../pages/Trails/TrailsSegments/EmployeeDetails/EmployeeDetailsOverview';
import { EmployeeDetailsWFH } from '../pages/Trails/TrailsSegments/EmployeeDetails/EmployeeDetailsWFH';
import OfficeOnboarding from '../pages/Trails/TrailsSegments/OfficeOnboarding';
import ManageBills from '../pages/Trails/TrailsSegments/OfficeOnboarding/ManageBills';
import OfficeDetails from '../pages/Trails/TrailsSegments/OfficeOnboarding/OfficeDetails';
import OfficeLocation from '../pages/Trails/TrailsSegments/OfficeOnboarding/OfficeLocation';
import OfficeOnboardingOverview from '../pages/Trails/TrailsSegments/OfficeOnboarding/OfficeOnboardingOverview';
import TargetSetting from '../pages/Trails/TrailsSegments/TargetSetting';
import TravelExpenseOverview from '../pages/Trails/TrailsSegments/TravelExpenses/TravelExpenseOverview';
import UserCompany from '../pages/UserCompany';
import UserProfile from '../pages/UserProfile';
import Verification from '../pages/Verification';
import VerifyEmail from '../pages/VerifyEmail';
import { MeService } from '../services';
import { AppHeader } from '../stories/molecules';
import AppRoutes from './appRoutes';

function AppRouter() {
  const [showRouter, setShowRouter] = useState(false);
  const [loading, setLoading] = useState(false);

  const { me, updateMe, setJWTToken, isSuperUser, getMyGroupSettings } =
    useMeContext();
  const { company } = me;
  const { role } = company ?? {};

  const location = useLocation();
  const { setCountries, setIndustries } = useMetaContext();
  const isAdmin = role?.permissionType === UserRole.ADMIN || isSuperUser(me);
  const routes = useRoutes([
    { path: AppRoutes.LOGIN, element: <Login /> },
    { path: AppRoutes.VERIFY_EMAIL, element: <VerifyEmail /> },
    { path: AppRoutes.FORGOT_PASSWORD, element: <ForgotPassword /> },
    { path: AppRoutes.RESET_PASSWORD, element: <ResetPassword /> },
    { path: AppRoutes.CREATE_EMAIL, element: <SignUp /> },
    { path: AppRoutes.COMPANY_INFORMATION, element: <CompanyInformation /> },
    { path: AppRoutes.CREATE_ACCOUNT, element: <CreateAccount /> },
    { path: AppRoutes.TERMS, element: <Terms /> },
    {
      path: AppRoutes.CHC_ADMIN,
      element: <RequireService />,
      children: [
        {
          path: AppRoutes.CHC_ADMIN,
          element: <ChcAdminCompanyOverview />
        },
        {
          path: AppRoutes.CHC_ADMIN_COMPANY_DETAILS,
          element: <ChcAdminCompanyDetails />
        }
      ]
    },
    {
      path: AppRoutes.HOME,
      element: <RequireAuth />,
      children: [
        {
          path: AppRoutes.BUSINESS_UNIT,
          element: <Navigate to={AppRoutes.HOME} />
        },
        {
          path: AppRoutes.BUSINESS_UNIT_DETAILS,
          element: (
            <CollectionContextProvider>
              <BusinessUnitDetails />
            </CollectionContextProvider>
          )
        },
        {
          path: AppRoutes.COLLECTION_DETAILS,
          element: (
            <CollectionContextProvider>
              <CollectionDetails />
            </CollectionContextProvider>
          )
        },
        { path: AppRoutes.CREDITS, element: <Credits /> },
        {
          path: AppRoutes.INTEGRATION_DETAIL,
          element: <IntegrationDetail />
        },
        {
          path: AppRoutes.HELP,
          element: <Help />
        },
        {
          path: AppRoutes.CONSULTANTS,
          element: <Consultants />
        },
        {
          path: AppRoutes.SELECT_COMPANY,
          element: <SelectCompany />
        },
        {
          path: AppRoutes.DASHBOARD,
          element: (
            <InitiativeContextProvider>
              <Dashboard />
            </InitiativeContextProvider>
          )
        },
        {
          path: AppRoutes.DATA_EXPLORER,
          element: <DataExplorer />
        },
        {
          path: AppRoutes.DATA_IMPORTS,
          element: (
            <DataInputContextProvider>
              <DataImport />
            </DataInputContextProvider>
          )
        },

        {
          path: AppRoutes.HOME,
          element: <Navigate to={AppRoutes.DASHBOARD} />
        },
        {
          path: AppRoutes.ONBOARDING,
          element: <Onboarding />
        },
        {
          path: AppRoutes.ONBOARDING_ADMIN,
          element: isAdmin ? (
            <OnboardingAdminLayout />
          ) : (
            <Navigate to={AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW} />
          ),
          children: [
            {
              path: AppRoutes.ONBOARDING_ADMIN_INVITE_TRAVEL_EXPENSE,
              element: <TravelExpenseOverview />
            },
            {
              path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING,
              element:
                isAdmin || isSuperUser() ? (
                  <TargetSetting />
                ) : (
                  <Navigate to={AppRoutes.TRAIL_COMPLETE} />
                ),
              children: [
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW,
                  element: <TargetSettingOverview />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_BASELINE,
                  element: <TargetSettingBaselineYear />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY,
                  element: <TargetSettingStrategy />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_REDUCTIONS,
                  element: <TargetSettingReductions />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_DATA,
                  element: <TargetSettingData />
                }
              ]
            },
            {
              path: AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES,
              element: <OnboardingAdminInviteEmployees />
            },
            {
              path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING,
              element: <OfficeOnboarding />,
              children: [
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OVERVIEW,
                  element: <OfficeOnboardingOverview />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION,
                  element: <OfficeLocation />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION_EDIT,
                  element: <OfficeLocation />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OFFICE_DETAILS,
                  element: <OfficeDetails />
                },
                {
                  path: AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS,
                  element: <ManageBills />
                }
              ]
            },
            {
              path: AppRoutes.ONBOARDING_ADMIN_SOURCES,
              element:
                isAdmin || isSuperUser() ? (
                  <CustomSources />
                ) : (
                  <Navigate to={AppRoutes.ONBOARDING_ADMIN_COMPLETE} />
                )
            },
            {
              path: AppRoutes.ONBOARDING_ADMIN_COMPLETE,
              element: <TrailComplete />
            }
          ]
        },
        {
          path: AppRoutes.ONBOARDING_EMPLOYEE,
          element: <OnboardingEmployeeLayout />,
          children: [
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS,
              element: <EmployeeDetailsOverview />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH,
              element: <EmployeeDetailsAddress />
            },

            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH_EDIT,
              element: <EmployeeDetailsAddress />
            },

            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS,
              element: <EmployeeDetailSteps />
            },

            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_DETAILS,
              element: <EmployeeDetailsCommute />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE,
              element: <OnboardingEmployeeOverview />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW,
              element: <OnboardingEmployeeOverview />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL,
              element: <TravelExpenseOverview />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_YOUR_IMPACT,
              element: <OnboardingEmployeeImpact />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME,
              element: <EmployeeDetailsWFH />
            },
            {
              path: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME_EDIT,
              element: <EmployeeDetailsWFH />
            }
          ]
        },
        {
          path: AppRoutes.ACTIONS,
          element: <Actions />
        },
        { path: AppRoutes.VERIFICATION, element: <Verification /> },
        {
          path: AppRoutes.INITIATIVE,
          element: <InitiativeOutlet />,
          children: [
            { index: true, element: <InitiativeOverview /> },
            {
              path: AppRoutes.INITIATIVE_TEMPLATE,
              element: <InitiativeTemplate />
            },
            {
              element: <InitiativeDetailLayout />,
              path: AppRoutes.COMPANY_INITIATIVE,
              children: [
                { index: true, element: <CompanyInitiativeOverview /> },
                {
                  path: AppRoutes.COMPANY_INITIATIVE_IMPACTED_SOURCES,
                  element: <CompanyInitiativeSources />
                },
                {
                  path: AppRoutes.COMPANY_INITIATIVE_BASELINE,
                  element: <CompanyInitiativeBaseline />
                },
                {
                  path: AppRoutes.COMPANY_INITIATIVE_BUSINESS_PLAN,
                  element: <CompanyInitiativeBusinessPlan />
                },
                {
                  path: AppRoutes.COMPANY_INITIATIVE_FUNDING,
                  element: <CompanyInitiativeFunding />
                },
                {
                  path: AppRoutes.COMPANY_INITIATIVE_RECOMMENDED_ACTIONS,
                  element: <CompanyInitiativeRecommendedActions />
                }
              ]
            }
          ]
        },
        { path: AppRoutes.ACHIEVEMENTS, element: <Achievements /> },
        { path: AppRoutes.EXTERNAL_DASHBOARD, element: <ExternalDashboard /> },
        { path: AppRoutes.USER_PROFILE, element: <UserProfile /> },
        { path: AppRoutes.SUBSCRIPTION, element: <Subscription /> },
        {
          path: AppRoutes.USER_COMPANY,
          element:
            isAdmin || isSuperUser() ? (
              <UserCompany />
            ) : (
              <Navigate to={AppRoutes.HOME} />
            )
        },
        { path: AppRoutes.TRAILS_OVERVIEW, element: <TrailsOverview /> },
        {
          path: AppRoutes.TRAILS_SEGMENTS,
          element: <TrailsSegments />,
          children: [
            {
              path: AppRoutes.TRAILS_SEGMENTS,
              element: (
                <Navigate to={AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW} />
              )
            },
            {
              path: AppRoutes.OFFICE_ONBOARDING,
              element: (
                <Navigate to={AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW} />
              ),
              children: [
                {
                  path: AppRoutes.OFFICE_ONBOARDING,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.OFFICE_ONBOARDING_OVERVIEW,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.OFFICE_ONBOARDING_LOCATION,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION}
                    />
                  )
                },
                {
                  path: AppRoutes.OFFICE_ONBOARDING_LOCATION_EDIT,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION}
                    />
                  )
                },
                {
                  path: AppRoutes.OFFICE_ONBOARDING_OFFICE_DETAILS,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_LOCATION_OFFICE_DETAILS}
                    />
                  )
                },
                {
                  path: AppRoutes.OFFICE_ONBOARDING_MANAGE_BILLS,
                  element: (
                    <Navigate to={AppRoutes.ONBOARDING_ADMIN_LOCATION_BILLS} />
                  )
                }
              ]
            },
            {
              path: AppRoutes.CUSTOM_SOURCES_TRAIL,
              element:
                isAdmin || isSuperUser() ? (
                  <Navigate to={AppRoutes.ONBOARDING_ADMIN_SOURCES} />
                ) : (
                  <Navigate to={AppRoutes.TRAIL_COMPLETE} />
                )
            },
            {
              path: AppRoutes.EMPLOYEE_COMMUTE,
              element: <EmployeeDetails />,
              children: [
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE,
                  element: <Navigate to={AppRoutes.EMPLOYEE_COMMUTE_OVERVIEW} />
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_OVERVIEW,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_INVITE_TEAM,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_MANAGE_EMPLOYEES,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_ADDRESS_NEW,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_LOCATION_ADDRESS}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_ADDRESS_EDIT,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_LOCATION_ADDRESS_EDIT}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_DETAILS_WFH_EDIT,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME_EDIT}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_DETAILS_WFH_NEW,
                  element: (
                    <Navigate to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME} />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_STEPS,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS}
                    />
                  )
                },
                {
                  path: AppRoutes.EMPLOYEE_COMMUTE_DETAILS,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_DETAILS}
                    />
                  )
                }
              ]
            },
            {
              path: AppRoutes.TARGET_SETTING,
              element:
                isAdmin || isSuperUser() ? (
                  <TargetSetting />
                ) : (
                  <Navigate to={AppRoutes.ONBOARDING_ADMIN_COMPLETE} />
                ),
              children: [
                {
                  path: AppRoutes.TARGET_SETTING,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.TARGET_SETTING_OVERVIEW,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW}
                    />
                  )
                },
                {
                  path: AppRoutes.TARGET_SETTING_BASELINE,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_BASELINE}
                    />
                  )
                },
                {
                  path: AppRoutes.TARGET_SETTING_STRATEGY,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY}
                    />
                  )
                },
                {
                  path: AppRoutes.TARGET_SETTING_REDUCTIONS,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_REDUCTIONS}
                    />
                  )
                },
                {
                  path: AppRoutes.TARGET_SETTING_DATA,
                  element: (
                    <Navigate
                      to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_DATA}
                    />
                  )
                }
              ]
            },
            {
              path: AppRoutes.BUSINESS_TRAVEL_OVERVIEW,
              element: (
                <Navigate
                  to={AppRoutes.ONBOARDING_ADMIN_INVITE_TRAVEL_EXPENSE}
                />
              )
            },
            {
              path: AppRoutes.TRAIL_COMPLETE,
              element: <TrailComplete />
            }
          ]
        },
        {
          path: AppRoutes.SOURCES,
          element:
            isAdmin || isSuperUser() ? (
              <Sources />
            ) : (
              <Navigate to={AppRoutes.HOME} />
            ),
          children: [
            {
              path: AppRoutes.SOURCES,
              element: <Navigate to={AppRoutes.SOURCES_OVERVIEW} />
            },
            {
              path: AppRoutes.SOURCES_OVERVIEW,
              element: <EmissionSourceOverview />
            },
            {
              path: AppRoutes.SOURCES_DETAILS,
              element: (
                <CollectionContextProvider>
                  <EmissionSourcesDetails />
                </CollectionContextProvider>
              )
            },
            {
              path: AppRoutes.SOURCES_LOCATION_DETAILS,
              element: (
                <CollectionContextProvider>
                  <EmissionSourcesLocationDetails />
                </CollectionContextProvider>
              )
            }
          ]
        }
      ]
    }
  ]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setCountries();
    setIndustries();
    if (token) {
      setJWTToken(token);
      setLoading(true);
      MeService.getMyDetails(
        (me) => {
          window.pendo.initialize({
            visitor: {
              id: me.id,
              email: me.email
            }
          });
          updateMe(me);
          if (
            !isSuperUser(me) &&
            me.company?.onboardingStatus?.isOnboardingRequired
          ) {
            history.push(AppRoutes.ONBOARDING, me.company?.onboardingStatus);
          }
          setShowRouter(true);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    } else {
      setShowRouter(true);
    }
  }, []);

  useEffect(() => {
    getMyGroupSettings();
  }, []);

  return showRouter ? (
    <div>
      {!!me.id && <AppHeader />}
      <div
        className="ch-margin-container"
        style={{
          marginTop: location.pathname.startsWith(AppRoutes.CHC_ADMIN)
            ? '48px'
            : '80px'
        }}
      >
        <ScrollToTop />
        {routes}
      </div>
    </div>
  ) : loading ? (
    <AppLoader loading={loading} />
  ) : null;
}

export default AppRouter;
