/** OfficeOnboardingContext is responsible for storing the Global State
 * for office onboarding details.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';

// Create the context
const EmployeeCommuteContext = createContext({});

// Define the default context state
const employeeCommuteState = {
  activeCommute: undefined,
  commutes: [],
  profile: {},
  isDetailsLoading: false
};

// Create method to use context
function useEmployeeCommuteContext() {
  const context = useContext(EmployeeCommuteContext);
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useEmployeeCommuteContext must be used within a EmployeeCommuteContextProvider'
    );
  }
  const [employeeCommuteDetails, setEmployeeCommuteDetails] = context;

  // Context Methods //

  const setActiveCommute = (activeCommute) => {
    setEmployeeCommuteDetails((employeeCommuteDetails) => ({
      ...employeeCommuteDetails,
      activeCommute
    }));
  };

  const setEmployeeCommuteActiveStep = (activeStep) => {
    setEmployeeCommuteDetails((employeeCommuteDetails) => ({
      ...employeeCommuteDetails,
      activeStep
    }));
  };

  const setCommutes = (commutes) => {
    setEmployeeCommuteDetails((employeeCommuteDetails) => ({
      ...employeeCommuteDetails,
      commutes
    }));
  };
  const setProfile = (profile) => {
    setEmployeeCommuteDetails((employeeCommuteDetails) => ({
      ...employeeCommuteDetails,
      profile
    }));
  };
  const setIsDetailsLoading = (isDetailsLoading) => {
    setEmployeeCommuteDetails((employeeCommuteDetails) => ({
      ...employeeCommuteDetails,
      isDetailsLoading
    }));
  };

  // Return state and Context Methods
  // Note: DO NOT return "setMe".State updates should be managed through context methods
  return {
    employeeCommuteDetails,
    setEmployeeCommuteDetails,
    setEmployeeCommuteActiveStep,
    setCommutes,
    setActiveCommute,
    setIsDetailsLoading,
    setProfile
  };
}

// Create the context provider
function EmployeeCommuteContextProvider(props) {
  const [employeeCommuteDetails, setEmployeeCommuteDetails] =
    useState(employeeCommuteState);
  const value = useMemo(
    () => [employeeCommuteDetails, setEmployeeCommuteDetails],
    [employeeCommuteDetails]
  );
  return <EmployeeCommuteContext.Provider value={value} {...props} />;
}

export { EmployeeCommuteContextProvider, useEmployeeCommuteContext };
