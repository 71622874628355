import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { MeService } from '../../../services';
import pawContract from '../../../stories/assets/svgs/PawContract.svg';
import { Anchor, Img, List, Paragraph, Title } from '../../../stories/atoms';
import ModalFooter from '../../../stories/atoms/ModalFooter';
import './terms-summary.scss';

export function TermsSummary() {
  const navigate = useNavigate();
  const { me, getMyDetails } = useMeContext();
  const { prevRoute } = me;
  const handleAcceptTerms = async () => {
    const response = await MeService.acceptTerms(prevRoute);
    if (!!response) {
      await getMyDetails();
      navigate(AppRoutes.HOME);
    }
  };
  const { execute: acceptTerms, isLoading } = useAsync({
    asyncFunction: handleAcceptTerms,
    immediate: false
  });
  return (
    <Row className="terms-summary">
      <Col span={24}>
        <Row gutter={[0, 40]}>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Img
                  width={120}
                  src={pawContract}
                  alt="An illustration of a contract signed with a paw-print"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col flex="636px">
                <Title align="center" size="lg" bottomSpacing={8}>
                  We’ve updated our terms and conditions
                </Title>
                <Paragraph align="center" bottomSpacing={0}>
                  You will need to review accept the new terms and conditions
                  before continuing to use Carbonhound.
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col flex="636px" className="terms-summary__change-list">
                <Title size="rg" bottomSpacing={16}>
                  Change summary
                </Title>
                <List type="ul">
                  <li>Section 6: Fees and Payment</li>

                  <List type="ul">
                    <li>
                      Carbonhound will now be charging fees for the use of our
                      platform after your free trial has expired.
                    </li>
                    <li>All fees will be in USD</li>
                    <li>Partnership with Stripe outline</li>
                  </List>
                  <li>Section 10: Terms & Termination</li>
                  <List type="ul">
                    <li>
                      Termination of your subscription to Carbonhound will be
                      applied within the next billing term.
                    </li>
                  </List>
                </List>
                <Anchor
                  fontWeight={600}
                  href="https://carbonhound.com/terms-and-conditions/"
                >
                  Read Full Terms & Conditions
                </Anchor>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <ModalFooter
          showHelpLink={false}
          showCancelButton={false}
          saveButtonCopy="Accept Terms"
          saveButtonProps={{
            onClick: acceptTerms,
            isLoading
          }}
        />
      </Col>
    </Row>
  );
}
