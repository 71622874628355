import { Col, Row } from 'antd';
import classNames from 'classnames';
import Title from '../Title';
import './PricingCard.scss';
import { PricingCardListItem } from './PricingCardListItem';

function PricingCard({ pricingTier, className }) {
  const { title, pricePoint, features, badges } = pricingTier ?? {};
  return (
    <Row
      className={classNames('ch-pricing-card', {
        [className]: !!className
      })}
      gutter={[0, 40]}
    >
      <Col span={24} className="ch-pricing-card__header">
        <Row align="middle">
          <Col flex="1 1 auto">
            <Title size="lg" bottomSpacing={0}>
              {title}
            </Title>
          </Col>
          <Col className="ch-pricing-card__header__price">{pricePoint}</Col>
        </Row>
      </Col>
      <Col className="ch-pricing-card__features" span={24}>
        {features.map((feature, i) => (
          <PricingCardListItem item={feature} key={i} />
        ))}
      </Col>
      <Col className="ch-pricing-card__badges" span={24}>
        <Title bottomSpacing={24} size="lg" align="center">
          Badges earned
        </Title>
        {badges.map((badge, i) => (
          <PricingCardListItem item={badge} key={i} />
        ))}
      </Col>
    </Row>
  );
}

export default PricingCard;
