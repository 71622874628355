import { Col, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import {
  EnergyTypeConstants,
  EnergyUnit,
  YesOrNoOptions
} from '../../constants';
import { useMetaContext } from '../../context';
import { getDropdownOptions } from '../../helpers';
import { EnergyService } from '../../services';
import {
  Button,
  DatePicker,
  Input,
  InputNumberField,
  Radio,
  Select
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { INPUT_TYPES } from '../../stories/atoms/Input/Input.types';
import { CompletionTimeline } from '../../stories/molecules';
import { Card } from '../index';
import './energyItemForm.scss';
import { energyItemFormValidation } from './energyItemFormValidation';

function EnergyItemForm({
  onSuccess,
  energyItem,
  energyTypeId,
  onCancel,
  timeline,
  energyDetailsId,
  integrationName
}) {
  const { meta } = useMetaContext();

  const [formValues, setFormValues] = useState({});

  const [formLoading, setFormLoading] = useState(false);

  const [energyUnitOptions, setEnergyUnitOptions] = useState([]);

  const [_energyTypeOptions, setEnergyTypeOptions] = useState([]);

  const { setEnergyUnits, setEnergyTypes } = useMetaContext();

  const handleLoadEnergyTypes = useCallback(async () => {
    setEnergyTypes();
    setEnergyTypeOptions(
      getDropdownOptions(meta.energyTypes, 'friendlyName', 'id')
    );
  }, [meta.energyTypes]);

  const handleLoadEnergyUnits = useCallback(async () => {
    setEnergyUnits();
    setEnergyUnitOptions(
      getDropdownOptions(meta.energyUnits, 'friendlyName', 'name')
    );
  }, [meta.energyUnits]);

  const handleSubmit = (energyItemFormValues) => {
    setFormLoading(true);
    if (energyItemFormValues.id) {
      EnergyService.updateEnergyItem(
        { ...energyItemFormValues, integrationName },
        () => {
          onSuccess?.();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else if (energyDetailsId) {
      EnergyService.createEnergyItem(
        energyDetailsId,
        { ...energyItemFormValues, integrationName },
        () => {
          onSuccess?.();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleLoadEnergyTypes();
  }, [handleLoadEnergyTypes]);

  useEffect(() => {
    handleLoadEnergyUnits();
  }, [handleLoadEnergyUnits]);

  useEffect(() => {
    let formValues = { energyTypeId };
    if (
      meta.energyTypes.length > 0 &&
      meta.energyUnits.length > 0 &&
      energyTypeId
    ) {
      const energyTypeName = meta.energyTypes.find(
        (energyType) => energyType?.id === energyTypeId
      )?.name;
      if (
        energyTypeName === EnergyTypeConstants.ELECTRICITY ||
        energyTypeName === EnergyTypeConstants.NATURAL_GAS
      ) {
        const energyUnitName =
          energyTypeName === EnergyTypeConstants.ELECTRICITY
            ? EnergyUnit.KWH
            : EnergyUnit.M3;
        formValues.conversionUnitName = meta.energyUnits.find(
          (energyUnit) => energyUnit.unit.indexOf(energyUnitName) > -1
        )?.name;
      }
    }
    if (energyItem) {
      const energyTypeIndex = meta.energyTypes.findIndex(
        (type) =>
          energyItem?.energyType?.id === type.id || energyTypeId === type.id
      );
      formValues = {
        ...formValues,
        ...energyItem,
        energyTypeId:
          energyTypeIndex === -1
            ? 'Unknown'
            : energyTypeId ?? energyItem?.energyType?.id,
        conversionUnitName: energyItem?.activityUnit?.name,
        billingStart: energyItem?.billingStart
          ? moment.utc(energyItem?.billingStart).format('YYYY-MM-DD')
          : undefined,
        billingEnd: energyItem?.billingEnd
          ? moment.utc(energyItem?.billingEnd).format('YYYY-MM-DD')
          : undefined,
        isEstimate: energyItem.isEstimate,
        activity: energyItem.activity
      };
    }
    setFormValues({
      ...formValues
    });
  }, [
    meta.energyTypes.length,
    meta.energyUnits.length,
    energyTypeId,
    energyItem
  ]);
  return (
    <div className="energy-item-form">
      <h4 className="text-bd">Enter Usage</h4>
      <Card>
        <CompletionTimeline timeline={timeline} />
      </Card>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={energyItemFormValidation}
      >
        {({ values, _errors, isValid, _dirty, setFieldValue }) => (
          <Form>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <InputNumberField
                  title="Usage"
                  name="activity"
                  placeholder="Enter value"
                />
              </Col>
              <Col span={8}>
                <Select
                  title="Units"
                  name="conversionUnitName"
                  value={values?.conversionUnitName}
                  placeholder="Select Energy Unit"
                  setFieldValue={setFieldValue}
                  options={energyUnitOptions}
                />
              </Col>
              <Col span={8}>
                <DatePicker
                  fromName="billingStart"
                  toName="billingEnd"
                  isRange
                  name="date"
                  defaultValue={[values?.billingStart, values?.billingEnd]}
                  title="Billing period"
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col span={24}>
                <Radio
                  title="Is this data estimated?"
                  raised
                  value={values?.isEstimate}
                  name="isEstimate"
                  options={YesOrNoOptions}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col span={24}>
                <Input
                  type={INPUT_TYPES.TEXT_AREA}
                  title="Notes (Optional)"
                  name="notes"
                  defaultValue={values?.notes}
                  placeholder="Enter"
                />
              </Col>
              <Col span={24} className="energy-item-form__submit text-right">
                <Space>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    htmlType="button"
                    className="mr-3"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    disabled={formLoading || !isValid}
                    loading={formLoading}
                  >
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EnergyItemForm;
