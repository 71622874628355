import { Col, Row } from 'antd';
import { useCallback } from 'react';
import { useMeContext } from '../../context';
import { useAsync } from '../../hooks';
import SubscriptionService from '../../services/SubscriptionService';
import tree from '../../stories/assets/svgs/Tree.svg';
import {
  Anchor,
  Button,
  Img,
  Page,
  Paragraph,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { SubscriptionCard } from '../../stories/molecules';
import { SubscriptionDetails } from './Subscription.constants';

import './Subscription.scss';

export function Subscription() {
  const { me } = useMeContext();
  const { company } = me;
  const meetingLink =
    'https://meetings.hubspot.com/sanders5/subscription-page-upgrade-meeting';

  const isActiveSubscription = company?.subscriptions?.find(
    (sub) => sub.type === 'APP_ACCESS' && sub.status !== 'EXPIRED'
  );

  const loadSubscriptionPriceList = useCallback(async () => {
    try {
      if (!company?.slug) return;
      const response = await SubscriptionService.listStripePrices({
        companySlug: company.slug
      });
      return response.list ?? [];
    } catch (e) {
      console.error(e);
    }
  }, [company.slug]);

  const { Loader, value: subscriptionPriceList } = useAsync({
    asyncFunction: loadSubscriptionPriceList,
    defaultValue: []
  });

  return (
    <Page>
      <Row gutter={[0, 84]}>
        <Col span={24}>
          <Row justify="center">
            <Col flex="680px">
              <Title bottomSpacing={24} align="center" size="xl">
                {isActiveSubscription
                  ? 'Upgrade your trial now!'
                  : `Your trial has timed out, please upgrade to continue using
                      Carbonhound`}
              </Title>
              <Paragraph size="lg" bottomSpacing={24} align="center">
                We love the progress you’ve made on your sustainability efforts
                so far, let’s keep the momentum up!
              </Paragraph>
              <Anchor
                showLinkIcon={false}
                href="https://meetings.hubspot.com/sanders5/subscription-page-upgrade-meeting"
                className="display-flex justify-center"
              >
                <Button type={BUTTON_TYPES.SECONDARY} icon="ExternalLink">
                  Chat with us
                </Button>
              </Anchor>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={40} justify="center">
            <Loader>
              {subscriptionPriceList.map((subscription) => (
                <Col className="display-flex">
                  <SubscriptionCard
                    subscription={subscription}
                    subscriptionDetail={SubscriptionDetails[subscription.icon]}
                  />
                </Col>
              ))}
            </Loader>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" align="middle" gutter={[56, 16]}>
            <Col>
              <Img src={tree} alt="An illustration of a tree" />
            </Col>
            <Col flex="616px">
              <Title bottomSpacing={8}>More than 500 employees?</Title>
              <Paragraph bottomSpacing={0}>
                Let’s chat! If your team is over 500 employees we can work
                closely together with you to create a custom plan that makes the
                most sense for your climate goals.{' '}
              </Paragraph>
            </Col>
            <Col>
              <a target="_blank" rel="noopener noreferrer" href={meetingLink}>
                <Button type={BUTTON_TYPES.SECONDARY}>Get in touch</Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="display-flex justify-center">
          <Paragraph>
            Need help selecting a plan or want a quote in another currency?{' '}
            <Anchor href={meetingLink}>Contact our sales team</Anchor>
          </Paragraph>
        </Col>
      </Row>
    </Page>
  );
}
