import classNames from 'classnames';
import './StatisticGroup.scss';

function StatisticGroup({ children, className }) {
  return (
    <div
      className={classNames('ch-statistic-group', {
        [className]: !!className
      })}
    >
      {children}
    </div>
  );
}
export default StatisticGroup;
