import bud from '../../stories/assets/svgs/bud.svg';
import flower from '../../stories/assets/svgs/flower.svg';
import plant from '../../stories/assets/svgs/plant.svg';
import seedling from '../../stories/assets/svgs/seedling.svg';

export const SubscriptionLevels = {
  SMALL_BUSINESS: 'SMALL_BUSINESS',
  MEDIUM_BUSINESS: 'MEDIUM_BUSINESS',
  LARGE_BUSINESS: 'LARGE_BUSINESS',
  CUSTOM_BUSINESS: 'CUSTOM_BUSINESS'
};

export const SubscriptionDetails = {
  [SubscriptionLevels.SMALL_BUSINESS]: {
    title: 'Small Business',
    imageUrl: seedling
  },
  [SubscriptionLevels.MEDIUM_BUSINESS]: {
    title: 'Medium Business',
    imageUrl: bud
  },
  [SubscriptionLevels.LARGE_BUSINESS]: {
    title: 'Large Business',
    imageUrl: plant
  },
  [SubscriptionLevels.CUSTOM_BUSINESS]: {
    title: 'Enterprise Business',
    imageUrl: flower
  }
};
