function ArrowNarrowLeft() {
  return (
    <path
      d="M1.5 5.3985H19.5M5.5 9.3985L1.5 5.3985L5.5 9.3985ZM1.5 5.3985L5.5 1.3985L1.5 5.3985Z"
      stroke="#5C29C7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowNarrowLeft;
