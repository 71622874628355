import { REPORTING_PERIOD_VALUES } from '../reportingPeriodValues';

export const REPORTING_PERIOD_OPTIONS = [
  {
    label: 'Custom',
    value: REPORTING_PERIOD_VALUES.CUSTOM
  },
  {
    label: 'Current calendar year to date',
    value: REPORTING_PERIOD_VALUES.CURRENT_CALENDAR_YEAR
  },
  {
    label: 'Current fiscal year to date',
    value: REPORTING_PERIOD_VALUES.CURRENT_FISCAL_YEAR
  },
  {
    label: 'Current quarter to date',
    value: REPORTING_PERIOD_VALUES.CURRENT_QUARTER
  },
  {
    label: 'Previous calendar year',
    value: REPORTING_PERIOD_VALUES.PREVIOUS_CALENDAR_YEAR
  },
  {
    label: 'Previous fiscal quarter',
    value: REPORTING_PERIOD_VALUES.PREVIOUS_QUARTER
  },
  {
    label: 'Previous fiscal year',
    value: REPORTING_PERIOD_VALUES.PREVIOUS_FISCAL_YEAR
  },
  {
    label: 'All time',
    value: REPORTING_PERIOD_VALUES.ALL
  }
];
