import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { ButtonIcon, Paragraph } from '../../../stories/atoms';
import './InitiativeResource.scss';

export function InitiativeResource({
  resource,
  theme = 'dark',
  companyInitiative
}) {
  const { me } = useMeContext();
  const { title, url } = resource;
  const handleOnClickResource = () => {
    analytics.track('Item Clicked', me, {
      level1: 'Initiatives',
      level2: 'Overview',
      companyInitiative
    });
  };
  return (
    <Row
      justify="end"
      className={classNames('initiative-resource', {
        [theme]: !!theme
      })}
    >
      <Col flex={1}>
        <Paragraph bottomSpacing={0}>{title}</Paragraph>
      </Col>
      <Col>
        <a
          href={`${url}`}
          target="_blank"
          download
          onClick={handleOnClickResource}
          rel="noopener noreferrer"
          className="prepare-upload__download-link"
        >
          <ButtonIcon
            backgroundColour="transparent"
            name="Download"
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
          />
        </a>
      </Col>
    </Row>
  );
}
