import { Col, Row, Space } from 'antd';
import classNames from 'classnames';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { BUTTON_TYPES } from '../Button/Button.types';
import Icon from '../Icon';
import { Button, Paragraph } from '../index';
import './ModalFooter.scss';

function ModalFooter({
  className,
  handleOnSave,
  showCancelButton = true,
  cancelButtonProps,
  saveButtonCopy = 'Save',
  cancelButtonCopy = 'Cancel',
  saveButtonProps,
  helpLink = '',
  showHelpLink = true,
  copy
}) {
  return (
    <div
      className={classNames('ch-modal-footer', {
        [className]: !!className
      })}
    >
      <Row>
        <Col className="display-flex text-left align-end" span={12}>
          {copy && (
            <Paragraph weight="400!important" bottomSpacing={0}>
              {copy}
            </Paragraph>
          )}
          {showHelpLink && (
            <a
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
              href={helpLink}
            >
              <Icon
                className="mr-2"
                size={24}
                name="InformationCircle"
                color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
              />
              Get help
            </a>
          )}
        </Col>

        <Col className="text-right" span={12}>
          <Space size={16}>
            {showCancelButton && (
              <Button
                className="ch-modal__footer__save"
                type={BUTTON_TYPES.SECONDARY}
                {...cancelButtonProps}
              >
                {cancelButtonCopy}
              </Button>
            )}
            <Button
              className="ch-modal__footer__save"
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleOnSave}
              {...saveButtonProps}
            >
              {saveButtonCopy}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default ModalFooter;
