export const SUSTAINABILITY_GOALS = {
  REDUCE_FOOTPRINT: 'REDUCE_FOOTPRINT',
  SUSTAINABILITY_CLAIMS: 'SUSTAINABILITY_CLAIMS',
  SUSTAINABILITY_CULTURE: 'SUSTAINABILITY_CULTURE',
  ANOTHER_COMPANY: 'ANOTHER_COMPANY',
  INVESTOR_REPORTS: 'INVESTOR_REPORTS',
  OTHER: 'OTHER'
};

export const REPORTING_STANDARDS = {
  B_CORP: 'B_CORP',
  CDP: 'CDP',
  GRI: 'GRI',
  ISSB_IFRS: 'ISSB_IFRS',
  ISO_14064_1: 'ISO_14064_1',
  MSCI: 'MSCI',
  TCFD: 'TCFD',
  GHG_UNEP: 'GHG_UNEP',
  UNSURE: 'UNSURE',
  OTHER: 'OTHER'
};

export const CALCULATED_CABRON_FOOTPRINT = {
  NEVER: 'NEVER',
  RARELY: 'RARELY',
  OFTEN: 'OFTEN'
};
