import { Col, Drawer as AntDrawer, Layout, Row, Space } from 'antd';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { Button, ButtonIcon, ConditionalWrapper, Title } from '../index';
import './Drawer.scss';

const { Content, Header, Footer } = Layout;
export function Drawer({
  title,
  children,
  className,
  buttons = [],
  onCancel,
  formikContext,
  width = '40%',
  // I am creating this prop to make things work retro-actively with the old iteration of the Drawer.
  showHeader = true,
  ...props
}) {
  return (
    <AntDrawer
      className={classNames('ch-drawer', {
        [className]: !!className
      })}
      width={width}
      {...props}
    >
      <ConditionalWrapper
        condition={!!formikContext}
        wrapper={(child) => (
          <Formik {...formikContext}>
            <Form>{child}</Form>
          </Formik>
        )}
      >
        <Layout>
          {showHeader && (
            <Header className="ch-drawer__header">
              <Row
                wrap={false}
                align="middle"
                justify="space-between"
                gutter={8}
              >
                <Col>
                  <Title bottomSpacing={0} size="md">
                    {title}
                  </Title>
                </Col>
                <Col>
                  <ButtonIcon name="Times" onClick={onCancel} />
                </Col>
              </Row>
            </Header>
          )}
          <Content className="ch-drawer__content">{children}</Content>
          {!!buttons.length && (
            <Footer style={{ left: `${width}%` }} className="ch-drawer__footer">
              <Space size={16}>
                {buttons.map((button, i) => (
                  <Button key={i} {...button}>
                    {button.children}
                  </Button>
                ))}
              </Space>
            </Footer>
          )}
        </Layout>
      </ConditionalWrapper>
    </AntDrawer>
  );
}
