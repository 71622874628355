import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card } from '../../../../components';
import { Footer } from '../../../../stories/atoms/Layout';
import { COLOURS_INDIGO } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import { formatDecimal, formatEmissions } from '../../../../helpers';
import { formatSingleEmission } from '../../../../helpers/generators';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { CommuteService } from '../../../../services';
import gusParty from '../../../../stories/assets/svgs/Gus/gusParty.svg';
import shoe from '../../../../stories/assets/svgs/shoe.svg';
import { Button, Icon, Img, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import './onboardingEmployeeImpact.scss';

export function OnboardingEmployeeImpact() {
  const [carbonTotal, setCarbonTotal] = useState(0);
  const [shoeCount, setShoeCount] = useState(0);
  const { me } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};
  const navigate = useNavigate();

  const fetchCommutes = useCallback(async () => {
    const commuteList = await CommuteService.fetchEmployeeCommutes({
      companySlug: me?.company?.slug,
      loggedInUserId
    });
    return commuteList;
  }, [loggedInUserId, me.company?.slug]);
  const { value: commutes, Loader: CommuteTotalLoader } = useAsync({
    asyncFunction: fetchCommutes,
    defaultValue: []
  });

  useEffect(() => {
    const formattedEmissions = formatEmissions(commutes, 'emissionSummary');

    const carbonTotalFormatted = formatDecimal(
      formatSingleEmission(
        formattedEmissions.reduce((a, b) => a + b.emissionSummary, 0)
      )
    );

    const noOfShoes = formatDecimal(
      commutes
        .map((commute) =>
          commute?.emissionSummary ? Number(commute?.emissionSummary) : 0
        )
        .reduce((a, b) => a + b, 0) / 11.5
    );
    setShoeCount(noOfShoes);
    setCarbonTotal(carbonTotalFormatted);
  }, [carbonTotal, commutes]);

  const navigateDashboard = () => navigate(AppRoutes.DASHBOARD);
  return (
    <>
      <Row className="your-impact__container">
        <Col span={24} className="your-impact-header">
          <Title bottomSpacing={0}>Your Impact</Title>
        </Col>
        <Col span={24} className="impact-card__container">
          <Card className="impact-card">
            <CommuteTotalLoader>
              <div className="impact-text__wrapper">
                <Paragraph size="sm">
                  Your individual annual impact is
                </Paragraph>
                <Title size="xl">{`${carbonTotal} tons CO2e a year`}</Title>
              </div>
              {carbonTotal > 0 ? (
                <div className="impact-equivalent__section">
                  <Img src={shoe} />
                  <Paragraph>
                    {`That is equivalent to ${shoeCount} pairs of shoes being produced`}
                  </Paragraph>
                </div>
              ) : (
                <div className="mt-8">
                  <Img src={gusParty} />
                </div>
              )}
            </CommuteTotalLoader>
          </Card>
        </Col>
        <Col span={24} className="mt-8">
          <Title fontSize={20}>How to reduce your impact as an employee</Title>
          <Paragraph>
            While the responsibility lies on corporations, individuals still
            have an opportunity to make an impact
          </Paragraph>
        </Col>
        <Col span={24} className="your-impact-body__container">
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Card>
                <div className="your-impact__logo your-impact__logo--indigo your-impact__logo--size-40 mb-4">
                  <Icon name="Bike" color={COLOURS_INDIGO.INDIGO_500} />
                </div>
                <Title>How you get around</Title>
                <Paragraph>
                  Choosing cycling, walking, or public transit where you can
                  will greatly reduce your impact. In North America, personal
                  vehicles are some of the largest emission sources for
                  individuals.
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <div className="your-impact__logo your-impact__logo--indigo your-impact__logo--size-40 mb-4">
                  <Icon name="Eat" color={COLOURS_INDIGO.INDIGO_500} />
                </div>
                <Title>What you eat</Title>
                <Paragraph>
                  Our food choices are a significant contribution to our
                  personal emissions. Choosing to eat local produce when
                  possible and opting for plant based options can significantly
                  reduce your own emissions.
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <div className="your-impact__logo your-impact__logo--indigo your-impact__logo--size-40 mb-4">
                  <Icon name="Desktop" color={COLOURS_INDIGO.INDIGO_500} />
                </div>
                <Title>Your Home Office</Title>
                <Paragraph>
                  Getting a home energy audit can help you understand not only
                  where you can reduce your home emissions but also where you
                  can save on energy costs!
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <div className="your-impact__logo your-impact__logo--indigo your-impact__logo--size-40 mb-4">
                  <Icon name="ShoppingCart" color={COLOURS_INDIGO.INDIGO_500} />
                </div>
                <Title>What you buy</Title>
                <Paragraph>
                  The goods you purchase all result in emissions, choosing to
                  reduce your spend, or renting and repurposing old goods is one
                  of the best things you can do for your wallet and your
                  emissions!
                </Paragraph>
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} className="mt-6">
              <Card>
                <div className="your-impact__logo your-impact__logo--indigo your-impact__logo--size-40 mb-4">
                  <Icon name="Briefcase" color={COLOURS_INDIGO.INDIGO_500} />
                </div>
                <Title>At Work</Title>
                <Paragraph>
                  There are several ways you as an employee can advocate for
                  sustainable action from your employer. Some examples include:
                </Paragraph>
                <ul>
                  <li>Advocate for transit & cycling</li>
                  <li>Work from home periods to reduce commute emissions</li>
                  <li>Implement composting & compostable supplies</li>
                </ul>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  onClick={navigateDashboard}
                >
                  See how my company is emitting today
                </Button>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer className="display-flex justify-end">
        <Button onClick={navigateDashboard}>View Dashboard</Button>
      </Footer>
    </>
  );
}
