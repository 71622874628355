import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { AuthService } from '../../../services';
import ArrowNarrowLeft from '../../../stories/assets/icons/ArrowNarrowLeft';
import {
  Button,
  Input,
  Label,
  Link,
  Logo,
  Title
} from '../../../stories/atoms';
import CheckEmail from '../../../stories/molecules/CheckEmail';

import './forgotPassword.scss';
import { forgotPasswordValidation } from './forgotPasswordValidation';

function ForgotPassword() {
  const { setEmail, me, isGroupSettingsMember } = useMeContext();
  const { groupSettings } = me;

  const navigate = useNavigate();

  const [checkEmail, setCheckEmail] = useState(false);

  const [formValues, setFormValues] = useState({
    email: ''
  });

  const handlePrevious = () => {
    setCheckEmail(false);
    navigate(AppRoutes.LOGIN);
  };

  const handleSubmit = async (values) => {
    setFormValues(values);
    try {
      const resetPasswordResponse = await AuthService.sendResetPasswordEmail({
        groupSlug: groupSettings?.slug,
        ...values
      });
      if (
        typeof resetPasswordResponse !== undefined ||
        resetPasswordResponse.length > 0
      ) {
        setEmail(values.email);
        setCheckEmail(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  return checkEmail ? (
    <CheckEmail
      email={formValues.email}
      description="Click the link in the email and follow the steps to reset your password."
      onPrevious={handlePrevious}
    />
  ) : (
    <div
      className={classNames('forgot__password__page-container', {
        'forgot__password__page-container--group-settings-member':
          isGroupSettingsMember
      })}
    >
      <Row className="forgot__password__form-wrapper">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="display-flex justify-center">
            <Card className="forgot__password__form-card">
              <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={execute}
                validationSchema={forgotPasswordValidation}
              >
                {({ _values, _errors, _isValid, _dirty, _setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col span={24}>
                        <Logo className="forgot__password__logo mb-8" />
                        <Title
                          fontSize={24}
                          fontWeight={600}
                          align="center"
                          color={COLOURS_GRAY.GRAY_800}
                          className="mb-2"
                        >
                          Forgot your password?
                        </Title>
                        <Title
                          fontSize={16}
                          fontWeight={400}
                          align="center"
                          color={COLOURS_GRAY.GRAY_800}
                        >
                          No worries, we'll send you reset instructions
                        </Title>
                        <div className="mb-5">
                          <Label>Email</Label>
                          <Input type="text" name="email" placeholder="Email" />
                        </div>
                        <div className="mt-5">
                          <Button
                            fullWidth
                            htmlType="submit"
                            loading={isLoading}
                          >
                            Reset Password
                          </Button>
                        </div>
                        <div className="justify-center display-flex align-center mt-7 pr-2">
                          <Link to={AppRoutes.LOGIN} fontWeight={600}>
                            <svg
                              className="mr-2"
                              width="21"
                              height="11"
                              viewBox="0 0 21 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <ArrowNarrowLeft />
                            </svg>
                            Back to login
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;
