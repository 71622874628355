import { Col, Row } from 'antd';
import React from 'react';
import { Logo, Paragraph, Title } from '../../../../stories/atoms';
import './inviteEmailPreviewMessage.scss';

export function InviteEmailPreviewMessage() {
  return (
    <Row className="invite-email-preview-message" gutter={[0, 40]}>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Logo width={155} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Title bottomSpacing={36} size="md" align="center">
          You have been invited!
        </Title>
        <Paragraph bottomSpacing={36} align="center">
          Your admin at Company Name is trying to get a better understanding of
          the carbon footprint of your organization. For many companies,
          Employee Details are a significant source of emissions. So in order to
          get a more complete picture; they need some information about your
          commute.
        </Paragraph>
        <Paragraph bottomSpacing={36} align="center">
          Please click the button below to set your password or log in and enter
          your commute details.
        </Paragraph>
      </Col>
      <Col span={24}>
        <Paragraph
          className="invite-email-preview-message__paragraph--gradient-text"
          align="center"
          bottomSpacing={0}
        >
          Welcome to the pack!
        </Paragraph>
      </Col>
    </Row>
  );
}
