import { Col, Modal, Row, Space } from 'antd';
import { BulkUploadType, UploadStatus } from '../../../constants';
import { useBulkUploadContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import {
  EmployeeService,
  EnergyService,
  TravelExpenseService
} from '../../../services';
import EmissionSourceService from '../../../services/emissionSourceService';
import { Anchor, Button, Icon } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './Footer.scss';
import { useDataImportContext } from '../../../context/DataImportContext';

export function Footer({
  isLoading = false,
  onNextFn,
  nextCopy,
  isStepInvalid,
  canGoBack = true,
  canGoForward = true,
  importTypeNotification,
  analyticsStepContext
}) {
  const { bulkUploadState, nextStep, prevStep } = useBulkUploadContext();
  const { handleDownloadFile } = useDataImportContext();
  const { activeStep, notification, onSuccess, companySlug } = bulkUploadState;

  const { me } = useMeContext();
  const showDeleteUploadButton =
    !!notification.id &&
    notification.status !== UploadStatus.PROCESSING &&
    !(
      notification.status === UploadStatus.COMPLETE &&
      notification.details?.uploadType === BulkUploadType.USER
    ) &&
    !(
      [UploadStatus.COMPLETE, UploadStatus.READY_FOR_REVIEW].includes(
        notification.status
      ) && notification.details?.uploadType === BulkUploadType.PERSONAL_VEHICLE
    ); // Internal only - not currently supported

  const handleDeleteNotification = async () => {
    Modal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Delete',
      cancelText: 'Cancel',
      content: (
        <div className="text-left">
          <h5 className="text-bd">
            Are you sure you’d like to delete this data upload?
          </h5>
          <p>All data will be permanently deleted. </p>
        </div>
      ),
      onOk: async () => {
        if (notification.details?.uploadType === BulkUploadType.TRAVEL) {
          await TravelExpenseService.hardDeleteTravelItems(companySlug, {
            uploadProcessId: notification.id
          });
        } else if (notification.details?.uploadType === BulkUploadType.ENERGY) {
          await EnergyService.hardDeleteEnergyItems(
            notification.details?.energyDetailsId,
            {
              uploadProcessId: notification.id
            }
          );
        } else if (notification.details?.uploadType === BulkUploadType.CUSTOM) {
          await EmissionSourceService.hardDeleteEmissionSourceItem(
            notification.details?.emissionSourceDetailsId,
            {
              uploadProcessId: notification.id
            }
          );
        } else if (notification.details?.uploadType === BulkUploadType.USER) {
          await EmployeeService.hardDeletePendingEmployees({
            companySlug,
            uploadProcessId: notification.id
          });
        }
        onSuccess?.();
      }
    });
  };

  const handleOnNext = () => {
    analytics.track('Step Completed', me, {
      level1: 'Bulk Uploads',
      level2: analyticsStepContext,
      notification: !!importTypeNotification
        ? importTypeNotification
        : bulkUploadState.notification
    });
    if (!!onNextFn) {
      onNextFn();
    } else {
      nextStep();
    }
  };
  const handleHelpClicked = () => {
    analytics.track('Help Clicked', me, {
      level1: 'Bulk Uploads',
      level2: analyticsStepContext,
      notification
    });
  };
  const { execute: deleteNotification } = useAsync({
    asyncFunction: handleDeleteNotification,
    immediate: false
  });
  return (
    <Row className="bulk-upload-form-footer mt-5 shadow-medium" align="center">
      <Col span={12} className="text-left text-bd">
        <Anchor
          href="//carbonhound.notion.site/Troubleshooting-Data-Imports-daec5285adcd40f6944874c528cf8088?pvs=4"
          onClick={handleHelpClicked}
          flex
        >
          <Icon className="mr-1" name="InformationCircle" />
          Data Import Guide
        </Anchor>
      </Col>
      <Col span={12} className="text-right">
        <div className="steps-action">
          <Space className="justify-end" size={12} wrap>
            {!!bulkUploadState.notification.id &&
              bulkUploadState.notification.details?.originalFileName && (
                <Button
                  name="Download"
                  onClick={() =>
                    handleDownloadFile(bulkUploadState.notification)
                  }
                  type={BUTTON_TYPES.TEXT}
                  icon="Download"
                >
                  Download File
                </Button>
              )}
            {showDeleteUploadButton && (
              <Button
                danger
                type={BUTTON_TYPES.TEXT}
                onClick={() => {
                  analytics.track('Delete Item Clicked', me, {
                    level1: 'Bulk Uploads',
                    level2: analyticsStepContext,
                    notification: bulkUploadState.notification
                  });
                  deleteNotification();
                }}
              >
                Delete Upload
              </Button>
            )}
            {activeStep > 0 && canGoBack && (
              <Button type={BUTTON_TYPES.SECONDARY} onClick={prevStep}>
                Back
              </Button>
            )}
            {canGoForward && (
              <Button
                disabled={isStepInvalid || isLoading}
                onClick={handleOnNext}
              >
                {nextCopy || 'Next'}
              </Button>
            )}
          </Space>
        </div>
      </Col>
    </Row>
  );
}
