import { Col, Row } from 'antd';
import { useCallback } from 'react';
import { Card } from '../../components';
import { COLOURS_BLUE } from '../../constants/colours';
import {
  AGGREGATE_TYPE_OPTIONS,
  TIME_UNIT_OPTIONS
} from '../../constants/options';
import { useMeContext } from '../../context';
import { useDataContext } from '../../context/DataContext';
import {
  analytics,
  capitalizeText,
  getYearDropdownOptions
} from '../../helpers';
import { useAsync } from '../../hooks';
import { AppRoutes } from '../../routes';
import { TargetService } from '../../services';
import { Icon, Link, Paragraph, Select, Title } from '../../stories/atoms';
import { DataChart } from '../Data';
import './CarbonExplorer.scss';

function LearnMore() {
  const { me } = useMeContext();
  const handleAnalytics = () => {
    analytics.track('Help Clicked', me, {
      level1: 'Dashboard',
      level2: 'Data Explorer'
    });
  };
  return (
    <Link
      flex
      to="//carbonhound.notion.site/What-are-all-of-the-GHG-Categories-8582889360cb411e9631526a80eab104"
      target="_blank"
      fontWeight={500}
      onClick={handleAnalytics}
    >
      Learn More
      <Icon name="ChevronRight" />
    </Link>
  );
}

function DataExplorerLink() {
  const { me } = useMeContext();
  const handleAnalytics = () => {
    analytics.track('Link Clicked', me, {
      level1: 'Dashboard',
      level2: 'Data Explorer'
    });
  };
  return (
    <Link
      flex
      to={AppRoutes.DATA_EXPLORER}
      fontWeight={500}
      onClick={handleAnalytics}
    >
      Data Explorer
      <Icon name="ChevronRight" />
    </Link>
  );
}

export function CarbonExplorer() {
  const { me } = useMeContext();
  const {
    data,
    handleSetChartType,
    handleSetChartYear,
    handleSetVisibleTarget,
    handleSetTimeUnit,
    handleFetchDataExplorerData
  } = useDataContext();
  const { filters } = data;
  const {
    chartDateYear,
    aggregateType,
    selectedDateRange,
    visibleTarget,
    timeUnit
  } = filters;
  const { value: chartData, isLoading } = useAsync({
    asyncFunction: handleFetchDataExplorerData,
    defaultValue: {
      datasets: [],
      labels: []
    }
  });

  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];

  const handleTargetSummaryFilter = useCallback(async () => {
    if (
      me.company?.slug &&
      selectedDateRange.rangeStart &&
      selectedDateRange.rangeEnd
    ) {
      const { data: targetSummaries } =
        await TargetService.fetchTargetDetailsSummary({
          companySlug: me.company?.slug,
          rangeStart: selectedDateRange.rangeStart,
          rangeEnd: selectedDateRange.rangeEnd,
          timeUnit
        });

      const currentlySelectedTarget = targetSummaries.find(
        (ts) => ts.name === visibleTarget?.name
      );

      if (currentlySelectedTarget) {
        handleSetVisibleTarget(currentlySelectedTarget);
      }

      return targetSummaries;
    }
    return [];
  }, [
    me.company?.slug,
    selectedDateRange.rangeStart,
    selectedDateRange.rangeEnd,
    timeUnit
  ]);

  const { value: targetSummaries } = useAsync({
    asyncFunction: handleTargetSummaryFilter,
    defaultValue: []
  });
  const targetDropdownOptions = [
    { label: 'No Target', value: null },
    ...(targetSummaries ?? []).map((t) => ({
      label: capitalizeText(t.name),
      value: t.name
    }))
  ];

  const handleSetSelectedTargetData = (val) => {
    if (!val) {
      handleSetVisibleTarget({});
    } else {
      const selectedTarget = targetSummaries.find((td) => td.name === val);
      handleSetVisibleTarget(selectedTarget);
    }
  };
  return (
    <Card className="carbon-explorer">
      <Row>
        <Col span={24}>
          <Row className="mb-4">
            <Col flex="1">
              <Title bottomSpacing={0} size="rg">
                Your Footprint Summary
              </Title>
            </Col>
            <Col>
              <DataExplorerLink />
            </Col>
          </Row>
          <Row gutter={8} className="mb-8" align="middle">
            <Col flex="155px">
              <Select
                disableForm
                name="dateFilter"
                options={footprintRangeDropdownOptions}
                value={chartDateYear}
                onChange={handleSetChartYear}
              />
            </Col>
            <Col flex="155px">
              <Select
                disableForm
                name="aggregateType"
                options={AGGREGATE_TYPE_OPTIONS}
                value={aggregateType}
                onChange={handleSetChartType}
              />
            </Col>
            <Col flex="205px">
              <Select
                disableForm
                name="visibleTarget"
                options={targetDropdownOptions}
                placeholder="No Target Selected"
                onChange={handleSetSelectedTargetData}
              />
            </Col>
            <Col flex="205px">
              <Select
                disableForm
                options={TIME_UNIT_OPTIONS}
                onChange={handleSetTimeUnit}
                value={timeUnit}
              />
            </Col>
          </Row>
          <Row className="mb-8">
            <Col span={24}>
              <DataChart chartData={chartData} isLoading={isLoading} />
            </Col>
          </Row>
        </Col>
        <Col span={24} className="carbon-explorer__data-link">
          <Row wrap={false} align="middle" gutter={24}>
            <Col>
              <Icon name="BookOpen" color={COLOURS_BLUE.BLUE_500} size="24" />
            </Col>
            <Col flex="1">
              <Title bottomSpacing={8}>
                Learn more about Emission Categories
              </Title>
              <Paragraph bottomSpacing={0}>
                There are so many buzzwords in Climate Reporting, we've put
                together a guide to break it down in simple terms for you.
              </Paragraph>
            </Col>
            <Col>
              <LearnMore />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
