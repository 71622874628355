import { Col, Modal, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { INITIATIVE_TYPE } from '../../../../constants/InitiativeType';
import { useMeContext } from '../../../../context';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { analytics } from '../../../../helpers';
import InitiativeService from '../../../../services/InitiativeService';
import {
  Button,
  DatePicker,
  Icon,
  Input,
  Paragraph,
  Title
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import './CompanyInitiativeForm.scss';
import { initiativeFormValidation } from './InitiativeFormValidation';

export function CompanyInitiativeForm({
  initiativeTemplateId,
  companyInitiative,
  onSuccess,
  onDeleteSuccess,
  analyticsContext,
  relatedInitiatives
}) {
  const { me } = useMeContext();
  const { company } = me;
  const { slug: companySlug } = company;
  const hasRelatedProjects = !!relatedInitiatives?.length;
  const [showInitiativeForm, setShowInitiativeForm] = useState(
    !hasRelatedProjects
  );
  const isEditMode = !!companyInitiative?.id;
  const handleSubmit = async (value) => {
    const method = isEditMode
      ? InitiativeService.editInitiative
      : InitiativeService.createInitiative;
    const initiativeResp = await method({
      companySlug,
      initiativeTemplateId,
      ...value
    });
    if (!isEditMode && analyticsContext) {
      analytics.track('Initiative Created', me, {
        ...analyticsContext,
        initiativeTemplateId,
        companyInitiativeId: initiativeResp.id
      });
    }
    onSuccess?.(initiativeResp);
  };
  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  const handleDelete = async () => {
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId,
      companyInitiativeId: companyInitiative.id
    });
    await InitiativeService.deleteInitiative(companyInitiative?.id);
    onDeleteSuccess?.();
  };
  const { execute: handleDeleteInitiative, isLoading: isDeletingInitiative } =
    useAsync({
      asyncFunction: handleDelete,
      immediate: false
    });
  const handleWarnOnDelete = () => {
    Modal.confirm({
      className: 'ch-modal',
      icon: null,
      closable: true,
      okText: 'Delete',
      cancelText: 'Cancel',
      okButtonProps: {
        className: 'ch-button-new',
        shape: 'round',
        danger: true
      },
      cancelButtonProps: {
        className: 'ch-button-new secondary',
        shape: 'round'
      },
      content: (
        <div className="text-left">
          <Title bottomSpacing={24}>
            Are you sure you’d like to delete this initiative ?
          </Title>
          <Paragraph bottomSpacing={0}>
            The initiative will be cleared and all related actions deleted.
          </Paragraph>
        </div>
      ),
      onOk: handleDeleteInitiative
    });
  };
  const handleToggleForm = () => {
    setShowInitiativeForm(true);
  };
  return showInitiativeForm ? (
    <Formik
      onSubmit={execute}
      enableReinitialize
      initialValues={{
        companyInitiativeId: companyInitiative?.id,
        name: companyInitiative?.name,
        endDate: companyInitiative?.endDate
      }}
      validationSchema={initiativeFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row gutter={[0, 24]}>
            {!isEditMode && (
              <Col span={24}>
                <Paragraph bottomSpacing={0}>
                  Once you create this initiative you will see all of the steps
                  need to complete this initiative
                </Paragraph>
              </Col>
            )}
            <Col span={24}>
              <Input
                placeholder="Enter an initiative name (e.g Office Energy Reduction John Street, 2022)"
                name="name"
                value={values?.name}
              />
            </Col>
            <Col span={24}>
              <DatePicker
                title="Target End Date"
                name="endDate"
                placeholder="Select"
                defaultValue={values?.endDate}
                setFieldValue={setFieldValue}
              />
            </Col>
            <Col span={24} className="display-flex justify-end mt-6">
              <Space size={16}>
                {isEditMode && (
                  <Button
                    type={BUTTON_TYPES.TEXT}
                    className="text-danger"
                    isLoading={isDeletingInitiative || isLoading}
                    disabled={isDeletingInitiative || isLoading}
                    onClick={handleWarnOnDelete}
                  >
                    Delete Initiative
                  </Button>
                )}

                <Button
                  htmlType="submit"
                  isLoading={isDeletingInitiative || isLoading}
                  disabled={isDeletingInitiative || isLoading}
                >
                  {isEditMode ? 'Save' : 'Create Initiative'}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  ) : (
    <Row wrap="no-wrap" gutter={24}>
      <Col span={24}>
        <Paragraph>
          In order to get started with this template we recommend you complete
          the prerequisite initiatives. These initiatives will help you more
          accurately measure your emissions and accurately understand your
          reduction estimates.
        </Paragraph>
      </Col>
      <Col span={24}>
        <Title>Recommended Initiatives:</Title>
        <Row gutter={[16, 16]}>
          {relatedInitiatives.map((initiative) => {
            const toRoute =
              initiative.type === INITIATIVE_TYPE.MEASUREMENT
                ? AppRoutes.TRAILS_OVERVIEW
                : AppRoutes.INITIATIVE_TEMPLATE.replace(
                    ':initiativeTemplateId',
                    initiativeTemplateId
                  );

            return (
              <Col span={24}>
                <Link to={toRoute}>
                  <Row
                    align="middle"
                    className="company-initiative-form__pre-req"
                  >
                    <Col flex={1}>
                      <Title bottomSpacing={16}>{initiative.name}</Title>
                      <Paragraph bottomSpacing={0}>
                        {initiative.shortDescription}
                      </Paragraph>
                    </Col>
                    <Col>
                      <Icon name="ChevronRight" />
                    </Col>
                  </Row>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col span={24} className="display-flex justify-end  mt-6">
        <Button onClick={handleToggleForm}>Ignore & continue</Button>
      </Col>
    </Row>
  );
}
