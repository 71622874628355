function PawContract() {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="120" height="120" rx="60" fill="#FEFCE8" />
      <rect
        x="31.9414"
        y="31.5664"
        width="43.2733"
        height="60.0527"
        rx="1.76626"
        fill="#D4D4D8"
      />
      <rect
        x="35.4746"
        y="28.0312"
        width="43.2733"
        height="60.0527"
        rx="1.76626"
        fill="#E4E4E7"
      />
      <rect
        x="39.0068"
        y="24.5"
        width="43.2733"
        height="60.0527"
        rx="1.76626"
        fill="white"
      />
      <rect
        x="43.4229"
        y="31.5664"
        width="12.3638"
        height="11.4807"
        rx="5.74033"
        fill="#E4E4E7"
      />
      <rect
        x="43.4229"
        y="50.9922"
        width="35.3251"
        height="4.41564"
        rx="2.20782"
        fill="#E4E4E7"
      />
      <rect
        x="43.4229"
        y="58.9414"
        width="35.3251"
        height="4.41564"
        rx="2.20782"
        fill="#E4E4E7"
      />
      <rect
        x="43.4229"
        y="66.8906"
        width="35.3251"
        height="4.41564"
        rx="2.20782"
        fill="#E4E4E7"
      />
      <rect
        x="43.4229"
        y="74.8379"
        width="35.3251"
        height="4.41564"
        rx="2.20782"
        fill="#E4E4E7"
      />
      <g filter="url(#filter0_d_6605_86222)">
        <path
          d="M73.8071 73.2349C73.0152 73.9098 72.0305 74.3176 70.9934 74.4004C69.8211 74.4936 68.7204 75.0016 67.8888 75.8332C67.0573 76.6647 66.5493 77.7654 66.456 78.9377C66.3732 79.9749 65.9654 80.9595 65.2906 81.7515C64.5275 82.6464 64.1084 83.7839 64.1084 84.96C64.1084 86.136 64.5275 87.2736 65.2906 88.1685C65.9654 88.9605 66.3732 89.9451 66.456 90.9823C66.5493 92.1546 67.0573 93.2553 67.8888 94.0868C68.7204 94.9184 69.8211 95.4264 70.9934 95.5196C72.0305 95.6024 73.0152 96.0102 73.8071 96.685C74.702 97.4481 75.8396 97.8672 77.0157 97.8672C78.1917 97.8672 79.3293 97.4481 80.2242 96.685C81.0161 96.0102 82.0008 95.6024 83.038 95.5196C84.2102 95.4264 85.3109 94.9184 86.1425 94.0868C86.974 93.2553 87.482 92.1546 87.5753 90.9823C87.6581 89.9451 88.0659 88.9605 88.7407 88.1685C89.5038 87.2736 89.9229 86.136 89.9229 84.96C89.9229 83.7839 89.5038 82.6464 88.7407 81.7515C88.0659 80.9595 87.6581 79.9749 87.5753 78.9377C87.482 77.7654 86.974 76.6647 86.1425 75.8332C85.3109 75.0016 84.2102 74.4936 83.038 74.4004C82.0008 74.3176 81.0161 73.9098 80.2242 73.2349C79.3293 72.4719 78.1917 72.0527 77.0157 72.0527C75.8396 72.0527 74.702 72.4719 73.8071 73.2349Z"
          fill="white"
        />
        <path
          d="M72.668 89.0826C72.668 87.0826 74.2213 84.1445 76.1374 84.1445H78.0298C79.9459 84.1445 81.4992 87.0826 81.4992 89.0826C81.4992 91.0826 79.9459 90.0702 78.0298 90.0702H76.1374C74.2213 90.0702 72.668 91.0826 72.668 89.0826Z"
          fill="#FBAB1D"
        />
        <ellipse
          cx="74.8755"
          cy="80.612"
          rx="1.32469"
          ry="1.76626"
          fill="#FBAB1D"
        />
        <ellipse
          cx="78.4077"
          cy="80.612"
          rx="1.32469"
          ry="1.76626"
          fill="#FBAB1D"
        />
        <ellipse
          cx="81.9409"
          cy="84.1432"
          rx="1.32469"
          ry="1.76626"
          fill="#FBAB1D"
        />
        <ellipse
          cx="72.2261"
          cy="84.1432"
          rx="1.32469"
          ry="1.76626"
          fill="#FBAB1D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6605_86222"
          x="58.4473"
          y="66.3917"
          width="37.1366"
          height="37.1366"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.83054" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6605_86222"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6605_86222"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export default PawContract;
