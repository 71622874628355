import AcademicCap from './AcademicCap';
import Adjustments from './Adjustments';
import Annotation from './Annotation';
import Archive from './Archive';
import ArrowCircleDown from './ArrowCircleDown';
import ArrowCircleLeft from './ArrowCircleLeft';
import ArrowCircleUp from './ArrowCircleUp';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowNarrowDown from './ArrowNarrowDown';
import ArrowNarrowLeft from './ArrowNarrowLeft';
import ArrowNarrowRight from './ArrowNarrowRight';
import ArrowNarrowUp from './ArrowNarrowUp';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import AtSymbol from './AtSymbol';
import BackSpace from './BackSpace';
import BadgeCheck from './BadgeCheck';
import Ban from './Ban';
import Beaker from './Beaker';
import Bell from './Bell';
import Bike from './Bike';
import Bookmark from './Bookmark';
import BookmarkAlt from './BookmarkAlt';
import BookOpen from './BookOpen';
import Briefcase from './Briefcase';
import Cake from './Cake';
import Calculator from './Calculator';
import Cash from './Cash';
import ChartBar from './ChartBar';
import ChartPie from './ChartPie';
import ChartSquareBar from './ChartSquareBar';
import ChatAlt from './ChatAlt';
import ChatAlt2 from './ChatAlt2';
import Check from './Check';
import CheckCircle from './CheckCircle';
import ChevronDoubleDown from './ChevronDoubleDown';
import ChevronDoubleRight from './ChevronDoubleRight';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import CircleFilled from './CircleFilled';
import ClipboardCopy from './ClipboardCopy';
import Clock from './Clock';
import CloudUpload from './CloudUpload';
import Collection from './Collection';
import CreditCard from './CreditCard';
import CurrencyDollar from './CurrencyDollar';
import Desktop from './Desktop';
import DocumentReport from './DocumentReport';
import Download from './Download';
import Eat from './Eat';
import Exclamation from './Exclamation';
import ExclamationTriangle from './ExclamationTriangle';
import ExternalLink from './ExternalLink';
import Fire from './Fire';
import Flag from './Flag';
import Globe from './Globe';
import GlobeAlt from './GlobeAlt';
import Home from './Home';
import InformationCircle from './InformationCircle';
import Key from './Key';
import Libra from './Libra';
import LightBulb from './LightBulb';
import LightningBolt from './LightningBolt';
import LockClosed from './LockClosed';
import Logout from './Logout';
import Map from './Map';
import MinusCircle from './MinusCircle';
import OfficeBuilding from './OfficeBuilding';
import Pencil from './Pencil';
import Phone from './Phone';
import Plus from './Plus';
import QuestionCircle from './QuestionCircle';
import Refresh from './Refresh';
import Search from './Search';
import ShieldCheck from './ShieldCheck';
import ShoppingCart from './ShoppingCart';
import Speakerphone from './Speakerphone';
import Support from './Support';
import SwitchHorizontal from './SwitchHorizontal';
import Ticket from './Ticket';
import Times from './Times';
import TrendingDown from './TrendingDown';
import Truck from './Truck';
import Upload from './Upload';
import User from './User';
import UserCircle from './UserCircle';
import Weight from './Weight';
import XCircle from './XCircle';

const icons = {
  AcademicCap,
  Adjustments,
  Annotation,
  Archive,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleUp,
  ArrowDown,
  ArrowLeft,
  ArrowNarrowDown,
  ArrowNarrowLeft,
  ArrowNarrowRight,
  ArrowNarrowUp,
  ArrowRight,
  ArrowUp,
  AtSymbol,
  BackSpace,
  BadgeCheck,
  Ban,
  Beaker,
  Bell,
  Bike,
  BookOpen,
  Bookmark,
  BookmarkAlt,
  Briefcase,
  Cake,
  Calculator,
  Cash,
  ChartBar,
  ChartPie,
  ChartSquareBar,
  ChatAlt,
  ChatAlt2,
  Check,
  CheckCircle,
  ChevronDoubleDown,
  ChevronDoubleRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleFilled,
  ClipboardCopy,
  Clock,
  CloudUpload,
  Collection,
  CreditCard,
  CurrencyDollar,
  Desktop,
  DocumentReport,
  Download,
  Eat,
  Exclamation,
  ExclamationTriangle,
  ExternalLink,
  Fire,
  Flag,
  Globe,
  GlobeAlt,
  Home,
  InformationCircle,
  Key,
  Libra,
  LightBulb,
  LightningBolt,
  LockClosed,
  Logout,
  Map,
  MinusCircle,
  OfficeBuilding,
  Pencil,
  Phone,
  Plus,
  QuestionCircle,
  Refresh,
  Search,
  ShieldCheck,
  ShoppingCart,
  Speakerphone,
  Support,
  SwitchHorizontal,
  Ticket,
  Times,
  TrendingDown,
  Truck,
  Upload,
  User,
  UserCircle,
  Weight,
  XCircle
};

export const iconList = Object.keys(icons).sort();
export default icons;
