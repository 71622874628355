import { Col, Divider, Row } from 'antd';
import { COLOURS_GRAY } from '../../constants/colours';
import { Anchor, Icon, Paragraph, Title } from '../../stories/atoms';
import './CreditsBasicExplaination.scss';

function CreditsBasicExplanation() {
  return (
    <Row className="credits-basic-explanation" justify="center">
      <Col xs={20} xl={15} className="mb-8">
        <Row>
          <Col span={24}>
            <Title size="xl">What are carbon credits?</Title>
          </Col>
        </Row>
        <Row gutter={[0, 40]}>
          <Col span={24}>
            <Paragraph>
              Carbon credits allow a company to invest in a project in order to
              compensate for their emissions made in their regular business
              operations. Offsets can be controversial within the scientific
              community because certain companies can treat them as a way to get
              “off the hook” with actual reduction efforts.
            </Paragraph>
            <Paragraph>
              In spite their controversy, we are facing a climate emergency and
              the time to act is now. But purchasing credits cannot be your only
              reduction strategy; our stance is that offsets should only be used
              by businesses when reduction efforts have been made, rather than a
              hall pass for pollution to continue at current rates.
            </Paragraph>
          </Col>
          <Col span={24}>
            <Title size="xl">The Basics</Title>
            <ul>
              <li>
                A carbon credit is a certificate showing that a company has paid
                to have a certain amount of carbon dioxide removed from the
                environment.
              </li>
              <li>
                1 high quality carbon credit is equal to 1 tonne of CO2e
                emissions.
              </li>
              <li>
                Carbon credits provide a way for companies to take additional
                climate action.
              </li>
              <li>
                There are two types of carbon credit types, avoidance/reduction
                and removal/sequestration.
              </li>
              <li>
                'Net zero emissions' refers to achieving an overall balance
                between greenhouse gas emissions produced and greenhouse gas
                emissions taken out of the atmosphere.
              </li>
            </ul>
          </Col>
          <Col span={24}>
            <Title size="xl">Avoidance/Reduction</Title>
            <Paragraph>
              Projects that avoid emissions through Energy efficiency projects
              or avoided nature loss.
            </Paragraph>
          </Col>
          <Col span={24} className="credits-basic-explanation__types">
            <Title size="md">
              <Icon name="LightBulb" color={COLOURS_GRAY.GRAY_700} /> Project
              types
            </Title>
            <Title size="rg">Avoided Nature Loss</Title>
            <ul>
              <li>
                Protects against loss of nature such as forests & peatlands
                which house large amounts of carbon.
              </li>
              <li>
                Offsets are calculated based on looking at the average nature
                loss in the area vs the nature loss in the project area. Offsets
                are issued based on the difference between these two numbers
              </li>
            </ul>
            <Title size="rg">Avoided Nature Loss</Title>
            <ul>
              <li>
                Measures the impact of projects that reduce the climate impact
                of emission sources. i.e. the replacement of wood burning stoves
                with solar cookers
              </li>
              <li>
                Offsets are calculated based on difference between previous
                emissions and current emissions from these sources
              </li>
            </ul>
          </Col>
          <Col span={24}>
            <Title>Removal/Sequestration</Title>
            <Paragraph>
              Projects that remove carbon from the atmosphere directly and store
              it
            </Paragraph>
          </Col>
          <Col span={24} className="credits-basic-explanation__types">
            <Title size="md">
              <Icon name="LightBulb" color={COLOURS_GRAY.GRAY_700} /> Project
              types
            </Title>
            <Title size="rg">Avoided Nature Loss</Title>
            <ul>
              <li>
                Uses natual processes to sequester carbon i.e. Planting forests,
                Soil Restoration, Mangrove Planting, Seagrass Farming
              </li>
            </ul>
            <Title size="rg">Engineered Solutions</Title>
            <ul>
              <li>
                Man-made solutions designed to remove carbon from the
                atmosphere. i.e. direct air capture Machines that pull carbon
                dioxide directly out of the atmosphere and inject it into mine
                shafts formations to mineralize into stone
              </li>
            </ul>
          </Col>
          <Col span={24}>
            <Paragraph align="center">
              If a business emits 1 tonne of emissions and purchases an offset
              for 1 tonne of avoided emissions. There is still one tonne of
              emissions in the atmosphere.
            </Paragraph>
          </Col>

          <Col span={24} className="justify-center display-flex">
            <Divider />
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col span={12}>
                <Paragraph className="text-bd" align="center">
                  At Carbonhound we only support projects that remove carbon
                  from the atmosphere.
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Title size="xl">Questions?</Title>
            <Paragraph>
              Visit our{' '}
              <Anchor
                href="https://carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4?pvs=4"
                className="text-bd"
              >
                FAQ
              </Anchor>
              , or{' '}
              <Anchor
                href="//meetings.hubspot.com/sanders5/carbonhound-credits-consultation"
                className="text-bd"
              >
                get in touch with us
              </Anchor>
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default CreditsBasicExplanation;
