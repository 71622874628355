import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Space, Spin } from 'antd';
import { useFormikContext } from 'formik';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { Alert, AttachmentInput, Card } from '../../../components';
import {
  BulkUploadType,
  TransportationType,
  UnitTypeFamilies,
  UploadStatus
} from '../../../constants';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import {
  useBulkUploadContext,
  useMeContext,
  useMetaContext
} from '../../../context';
import { analytics, capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { NotificationService } from '../../../services';
import { Button, Paragraph, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { Footer } from '../Footer';
import './PrepareUpload.scss';

function DownloadTemplateButton() {
  const { values } = useFormikContext();
  const { bulkUploadState } = useBulkUploadContext();
  const { notification, notificationReqParams } = bulkUploadState;
  const { meta } = useMetaContext();
  const { travelCategories } = meta;
  const isRange =
    (bulkUploadState.notificationReqParams.dateType ||
      notification.details?.dateType) === 'RANGE';
  const travelCategoryId =
    values?.travelCategoryId ||
    notificationReqParams?.travelCategoryId ||
    notification.details?.travelCategoryId;
  const uploadType = BulkUploadType[notification.details?.uploadType];
  const isDistanceUnit =
    notificationReqParams.unitFamilyName === UnitTypeFamilies.DISTANCE;
  const travelCategoryName = travelCategories.find(
    (cat) => cat.id === travelCategoryId
  )?.name;

  const { me } = useMeContext();
  const templateRoute =
    'https://storage.googleapis.com/carbonhound-prod-public/templates';
  const TemplateFileNames = {
    Energy: 'EnergyUpload-Template.csv',
    TravelFlightsToFrom: 'BusinessTravelUpload-Template-flights-toFrom.csv',
    TravelFlightsDistance:
      'BusinessTravelUpload-Template-flights-totalDistance.csv',
    TravelOtherToFrom: 'BusinessTravelUpload-Template-other-ToFrom.csv',
    TravelOtherDistance:
      'BusinessTravelUpload-Template-other-totalDistance.csv',
    TravelCarToFrom: 'BusinessTravelUpload-Template-car-toFrom.csv',
    TravelCarDistance: 'BusinessTravelUpload-Template-car-totalDistance.csv',
    CustomDate: 'CustomUpload-Template-date.csv',
    CustomRange: 'CustomUpload-Template-range.csv',
    CustomToFrom: 'CustomUpload-Template-toFrom.csv',
    UserEmployee: 'UserUpload-employee.csv',
    PersonalVehicle: 'PersonalVehicleUpload.csv'
  };
  const handleDownloadTemplate = () => {
    analytics.track('Download Clicked', me, {
      level1: 'Bulk Uploads',
      level2: 'Prepare and upload',
      notification
    });
  };

  const toFromCopy =
    travelCategoryName === TransportationType.PLANE
      ? 'To/From Airport CSV'
      : 'To/From CSV';
  let toFromFile;
  let defaultCSVType;
  if (uploadType === BulkUploadType.TRAVEL) {
    if (
      travelCategoryName === TransportationType.CAR ||
      travelCategoryName === TransportationType.MOTORBIKE
    ) {
      // Car Case
      defaultCSVType = TemplateFileNames.TravelCarDistance;
      toFromFile = TemplateFileNames.TravelCarToFrom;
    } else if (travelCategoryName === TransportationType.PLANE) {
      // Plane Cases
      toFromFile = TemplateFileNames.TravelFlightsToFrom;
      defaultCSVType = TemplateFileNames.TravelFlightsDistance;
    } else {
      // default cases
      toFromFile = TemplateFileNames.TravelOtherToFrom;
      defaultCSVType = TemplateFileNames.TravelOtherDistance;
    }
  } else if (uploadType === BulkUploadType.CUSTOM) {
    //  Custom Case
    defaultCSVType = isRange
      ? TemplateFileNames.CustomRange
      : TemplateFileNames.CustomDate;

    toFromFile = TemplateFileNames.CustomToFrom;
  } else if (uploadType === BulkUploadType.ENERGY || isRange) {
    //  Energy Case
    defaultCSVType = TemplateFileNames.Energy;
  } else if (uploadType === BulkUploadType.USER) {
    defaultCSVType = TemplateFileNames.UserEmployee;
  } else if (uploadType === BulkUploadType.PERSONAL_VEHICLE) {
    defaultCSVType = TemplateFileNames.PersonalVehicle;
  }

  const defaultCSVCopy =
    !!isDistanceUnit || uploadType === BulkUploadType.TRAVEL
      ? 'Total Distance CSV'
      : 'Download';
  return (
    <Space wrap className="justify-center">
      {(!!isDistanceUnit || uploadType === BulkUploadType.TRAVEL) && (
        <a
          href={`${templateRoute}/${toFromFile}`}
          target="_blank"
          download
          rel="noopener noreferrer"
          className="prepare-upload__download-link"
        >
          <Button
            type={BUTTON_TYPES.SECONDARY}
            icon="Download"
            onClick={handleDownloadTemplate}
            outlined
            small
            primary
          >
            {toFromCopy}
          </Button>
        </a>
      )}

      <a
        href={`${templateRoute}/${defaultCSVType}`}
        target="_blank"
        download
        rel="noopener noreferrer"
        className="prepare-upload__download-link"
      >
        <Button
          type={BUTTON_TYPES.SECONDARY}
          icon="Download"
          onClick={handleDownloadTemplate}
          outlined
          small
          primary
        >
          {defaultCSVCopy}
        </Button>
      </a>
    </Space>
  );
}

function PrepareUpload() {
  const { values, setFieldValue } = useFormikContext();
  const { bulkUploadState, setStatus, updateNotification } =
    useBulkUploadContext();
  const { me } = useMeContext();
  const { setTravelCategories } = useMetaContext();
  const { notification, notificationReqParams, companySlug } = bulkUploadState;
  const uploadType = BulkUploadType[notification.details?.uploadType];
  const travelCategoryId =
    values?.travelCategoryId ?? notificationReqParams?.travelCategoryId;
  const [alert, setAlert] = useState({
    closable: undefined,
    visible: false,
    showIcon: undefined,
    type: undefined,
    copy: ''
  });

  const handleOnDropSuccess = (acceptedFiles) => {
    setFieldValue('file', acceptedFiles[0]);

    setAlert((prevState) =>
      produce(prevState, (draft) => {
        draft.visible = true;
        draft.type = 'success';
        draft.copy = (
          <>
            <span className="text-bd">{acceptedFiles[0].name}</span>{' '}
            successfully uploaded
          </>
        );
      })
    );
  };

  const handleOnDropError = () => {
    setAlert((prevState) =>
      produce(prevState, (draft) => {
        draft.visible = true;
        draft.type = 'error';
        draft.copy =
          'That is the wrong file format, please upload only supported file types (.csv)';
      })
    );
  };

  const handleCloseAlert = () => {
    setAlert((prevState) =>
      produce(prevState, (draft) => {
        draft.visible = false;
        draft.closable = undefined;
        draft.showIcon = undefined;
        draft.type = undefined;
        draft.copy = '';
      })
    );
  };

  const handleCreateNotification = async () => {
    const notificationTitle = `${capitalizeText(
      uploadType ?? notificationReqParams.uploadType
    )} ${notificationReqParams?.address ?? ''}`;
    const notificationResponse = await NotificationService.createNotification(
      {
        category: notification?.category,
        uploadType: uploadType ?? notificationReqParams.uploadType,
        title: notificationTitle,
        companySlug,
        ownerId: me?.company?.userCompanyId,
        energyDetailsId: values?.energyDetailsId,
        collectionId: values?.collectionId,
        travelCategoryId,
        ...notificationReqParams
      },
      (notification) => {
        updateNotification({
          notification
        });
      },
      () => {},
      () => {}
    );
    return notificationResponse;
  };

  const handleStartUploadFn = async () => {
    let notificationParams = notification;
    if (!notificationParams.id) {
      notificationParams = await handleCreateNotification();
    }
    const baseParams = {
      notificationId: notificationParams.id,
      companySlug,
      fileName: values?.file?.name,
      ...notificationReqParams
    };
    await NotificationService.authorizeUpload(
      {
        ...baseParams,
        file: values?.file
      },
      ({ status }) => {
        const emissionSourceDetailsId =
          notificationParams.details?.emissionSourceDetailsId;
        if (status === 200) {
          NotificationService.startBulkUpload(
            {
              ...baseParams,
              emissionSourceDetailsId,
              energyDetailsId:
                notificationParams.details?.energyDetailsId ??
                baseParams.energyDetailsId,
              travelCategoryId,
              uploadType: uploadType ?? baseParams.uploadType,
              ...(!!notificationReqParams?.origin && {
                origin: notificationReqParams.origin
              })
            },
            () => {
              setStatus({ status: UploadStatus.PROCESSING });
            },
            () => {},
            () => {}
          );
        }
      },
      () => {},
      () => {}
    );
  };

  const { execute: handleStartUpload, isLoading: isUploadStarted } = useAsync({
    asyncFunction: handleStartUploadFn,
    immediate: false
  });

  const handleNextStep = async () => {
    setAlert((prevState) =>
      produce(prevState, (draft) => {
        draft.showIcon = false;
        draft.visible = true;
        draft.closable = false;
        draft.type = undefined;
        draft.copy = (
          <span>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ color: COLOURS_CARBONHOUND.PRIMARY_PURPLE }}
                />
              }
              spin
            />{' '}
            Uploading file
          </span>
        );
      })
    );

    await handleStartUpload();

    analytics.track('Upload Data Clicked', me, {
      level1: 'Bulk Uploads',
      level2: 'Prepare and upload',
      notification
    });
  };

  useEffect(() => {
    if (notification.status === UploadStatus.ERROR) {
      setAlert((prevState) =>
        produce(prevState, (draft) => {
          draft.showIcon = true;
          draft.visible = true;
          draft.closable = false;
          draft.type = 'error';
          draft.copy =
            notification.details?.errors[0]?.friendlyMessage ||
            'Something went wrong with your upload, please try again ';
        })
      );
    } else {
      setAlert((prevState) =>
        produce(prevState, (draft) => {
          draft.visible = false;
          draft.closable = undefined;
          draft.showIcon = undefined;
          draft.type = undefined;
          draft.copy = '';
        })
      );
    }
  }, [notification.status, notification.details?.errorMessage]);
  useEffect(() => {
    if (uploadType === BulkUploadType.TRAVEL) {
      setTravelCategories();
    }
  }, []);
  return (
    <div className="prepare-upload">
      <Row gutter={8}>
        <Col span={12}>
          <Card className="text-center">
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <span className="prepare-upload__icon">
                  <DownloadOutlined />
                </span>
              </Col>
              <Col span={24}>
                <Title className="text-bd">Step 1</Title>
                <Paragraph>Download our csv template to get started</Paragraph>
              </Col>
              <Col span={24}>
                <DownloadTemplateButton />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <AttachmentInput
            multiple={false}
            accept={{
              'text/html': ['.csv']
            }}
            handleOnDropSuccess={handleOnDropSuccess}
            handleOnDropError={handleOnDropError}
            title="Step 2"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Alert
            closable={alert.closable}
            showIcon={alert.showIcon}
            isVisible={alert.visible}
            handleCloseAlert={handleCloseAlert}
            type={alert.type}
            message={alert.copy}
          />
        </Col>
      </Row>
      <Footer
        analyticsStepContext="Prepare and upload"
        isLoading={isUploadStarted}
        onNextFn={handleNextStep}
        isStepInvalid={typeof values?.file === 'undefined'}
      />
    </div>
  );
}
export default PrepareUpload;
