import { useCallback } from 'react';
import BuildingType from '../../../constants/buildingType';
import { getDropdownOptions } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { MetaService } from '../../../services';
import { Select } from '../../atoms';

function BuildingTypeSelect({ countryId, ...props }) {
  const handleLoadDropdown = useCallback(async () => {
    const resp = await MetaService.fetchBuildingTypes({
      category: BuildingType.RESIDENTIAL,
      countryId
    });
    return getDropdownOptions(resp, 'friendlyName', 'id');
  }, [countryId]);

  const { value: buildingTypeList, isLoading: isLoadingBuildingTypeList } =
    useAsync({
      asyncFunction: handleLoadDropdown,
      defaultValue: []
    });

  return (
    <Select
      {...props}
      loading={isLoadingBuildingTypeList}
      options={buildingTypeList}
    >
      {!!props.children && props.children}
    </Select>
  );
}
export default BuildingTypeSelect;
