import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { YesOrNoOptions } from '../../../../constants';
import { capitalizeText } from '../../../../helpers';
import { Select, Title } from '../../../atoms';
import InputNumberField from '../../../atoms/InputNumberField';

export function EnergyInputs() {
  const isOfficeSquareFootageEnabled = (formValues) => {
    const { energyTypes } = formValues;
    return (
      energyTypes.filter(
        (energyType) =>
          energyType.hasAccessToBills === false ||
          energyType.hasSeparateMeter === false
      ).length > 0
    );
  };

  const isBuildingSquareFootageEnabled = (formValues) => {
    const { energyTypes } = formValues;
    return (
      energyTypes.filter((energyType) => energyType.hasSeparateMeter === false)
        .length > 0
    );
  };
  const { setFieldValue, values } = useFormikContext();

  return (
    <Row className="location-form__energy-row" align="middle" gutter={[16, 24]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {values?.energyTypes.map((energyType, fieldIndex) => (
            <>
              <Col span={8}>
                <Title bottomSpacing={0} size="sm" className="text-sbd">
                  {capitalizeText(energyType.energyType)}
                </Title>
              </Col>
              <Col span={8}>
                <Select
                  name={`energyTypes[${fieldIndex}].hasAccessToBills`}
                  value={values?.energyTypes[fieldIndex].hasAccessToBills}
                  className="mb-0"
                  placeholder="Select"
                  disabled={!values?.isEstimationSupported}
                  onChange={(value) => {
                    setFieldValue(
                      `energyTypes[${fieldIndex}].hasAccessToBills`,
                      value
                    );
                    if (!value) {
                      setFieldValue(
                        `energyTypes[${fieldIndex}].hasSeparateMeter`,
                        null
                      );
                    }
                  }}
                  options={YesOrNoOptions}
                />
              </Col>
              <Col span={8}>
                <Select
                  name={`energyTypes[${fieldIndex}].hasSeparateMeter`}
                  value={values.energyTypes[fieldIndex].hasSeparateMeter}
                  className="mb-0"
                  setFieldValue={setFieldValue}
                  placeholder="Select"
                  options={YesOrNoOptions}
                  disabled={!values?.energyTypes[fieldIndex].hasAccessToBills}
                />
              </Col>
            </>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          {isOfficeSquareFootageEnabled(values) && (
            <Col span={isBuildingSquareFootageEnabled(values) ? 12 : 24}>
              <InputNumberField
                placeholder="Enter"
                type="number"
                title="Office Square Footage"
                name="officeSquareFootage"
                value={values?.officeSquareFootage}
              />
            </Col>
          )}
          {isBuildingSquareFootageEnabled(values) && (
            <Col span={isOfficeSquareFootageEnabled(values) ? 12 : 24}>
              <InputNumberField
                placeholder="Enter"
                type="number"
                title="Building Square Footage"
                name="buildingSquareFootage"
                value={values?.buildingSquareFootage}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
