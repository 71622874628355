import { axiosInstance } from '../interceptors';
import { successValidator } from '../interceptors/statusValidators';
import { ApiRoutes } from '../routes';

class CompanyTrailService {
  static async fetchCompanyTrails(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_TRAILS.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await axiosInstance.get(API_URL, { params });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createCompanyTrail(companySlug, trail, onSuccess) {
    try {
      const API_URL = ApiRoutes.COMPANY_TRAILS.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(API_URL, trail, {
        validateStatus: successValidator
      });
      onSuccess?.(response);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  }
}

export default CompanyTrailService;
