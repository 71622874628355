function ArrowNarrowDown() {
  return (
    <path
      d="M16 17L12 21M12 21L8 17M12 21V3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowNarrowDown;
