import apiAxiosInstance from '../interceptors/apiAxiosInstance';

class OpenFlightsService {
  static async searchAirports({ term }, onSuccess, onError, onFinal) {
    const API_URL = `${process.env.REACT_APP_OPEN_FLIGHTS_API_URL}airport/search?search=${term}`;

    try {
      const response = await apiAxiosInstance.get(API_URL);
      onSuccess?.(response?.data.airports || []);
      return response?.data.airports || [];
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }
}

export default OpenFlightsService;
