export const IntegrationStatus = {
  PENDING: 'PENDING',
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
  PENDING_CONNECTION: 'PENDING CONNECTION',
  PENDING_HISTORICAL_IMPORT: 'PENDING HISTORICAL IMPORT',
  PENDING_DISCONNECT: 'PENDING DISCONNECT'
};

export const PendingIntegrationStatuses = [
  IntegrationStatus.PENDING,
  IntegrationStatus.PENDING_HISTORICAL_IMPORT,
  IntegrationStatus.PENDING_CONNECTION
];
export const ActiveIntegrationStatuses = [
  ...PendingIntegrationStatuses,
  IntegrationStatus.CONNECTED
];

export const DisconnectIntegrationStatuses = [
  IntegrationStatus.DISCONNECTED,
  IntegrationStatus.PENDING_DISCONNECT
];
