import { LoadingOutlined } from '@ant-design/icons';
import { Table as AntTable } from 'antd';
import classNames from 'classnames';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { ColumnItem } from '../index';
import './Table.scss';

function Table({
  className,
  total,
  pagination: { pageNumber, pageSize, onChange } = {
    pageNumber: 1,
    pageSize: 30,
    onChange: () => {}
  },
  showPagination = false,
  rowClassName,
  rowKey = 'id',
  isHeaderBorder = false,
  isAlternatingRowBackgroundDisabled = false,
  isLoading,
  dataSource,
  ...tableProps
}) {
  return (
    <AntTable
      {...tableProps}
      className={classNames('ch-table-wrapper', {
        [className]: !!className,
        'header-border': isHeaderBorder
      })}
      rowKey={rowKey}
      showSorterTooltip={false}
      rowClassName={(actionItem, index) => {
        const rowCN = rowClassName?.(actionItem, index);
        return classNames('ch-table__row', {
          [rowCN]: !!rowCN,
          ...(!isAlternatingRowBackgroundDisabled && {
            'ch-table__row ch-table__row--dark': isHeaderBorder
              ? index % 2 === 1
              : index % 2 === 0,
            'ch-table__row ch-table__row--light': isHeaderBorder
              ? index % 2 === 0
              : index % 2 === 1
          })
        });
      }}
      tableLayout="auto"
      bordered={false}
      sortDirections={['ASC', 'DESC']}
      loading={
        isLoading && {
          indicator: (
            <LoadingOutlined
              style={{
                fontSize: 40,
                color: COLOURS_CARBONHOUND.PRIMARY_PURPLE
              }}
              spin
            />
          )
        }
      }
      components={{
        body: {
          cell: dataSource?.length > 0 ? ColumnItem : undefined
        }
      }}
      pagination={
        !!showPagination && {
          position: ['none', 'bottomCenter'],
          pageSize,
          current: pageNumber,
          onChange: (targetPageNumber) =>
            onChange?.(targetPageNumber, pageNumber),
          total,
          showSizeChanger: false,
          simple: true,
          hideOnSinglePage: true
        }
      }
      dataSource={dataSource}
    />
  );
}

export default Table;
