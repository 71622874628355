import { Col, Divider, Row, Select as AntSelect, Space, Statistic } from 'antd';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate, useParams } from 'react-router-dom';
import externalLink from '../../../assets/images/external-link.svg';
import mountain from '../../../assets/images/mountain.svg';
import TargetChart from '../../../assets/images/target-chart.svg';
import { Card, StatisticGroup, Tag } from '../../../components';
import { TargetType } from '../../../constants';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import {
  analytics,
  capitalizeText,
  getYearDropdownOptions
} from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { TargetService } from '../../../services';
import { Button, Paragraph, Select } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import BaseYearInfo from '../BaseYearInfo';
import IntensityFormRow from './IntensityFormRow';
import {
  absoluteStrengths,
  absoluteWeaknesses,
  getTargetYearDropdownOptions,
  intensityStrengths,
  intensityWeaknesses,
  targetIntensityInternalAggregateMap
} from './TargetSettingStrategy.constants';
import './TargetSettingStrategy.scss';
import { setTargetSettingStrategyValidation } from './TargetSettingStrategyValidation';

const getTargetDetailValueName = ({ values = {} }) => {
  if (values.type === TargetType.ABSOLUTE) {
    return capitalizeText(values.type);
  }
  return values?.name;
};

export function TargetSettingStrategy() {
  const { targetDetailsId } = useParams();
  const isNew = targetDetailsId === 'new';
  const { me } = useMeContext();
  const {
    company: { slug: companySlug, id: companyId }
  } = me;
  const navigate = useNavigate();
  const location = useLocation();
  const initialEndYearOptions = getTargetYearDropdownOptions();
  const startYearOptions = getYearDropdownOptions();
  const initialDate = new Date().getUTCFullYear();
  const [endYearOptions, setEndYearOptions] = useState(initialEndYearOptions);
  // business logic - we always want the recommended year to be 5 years from the earliest option
  const defaultEndYear =
    initialEndYearOptions[5] && initialEndYearOptions[5].value;

  const targetTypeOptions = [
    {
      label: 'Absolute',
      value: TargetType.ABSOLUTE,
      desc: 'Do your part by ensuring that you are reducing the total emissions your business is producing each year.'
    },
    {
      label: 'Intensity',
      value: TargetType.INTENSITY,
      desc: 'Intensity goals take your emissions and divide them by a metric (i.e. # of Employees) your carbon efficiency as you grow.'
    }
  ];

  const handleGetTargetDetails = useCallback(async () => {
    const { data } = await TargetService.getTargetDetails({
      companySlug,
      targetDetailsId
    });

    return data;
  }, [companySlug, targetDetailsId]);

  const { value: targetDetails, isLoading: areTargetDetailsLoading } = useAsync(
    {
      asyncFunction: handleGetTargetDetails,
      immediate: !isNew,
      defaultValue: {}
    }
  );

  const handleSubmit = async (values) => {
    const formSubmitFn = isNew
      ? TargetService.createTargetDetails
      : TargetService.updateTargetDetails;
    const detailsTitle = getTargetDetailValueName({
      values
    });
    const aggType =
      values.type === TargetType.INTENSITY && !values.aggType
        ? targetIntensityInternalAggregateMap[detailsTitle]
        : values.aggType;

    const { data: targetDetailsResp } = await formSubmitFn({
      ...values,
      companySlug,
      name: capitalizeText(detailsTitle),
      companyId,
      endYear: `${values.endYear}-01-01`,
      startYear: `${values.startYear}-01-01`,
      id: targetDetailsId,
      aggType
    });

    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Set Targets',
      level3: 'Set Target Strategy',
      targetDetails: targetDetailsResp
    });

    const nextRoute =
      values?.type === TargetType.ABSOLUTE
        ? AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_REDUCTIONS
        : AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_DATA;
    navigate(nextRoute.replace(':targetDetailsId', targetDetailsResp.id), {
      state: { from: location.state?.from, type: values?.type }
    });
  };

  const { execute: handleSubmitDetails, isLoading: isFormSubmitting } =
    useAsync({
      asyncFunction: handleSubmit,
      immediate: false
    });

  useEffect(() => {
    if (
      targetDetails?.type === TargetType.INTENSITY &&
      typeof location.state?.type === 'undefined'
    ) {
      navigate('.', { replace: true, state: { type: targetDetails?.type } });
    }
  }, [targetDetails?.type, location.state?.type]);

  return (
    <div className="target-setting-strategy">
      <h3 className="mb-0">Set your target strategy</h3>
      <p className="mb-9 text-14">
        Your progress towards your targets will be measured against the strategy
        you select.
      </p>
      <Formik
        enableReinitialize
        validationSchema={setTargetSettingStrategyValidation}
        onSubmit={handleSubmitDetails}
        initialValues={{
          ...targetDetails,
          startYear: targetDetails.startYear
            ? new Date(targetDetails.startYear).getUTCFullYear()
            : initialDate,
          endYear: targetDetails.endYear
            ? new Date(targetDetails.endYear).getUTCFullYear()
            : defaultEndYear,
          durationYears:
            targetDetails.durationYears || defaultEndYear - initialDate
        }}>
        {({ values, setFieldValue, isValid, setValues }) => {
          const strengths =
            values.type === TargetType.ABSOLUTE
              ? absoluteStrengths
              : intensityStrengths;
          const weaknesses =
            values.type === TargetType.ABSOLUTE
              ? absoluteWeaknesses
              : intensityWeaknesses;

          const description =
            values?.type === TargetType.ABSOLUTE
              ? 'This type of targets measures your reductions efforts against your total annual emissions'
              : 'Intensity goals take your emissions and divide them by a metric (i.e # of Employees) your carbon emissions as you grow';
          const isIntensityMetric = values?.type === TargetType.INTENSITY;
          return (
            <Form>
              <Card className="target-setting-strategy__baseline-info">
                <h6 className="text-bd">Baseline</h6>
                <p className=" text-12">
                  All of your targets will be measured against this year.
                </p>
                <StatisticGroup>
                  <BaseYearInfo />
                </StatisticGroup>
              </Card>
              <Card className="target-setting-strategy__target-form-card mt-6">
                <h6 className="text-bd">Target details</h6>
                <p className="mb-6 text-12">
                  We recommend that you choose a target 10 years after your base
                  year to ensure we address the immediate climate crisis.
                </p>

                <Row gutter={28}>
                  <Col span={8}>
                    <Select
                      loading={areTargetDetailsLoading}
                      title="Target Type"
                      name="type"
                      placeholder="Select"
                      setFieldValue={setFieldValue}
                      value={values?.type}
                      optionLabelProp="title"
                      dropdownMatchSelectWidth
                      className="target-setting-strategy__dropdown">
                      <AntSelect.OptGroup>
                        {targetTypeOptions.map((opt) => (
                          <AntSelect.Option
                            key={opt.value}
                            title={opt.label}
                            value={opt.value}
                            className="target-setting-strategy__type-option">
                            <div className="target-setting-strategy__type-option__header mb-2">
                              {opt.label}
                              {opt.value === TargetType.ABSOLUTE && (
                                <Tag color="green">Recommended</Tag>
                              )}
                            </div>
                            <p className="text-12 target-setting-strategy__type-option__content">
                              {opt.desc}
                            </p>
                          </AntSelect.Option>
                        ))}
                      </AntSelect.OptGroup>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      options={startYearOptions}
                      name="startYear"
                      value={values?.startYear}
                      title="Target commit year"
                      onChange={(startYear) => {
                        const newEndYearOptions =
                          getTargetYearDropdownOptions(startYear);
                        setEndYearOptions(newEndYearOptions);
                        const newEndYear =
                          newEndYearOptions.indexOf(values?.endYear) === -1
                            ? newEndYearOptions[5] && newEndYearOptions[5].value
                            : values?.endYear;
                        const durationYears = newEndYear - startYear;
                        setValues({
                          ...values,
                          durationYears,
                          startYear,
                          endYear: newEndYear
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      loading={areTargetDetailsLoading}
                      title="Target Year"
                      name="endYear"
                      placeholder="Select"
                      setFieldValue={setFieldValue}
                      value={values?.endYear ?? defaultEndYear}
                      optionLabelProp="title"
                      dropdownMatchSelectWidth
                      defaultValue={defaultEndYear}
                      className="target-setting-strategy__dropdown"
                      onChange={(value) => {
                        const startYearValue = values?.startYear
                          ? values?.startYear
                          : initialDate;
                        const durationYears = value - startYearValue;
                        setValues({ ...values, durationYears, endYear: value });
                      }}>
                      {endYearOptions.map((opt, i) => (
                        <AntSelect.Option
                          title={opt.label}
                          key={opt.value}
                          value={opt.value}
                          className="target-setting-strategy__type-option">
                          <div className="target-setting-strategy__type-option__header">
                            <span>{opt.label}</span>
                            {i === 9 && <Tag color="green">Recommended</Tag>}
                          </div>
                        </AntSelect.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                {isIntensityMetric && (
                  <>
                    <h6 className="text-bd mb-6 mt-6">
                      Set your intensity metric
                    </h6>
                    <IntensityFormRow />
                  </>
                )}
              </Card>
              {!!values.type && !!values?.endYear && (
                <Card className="target-setting-strategy__target-form-card mt-6">
                  <Row align="middle">
                    <Col className="mr-6" flex="45px">
                      <img
                        src={
                          values?.type === TargetType.INTENSITY
                            ? TargetChart
                            : mountain
                        }
                        alt={
                          values?.type === TargetType.INTENSITY
                            ? 'Target chart'
                            : 'mountain'
                        }
                      />
                    </Col>
                    <Col flex="auto">
                      <sup>Your selected target strategy</sup>
                      <h4 className="text-bd mb-0">
                        {capitalizeText(values?.type)}
                      </h4>
                      <Paragraph
                        size={12}
                        weight={400}
                        color={COLOURS_GRAY.GRAY_600}>
                        {description}
                      </Paragraph>
                    </Col>
                    <Col flex="121px">
                      <Statistic
                        className="text-center"
                        groupSeparator=""
                        title="Timeline"
                        value={`${values?.durationYears} years`}
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="middle" className="mt-2">
                    <Col
                      flex="100px"
                      className="target-setting-strategy__info-title text-sbd">
                      Strengths
                    </Col>
                    <Col flex="grow">
                      <Space wrap>
                        {strengths.map((s, i) => (
                          <Tag key={i} color="green">
                            {s}
                          </Tag>
                        ))}
                      </Space>
                    </Col>
                  </Row>
                  <Row justify="middle" className="mt-2">
                    <Col
                      flex="100px"
                      className="target-setting-strategy__info-title text-sbd">
                      Weaknesses
                    </Col>
                    <Col flex="grow">
                      <Space wrap>
                        {weaknesses.map((s, i) => (
                          <Tag key={i} color="red">
                            {s}
                          </Tag>
                        ))}
                      </Space>
                    </Col>
                  </Row>
                  <Row justify="middle" className="mt-2">
                    <Col
                      flex="auto"
                      className="target-setting-strategy__info-title text-sbd">
                      <a
                        href="https://carbonhound.freshdesk.com/support/home"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary"
                        onClick={() => {
                          analytics.track('Help clicked', me, {
                            level1: 'Office-Based Operations',
                            level2: 'Set Targets',
                            level3: 'Set Target Strategy',
                            targetDetails
                          });
                        }}>
                        Read more{' '}
                        <img src={externalLink} alt="external link icon" />
                      </a>
                    </Col>
                  </Row>
                </Card>
              )}

              <div className="target-setting-overview__actions mt-6 text-right">
                <Space size={16}>
                  <Link
                    to={
                      location.state?.from?.pathname ??
                      AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW
                    }
                    state={{ from: location }}>
                    <Button type={BUTTON_TYPES.SECONDARY}>Back</Button>
                  </Link>
                  <Button
                    htmlType="submit"
                    disabled={isFormSubmitting || !isValid}>
                    Next
                  </Button>
                </Space>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
