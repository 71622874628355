import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Steps as AntSteps } from 'antd';
import { Formik } from 'formik';
import produce from 'immer';
import { useEffect, useState } from 'react';
import emojiHappy from '../../assets/images/emoji-happy.svg';
import { UploadStatus } from '../../constants';
import { BulkUploadContextProvider, useBulkUploadContext } from '../../context';
import { DataInputContextProvider } from '../../context/DataImportContext';
import { Steps } from '../../stories/atoms';
import './BulkUploadForm.scss';
import Complete from './Complete';
import ImportType from './ImportType';
import MissingData from './MissingData';
import PrepareUpload from './PrepareUpload';
import ProcessUpload from './ProcessUpload';
import { StepContainer } from './StepContainer';
import Summary from './Summary';

const processingStates = [
  UploadStatus.PROCESSING,
  UploadStatus.READY_FOR_REVIEW
];

const getBulkUploadSteps = ({ status, isTypePreSelected = false }) => {
  const showProcessingView = processingStates.indexOf(status) > -1;
  return [
    ...(!isTypePreSelected
      ? [
          {
            title: 'Type',
            content: (
              <ImportType
                contentTitle="Select type of activity you are uploading"
                contentDescription="You can only upload one activity type at a time"
              />
            ),
            contentTitle: 'Select type of activity you are uploading',
            contentDescription:
              'You can only upload one activity type at a time'
          }
        ]
      : []),
    {
      title: 'Prepare & upload',
      content: showProcessingView ? <ProcessUpload /> : <PrepareUpload />,
      contentTitle: showProcessingView
        ? 'Processing your file'
        : 'Prepare your upload',
      contentDescription: showProcessingView
        ? 'This could take up to 10 minutes, please feel free to navigate away, we will notify you when the upload is complete.'
        : 'File type must be .csv'
    },
    {
      title: 'Summary',
      content: <Summary />,
      contentTitle: 'We’ve processed your file!',
      contentDescription: 'Here’s the summary'
    },
    {
      title: 'Missing data',
      content: <MissingData />,
      contentTitle: 'We’ve processed your file!',
      contentDescription: 'Here’s the summary'
    },
    {
      title: 'Complete',
      content: <Complete />,
      contentTitle: 'Data upload complete!',
      contentDescription:
        'All items have been resolved and your data has been processed.',
      contentIcon: <CheckOutlined />
    }
  ];
};

const { Step } = AntSteps;
function StepsDot(iconDot, { status, index }) {
  if (status === 'wait') return null;
  if (status === 'finish')
    return index < 4 ? (
      <CheckOutlined />
    ) : (
      <img src={emojiHappy} alt="Happy face emoji" />
    );
  // Adding one because arrays start at 0, but human numbers 1
  if (status === 'process') return index + 1;
  if (status === 'error') return <CloseOutlined />;
}

function BulkUploadFormContextContainer({
  internalSpec,
  notificationReqParams,
  notification,
  handleCloseBulkUploadsModal,
  companySlug,
  onSuccess
}) {
  return (
    <DataInputContextProvider>
      <BulkUploadContextProvider
        handleCloseBulkUploadsModal={handleCloseBulkUploadsModal}
        notification={notification}
        notificationReqParams={notificationReqParams}
        internalSpec={internalSpec}
        onSuccess={onSuccess}
        companySlug={companySlug}
      >
        <BulkUploadFormContent />
      </BulkUploadContextProvider>
    </DataInputContextProvider>
  );
}

function BulkUploadFormContent() {
  const { bulkUploadState, setStep } = useBulkUploadContext();
  const { activeStep, type, notification, notificationReqParams } =
    bulkUploadState;
  const { emissionSourceDetailsId } = notificationReqParams;
  const [formValues, setFormValues] = useState({
    uploadType: notification?.details?.uploadType,
    collectionId: notification?.details?.collectionId,
    file: undefined,
    address1: ''
  });

  const isTypePreSelected = !!emissionSourceDetailsId || !!type;

  const [uploadSteps, setUploadSteps] = useState(
    getBulkUploadSteps({
      status: bulkUploadState?.notification.status,
      isTypePreSelected
    })
  );

  useEffect(() => {
    setUploadSteps(
      getBulkUploadSteps({
        status: bulkUploadState?.notification.status,
        isTypePreSelected
      })
    );
  }, [bulkUploadState?.notification.status]);

  useEffect(() => {
    if (isTypePreSelected) {
      setStep({ activeStep: 0 });
    } else if (notification.status === UploadStatus.ERROR) {
      setStep({ activeStep: 1 });
    } else if (
      notification.status === UploadStatus.PROCESSING ||
      notification.status === UploadStatus.PENDING
    ) {
      setStep({ activeStep: 1 });
    } else if (notification.status === UploadStatus.READY_FOR_REVIEW) {
      setStep({ activeStep: 2 });
    } else if (notification.status === UploadStatus.COMPLETE) {
      setStep({ activeStep: 4 });
    } else {
      setStep({ activeStep: 0 });
    }
  }, []);

  useEffect(() => {
    setFormValues((prevState) =>
      produce(prevState, (draft) => {
        draft.type = type;
      })
    );
  }, [type]);

  return (
    <div className="bulk-upload-form">
      <Steps labelPlacement="vertical" size="small" current={activeStep}>
        {uploadSteps.map((item) => (
          <Step
            key={item.title}
            title={<span className="no-wrap">{item.title}</span>}
            progressDot={StepsDot}
          />
        ))}
      </Steps>
      <Formik initialValues={formValues}>
        <StepContainer
          title={uploadSteps[activeStep]?.contentTitle}
          description={uploadSteps[activeStep]?.contentDescription}
          icon={uploadSteps[activeStep]?.contentIcon}
          activeStep={activeStep}
        >
          {uploadSteps[activeStep]?.content}
        </StepContainer>
      </Formik>
    </div>
  );
}
export default BulkUploadFormContextContainer;
