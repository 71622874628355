import { CARBONHOUND_CONNECT_TYPES_BUTTON_COPY } from '../../../../constants';
import { AppRoutes } from '../../../../routes';
import { Anchor, Button, Link } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';

export function IntegrationConnectCardFooter({
  isContactUsNowLinkVisible,
  isConnectionActive,
  item,
  handleShowModal
}) {
  const { urlSlug, slug } = item;
  if (isContactUsNowLinkVisible) {
    return (
      <Button
        type={BUTTON_TYPES.SECONDARY}
        className="integration-connect-card__button"
      >
        <Anchor
          href="https://meetings.hubspot.com/carbonhound-erin/carbonhound-supply-chain-emissions"
          showLinkIcon={false}
        >
          Contact our team
        </Anchor>
      </Button>
    );
  }
  if (isConnectionActive) {
    return (
      <Link
        fontWeight={500}
        fontSize={16}
        to={AppRoutes.INTEGRATION_DETAIL.replace(
          ':parentIntegrationSlug/:integrationSlug',
          urlSlug
        )}
        className="integration-connect-card__button"
      >
        Connection Settings
      </Link>
    );
  }
  return (
    <Button
      className="integration-connect-card__button"
      type={BUTTON_TYPES.SECONDARY}
      onClick={handleShowModal}
    >
      {CARBONHOUND_CONNECT_TYPES_BUTTON_COPY[slug]}
    </Button>
  );
}
