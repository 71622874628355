import { Col, Row, Space } from 'antd';
import * as dateFns from 'date-fns';
import { useCallback } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { useLocation, useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../../../components';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import { useInitiativeContext } from '../../../../context/InitiativeContext';
import { analytics } from '../../../../helpers';
import { useAsync, usePagination } from '../../../../hooks';
import {
  COMPANY_INITIATIVE_STEPS,
  COMPANY_INITIATIVE_STEPS_LABEL,
  COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS
} from '../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import {
  getIsSetupComplete,
  getNextStep
} from '../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.generators';
import { AppRoutes } from '../../../../routes';
import { ColumnTitle, Icon, Paragraph, Table } from '../../../../stories/atoms';
import './CompanyInitiativeList.scss';
import { CompanyInitiativeListEmptyState } from './CompanyInitiativeListEmptyState';
import { InitiativeTemplateCompanyInitiativeListEmptyState } from './InitiativeTemplateCompanyInitiativeListEmptyState';

function NextStepRender(trailOverview, initiative) {
  let nextCopy;
  const isSetupComplete = getIsSetupComplete(trailOverview);
  if (!trailOverview) {
    nextCopy = 'Not Started';
  } else if (initiative?.completeAt) {
    nextCopy = 'Completed';
  } else if (isSetupComplete) {
    nextCopy = 'Complete Actions';
  } else {
    const segmentName = getNextStep(trailOverview);
    nextCopy = COMPANY_INITIATIVE_STEPS_LABEL[segmentName];
  }

  return nextCopy;
}

export function CompanyInitiativeList({
  isComplete,
  analyticsContext,
  showCard
}) {
  const { handleLoadInitiativeList, initiativeState } = useInitiativeContext();
  const navigate = useNavigate();
  const { initiativeList, totalInitiativeCount } = initiativeState;
  const location = useLocation();
  const { initiativeTemplateId } = useParams();
  const { me, isAdmin } = useMeContext();

  const { paginationParams } = usePagination({
    pageSize: 5,
    order: 'ASC',
    paginationArray: initiativeList,
    orderNulls: { completeAt: 'FIRST' },
    orderBy: ['completeAt', 'endDate', 'id']
  });

  const handleRowAnalyticsClick = (initiative) => {
    const { initiativeTemplate } = initiative;
    analytics.track('Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: initiative.id
    });
  };
  const handleLinkToNextStep = (initiative) => {
    const { trailOverview } = initiative;
    const isSetupComplete = getIsSetupComplete(trailOverview);
    const nextStep = isSetupComplete
      ? COMPANY_INITIATIVE_STEPS.OVERVIEW
      : trailOverview?.findLast((trail) => trail.isStarted && !trail.isComplete)
          ?.segmentName ||
        trailOverview?.[trailOverview?.length - 1]?.segmentName;
    handleRowAnalyticsClick(initiative);
    navigate(
      `${COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS[
        nextStep ?? COMPANY_INITIATIVE_STEPS.OVERVIEW
      ].replace(':companyInitiativeId', initiative.id)}`,
      { state: { prevRoute: location.pathname } }
    );
  };
  const handleLinkToOverview = (initiative) => {
    handleRowAnalyticsClick(initiative);
    navigate(
      AppRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        initiative.id
      )
    );
  };

  const columns = [
    {
      title: <ColumnTitle>Name</ColumnTitle>,
      onCell: (initiative) => ({
        onClick: () => {
          if (isAdmin) handleLinkToOverview(initiative);
        }
      }),
      dataIndex: 'name',
      width: '60%',
      render: (name) => <b>{name}</b>
    },
    {
      title: <ColumnTitle>Open Tasks</ColumnTitle>,
      onCell: (initiative) => ({
        onClick: () => {
          if (isAdmin) handleLinkToOverview(initiative);
        }
      }),
      dataIndex: 'openActionsCount',
      render: (count) => count || 0
    },
    {
      title: <ColumnTitle>End Date</ColumnTitle>,
      dataIndex: 'endDate',
      onCell: (initiative) => ({
        onClick: () => {
          if (isAdmin) handleLinkToOverview(initiative);
        }
      }),
      render: (endDate) => {
        const endOfToday = dateFns.endOfToday();
        endDate = new Date(endDate);
        const date = formatInTimeZone(new Date(endDate), 'UTC', 'MMM dd, yyyy');
        if (endDate > endOfToday) {
          return date;
        }
        return (
          <Paragraph
            color={COLOURS_CARBONHOUND.PRIMARY_RED}
            bottomSpacing={0}
            weight={500}
          >
            {date}
          </Paragraph>
        );
      }
    },
    {
      onCell: (initiative) => ({
        onClick: () => {
          if (isAdmin) handleLinkToNextStep(initiative);
        }
      }),
      title: 'Next Step',
      dataIndex: 'trailOverview',
      render: NextStepRender
    }
  ];
  const loadInitiativeList = useCallback(async () => {
    await handleLoadInitiativeList({
      pagination: paginationParams,
      initiativeTemplateId,
      ...(typeof isComplete === 'boolean' && { isComplete })
    });
  }, [initiativeTemplateId, isComplete, paginationParams.pageNumber]);

  const { _Loader } = useAsync({
    asyncFunction: loadInitiativeList
  });

  if (!initiativeList?.length && initiativeTemplateId) {
    return (
      <InitiativeTemplateCompanyInitiativeListEmptyState
        analyticsContext={analyticsContext}
      />
    );
  }
  if (!initiativeList?.length) {
    return <CompanyInitiativeListEmptyState showCard={showCard} />;
  }
  return showCard ? (
    <Card className="company-initiative-list p-0">
      <div>
        {initiativeList.map((initiative) => {
          const NextStep = NextStepRender(initiative.trailOverview, initiative);
          const row = (
            <>
              <div className="company-initiative-list__link--left">
                {initiative.name}
              </div>
              <div className="company-initiative-list__link--right">
                <Space>
                  {`Next step: ${NextStep}`}
                  {isAdmin ? (
                    <Icon
                      name="ChevronRight"
                      size={24}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                    />
                  ) : null}
                </Space>
              </div>
            </>
          );

          return isAdmin ? (
            <Link
              to={AppRoutes.COMPANY_INITIATIVE.replace(
                ':companyInitiativeId',
                initiative.id
              )}
              className="company-initiative-list__link"
              children={row}
            />
          ) : (
            <Row className="company-initiative-list__link" children={row} />
          );
        })}
      </div>
    </Card>
  ) : (
    <Row className="company-initiative-list">
      <Col span={24}>
        <Table
          rowKey="id"
          isHeaderBorder
          dataSource={initiativeList}
          columns={columns}
          total={totalInitiativeCount}
          showPagination
          pagination={paginationParams}
          onRow={() => ({
            className: isAdmin ? 'cursor-pointer' : ''
          })}
        />
      </Col>
    </Row>
  );
}
