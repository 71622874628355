import { Col, Row } from 'antd';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { Img, List, Paragraph, Title } from '../../../stories/atoms';
import ModalFooter from '../../../stories/atoms/ModalFooter';
import './CarbonhoundConnectDetails.scss';

export function CarbonConnectDetails({
  handleNext,
  analyticsContext,
  integration
}) {
  const { me } = useMeContext();
  const { displayName, description, logoUrl } = integration ?? {};
  return (
    <>
      <Row className="carbonhound-connect-details mb-10" gutter={[0, 40]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="blurb-bg-gray ">
                <Row gutter={24} align="middle">
                  <Col>
                    <Img src={logoUrl} />
                  </Col>
                  <Col>
                    <Title bottomSpacing={0}>{displayName}</Title>
                    <Paragraph bottomSpacing={0}>{description}</Paragraph>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12} className="display-flex">
              <div className="blurb-bg-gray ">
                <Title bottomSpacing={12}>How it works</Title>
                <List type="ol">
                  <li>Select your provider</li>
                  <li>Enter your login details</li>
                  <li>Set your import settings</li>
                  <li>
                    You will be notified about billing and data connection
                  </li>
                </List>
              </div>
            </Col>
            <Col span={12} className="display-flex">
              <div className="blurb-bg-gray ">
                <Title bottomSpacing={12}>Pricing</Title>
                <Paragraph bottomSpacing={0}>
                  We will contact you to process payment before beginning data
                  import.
                </Paragraph>
                <List type="ul" className="text-bd">
                  <li>1 credit per data point</li>
                  <li>$5 per credit</li>
                </List>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalFooter
        showHelpLink={false}
        showCancelButton={false}
        saveButtonCopy="Continue"
        saveButtonProps={{
          onClick: () => {
            analytics.track('Step Completed', me, {
              ...analyticsContext,
              level3: 'Details'
            });
            handleNext();
          }
        }}
      />
    </>
  );
}
