export const CARBONHOUND_CONNECT_TYPES = {
  UTILITY: 'utility',
  WASTE: 'waste',
  TRAVEL: 'travel',
  VALUE_CHAIN: 'value-chain',
  WATER: 'water',
  CUSTOM: 'custom'
};

export const CARBONHOUND_CONNECT_TYPES_BUTTON_COPY = {
  [CARBONHOUND_CONNECT_TYPES.UTILITY]: 'Connect',
  [CARBONHOUND_CONNECT_TYPES.WASTE]: 'Connect',
  [CARBONHOUND_CONNECT_TYPES.TRAVEL]: 'Connect',
  [CARBONHOUND_CONNECT_TYPES.VALUE_CHAIN]: 'Contact our team',
  [CARBONHOUND_CONNECT_TYPES.CUSTOM]: 'Connect',
  [CARBONHOUND_CONNECT_TYPES.WATER]: 'Connect'
};
