import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useMeContext } from './MeContext';
import { CollectionService, EmissionSourceService } from '../services';
import { getDefaultYearForChart } from '../helpers/generators';
import { useAsync } from '../hooks';

const collectionContext = createContext({});

const collectionInitialState = {};

function useCollectionContext() {
  const context = useContext(collectionContext);

  const { me } = useMeContext();

  const [collectionState, setCollectionState] = context;

  const getCollectionId = (collectionId) => {
    setCollectionState((prevState) => ({
      ...prevState,
      collectionId
    }));
  };

  const setSelectedDateRange = (values) =>
    setCollectionState((prevState) => ({
      ...prevState,
      selectedDateRange: {
        rangeStart: values.rangeStart,
        rangeEnd: values.rangeEnd
      }
    }));

  const setCollectionDetails = (value) =>
    setCollectionState((prevState) => ({
      ...prevState,
      collectionDetails: { ...value }
    }));

  const fetchCollectionChart = useCallback(async () => {
    const { collectionId } = collectionState;

    if (!!collectionId && collectionId.length > 0) {
      const response =
        await EmissionSourceService.getEmissionSourceLocationDetails({
          companySlug: me?.company?.slug,
          collectionId,
          rangeStart: collectionState?.selectedDateRange?.rangeStart,
          rangeEnd: collectionState?.selectedDateRange?.rangeEnd,
          aggregateType: 'CATEGORY'
        });

      setCollectionState((prevState) => ({
        ...prevState,
        chartData: {
          ...response
        }
      }));

      return response;
    }
  }, [
    me?.company.slug,
    collectionState?.collectionId,
    collectionState?.selectedDateRange?.rangeStart,
    collectionState?.selectedDateRange?.rangeEnd
  ]);

  const {
    Loader: ChartLoader,
    isLoading: isChartLoading,
    execute: fetchChart
  } = useAsync({
    asyncFunction: fetchCollectionChart
  });

  const fetchCollectionTable = useCallback(async () => {
    const { collectionId } = collectionState;

    if (!!collectionId && collectionId.length > 0) {
      const collectionResponse = await CollectionService.getCollection({
        collectionId
      });
      const emissionResponse =
        await EmissionSourceService.listEmissionSourceDetails({
          companySlug: me?.company?.slug,
          collectionIds: [collectionId]
        });
      const response = {
        ...collectionResponse,
        emissionSources: [...emissionResponse.list]
      };
      setCollectionState((prevState) => ({
        ...prevState,
        collectionDetails: {
          ...response
        }
      }));
    }
  }, [collectionState?.collectionId]);

  const {
    Loader: TableLoader,
    isLoading: isTableLoading,
    execute: fetchTable
  } = useAsync({
    asyncFunction: fetchCollectionTable
  });

  return {
    collectionState,
    getCollectionId,
    fetchChart,
    fetchTable,
    ChartLoader,
    isChartLoading,
    TableLoader,
    isTableLoading,
    setSelectedDateRange,
    setCollectionDetails
  };
}

function CollectionContextProvider({ children, ...props }) {
  const { me, selectCarbonYearRange } = useMeContext();
  const initialDataState = {
    ...collectionInitialState,
    selectedDateRange: {
      ...selectCarbonYearRange({
        activeYear: getDefaultYearForChart({
          startingMonth: me.company?.startingMonth
        })
      })
    }
  };
  const [collectionState, setCollectionState] = useState(initialDataState);
  const value = useMemo(
    () => [collectionState, setCollectionState],
    [collectionState]
  );
  return (
    <collectionContext.Provider value={value} {...props}>
      {children}
    </collectionContext.Provider>
  );
}

export { CollectionContextProvider, useCollectionContext };
