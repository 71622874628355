import { Layout } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  CompanyInitiativeLayoutContextProvider,
  TargetContextProvider,
  useCompanyInitiativeLayoutContext,
  useInitiativeContext,
  useMeContext,
  useTargetContext
} from '../../../../context';
import {
  CompanyInitiativeFooter,
  CompanyInitiativeHeader,
  CompanyInitiativeSidebar
} from '../../../../features/Initiative/CompanyInitiative';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { analytics } from '../../../../helpers';
import {
  COMPANY_INITIATIVE_STEPS,
  CompanyInitiativeForecastingSteps,
  CompanyInitiativePlanningSteps,
  CompanyInitiativeStepsTuple
} from '../CompanyInitiative.constants';
import './CompanyInitiativeLayout.scss';

function InitiativeDetailLayoutContent() {
  const navigate = useNavigate();
  const { updateTarget, targetState } = useTargetContext();
  const { targetDetailId, targetDetail } = targetState;
  const { percentReduction } = targetDetail;
  const { nextStep, handleLoadCompanyTrailOverviewList } =
    useCompanyInitiativeLayoutContext();
  const [formContext, setFormContext] = useState({
    initialValues: {},
    handleSubmit: async () => {
      nextStep();
    }
  });

  const { handleLoadInitiativeDetail, initiativeState } =
    useInitiativeContext();
  const { companyInitiativeId, companyInitiative } = initiativeState;
  const {
    companyInitiativeLayoutState,
    handleCreateOrUpdateCompanyTrailOverview
  } = useCompanyInitiativeLayoutContext();
  const { currentStep, completedSteps, startedSteps } =
    companyInitiativeLayoutState;

  const { initiativeTemplate } = companyInitiative;
  const { me } = useMeContext();
  const { Header, Footer, Sider, Content } = Layout;

  const { Loader } = useAsync({
    asyncFunction: handleLoadInitiativeDetail,
    defaultValue: {}
  });

  const handleLoadTrailForInitiatives = useCallback(async () => {
    if (companyInitiativeId)
      await handleLoadCompanyTrailOverviewList({ companyInitiativeId });
  }, [companyInitiativeId]);
  useAsync({
    asyncFunction: handleLoadTrailForInitiatives,
    defaultValue: {}
  });

  const handleEditTrail = useCallback(
    async (isComplete) => {
      if (!!currentStep && currentStep !== COMPANY_INITIATIVE_STEPS.OVERVIEW) {
        const isForecastingStep =
          CompanyInitiativeForecastingSteps.indexOf(currentStep) > -1;
        const isPlanningStep =
          CompanyInitiativePlanningSteps.indexOf(currentStep) > -1;
        await handleCreateOrUpdateCompanyTrailOverview({
          companyInitiativeId,
          segmentName: currentStep,
          trailName: isForecastingStep
            ? COMPANY_INITIATIVE_STEPS.FORECASTING
            : isPlanningStep
            ? COMPANY_INITIATIVE_STEPS.PLANNING
            : currentStep,
          isStarted: true,
          isComplete
        });
      }
    },
    [companyInitiativeId, currentStep]
  );

  const { execute: editTrail } = useAsync({
    asyncFunction: handleEditTrail,
    immediate: false
  });
  const nextStepAnalyticsContext = (analyticsStepContext) => {
    analytics.track('Step Completed', me, {
      ...analyticsStepContext,
      level1: 'Initiatives',
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
  };
  useEffect(() => {
    switch (currentStep) {
      case COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS:
        setFormContext({
          initialValues: {},
          handleSubmit: async () => {
            nextStepAnalyticsContext({ level2: 'Recommended Action' });
            await editTrail(true);
            navigate(AppRoutes.ACTIONS);
          }
        });
        break;
      case COMPANY_INITIATIVE_STEPS.TARGET_BASELINE:
        setFormContext({
          initialValues: {
            percentReduction
          },
          handleSubmit: async (params) => {
            nextStepAnalyticsContext({
              level2: 'Forecasting',
              level3: 'Baseline and Targets'
            });
            const isStepCompleted = completedSteps?.indexOf(currentStep) > -1;
            if (targetDetailId) {
              await updateTarget({
                id: targetDetailId,
                isCommitted: true,
                ...params
              });
            }
            if (!isStepCompleted) {
              await editTrail(true);
            }
            nextStep();
          }
        });
        break;
      default:
        let analyticsContext = {};
        switch (currentStep) {
          case COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES:
            analyticsContext = {
              level2: 'Forecasting',
              level3: 'Impacted Source'
            };
            break;
          case COMPANY_INITIATIVE_STEPS.FUNDING:
            analyticsContext = {
              level2: 'Planning',
              level3: 'Funding'
            };
            break;
          case COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN:
            analyticsContext = {
              level2: 'Planning',
              level3: 'Business Plan'
            };
            break;
          default:
            analyticsContext = {};
            break;
        }
        setFormContext({
          initialValues: {},
          handleSubmit: async () => {
            const isStepCompleted = completedSteps?.indexOf(currentStep) > -1;
            nextStepAnalyticsContext(analyticsContext);
            if (!isStepCompleted) {
              await editTrail(true);
            }
            nextStep();
          }
        });
        break;
    }
  }, [currentStep, targetDetailId, percentReduction, completedSteps]);

  const { isLoading: isFormSubmitting, execute: handleSubmit } = useAsync({
    asyncFunction: formContext.handleSubmit,
    immediate: false
  });

  useEffect(() => {
    if (startedSteps?.indexOf(currentStep) === -1) {
      editTrail();
    }
  }, [startedSteps, currentStep]);

  return (
    <Layout className="company-initiative-layout">
      <Formik
        enableReinitialize
        initialValues={formContext.initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <Layout>
            <Sider theme="light" width={386}>
              <Loader>
                <CompanyInitiativeSidebar />
              </Loader>
            </Sider>
            <Layout>
              <Header theme="light">
                <Loader>
                  <CompanyInitiativeHeader />
                </Loader>
              </Header>
              <Content className="company-initiative-layout__content">
                <Outlet />
              </Content>
            </Layout>
          </Layout>
          {currentStep !== CompanyInitiativeStepsTuple[0] && (
            <Footer>
              <CompanyInitiativeFooter
                editTrail={editTrail}
                nextStepAnalyticsContext={nextStepAnalyticsContext}
                isFormSubmitting={isFormSubmitting}
              />
            </Footer>
          )}
        </Form>
      </Formik>
    </Layout>
  );
}

export function InitiativeDetailLayout() {
  return (
    <CompanyInitiativeLayoutContextProvider>
      <TargetContextProvider>
        <InitiativeDetailLayoutContent />
      </TargetContextProvider>
    </CompanyInitiativeLayoutContextProvider>
  );
}
