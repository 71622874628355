import { Col, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import {
  NotificationCategory,
  NotificationStatus
} from '../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useInitiativeActionContext, useMeContext } from '../../../../context';
import { useAsync } from '../../../../hooks';
import { analytics } from '../../../../helpers';
import { Button, Icon, Paragraph } from '../../../../stories/atoms';

export function InitiativeActionFooter({
  onSuccess,
  onClose,
  analyticsContext
}) {
  const {
    initiativeActionState,
    handleEditNotification,
    handleCreateNotification
  } = useInitiativeActionContext();
  const { initiativeAction, initiativeTemplate, companyInitiative } =
    initiativeActionState;
  const { me } = useMeContext();

  const isComplete = initiativeAction.status === NotificationStatus.COMPLETE;
  const handleCompleteNotification = async () => {
    analytics.track('Action Completed', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id,
      notificationId: initiativeAction.id
    });
    await handleEditNotification({
      id: initiativeAction.id,
      status: NotificationStatus.COMPLETE
    });
    onSuccess?.();
    onClose?.();
  };
  const { execute: editNotification } = useAsync({
    asyncFunction: handleCompleteNotification,
    immediate: false
  });

  const handleCreateNotificationCallback = async () => {
    analytics.track('Add Action to Initiative', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id,
      notificationId: initiativeAction.id
    });
    await handleCreateNotification({
      ...(initiativeTemplate?.details ?? {}),
      title: initiativeTemplate.title,
      message: initiativeTemplate.message,
      notes: initiativeTemplate.notes,
      category: NotificationCategory.INITIATIVE,
      companySlug: me?.company?.slug,
      stepName: initiativeTemplate.stepName,
      companyInitiativeId: companyInitiative.id,
      notificationTemplateId: initiativeTemplate.id
    });
    onSuccess?.();
  };
  const { execute: handleCommitToTarget } = useAsync({
    asyncFunction: handleCreateNotificationCallback,
    immediate: false
  });
  const footerButtonProps = initiativeAction.id
    ? {
        onClick: editNotification,
        children: 'Mark as Complete'
      }
    : {
        onClick: handleCommitToTarget,
        children: 'Add to Project'
      };
  return (
    <Row justify="end">
      {!initiativeAction.id && (
        <Col flex={1} className="display-flex align-center">
          <Space size={12}>
            <Icon
              color={COLOURS_CARBONHOUND.PRIMARY_ORANGE}
              name="Exclamation"
            />
            <Paragraph bottomSpacing={0}>
              This action hasn't been added to the project yet, once it has been
              added you can edit all the fields
            </Paragraph>
          </Space>
        </Col>
      )}
      <Col>
        {isComplete ? (
          <Paragraph bottomSpacing={0}>
            Completed{' '}
            {!!initiativeAction.updatedAt &&
              formatInTimeZone(
                initiativeAction.updatedAt,
                'UTC',
                'MMM dd, yyyy'
              )}
          </Paragraph>
        ) : (
          <Button {...footerButtonProps} />
        )}
      </Col>
    </Row>
  );
}
