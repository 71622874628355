import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import {
  useCompanyInitiativeLayoutContext,
  useInitiativeContext,
  useMeContext
} from '../../../../context';
import { analytics } from '../../../../helpers';
import { Button, Drawer, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { CompanyInitiativeCustomActionForm } from '../../CompanyInitiative';
import { InitiativeTemplateRelatedActionsTable } from './InitiativeTemplateRelatedActionsTable';

export function InitiativeTemplateRelatedActions({ analyticsContext }) {
  const [showCustomActionModal, setShowCustomActionModal] = useState(false);
  const { handleLoadCompanyInitiativeRelatedActions } = useInitiativeContext();
  const { companyInitiativeLayoutState } = useCompanyInitiativeLayoutContext();
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative, initiativeTemplate } = initiativeState;
  const { me } = useMeContext();
  const { currentStep } = companyInitiativeLayoutState;
  const handleCreateRelatedActionSuccess = () => {
    setShowCustomActionModal(false);
    handleLoadCompanyInitiativeRelatedActions({ stepName: currentStep });
  };

  const handleCreateCustomActions = () => {
    setShowCustomActionModal(true);
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
  };
  return (
    <Row gutter={[16, 20]} className="company-initiative-header">
      <Col span={24}>
        <Row align="middle">
          <Col flex="1">
            <Title bottomSpacing={0}>Related actions</Title>
          </Col>
          <Col>
            <Space size={32}>
              <Button
                onClick={handleCreateCustomActions}
                type={BUTTON_TYPES.SECONDARY}
              >
                Create Custom
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <InitiativeTemplateRelatedActionsTable
          analyticsContext={analyticsContext}
        />
      </Col>
      <Drawer
        showHeader={false}
        width="30%"
        destroyOnClose
        visible={showCustomActionModal}
        onClose={() => setShowCustomActionModal(false)}
      >
        <CompanyInitiativeCustomActionForm
          analyticsContext={analyticsContext}
          onSuccess={handleCreateRelatedActionSuccess}
        />
      </Drawer>
    </Row>
  );
}
