import * as Yup from 'yup';

export const setTargetSettingStrategyValidation = Yup.lazy((values) =>
  Yup.object().shape({
    [values?.baseYear]: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .typeError('Please enter a valid number')
          .min(1, 'Value must be above 0')
          .required('Please enter a value')
      })
    )
  })
);
