import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Checkbox,
  GraphicFormCard,
  InputField,
  Notification
} from '../../../../components';
import { useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { AppRoutes } from '../../../../routes';
import { AuthService, MeService } from '../../../../services';
import gusGlasses from '../../../../stories/assets/svgs/Gus/gusGlasses.svg';
import { Button, Link, Logo, Paragraph } from '../../../../stories/atoms';
import './createAccount.scss';
import { createAccountFormValidation } from './createAccountFormValidation';

function CreateAccount() {
  const navigate = useNavigate();

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const [formLoading, setFormLoading] = useState(false);

  const [verificationCode, setVerificationCode] = useState();
  const { updateMe, me, setJWTToken, isGroupSettingsMember } = useMeContext();
  const [formValues, setFormValues] = useState({});
  const [email, setEmail] = useState('');
  const handleSubmit = (values) => {
    delete values.email;
    setFormLoading(true);
    AuthService.resetPassword(
      {
        ...values,
        verificationCode
      },
      ({ token }) => {
        analytics.track('Set Password completed', me, {
          level1: 'Create Account',
          level2: 'Signup',
          level3: 'Create Password'
        });
        Notification({
          type: 'success',
          message: 'Your account',
          description: 'Your account has been created!'
        });
        const origin = query.get('origin');
        if (origin === 'commute') {
          navigate({
            pathname: AppRoutes.LOGIN,
            search: `?${new URLSearchParams({
              origin
            })}`
          });
          return;
        }
        MeService.getMyDetails((me) => {
          setJWTToken(token);
          updateMe(me);
          if (me.company?.slug) {
            navigate({
              pathname: AppRoutes.DASHBOARD
            });
          } else {
            navigate({
              pathname: AppRoutes.COMPANY_INFORMATION,
              search: !!origin
                ? `?${new URLSearchParams({
                    origin
                  }).toString()}`
                : ''
            });
          }
        });
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    const verificationCode = query.get('code');
    setVerificationCode(verificationCode);
    if (verificationCode) {
      AuthService.validateEmail(
        verificationCode,
        (data) => {
          setEmail(data.email);

          analytics.track('Verify Email Link Clicked', null, {
            email: data.email,
            level1: 'Create Account',
            level2: 'Signup',
            level3: 'Verify email'
          });
          if (data.isVerified) {
            Notification({
              type: 'success',
              message: 'Email verification',
              description: 'Email already verified. Please login to continue'
            });
            navigate(AppRoutes.LOGIN);
          }
        },
        () => {},
        () => {}
      );
    }
  }, []);

  useEffect(() => {
    const { firstName, lastName } = me;
    setFormValues({
      firstName,
      lastName,
      isAcceptTermsAndConditions: false
    });
  }, [me]);

  return (
    <div className="create-account">
      {!isGroupSettingsMember && <Logo className="create-account__logo" />}
      <GraphicFormCard imgSrc={gusGlasses}>
        <h3 className="text-bd">Let's create your account.</h3>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={createAccountFormValidation}
        >
          {({ values, _errors, isValid, _dirty, setFieldValue }) => (
            <Form>
              <Row>
                <Col span={24}>
                  <InputField
                    readOnly
                    name="email"
                    value={email}
                    title="Email Address"
                    type="email"
                    placeholder="Enter email"
                    required
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    title="First name"
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                    required
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    title="Last name"
                    type="text"
                    name="lastName"
                    placeholder="Enter Last name"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    title="Password"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    required
                  />
                  {values?.password?.length > 10 && (
                    <div className="create-account__password-note">
                      {values?.password?.length < 13
                        ? 'Password is okay'
                        : 'Password is strong'}
                    </div>
                  )}
                </Col>
                <Col span={24}>
                  <InputField
                    title="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Enter Password"
                    required
                  />
                </Col>
                <Col span={24}>
                  <Checkbox
                    className="sign-up-checkbox"
                    checked={values?.isAcceptTermsAndConditions}
                    name="isAcceptTermsAndConditions"
                    onChange={(event) => {
                      setFieldValue(
                        'isAcceptTermsAndConditions',
                        event.target.checked
                      );
                    }}
                  >
                    <Paragraph
                      size="rg"
                      bottomSpacing={0}
                      className="display-flex"
                    >
                      I agree to the{' '}
                      <Link
                        to={{
                          pathname: '//carbonhound.com/terms-and-conditions/'
                        }}
                        target="_blank"
                        fontSize={16}
                        fontWeight={600}
                      >
                        Terms & Conditions
                      </Link>
                    </Paragraph>
                  </Checkbox>
                </Col>
                <Col
                  span={24}
                  className="create-account__submit text-center display-flex justify-end"
                >
                  <Button
                    fullWidth
                    htmlType="submit"
                    disabled={formLoading || !isValid}
                    loading={formLoading}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </GraphicFormCard>
    </div>
  );
}

export default CreateAccount;
