import { useBulkUploadContext } from '../../../context';
import { Table } from '../../../stories/atoms';
import './ErrorList.scss';

function ErrorList() {
  const { bulkUploadState } = useBulkUploadContext();
  return (
    <div className="bulk-upload-form-error-list">
      <Table
        scroll={{ y: 406 }}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-dark' : 'table-row-light'
        }
        rowKey="line"
        dataSource={bulkUploadState?.notification.details?.rowErrors}
        secondary
        columns={[
          { title: 'Line', dataIndex: 'rowNumber' },
          { title: 'Content', dataIndex: 'message' }
        ]}
      />
    </div>
  );
}
export default ErrorList;
