import React from 'react';
import './error.scss';
import PropTypes from 'prop-types';

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
};

function ErrorMessage({ message, className }) {
  return <div className={`input__error ${className}`}>{message}</div>;
}

export default ErrorMessage;
