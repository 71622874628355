import { Col, Divider, Row } from 'antd';
import { useCallback } from 'react';
import { Card } from '../../../components';
import { useMeContext } from '../../../context';
import { copyToClipboard } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { IntegrationService } from '../../../services';
import { Anchor, Button, Paragraph, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { IntegrationConnectCard } from '../IntegrationConnectCard';
import './IntegrationList.scss';

export function IntegrationList() {
  const { me } = useMeContext();
  const { company } = me;
  const { slug: companySlug } = company ?? {};
  const loadCompanyIntegrations = useCallback(async () => {
    const response = await IntegrationService.listCompanyIntegrations({
      companySlug: me.company?.slug,
      parentIntegrationSlug: 'carbonhound-connect'
    });
    return response?.list ?? [];
  }, []);

  const {
    value: integrationList,
    Loader: IntegrationLoader,
    execute: refreshList
  } = useAsync({
    asyncFunction: loadCompanyIntegrations,
    defaultValue: []
  });
  return (
    <Row gutter={[32, 32]} className="integration-list">
      <Col span={24}>
        <Title size="xl" bottomSpacing={0}>
          Data Concierge
        </Title>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <Row wrap>
            <Col span={24}>
              <Title>Your bill forwarding email</Title>
              <Paragraph bottomSpacing={0}>
                Forward bills you receive to your inbox directly to Carbonhound.
              </Paragraph>
            </Col>
            <Col span={24}>
              <Divider />
              <Button
                prefixIcon="ClipboardCopy"
                type={BUTTON_TYPES.TEXT}
                className="text-left"
                onClick={() =>
                  copyToClipboard({
                    text: `connect+${companySlug}@carbonhound.com`
                  })
                }
              >
                {`connect+${companySlug}@carbonhound.com`}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <Row>
            <Col span={24}>
              <Title>Want customized data ingestion?</Title>
              <Paragraph bottomSpacing={0}>
                Connect with our team to see how we can customize your
                experience!
              </Paragraph>
            </Col>
            <Col span={24}>
              <Divider />
              <Anchor
                fontWeight={500}
                showLinkIcon={false}
                flex
                href="https://meetings.hubspot.com/hannah472/carbonhound-contact-consultant-inbound"
              >
                Contact our team
              </Anchor>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Title size="xl" bottomSpacing={0}>
          All connections
        </Title>
      </Col>
      <Col span={24}>
        <IntegrationLoader>
          <Row gutter={[16, 16]}>
            {integrationList.map((listItem) => (
              <Col xs={24} sm={24} md={24} lg={8} xl={8} key={listItem.slug}>
                <IntegrationConnectCard
                  refreshList={refreshList}
                  item={listItem}
                />
              </Col>
            ))}
          </Row>
        </IntegrationLoader>
      </Col>
    </Row>
  );
}
