import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';

import { StepOne } from '../modal/StepOne';
import { ModalFooter, Steps } from '../../../stories/atoms';
import { EditFields } from './EditFields';
import { useAsync } from '../../../hooks';
import { InitiativeService } from '../../../services';
import { EditFieldsValidation } from '../../IntensityMetric/Form/EditFields/EditFieldsValidation';

const steps = [
  {
    title: 'Benchmark',
    content: StepOne
  },
  {
    title: 'Configure',
    content: StepOne
  },
  {
    title: 'Complete',
    content: StepOne
  }
];

function BenchmarkForm({ companySlug, benchmark: { id, name }, onSuccess }) {
  const [current, setCurrent] = useState(0);
  const isEditingBenchmark = !!id;
  const isCreateCompleteStep = current === steps.length - 1;
  const nextButtonCopy = isEditingBenchmark ? 'Save' : 'Next';
  const isModalFooterVisible = isEditingBenchmark || !isCreateCompleteStep;

  const currentFormValidation = isEditingBenchmark
    ? EditFieldsValidation
    : current.validationSchema;
  const handleNextStep = () => {
    setCurrent(current + 1);
  };
  const createOrUpdateBenchmark = async (values) => {
    const serviceMethod = isEditingBenchmark
      ? InitiativeService.editInitiative
      : InitiativeService.createInitiative;
    const onSuccessMethod = isEditingBenchmark ? onSuccess : handleNextStep;
    await serviceMethod({
      companyInitiativeId: id,
      companySlug,
      ...values
    });
    onSuccessMethod();
  };

  const { execute: handleCreateOrUpdateMetric, isLoading: isSaving } = useAsync(
    {
      asyncFunction: createOrUpdateBenchmark,
      immediate: false
    }
  );

  const handleSubmit = async (values) => {
    if (!isEditingBenchmark) {
      handleNextStep();
    } else {
      await handleCreateOrUpdateMetric(values);
    }
  };
  return (
    <Formik
      initialValues={{ benchmarkTemplate: null, name }}
      onSubmit={handleSubmit}
      validationSchema={currentFormValidation}
      enableReinitialize>
      {({ isValid }) => {
        const saveButtonProps = isCreateCompleteStep
          ? null
          : {
              disabled: isSaving || !isValid,
              loading: isSaving,
              htmlType: 'submit'
            };
        return (
          <Form>
            {isEditingBenchmark ? (
              <EditFields />
            ) : (
              <StepsContainer
                companySlug={companySlug}
                onSuccess={onSuccess}
                stepProps={{ current, steps, companySlug }}
              />
            )}
            {isModalFooterVisible && (
              <ModalFooter
                saveButtonCopy={nextButtonCopy}
                showHelpLink={false}
                showCancelButton={false}
                saveButtonProps={saveButtonProps}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

function StepsContainer({ stepProps, onSuccess, successProps }) {
  const { steps, current, companySlug } = stepProps;
  const CurrentStepComponent = steps[current].content;

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Steps
          current={current}
          items={steps}
          labelPlacement="vertical"
          size="small"
        />
      </Col>
      <Col span={24}>
        <CurrentStepComponent
          companySlug={companySlug}
          onSuccess={() => onSuccess(successProps)}
        />
      </Col>
    </Row>
  );
}

StepsContainer.propTypes = {
  stepProps: PropTypes.object,
  companySlug: PropTypes.string,
  onSuccess: PropTypes.func,
  successProps: PropTypes.object
};

export default BenchmarkForm;
