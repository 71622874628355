export const EmissionSourceItemOrigin = {
  BILL: 'BILL',
  CARBONHOUND_CONNECT: 'CARBONHOUND CONNECT',
  INTERNAL_ESTIMATION: 'INTERNAL ESTIMATION',
  ESTIMATION: 'ESTIMATION'
};

export const EmissionSourceItemOriginCopy = {
  [EmissionSourceItemOrigin.CARBONHOUND_CONNECT]: 'Carbonhound Connect',
  [EmissionSourceItemOrigin.INTERNAL_ESTIMATION]: 'Estimate',
  [EmissionSourceItemOrigin.ESTIMATION]: 'Estimate',
  BILL: 'Bill',
  CARBONHOUND_CONNECT_ESTIMATE: 'Carbonhound Connect: Estimate'
};
