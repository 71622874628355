import { axiosInstance } from '../interceptors';
import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class GroupService {
  static async updateGroup({ groupId, companySlug, ...params }) {
    try {
      const API_URL = `${ApiRoutes.GROUP.replace(':groupId', groupId).replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.put(API_URL, params);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  static async listGroupContent({ groupId, companySlug, type }) {
    try {
      const API_URL = `${ApiRoutes.GROUP_CONTENT_LIST.replace(
        ':groupId',
        groupId
      ).replace(':companySlug', companySlug)}`;
      const response = await AxiosInstance.get(API_URL, {
        params: {
          ...(type && { type })
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  static async authorizeGroupUpload({ groupId, companySlug, file, ...params }) {
    let fileUrl;
    let signedUrl;
    try {
      const API_URL = `${ApiRoutes.GROUP_CONTENT_AUTHORIZE.replace(
        ':groupId',
        groupId
      ).replace(':companySlug', companySlug)}`;
      const response = await AxiosInstance.post(API_URL, params);
      signedUrl = response?.data?.signedUrl;
      fileUrl = response?.data?.fileUrl;
    } catch (error) {
      return error;
    }
    if (signedUrl) {
      try {
        await axiosInstance.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        });
        return fileUrl;
      } catch (error) {
        return error;
      }
    }
  }

  // Unauthenticated Endpoint
  static async listGroupSettings({ groupSlug, ...params }) {
    try {
      const API_URL = `${ApiRoutes.GROUP_SETTINGS.replace(
        ':groupSlug',
        groupSlug
      )}`;
      const response = await AxiosInstance.get(API_URL, params);
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default GroupService;
