import { Tabs as AntTabs } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TAB_URL_PARAM_KEY } from './Tabs.constants';
import './Tabs.scss';

export function Tabs({
  className,
  children,
  secondary,
  defaultActiveKey,
  ...props
}) {
  const location = useLocation();
  const defaultActiveKeyProp =
    new URLSearchParams(location.search).get(TAB_URL_PARAM_KEY) ||
    defaultActiveKey;
  return (
    <AntTabs
      {...props}
      defaultActiveKey={defaultActiveKeyProp}
      className={classNames('ch-tabs', {
        [className]: !!className,
        secondary: !!secondary
      })}
    >
      {children}
    </AntTabs>
  );
}
