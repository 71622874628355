import { Col, Row } from 'antd';
import { useCallback } from 'react';
import { useAsync } from '../../../../hooks';
import SubscriptionService from '../../../../services/SubscriptionService';
import { Anchor, Button, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';

export function UserCompanySubscription({ company }) {
  const loadCustomerPortal = useCallback(async () => {
    const response = await SubscriptionService.getStripeCustomerPortal({
      companySlug: company?.slug
    });
    return response.customerPortalUrl;
  }, [company]);

  const { value: stripeCustomerPortalUrl, Loader: PortalLoader } = useAsync({
    asyncFunction: loadCustomerPortal,
    defaultValue: ''
  });

  return (
    <Row gutter={[24, 8]}>
      <Col span={16}>
        <Title bottomSpacing={8} size="rg">
          Subscription & Billing
        </Title>
        <Paragraph bottomSpacing={0}>
          Manage your subscription and view all of your previous invoices.
        </Paragraph>
      </Col>
      <Col span={8} className="display-flex align-center justify-end">
        <PortalLoader>
          <Anchor href={stripeCustomerPortalUrl} showLinkIcon={false}>
            <Button type={BUTTON_TYPES.SECONDARY}>Manage Account</Button>
          </Anchor>
        </PortalLoader>
      </Col>
    </Row>
  );
}
