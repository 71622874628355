import { SearchOutlined } from '@ant-design/icons';
import { PopupButton } from '@typeform/embed-react';
import { Checkbox as AntCheckbox, Col, Modal as AntModal, Row } from 'antd';
import classNames from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  DropdownField,
  InputField,
  Popover
} from '../../../../../components';
import { EnergyTypeConstants } from '../../../../../constants';
import {
  COLOURS_CARBONHOUND,
  COLOURS_GRAY
} from '../../../../../constants/colours';
import {
  useMeContext,
  useMetaContext,
  useOfficeOnboardingContext
} from '../../../../../context';
import {
  analytics,
  capitalizeText,
  getDropdownOptions
} from '../../../../../helpers';
import { useModal } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import { EnergyService, MetaService } from '../../../../../services';
import { Button, Icon, Paragraph, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { Blurb } from '../../../../../stories/molecules';
import DataRequestTemplate from '../../../../../stories/molecules/DataRequestTemplate';
import './officeDetails.scss';
import { officeDetailsFormValidation } from './officeDetailsFormValidation';

function OfficeDetails() {
  const { me } = useMeContext();
  const navigate = useNavigate();

  const params = useParams();

  const { collectionId } = params;

  const {
    officeOnboardingDetails,
    setActiveCompanyLocation,
    setEnergyDetailsId
  } = useOfficeOnboardingContext();

  const { meta } = useMetaContext();

  const [formValues, setFormValues] = useState({
    electricityTypeCheckboxIds: [],
    electricityTypeDropdownIds: [],
    energyTypes: [],
    energyTypeIds: []
  });

  const [formLoading, setFormLoading] = useState(false);

  const [_isElectricitySelected, setSelectElectricity] = useState(false);

  const [country, setCountry] = useState({});

  const [energyTypes, setEnergyTypes] = useState([]);

  const [energyTypeCheckboxOptions, setEnergyTypeCheckboxOptions] = useState(
    []
  );

  const [energyTypeDropdownOptions, setEnergyTypeDropdownOptions] = useState(
    []
  );

  const blurbRef = useRef();

  const onPopup = (e) => {
    e.preventDefault();
    const analyticsContext = {
      level1: 'Office-Based Business',
      level2: 'Office Onboarding',
      level3: 'Office Details',
      collectionId
    };
    analytics.track('Popup form clicked', me, { ...analyticsContext });
  };

  const yesOrNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  const isBuildingSquareFootageEnabled = (formValues) => {
    const { energyTypes } = formValues;
    return (
      energyTypes.filter((energyType) => energyType.hasSeparateMeter === false)
        .length > 0
    );
  };

  const isOfficeSquareFootageEnabled = (formValues) => {
    const { energyTypes, disableEnergyTypes } = formValues;
    return (
      disableEnergyTypes ||
      energyTypes?.filter(
        (energyType) =>
          energyType.hasAccessToBills === false ||
          energyType.hasSeparateMeter === false
      ).length > 0
    );
  };

  const handleSave = (formValues) => {
    const { energyTypes, disableEnergyTypes, officeSquareFootage } = formValues;
    formValues.isEnergyTypeUnknown = disableEnergyTypes;
    if (disableEnergyTypes) {
      formValues = {
        isEnergyTypeUnknown: disableEnergyTypes,
        officeSquareFootage
      };
    } else {
      const energyTypeIndex = energyTypes.findIndex(
        (energyType) =>
          energyType.energyType === EnergyTypeConstants.ELECTRICITY
      );
      if (energyTypeIndex >= 0) {
        energyTypes[energyTypeIndex] = {
          ...energyTypes[energyTypeIndex]
        };
      }
    }
    const energyMethod = officeOnboardingDetails?.activeCompanyLocation
      ?.energyDetailsId
      ? EnergyService.updateEnergy
      : EnergyService.createEnergy;
    setFormLoading(true);
    energyMethod(
      collectionId,
      {
        ...formValues,
        energyDetailsId:
          officeOnboardingDetails?.activeCompanyLocation?.energyDetailsId
      },
      (detailsId) => {
        const enableManageBills =
          energyTypes.filter(
            (energyType) => energyType.hasAccessToBills === true
          ).length > 0;
        setEnergyDetailsId(detailsId);

        analytics.track('Step Completed', me, {
          level1: 'Office-Based Operations',
          level2: 'Office Onboarding',
          level3: 'Office Details',
          energyDetails: officeOnboardingDetails.activeCompanyLocation
        });
        if (!enableManageBills) {
          navigate(AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_OVERVIEW);
        } else {
          const { energyTypeId } = energyTypes.find(
            (energyType) => energyType.hasAccessToBills
          );
          navigate(
            generatePath(
              AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS,
              {
                energyDetailsId: detailsId,
                collectionId,
                energyTypeId
              }
            )
          );
        }
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleSubmit = (formValues) => {
    const showConfirmationPopup =
      formValues?.disableEnergyTypes ||
      formValues?.energyTypes?.filter(
        (energyType) => !energyType.hasAccessToBills
      ).length > 0;
    if (showConfirmationPopup) {
      AntModal.confirm({
        className: 'ch-modal',
        icon: null,
        okText: 'Yes',
        cancelText: 'No',
        content: (
          <div>
            We will now estimate your office impact using regional averages, but
            it is more accurate to enter your utility bill information if you
            have access. <br />
            <br />
            Are you sure you wish to proceed?
          </div>
        ),
        onOk: () => {
          handleSave(formValues);
        }
      });
    } else {
      handleSave(formValues);
    }
  };

  const handleSetEnergyTypes = (
    values,
    setFieldValue,
    selectedEnergyTypeIds
  ) => {
    const existingEnergyTypes = values.energyTypes;
    const energyTypeValues = selectedEnergyTypeIds.map((energyTypeId) => {
      const energyType = energyTypes.find(
        (energyType) => energyType.id === energyTypeId
      );
      const existingEnergyType = existingEnergyTypes.find(
        (energyType) => energyType.energyTypeId === energyTypeId
      );
      if (existingEnergyType) return existingEnergyType;

      return {
        energyTypeId: energyType.id,
        energyType: energyType.name,
        ...(!country?.isEstimationSupported && { hasAccessToBills: true })
      };
    });
    const isElectricitySelected =
      energyTypeValues.filter(
        (energyType) =>
          energyType.energyType === EnergyTypeConstants.ELECTRICITY
      ).length > 0;
    setSelectElectricity(isElectricitySelected);
    setFieldValue('energyTypes', [...energyTypeValues]);
    if (!isElectricitySelected) {
      setFieldValue('electricityEnergyPercentage', undefined);
      setFieldValue('electricityStartMonth', undefined);
      setFieldValue('electricityStartYear', undefined);
    }
  };

  const handlePrevious = () =>
    navigate(
      generatePath(AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION_EDIT, {
        collectionId
      })
    );

  useEffect(() => {
    if (collectionId) {
      EnergyService.fetchEnergies(
        collectionId,
        (energies) => {
          if (energies.length > 0) {
            const [energyDetails] = energies;
            if (energyDetails?.energyTypes) {
              energyDetails.energyTypes.forEach((energyType) => {
                if (!energyType.hasAccessToBills) {
                  energyType.hasSeparateMeter = null;
                }
              });
            } else {
              energyDetails.energyTypes = [];
            }

            setCountry(
              meta?.countries?.find(
                (countryItem) =>
                  countryItem.id === energies[0].location.country.id
              )
            );
            setActiveCompanyLocation(energyDetails);
            setSelectElectricity(energyDetails.isElectricitySelected);
            setFormValues({
              ...energyDetails,
              energyTypeIds: energyDetails.energyTypes.map((type) => type.id),
              disableEnergyTypes: energyDetails.isEnergyTypeUnknown
            });
          }
        },
        () => {},
        () => {}
      );
    }
  }, []);

  useEffect(() => {
    if (!country?.id) return;
    MetaService.fetchEnergyTypes(
      { countryId: country.id },
      (energyTypes) => {
        setEnergyTypes(energyTypes);
        const checkboxEnergyTypes = energyTypes.filter(
          (energyType) =>
            energyType.name === EnergyTypeConstants.ELECTRICITY ||
            energyType.name === EnergyTypeConstants.NATURAL_GAS
        );
        const dropdownEnergyTypes = energyTypes.filter(
          (energyType) =>
            !(
              energyType.name === EnergyTypeConstants.ELECTRICITY ||
              energyType.name === EnergyTypeConstants.NATURAL_GAS
            )
        );
        setEnergyTypeDropdownOptions(
          getDropdownOptions(dropdownEnergyTypes, 'friendlyName', 'id')
        );
        setEnergyTypeCheckboxOptions(
          getDropdownOptions(checkboxEnergyTypes, 'friendlyName', 'id')
        );
      },
      () => {},
      () => {}
    );
  }, [country?.id]);

  const isEstimationSupported = !!country.isEstimationSupported;
  return (
    <div className="office-details">
      <h3 className="text-bd">Office details</h3>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
        enableReinitialize
        validationSchema={officeDetailsFormValidation}
        initialValues={formValues}
      >
        {({
          values,
          _errors,
          isValid,
          _dirty,
          setFieldValue,
          _setFieldTouched
        }) => {
          const areEnergyFieldsVisible =
            (!isEstimationSupported && values?.energyTypes.length > 0) ||
            (!!!values?.disableEnergyTypes && values?.energyTypes.length > 0);
          return (
            <Form className="office-location__form">
              <Card className="office-location__card">
                <Row gutter={[20, 10]}>
                  <Col span={24}>
                    <p className="text-light-black">
                      Select all of the type(s) of energy your office uses
                    </p>
                    <Row align="middle">
                      <Col span={24} className="office-location__form-header">
                        {isEstimationSupported && (
                          <AntCheckbox
                            checked={values?.disableEnergyTypes}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setFieldValue('energyTypeIds', []);
                                handleSetEnergyTypes(values, setFieldValue, []);
                              }
                              setFieldValue(
                                'disableEnergyTypes',
                                event.target.checked
                              );
                            }}
                          >
                            I don't know
                          </AntCheckbox>
                        )}
                        {!values?.disableEnergyTypes && (
                          <DropdownField
                            className={classNames('mb-0 dropdown-field', {
                              'office-energy-search-select__without-estimations':
                                !isEstimationSupported
                            })}
                            onChange={(energyTypeIds) => {
                              setFieldValue('energyTypeIds', energyTypeIds);
                              handleSetEnergyTypes(
                                values,
                                setFieldValue,
                                energyTypeIds
                              );
                            }}
                            name="energyTypeIds"
                            value={values?.energyTypeIds}
                            placeholder="Search and select"
                            options={[
                              ...energyTypeCheckboxOptions,
                              ...energyTypeDropdownOptions
                            ]}
                            mode="multiple"
                            suffixIcon={<SearchOutlined />}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={20}>
                    {areEnergyFieldsVisible && (
                      <>
                        <Row gutter={[20, 0]} className="mt-4 mb-4 text-sbd">
                          <Col span={6}>Energy Type</Col>
                          <Col span={8}>
                            Access to Bills?
                            <Popover content="If you have access to utility bills or can retrieve the amount of energy you use, select Yes" />
                          </Col>
                          <Col span={8}>
                            <span>Separately metered?</span>
                            <Popover content="If your utility provider is measuring usage for your business separately then select yes." />
                          </Col>
                        </Row>
                        <FieldArray
                          name="energyTypes"
                          render={() => (
                            <>
                              {values?.energyTypes?.map(
                                (energyType, fieldIndex) => (
                                  <Row
                                    key={fieldIndex}
                                    gutter={[20, 0]}
                                    align="middle"
                                    className="mb-4"
                                  >
                                    <Col span={6} className="text-sbd">
                                      {capitalizeText(energyType.energyType)}
                                    </Col>
                                    <Col span={8}>
                                      <DropdownField
                                        name={`energyTypes[${fieldIndex}].hasAccessToBills`}
                                        value={
                                          values.energyTypes[fieldIndex]
                                            .hasAccessToBills
                                        }
                                        className="mb-0 dropdown-field__no-width"
                                        placeholder="Select"
                                        disabled={!isEstimationSupported}
                                        onChange={(value) => {
                                          setFieldValue(
                                            `energyTypes[${fieldIndex}].hasAccessToBills`,
                                            value
                                          );
                                          if (!value) {
                                            setFieldValue(
                                              `energyTypes[${fieldIndex}].hasSeparateMeter`,
                                              null
                                            );
                                          }
                                        }}
                                        options={yesOrNoOptions}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      {values?.energyTypes[fieldIndex]
                                        .hasAccessToBills && (
                                        <DropdownField
                                          name={`energyTypes[${fieldIndex}].hasSeparateMeter`}
                                          value={
                                            values.energyTypes[fieldIndex]
                                              .hasSeparateMeter
                                          }
                                          className="mb-0 dropdown-field__no-width"
                                          setFieldValue={setFieldValue}
                                          placeholder="Select"
                                          options={yesOrNoOptions}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                )
                              )}
                            </>
                          )}
                        />
                      </>
                    )}
                  </Col>
                  <Col span={10}>
                    {isOfficeSquareFootageEnabled(values) && (
                      <InputField
                        title="Office Square Footage"
                        type="number"
                        name="officeSquareFootage"
                        placeholder="Enter value"
                      />
                    )}
                  </Col>
                  {isBuildingSquareFootageEnabled(values) && (
                    <Col span={10}>
                      <InputField
                        title="Building Square Footage"
                        type="number"
                        name="buildingSquareFootage"
                        placeholder="Enter value"
                      />
                    </Col>
                  )}

                  <Col
                    span={24}
                    className="data-request-template__container m-0"
                  >
                    <div className="display-flex">
                      <div className="mr-2">
                        <Icon
                          name="ClipboardCopy"
                          color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                        />
                      </div>
                      <div>
                        <Title
                          color={COLOURS_GRAY.GRAY_800}
                          size="sm"
                          fontWeight={500}
                          bottomSpacing={0}
                        >
                          Need to request data?
                        </Title>
                        <Paragraph
                          color={COLOURS_GRAY.GRAY_600}
                          size="sm"
                          bottomSpacing={0}
                        >
                          We’ve created a data request template to make it
                          easier for you to get the data you need from
                          landlords, or other team members.
                        </Paragraph>
                        <Button
                          type={BUTTON_TYPES.LINK}
                          onClick={handleShowTemplateModal}
                        >
                          View data request template
                        </Button>
                      </div>
                    </div>
                  </Col>
                  {!isEstimationSupported && (
                    <Col
                      span={24}
                      className="mt-3 p-0 request-estimations__container"
                    >
                      <Blurb
                        titleProps={{
                          title: 'Estimates locked for international locations',
                          size: 'sm'
                        }}
                        iconProps={{
                          name: 'GlobeAlt',
                          color: COLOURS_CARBONHOUND.PRIMARY_PURPLE,
                          className: 'mt-1',
                          size: 16
                        }}
                      >
                        <Paragraph size="sm" bottomSpacing={0}>
                          We currently don’t support estimations for the country
                          you have selected. If you would like us to support
                          estimations for this location in the future please
                          send us a request.
                        </Paragraph>
                        <Button onClick={onPopup} type={BUTTON_TYPES.LINK}>
                          <PopupButton
                            ref={blurbRef}
                            className="ch-typeform-button--link ch-typeform-button--link__blurb-popup request-estimations-button"
                            id="a3KzDEDf"
                          >
                            Request estimations for this country
                          </PopupButton>
                        </Button>
                      </Blurb>
                    </Col>
                  )}
                </Row>
              </Card>
              <Row gutter={[20, 20]} className="mt-5 mb-5">
                <Col span={12}>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    htmlType="button"
                    prefixIcon="ArrowLeft"
                    onClick={handlePrevious}
                  >
                    Previous
                  </Button>
                </Col>
                <Col span={12} className="justify-end display-flex">
                  <Button
                    icon="ArrowRight"
                    htmlType="submit"
                    disabled={!isValid || formLoading}
                    loading={formLoading}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <DataRequestTemplateModal title="Data request template">
        <DataRequestTemplate />
      </DataRequestTemplateModal>
    </div>
  );
}

export default OfficeDetails;
