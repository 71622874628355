import { Col, Row, Space, Statistic } from 'antd';
import { Chart, registerables } from 'chart.js';
import { formatInTimeZone } from 'date-fns-tz';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useLocation } from 'react-router';
import { Link, useNavigate, useParams } from 'react-router-dom';
import mountain from '../../../assets/images/mountain.svg';
import plantThriving from '../../../assets/images/plant-thriving.svg';
import plantWithered from '../../../assets/images/plant-withered.svg';
import TargetChart from '../../../assets/images/target-chart.svg';
import {
  Card,
  InputNumberField,
  Slider,
  StatisticGroup
} from '../../../components';
import { TargetType } from '../../../constants';
import { useMeContext } from '../../../context';
import { analytics, capitalizeText, formatDecimal } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { DashboardService, TargetService } from '../../../services';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './TargetSettingReductions.scss';
import { setTargetSettingStrategyValidation } from './TargetSettingReductionsValidation';

Chart.register(...registerables);
export const getChartLabels = (baseYear, endYear) => {
  const options = [];
  for (let i = baseYear; i <= endYear; i++) {
    options.push(i);
  }
  return options;
};

export function TargetSettingReductionFormikContext() {
  const { targetDetailsId } = useParams();
  const navigate = useNavigate();
  const { me } = useMeContext();
  const {
    company: { slug: companySlug, id: companyId }
  } = me;

  const handleSubmit = async (values) => {
    const { data } = await TargetService.updateTargetDetails({
      companySlug,
      companyId,
      id: targetDetailsId,
      percentReduction: values?.percentReduction
    });
    if (!!data) {
      analytics.track('Step Completed', me, {
        level1: 'Office-Based Operations',
        level2: 'Set Targets',
        level3: 'Set reduction target'
      });
      navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW);
    }
  };

  const { execute: handleSubmitDetails } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });
  return (
    <div className="target-setting-reductions">
      <Formik
        onSubmit={handleSubmitDetails}
        enableReinitialize
        validationSchema={setTargetSettingStrategyValidation}
        initialValues={{
          percentReduction: 0
        }}
      >
        <TargetSettingReductions />
      </Formik>
    </div>
  );
}

function TargetSettingReductions() {
  const { targetDetailsId } = useParams();
  const { me } = useMeContext();
  const {
    company: {
      slug: companySlug,
      baseYear,
      startingMonth,
      baseYearEmissionsSum
    }
  } = me;
  const { values, setFieldValue, isValid, isSubmitting } = useFormikContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [emissionsSum, setEmissionsSum] = useState(0);
  const [targetDetails, setTargetDetails] = useState({});

  const handleFetchChartDetails = useCallback(async () => {
    let td;
    let yAxisValues;
    let baseYearVal;
    try {
      const { data: targetDetailsResp } = await TargetService.getTargetDetails({
        companySlug,
        targetDetailsId
      });

      td = targetDetailsResp;

      const startYear = td.startYear
        ? new Date(td.startYear).getUTCFullYear()
        : baseYear;
      baseYearVal = startYear < baseYear ? startYear : baseYear;

      const initialDate = new Date(baseYearVal, 0, 1).getFullYear();
      yAxisValues = getChartLabels(
        initialDate,
        new Date(targetDetailsResp.endYear).getUTCFullYear()
      );
      setChartLabels(yAxisValues);
      setFieldValue(
        'percentReduction',
        targetDetailsResp.percentReduction ?? 0
      );
      setTargetDetails(td);
    } catch (error) {
      console.error(error);
    }
    if (!!td && !!yAxisValues) {
      const month = startingMonth ? startingMonth - 1 : 0;

      try {
        const { emissions } = await DashboardService.fetchEmissionsSum(
          {
            companySlug,
            rangeStart: formatInTimeZone(
              new Date(baseYear, month, 1),
              'UTC',
              'yyyy-MM-dd'
            ),
            rangeEnd: formatInTimeZone(
              new Date(baseYear + 1, month, 0),
              'UTC',
              'yyyy-MM-dd'
            )
          },
          () => {},
          () => {},
          () => {}
        );
        setEmissionsSum(formatDecimal((emissions || 0) / 1000));
        handleSetChartData(
          td.percentReduction ?? 0,
          yAxisValues,
          td.durationYears,
          emissions,
          td.startYear
        );
      } catch (e) {}
    }
  }, [baseYear, baseYearEmissionsSum, companySlug, startingMonth]);
  const { _Loader } = useAsync({
    asyncFunction: handleFetchChartDetails
  });
  const backLink =
    targetDetails?.type === TargetType.INTENSITY
      ? AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_DATA
      : AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY;
  const chartConfig = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: '#F0ECFA',
        fill: true
      }
    ]
  };
  const handleOnChangeSlider = (value) => {
    setFieldValue('percentReduction', value);
    handleSetChartData(value);
  };
  const handleSetChartData = (
    percentReduction,
    labels = chartLabels,
    durationYears = targetDetails.durationYears,
    esValue,
    startYear = targetDetails.startYear
  ) => {
    const es = emissionsSum || esValue;
    setChartData(
      labels.map((year) => {
        if (percentReduction === 0) {
          return es;
        }
        const yearsDifference = year - new Date(startYear).getUTCFullYear();
        const emissions =
          (1 - (yearsDifference * percentReduction) / durationYears / 100) * es;
        return yearsDifference >= 0 ? emissions : es;
      })
    );
  };

  useEffect(() => {
    if (
      targetDetails?.type === TargetType.INTENSITY &&
      typeof location.state?.type === 'undefined'
    ) {
      navigate('.', { replace: true, state: { type: targetDetails?.type } });
    }
  }, [targetDetails?.type, location.state?.type]);

  return (
    <Form>
      <h3>Set your reduction target</h3>
      <p>
        These reduction targets will be based on your operational footprint and
        will not include your offsets. Reducing your operational footprint saves
        money and is critical to meet climate objectives.
      </p>
      <Card>
        <Row align="middle">
          <Col className="mr-6" flex="45px">
            <img
              src={
                values?.type === TargetType.INTENSITY ? TargetChart : mountain
              }
            />
          </Col>
          <Col flex="auto">
            <sup>
              <span className="text-capitalize">
                {capitalizeText(targetDetails.type)}
              </span>{' '}
              target
            </sup>
            <h4 className="text-bd">
              {targetDetails.type === TargetType.INTENSITY
                ? targetDetails.name
                : 'Your overall reduction target'}
            </h4>
          </Col>
          <Col>
            <StatisticGroup>
              <Row>
                <Col>
                  <Statistic
                    className="text-center"
                    groupSeparator=""
                    title="Base year"
                    value={baseYear}
                  />
                </Col>
                <Col>
                  <Statistic
                    className="text-center"
                    groupSeparator=""
                    title="Footprint"
                    value={`${emissionsSum} tonnes of CO2e`}
                  />
                </Col>
                <Col>
                  <Statistic
                    className="text-center"
                    groupSeparator=""
                    title="Timeline"
                    value={`${targetDetails.durationYears ?? '-'} years`}
                  />
                </Col>
              </Row>
            </StatisticGroup>
          </Col>
        </Row>
      </Card>
      <Card className="mt-6">
        <h6 className="text-bd">
          Set how much would you like to reduce your carbon footprint
        </h6>
        <Row className="mb-6 mt-6 slider-container" align="middle" gutter={72}>
          <Col flex="shrink">
            <InputNumberField
              onChange={handleOnChangeSlider}
              controls={false}
              min={0}
              max={100}
              value={values?.percentReduction ?? 0}
              name="percentReduction"
              addonAfter="%"
              title="Percentage"
            />
          </Col>
          <Col flex="auto" className="icon-wrapper">
            <img
              src={plantWithered}
              alt="An illustration of a plant that is withering"
            />
            <Slider
              min={0}
              max={100}
              value={values?.percentReduction}
              onChange={handleOnChangeSlider}
              marks={{
                0: (
                  <div className="text-rg mt-4 no-wrap text-left">
                    <div className="text-sbd ant-slider-mark-text__value">
                      0%
                    </div>
                    <div className="ant-slider-mark-text__description">
                      Conservative
                    </div>
                  </div>
                ),
                100: (
                  <div className="text-rg mt-4 no-wrap text-right">
                    <div className="text-sbd ant-slider-mark-text__value">
                      100%
                    </div>
                    <div className="ant-slider-mark-text__description">
                      Planet friendly
                    </div>
                  </div>
                )
              }}
            />
            <img
              src={plantThriving}
              alt="An illustration of a plant that is withering"
            />
          </Col>
        </Row>
        <Row className="target-setting-reductions__chart">
          <div className="target-setting-reductions__chart__summary">
            <p>
              Your yearly emissions will reduced by
              <span className="text-right text-bd target-setting-reductions__chart__summary__percentage">
                {values?.percentReduction} %
              </span>
            </p>
          </div>
          <Line
            options={{
              hover: {
                mode: 'nearest',
                intersect: true
              },
              plugins: {
                legend: {
                  display: false
                }
              },
              elements: {
                line: {
                  borderWidth: 0
                },

                point: {
                  radius: 0,
                  hitRadius: 1,
                  hoverRadius: 4
                },
                hover: {
                  mode: 'nearest',
                  intersect: true
                }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Goal',
                    fullSize: true,
                    align: 'start'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Annual Carbon Emissions'
                  },
                  min: 0,
                  max: emissionsSum,
                  grid: {
                    display: false
                  }
                }
              }
            }}
            data={chartConfig}
          />
        </Row>
      </Card>

      <div className="target-setting-overview__actions mt-6 text-right">
        <Space size={16}>
          <Link
            to={backLink.replace(':targetDetailsId', targetDetailsId)}
            state={{ type: targetDetails?.type }}
          >
            <Button type={BUTTON_TYPES.SECONDARY}>Back</Button>
          </Link>
          <Button htmlType="submit" disabled={!isValid || isSubmitting}>
            Next
          </Button>
        </Space>
      </div>
    </Form>
  );
}
