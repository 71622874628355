import React from 'react';
import BulkUploadForm from '../../../features/BulkUploadForm';
import { useModal } from '../../../hooks';
import { analytics } from '../../../helpers';
import { Button } from '../../atoms';
import { useMeContext } from '../../../context';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import './DataImportButton.scss';

export function DataImportButton({ onSuccess, analyticsContext, ...props }) {
  const { me } = useMeContext();
  const {
    Modal: BUModal,
    handleShowModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    width: '90%',
    onShowModal: () => {
      analytics.track('Upload data clicked', me, analyticsContext);
    }
  });

  const handleBulkModalSuccess = () => {
    onSuccess?.();
    handleCloseBUModal();
  };

  return (
    <>
      <Button onClick={handleShowModal} type={BUTTON_TYPES.SECONDARY}>
        Data Import
      </Button>

      <BUModal title="Upload your file" className="bulk-upload-modal">
        <BulkUploadForm onSuccess={handleBulkModalSuccess} {...props} />
      </BUModal>
    </>
  );
}
