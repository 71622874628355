import { TAG_COLOURS } from '../stories/atoms/Tag/tag.types';

export const InitiativeFinancePartnerships = [
  {
    locations: ['Regional - Ontario'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Ontario.png',
    tags: ['Grant'],
    title: 'Save on Energy – Retrofit Program',
    financePartnersFilters: ['CANADA-ONTARIO_BUSINESS_GRANT-RETROFIT_PROGRAM'],
    description: `
      The Retrofit Program by Save on Energy is designed to help Ontario businesses save energy, reduce costs and increase productivity. 

      This province-wide initiative offers financial incentives for equipment upgrades that reduce facility electricity consumption such as:
      
      - Lighting retrofits
      - Lighting controls
      - HVAC redesign
      - Chiller replacement
      - Variable-speed drive installations
    `,
    url: 'https://ontariobusinessgrants.com/grants/retrofit-program/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Ontario'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Save-On-Energy-Logo.png',
    tags: ['Grant'],
    title: 'Save on Energy: Small Business Program',
    financePartnersFilters: ['CANADA-SAVE_ON_ENERGY-SMALL_BUSINESS_PROGRAM'],
    description: `
      The Small Business Program by Save on Energy provides financial incentives up to $2,000 in energy-efficient lighting upgrades, including a wide range of ENERGY STAR® certified LED lighting products, and up to $2,500 for non-lighting upgrades to qualifying businesses.
    `,
    url: 'https://saveonenergy.ca//en/For-Your-Small-Business/Programs-and-Incentives/Small-Business-Program',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Ontario', 'Regional - Quebec'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/CanadaGovernment.png',
    tags: ['Rebates'],
    title: 'Enbridge: Commercial Custom Retrofit Program',
    financePartnersFilters: [
      'CANADA-ENBRIDGE-COMMERCIAL_CUSTOM_RETROFIT_PROGRAM'
    ],
    description: `
      The Commercial Custom Retrofit Program by Enbridge provides experts to support your business to uncover energy-saving retrofit opportunities specific to your building(s). Upon completion of the recommended retrofit projects, you can receive an incentive that covers up to 50% of energy efficiency upgrade costs, up to $100,000 per project. Incentives are calculated based on natural gas saved per project at a rate of $0.25/meter cubed of natural gas saved.

      Eligible measures include higher efficiency boilers, higher efficiency combination water and space heating systems, better building controls, water conservation and efficient make-up air and ventilation (including ENERGY STAR certified models).
    `,
    url: 'https://www.enbridgegas.com/business-industrial/incentives-conservation/programs-and-incentives/retrofits-custom-projects/commercial-custom-retrofit-program',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Ontario'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Save-On-Energy-Logo.png',
    tags: ['Financing'],
    title: 'Save on Energy: Energy Performance Program (Multi-Site Businesses)',
    financePartnersFilters: [
      'CANADA-SAVE_ON_ENERGY-ENERGY_PERFORMANCE_PROGRAM'
    ],
    description: `
      The Energy Performance Program (EPP) by Save on Energy rewards customers who are able to make behavioural and operational changes that support capital investment projects, enabling them to grow their energy savings over a number of years.

      The pay-for-performance model encourages whole building energy performance improvements through incentives at a four cent per kilowatt-hour ($0.04/kWh) of savings per year for up to 3 years.
    `,
    url: 'https://saveonenergy.ca//en/For-Business-and-Industry/Programs-and-incentives/Energy-Performance-Program',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Ontario'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/implementation-partners/Save-On-Energy-Logo.png',
    tags: ['Financing'],
    title: 'Save on Energy: Industrial Energy Efficiency Program',
    financePartnersFilters: [
      'CANADA-SAVE_ON_ENERGY-INDUSTRIAL_ENERGY_EFFICIENCY_PROGRAM'
    ],
    description: `
      The Energy Industrial Efficiency Program by Save on Energy supports industrial customers across Ontario to improve their industrial processes and implement system optimization projects. 

      The program offers organizations up to $5 million in financial incentives for each large, complex industrial energy-efficiency project accepted into the program. Eligible projects will deliver electricity savings using proven commercial technologies to lower energy consumption and reduce electricity bills.
    `,
    url: 'https://saveonenergy.ca/For-Business-and-Industry/Programs-and-incentives/Industrial-Energy-Efficiency-Program',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - British Columbia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/CleanBC-Logo.png',
    tags: ['Financing'],
    title: 'CleanBC: Commercial New Construction Program',
    financePartnersFilters: [
      'CANADA-CLEAN_BC-COMMERCIAL_NEW_CONSTRUCTION_PROGRAM'
    ],
    description: `
      The Provincial Commercial New Construction Program by CleanBC provides funding for the design and construction of new high-performance buildings that use high-efficiency electricity in place of fossil fuels, in order to reduce greenhouse gas (GHG) emissions.

      The Program supports electrification measures focused on the building heating system (space heating, ventilation, service hot water heating, and heat recovery systems).
    `,
    url: 'https://www.betterbuildingsbc.ca/incentives/cleanbc-commercial-new-construction-program/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - British Columbia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/BCHydro.png',
    tags: ['Grant'],
    title: 'BC Hydro: Business Energy-Saving Incentives',
    financePartnersFilters: [
      'CANADA-BC_HYDRO-BUSINESS_ENERGY_SAVING_INCENTIVES'
    ],
    description: `
      The Business Energy-Saving Incentives by BC Hydro provides funding for energy-efficient equipment upgrades.  Upgrade any lighting, HVAC, refrigeration, or mechanical technologies equipment and BC Hydro will provide funding to cover on average 25% of the upfront costs through energy-saving incentives.

      Not only do these incentives shorten the payback period of your upfront investment, they make the decision to upgrade easy – helping you to reduce consumption, save on energy costs, and increase your bottom line. Incentives could help you save over $2,800 per year on your bill.
    `,
    url: 'https://www.bchydro.com/powersmart/business/programs/business-incentives.html',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - British Columbia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/BCHydro.png',
    tags: ['Financing'],
    title: 'BC Hydro: Energy Manager Program',
    financePartnersFilters: ['CANADA-BC_HYDRO-ENERGY_MANAGER_PROGRAM'],
    description: `
      The Energy Manager Program by BC Hydro helps organizations by subsidizing the compensation package of an Energy Manager employed by the funded organization. BC Hydro will cover 50% up to $50,000 per year.

      An Energy Manager identifies energy efficiency solutions, manages the implementation of energy saving projects, and implements organizational changes to adopt a structured energy management program that ensures optimization of energy dollars.
    `,
    url: 'https://www.bchydro.com/powersmart/business/programs/energy-manager.html',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - British Columbia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/BCHydro.png',
    tags: ['Financing'],
    title: 'BC Hydro: Continuous Optimization Program',
    financePartnersFilters: ['CANADA-BC_HYDRO-CONTINUOUS_OPTIMIZATION_PROGRAM'],
    description: `
      The Continuous Optimization Program by BC Hydro provides assistance to existing customers to save energy and improve operations in large commercial buildings. 

      The primary focus of the program is on reducing the energy consumption of heating ventilation and air-conditioning (HVAC) systems by making improvements to the Building Automation System. Additional systems of interest include lighting, refrigeration, and other energy intensive systems with complex operating strategies and control systems.
    `,
    url: 'https://www.bchydro.com/powersmart/business/programs/continuous-optimization.html',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - British Columbia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/BCHydro.png',
    tags: ['Free Resource'],
    title: 'BC Hydro: Energy Studies & Audits',
    financePartnersFilters: ['CANADA-BC_HYDRO-ENERGY_STUDIES_AUDITS'],
    description: `
      Energy studies and audits by BC Hydro provide free or funded experts to visit client sites to conduct a detailed analysis and assess options to improve inefficiencies. 

      Power Smart offers 100 percent funding of the assessment (some limits apply). Funding is available for detailed energy efficiency and feasibility studies to help build a solid business case to upgrade plant and equipment systems.
    `,
    url: 'https://www.bchydro.com/powersmart/business/programs/studies-audits.html',
    urlText: 'Learn More'
  },
  {
    locations: ['Local - Edmonton'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Edmonton-City-Logo.png',
    tags: ['Financing'],
    title: 'City of Edmonton: Building Energy Retrofit Accelerator program',
    financePartnersFilters: [
      'CANADA-CITY_OF_EDMONTON-BUILDING_ENERGY_RETROFIT_ACCELERATOR'
    ],
    description: `
      The Building Energy Retrofit Accelerator Program by the City of Edmondon is for owners and operators of buildings (over 1,000 square feet) in Edmonton. Anyone can register, however the building owner must consent to participation.

      Participants will benefit from technical support, customized building benchmarking reports, and access to financial incentives ($6,000/building) to help offset the cost of an energy audit, energy efficiency upgrades, and renewable energy installations to commercial buildings.
    `,
    url: 'https://www.edmonton.ca/programs_services/environmental/building-energy-retrofit-accelerator',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Manitoba'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Manitoba-Logo.jpeg',
    tags: ['Financing'],
    title: 'Efficiency Manitoba: Business Lighting Program',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_MANITOBA-BUSINESS_LIGHTING_PROGRAM'
    ],
    description: `
      The Business Lighting Program by Efficiency Manitoba offers financial incentives to help businesses cover the costs of installing energy efficient LED lighting. 

      Products include DLC listed, ENERGY STAR certified, or Efficiency Manitoba approved, LED lamps, LED fixtures, LED backlit signs, and lighting controls.
    `,
    url: 'https://efficiencymb.ca/business/business-lighting/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Manitoba'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Manitoba-Logo.jpeg',
    tags: ['Financing'],
    title: 'Efficiency Manitoba: Energy Efficient Upgrades',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_MANITOBA-ENERGY_EFFICIENT_UPGRADES'
    ],
    description: `
      This program helps small, independent businesses reduce their water and energy consumption by providing free or subsidized material and installation costs for a variety of retrofits.
      
      Basic Upgrade: Get free A-line LED bulbs, bathroom and kitchen faucet accelerators, pre-rinse spray valves, shower heads, and lighting or attic insulation assessments.
      
      Premium Upgrade: Get 70% of the material costs covered for LED linear lamps, speciality LED bulbs, LED exist signs, smart thermostats, lighting controls, pitched roof insulation.
    `,
    url: 'https://efficiencymb.ca/business/small-business-program/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Nova Scotia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Nova-Scotia-Logo.png',
    tags: ['Financing'],
    title: 'Efficiency Nova Scotia: Commercial Buildings Rebates',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_NOVA_SCOTIA-COMMERCIAL_BUILDING_REBATES'
    ],
    description: `
      The Commercial Buildings Rebates by Efficiency Nova Scotia offers incentives, financing and expert advice to help business upgrade to energy efficient products. 

      Some of the benefits of efficiency include rebates on products such as HVAC, water systems, and lighting and help with custom services and the cost of engineering studies.
    `,
    url: 'https://www.efficiencyns.ca/business/business-types/commercial-buildings/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Nova Scotia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Nova-Scotia-Logo.png',
    tags: ['Financing'],
    title: 'Efficiency Nova Scotia: Commercial Buildings Rebates',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_NOVA_SCOTIA-COMMERCIAL_BUILDING_REBATES'
    ],
    description: `
      The Commercial Buildings Rebates by Efficiency Nova Scotia offers incentives, financing and expert advice to help business upgrade to energy efficient products. 

      Some of the benefits of efficiency include rebates on products such as HVAC, water systems, and lighting and help with custom services and the cost of engineering studies.
    `,
    url: 'https://www.efficiencyns.ca/business/business-types/commercial-buildings/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Nova Scotia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Nova-Scotia-Logo.png',
    tags: ['Financing'],
    title: 'Efficiency Nova Scotia: Hospitality Lighting Efficiency Program',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_NOVA_SCOTIA-HOSPITALITY_LIGHTING_EFFICIENCY'
    ],
    description: `
      The Hospitality Program by Efficiency Nova Scotia offers incentives, financing and expert advice to help a hotel or restaurant business upgrade to energy efficient products, including heating, kitchen and refrigeration equipment. 

      Incentives cover up to 80% of the total project cost on select lighting and all Commercial Heating measures. This offer is for small businesses that consume less than 350,000 kWh annually.
    `,
    url: 'https://www.efficiencyns.ca/business/business-types/hospitality/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Nova Scotia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Nova-Scotia-Logo.png',
    tags: ['Financing'],
    title: 'Efficiency Nova Scotia: Retail Program',
    financePartnersFilters: ['CANADA-EFFICIENCY_NOVA_SCOTIA-RETAIL_PROGRAM'],
    description: `
      The Retail Program by Efficiency Nova Scotia provides incentives up to 80% of the total project cost on select lighting and all Commercial Heating measures. Projects include improved lighting, HVAC system upgrades, heat pumps, and more efficient refrigeration.

      This offer is for small businesses that consume less than 350,000 kWh annually.
    `,
    url: 'https://www.efficiencyns.ca/business/business-types/retail/',
    urlText: 'Learn More'
  },
  {
    locations: ['Regional - Nova Scotia'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Efficiency-Nova-Scotia-Logo.png',
    tags: ['Rebates'],
    title: 'Efficiency Nova Scotia: Small Business Program',
    financePartnersFilters: [
      'CANADA-EFFICIENCY_NOVA_SCOTIA-SMALL_BUSINESS_PROGRAM'
    ],
    description: `
      The Small Business Program by Efficiency Nova Scotia provides rebates on high quality, efficient equipment, and programs that identify energy saving opportunities.

      Qualified businesses may receive a free energy assessment, rebates of up to 80% on upgrade projects, and 24-months interest free financing. Your small business will notice big benefits while enjoying the good things efficiency brings.
      
      Even if your business is non-electrically heated, you can access rebates up to 80% on energy efficient products, including heating and lighting, through the Small Business Energy Solutions Program.
    `,
    url: 'https://www.efficiencyns.ca/business/business-types/small-business/',
    urlText: 'Learn More'
  },
  {
    locations: ['National - Canada'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/CanadaGovernment.png',
    tags: ['Free Resource'],
    title: 'Government of Canada: Business Benefits Finder',
    financePartnersFilters: [
      'CANADA-GOVERNMENT_OF_CANADA-BUSINESS_BENEFITS_FINDER'
    ],
    description: `
      The Business Benefits Finder by the Government of Canada helps small businesses find the right funding programs and services, whether you’re starting out or scaling up.
    `,
    url: 'https://innovation.ised-isde.canada.ca/s/?language=en_CA',
    urlText: 'Learn More'
  },
  {
    locations: ['National - Canada'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/My-Grant-Canada-Logo.png',
    tags: ['Free Resource'],
    title: 'MyGrant Canada',
    financePartnersFilters: ['CANADA-MY_GRANT_CANADA'],
    description: `
      MyGrant Canada is a service to support businesses in finding grants available to them. Hundreds of small business owners used MyGrant to quickly find funds to kickstart or work on a project in their business.
    `,
    url: 'https://mygrant.ca/',
    urlText: 'Learn More'
  },
  {
    locations: ['National - Canada', 'National - USA'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Swoop-Logo.png',
    tags: ['Free Resource'],
    title: 'Swoop',
    financePartnersFilters: ['NORTH_AMERICA-SWOOP'],
    description: `
      Swoop works with businesses to find the right loan, equity funding, or grant to meet your goals. They work with businesses to offer simple and clear comparisons that allow you to make choices on financial products and services.
    `,
    url: 'https://swoopfunding.com/ca/how-swoop-works/',
    urlText: 'Learn More'
  },
  {
    locations: ['Local - Toronto'],
    logoSrc:
      'https://storage.googleapis.com/carbonhound-prod-public/initiatives/financing-partners/Toronto.png',
    tags: ['Financing'],
    title: 'City of Toronto: Energy Retrofit Loans',
    financePartnersFilters: ['CANADA-CITY_OF_TORONTO-ENERGY_RETROFIT_LOANS'],
    description: `
      The Energy Retrofit Loan Program by the City of Toronto offers fixed, long-term, low-interest financing to enable building owners to invest in low-carbon, energy-efficient capital improvements.

      Financing up to 100% of project costs are available on heat pump, lighting retrofits, renewable energy systems, fuel switching, high-efficiency boilers, chillers and HVAC, and other retrofit measures/technologies
    `,
    url: 'https://www.toronto.ca/services-payments/water-environment/environmental-grants-incentives/energy-retrofit-loans/',
    urlText: 'Learn More'
  }
];

export const InitiativeFinancePartnershipTypes = {
  FINANCING: 'Financing',
  GRANT: 'Grant',
  FREE_RESOURCE: 'Free Resource',
  REBATES: 'Rebates'
};

export const InitiativeFinancePartnershipTypeColours = {
  [InitiativeFinancePartnershipTypes.FINANCING]: TAG_COLOURS.VIOLET,
  [InitiativeFinancePartnershipTypes.FREE_RESOURCE]: TAG_COLOURS.GREEN,
  [InitiativeFinancePartnershipTypes.GRANT]: TAG_COLOURS.YELLOW,
  [InitiativeFinancePartnershipTypes.REBATES]: TAG_COLOURS.BLUE
};
