import 'antd/dist/antd.min.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserType } from './constants';
import { ContextProviders } from './ContextProviders';
import { history } from './helpers';
import { Maintenance } from './pages/Maintenance';
import { AppRouter } from './routes';
import { MeService } from './services';
import './styles/_main.scss';

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(
    process.env.REACT_APP_IS_MAINTENANCE_LOCK === 'true'
  );
  useEffect(() => {
    const getUser = async () => {
      return await MeService.getMyDetails();
    };

    if (isMaintenanceMode) {
      const localToken = localStorage.getItem('token');
      if (!!localToken) {
        const user = getUser();
        if (!!user?.userType) {
          setIsMaintenanceMode(user.userType !== UserType.INTERNAL);
        }
      }
    }
  }, [isMaintenanceMode]);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }
  return (
    <Router history={history}>
      <ContextProviders>
        <AppRouter />
      </ContextProviders>
    </Router>
  );
}

export default App;
