import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CollectionType } from '../../../../constants';
import { COLOURS_CARBONHOUND } from '../../../../constants/colours';
import { useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import {
  getFormattedAddress,
  getFullName
} from '../../../../helpers/generators';
import { useAsync, usePagination } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { CollectionService } from '../../../../services';
import { ColumnTitle, Drawer, Icon, Table } from '../../../../stories/atoms';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import { LocationForm } from '../../../../stories/molecules';
import TableTitle from '../../../../stories/molecules/TableTitle';

function EmissionSourceLocationList() {
  const { me } = useMeContext();
  const [activeLocationList, setActiveLocationList] = useState([]);
  const [activeLocationCount, setActiveLocationCount] = useState(0);
  const [shouldRefreshList, setShouldRefreshList] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [movedOutLocationList, setMovedOutLocationList] = useState([]);
  const [movedOutLocationCount, setMovedOutLocationCount] = useState(0);
  const [activeList, setActiveList] = useState(true);
  const defaultOrderBy = ['updatedAt', 'id'];
  const analyticsContext = {
    level1: 'Emission Sources',
    level2: 'Overview',
    level3: 'Location'
  };
  const navigate = useNavigate();
  const {
    paginationParams: activePagination,
    handleSetPaginationParams: handleSetActivePaginationParams
  } = usePagination({
    pageSize: 30,
    paginationArray: activeLocationList,
    orderBy: defaultOrderBy,
    order: 'ASC'
  });
  const {
    paginationParams: movedOutPagination,
    handleSetPaginationParams: handleSetMovedOutPaginationParams
  } = usePagination({
    pageSize: 30,
    paginationArray: movedOutLocationList,
    orderBy: defaultOrderBy,
    order: 'ASC'
  });

  const handleSortColumn = (sorter, isMovedOut = false) => {
    const paginationFn = isMovedOut
      ? handleSetMovedOutPaginationParams
      : handleSetActivePaginationParams;
    const paginationParams = isMovedOut ? movedOutPagination : activePagination;
    const paginationArray = isMovedOut
      ? movedOutLocationList
      : activeLocationList;

    paginationFn({
      ...paginationParams,
      paginationArray,
      pageNumber: 1,
      prevPageNumber: 1,
      previousValue: null,
      orderBy: sorter.column?.key ? [sorter.column?.key, 'id'] : defaultOrderBy,
      order: sorter.order,
      reverse: false
    });
  };

  const handleSwitchListViews = (value) => {
    setActiveList(value);
  };
  const handleLocationFormSuccess = () => {
    setShouldRefreshList(true);
    setDrawerOpen(false);
  };
  const handleCreateLocation = () => {
    setDrawerOpen(true);
  };

  const columns = [
    {
      title: () => (
        <ColumnTitle columnTitle="formattedAddress">Address</ColumnTitle>
      ),
      render: (location) => (
        <span className="text-bd">{getFormattedAddress({ location })}</span>
      ),
      dataIndex: 'location',
      key: 'formattedAddress'
    },
    {
      title: () => <ColumnTitle columnTitle="nickname">Nickname</ColumnTitle>,
      render: (name) => <span>{getFormattedAddress({ name })}</span>,
      dataIndex: 'name',
      key: 'nickname'
    },
    {
      key: 'updatedAt',
      title: ({ sortColumns }) => (
        <ColumnTitle
          columnTitle="updatedAt"
          sortColumns={sortColumns}
          isSortable
        >
          Last Edited
        </ColumnTitle>
      ),
      render: (_, item) =>
        item.updatedAt
          ? `${formatInTimeZone(
              item.updatedAt,
              'UTC',
              'MMM dd, ' + 'yyyy'
            )} by ${getFullName(item.updatedBy)}`
          : '-',
      sorter: true
    },
    {
      key: 'chevron',
      onCell: () => ({
        align: 'right'
      }),
      render: () => (
        <Icon
          size={14}
          color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
          name="ChevronRight"
        />
      )
    }
  ];
  const switcherProps = {
    onClick: handleSwitchListViews,
    activeValue: activeList,
    options: [
      {
        label: `Active (${activeLocationCount})`,
        value: true
      },
      { label: `Moved Out (${movedOutLocationCount})`, value: false }
    ]
  };
  const handleSetPaginationParams = activeList
    ? handleSetActivePaginationParams
    : handleSetMovedOutPaginationParams;
  const pagination = activeList ? activePagination : movedOutPagination;
  const total = activeList ? activeLocationCount : movedOutLocationCount;
  const dataSource = activeList ? activeLocationList : movedOutLocationList;

  const fetchLocationList = useCallback(
    async (isMovedOut = false) => {
      const response = await CollectionService.listCollections({
        companySlug: me.company?.slug,
        isMovedOut,
        pagination,
        types: [CollectionType.LOCATION]
      });
      const updateListFn = isMovedOut
        ? setMovedOutLocationList
        : setActiveLocationList;
      const updateCountFn = isMovedOut
        ? setMovedOutLocationCount
        : setActiveLocationCount;

      updateListFn(response?.list ?? []);
      updateCountFn(response?.count ?? 0);
      setShouldRefreshList(false);
    },
    [pagination.pageNumber, pagination.orderBy, pagination.search?.queryString]
  );

  const { isLoading: isLocationLoading, execute: loadLocationList } = useAsync({
    asyncFunction: fetchLocationList,
    immediate: false
  });

  useEffect(() => {
    loadLocationList(true);
    loadLocationList(false);
  }, [
    pagination.pageNumber,
    pagination.orderBy,
    pagination.search?.queryString,
    shouldRefreshList
  ]);

  return (
    <>
      <Table
        isLoading={isLocationLoading}
        title={() => (
          <TableTitle
            switcherProps={switcherProps}
            searchProps={{
              type: INPUT_TYPES.SEARCH,
              isFormikField: false,
              enterButton: true,
              placeholder: 'Search by address',
              onSearch: (query) => {
                handleSetPaginationParams({
                  ...pagination,
                  pageNumber: 1,
                  reverse: false,
                  prevPageNumber: 1,
                  previousValue: undefined,
                  search: !!query
                    ? {
                        queryString: query,
                        queryFields: ['name']
                      }
                    : undefined
                });
              }
            }}
            buttons={[
              {
                children: 'Create New',
                onClick: handleCreateLocation
              }
            ]}
          />
        )}
        columns={columns}
        total={total}
        buttonProps={{}}
        pagination={pagination}
        onChange={(pagination, filters, sorter, { action }) => {
          if (action === 'sort') {
            handleSortColumn(sorter);
          }
        }}
        onRow={(rowItem) => ({
          className: 'cursor-pointer',
          onClick: () => {
            analytics.track('Item Clicked', me, {
              ...analyticsContext,
              collectionId: null // TODO after collections
            });
            navigate(
              AppRoutes.SOURCES_LOCATION_DETAILS.replace(
                ':collectionId',
                rowItem.id
              )
            );
          }
        })}
        showPagination
        dataSource={dataSource}
      />
      <Drawer
        destroyOnClose
        width={565}
        visible={drawerOpen}
        showHeader={false}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <LocationForm
          isEdit={false}
          showAdditionalFields
          destroyOnClose
          onSuccess={handleLocationFormSuccess}
          analyticsContext={{
            tracking: 'Popup form clicked',
            level1: 'Emission Sources',
            level2: 'Overview',
            level3: 'Location',
            micro: 'Item Modal'
          }}
        />
      </Drawer>
    </>
  );
}

export default EmissionSourceLocationList;
