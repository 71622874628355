function PawClipboard() {
  return (
    <svg
      width="44"
      height="65"
      viewBox="0 0 44 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08333 7.16663C2.10431 7.16663 0.5 8.77094 0.5 10.75V60.9166C0.5 62.8956 2.10431 64.5 4.08333 64.5H39.9167C41.8957 64.5 43.5 62.8956 43.5 60.9166V10.75C43.5 8.77094 41.8957 7.16663 39.9167 7.16663H4.08333ZM14.8333 10.75C12.8543 10.75 11.25 12.3543 11.25 14.3333C11.25 16.3123 12.8543 17.9166 14.8333 17.9166H29.1667C31.1457 17.9166 32.75 16.3123 32.75 14.3333C32.75 12.3543 31.1457 10.75 29.1667 10.75H14.8333Z"
        fill="#93C5FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0833 43C9.10431 43 7.5 44.6043 7.5 46.5833C7.5 48.5624 9.10431 50.1667 11.0833 50.1667H32.5833C34.5624 50.1667 36.1667 48.5624 36.1667 46.5833C36.1667 44.6043 34.5624 43 32.5833 43H11.0833ZM9.29167 53.75C8.30216 53.75 7.5 54.5522 7.5 55.5417C7.5 56.5312 8.30216 57.3333 9.29167 57.3333H34.375C35.3645 57.3333 36.1667 56.5312 36.1667 55.5417C36.1667 54.5522 35.3645 53.75 34.375 53.75H9.29167Z"
        fill="white"
      />
      <rect
        x="18.5"
        width="7.16667"
        height="14.3333"
        rx="3.58333"
        fill="#FBAB1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3667 35.5739C15.9155 35.2578 15.5563 34.6427 15.1802 33.9988C15.0536 33.7821 14.9251 33.5621 14.7906 33.3491C14.1177 31.7258 14.6532 29.9617 15.9867 29.409C16.3248 29.2689 16.6818 29.22 17.0373 29.2517C16.8455 27.9272 17.4022 26.6767 18.4866 26.2272C19.5841 25.7723 20.8801 26.2797 21.6795 27.3786C22.4584 26.1243 23.8547 25.5147 25.0253 25.9999C26.2672 26.5147 26.8169 28.0801 26.343 29.604C26.8833 29.4213 27.4543 29.4185 27.9798 29.6363C29.3132 30.189 29.8487 31.953 29.1759 33.5764C29.0413 33.7894 28.9128 34.0094 28.7862 34.2261C28.4853 34.7413 28.1951 35.2381 27.86 35.578C27.4888 38.0306 25.0576 39.9243 22.1137 39.9243C19.1681 39.9243 16.7358 38.0284 16.3667 35.5739Z"
        fill="#EFF6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0394 29.6716C24.7475 30.5003 23.9502 30.9746 23.2586 30.731C22.567 30.4874 22.243 29.618 22.5349 28.7893C22.8268 27.9606 23.6241 27.4863 24.3157 27.7299C25.0073 27.9735 25.3313 28.8429 25.0394 29.6716ZM20.9376 28.7591C21.2495 29.5805 20.9466 30.4574 20.2611 30.7177C19.5756 30.978 18.7671 30.5232 18.4552 29.7018C18.1432 28.8804 18.4461 28.0035 19.1316 27.7432C19.8171 27.4829 20.6257 27.9377 20.9376 28.7591ZM26.188 33.6686C26.8796 33.9122 27.6769 33.4379 27.9689 32.6092C28.2608 31.7805 27.9368 30.9111 27.2452 30.6675C26.5536 30.4239 25.7563 30.8982 25.4643 31.7269C25.1724 32.5556 25.4964 33.425 26.188 33.6686ZM15.9191 32.6092C16.211 33.4379 17.0083 33.9122 17.6999 33.6686C18.3915 33.425 18.7155 32.5556 18.4236 31.7269C18.1317 30.8982 17.3344 30.4239 16.6428 30.6675C15.9512 30.9111 15.6272 31.7805 15.9191 32.6092ZM22.5759 36.9225C22.7067 36.9059 22.8433 36.8886 23.0044 36.8875C23.2689 36.8857 23.4912 36.9549 23.703 37.0208C24.0036 37.1142 24.2832 37.2012 24.6324 37.0698C25.48 36.7508 25.9513 35.9188 25.6851 35.2114C25.6213 35.0419 25.5091 34.9342 25.382 34.8122C25.3205 34.7533 25.2556 34.691 25.191 34.6167C24.9065 34.2898 24.6859 33.9425 24.4762 33.6124C23.9683 32.8127 23.5245 32.114 22.3906 32.0502C20.7691 31.9591 19.3929 32.9822 19.3169 34.3354C19.3147 34.3748 19.3167 34.4109 19.3188 34.4479C19.3213 34.4939 19.324 34.5411 19.3187 34.5975C19.2991 34.8071 19.1714 34.9473 19.0476 35.083C18.9278 35.2145 18.8117 35.3419 18.8014 35.5242C18.759 36.2788 19.4576 36.9318 20.3618 36.9826C20.533 36.9922 20.6738 36.9455 20.819 36.8974C20.9598 36.8507 21.1047 36.8027 21.2855 36.8031C21.4644 36.8035 21.6083 36.8422 21.749 36.8802C21.867 36.912 21.9827 36.9432 22.1151 36.9506C22.2804 36.9599 22.4242 36.9417 22.5759 36.9225Z"
        fill="#2563EB"
      />
    </svg>
  );
}
export default PawClipboard;
