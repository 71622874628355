import { Col, Space } from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card } from '../../../components';
import { OfficeTrailSegment } from '../../../constants';

import { useMeContext, useOfficeTrailContext } from '../../../context';
import {
  analytics,
  capitalizeText,
  formatDecimal,
  formatEmissions,
  formatOwnershipString,
  getFullName
} from '../../../helpers';
import { formatSingleEmission } from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CommuteService } from '../../../services';
import { Button, Link, Paragraph, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

import '../DashboardOnboardingWidgets.scss';

export function YourOnboardingWidget() {
  const { me } = useMeContext();
  const { loggedInAs } = me;
  const { officeTrailDetails } = useOfficeTrailContext();
  const navigate = useNavigate();
  const { employeeTrailStatus } = officeTrailDetails;

  const [carbonTotal, setCarbonTotal] = useState(0);

  const handleLoadCommuteData = useCallback(async () => {
    const commutes = await CommuteService.fetchEmployeeCommutes({
      companySlug: me.company.slug,
      ...(loggedInAs?.user?.id && { loggedInUserId: loggedInAs.user.id })
    });
    const formattedEmissions = formatEmissions(commutes, 'emissionSummary');

    const carbonTotalFormatted = formatDecimal(
      formatSingleEmission(
        formattedEmissions.reduce((a, b) => a + b.emissionSummary, 0)
      )
    );

    setCarbonTotal(carbonTotalFormatted);
  }, [me?.company?.slug, carbonTotal]);
  const { Loader } = useAsync({
    asyncFunction: handleLoadCommuteData
  });

  const employeeTrailStatusOrdered = [
    {
      key: OfficeTrailSegment.COMMUTE,
      value: employeeTrailStatus[OfficeTrailSegment.COMMUTE]
    },
    {
      key: OfficeTrailSegment.BUSINESS_TRAVEL,
      value: employeeTrailStatus[OfficeTrailSegment.BUSINESS_TRAVEL]
    }
  ];

  const currentEmployeeStep = employeeTrailStatusOrdered.find(
    (elem) => elem.value === 'started' || elem.value === 'incomplete'
  );
  const isStarted = employeeTrailStatusOrdered.some(
    (elem) => elem.value === 'complete'
  );
  const step = currentEmployeeStep?.key;

  const orderedTrailSteps = {
    [OfficeTrailSegment.COMMUTE]: {
      trailName: !!isStarted ? 'Employee Details' : 'Get Started',
      trailRoute: AppRoutes.ONBOARDING_EMPLOYEE_DETAILS
    },
    [OfficeTrailSegment.BUSINESS_TRAVEL]: {
      trailName: 'Business Travel',
      trailRoute: AppRoutes.ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL
    }
  };

  const { trailName, trailRoute = '' } = orderedTrailSteps[step] ?? {};

  const handleNavigateWidgetButton = () => navigate(trailRoute);
  const handleYourImpactAnalytics = () => {
    analytics.track('Item clicked', me, {
      level1: 'Dashboard',
      level2: 'Your Impact Widget'
    });
  };
  const loggedInAsUserFullName =
    loggedInAs?.user && getFullName(loggedInAs.user);
  const widgetOwnershipText = loggedInAsUserFullName
    ? formatOwnershipString(loggedInAsUserFullName)
    : 'Your';

  if (!!currentEmployeeStep) {
    return (
      <Col flex="1 0 50%" className="display-flex">
        <Card className="onboarding-card">
          <Space align="center" className="display-flex justify-space-between">
            <div className="onboarding-icon__section">
              <div className="onboarding-icon onboarding-icon-gray mr-4">🪪</div>
              <Title bottomSpacing={0}>{widgetOwnershipText} Onboarding</Title>
            </div>
            <Space direction="vertical" size={0}>
              {!!isStarted && (
                <Title bottomSpacing={0} size="sm" align="right">
                  Next Step
                </Title>
              )}

              {!isStarted ? (
                <Button
                  onClick={handleNavigateWidgetButton}
                  type={BUTTON_TYPES.SECONDARY}
                >
                  {capitalizeText(trailName)}
                </Button>
              ) : (
                <Link fontWeight={600} to={trailRoute}>
                  {capitalizeText(trailName)}
                </Link>
              )}
            </Space>
          </Space>
        </Card>
      </Col>
    );
  }
  if (!currentEmployeeStep) {
    return (
      <Col flex="1 0 50%" className="display-flex">
        <Card className="onboarding-card">
          <Space align="center" className="display-flex justify-space-between">
            <div className="onboarding-icon__section">
              <div className="onboarding-icon onboarding-icon-blue mr-4">
                🌎
              </div>
              <Space direction="vertical" size={0}>
                <Paragraph size="sm" bottomSpacing={0}>
                  {widgetOwnershipText} annual commute impact
                </Paragraph>
                <Loader size="small" className="justify-start">
                  <Title bottomSpacing={0}>{carbonTotal} tonnes</Title>
                </Loader>
              </Space>
            </div>
            <Link
              fontWeight={600}
              to={AppRoutes.ONBOARDING_EMPLOYEE_YOUR_IMPACT}
            >
              <Button
                className="onboarding-widget-button"
                onClick={handleYourImpactAnalytics}
                type={BUTTON_TYPES.LINK}
              >
                View
              </Button>
            </Link>
          </Space>
        </Card>
      </Col>
    );
  }
  return null;
}
