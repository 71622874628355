import { Col, Row } from 'antd';
import { Card } from '../../../../../components';
import { TargetType } from '../../../../../constants';
import {
  useInitiativeContext,
  useMeContext,
  useTargetContext
} from '../../../../../context';
import { Paragraph, Select, Title } from '../../../../../stories/atoms';

export function CompanyInitiativeBaselineYear() {
  const { me } = useMeContext();
  const { updateTarget, createTarget, targetState } = useTargetContext();
  const { targetDetail } = targetState;
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative, companyInitiativeId } = initiativeState;
  const currYear = new Date().getUTCFullYear();
  let minYear = currYear;
  if (!!me?.company?.footprintRange?.length) {
    minYear = Math.min(...me?.company?.footprintRange);
  }
  const footPrintDropdownOptions = [];
  for (let year = minYear; year <= currYear; year++) {
    footPrintDropdownOptions.push({
      label: year,
      value: year
    });
  }
  const handleOnChangeBaselineYear = async (baseYear) => {
    const isNew = !targetDetail.id;
    const upsertMethod = isNew ? createTarget : updateTarget;
    const name = isNew ? `INITIATIVE__${companyInitiative.id}` : undefined;
    const type = isNew ? TargetType.INITIATIVE : undefined;
    await upsertMethod({
      baseYear,
      name,
      companyInitiativeId,
      id: targetDetail?.id,
      type,
      startYear: new Date(),
      endYear: companyInitiative.endDate
    });
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Title bottomSpacing={12}>Initiative Baseline Year</Title>
          <Paragraph bottomSpacing={0}>
            We recommend your initiative baseline year be the current year
          </Paragraph>
        </Col>
        <Col span={24}>
          <Select
            name="baseYear"
            options={footPrintDropdownOptions}
            disableForm
            value={
              targetDetail.baseYear &&
              new Date(targetDetail.baseYear).getUTCFullYear()
            }
            placeholder="Select"
            onChange={handleOnChangeBaselineYear}
          />
        </Col>
      </Row>
    </Card>
  );
}
