function Flag() {
  return (
    <path
      d="M3 21V17M3 17V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H11.5L12.5 4H21L18 10L21 16H12.5L11.5 15H5C4.46957 15 3.96086 15.2107 3.58579 15.5858C3.21071 15.9609 3 16.4696 3 17ZM12 3.5V9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Flag;
