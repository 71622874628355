import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import {
  COLOURS_GRAY,
  COLOURS_GREEN,
  COLOURS_INDIGO,
  COLOURS_ORANGE
} from '../../constants/colours';
import { useMeContext } from '../../context';
import { analytics, emailValidator } from '../../helpers';
import { useAsync } from '../../hooks';
import { AppRoutes } from '../../routes';
import { AuthService } from '../../services';
import {
  Button,
  Icon,
  Input,
  Label,
  Link,
  Paragraph,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import CheckEmail from '../../stories/molecules/CheckEmail';
import './signUp.scss';

function SignUp() {
  const { me, clearUser } = useMeContext();
  const { groupSettings } = me;

  const [checkEmail, setCheckEmail] = useState(false);

  const [formValues, setFormValues] = useState({
    email: '',
    isAcceptTermsAndConditions: false
  });

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setFormValues({
      email: values.email
    });
    try {
      const signUpResponse = await AuthService.signUp({
        groupSlug: groupSettings?.slug,
        user: values
      });
      if (signUpResponse.token) {
        analytics.track('Create Account Completed', null, {
          email: values.email,
          level1: 'Create Account',
          level2: 'Signup',
          level3: 'Enter Email Address'
        });
        setCheckEmail(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  const handlePrevious = () => {
    setCheckEmail(false);
    AuthService.logout(() => {
      clearUser();
      navigate(AppRoutes.LOGIN);
    });
  };

  return checkEmail ? (
    <CheckEmail
      onPrevious={handlePrevious}
      description="Click the link in the email to verify your email and set your password."
      email={formValues.email}
    />
  ) : (
    <div className="sign-up-page__container">
      <Row className="sign-up-form__wrapper">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="sign-up-left__container"
        >
          <div className="sign-up-left__wrapper">
            <Row>
              <Col span={24}>
                <img
                  src={logo}
                  className="sign-up-logo mb-8"
                  alt="Carbonhound logo"
                />
                <div>
                  <Title fontSize={32} color={COLOURS_GRAY.GRAY_800}>
                    Start your free trial
                  </Title>
                  <Paragraph
                    size="rg"
                    color={COLOURS_GRAY.GRAY_500}
                    bottomSpacing={56}
                  >
                    No credit card required, start tracking your carbon
                    emissions today.
                  </Paragraph>
                </div>
              </Col>
            </Row>
            <Row className="display-flex">
              <Col xs={4} sm={3} md={2} lg={3} xl={2}>
                <Icon
                  color={COLOURS_ORANGE.ORANGE_400}
                  bgColor={COLOURS_ORANGE.ORANGE_100}
                  name="LightningBolt"
                />
              </Col>
              <Col xs={20} sm={21} md={22} lg={21} xl={22}>
                <Title
                  bottomSpacing={4}
                  size="lg"
                  color={COLOURS_GRAY.GRAY_700}
                >
                  Quick start up
                </Title>
                <Paragraph size="rg" color={COLOURS_GRAY.GRAY_500}>
                  Utilize our guided onboarding to get your footprint mapped in
                  minutes.
                </Paragraph>
              </Col>
            </Row>
            <Row className="display-flex">
              <Col xs={4} sm={3} md={2} lg={3} xl={2}>
                <Icon
                  color={COLOURS_INDIGO.INDIGO_800}
                  bgColor={COLOURS_INDIGO.INDIGO_100}
                  name="Briefcase"
                />
              </Col>
              <Col xs={20} sm={21} md={22} lg={21} xl={22}>
                <Title
                  bottomSpacing={4}
                  size="lg"
                  color={COLOURS_GRAY.GRAY_700}
                >
                  Industry specific experience
                </Title>
                <Paragraph size="rg" color={COLOURS_GRAY.GRAY_500}>
                  Our platform recommendations have been catered to your
                  industries specific needs.
                </Paragraph>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={3} md={2} lg={3} xl={2}>
                <Icon
                  color={COLOURS_GREEN.GREEN_800}
                  bgColor={COLOURS_GREEN.GREEN_100}
                  name="Globe"
                />
              </Col>
              <Col xs={20} sm={21} md={22} lg={21} xl={22}>
                <Title
                  bottomSpacing={4}
                  size="lg"
                  color={COLOURS_GRAY.GRAY_700}
                >
                  Join 100+ company’s
                </Title>
                <Paragraph size="rg" color={COLOURS_GRAY.GRAY_500}>
                  Carbonhound is trusted by forward thinking businesses at every
                  size that are making an impact.
                </Paragraph>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="sign-up-right__container"
        >
          <div className="sign-up-right__wrapper">
            <Formik
              initialValues={{
                email: ''
              }}
              onSubmit={execute}
              validationSchema={emailValidator}
            >
              {({ _values, _errors, _isValid, _dirty, _setFieldValue }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <Title size="lg" align="center" className="mb-7">
                        Create your free account
                      </Title>
                      <div className="mt-7 mb-5">
                        <Label>Email</Label>
                        <Input type="text" name="email" placeholder="Email" />
                      </div>
                      <div className="mt-5">
                        <Button fullWidth htmlType="submit" loading={isLoading}>
                          Sign Up
                        </Button>
                      </div>
                      <div className="justify-center display-flex align-center mt-7 pr-2">
                        Already have an account?
                        <Link to={AppRoutes.LOGIN}>
                          <Button type={BUTTON_TYPES.TEXT} className="p-0 ml-2">
                            Login
                          </Button>
                        </Link>
                      </div>
                      <div className="sign-up-book-demo__container">
                        <Title
                          fontWeight={600}
                          size="rg"
                          color={COLOURS_GRAY.GRAY_800}
                          bottomSpacing={0}
                        >
                          Not sure where to begin?
                        </Title>
                        <Row>
                          <Col>
                            <div className="mr-1">
                              <Link
                                fontWeight={400}
                                fontSize={16}
                                to={{
                                  pathname:
                                    '//meetings.hubspot.com/sanders5/carbonhound-website-inbound'
                                }}
                                target="_blank"
                              >
                                Book a demo
                              </Link>
                            </div>
                          </Col>
                          <Col>
                            <Paragraph
                              fontSize={16}
                              color={COLOURS_GRAY.GRAY_500}
                            >
                              to see if Carbonhound is right for you
                            </Paragraph>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SignUp;
