function ContractYellow() {
  return (
    <svg
      width="58"
      height="77"
      viewBox="0 0 58 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.440674"
        y="8.0166"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="#EAB308"
      />
      <rect
        x="4.44946"
        y="4.00818"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="#FACC15"
      />
      <rect
        x="8.45728"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="#FDE047"
      />
      <path
        d="M38.5198 19.6324C38.5198 17.363 40.2823 14.0291 42.4565 14.0291H44.6039C46.7781 14.0291 48.5406 17.363 48.5406 19.6324C48.5406 21.9017 46.7781 20.753 44.6039 20.753H42.4565C40.2823 20.753 38.5198 21.9017 38.5198 19.6324Z"
        fill="#FEFCE8"
      />
      <ellipse
        cx="41.0249"
        cy="10.0208"
        rx="1.50313"
        ry="2.00417"
        fill="#FEFCE8"
      />
      <ellipse
        cx="45.0332"
        cy="10.0208"
        rx="1.50313"
        ry="2.00417"
        fill="#FEFCE8"
      />
      <ellipse
        cx="49.0417"
        cy="14.0291"
        rx="1.50313"
        ry="2.00417"
        fill="#FEFCE8"
      />
      <ellipse
        cx="38.0187"
        cy="14.0291"
        rx="1.50313"
        ry="2.00417"
        fill="#FEFCE8"
      />
      <rect
        x="13.4675"
        y="8.01662"
        width="14.0292"
        height="13.0271"
        rx="6.51354"
        fill="#FEFCE8"
      />
      <rect
        x="13.4675"
        y="30.0624"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#FEFCE8"
      />
      <rect
        x="13.4675"
        y="39.0812"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#FEFCE8"
      />
      <rect
        x="13.4675"
        y="48.0999"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#FEFCE8"
      />
      <rect
        x="13.4675"
        y="57.1188"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#FEFCE8"
      />
    </svg>
  );
}
export default ContractYellow;
