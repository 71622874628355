function GreenPaintbrush() {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="120" height="120" rx="60" fill="#ECFDF5" />
      <path
        d="M68.3643 56.4863L82.0143 58.3327L85.161 35.0696C85.392 33.3622 84.1951 31.7908 82.4877 31.5599V31.5599C80.8118 31.3332 79.2605 32.4831 78.99 34.1525L78.3473 38.1196C78.155 39.3064 77.0522 40.1238 75.8608 39.9627V39.9627C74.6246 39.7954 73.7694 38.6418 73.9689 37.4103L74.8216 32.1472C74.9628 31.2762 74.3579 30.4602 73.4835 30.3419V30.3419C72.6251 30.2258 71.835 30.8276 71.7189 31.6861L68.3643 56.4863Z"
        fill="#059669"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.79 50.8125L69.1368 78.1592C65.7102 81.5858 60.1433 81.7408 56.1675 79.1207C55.1902 78.4766 53.856 78.4555 53.0284 79.2831L45.5362 86.7753C42.1224 90.1891 36.5877 90.189 33.1739 86.7753C29.7602 83.3616 29.7602 77.8269 33.1739 74.4131L40.6662 66.9208C41.4938 66.0933 41.4726 64.759 40.8286 63.7818C38.2084 59.806 38.3634 54.2391 41.79 50.8125ZM36.0771 84.0599C37.5771 85.5599 40.0091 85.5599 41.509 84.0599C43.009 82.56 43.009 80.128 41.509 78.6281C40.0091 77.1281 37.5771 77.1281 36.0771 78.6281C34.5772 80.128 34.5772 82.56 36.0771 84.0599Z"
        fill="#A16207"
      />
      <path
        d="M57.898 34.7035C58.7256 33.876 60.0674 33.876 60.8949 34.7035L85.2448 59.0534C86.0723 59.8809 86.0723 61.2227 85.2448 62.0503L73.0698 74.2252L45.7231 46.8784L57.898 34.7035Z"
        fill="#FEFCE8"
      />
      <rect
        x="45.7227"
        y="46.877"
        width="38.6741"
        height="5.56271"
        transform="rotate(45 45.7227 46.877)"
        fill="#52525B"
      />
      <rect
        x="47.7832"
        y="61.6758"
        width="14.8339"
        height="2.11913"
        rx="1.05956"
        transform="rotate(45 47.7832 61.6758)"
        fill="#854D0E"
      />
      <path
        d="M82.248 56.0566L83.5592 57.3678L76.1606 64.7664C75.7985 65.1284 75.2115 65.1284 74.8494 64.7664V64.7664C74.4874 64.4043 74.4874 63.8173 74.8494 63.4552L82.248 56.0566Z"
        fill="#FEF9C3"
      />
      <path
        d="M78.3145 52.123L79.6256 53.4342L72.227 60.8328C71.8649 61.1949 71.2779 61.1949 70.9159 60.8328V60.8328C70.5538 60.4707 70.5538 59.8837 70.9159 59.5216L78.3145 52.123Z"
        fill="#FEF9C3"
      />
      <path
        d="M74.3809 48.1895L75.692 49.5006L64.9219 60.2707C64.5598 60.6328 63.9728 60.6328 63.6107 60.2707V60.2707C63.2487 59.9086 63.2487 59.3216 63.6107 58.9596L74.3809 48.1895Z"
        fill="#FEF9C3"
      />
      <path
        d="M70.4473 44.2559L71.7584 45.567L62.2994 55.026C61.9374 55.388 61.3504 55.388 60.9883 55.026V55.026C60.6262 54.6639 60.6262 54.0769 60.9883 53.7148L70.4473 44.2559Z"
        fill="#FEF9C3"
      />
      <path
        d="M66.5146 40.3242L67.8258 41.6354L62.3003 47.1609C61.9382 47.523 61.3512 47.523 60.9891 47.1609V47.1609C60.627 46.7988 60.627 46.2118 60.9891 45.8498L66.5146 40.3242Z"
        fill="#FEF9C3"
      />
      <path
        d="M62.5811 36.3887L63.8922 37.6998L53.4967 48.0953C53.1346 48.4574 52.5476 48.4574 52.1856 48.0953V48.0953C51.8235 47.7333 51.8235 47.1462 52.1856 46.7842L62.5811 36.3887Z"
        fill="#FEF9C3"
      />
      <path
        d="M63.7979 37.6055L73.5378 47.3454L64.6641 56.219C63.4616 57.4216 61.5118 57.4216 60.3093 56.219V56.219C59.1067 55.0165 59.1067 53.0667 60.3093 51.8642L61.7141 50.4594C62.5804 49.593 62.5804 48.1883 61.7141 47.322V47.322C60.8477 46.4556 59.4431 46.4556 58.5767 47.322L56.1183 49.7804C55.4976 50.4011 54.4913 50.4011 53.8706 49.7804V49.7804C53.2499 49.1597 53.2499 48.1534 53.8706 47.5327L63.7979 37.6055Z"
        fill="#10B981"
      />
    </svg>
  );
}
export default GreenPaintbrush;
