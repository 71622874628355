import {
  SourceCategoryTag,
  TransportationType,
  UserStatus
} from '../../../constants';

export const TAG_COLOURS = {
  GREEN: 'green',
  RED: 'red',
  BLUE: 'blue',
  VIOLET: 'violet',
  INDIGO: 'indigo',
  YELLOW: 'yellow',
  ORANGE: 'orange',
  GRAY: 'gray',
  FUCHSIA: 'fuchsia',
  PINK: 'pink',
  CYAN: 'cyan',
  TEAL: 'teal',
  RASPBERRY: 'raspberry',
  LIME: 'lime',
  SAND: 'sand',
  PEACH: 'peach',
  NAVY: 'navy',
  BRICK: 'brick',
  LAVENDER: 'lavender',
  FOREST: 'forest',
  DARK_BLUE: 'dark_blue',
  NIGHT_BLUE: 'night_blue'
};

export const USER_INVITE_STATUS_COLOURS = {
  [UserStatus.ACTIVE]: TAG_COLOURS.GREEN,
  [UserStatus.PENDING]: TAG_COLOURS.YELLOW,
  [UserStatus.DEACTIVATED]: TAG_COLOURS.RED
};

export const OnboardingStatus = {
  NOT_INVITED: 'Not Invited',
  INVITED: 'Invited',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
};

export const USER_ONBOARDING_STATUS_COLOURS = {
  [OnboardingStatus.COMPLETED]: TAG_COLOURS.GREEN,
  [OnboardingStatus.IN_PROGRESS]: TAG_COLOURS.YELLOW,
  [OnboardingStatus.INVITED]: TAG_COLOURS.ORANGE,
  [OnboardingStatus.NOT_INVITED]: TAG_COLOURS.RED
};

export const INTERNAL_TAG_COLOURS = {
  [SourceCategoryTag.BUSINESS_TRAVEL]: TAG_COLOURS.CYAN,
  [SourceCategoryTag.CAPITAL_GOODS]: TAG_COLOURS.YELLOW,
  [SourceCategoryTag.DOWNSTREAM_TRANSPORTATION_DISTRIBUTION]:
    TAG_COLOURS.RASPBERRY,
  [SourceCategoryTag.DOWNSTREAM_LEASED_ASSETS]: TAG_COLOURS.BRICK,
  [SourceCategoryTag.EMPLOYEE_COMMUTING]: TAG_COLOURS.TEAL,
  [SourceCategoryTag.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]: TAG_COLOURS.SAND,
  [SourceCategoryTag.FRANCHISES]: TAG_COLOURS.LAVENDER,
  [SourceCategoryTag.FUEL_ENERGY_RELATED_ACTIVITIES]: TAG_COLOURS.ORANGE,
  [SourceCategoryTag.FUGITIVE_EMISSIONS]: TAG_COLOURS.RED,
  [SourceCategoryTag.IMPORTED_ENERGY]: TAG_COLOURS.VIOLET,
  [SourceCategoryTag.INVESTMENTS]: TAG_COLOURS.GRAY,
  [SourceCategoryTag.MOBILE_COMBUSTION]: TAG_COLOURS.BLUE,
  [SourceCategoryTag.PROCESSING_OF_SOLD_PRODUCTS]: TAG_COLOURS.LIME,
  [SourceCategoryTag.PURCHASED_GOODS_SERVICES]: TAG_COLOURS.INDIGO,
  [SourceCategoryTag.STATIONARY_COMBUSTION]: TAG_COLOURS.GREEN,
  [SourceCategoryTag.UPSTREAM_LEASED_ASSETS]: TAG_COLOURS.PEACH,
  [SourceCategoryTag.UPSTREAM_TRANSPORTATION_DISTRIBUTION]: TAG_COLOURS.FUCHSIA,
  [SourceCategoryTag.USE_OF_SOLD_PRODUCTS]: TAG_COLOURS.NAVY,
  [SourceCategoryTag.WASTE_GENERATED_IN_OPERATIONS]: TAG_COLOURS.PINK,
  [SourceCategoryTag.OFFSETS]: TAG_COLOURS.FOREST,
  [SourceCategoryTag.NON_MECHANICAL]: TAG_COLOURS.DARK_BLUE
};

export const TRANSPORTATION_TYPE_COLOURS = {
  [TransportationType.CAR]: TAG_COLOURS.CYAN,
  [TransportationType.MOTORBIKE]: TAG_COLOURS.FUCHSIA,
  [TransportationType.LOCAL_BUS]: TAG_COLOURS.GREEN,
  [TransportationType.INTERCITY_TRAIN]: TAG_COLOURS.YELLOW,
  [TransportationType.STREETCAR_LRT]: TAG_COLOURS.RED,
  [TransportationType.SUBWAY]: TAG_COLOURS.NAVY,
  [TransportationType.BICYCLE]: TAG_COLOURS.INDIGO,
  [TransportationType.WALKING]: TAG_COLOURS.BRICK,
  WFH: TAG_COLOURS.RASPBERRY
};
