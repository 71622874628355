function PawContractAlt() {
  return (
    <svg
      width="74"
      height="91"
      viewBox="0 0 74 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.12598"
        y="8.43335"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="#D4D4D8"
      />
      <rect
        x="5.13452"
        y="4.42493"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="#E4E4E7"
      />
      <rect
        x="9.14258"
        y="0.416748"
        width="49.1021"
        height="68.1417"
        rx="2.00417"
        fill="white"
      />
      <path
        d="M39.2051 20.0491C39.2051 17.7798 40.9676 14.4459 43.1418 14.4459H45.2892C47.4634 14.4459 49.2259 17.7798 49.2259 20.0491C49.2259 22.3185 47.4634 21.1698 45.2892 21.1698H43.1418C40.9676 21.1698 39.2051 22.3185 39.2051 20.0491Z"
        fill="#D4D4D8"
      />
      <ellipse
        cx="41.7102"
        cy="10.4375"
        rx="1.50313"
        ry="2.00417"
        fill="#D4D4D8"
      />
      <ellipse
        cx="45.7185"
        cy="10.4375"
        rx="1.50313"
        ry="2.00417"
        fill="#D4D4D8"
      />
      <ellipse
        cx="49.727"
        cy="14.4458"
        rx="1.50313"
        ry="2.00417"
        fill="#D4D4D8"
      />
      <ellipse
        cx="38.7041"
        cy="14.4458"
        rx="1.50313"
        ry="2.00417"
        fill="#D4D4D8"
      />
      <rect
        x="14.1528"
        y="8.43335"
        width="14.0292"
        height="13.0271"
        rx="6.51354"
        fill="#E4E4E7"
      />
      <rect
        x="14.1528"
        y="30.4792"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#E4E4E7"
      />
      <rect
        x="14.1528"
        y="39.4979"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#E4E4E7"
      />
      <rect
        x="14.1528"
        y="48.5166"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#E4E4E7"
      />
      <rect
        x="14.1528"
        y="57.5356"
        width="40.0833"
        height="5.01042"
        rx="2.50521"
        fill="#E4E4E7"
      />
      <g filter="url(#filter0_d_11102_54532)">
        <path
          d="M48.6296 55.7163C47.731 56.482 46.6137 56.9448 45.4369 57.0387C44.1067 57.1445 42.8577 57.721 41.9142 58.6645C40.9706 59.6081 40.3942 60.857 40.2884 62.1872C40.1944 63.3641 39.7317 64.4814 38.966 65.38C38.1001 66.3955 37.6245 67.6862 37.6245 69.0207C37.6245 70.3552 38.1001 71.646 38.966 72.6614C39.7317 73.5601 40.1944 74.6773 40.2884 75.8542C40.3942 77.1844 40.9706 78.4333 41.9142 79.3769C42.8577 80.3205 44.1067 80.8969 45.4369 81.0027C46.6137 81.0966 47.731 81.5594 48.6296 82.3251C49.6451 83.1909 50.9359 83.6665 52.2703 83.6665C53.6048 83.6665 54.8956 83.1909 55.911 82.3251C56.8097 81.5594 57.927 81.0966 59.1038 81.0027C60.434 80.8969 61.683 80.3205 62.6265 79.3769C63.5701 78.4333 64.1465 77.1844 64.2523 75.8542C64.3463 74.6773 64.809 73.5601 65.5747 72.6614C66.4406 71.646 66.9162 70.3552 66.9162 69.0207C66.9162 67.6862 66.4406 66.3955 65.5747 65.38C64.809 64.4814 64.3463 63.3641 64.2523 62.1872C64.1465 60.857 63.5701 59.6081 62.6265 58.6645C61.683 57.721 60.434 57.1445 59.1038 57.0387C57.927 56.9448 56.8097 56.482 55.911 55.7163C54.8956 54.8505 53.6048 54.3749 52.2703 54.3749C50.9359 54.3749 49.6451 54.8505 48.6296 55.7163Z"
          fill="#FEF9C3"
        />
        <path
          d="M47.3372 73.6989C47.3372 71.4296 49.0997 68.0957 51.2739 68.0957H53.4212C55.5954 68.0957 57.358 71.4296 57.358 73.6989C57.358 75.9683 55.5954 74.8196 53.4212 74.8196H51.2739C49.0997 74.8196 47.3372 75.9683 47.3372 73.6989Z"
          fill="#FBAB1D"
        />
        <ellipse
          cx="49.8422"
          cy="64.0873"
          rx="1.50313"
          ry="2.00417"
          fill="#FBAB1D"
        />
        <ellipse
          cx="53.8508"
          cy="64.0873"
          rx="1.50313"
          ry="2.00417"
          fill="#FBAB1D"
        />
        <ellipse
          cx="57.8591"
          cy="68.0956"
          rx="1.50313"
          ry="2.00417"
          fill="#FBAB1D"
        />
        <ellipse
          cx="46.8361"
          cy="68.0956"
          rx="1.50313"
          ry="2.00417"
          fill="#FBAB1D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11102_54532"
          x="31.2009"
          y="47.9513"
          width="42.139"
          height="42.1389"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.21181" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11102_54532"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11102_54532"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export default PawContractAlt;
