import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useMeContext, useMetaContext } from '../../../../context';
import { handleCalculateDistance } from '../../../../helpers';
import {
  getAirportDropdownOptions,
  getDistanceUnit
} from '../../../../helpers/generators';
import { useLoadGooglePlaces } from '../../../../helpers/useLoadGooglePlaces';
import OpenFlightsService from '../../../../services/openFlightsService';
import AsyncSelect from '../../../atoms/AsyncSelect';

export function ToFromAirportFields() {
  useLoadGooglePlaces();
  const { me } = useMeContext();
  const { meta, setDistanceUnits } = useMetaContext();
  const { distanceUnits } = meta;

  const distanceUnitOption = distanceUnits.find((unit) => {
    const distanceUnit = getDistanceUnit(
      me.company?.headQuartersCountry?.isoName
    );
    return unit.unit === distanceUnit.toUpperCase();
  });
  const { setFieldValue, values } = useFormikContext();

  const handleSelectDestination = async ({ place, fieldKey }) => {
    const geometryObj = {
      lat: place.latitude,
      lng: place.longitute
    };
    setFieldValue(fieldKey, geometryObj);
    let distanceParams = {};
    if (fieldKey === 'destinationGeometry') {
      distanceParams = {
        originStop: {
          ...values?.departureGeometry,
          travelCategoryId: values?.travelCategoryId
        },
        destStop: geometryObj
      };
      setFieldValue('destinationAirport', place);
    } else if (fieldKey === 'departureGeometry') {
      distanceParams = {
        originStop: {
          ...geometryObj,
          travelCategoryId: values?.travelCategoryId
        },
        destStop: values?.destinationGeometry
      };
      setFieldValue('departureAirport', place);
    }
    await handleCalculateDistance({
      place: distanceParams,
      setFieldValue,
      travelCategoryName: values.travelCategoryName,
      distanceUnitOption,
      distanceFieldKey: 'distance'
    });
  };
  useEffect(() => {
    setDistanceUnits();
  }, []);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <AsyncSelect
            name="departureAirport"
            onChange={(_, place) => {
              handleSelectDestination({
                fieldKey: 'departureGeometry',
                place
              });
            }}
            getOptionsMethod={getAirportDropdownOptions}
            optionKeys={{ label: 'name', value: 'ICAO' }}
            searchMethod={OpenFlightsService.searchAirports}
            setFieldValue={setFieldValue}
            shouldReturnWholeValueOnSelect
            title="Departure"
            placeholder="Select"
            defaultValue={values.departureAirport?.name}
          />
        </Col>
        <Col span={12}>
          <AsyncSelect
            name="destinationAirport"
            onChange={(_, place) => {
              handleSelectDestination({
                fieldKey: 'destinationGeometry',
                place
              });
            }}
            getOptionsMethod={getAirportDropdownOptions}
            optionKeys={{ label: 'name', value: 'ICAO' }}
            searchMethod={OpenFlightsService.searchAirports}
            setFieldValue={setFieldValue}
            shouldReturnWholeValueOnSelect
            title="Destination"
            placeholder="Select"
            value={values.destinationAirport?.name}
          />
        </Col>
      </Row>
    </div>
  );
}
