import { Col, Divider, Modal as AntModal, Row } from 'antd';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import { Form, Formik } from 'formik';
import { Checkbox } from '../../../components';
import { useCHCContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { Paragraph, Select, Title } from '../../../stories/atoms';
import ModalFooter from '../../../stories/atoms/ModalFooter';
import { Blurb } from '../../../stories/molecules';
import './CarbonhoundConnectHistoricalImport.scss';
import { CarbonhoundConnectHistoricalImportValidation } from './CarbonhoundConnectHistoricalImportValidation';

export function CarbonhoundConnectHistoricalImport({
  handleNext,
  analyticsContext
}) {
  const { CHCState, updateCompanyProvider } = useCHCContext();
  const { me } = useMeContext();
  const yearRange = eachYearOfInterval({
    start: new Date('2018-1-1'),
    end: new Date()
  });

  const historicalYearOptions = yearRange
    .map((year) => ({
      label: new Date(year).getFullYear(),
      value: new Date(year).getFullYear()
    }))
    .reverse();

  const handleUpdateCompanyIntegration = async (values) => {
    try {
      await updateCompanyProvider({
        ...values,
        companySlug: me.company?.slug,
        integrationSlug: 'carbonhound-connect',
        companyUtilityProviderId: CHCState.companyUtilityProviderId
      });

      analytics.track('Step Completed', me, {
        ...analyticsContext,
        level3: 'Import Settings',
        utilityProvider: CHCState
      });
      handleNext();
    } catch (e) {
      console.error(e);
    }
  };

  const { execute: handleSubmit, isLoading: isSavingCompanyIntegration } =
    useAsync({
      asyncFunction: handleUpdateCompanyIntegration,
      immediate: false
    });

  const handleConfirmHistoricalImport = async (values) => {
    if (values.includeHistoricalData) {
      AntModal.confirm({
        className: 'ch-modal',
        icon: null,
        title: (
          <Title size="md">
            Are you sure you’d like to import historical data?
          </Title>
        ),
        okText: 'Import',
        cancelText: 'Cancel',
        content:
          'There is a chance you will create duplicate data entries to the ones you have already imported/created.',
        onOk: async () => {
          await handleSubmit(values);
        }
      });
    } else {
      await handleSubmit(values);
    }
  };
  return (
    <Formik
      validationSchema={CarbonhoundConnectHistoricalImportValidation}
      initialValues={{
        includeHistoricalData: false,
        carbonYears: [],
        includeOngoingData: false
      }}
      onSubmit={handleConfirmHistoricalImport}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row
            gutter={[0, 32]}
            className="carbonhound-connect-historical-import"
          >
            <Col span={24}>
              <Title bottomSpacing={0}>Settings</Title>
            </Col>
            <Col span={24}>
              <Checkbox
                checked={values?.includeOngoingData}
                setFieldValue={setFieldValue}
                name="includeOngoingData"
              >
                <Row align="middle">
                  <Col flex={1}>
                    <Title size="md" bottomSpacing={0}>
                      Ongoing Data Management{' '}
                      <span className="text-rg">(1 credit/data point)</span>
                    </Title>
                    <Paragraph bottomSpacing={0}>
                      Your data will be imported monthly
                    </Paragraph>
                  </Col>
                </Row>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Divider orientationMargin={0} />
              <Checkbox
                checked={values?.includeHistoricalData}
                setFieldValue={setFieldValue}
                name="includeHistoricalData"
              >
                <Row align="middle">
                  <Col flex={1}>
                    <Title size="md" bottomSpacing={0}>
                      Historical Data Import
                      <span className="text-rg">(1 credit/data point)</span>
                    </Title>
                    <Paragraph bottomSpacing={0}>
                      Import all your historical data for selected years.{' '}
                      <span className="text-danger">
                        This may cause duplicate data.
                      </span>
                    </Paragraph>
                  </Col>
                </Row>
              </Checkbox>
            </Col>

            {values?.includeHistoricalData && (
              <Col span={24}>
                <Select
                  mode="multiple"
                  title="Years to Import"
                  options={historicalYearOptions}
                  name="carbonYears"
                  value={values?.carbonYears}
                  setFieldValue={setFieldValue}
                />
              </Col>
            )}
          </Row>
          <Row className="carbonhound-connect-historical-import__gray-bg mt-8">
            <Col span={24}>
              <Blurb titleProps={{ size: 'md', title: 'Provider Data' }}>
                <Paragraph bottomSpacing={0}>
                  Provider data varies from one to the other. Typically we get 1
                  year of data, but the amount of coverage will depend on your
                  provider. We can’t pull anything more than 5 years in the
                  past.
                </Paragraph>
              </Blurb>
            </Col>
          </Row>
          <ModalFooter
            showHelpLink={false}
            showCancelButton={false}
            saveButtonCopy="Next"
            saveButtonProps={{
              isLoading: isSavingCompanyIntegration,
              htmlType: 'submit'
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
