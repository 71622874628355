import { useCallback } from 'react';
import { useAsync } from '../../hooks';
import { EmissionFactorService } from '../../services';
import RelatedEmissionFactor from '../../stories/molecules/RelatedEmissionFactor';

function RelatedEmissionFactorList({ efDetailsId }) {
  const handleLoadCollectionList = useCallback(
    async () =>
      await EmissionFactorService.listEmissionFactorDetailsById({
        efDetailsId
      }),
    [efDetailsId]
  );
  const { Loader, value: collectionsList } = useAsync({
    defaultValue: [],
    asyncFunction: handleLoadCollectionList
  });
  return (
    <div className="related-emission-factor-list">
      <Loader>
        {collectionsList.map((ci) => (
          <RelatedEmissionFactor key={ci.id} emissionFactorCollection={ci} />
        ))}
      </Loader>
    </div>
  );
}
export default RelatedEmissionFactorList;
