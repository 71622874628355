import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Collapse as AntCollapse } from 'antd';
import React from 'react';
import './collapse.scss';
// TODO revisit this component after carbon explorer to ensure it functions as a standalone collapse
function Collapse(props) {
  return (
    <AntCollapse
      expandIcon={({ isActive }) => {
        if (isActive) {
          return <RightOutlined />;
        }
        return <DownOutlined />;
      }}
      {...props}
      className={`ch-collapse${props.className ? ` ${props.className}` : ''}`}
    />
  );
}

export default Collapse;
