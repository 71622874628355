import { Col, Menu, Row, Steps as AntSteps } from 'antd';
import React, { useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import employeeCommute from '../../assets/images/employee-commute.png';
import inviteTeam from '../../assets/images/inviteTeam.png';
import officeOnboarding from '../../assets/images/office-onboarding.png';
import setGoals from '../../assets/images/set-goals.png';
import travelExpenses from '../../assets/images/travel-expenses.png';
import { StepStatus, TargetType, UserRole, UserType } from '../../constants';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import OfficeTrailSegment from '../../constants/officeTrailSegment';
import {
  useEmployeeCommuteContext,
  useMeContext,
  useOfficeOnboardingContext,
  useOfficeTrailContext
} from '../../context';
import { capitalizeText } from '../../helpers';
import { AppRoutes } from '../../routes';
import truckIcon from '../../stories/assets/svgs/truckIcon.svg';
import { Icon, Link, Logo, Paragraph, Title } from '../../stories/atoms';
import Steps from '../Steps';
import './trailSegmentsSidebar.scss';

const { SubMenu } = Menu;

function TrailSegmentsSidebar() {
  const navigate = useNavigate();

  const location = useLocation();

  const { officeTrailDetails, refreshOfficeTrails } = useOfficeTrailContext();
  const officeOnboardingRoutes = [
    AppRoutes.OFFICE_ONBOARDING_OVERVIEW,
    AppRoutes.OFFICE_ONBOARDING_LOCATION,
    AppRoutes.OFFICE_ONBOARDING_OFFICE,
    AppRoutes.OFFICE_ONBOARDING_BILLS,
    AppRoutes.CUSTOM_SOURCES_TRAIL
  ];

  const employeeCommuteRoutes = [
    AppRoutes.EMPLOYEE_COMMUTE_OVERVIEW,
    AppRoutes.EMPLOYEE_COMMUTE_ADDRESS_NEW,
    AppRoutes.EMPLOYEE_COMMUTE_STEPS_NEW,
    AppRoutes.EMPLOYEE_COMMUTE_DETAILS_NEW
  ];
  const employeeTargetRoutes = [
    ...(location.state?.from?.pathname === AppRoutes.TARGET_SETTING_BASELINE ||
    location.pathname === AppRoutes.TARGET_SETTING_BASELINE
      ? [AppRoutes.TARGET_SETTING_BASELINE]
      : []),
    AppRoutes.TARGET_SETTING_STRATEGY.replace('/:targetDetailsId', ''),

    ...(location.state?.type === TargetType.INTENSITY ||
    location.pathname.startsWith(
      AppRoutes.TARGET_SETTING_DATA.replace('/:targetDetailsId', '')
    )
      ? [AppRoutes.TARGET_SETTING_DATA.replace('/:targetDetailsId', '')]
      : []),
    AppRoutes.TARGET_SETTING_REDUCTIONS.replace('/:targetDetailsId', '')
  ];

  const items = [
    { key: '1', path: AppRoutes.OFFICE_ONBOARDING },
    { key: '2', path: AppRoutes.EMPLOYEE_COMMUTE },
    { key: '3', path: AppRoutes.BUSINESS_TRAVEL_OVERVIEW },
    { key: '4', path: AppRoutes.CUSTOM_SOURCES_TRAIL },
    { key: '5', path: AppRoutes.TARGET_SETTING }
  ];

  const [selectedKey, setSelectedKey] = useState(() => {
    const item = items.find((_item) =>
      location.pathname.startsWith(_item.path)
    );
    return item ? item?.key : '1';
  });

  const [activeOfficeStep, setActiveOfficeStep] = useState(() => {
    const itemIndex = officeOnboardingRoutes.findIndex((route) =>
      location.pathname.startsWith(route)
    );
    return itemIndex >= 0 ? itemIndex : 0;
  });

  const [activeCommuteStep, setActiveCommuteStep] = useState(() => {
    const itemIndex = employeeCommuteRoutes.findIndex((route) =>
      location.pathname.startsWith(route)
    );
    return itemIndex >= 0 ? itemIndex : 0;
  });
  const [activeTargetStep, setActiveTargetStep] = useState(() => {
    const itemIndex = employeeTargetRoutes.findIndex((route) =>
      location.pathname.startsWith(route)
    );
    return itemIndex >= 0 ? itemIndex : 0;
  });

  const { me } = useMeContext();

  const isAdmin =
    me?.company?.role?.permissionType === UserRole.ADMIN ||
    me?.userType === UserType.INTERNAL;

  const { employeeCommuteDetails } = useEmployeeCommuteContext();

  const { officeOnboardingDetails, setOfficeOnboardingActiveStep } =
    useOfficeOnboardingContext();

  const handleOfficeOverview = () => {
    setOfficeOnboardingActiveStep(0);
    navigate(AppRoutes.OFFICE_ONBOARDING);
  };

  const handleOfficeCommuteOverview = () => {
    navigate(AppRoutes.EMPLOYEE_COMMUTE);
  };

  const handleBusinessTravelOverview = () => {
    navigate(AppRoutes.BUSINESS_TRAVEL_OVERVIEW);
  };

  const handleCustomSources = () => {
    navigate(AppRoutes.CUSTOM_SOURCES_TRAIL);
  };

  const handleTargetSetting = () => {
    navigate(AppRoutes.TARGET_SETTING_OVERVIEW);
  };

  const getTrailStatusIcon = (trailName) =>
    officeTrailDetails?.officeTrailStatus[trailName] ===
    StepStatus.INCOMPLETE ? null : officeTrailDetails?.officeTrailStatus[
        trailName
      ] === StepStatus.COMPLETE ? (
      <i className="icon-success" />
    ) : null;

  // Ensures that the list of energy types puts true values at the top
  const currentOfficeEnergyTypes =
    officeOnboardingDetails?.activeCompanyLocation?.energyTypes?.filter(
      (energyType) => energyType?.hasAccessToBills
    );
  useEffect(() => {
    setSelectedKey(() => {
      const item = items.find((_item) =>
        location.pathname.startsWith(_item.path)
      );
      return item ? item?.key : '1';
    });
    setActiveOfficeStep(() => {
      const itemIndex = officeOnboardingRoutes.findIndex((route) =>
        location.pathname.startsWith(route)
      );
      return itemIndex >= 0 ? itemIndex : 0;
    });
    setActiveCommuteStep(() => {
      const itemIndex = employeeCommuteRoutes.findIndex((route) =>
        location.pathname.startsWith(route)
      );
      return itemIndex >= 0 ? itemIndex : 0;
    });
    setActiveTargetStep(() => {
      const itemIndex = employeeTargetRoutes.findIndex((route) =>
        location.pathname.startsWith(route)
      );
      return itemIndex;
    });
  }, [location]);
  useEffect(() => {
    refreshOfficeTrails();
  }, []);

  return (
    <div className="trail-segments-sidebar">
      <div className="trail-segments-sidebar__logo-wrapper">
        <Link to={AppRoutes.DASHBOARD}>
          <Logo className="trail-segments-sidebar__logo" />
        </Link>
      </div>
      <div className="trail-segments-sidebar__trail-wrapper">
        <span className="trail-segments-sidebar__current-trail">
          Office based operations
        </span>
      </div>
      <Menu
        className="trail-segments-sidebar__menu"
        mode="inline"
        selectedKeys={[selectedKey]}
        openKeys={[selectedKey]}
      >
        {isAdmin && (
          <SubMenu
            key="1"
            onTitleClick={handleOfficeOverview}
            icon={<img src={officeOnboarding} alt="Office onboarding" />}
            title={
              <div className="trail-segments-sidebar__menu-title">
                <span>Office onboarding</span>
                {getTrailStatusIcon(OfficeTrailSegment.ENERGY)}
              </div>
            }
          >
            <div
              onClick={handleOfficeOverview}
              className={`trail-segments-sidebar__overview ${
                officeOnboardingDetails.activeStep === 0 ? 'active' : ''
              }`}
            >
              <i className="icon-overview mr-2" />
              <span>Overview</span>
            </div>
            {activeOfficeStep > 0 && (
              <>
                <div className="trail-segments-sidebar__location-title">
                  <i className="icon-location mr-2" />
                  <span>
                    {officeOnboardingDetails?.activeCompanyLocation
                      ? officeOnboardingDetails?.activeCompanyLocation?.location
                          ?.address1
                      : `Office # ${
                          officeOnboardingDetails?.companyLocations?.length + 1
                        }`}
                  </span>
                </div>
                <Steps current={activeOfficeStep - 1} direction="vertical">
                  <AntSteps.Step title="Office Location" />
                  <AntSteps.Step title="Office details" />
                  <AntSteps.Step
                    title={
                      activeOfficeStep === 3 ? (
                        <div>
                          <div>Energy Usage</div>
                          <ul className="trail-segments-sidebar__step-ul">
                            {officeOnboardingDetails?.activeCompanyLocation
                              ?.energyTypes &&
                              currentOfficeEnergyTypes.map((energyType) => (
                                <li
                                  className={`trail-segments-sidebar__step-li ${
                                    energyType?.energyTypeId ===
                                    officeOnboardingDetails?.activeEnergyTypeId
                                      ? 'active'
                                      : ''
                                  } cursor-pointer`}
                                  key={energyType?.energyTypeId}
                                  onClick={() => {
                                    navigate(
                                      generatePath(
                                        AppRoutes.OFFICE_ONBOARDING_MANAGE_BILLS_ENERGY_TYPE,
                                        {
                                          collectionId:
                                            officeOnboardingDetails
                                              ?.activeCompanyLocation
                                              ?.collectionId,
                                          energyDetailsId:
                                            officeOnboardingDetails
                                              ?.activeCompanyLocation
                                              ?.energyDetailsId,
                                          energyTypeId: energyType?.energyTypeId
                                        }
                                      )
                                    );
                                  }}
                                >
                                  {capitalizeText(energyType.energyType)}
                                </li>
                              ))}
                          </ul>
                        </div>
                      ) : (
                        'Energy Usage'
                      )
                    }
                  />
                </Steps>
              </>
            )}
          </SubMenu>
        )}

        <SubMenu
          key="2"
          onTitleClick={handleOfficeCommuteOverview}
          icon={<img src={employeeCommute} alt="Employee Details" />}
          title={
            <div className="trail-segments-sidebar__menu-title">
              <span>Employee Details</span>
              {getTrailStatusIcon(OfficeTrailSegment.COMMUTE)}
            </div>
          }
        >
          <div
            onClick={handleOfficeCommuteOverview}
            className={`trail-segments-sidebar__overview ${
              location.pathname === AppRoutes.EMPLOYEE_COMMUTE_OVERVIEW
                ? 'active'
                : ''
            }`}
          >
            <i className="icon-overview mr-2" />
            <span>Overview</span>
          </div>
          {location.pathname === AppRoutes.EMPLOYEE_COMMUTE_INVITE_TEAM && (
            <div className="trail-segments-sidebar__overview active">
              <img src={inviteTeam} alt="Invite Team" className="mr-2" />
              <span>Invite Team</span>
            </div>
          )}
          {activeCommuteStep > 0 && (
            <>
              <div className="trail-segments-sidebar__location-title">
                <i className="icon-commute mr-4" />
                <span>
                  {employeeCommuteDetails?.activeCommute
                    ? employeeCommuteDetails?.activeCommute?.homeLocation
                        ?.address1
                    : `Commute #${
                        employeeCommuteDetails?.commutes?.length + 1
                      }`}
                </span>
              </div>
              <Steps
                current={activeCommuteStep - 1}
                direction="vertical"
                style={{ paddingLeft: '100px' }}
              >
                <AntSteps.Step title="Start and finish addresses" />
                <AntSteps.Step title="Commute steps" />
                <AntSteps.Step title="Commute details" />
              </Steps>
            </>
          )}
        </SubMenu>
        <SubMenu
          key="3"
          onTitleClick={handleBusinessTravelOverview}
          icon={<img src={travelExpenses} alt="Travel expenses" />}
          title={
            <div className="trail-segments-sidebar__menu-title">
              <span>Travel expenses</span>
              {getTrailStatusIcon(OfficeTrailSegment.BUSINESS_TRAVEL)}
            </div>
          }
        >
          <div
            onClick={handleBusinessTravelOverview}
            className={`trail-segments-sidebar__overview ${
              location.pathname === AppRoutes.CUSTOM_SOURCES_TRAIL
                ? 'active'
                : ''
            }`}
          >
            <i className="icon-overview mr-2" />
            <span>Overview</span>
          </div>
        </SubMenu>
        {isAdmin && (
          <SubMenu
            key="4"
            onTitleClick={handleCustomSources}
            icon={<img src={truckIcon} alt="Set custom sources" />}
            title={
              <div className="trail-segments-sidebar__menu-title">
                <span>Custom Sources</span>
                {getTrailStatusIcon(OfficeTrailSegment.CUSTOM_SOURCES)}
              </div>
            }
          >
            <div
              onClick={handleBusinessTravelOverview}
              className={`trail-segments-sidebar__overview ${
                location.pathname === AppRoutes.CUSTOM_SOURCES_TRAIL
                  ? 'active'
                  : ''
              }`}
            >
              <i className="icon-overview mr-2" />
              <span>Overview</span>
            </div>
          </SubMenu>
        )}
        {isAdmin && (
          <SubMenu
            key="5"
            icon={<img src={setGoals} alt="Set targets" />}
            onTitleClick={handleTargetSetting}
            title={
              <div className="trail-segments-sidebar__menu-title">
                <span>Set targets</span>
                {getTrailStatusIcon(OfficeTrailSegment.TARGET)}
              </div>
            }
          >
            <div
              onClick={handleTargetSetting}
              className={`trail-segments-sidebar__overview ${
                location.pathname === AppRoutes.TARGET_SETTING_OVERVIEW
                  ? 'active'
                  : ''
              }`}
            >
              <i className="icon-overview mr-2" />
              <span>Overview</span>
            </div>

            {activeTargetStep > -1 && (
              <Steps
                current={activeTargetStep}
                direction="vertical"
                style={{ paddingLeft: '100px' }}
              >
                {(location.pathname === AppRoutes.TARGET_SETTING_BASELINE ||
                  location.state?.from?.pathname ===
                    AppRoutes.TARGET_SETTING_BASELINE) && (
                  <AntSteps.Step title="Baseline year" />
                )}
                <AntSteps.Step title="Target strategy" />
                {(location.pathname.startsWith(
                  AppRoutes.TARGET_SETTING_DATA.replace(':targetDetailsId', '')
                ) ||
                  location.state?.type === TargetType.INTENSITY) && (
                  <AntSteps.Step title="Data entry" />
                )}
                <AntSteps.Step title="Reduction plan" />
              </Steps>
            )}
          </SubMenu>
        )}
      </Menu>
      <Row className="trail-segments-sidebar__help">
        <Col span={24}>
          <Row className="trail-segments-sidebar__help__inner" gutter={[0, 24]}>
            <Col span={24} className="display-flex justify-center">
              <div className="trail-segments-sidebar__help__icon-wrapper">
                <Icon
                  size={18}
                  name="Phone"
                  color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                />
              </div>
            </Col>
            <Col span={24}>
              <Title align="center" size="rg">
                Need help?
              </Title>
              <Paragraph bottomSpacing={0} align="center">
                We can help you with your onboarding, or connect you with an
                expert to address your unique needs.
              </Paragraph>
            </Col>
            <Col span={24} className="display-flex justify-center">
              <Link fontWeight={600} to={AppRoutes.HELP}>
                Get help
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default TrailSegmentsSidebar;
