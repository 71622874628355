import { formatInTimeZone } from 'date-fns-tz';
import { Notification } from '../components';
import { getUnitFriendlyName } from '../helpers/generators';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';
import apiRoutes from '../routes/apiRoutes';

class EnergyService {
  static async fetchEnergies(collectionId, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.TRAIL_ENERGIES}/${collectionId}/list`;
      const response = await axiosInstance.get(API_URL);
      onSuccess?.(response.data?.energyDetails);
      return response.data?.energyDetails;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static fetchCompanyTrailEnergies(companySlug, onSuccess, onError, onFinal) {
    const API_URL = ApiRoutes.COMPANY_TRAIL_ENERGIES.replace(
      ':companySlug',
      companySlug
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data.energyDetails);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchOfficeTrailSummary(params, onSuccess, onError, onFinal) {
    axiosInstance
      .get(ApiRoutes.OFFICE_TRAIL_OVERVIEW_SUMMARY, { params })
      .then((response) => {
        if (response?.data?.emissionSummary) {
          onSuccess(
            response.data.emissionSummary.map(({ totalEmissions, date }) => ({
              totalEmissions,
              month: formatInTimeZone(new Date(date), 'UTC', 'MMM yy')
            }))
          );
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  // If "emissionSourceDetailsId" is available, use the EmissionSourceService.fetchEmissionSourceDetailsTimeline instead
  static async fetchOfficeTimelineSummary(
    { energyDetailsId, energyTypeId, rangeStart, rangeEnd },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.OFFICE_ENERGY_TIMELINE_SUMMARY}/${energyDetailsId}`;
    try {
      const response = await axiosInstance.get(API_URL, {
        params: {
          energyTypeId,
          rangeStart,
          rangeEnd
        }
      });
      onSuccess?.(response.data?.timeline);
      return response.data?.timeline;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static fetchOfficeActivitySummary(params, onSuccess, onError, onFinal) {
    axiosInstance
      .get(ApiRoutes.OFFICE_ENERGY_ACTIVITY_SUMMARY, { params })
      .then((response) => {
        if (response?.data?.activitySummary) {
          onSuccess(
            response.data.activitySummary.map((summary) => ({
              emissions: summary.totalActivity,
              month: formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
            }))
          );
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createEnergy(collectionId, energy, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.TRAIL_ENERGIES}/${collectionId}`;
    axiosInstance
      .post(API_URL, energy, { params: { clearEstimations: true } })
      .then((response) => {
        onSuccess?.(response.data.id);
        Notification({
          type: 'success',
          message: 'Energy details',
          description: 'Energy details created'
        });
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }

  static updateEnergy(collectionId, energy, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.TRAIL_ENERGIES}/${energy.energyDetailsId}`;
    axiosInstance
      .put(API_URL, energy)
      .then((_response) => {
        onSuccess?.(energy.energyDetailsId);
      })
      .catch((error) => {
        onError?.(error);
      })
      .finally(() => {
        onFinal?.();
      });
  }

  static async fetchEnergyItems(
    energyDetailsId,
    { pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const baseUrl = ApiRoutes.ENERGY_ITEM.replace(
      ':energyDetailsId',
      energyDetailsId
    );

    const API_URL = `${baseUrl}/list`;
    try {
      const response = await axiosInstance.post(
        API_URL,
        { pagination },
        { params }
      );
      const formattedEnergyItems = response.data?.energyItems.map(
        (energyItem) => ({
          ...energyItem,
          activityUnit: {
            ...energyItem.activityUnit,
            friendlyName: getUnitFriendlyName(energyItem.activityUnit)
          }
        })
      );
      onSuccess?.({
        list: formattedEnergyItems,
        count: Number(response.data.totalRowCount)
      });
      return {
        list: formattedEnergyItems,
        count: Number(response.data.totalRowCount)
      };
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static createEnergyItem(
    energyDetailsId,
    energyItem,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.ENERGY_ITEM.replace(
      ':energyDetailsId',
      energyDetailsId
    );
    axiosInstance
      .post(API_URL, energyItem)
      .then((response) => {
        Notification({
          type: 'success',
          message: 'Create Energy item',
          description: 'Energy item created'
        });
        onSuccess(response.data.energyItem);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static showEnergyItem(energyItemId, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.INDIVIDUAL_ENERGY_ITEM}/${energyItemId}`;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data.energyItem);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateEnergyItem(energyItem, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.INDIVIDUAL_ENERGY_ITEM}/${energyItem.id}`;
    axiosInstance
      .put(API_URL, energyItem)
      .then((response) => {
        Notification({
          type: 'success',
          message: 'Update Energy item',
          description: 'Energy item updated'
        });
        onSuccess(response.data.id);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async deleteEnergyItem(energyItem, onSuccess, onError, onFinal) {
    const API_URL = `${ApiRoutes.INDIVIDUAL_ENERGY_ITEM}/${energyItem.id}`;

    try {
      await axiosInstance.delete(API_URL, energyItem);
      Notification({
        type: 'success',
        message: 'Delete Energy item',
        description: 'Energy item deleted'
      });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async listEnergyEmissionSummaries(
    params,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = apiRoutes.ENERGY_EMISSION_SUMMARIES;
    await axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        if (response?.data?.summary) {
          onSuccess(response.data.summary);
        }
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async listEnergyEmissionSummariesByCompany(
    params,
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = apiRoutes.ENERGY_EMISSION_SUMMARIES_COMPANY;
      const response = await axiosInstance.get(API_URL, { params });

      onSuccess?.(response.data?.emissionSummary);
      return response.data?.emissionSummary;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async listEnergyOfficeLocations(
    companySlug,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.TRIAL_ENERGY_COMPANY_LOCATION.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = await axiosInstance.post(`${API_URL}/list`);
      onSuccess?.(response.data.companies);
      return response.data.companies;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async hardDeleteEnergyItems(
    energyDetailsId,
    { uploadProcessId, _energyTypeId },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.ENERGY_ITEM_HARD_CLEAR.replace(
      ':energyDetailsId',
      energyDetailsId
    );
    try {
      await axiosInstance.delete(`${API_URL}`, {
        data: {
          uploadProcessId
        }
      });
      onSuccess?.();
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }
}

export default EnergyService;
