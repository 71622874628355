import { Menu } from 'antd';
import classNames from 'classnames';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import Icon from '../Icon';
import './MenuItem.scss';

export function MenuItem({
  children,
  className,
  successIcon = 'Check',
  activeIcon = 'CircleFilled',
  stepName,
  _key,
  currentStep,
  completedSteps,
  ...props
}) {
  const isCurrentlyActive = currentStep === stepName;
  const isStepCompleted = completedSteps?.indexOf(stepName) > -1;
  return (
    <Menu.Item
      className={classNames('ch-menu-item', { [className]: !!className })}
      {...props}
    >
      <span>{children}</span>
      {(isCurrentlyActive || isStepCompleted) && (
        <Icon
          name={
            isStepCompleted ? successIcon : isCurrentlyActive ? activeIcon : ''
          }
          color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
        />
      )}
    </Menu.Item>
  );
}
