function Navigation({ width = '72', height = '73' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6838 53.2279L29 58V19L15.2873 14.8862C14.6457 14.6937 14 15.1742 14 15.844V52.2792C14 52.7097 14.2754 53.0918 14.6838 53.2279Z"
        fill="#A78BFA"
      />
      <path
        d="M44 53L57.6838 57.5613C58.3313 57.7771 59 57.2951 59 56.6126V19.744C59 19.3024 58.7103 18.9131 58.2873 18.7862L44 14.5V53Z"
        fill="#A78BFA"
      />
      <path d="M44 53L29 58V19L44 14.5V53Z" fill="#7C3AED" />
      <path
        d="M18.3022 26.6482C18.8576 28.2778 19.9865 29.6841 21.4996 31.5C22.8169 29.9569 23.7633 28.7835 24.4036 27.6337C25.8891 24.9665 24.1464 20.8002 21.1233 21.2265C18.7596 21.5598 17.5321 24.3887 18.3022 26.6482Z"
        fill="#5C29C7"
      />
      <circle cx="21.5" cy="25" r="1.5" fill="white" />
      <path
        d="M50 42.5V29.75C50 26.0221 46.9779 23 43.25 23V23C39.5221 23 36.5 26.0221 36.5 29.75V40.25C36.5 44.5302 33.0302 48 28.75 48V48C24.4698 48 21 44.5302 21 40.25V34.5"
        stroke="#5C29C7"
        strokeDasharray="3 3"
      />
      <path
        d="M47 42.5L53 48.5M47 48.5L53 42.5L47 48.5Z"
        stroke="#ED2861"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Navigation;
