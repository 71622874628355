import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { Card, Loader } from '../../../../components';
import {
  BulkUploadType,
  EmissionSourceItemOrigin,
  EmissionSourceItemOriginCopy
} from '../../../../constants';
import { COLOURS_GRAY } from '../../../../constants/colours';
import DateFormats from '../../../../constants/dateFormats';
import { useMeContext } from '../../../../context';
import { analytics, capitalizeText, formatDecimal } from '../../../../helpers';
import { useAsync, useModal, usePagination } from '../../../../hooks';
import EmissionSourceService from '../../../../services/emissionSourceService';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { CompletionTimeline } from '../../../../stories/molecules';
import DataRequestTemplate from '../../../../stories/molecules/DataRequestTemplate';
import TableTitle from '../../../../stories/molecules/TableTitle';
import BulkUploadForm from '../../../BulkUploadForm';
import EmissionSourceItemForm from '../EmissionSourceItemForm';

function EmissionSourceItemTable({
  emissionSourceDetails,
  analyticsContext,
  refreshChart,
  timeline,
  isLoadingTimeline,
  refreshTimeline
}) {
  const { me } = useMeContext();
  const [sourceItemList, setSourceItemList] = useState([]);
  const [activeItem, setActiveItem] = useState({});
  const [dateRange, setDateRange] = useState(null);
  const isEstimate = emissionSourceDetails?.internalDetails?.isEstimate;
  const isRangeType = emissionSourceDetails
    ? emissionSourceDetails?.dateType === 'RANGE'
    : undefined;
  let orderBy;
  if (emissionSourceDetails) {
    orderBy = isRangeType ? ['startDate', 'id'] : ['date', 'id'];
  }
  const { paginationParams, handleSetPaginationParams } = usePagination({
    pageSize: 30,
    paginationArray: sourceItemList,
    orderBy
  });

  const {
    Modal: BUModal,
    handleShowModal: handleShowBUModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Upload data clicked', me, {
        ...analyticsContext
      });
    },
    width: '90%'
  });

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  useEffect(() => {
    if (orderBy) {
      handleSetPaginationParams({
        ...paginationParams,
        orderBy
      });
    }
  }, [isRangeType]);

  const handleLoadTableData = useCallback(async () => {
    if (
      !!paginationParams.orderBy &&
      !!emissionSourceDetails &&
      emissionSourceDetails?.id
    ) {
      const { list, count } =
        await EmissionSourceService.listEmissionSourceItems({
          emissionSourceDetailsId: emissionSourceDetails?.id,
          pagination: paginationParams,
          ...(dateRange?.length > 1 && {
            rangeStart: formatInTimeZone(
              new Date(dateRange?.[0]),
              'UTC',
              DateFormats.API
            ),
            rangeEnd: formatInTimeZone(
              new Date(dateRange?.[1]),
              'UTC',
              DateFormats.API
            )
          })
        });
      setSourceItemList(list);
      return count;
    }
    return 0;
  }, [
    emissionSourceDetails?.id,
    paginationParams.orderBy,
    paginationParams.order,
    paginationParams.pageNumber,
    dateRange
  ]);
  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData
  });
  const handleDeleteItem = async (item) => {
    await EmissionSourceService.deleteEmissionSourceItem(item.id);
    refreshList();
  };

  const { Modal, handleShowModal, handleCloseModal } = useModal({
    width: '60%',
    onCloseModal: () => {
      refreshList();
      setActiveItem(undefined);
    }
  });

  const handleShowNewItemModal = () => {
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      level4: 'Item Modal'
    });
    handleShowModal();
  };
  const handleCloseCreateItemModal = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      emissionSourceItem: activeItem,
      level4: 'Item Modal'
    });
    refreshList();
    refreshChart();
    refreshTimeline();
    setActiveItem(undefined);
    handleCloseModal();
  };
  const handleOpenDeleteItemModal = async (item) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      closable: true,
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: { className: 'ch-button-new primary', shape: 'round' },
      cancelButtonProps: {
        className: 'ch-button-new secondary',
        shape: 'round'
      },
      content: 'Are you sure you want to delete this?',
      onOk: async () => {
        analytics.track('Delete item clicked', me, {
          emissionSourceItem: item,
          ...analyticsContext
        });
        await handleDeleteItem(item);
        await refreshChart();
      }
    });
  };
  const handleEditItemModal = (item) => {
    analytics.track('Edit Item Clicked', me, {
      emissionSourceItem: item,
      ...analyticsContext
    });
    setActiveItem(item);
    handleShowModal();
  };
  const columns = [
    {
      key: 'Period',
      title: <ColumnTitle>Period</ColumnTitle>,
      render: (_, item) =>
        isRangeType
          ? `${formatInTimeZone(
              new Date(item.startDate),
              'UTC',
              'MM/dd/yyyy'
            )} - ${
              item.endDate
                ? formatInTimeZone(new Date(item.endDate), 'UTC', 'MM/dd/yyyy')
                : ' --'
            }`
          : formatInTimeZone(new Date(item.date), 'UTC', 'MM/dd/yyyy')
    },
    {
      key: 'usage',
      title: <ColumnTitle>Usage</ColumnTitle>,
      render: (_, item) => item.activity
    },
    {
      key: 'unit',
      title: <ColumnTitle>Unit</ColumnTitle>,
      render: (_, item) => capitalizeText(item.conversionUnitName)
    },
    {
      key: 'emissionTotal',
      dataIndex: 'emissionTotal',
      title: <ColumnTitle>Impact (kg Co2e)</ColumnTitle>,
      render: (emissionTotal) => formatDecimal(Number(emissionTotal), 2)
    },
    {
      key: 'dateSource',
      title: <ColumnTitle>Data Source</ColumnTitle>,
      render: (_, item) => {
        if (
          item.sourceDetails?.origin ===
          EmissionSourceItemOrigin.CARBONHOUND_CONNECT
        ) {
          if (!!item.isEstimate)
            return EmissionSourceItemOriginCopy.CARBONHOUND_CONNECT_ESTIMATE;
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.CARBONHOUND_CONNECT
          ];
        }
        if (!!item.isEstimate) {
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.ESTIMATION
          ];
        }
        return EmissionSourceItemOriginCopy.BILL;
      }
    },
    {
      width: '50px',
      onCell: () => ({
        className: 'justify-end'
      }),
      render: (_, item) => (
        <Space size={24}>
          <ButtonIcon
            onClick={() => handleEditItemModal(item)}
            name="Pencil"
            color={COLOURS_GRAY.GRAY_500}
            backgroundColour="transparent"
          />
          <ButtonIcon
            onClick={() => handleOpenDeleteItemModal(item)}
            name="MinusCircle"
            backgroundColour="transparent"
            color={COLOURS_GRAY.GRAY_400}
          />
        </Space>
      )
    }
  ];

  const handleDateRangeChange = (date) => {
    setDateRange(date ?? null);
  };
  return (
    <div className="emission-source-item-table">
      {isEstimate ? (
        <Empty
          icon="Calculator"
          title="This source is estimated"
          description="You have set that you do not know the energy types for this location so we have estimated this source based off of your regional averages. If you now know the energy types please edit them in the Location Settings"
        />
      ) : (
        <Row gutter={[0, 40]}>
          {emissionSourceDetails?.requireNotifications && (
            <Col span={24}>
              <Card>
                <Loader isLoading={isLoadingTimeline}>
                  <CompletionTimeline timeline={timeline} />
                </Loader>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Table
              isLoading={isLoading}
              isHeaderBorder
              dataSource={sourceItemList}
              pagination={paginationParams}
              showPagination
              columns={columns}
              total={count}
              title={() => (
                <TableTitle
                  isHeaderBorder
                  buttons={[
                    {
                      type: BUTTON_TYPES.LINK,
                      children: 'Data Request Template',
                      onClick: handleShowTemplateModal
                    },
                    {
                      type: BUTTON_TYPES.SECONDARY,
                      children: 'Data Import',
                      onClick: handleShowBUModal
                    },
                    {
                      children: 'Add Item',
                      onClick: handleShowNewItemModal
                    }
                  ]}
                  dateFilterProps={{
                    value: dateRange,
                    onChange: handleDateRangeChange
                  }}
                />
              )}
            />
          </Col>
          <Modal
            title={`Enter Usage Data: ${emissionSourceDetails?.name ?? ''}`}
          >
            <EmissionSourceItemForm
              emissionSourceDetails={emissionSourceDetails}
              unitFamilyName={
                emissionSourceDetails?.emissionFactor?.unitFamilyName
              }
              emissionSourceItem={activeItem}
              onSuccess={handleCloseCreateItemModal}
            />
          </Modal>
          <BUModal title="Upload your file" className="bulk-upload-modal">
            <BulkUploadForm
              notificationReqParams={{
                unitFamilyName:
                  emissionSourceDetails?.emissionFactor?.unitFamilyName,
                emissionSourceDetailsId: emissionSourceDetails?.id,
                dateType: emissionSourceDetails?.dateType,
                address: emissionSourceDetails?.collections?.find(
                  (cl) => cl.type === 'LOCATION'
                )?.location?.address1
              }}
              notification={{
                details: {
                  uploadType: BulkUploadType.CUSTOM
                }
              }}
              handleCloseBulkUploadsModal={handleCloseBUModal}
            />
          </BUModal>
          <DataRequestTemplateModal title="Data request template">
            <DataRequestTemplate />
          </DataRequestTemplateModal>
        </Row>
      )}
    </div>
  );
}
export default EmissionSourceItemTable;
