import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { SCOPE_OPTIONS } from '../../../../constants/options';
import { useDataContext, useMeContext } from '../../../../context';
import { getDropdownOptions } from '../../../../helpers';
import { Select } from '../../../../stories/atoms';

export function ReportingFilterDrilldownRow({
  execute,
  categoryList,
  isLoadingOptions
}) {
  const { me } = useMeContext();
  const { updateDisplayTypeFilter } = useDataContext();
  const { values } = useFormikContext();
  const handleUpdateFilter = (key, value) => {
    updateDisplayTypeFilter(value, key);
  };
  const scopeOptions = [{ label: 'All', value: null }, ...SCOPE_OPTIONS];

  const categoryOptions = [
    { label: 'All', value: null },
    ...getDropdownOptions(categoryList, 'name', 'id')
  ];
  useEffect(() => {
    execute({
      tagFamilyNames: ['EMISSION_SOURCE'],
      companySlug: me.company?.slug
    });
  }, []);
  return (
    <Row gutter={[18, 18]}>
      <Col span={12}>
        <Select
          disableForm
          name="emissionScopes"
          title="Included Scopes"
          options={scopeOptions}
          mode="multiple"
          showSearch
          value={values.emissionScopes ?? null}
          onChange={(val) => handleUpdateFilter('emissionScopes', val)}
        />
      </Col>
      <Col span={12}>
        <Select
          disableForm
          name="tagIds"
          title="Included Categories"
          options={categoryOptions}
          mode="multiple"
          loading={isLoadingOptions}
          showSearch
          value={values.tagIds ?? null}
          onChange={(val) => handleUpdateFilter('tagIds', val)}
        />
      </Col>
      <Col span={12} />
    </Row>
  );
}
