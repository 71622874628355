import { Col, Layout, Row, Space } from 'antd';
import React from 'react';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import InitiativeCaseStudies from '../../../constants/InitiativeCaseStudies';
import { INITIATIVE_TYPE_TAG_COLOURS } from '../../../constants/InitiativeType';
import { useMeContext } from '../../../context';
import { useInitiativeContext } from '../../../context/InitiativeContext';
import {
  InitiativeTemplateCaseStudy,
  InitiativeTemplateFinancing,
  InitiativeTemplateOverview,
  InitiativeTemplateSidebar
} from '../../../features/Initiative/InitiativeTemplate';
import { analytics, capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { Recommended } from '../../../stories/assets/svgs';
import {
  Anchor,
  Icon,
  Link,
  PageHeader,
  Paragraph,
  TabPane,
  Tabs,
  Tag
} from '../../../stories/atoms';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../stories/atoms/Tag/tag.types';
import './InitiativeTemplate.scss';

export function InitiativeTemplate() {
  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Template Overview'
  };
  const { me } = useMeContext();
  const { handleLoadInitiativeTemplate, initiativeState } =
    useInitiativeContext();
  const { initiativeTemplate } = initiativeState;
  const { details } = initiativeTemplate;
  const { caseStudyFilter, partners } = details ?? {};
  const { Loader, isLoading } = useAsync({
    asyncFunction: handleLoadInitiativeTemplate,
    defaultValue: {}
  });

  const tags = [
    ...(initiativeTemplate.tags ?? []).map((tag) => ({ ...tag })),
    { name: initiativeTemplate.type },
    ...(initiativeTemplate.industries?.findIndex(
      (industry) => industry.id === me.company?.industry?.id
    ) > -1
      ? [{ icon: <Recommended /> }]
      : [])
  ];
  const routes = [
    { breadcrumbName: 'Initiatives', path: AppRoutes.INITIATIVE }
  ];
  const { Header, Sider, Content } = Layout;
  const handleOnTabChange = (tab) => {
    analytics.track('Tab Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate.id,
      tab
    });
  };
  const setFilteredCaseStudies = new Set();
  for (const filter of caseStudyFilter ?? []) {
    const caseStudies = InitiativeCaseStudies.filter(
      (csf) => csf.caseStudyFilters.indexOf(filter?.toUpperCase()) > -1
    );
    if (caseStudies?.length) {
      caseStudies.forEach((study) => setFilteredCaseStudies.add(study));
    }
  }
  const filteredCaseStudies = Array.from(setFilteredCaseStudies);

  return (
    <Row className="initiative-template">
      <Loader>
        <Layout>
          <Layout>
            <Header>
              <PageHeader
                breadcrumb={{
                  routes,
                  itemRender: (route) => (
                    <Link key={route.breadcrumbName} to={route.path}>
                      <Icon
                        size={16}
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                        name="ChevronLeft"
                      />
                      {route.breadcrumbName}
                    </Link>
                  )
                }}
                title={initiativeTemplate.name}
                tags={
                  <Space size={[8, 8]}>
                    {tags.map((tag, i) => (
                      <Tag
                        key={i}
                        color={
                          INITIATIVE_TYPE_TAG_COLOURS[tag.name] ||
                          INTERNAL_TAG_COLOURS[tag.name] ||
                          TAG_COLOURS.ORANGE
                        }
                      >
                        {tag.icon && tag.icon}
                        {capitalizeText(tag.name)}
                      </Tag>
                    ))}
                  </Space>
                }
              />
            </Header>
            <Content>
              <Paragraph bottomSpacing={40}>
                {initiativeTemplate.description}
              </Paragraph>
              {!!partners && (
                <Row gutter={12} align="middle" className="mb-10">
                  <Col>
                    <Paragraph weight={600} bottomSpacing={0}>
                      Made in partnership with:
                    </Paragraph>
                  </Col>
                  <Col flex="110px">
                    <Anchor
                      showLinkIcon={false}
                      href={partners?.[0]?.externalUrl}
                    >
                      <img
                        className="full-width"
                        src={partners?.[0]?.logoUrl}
                        alt={`Made in partnership with: ${partners?.[0]?.name}`}
                      />
                    </Anchor>
                  </Col>
                </Row>
              )}
              <Tabs onChange={handleOnTabChange} secondary>
                <TabPane tab="Overview" key="Overview">
                  <InitiativeTemplateOverview
                    analyticsContext={analyticsContext}
                  />
                </TabPane>
                <TabPane
                  tab="Financing & Partnerships"
                  key="Financing & Partnerships"
                >
                  <InitiativeTemplateFinancing
                    analyticsContext={analyticsContext}
                  />
                </TabPane>
                {!!filteredCaseStudies.length && (
                  <TabPane
                    tab="
                  Case Studies"
                    key="Case Studies"
                  >
                    <InitiativeTemplateCaseStudy
                      caseStudyList={filteredCaseStudies}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Content>
          </Layout>
          <Sider width={400} theme="light">
            <InitiativeTemplateSidebar
              analyticsContext={{ ...analyticsContext, level3: 'Sidebar' }}
              isLoading={isLoading}
            />
          </Sider>
        </Layout>
      </Loader>
    </Row>
  );
}
