export const AGGREGATE_TYPE = {
  CATEGORY: 'CATEGORY',
  SCOPE: 'SCOPE',
  FACILITY: 'FACILITY',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  COLLECTION: 'COLLECTION'
};
export const AGGREGATE_TYPE_LABELS = {
  CATEGORY: 'Categories',
  SCOPE: 'Scope',
  FACILITY: 'Facility',
  BUSINESS_UNIT: 'Business Unit',
  COLLECTION: 'Collection'
};
