import { Col, Divider, Row, Space } from 'antd';
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  isFuture,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears
} from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import {
  AGGREGATE_TYPE,
  CollectionType,
  REPORTING_PERIOD_VALUES
} from '../../../constants';
import {
  REPORTING_AGGREGATE_TYPE_OPTIONS,
  REPORTING_PERIOD_OPTIONS,
  SCOPE_OPTIONS,
  TIME_UNIT_OPTIONS
} from '../../../constants/options';
import { useDataContext, useMeContext } from '../../../context';
import { analytics, getDropdownOptions } from '../../../helpers';
import { getDefaultYearForChart } from '../../../helpers/generators';
import { useAsync, useModal } from '../../../hooks';
import { LocationService } from '../../../services';
import TagService from '../../../services/tagService';
import { Button, DatePicker, Select, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { ReportingFilterDrilldownRow } from './ReportingFilterDrilldownRow';
import RequestCSVDetails from './RequestCSVDetails';

const advancedFilterRow = [
  AGGREGATE_TYPE.BUSINESS_UNIT,
  AGGREGATE_TYPE.COLLECTION,
  AGGREGATE_TYPE.FACILITY
];

const getReportTypeSearchFn = (aggregateType) => {
  switch (aggregateType) {
    case AGGREGATE_TYPE.CATEGORY:
      return TagService.listTags;
    case AGGREGATE_TYPE.BUSINESS_UNIT:
    case AGGREGATE_TYPE.COLLECTION:
    case AGGREGATE_TYPE.FACILITY:
      return LocationService.fetchCompanyLocations;

    case AGGREGATE_TYPE.SCOPE:
    default:
      return null;
  }
};

const setDropdownOptions = (aggregateType, optionsArray) => {
  switch (aggregateType) {
    case AGGREGATE_TYPE.SCOPE:
      return SCOPE_OPTIONS;
    case AGGREGATE_TYPE.CATEGORY:
    case AGGREGATE_TYPE.COLLECTION:
    case AGGREGATE_TYPE.BUSINESS_UNIT:
    case AGGREGATE_TYPE.FACILITY:
      return getDropdownOptions(optionsArray, 'name', 'id');
    default:
      return [];
  }
};

const setDisplayTypeName = (aggregateType) => {
  switch (aggregateType) {
    case AGGREGATE_TYPE.CATEGORY:
      return 'tagIds';
    case AGGREGATE_TYPE.SCOPE:
      return 'emissionScopes';
    case AGGREGATE_TYPE.BUSINESS_UNIT:
    case AGGREGATE_TYPE.COLLECTION:
    case AGGREGATE_TYPE.FACILITY:
    default:
      return 'collectionIds';
  }
};

export function ReportingFilters({
  loadReportingView,
  setSelectedChartType,
  setSelectedTimeUnit,
  changeTab
}) {
  const { me, selectCarbonYearRange } = useMeContext();

  const { data, updateDataFilters, updateData, updateDisplayTypeFilter } =
    useDataContext();
  const { filters, isDataFiltersInit } = data;
  const { aggregateType, timeUnit } = filters;
  const formValues = filters;
  const reportTypeSearchFn = getReportTypeSearchFn(aggregateType);
  const displayTypeName = setDisplayTypeName(aggregateType);

  const {
    execute: executeReportTypeSearchFn,
    value: displayTypeOptions = [],
    isLoading: isLoadingOptions
  } = useAsync({
    asyncFunction: reportTypeSearchFn,
    immediate: false,
    defaultValue: []
  });

  const {
    execute: executeCategoryList,
    value: { list: categoryList = [] },
    isLoading: isLoadingCategoryListOptions
  } = useAsync({
    asyncFunction: TagService.listTags,
    immediate: false,
    defaultValue: []
  });

  const aggregateDisplayOptions = setDropdownOptions(
    aggregateType,
    displayTypeOptions?.list ?? []
  );

  const dropDownOptions = [
    { label: 'All', value: null },
    ...aggregateDisplayOptions
  ];
  const analyticsContext = {
    level1: 'Data Explorer',
    level2: 'Reporting'
  };

  const showAdditionalFilters = advancedFilterRow.indexOf(aggregateType) > -1;

  const handlePrintChart = () => {
    analytics.track('Export Clicked', me, analyticsContext);
    window.print();
  };
  const handleFilterChart = () => {
    analytics.track('Run Report Clicked', me, analyticsContext);

    setSelectedChartType(aggregateType);
    setSelectedTimeUnit(timeUnit);
    loadReportingView();
  };

  const handleUpdateReportingPeriodFilter = ({ key, value }) => {
    const newSelectedDateRange = {};
    const today = endOfMonth(new Date());
    let newRangeEnd;
    let newRangeStart;
    switch (value) {
      case REPORTING_PERIOD_VALUES.PREVIOUS_FISCAL_YEAR: {
        const newRangeStartMonth = me.company?.startingMonth
          ? me.company.startingMonth - 1
          : 0;
        newRangeStart = subMonths(new Date().setMonth(newRangeStartMonth), 12);
        newRangeEnd = addYears(subMonths(newRangeStart, 1), 1);
        if (isFuture(newRangeEnd)) {
          newRangeEnd = subYears(newRangeEnd, 1);
          newRangeStart = subYears(newRangeStart, 1);
        }

        // Account
        newSelectedDateRange.rangeEnd = newRangeEnd;
        newSelectedDateRange.rangeStart = newRangeStart;
        break;
      }
      case REPORTING_PERIOD_VALUES.PREVIOUS_CALENDAR_YEAR: {
        const previousYear = subYears(today, 1);
        newSelectedDateRange.rangeEnd = endOfYear(previousYear);
        newSelectedDateRange.rangeStart = startOfYear(previousYear);
        break;
      }
      case REPORTING_PERIOD_VALUES.CURRENT_CALENDAR_YEAR:
        newRangeStart = startOfYear(new Date());
        newSelectedDateRange.rangeEnd = today;
        newSelectedDateRange.rangeStart = newRangeStart;
        break;
      case REPORTING_PERIOD_VALUES.PREVIOUS_QUARTER:
      case REPORTING_PERIOD_VALUES.CURRENT_QUARTER: {
        newSelectedDateRange.rangeEnd = today;
        const startIndexShift =
          Math.abs(today.getMonth() - (me?.company?.startingMonth - 1)) % 3;
        if (startIndexShift > 0) {
          newSelectedDateRange.rangeStart = startOfMonth(
            subMonths(today, startIndexShift)
          );
        } else {
          newSelectedDateRange.rangeStart = startOfMonth(today);
        }
        if (value === REPORTING_PERIOD_VALUES.PREVIOUS_QUARTER) {
          newSelectedDateRange.rangeStart = subMonths(
            newSelectedDateRange.rangeStart,
            3
          );
          newSelectedDateRange.rangeEnd = endOfMonth(
            addMonths(newSelectedDateRange.rangeStart, 2)
          );
        }
        break;
      }
      case REPORTING_PERIOD_VALUES.ALL: {
        newSelectedDateRange.rangeEnd = today;
        newSelectedDateRange.rangeStart = new Date(2006, 0, 1);
        break;
      }
      case REPORTING_PERIOD_VALUES.CURRENT_FISCAL_YEAR:
      default: {
        const { rangeStart } = selectCarbonYearRange({
          activeYear: getDefaultYearForChart({
            startingMonth: me.company?.startingMonth
          })
        });
        newSelectedDateRange.rangeEnd = today;
        newSelectedDateRange.rangeStart = rangeStart;
        break;
      }
    }

    updateDataFilters({
      selectedDateRange: {
        ...newSelectedDateRange,
        rangeEnd: startOfDay(new Date(newSelectedDateRange.rangeEnd))
      },
      [key]: value
    });
  };

  const handleUpdateFilter = (key, value) => {
    switch (key) {
      case 'reportingPeriod': {
        handleUpdateReportingPeriodFilter({ key, value });
        break;
      }
      case 'selectedDateRange': {
        const newSelectedDateRange = {};
        if (!!value[0]) {
          newSelectedDateRange.rangeStart = new Date(value[0]);
        }
        if (!!value[1]) {
          newSelectedDateRange.rangeEnd = new Date(value[1]);
        }
        updateDataFilters({
          selectedDateRange: newSelectedDateRange
        });
        updateDataFilters({ [key]: value });
        break;
      }
      case 'aggregateType': {
        const defaultFilters = {
          collectionIds: undefined,
          emissionScopes: undefined,
          tagIds: undefined
        };
        updateDataFilters({ ...defaultFilters, [key]: value });
        break;
      }
      case 'tagIds':
      case 'collectionIds':
      case 'emissionScopes':
        updateDisplayTypeFilter(value, key);
        break;
      default:
        updateDataFilters({ [key]: value });
        break;
    }
  };

  const {
    Modal: RequestCSVModal,
    handleShowModal: handleShowRequestCSVModal,
    handleCloseModal: handleCloseRequestCSVModal
  } = useModal({
    width: '60%'
  });

  useEffect(() => {
    if (!!reportTypeSearchFn) {
      const isCollectionEndpoint =
        aggregateType === AGGREGATE_TYPE.FACILITY ||
        aggregateType === AGGREGATE_TYPE.BUSINESS_UNIT ||
        aggregateType === AGGREGATE_TYPE.COLLECTION;
      const isCategoryEndpoint = aggregateType === AGGREGATE_TYPE.CATEGORY;
      const params = { companySlug: me.company.slug };
      if (isCollectionEndpoint) {
        const types = [
          aggregateType === AGGREGATE_TYPE.FACILITY
            ? CollectionType.LOCATION
            : AGGREGATE_TYPE[aggregateType]
        ];
        params.types = types;
      } else if (isCategoryEndpoint) {
        params.tagFamilyNames = ['EMISSION_SOURCE'];
      }
      executeReportTypeSearchFn(params);
    }
  }, [reportTypeSearchFn, aggregateType]);

  useEffect(() => {
    if (isDataFiltersInit) {
      handleUpdateReportingPeriodFilter({
        key: 'reportingPeriod',
        value: REPORTING_PERIOD_VALUES.CURRENT_FISCAL_YEAR
      });
    }
  }, [isDataFiltersInit]);

  const handleRequestCSV = () => {
    analytics.track('Request CSV Clicked', me, analyticsContext);
    handleShowRequestCSVModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleFilterChart}
      className="reporting-filters">
      {({ values }) => (
        <Form>
          <Row gutter={[18, 18]}>
            <Col span={24}>
              <Title size="rg">Report type</Title>
            </Col>
            <Col span={12}>
              <Select
                disableForm
                name="aggregateType"
                title="Report type"
                options={REPORTING_AGGREGATE_TYPE_OPTIONS}
                value={values.aggregateType}
                onChange={(val) => handleUpdateFilter('aggregateType', val)}
              />
            </Col>
            <Col span={12}>
              <Select
                allowClear
                key={values[displayTypeName]}
                loading={isLoadingOptions}
                mode="multiple"
                disableForm
                value={values[displayTypeName]}
                title="Display"
                options={dropDownOptions}
                showSearch
                defaultValue={null}
                onChange={(val) => handleUpdateFilter(displayTypeName, val)}
              />
            </Col>

            <Col span={24}>
              {showAdditionalFilters && (
                <ReportingFilterDrilldownRow
                  execute={executeCategoryList}
                  categoryList={categoryList}
                  isLoading={isLoadingCategoryListOptions}
                />
              )}
            </Col>
          </Row>
          <Divider />
          <Row wrap={false} gutter={18} align="middle">
            <Col flex="1">
              <Row gutter={[18, 18]}>
                <Col flex="1">
                  <Select
                    title="Reporting Period"
                    options={REPORTING_PERIOD_OPTIONS}
                    disableForm
                    onChange={(val) =>
                      handleUpdateFilter('reportingPeriod', val)
                    }
                    value={values.reportingPeriod}
                    name="reportingPeriod"
                  />
                </Col>
                <Col flex="1">
                  <DatePicker
                    format="MMM YYYY"
                    picker="month"
                    isDisplayUTCFormat={false}
                    isRange
                    title="Date Range"
                    onChange={(val) =>
                      handleUpdateFilter('selectedDateRange', val)
                    }
                    defaultValue={[
                      values.selectedDateRange.rangeStart,
                      values.selectedDateRange.rangeEnd
                    ]}
                    fromName="selectedDateRange.rangeStart"
                    toName="selectedDateRange.rangeEnd"
                    name="selectedDateRange"
                    disabled={
                      values.reportingPeriod !== REPORTING_PERIOD_VALUES.CUSTOM
                    }
                  />
                </Col>
                <Col flex="1">
                  <Select
                    title="View by"
                    options={TIME_UNIT_OPTIONS}
                    disableForm
                    onChange={(val) => handleUpdateFilter('timeUnit', val)}
                    value={values.timeUnit}
                    name="timeUnit"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button className="mt-6" htmlType="onSubmit">
                Run Report
              </Button>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col flex="1">
              <Title size="rg" bottomSpacing={0}>
                Share report
              </Title>
            </Col>
            <Col>
              <Space>
                <Button onClick={handlePrintChart} type={BUTTON_TYPES.TEXT}>
                  Export Chart
                </Button>
                <Button
                  onClick={handleRequestCSV}
                  type={BUTTON_TYPES.SECONDARY}>
                  Request CSV
                </Button>
              </Space>
            </Col>
          </Row>
          <RequestCSVModal title="Request CSV Export">
            <RequestCSVDetails
              displayOptions={dropDownOptions}
              displayTypeOptions={displayTypeOptions}
              categoryList={categoryList}
              onSuccess={handleCloseRequestCSVModal}
              changeTab={changeTab}
            />
          </RequestCSVModal>
        </Form>
      )}
    </Formik>
  );
}
