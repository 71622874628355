import { Col, Modal as AntModal, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import alertCircle from '../../../../../assets/images/alert-circle.svg';
import {
  Card,
  DropdownField,
  InfoModalContent,
  Note,
  Popover
} from '../../../../../components';
import {
  EnergyTypeConstants,
  OfficeTrail,
  OfficeTrailSegment
} from '../../../../../constants';
import {
  useMeContext,
  useMetaContext,
  useOfficeOnboardingContext,
  useOfficeTrailContext
} from '../../../../../context';
import {
  analytics,
  capitalizeText,
  formatDecimal,
  getDetailsFromAddress,
  getYearDropdownOptions
} from '../../../../../helpers';
import { getFormattedAddress } from '../../../../../helpers/generators';
import { AppRoutes } from '../../../../../routes';
import {
  CollectionService,
  CompanyService,
  CompanyTrailService,
  EnergyService
} from '../../../../../services';
import { BarChart, Button } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import './officeOnboardingOverview.scss';

function OfficeOnboardingOverview() {
  const navigate = useNavigate();

  const { me, selectCarbonYearRange } = useMeContext();

  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());

  const [emissionSummary, setEmissionSummary] = useState([]);

  const [_isPageloading, setIsPageLoading] = useState(true);

  const { refreshOfficeTrails } = useOfficeTrailContext();

  const { setEnergyUnits, setEnergyTypes } = useMetaContext();
  const {
    officeOnboardingDetails,
    setActiveCompanyLocation,
    handleFetchEnergies
  } = useOfficeOnboardingContext();

  const isPending =
    officeOnboardingDetails?.companyLocations?.filter(
      (location) =>
        location?.energyTypes &&
        location.energyTypes.filter((energyType) => !energyType.isDataComplete)
          .length > 0
    ).length > 0;

  const handleAddOffice = ({ isEmptyState }) => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Office Onboarding',
      level3: 'Overview',
      level4: isEmptyState ? 'Empty State' : undefined
    });
    setActiveCompanyLocation(undefined);
    navigate(AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION);
  };

  const handleClickFullyRemote = ({ isEmptyState }) => {
    AntModal.info({
      className: 'ch-modal',
      icon: null,
      onOk: () => {
        CompanyTrailService.createCompanyTrail(
          me.company.slug,
          {
            trailName: OfficeTrail.OFFICE_ONBOARDING,
            segmentName: OfficeTrailSegment.ENERGY,
            isComplete: true
          },
          () => {
            analytics.track('Fully Remote Selected', me, {
              level1: 'Office-Based Operations',
              level2: 'Office Onboarding',
              level3: 'Overview',
              level4: isEmptyState ? 'Empty State' : undefined
            });
            refreshOfficeTrails();
            navigate(AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES);
          },
          () => {},
          () => {}
        );
      },
      okText: 'Continue',
      content: (
        <InfoModalContent
          title="Fully remote selected"
          description="Amazing, this is the most environmentally friendly option! You will now proceed to the Employee Details segment"
        />
      )
    });
  };

  const handleNext = () => {
    CompanyTrailService.createCompanyTrail(
      me.company.slug,
      {
        trailName: OfficeTrail.OFFICE_ONBOARDING,
        segmentName: OfficeTrailSegment.ENERGY,
        isComplete: true
      },
      () => {
        analytics.track('Segment Completed', me, {
          level1: 'Office-Based Operations',
          level2: 'Office Onboarding',
          level3: 'Overview'
        });
        refreshOfficeTrails();
        navigate(AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES);
      },
      () => {},
      () => {}
    );
  };

  const handleDeleteLocation = (energyDetails) => () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure want to delete this?',
      onOk: () => {
        CollectionService.deleteCollection(
          {
            collectionId: energyDetails.collectionId
          },
          () => {
            analytics.track('Delete item clicked', me, {
              level1: 'Office-Based Operations',
              level2: 'Office Onboarding',
              level3: 'Overview',
              level4: 'Office Location',
              energyDetails
            });
            handleFetchEnergies();
          },
          () => {},
          () => {}
        );
      }
    });
  };

  const handleShowCompanyDetails = () => {
    CompanyService.showCompany(
      me.company.slug,
      ({ footprintRange }) => {
        setSelectedYear(
          footprintRange?.length > 0
            ? footprintRange[0]
            : new Date().getUTCFullYear()
        );
      },
      () => {},
      () => {}
    );
  };

  const handleInitialPageLoad = async () => {
    await setEnergyUnits();
    await setEnergyTypes();
    await handleFetchEnergies();
    await handleShowCompanyDetails();
    await refreshOfficeTrails();
    setIsPageLoading(false);
  };

  useEffect(() => {
    if (selectedYear) {
      const { rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: selectedYear
      });
      EnergyService.fetchOfficeTrailSummary(
        {
          companySlug: me?.company?.slug,
          rangeStart,
          rangeEnd
        },
        (emissionSummary) => {
          setEmissionSummary(emissionSummary);
        },
        () => {},
        () => {}
      );
    }
  }, [selectedYear]);

  useEffect(() => {
    handleInitialPageLoad();
  }, []);
  return (
    <div className="office-onboarding-overview">
      {officeOnboardingDetails?.companyLocations?.length === 0 ? (
        <>
          <h3 className="text-bd">Location(s) Summary</h3>
          <Card className="office-onboarding-overview__empty-state-card">
            <p>
              No data yet, add an office or select fully remote to skip this
              segment
            </p>
            <Space>
              <Button
                prefixIcon="Plus"
                onClick={() => handleAddOffice({ isEmptyState: true })}
              >
                Add new office
              </Button>
              <Button
                type={BUTTON_TYPES.SECONDARY}
                className="ml-4"
                onClick={() => handleClickFullyRemote({ isEmptyState: true })}
              >
                Fully remote
              </Button>
            </Space>
          </Card>
        </>
      ) : (
        <>
          <h3 className="text-bd">Your office impact</h3>
          <Card className="office-onboarding-overview__chart-card">
            <div className="office-onboarding-overview__pending-entries">
              <Row>
                <Col span={24}>
                  <div className="office-onboarding-overview__date-range-wrapper">
                    <span className="text-rg">Date Range</span>
                    <DropdownField
                      className="office-onboarding-overview__select-year"
                      value={selectedYear}
                      disableForm
                      options={getYearDropdownOptions()}
                      onChange={(year) => setSelectedYear(year)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <BarChart
              labels={emissionSummary?.map((summary) => summary.month)}
              bgColors={['#5C29C7']}
              data={emissionSummary?.map(
                (summary) => Number(summary.totalEmissions) / 1000
              )}
            />
          </Card>
          <Row>
            <Col span={12}>
              <h3 className="text-bd">Location(s) Summary</h3>
            </Col>
            {officeOnboardingDetails?.companyLocations?.length > 0 && (
              <Col span={12} className="justify-end display-flex align-center">
                <Button prefixIcon="Plus" onClick={handleAddOffice}>
                  Add another office
                </Button>
              </Col>
            )}
          </Row>
          {officeOnboardingDetails?.companyLocations.map(
            (companyLocation, i) => (
              <Row gutter={[15, 0]} align="middle" key={i}>
                <Col span={23}>
                  <Card className="office-onboarding-overview__card">
                    <div className="office-onboarding-overview__header">
                      <Row align="middle">
                        <Col span={2}>
                          <span className="office-onboarding-overview__item-no">
                            #{i + 1}
                          </span>
                        </Col>
                        <Col span={16}>
                          <h5 className="text-sbd mb-0 display-flex">
                            {getFormattedAddress({
                              location: companyLocation?.location,
                              name: companyLocation?.name
                            })}
                            <Button
                              className="ml-2"
                              type={BUTTON_TYPES.SMALL}
                              onClick={() => {
                                analytics.track('Edit Address', me, {
                                  level1: 'Office-Based Operations',
                                  level2: 'Office Onboarding',
                                  level3: 'Overview',
                                  level4: 'Office Location',
                                  energyDetails: companyLocation
                                });
                                setActiveCompanyLocation(companyLocation);
                                navigate(
                                  generatePath(
                                    AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_LOCATION_EDIT,
                                    {
                                      collectionId: companyLocation.collectionId
                                    }
                                  ),
                                  {
                                    state: {
                                      isEdit: true
                                    }
                                  }
                                );
                              }}
                            >
                              Edit
                            </Button>
                          </h5>
                          <div className="office-onboarding-overview__location">
                            <i className="icon-location mr-1" />
                            <span className="text-light-black">
                              {getDetailsFromAddress(companyLocation.location)}
                            </span>
                          </div>
                        </Col>
                        <Col span={6} className="text-bd">
                          <div className="text-right">
                            <span>
                              {companyLocation?.emissionSummary
                                ? formatDecimal(
                                    Number(companyLocation?.emissionSummary) /
                                      1000
                                  )
                                : 0}{' '}
                              tonnes CO
                              <sub>2e</sub> total
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row
                      gutter={[15, 20]}
                      className="office-onboarding-overview__energy-type-title"
                    >
                      <Col offset={2} span={5}>
                        Energy Type(s)
                      </Col>
                      <Col span={5}>Total Usage</Col>
                      <Col span={5}>
                        Data complete?
                        <Popover content="If you have entered in data to cover your baseline period, this will be marked as yes" />
                      </Col>
                      <Col span={3}>Access to bills?</Col>
                    </Row>
                    {companyLocation?.energyTypes?.map((energyType, i) => (
                      <Row
                        gutter={[15, 0]}
                        key={i}
                        className="office-onboarding-overview__energy-type"
                      >
                        <Col offset={2} span={5}>
                          {capitalizeText(energyType?.energyType)}
                          {energyType?.energyType ===
                            EnergyTypeConstants.ELECTRICITY && (
                            <Popover content="Transmission and Distribution losses are currently not included in the calculations." />
                          )}
                        </Col>
                        <Col span={5}>
                          {`${formatDecimal(energyType?.activityAvg ?? 0)} ${
                            energyType?.energyType ===
                            EnergyTypeConstants.ELECTRICITY
                              ? 'KWH'
                              : 'MMBTU'
                          }`}
                          {!energyType?.hasAccessToBills && (
                            <Popover content="Estimated value" warning />
                          )}
                        </Col>
                        <Col span={5}>
                          {energyType?.isDataComplete ? (
                            <>
                              <i className="icon-success" /> Yes
                            </>
                          ) : (
                            <>
                              <i className="icon-alert" /> No
                            </>
                          )}
                        </Col>
                        <Col span={3}>
                          {energyType?.hasAccessToBills ? (
                            <>
                              <i className="icon-success" /> Yes
                            </>
                          ) : (
                            <>
                              <i className="icon-alert" /> No
                            </>
                          )}
                        </Col>
                        <Col
                          span={4}
                          className="office-onboarding-overview__energy-type-actions"
                        >
                          {energyType?.hasAccessToBills ? (
                            energyType?.isDataComplete ? (
                              <Button
                                type={BUTTON_TYPES.SECONDARY}
                                onClick={() => {
                                  analytics.track('Edit Energy type', me, {
                                    level1: 'Office-Based Operations',
                                    level2: 'Office Onboarding',
                                    level3: 'Overview',
                                    level4: 'Office Location',
                                    energyDetails: companyLocation
                                  });
                                  navigate(
                                    generatePath(
                                      AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS,
                                      {
                                        collectionId:
                                          companyLocation.collectionId,
                                        energyDetailsId:
                                          companyLocation.energyDetailsId,
                                        energyTypeId: energyType.energyTypeId
                                      }
                                    )
                                  );
                                }}
                              >
                                Edit
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  analytics.track('Edit Energy type', me, {
                                    level1: 'Office-Based Operations',
                                    level2: 'Office Onboarding',
                                    level3: 'Overview',
                                    level4: 'Office Location',
                                    energyDetails: companyLocation
                                  });
                                  navigate(
                                    generatePath(
                                      AppRoutes.ONBOARDING_ADMIN_OFFICE_ONBOARDING_MANAGE_BILLS,
                                      {
                                        collectionId:
                                          companyLocation.collectionId,
                                        energyDetailsId:
                                          companyLocation.energyDetailsId,
                                        energyTypeId: energyType.energyTypeId
                                      }
                                    )
                                  );
                                }}
                              >
                                Update
                              </Button>
                            )
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                    {companyLocation?.isEnergyTypeUnknown && (
                      <div className="office-onboarding-overview__unknown-energy-type">
                        <img src={alertCircle} alt="Warning" />
                        <span className="ml-2">
                          Unknown Energy types - emissions are estimated
                        </span>
                        <Popover content="Transmission and Distribution losses are currently not included in the calculations." />
                      </div>
                    )}
                  </Card>
                </Col>
                <Col span={1} className="office-onboarding-overview__delete">
                  <i
                    className="icon-delete"
                    onClick={handleDeleteLocation(companyLocation)}
                  />
                </Col>
              </Row>
            )
          )}
        </>
      )}

      <div className="mt-5">
        <Row>
          <Col span={12} offset={12}>
            {isPending && (
              <Note
                className="mb-4"
                warning
                title="Warning"
                description="All of your locations must have up to date data before you can complete the trail"
              />
            )}
            {officeOnboardingDetails?.companyLocations?.length > 0 && (
              <Note
                info
                title="Note"
                description="Please ensure that you have all of your locations added before proceeding to the next step."
              />
            )}
          </Col>
        </Row>
      </div>
      <Row gutter={[20, 20]} className="mt-5 mb-5">
        <Col span={24} className="display-flex justify-end">
          <Button
            className="office-onbaording-overview__complete-btn"
            disabled={isPending}
            icon="ArrowRight"
            onClick={handleNext}
          >
            Complete office onboarding
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default OfficeOnboardingOverview;
