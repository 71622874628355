import { Col, Divider, Row } from 'antd';
import { Card } from '../../../components';
import { CARBONHOUND_CONNECT_TYPES } from '../../../constants';
import {
  DisconnectIntegrationStatuses,
  IntegrationStatus,
  PendingIntegrationStatuses
} from '../../../constants/status';
import { useMeContext } from '../../../context';
import { analytics, capitalizeText } from '../../../helpers';
import { useModal } from '../../../hooks';
import { Img, Paragraph, Tag, Title } from '../../../stories/atoms';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import { CarbonhoundConnectSteps } from '../../CarbonhoundConnect/CarbonhoundConnectSteps';
import './IntegrationConnectCard.scss';
import { IntegrationConnectCardFooter } from './IntegrationConnectCardFooter';

const activeStatuses = [
  ...PendingIntegrationStatuses,
  IntegrationStatus.CONNECTED
];

export function IntegrationConnectCard({ refreshList, item = {} }) {
  const { status, displayName, description, logoUrl, slug } = item;
  const { me } = useMeContext();
  const {
    Modal: CarbonhoundConnectModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '70%',
    onShowModal: () => {
      analytics.track('Item Clicked', me, {
        level1: 'My Company',
        level2: 'Integrations',
        integration: item
      });
    },
    onCloseModal: refreshList
  });
  const isTagPending = PendingIntegrationStatuses.indexOf(status) > -1;
  const isTagConnected = status === IntegrationStatus.CONNECTED;
  const isTagDisconnected = DisconnectIntegrationStatuses.indexOf(status) > -1;
  let tagColour;
  let tagCopy = '';
  if (isTagPending) {
    tagColour = TAG_COLOURS.YELLOW;
    tagCopy = IntegrationStatus.PENDING;
  } else if (isTagConnected) {
    tagColour = TAG_COLOURS.GREEN;
    tagCopy = IntegrationStatus.CONNECTED;
  } else if (isTagDisconnected) {
    tagColour = TAG_COLOURS.RED;
    tagCopy = IntegrationStatus.DISCONNECTED;
  }

  const isConnectionActive = activeStatuses.indexOf(status) > -1;
  const isContactUsNowLinkVisible =
    slug === CARBONHOUND_CONNECT_TYPES.VALUE_CHAIN;
  return (
    <Card className="integration-connect-card">
      <Row className="testing-styles">
        <Col span={24} className="integration-connect-card--padded">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Img
                  src={logoUrl}
                  className=" integration-connect-card__icon"
                />
              </Col>
              {status && (
                <Col>
                  <Tag color={tagColour}>{capitalizeText(tagCopy)}</Tag>
                </Col>
              )}
            </Row>
          </Col>
          <Title bottomSpacing={0}>{displayName}</Title>
          <Paragraph bottomSpacing={0}>{description}</Paragraph>
        </Col>
        <Col span={24}>
          <Divider />
          <IntegrationConnectCardFooter
            isContactUsNowLinkVisible={isContactUsNowLinkVisible}
            isConnectionActive={isConnectionActive}
            item={item}
            handleShowModal={handleShowModal}
          />
        </Col>
      </Row>

      <CarbonhoundConnectModal title="Data Concierge">
        <CarbonhoundConnectSteps
          integration={item}
          onClose={handleCloseModal}
        />
      </CarbonhoundConnectModal>
    </Card>
  );
}
