import { Steps as AntSteps } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Notification } from '../../components';
import { CollectionType } from '../../constants';
import { SEGMENT_NAME, TRAIL_NAME } from '../../constants/companyTrailSegments';
import { useMeContext } from '../../context';
import { analytics } from '../../helpers';
import { useModal } from '../../hooks';
import { AppRoutes } from '../../routes';
import {
  CollectionService,
  CompanyService,
  CompanyTrailService
} from '../../services';
import OnboardingService from '../../services/onboardingService';
import { Steps } from '../../stories/atoms';
import StepsDot from '../../stories/atoms/StepsDot';
import { ClimateGoals } from './ClimateGoals';
import { setClimateGoalsValidations } from './ClimateGoals/climateGoalsValidations';
import { GreenTeam } from './GreenTeam';
import { setGreenTeamValidations } from './GreenTeam/greenTeamValidation';
import './onboarding.scss';
import { OnboardingComplete } from './OnboardingComplete';
import OnboardingModalFooter from './OnboardingModalFooter';
import OnboardingWelcome from './OnboardingWelcome';
import ReportingDetails from './ReportingDetails';
import { setReportingDetailsValidation } from './ReportingDetails/reportingDetailsValidation';
import YourOrganization from './YourOrganization';
import { setYourOrganizationValidation } from './YourOrganization/yourOrganizationValidation';

function Onboarding() {
  const navigate = useNavigate();

  const { me, getMyDetails, updateCompany } = useMeContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentSegmentPage, setCurrentSegmentPage] = useState('');
  const {
    Modal: OnboardingModal,
    handleShowModal: handleShowOnboardingModal,
    handleCloseModal: handleCloseOnboardingModal
  } = useModal({
    width: '90%'
  });

  useEffect(() => {
    handleShowOnboardingModal();
  }, []);

  const [current, setCurrent] = useState(0);
  const [isGreenTeamScreen, setIsGreenTeamScreen] = useState(false);

  const [hasStarted, setHasStarted] = useState(false);
  const [isCompleteScreen, setIsCompleteScreen] = useState(false);

  const adminRoleId = me?.company?.roles?.[0]?.id;

  const formSteps = [
    {
      key: 1,
      dotTitle: 'Details',
      title: 'Your reporting details ',
      content: <ReportingDetails />,
      validation: setReportingDetailsValidation,
      formValues: {
        footprintRange: [],
        reportingStartYear: undefined,
        startingMonth: undefined
      }
    },
    {
      key: 2,
      dotTitle: 'Your Org',
      title: 'Configure your organization',
      content: <YourOrganization />,
      validation: setYourOrganizationValidation,
      formValues: {
        businessUnitFields: []
      }
    },
    {
      key: 3,
      dotTitle: 'Goals',
      title: 'Your Climate Goals ',
      content: <ClimateGoals />,
      validation: setClimateGoalsValidations,
      formValues: {
        sustainabilityGoal: undefined,
        reportingStandards: undefined,
        calculatedCarbonFootprint: ''
      }
    },
    {
      key: 4,
      dotTitle: 'Green Team',
      title: 'Invite your Green Team ',
      content: <GreenTeam />,
      validation: setGreenTeamValidations,
      formValues: {
        adminFields: [
          { email: '', roleId: adminRoleId },
          { email: '', roleId: adminRoleId },
          { email: '', roleId: adminRoleId },
          { email: '', roleId: adminRoleId },
          { email: '', roleId: adminRoleId }
        ]
      }
    }
  ];

  const { Step } = AntSteps;

  const {
    content: ModalContent,
    formValues,
    validation
  } = formSteps?.[current];

  useEffect(() => {
    getMyDetails();
    if (searchParams.get('screen') === SEGMENT_NAME.REPORTING_DETAILS) {
      setHasStarted(true);
      setCurrent(0);
    } else if (
      searchParams.get('screen') === SEGMENT_NAME.CONFIGURE_ORGANIZATION
    ) {
      setHasStarted(true);
      setCurrent(1);
    } else if (searchParams.get('screen') === SEGMENT_NAME.CLIMATE_GOALS) {
      setHasStarted(true);
      setCurrent(2);
    } else if (searchParams.get('screen') === SEGMENT_NAME.GREEN_TEAM) {
      setHasStarted(true);
      setCurrent(3);
      setIsGreenTeamScreen(true);
    }
  }, []);

  const handleCompanySegmentTrailStep = (segmentType) =>
    CompanyTrailService.createCompanyTrail(me?.company?.slug, {
      trailName: TRAIL_NAME.COMPANY,
      segmentName: SEGMENT_NAME[segmentType],
      isComplete: true
    });

  const handleSubmit = async (values) => {
    if (current === 0) {
      const updatedCompanyRes = await CompanyService.updateCompany({
        ...values,
        slug: me?.company?.slug
      });
      if (!!updatedCompanyRes || updatedCompanyRes === '') {
        analytics.track('Next Step Clicked', me, {
          level1: 'Company Onboarding',
          level2: 'Reporting'
        });
        await handleCompanySegmentTrailStep(SEGMENT_NAME.REPORTING_DETAILS);
        updateCompany({
          footprintRange: values?.footprintRange,
          reportingStartYear: values?.reportingStartYear
        });
        setCurrentSegmentPage('Set Goals');
        setSearchParams({ screen: SEGMENT_NAME.CLIMATE_GOALS });
      }
    } else if (current === 1) {
      const { businessUnitFields } = values;
      const filledBusinessFields = businessUnitFields
        .filter((field) => field.name.length > 0)
        .map((field) => field.name);
      if (businessUnitFields.length > 0) {
        await CollectionService.createCollectionBulk({
          companySlug: me?.company?.slug,
          names: filledBusinessFields,
          type: CollectionType.BUSINESS_UNIT
        });
      }
      analytics.track('Next Step Clicked', me, {
        level1: 'Company Onboarding',
        level2: 'Organization'
      });
      setCurrentSegmentPage('Configure Organization');
      await handleCompanySegmentTrailStep(SEGMENT_NAME.CONFIGURE_ORGANIZATION);
      setSearchParams({ screen: SEGMENT_NAME.CLIMATE_GOALS });
    } else if (current === 2) {
      await OnboardingService.setClimateGoals({
        companySlug: me?.company?.slug,
        climateGoals: { ...values }
      });
      analytics.track('Next Step Clicked', me, {
        level1: 'Company Onboarding',
        level2: 'Set Goals'
      });
      setCurrentSegmentPage('Green Team');
      await handleCompanySegmentTrailStep(SEGMENT_NAME.CLIMATE_GOALS);
      setSearchParams({ screen: SEGMENT_NAME.GREEN_TEAM });
      setIsGreenTeamScreen(true);
    } else if (current === 3) {
      const greenTeam = values.adminFields.filter(
        (value) => value.email.length > 0 && value.roleId.length > 0
      );
      await OnboardingService.setGreenTeam({
        companySlug: me?.company?.slug,
        users: greenTeam
      });
      analytics.track('Next Step Clicked', me, {
        level1: 'Company Onboarding',
        level2: 'Green Team'
      });
      Notification({
        type: 'success',
        message: 'Invitation sent',
        description: 'We’ve sent invites to the emails that you entered!'
      });
      await handleCompanySegmentTrailStep(SEGMENT_NAME.GREEN_TEAM);
      setIsCompleteScreen(true);
      setIsGreenTeamScreen(false);
    } else {
      navigate(AppRoutes.HOME);
    }

    if (current !== formSteps.length - 1) {
      setCurrent((prev) => prev + 1);
    }
  };

  const onSkipHandler = async () => {
    await handleCompanySegmentTrailStep(SEGMENT_NAME.GREEN_TEAM);
    setIsGreenTeamScreen(false);
    setIsCompleteScreen(true);
    analytics.track('Skip Step Selected', me, {
      level1: 'Company Onboarding',
      level2: 'Set Goals'
    });
  };

  const onCloseModalHandler = () => {
    navigate(AppRoutes.HOME);
    setHasStarted(false);
    handleCloseOnboardingModal();
    analytics.track('Step Completed', me, {
      level1: 'Company Onboarding',
      level2: 'Onboarding Complete'
    });
  };

  const onStartOnboardingHandler = () => {
    setHasStarted(true);
    setCurrentSegmentPage('Reporting');
  };

  return (
    <OnboardingModal showHeader={false} maskClosable={false}>
      {hasStarted ? (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={validation}
          enableReinitialize
        >
          <Form noValidate>
            <div className="onboarding-modal__container">
              <Steps labelPlacement="vertical" size="small" current={current}>
                {formSteps.map((item) => (
                  <Step
                    className="mb-7"
                    key={item.key}
                    title={<span className="no-wrap">{item.dotTitle}</span>}
                    progressDot={(iconDot, { status, index }) => (
                      <StepsDot
                        iconDot={iconDot}
                        status={status}
                        index={index}
                      />
                    )}
                  />
                ))}
              </Steps>
              {isCompleteScreen ? (
                <OnboardingComplete closeModal={handleCloseOnboardingModal} />
              ) : (
                ModalContent
              )}
            </div>
            <OnboardingModalFooter
              isGreenTeamScreen={isGreenTeamScreen}
              isCompleteScreen={isCompleteScreen}
              onSkipHandler={onSkipHandler}
              onCloseModalHandler={onCloseModalHandler}
              currentSegmentPage={currentSegmentPage}
            />
          </Form>
        </Formik>
      ) : (
        <OnboardingWelcome
          onStartOnboardingHandler={onStartOnboardingHandler}
        />
      )}
    </OnboardingModal>
  );
}

export default Onboarding;
