import { Col, Row } from 'antd';
import { Card, Loader } from '../../../components';
import {
  IntegrationStatus,
  PendingIntegrationStatuses
} from '../../../constants/status';
import { useMeContext } from '../../../context';
import { CarbonhoundConnectSteps } from '../../../features/CarbonhoundConnect/CarbonhoundConnectSteps';
import { useAsync, useModal } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import EmissionSourceService from '../../../services/emissionSourceService';
import gusSleepingLaptop from '../../assets/svgs/Gus/gusSleepingLaptop.svg';
import { Button, Img, Link, Paragraph, Title } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import './CarbonhoundConnectCta.scss';

const activeStatuses = [
  ...PendingIntegrationStatuses,
  IntegrationStatus.CONNECTED
];
function CarbonhoundConnectCta({
  emissionSourceDetailsId,
  integration = {},
  isSourceLevel = false,
  onSuccess
}) {
  const { isAdmin, isSuperUser } = useMeContext();
  const updateConnectEmissionSource = async (integrationResp) => {
    await EmissionSourceService.updateEmissionSourcesDetails({
      integrationName: integrationResp.name,
      integrationStatus: IntegrationStatus.CONNECTED,
      id: emissionSourceDetailsId
    });
  };

  const { execute: handleUpdateConnectEmissionSource, isLoading: isSavingES } =
    useAsync({
      asyncFunction: updateConnectEmissionSource,
      immediate: false
    });

  const activeIntegration = integration ?? {};
  const {
    Modal: CarbonhoundConnectModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '70%',
    onCloseModal: async (integrationResp) => {
      if (!!emissionSourceDetailsId && !!integrationResp?.slug) {
        await handleUpdateConnectEmissionSource(integrationResp);
        onSuccess();
      } else {
        onSuccess();
      }
    }
  });

  const { status } = activeIntegration;
  const isIntegrationActive =
    !!activeIntegration && activeStatuses.indexOf(status) > -1 && isSourceLevel;

  if (isSuperUser() || isAdmin) {
    return (
      <>
        <Card className="carbonhound-connect-cta">
          <Row align="middle" gutter={[24, 16]} wrap={false}>
            <Col>
              {isIntegrationActive ? (
                <span className="carbonhound-connect-cta__success-icon">
                  🎉
                </span>
              ) : (
                <Img
                  src={gusSleepingLaptop}
                  alt="An illustration of a dog sleeping on the keyboard of a laptop"
                />
              )}
            </Col>
            <Col flex={1}>
              {!isIntegrationActive && (
                <Title bottomSpacing={8} size="md" fontSize={18}>
                  Tired of wasting your time entering data?
                </Title>
              )}
              <Paragraph size={isIntegrationActive && 'xl'} bottomSpacing={0}>
                {isIntegrationActive
                  ? 'You’re saving yourself hours of data management with Carbonhound Connect!'
                  : 'Effortlessly import all of your data straight from your provider through Carbonhound Concierge.'}
              </Paragraph>
            </Col>
            <Col>
              <Loader isLoading={isSavingES}>
                {isIntegrationActive || !isSourceLevel ? (
                  <Link
                    fontWeight={600}
                    to={
                      !isSourceLevel
                        ? AppRoutes.USER_COMPANY
                        : AppRoutes.INTEGRATION_DETAIL.replace(
                            ':parentIntegrationSlug/:integrationSlug',
                            activeIntegration.urlSlug
                          )
                    }
                    state={{ defaultKey: '4' }}
                  >
                    {!isSourceLevel ? 'Learn More' : 'Manage connection'}
                  </Link>
                ) : (
                  <Button
                    onClick={handleShowModal}
                    type={BUTTON_TYPES.SECONDARY}
                  >
                    Connect
                  </Button>
                )}
              </Loader>
            </Col>
          </Row>
        </Card>

        <CarbonhoundConnectModal>
          <CarbonhoundConnectSteps
            integrationDetail={activeIntegration}
            onClose={handleCloseModal}
          />
        </CarbonhoundConnectModal>
      </>
    );
  }
  return null;
}
export default CarbonhoundConnectCta;
