function Ban() {
  return (
    <path
      d="M18.364 18.364C20.0518 16.6762 21 14.387 21 12C21 9.61303 20.0518 7.32383 18.364 5.63599C16.6762 3.94816 14.387 2.99994 12 2.99994C9.61303 2.99994 7.32383 3.94816 5.63599 5.63599M18.364 18.364L5.63599 5.63599M18.364 18.364C16.6762 20.0518 14.387 21 12 21C9.61303 21 7.32383 20.0518 5.63599 18.364C3.94816 16.6762 2.99994 14.387 2.99994 12C2.99994 9.61303 3.94816 7.32383 5.63599 5.63599"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Ban;
