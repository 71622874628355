import { Card, Col, Row } from 'antd';
import { useState } from 'react';
import { AGGREGATE_TYPE, TIMEFRAME_VALUES } from '../../../constants';
import { useDataContext } from '../../../context';
import { useAsync } from '../../../hooks';
import { DataChart } from '../../Data';
import { ReportingFilters } from '../ReportingFilters';
import { ReportingTable } from '../ReportingTable';
import './ReportingOverview.scss';

export function ReportingOverview({ changeTab }) {
  const [selectedChartType, setSelectedChartType] = useState(
    AGGREGATE_TYPE.BUSINESS_UNIT
  );
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(
    TIMEFRAME_VALUES.MONTH
  );
  const { handleFetchReportingList, handleFetchReportingData } =
    useDataContext();

  const {
    value: chartData,
    execute: handleLoadReport,
    isLoading: isLoadingChartData
  } = useAsync({
    asyncFunction: handleFetchReportingData,
    immediate: false
  });
  const {
    value: listData,
    execute: handleLoadReportList,
    isLoading: isLoadingList
  } = useAsync({
    asyncFunction: handleFetchReportingList,
    immediate: false,
    defaultValue: []
  });

  const loadReportingView = () => {
    handleLoadReport();
    handleLoadReportList();
  };

  const isLoadingData = isLoadingList || isLoadingChartData;

  return (
    <Row gutter={[0, 16]} className="reporting-overview">
      <Col span={24} className="reporting-overview__filters">
        <Card>
          <ReportingFilters
            loadReportingView={loadReportingView}
            selectedChartType={selectedChartType}
            setSelectedChartType={setSelectedChartType}
            setSelectedTimeUnit={setSelectedTimeUnit}
            selectedTimeUnit={selectedTimeUnit}
            changeTab={changeTab}
          />
        </Card>
      </Col>
      <Col span={24} className="reporting-overview__chart">
        <Card>
          <Row>
            <Col span={24}>
              <DataChart
                chartData={chartData}
                isLoading={isLoadingData}
                selectedChartType={selectedChartType}
              />
            </Col>
            <Col span={24} className="reporting-overview__table">
              {!isLoadingData && !!listData.length && (
                <ReportingTable
                  listData={listData}
                  isLoading={isLoadingData}
                  selectedTimeUnit={selectedTimeUnit}
                  selectedChartType={selectedChartType}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
