import { Statistic as AntStatistic } from 'antd';
import classNames from 'classnames';
import './Statistic.scss';

function Statistic({ className, type, ...props }) {
  return (
    <AntStatistic
      {...props}
      className={classNames('ch-statistic', {
        [className]: !!className,
        success: type === 'success',
        error: type === 'error',
        info: type === 'info'
      })}
    />
  );
}

export default Statistic;
