function Cake() {
  return (
    <path
      d="M21 15.546C20.477 15.546 19.954 15.697 19.5 16C19.0558 16.2962 18.5339 16.4542 18 16.4542C17.4661 16.4542 16.9442 16.2962 16.5 16C16.0558 15.7038 15.5339 15.5458 15 15.5458C14.4661 15.5458 13.9442 15.7038 13.5 16C13.0558 16.2962 12.5339 16.4542 12 16.4542C11.4661 16.4542 10.9442 16.2962 10.5 16C10.0558 15.7038 9.53387 15.5458 9 15.5458C8.46613 15.5458 7.9442 15.7038 7.5 16C7.0558 16.2962 6.53387 16.4542 6 16.4542C5.46613 16.4542 4.9442 16.2962 4.5 16C4.05586 15.7037 3.53388 15.5458 3 15.546M9 6V8M12 6V8M15 6V8M9 3H9.01M12 3H12.01M15 3H15.01M21 21V14C21 13.4696 20.7893 12.9609 20.4142 12.5858C20.0391 12.2107 19.5304 12 19 12H5C4.46957 12 3.96086 12.2107 3.58579 12.5858C3.21071 12.9609 3 13.4696 3 14V21H21ZM18 12V10C18 9.46957 17.7893 8.96086 17.4142 8.58579C17.0391 8.21071 16.5304 8 16 8H8C7.46957 8 6.96086 8.21071 6.58579 8.58579C6.21071 8.96086 6 9.46957 6 10V12H18Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Cake;
