import { Col, Row } from 'antd';
import { useCHCContext, useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { Tada } from '../../../stories/assets/svgs';
import { Paragraph, Title } from '../../../stories/atoms';
import ModalFooter from '../../../stories/atoms/ModalFooter';

export function CarbonhoundConnectSuccess({
  onClose,
  analyticsContext,
  integration
}) {
  const { CHCState } = useCHCContext();
  const { me } = useMeContext();
  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row className="carbonhound-connect-success" justify="center">
            <Col flex="52px">
              <Tada />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col flex="525px">
              <Title bottomSpacing={8} align="center" size="md">
                Connection request received
              </Title>
              <Paragraph align="center">
                We will contact you shortly regarding your request.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalFooter
        showHelpLink={false}
        showCancelButton={false}
        saveButtonCopy="Close"
        saveButtonProps={{
          onClick: () => {
            analytics.track('Step Completed', me, {
              ...analyticsContext,
              level3: 'Import Settings',
              utilityProvider: CHCState
            });
            onClose(integration);
          }
        }}
      />
    </>
  );
}
