import { Col, Row } from 'antd';
import { AppRoutes } from '../../routes';
import gusParty from '../../stories/assets/svgs/Gus/gusParty.svg';
import { Button, Link, Page, Paragraph, Title } from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import './trailComplete.scss';

function TrailComplete() {
  return (
    <Page className="trail-complete">
      <Row align="center" justify="center">
        <Col>
          <div className="mb-10">
            <img src={gusParty} alt="Dog wearing a party hat" />
          </div>
          <Title align="center" size="xl" bottomSpacing="12">
            Trail Complete!
          </Title>
          <Paragraph align="center" bottomSpacing="36">
            Congrats, you've finished your onboarding trail.
          </Paragraph>
          <div>
            <Link to={AppRoutes.DASHBOARD}>
              <Button type={BUTTON_TYPES.PRIMARY}>Return Home</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Page>
  );
}

export default TrailComplete;
