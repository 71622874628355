import { Col, Modal as AntModal, Row } from 'antd';
import { useMeContext } from '../../../../context';
import { useAsync, useModal } from '../../../../hooks';
import groupService from '../../../../services/groupService';
import { Button, Logo, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import './UserCompanyDetailsLogo.scss';
import { UserCompanyDetailsLogoUploadForm } from './UserCompanyDetailsLogoUploadForm';

export function UserCompanyDetailsLogo() {
  const { getMyGroupSettings, me } = useMeContext();

  const { groupSettings, company } = me;
  const { id: groupId, logoUrl } = groupSettings ?? {};
  const { Modal, handleShowModal, handleCloseModal } = useModal({
    onCloseModal: getMyGroupSettings,
    width: '60%'
  });
  const isCustomLogoUploaded = !!logoUrl;
  const removeLogoUrl = async () => {
    await groupService.updateGroup({
      groupId,
      companySlug: company?.slug,
      logoUrl: null
    });
    await getMyGroupSettings();
  };

  const {
    execute: handleRemoveLogo,
    Loader: LogoLoader,
    isLoading: isRemovingLogo
  } = useAsync({
    asyncFunction: removeLogoUrl,
    immediate: false
  });

  const confirmRemoveLogo = () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content: 'Are you sure you want to remove the group logo?',
      onOk: handleRemoveLogo
    });
  };

  return (
    <Row gutter={[24, 32]} className="user-company-details-logo">
      <Col span={24}>
        <Row justify="space-between">
          <Col span={12}>
            <Title bottomSpacing={0} size="rg">
              Group Logo
            </Title>
          </Col>

          <Col span={12} className="display-flex justify-end">
            <Button onClick={handleShowModal} type={BUTTON_TYPES.SECONDARY}>
              Edit
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24} align="middle">
          <Col className="blurb-bg-gray">
            <LogoLoader>
              <Logo width={133} />
            </LogoLoader>
          </Col>
          <Col>
            {isCustomLogoUploaded ? (
              <Button
                onClick={confirmRemoveLogo}
                type={BUTTON_TYPES.LINK}
                prefixIcon="Times"
                disabled={isRemovingLogo}
              >
                Remove Logo
              </Button>
            ) : (
              <Paragraph bottomSpacing={0}>Default logo</Paragraph>
            )}
          </Col>
        </Row>
      </Col>

      <Modal title="Edit Logo">
        <UserCompanyDetailsLogoUploadForm
          onCancel={handleCloseModal}
          onSuccess={handleCloseModal}
        />
      </Modal>
    </Row>
  );
}
