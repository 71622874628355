import { Progress as AntProgress } from 'antd';
import classNames from 'classnames';
import React from 'react';
import wriIcon from '../../assets/images/wri-logo-white.png';
import { Title } from '../../stories/atoms';
import { Tooltip } from '../index';
import './progress.scss';

function Progress({
  title,
  showBadge = true,
  showInfo = false,
  fillColor,
  secondary,
  className,
  ...props
}) {
  return (
    <div
      className={classNames('ch-progress__wrapper', {
        secondary,
        [className]: !!className
      })}
    >
      {title && (
        <Title bottomSpacing={0} size="sm">
          {title}
        </Title>
      )}
      <AntProgress
        {...props}
        showInfo={showInfo}
        strokeWidth={12}
        strokeColor={fillColor ?? '#FFFFFF'}
        className={`ch-progress ${props.className}`}
      />
      {showBadge && (
        <Tooltip
          title="WRI Office-Based organization standard"
          overlayClassName="ch-progress__tooltip"
        >
          <div className="ch-progress__achievement-badge-wrapper">
            <img
              src={wriIcon}
              alt="Achievement Icon"
              className="ch-progress__achievement-badge"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default Progress;
