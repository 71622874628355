import { Checkbox, Col, Row, Space } from 'antd';
import { Field, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { CollectionType } from '../../../constants';
import { useMeContext } from '../../../context';
import {
  getDropdownOptions,
  getEmissionSourceDetailsName
} from '../../../helpers';
import { preventOnEnter } from '../../../helpers/eventListenerHelper';
import { useAsync } from '../../../hooks';
import { CollectionService, EmissionSourceService } from '../../../services';
import { Input, Paragraph, Select, Title } from '../../../stories/atoms';

import { INPUT_TYPES } from '../../../stories/atoms/Input/Input.types';
import { convertConstantToString } from '../../../helpers/formatters';
import './collectionSettingsForm.scss';

export default function CollectionSettingsForm({ type }) {
  const { me } = useMeContext();
  const { values, setFieldValue } = useFormikContext();
  const [searchValue, setSearchValue] = useState('');
  const [initialEmissionSources, setInitialEmissionSources] = useState([]);
  const [locationEmissionSources, setLocationEmissionSources] = useState([]);
  const handleFetchEmissionSource = useCallback(async () => {
    const response =
      await EmissionSourceService.listMinimalEmissionSourceDetails({
        companySlug: me?.company?.slug,
        ...(type === CollectionType.BUSINESS_UNIT && {
          collectionTypes: [CollectionType.BUSINESS_UNIT],
          excludeCollectionType: true
        })
      });
    setInitialEmissionSources(response.list);
    return response.list;
  }, [me?.company?.slug]);

  const {
    Loader,
    value: emissionSources,
    setValue: setEmissionSources
  } = useAsync({
    asyncFunction: handleFetchEmissionSource
  });

  const fetchCollectionLocationsDropdown = useCallback(async () => {
    const response = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [CollectionType.LOCATION]
    });

    let filteredLocationsDropdown = getDropdownOptions(
      response.list,
      'name',
      'id'
    );
    filteredLocationsDropdown = [
      { value: null, label: 'None' },
      ...filteredLocationsDropdown
    ];
    return filteredLocationsDropdown;
  }, [me?.company?.slug]);

  const { isLoading: isLoadingLocations, value: locationsDropdown } = useAsync({
    asyncFunction: fetchCollectionLocationsDropdown
  });

  const handleEmissionSourceSearch = (e) => {
    setSearchValue(e.target.value);
    if (locationEmissionSources.length > 0) {
      const filteredEmissionSources = locationEmissionSources?.filter(
        (source) =>
          getEmissionSourceDetailsName(source)
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setEmissionSources(filteredEmissionSources);
      return;
    }
    const filteredEmissionSources = initialEmissionSources?.filter((source) =>
      getEmissionSourceDetailsName(source)
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    setEmissionSources(filteredEmissionSources);
  };

  const handleFilterByLocation = (value) => {
    let filteredEmissionSources = [];
    if (!value) {
      filteredEmissionSources = initialEmissionSources;
    } else {
      initialEmissionSources.forEach((source) => {
        if (!!source.collections) {
          source.collections.forEach((collection) => {
            if (
              collection.type === CollectionType.LOCATION &&
              collection.id === value
            ) {
              filteredEmissionSources = [...filteredEmissionSources, source];
            }
          });
        }
      });
    }
    if (searchValue.length > 0) {
      setLocationEmissionSources(filteredEmissionSources);
      setEmissionSources(
        filteredEmissionSources?.filter((source) =>
          source.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setLocationEmissionSources(filteredEmissionSources);
      setEmissionSources(filteredEmissionSources);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked && emissionSources.length > 0) {
      setFieldValue(
        'emissionSourceDetailsIds',
        emissionSources.map((source) => source.id)
      );
    } else {
      setFieldValue('emissionSourceDetailsIds', []);
    }
  };

  const handleCheckboxChange = (e) => {
    if (!!e.target.checked) {
      setFieldValue('emissionSourceDetailsIds', [
        ...values?.emissionSourceDetailsIds,
        e.target.value
      ]);
    } else {
      const filteredArray = values?.emissionSourceDetailsIds.filter(
        (el) => el !== e?.target?.value
      );
      setFieldValue('emissionSourceDetailsIds', filteredArray);
    }
  };

  const handleLocationsChange = (value) => {
    setFieldValue('collectionId', value);
    handleFilterByLocation(value);
  };

  const isAllSelected =
    values?.emissionSourceDetailsIds.length === emissionSources?.length &&
    emissionSources?.length > 0 &&
    emissionSources?.every((source) =>
      values.emissionSourceDetailsIds.includes(source.id)
    );

  const typeLabel = convertConstantToString(type);

  return (
    <Row>
      <Col
        span={24}
        className="create-business-unit-name__container collection-settings-form-divider"
      >
        <Input
          title="Name"
          type={INPUT_TYPES.TEXT}
          name="collectionName"
          placeholder="e.g. Manufacturing"
          onKeyPress={preventOnEnter}
          required
        />
      </Col>
      <Col span={24} className="mt-4">
        <Title bottomSpacing={16}>
          Add new sources to this {typeLabel.singular}
        </Title>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Input
              type={INPUT_TYPES.TEXT}
              isFormikField={false}
              value={searchValue}
              placeholder="Search source name"
              onChange={handleEmissionSourceSearch}
              onKeyPress={preventOnEnter}
              icon="Search"
            />
          </Col>
          <Col span={12}>
            <Select
              name="collectionId"
              placeholder="Locations"
              value={values?.businessUnitId}
              setFieldValue={setFieldValue}
              options={locationsDropdown}
              loading={isLoadingLocations}
              onChange={handleLocationsChange}
              onKeyPress={preventOnEnter}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Paragraph
              weight={500}
              className="filter-emission-sources__paragraph"
            >
              ({values?.emissionSourceDetailsIds?.length}) sources selected
            </Paragraph>
            <Field name="emissionSourceDetailsIds">
              {() => (
                <Loader>
                  <div className="select-all__container">
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={isAllSelected}
                    >
                      Select All
                    </Checkbox>
                  </div>
                  <Checkbox.Group
                    defaultValue={values?.emissionSourceDetailsIds}
                    value={values?.emissionSourceDetailsIds}
                    className="checkbox-group__container"
                  >
                    <Space
                      direction="vertical"
                      className="checkbox-group-space__wrapper"
                    >
                      {emissionSources?.map((source) => {
                        const emissionSourceDetailsName =
                          getEmissionSourceDetailsName(source);
                        return (
                          <div className="emission-source__container">
                            <Checkbox
                              onChange={handleCheckboxChange}
                              key={source.id}
                              value={source.id}
                            >
                              <Row gutter={16} align="middle">
                                <Col flex={1}>
                                  <Paragraph bottomSpacing={0}>
                                    {emissionSourceDetailsName}
                                  </Paragraph>
                                  <Paragraph
                                    className="business-unit-description-text"
                                    size="sm"
                                    bottomSpacing={0}
                                  >
                                    {source.description}
                                  </Paragraph>
                                </Col>
                              </Row>
                            </Checkbox>
                          </div>
                        );
                      })}
                    </Space>
                  </Checkbox.Group>
                </Loader>
              )}
            </Field>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
