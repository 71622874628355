import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { CollectionType } from '../../../constants';
import { useMeContext, useMetaContext } from '../../../context';
import { getAddressValues } from '../../../helpers';
import { getFormattedAddress } from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { CollectionService } from '../../../services';
import { Select } from '../../../stories/atoms';
import GoogleSelect from '../../../stories/atoms/GoogleSelect';

export function AddressFields({ isEdit = true }) {
  const { me } = useMeContext();
  const { meta } = useMetaContext();
  const { values, setValues, setFieldValue } = useFormikContext();

  const handleLoadCompanyLocations = useCallback(async () => {
    const locationResp = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [CollectionType.LOCATION]
    });
    return locationResp.list.reduce((acc, companyLocation) => {
      if (companyLocation.location?.address1) {
        acc.push({
          value: companyLocation.id,
          label: getFormattedAddress({ location: companyLocation.location })
        });
      }
      return acc;
    }, []);
  }, [me?.company?.slug]);

  const { value: companyLocations } = useAsync({
    asyncFunction: handleLoadCompanyLocations,
    defaultValue: []
  });
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[30, 24]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GoogleSelect
              isEdit={isEdit}
              placholder="Search and select"
              defaultValue={values?.formattedAddress || values?.address1}
              title="Commute Start Address "
              onSelect={(places) => {
                if (places[0]) {
                  const address = getAddressValues(places[0], meta.countries);
                  setValues({
                    ...values,
                    ...address,
                    homeLocationId: undefined,
                    placeId: places[0].place_id,
                    lat: places[0].geometry.location.lat(),
                    lng: places[0].geometry.location.lng()
                  });
                }
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Select
              title="Office Location (End address)"
              placholder="Search and select"
              value={values?.officeCollectionId}
              name="officeCollectionId"
              options={companyLocations}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
