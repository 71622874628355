import { Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { FieldArray, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Card, Loader } from '../../../components';
import { useMeContext } from '../../../context';
import { setTargetSettingStrategyValidation } from '../../../features/Target/TargetSettingData/TargetSettingDataValidation';
import { AppRoutes } from '../../../routes';
import { Button, ConditionalWrapper, TabPane, Tabs } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import DataRow from './DataRow';

const formatValuesArray = (month) => ({
  ...month,
  date: formatInTimeZone(month.date, 'UTC', 'yyyy-MM-dd')
});
function TargetCardWrapper(child) {
  return <Card>{child}</Card>;
}

export function ItemDataForm({
  details: { type, name, id } = {},
  isLoading,
  isLoadingList,
  isSavingForm,
  list,
  formType,
  onSaveFn
}) {
  const { me } = useMeContext();
  const isTargetForm = formType === 'TARGET';
  const isIntensityMetricForm = formType === 'INTENSITY_METRIC';

  const onSubmit = async (values) => {
    const formValues = Object.keys(values).reduce((acc, year) => {
      // We don't want to do logic on the baseYear field
      if (year === 'baseYear') return acc;
      const intYear = parseInt(year);
      const currentValues = values[year]
        .filter(
          (month) =>
            !!month.value &&
            formatInTimeZone(month.date, 'UTC', 'yyyy') === year
        )
        .map(formatValuesArray);

      const nextYearValues = values[year]
        .filter(
          (month) =>
            !!month.value &&
            formatInTimeZone(month.date, 'UTC', 'yyyy') !== year
        )
        .map(formatValuesArray);

      const existingYear = acc.findIndex((a) => a.year == year);
      if (existingYear > -1) {
        acc[existingYear].values.push(...currentValues);
      } else {
        acc.push({
          year: intYear,
          values: currentValues
        });
      }

      if (!!nextYearValues.length) {
        const nextYear = intYear + 1;
        const existingNextYear = acc.findIndex((a) => a.year == nextYear);
        if (existingNextYear > -1) {
          acc[existingNextYear].values.push(...nextYearValues);
        } else {
          acc.push({
            year: nextYear,
            values: nextYearValues
          });
        }
      }
      return acc;
    }, []);

    if (!!formValues) {
      onSaveFn(formValues);
    }
  };

  return (
    <div className="item-data-form">
      <Formik
        validationSchema={setTargetSettingStrategyValidation}
        onSubmit={onSubmit}
        initialValues={{ ...list, baseYear: me?.company?.baseYear }}
        enableReinitialize>
        {({ isValid }) => (
          <Form>
            <ConditionalWrapper
              condition={isTargetForm}
              wrapper={TargetCardWrapper}>
              <Loader isLoading={isLoading}>
                <h6 className="text-bd">{`Total ${name}`}</h6>
                <p className="text-12">
                  Enter the values for each month so you can see your progress
                  towards your goals
                </p>
                <Tabs secondary>
                  {Object.keys(list).map((year, i) => (
                    <TabPane
                      defaultActiveKey="1"
                      tab={
                        year == me?.company?.baseYear
                          ? `${year} (Required)`
                          : year
                      }
                      key={i}>
                      <Loader isLoading={isLoadingList}>
                        <Row gutter={[24, 24]} wrap>
                          <FieldArray
                            name={year}
                            render={() => <DataRow list={list} year={year} />}
                          />
                        </Row>
                      </Loader>
                    </TabPane>
                  ))}
                </Tabs>
              </Loader>
            </ConditionalWrapper>
            <div className="item-data-form__actions mt-6 text-right">
              <Space size={16}>
                {isTargetForm && (
                  <Link
                    to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
                      ':targetDetailsId',
                      id
                    )}
                    state={{ type: type }}>
                    <Button type={BUTTON_TYPES.SECONDARY}>Back</Button>
                  </Link>
                )}
                <Button htmlType="submit" disabled={!isValid || isSavingForm}>
                  {isIntensityMetricForm ? 'Save' : 'Next'}
                </Button>
              </Space>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
