import classNames from 'classnames';
import './List.scss';

export function List({ type, children, className }) {
  const classname = classNames(`ch-list ch-list__${type}`, {
    [className]: !!className
  });
  return type === 'ol' ? (
    <ol className={classname}>{children}</ol>
  ) : (
    <ul className={classname}>{children}</ul>
  );
}
