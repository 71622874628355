import React, { useEffect } from 'react';
import trailOfficeStandard from '../../../assets/images/trails-office-standard.png';
import { TrailSegmentCompletedSteps } from '../../../components';
import TrialOverview from '../../../components/TrailOverview';
import { useMeContext, useOfficeTrailContext } from '../../../context';
import { AppRoutes } from '../../../routes';
import './trailsOverview.scss';

function TrailsOverview() {
  const { me } = useMeContext();
  const { officeTrailDetails, refreshOfficeTrails } = useOfficeTrailContext();

  const isTrailComplete =
    officeTrailDetails?.completedSteps ===
    Object.keys(officeTrailDetails?.officeTrailStatus)?.length;

  const trailRoutes = [
    AppRoutes.OFFICE_ONBOARDING,
    AppRoutes.EMPLOYEE_COMMUTE,
    AppRoutes.BUSINESS_TRAVEL_OVERVIEW,
    AppRoutes.TARGET_SETTING_OVERVIEW
  ];

  const trailsContent = {
    title: 'Office-based operations',
    description:
      'This trail follows the World Resource Institute’s best practices for how office based organizations should measure their carbon footprint. We will cover all of the typical areas that cause significant emissions for any business that has office-based activities as part of their organization.',
    completedSegments: officeTrailDetails.completedSteps,
    totalSegments: Object.entries(officeTrailDetails.officeTrailStatus).length,
    isLocked: false,
    link: isTrailComplete
      ? AppRoutes.TRAIL_COMPLETE
      : trailRoutes[officeTrailDetails.activeStep],
    imgSrc: trailOfficeStandard
  };

  useEffect(() => {
    if (me?.company?.slug) {
      refreshOfficeTrails();
    }
  }, []);

  return (
    <div className="trails-overview">
      {trailsContent && <TrialOverview {...trailsContent} />}
      <div className="trails-overview__segments">
        <div className="trails-overview__segments__header">Trail segments</div>
        <TrailSegmentCompletedSteps />
      </div>
    </div>
  );
}

export default TrailsOverview;
