/** MeContext is responsible for storing the Global State
 * for the currently authenticated user. This is the ONLY Provider
 * used across all routes
 * */

import { formatInTimeZone } from 'date-fns-tz';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { DevelopmentEnvironments, UserRole, UserType } from '../constants';
import DateFormats from '../constants/dateFormats';
import { history, subscriptionHelper } from '../helpers';
import { useAsync } from '../hooks';
import { User } from '../models';
import { AppRoutes } from '../routes';
import { GroupService, MeService } from '../services';
// Create the context
const MeContext = createContext({});

// Define the default context state
const meState = new User();

// Create method to use context
function useMeContext() {
  const context = useContext(MeContext);
  const navigate = useNavigate();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error('useMeContext must be used within a MeContextProvider');
  }
  const [me, setMe] = context;
  const { company } = me;
  const appAccessSubscription = subscriptionHelper.getAppAccessSubscription({
    subscriptions: company?.subscriptions
  });
  const isAppExpired = !appAccessSubscription;

  // Context Methods //
  // This assumes token is sent as a string
  const setJWTToken = (token) => setMe((m) => ({ ...m, token }));

  const setEmail = (email) => setMe((m) => ({ ...m, email }));

  const updateMe = (user) => {
    setMe((m) => ({ ...m, ...user }));
  };
  const updateLoggedInAs = ({ loggedInAs = {}, ...user }) => {
    if (!user) user = me;
    if (!!loggedInAs) {
      localStorage.setItem('loggedInAs', JSON.stringify(loggedInAs));
    } else {
      localStorage.removeItem('loggedInAs');
    }
    setMe((m) => ({ ...m, ...user, loggedInAs }));
  };

  const clearUser = () => {
    setMe((prevMe) => ({ ...new User(), groupSettings: prevMe.groupSettings }));
  };

  const refreshGroupSettings = async () => {
    if (window.location.hostname.includes('app.')) return;
    const hostNameSplit = window.location.hostname.split('.');
    const groupSlug = hostNameSplit.length > 1 ? hostNameSplit[0] : null;
    if (groupSlug) {
      const response = await GroupService.listGroupSettings({
        groupSlug
      });
      setMe((prev) => ({
        ...prev,
        groupSettings: response.group
      }));
    }
  };

  const { execute: getMyGroupSettings, isLoading: isLoadingGroupSettings } =
    useAsync({ immediate: false, asyncFunction: refreshGroupSettings });

  const getMyDetails = async () => {
    try {
      const user = await MeService.getMyDetails();
      updateMe(user);
      return user;
    } catch (err) {
      return err;
    }
  };

  const selectCarbonYearRange = ({ activeYear }) => {
    const month = me.company?.startingMonth ? me.company?.startingMonth - 1 : 0;
    const unformattedRangeStart = new Date(activeYear, month, 1);
    const rangeStart = formatInTimeZone(
      unformattedRangeStart,
      'UTC',
      DateFormats.API
    );

    const rangeEnd = formatInTimeZone(
      new Date(
        unformattedRangeStart.getUTCFullYear() + 1,
        unformattedRangeStart.getUTCMonth(),
        0
      ),
      'UTC',
      DateFormats.API
    );
    return { rangeStart, rangeEnd };
  };

  const updateCompany = (company) => {
    setMe((m) => {
      const companyUpdate = { ...m.company, ...company };
      return { ...m, company: companyUpdate };
    });
  };

  const isSuperUser = (user) => {
    if (!user) user = me;
    return user.userType === UserType.INTERNAL;
  };

  const isServiceUser = (user) => {
    if (!user) user = me;
    return isSuperUser(user) || user.userType === UserType.SERVICE;
  };

  const isCurrentUser = (id) => id === me?.id;
  const isGroupSettingsMember = !!me.groupSettings;
  // Adapted from https://jasonwatmore.com/post/2021/10/07/react-history-listen-and-unlisten-with-react-router-v5
  // Redirecting in RRV6 from the axios interceptors is difficult, because you cannot use context methods,
  // and this is the best way I've found to implement redirects from the server
  useEffect(() => {
    const unListen = history.listen(async ({ location }) => {
      const locationState = location.state;
      if (!!locationState) {
        if (!!locationState.isTermsInvalid) {
          navigate(AppRoutes.TERMS);
        } else if (!!locationState.isSubscriptionInvalid) {
          navigate(AppRoutes.SUBSCRIPTION);
        } else if (!!locationState.isLoggedOut) {
          clearUser();
          navigate(AppRoutes.LOGIN);
        } else if (!!locationState.isOnboardingRequired) {
          navigate(
            `${AppRoutes.ONBOARDING}${
              locationState.onboardingStep
                ? `?screen=${locationState.onboardingStep}`
                : ''
            }`
          );
        } else if (
          typeof locationState.isTermsInvalid !== 'undefined' &&
          !locationState.isTermsInvalid
        ) {
          navigate(me.prevRoute ?? AppRoutes.HOME);
        }
      }
    });
    // stop the listener when component unmounts
    return unListen;
  }, []);

  useEffect(() => {
    const loggedInAs = localStorage.getItem('loggedInAs');
    if (!!loggedInAs) updateMe({ loggedInAs: JSON.parse(loggedInAs) });
  }, []);

  // Return state and Context Methods
  // Note: DO NOT return "setMe".State updates should be managed through context methods
  return {
    me,
    setJWTToken,
    getMyDetails,
    updateMe,
    updateLoggedInAs,
    updateCompany,
    setEmail,
    isCurrentUser,
    clearUser,
    isSuperUser,
    isServiceUser,
    isAppExpired,
    appAccessSubscription,
    isAdmin:
      me?.company?.role?.permissionType === UserRole.ADMIN || isSuperUser(me),
    selectCarbonYearRange,
    canAccessDeveloperFeature:
      DevelopmentEnvironments.indexOf(process.env.REACT_APP_ENV) >= 0 ||
      isSuperUser(),
    getMyGroupSettings,
    isGroupSettingsMember,
    isLoadingGroupSettings
  };
}

// Create the context provider
function MeContextProvider(props) {
  const [me, setMe] = useState(meState);
  const value = useMemo(() => [me, setMe], [me]);
  return <MeContext.Provider value={value} {...props} />;
}

export { MeContextProvider, useMeContext };
