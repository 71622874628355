import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { CollectionRoute } from '../../../constants/collectionTypes';

import {
  COLOURS_CARBONHOUND,
  COLOURS_VIOLET
} from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { convertConstantToString } from '../../../helpers/formatters';
import { useAsync } from '../../../hooks';
import { useDrawer } from '../../../hooks/useDrawer';
import { CollectionService } from '../../../services';
import { Button, Icon, Paragraph, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import CollectionSettingsForm from '../CollectionSettingsForm';
import './collectionsSettings.scss';
import { collectionSettingsValidation } from './collectionsSettingsValidation';

function CollectionSettings({ type }) {
  const { me } = useMeContext();
  const navigate = useNavigate();

  const typeLabel = convertConstantToString(type);

  const handleFetchCollectionsList = useCallback(async () => {
    const response = await CollectionService.listCollections({
      companySlug: me?.company?.slug,
      types: [type]
    });
    return response;
  }, [me?.company?.slug]);

  const {
    execute: fetchCollectionsList,
    value: collections,
    Loader: CollectionsLoader
  } = useAsync({
    asyncFunction: handleFetchCollectionsList
  });

  const {
    Drawer: CollectionSettingDrawer,
    handleShowDrawer,
    handleCloseDrawer
  } = useDrawer({
    width: '50%'
  });

  const handleSubmitCollection = async (values) => {
    const collectionResponse = await CollectionService.createCollection({
      companySlug: me?.company?.slug,
      name: values.collectionName,
      type
    });
    if (values.emissionSourceDetailsIds.length > 0) {
      await CollectionService.createCollectionEmissionSourceBulk({
        collectionId: collectionResponse.data.id,
        emissionSourceDetailsIds: values.emissionSourceDetailsIds
      });
    }
    analytics.track('Save item clicked', me, {
      level1: 'Sources',
      level2: `${typeLabel.plural}`,
      collectionId: collectionResponse.data.id
    });
    fetchCollectionsList();
    handleCloseDrawer();
  };

  const handleSelect = (id) => {
    analytics.track('Item clicked', me, {
      level1: 'Sources',
      level2: `${typeLabel.plural}`,
      collectionId: id
    });

    navigate(`/${CollectionRoute[type]}/${id}`);
  };

  const handleAddCollection = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Sources',
      level2: `${typeLabel.plural}`
    });
    handleShowDrawer();
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <div className="new-collection__wrapper">
              <Title bottomSpacing={0}>{typeLabel.plural}</Title>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                htmlType="button"
                className="mr-3"
                onClick={handleAddCollection}
              >
                Add New
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <CollectionsLoader>
            {collections?.list?.length === 0 ? (
              <Col span={24} className="no-collections-units__container">
                <Row>
                  <Col span={24} className="display-flex justify-center">
                    <div className="no-collections-icon__wrapper">
                      <Icon
                        bgColor={COLOURS_VIOLET.VIOLET_200}
                        color={COLOURS_VIOLET.VIOLET_800}
                        name="OfficeBuilding"
                        bottomSpacing={32}
                      />
                    </div>
                  </Col>
                  <Col span={24} className="display-flex justify-center">
                    <Title bottomSpacing={8} size="md">
                      No {typeLabel.plural} Added
                    </Title>
                  </Col>
                  <Col span={24} className="display-flex justify-center">
                    <Paragraph bottomSpacing={0}>
                      {typeLabel.singular} are divisions within your company
                      that you can assign data to.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            ) : (
              collections?.list?.map((collection) => (
                <Col span={24} onClick={() => handleSelect(collection?.id)}>
                  <div className="collection__wrapper">
                    <Paragraph bottomSpacing={0} weight={500}>
                      {collection.name}
                    </Paragraph>
                    <Icon
                      size={16}
                      color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      name="ChevronRight"
                      className="collection__icon"
                    />
                  </div>
                </Col>
              ))
            )}
          </CollectionsLoader>
        </Row>
      </Col>
      <CollectionSettingDrawer
        onCancel={handleCloseDrawer}
        buttons={[
          {
            htmlType: 'submit',
            children: 'Save'
          }
        ]}
        title="Add New Collection"
        formikContext={{
          className: 'actions-form',
          onSubmit: handleSubmitCollection,
          initialValues: {
            collectionName: '',
            emissionSourceDetailsIds: [],
            collectionNameSearch: '',
            collectionId: undefined
          },
          validationSchema: collectionSettingsValidation
        }}
      >
        <CollectionSettingsForm type={type} />
      </CollectionSettingDrawer>
    </Row>
  );
}

export default CollectionSettings;
