import { Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../../context';
import { AppRoutes } from '../../../../routes';
import { AuthService } from '../../../../services';
import { Button, Icon } from '../../../../stories/atoms';
import './ChcAdminHeaderMenu.scss';

export function ChcAdminHeaderMenu({ text = '' }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { clearUser } = useMeContext();
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    AuthService.logout(() => {
      clearUser();
      navigate(AppRoutes.LOGIN);
    });
  };

  return (
    <Dropdown
      className="chc-admin-header-menu"
      trigger="click"
      onVisibleChange={handleMenuClick}
      overlay={
        <Menu>
          <Menu.Item onClick={handleLogout}>
            <span className="display-flex align-center">
              <Icon className="mr-2" name="Logout" /> Logout
            </span>
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        className="chc-admin-header-menu__toggle"
        onClick={handleMenuClick}
        icon={isMenuOpen ? 'ChevronUp' : 'ChevronDown'}
      >
        {text}
      </Button>
    </Dropdown>
  );
}
