import { Col, Divider, Row, Space } from 'antd';
import { useCallback } from 'react';
import { Card, Loader } from '../../../../../components';
import { OfficeTrail, OfficeTrailSegment } from '../../../../../constants';
import {
  useEmployeeCommuteContext,
  useLayoutContext,
  useMeContext,
  useOfficeTrailContext
} from '../../../../../context';
import EmployeeInformation from '../../../../../features/EmployeeDetail/EmployeeInformation';
import { analytics } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import {
  CommuteService,
  CompanyTrailService,
  MeService
} from '../../../../../services';
import { Button, Paragraph, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../../../stories/atoms/Layout';
import {
  CommuteItem,
  CompletionTimeline
} from '../../../../../stories/molecules';

function WFHDetails({
  workFromHomeCommutes,
  handleOnDeleteItemSuccess,
  handleProcessingData
}) {
  return workFromHomeCommutes.map((commute) => (
    <Col span={24}>
      <CommuteItem
        emissionType="wfh"
        handleOnDeleteSuccess={handleOnDeleteItemSuccess}
        handleProcessingData={handleProcessingData}
        key={commute.id}
        commuteItem={commute}
      />
    </Col>
  ));
}

function EmployeeDetailsOverview() {
  const { me } = useMeContext();
  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};
  const { navStep } = useLayoutContext();
  const {
    setCommutes,
    setIsDetailsLoading,
    setProfile,
    employeeCommuteDetails
  } = useEmployeeCommuteContext();
  const { refreshEmployeeOnboardingTrails } = useOfficeTrailContext();

  const { profile } = employeeCommuteDetails;

  const handleFetchSummaryTimeline = useCallback(async () => {
    const response = await CommuteService.fetchCommuteSummaryTimeline({
      companySlug: me?.company?.slug,
      loggedInUserId
    });
    return response.timeline;
  }, [me?.company?.slug]);

  const loadEmployeeCommutes = useCallback(async () => {
    setIsDetailsLoading(true);
    const commuteList = await CommuteService.fetchEmployeeCommutes({
      companySlug: me?.company?.slug,
      loggedInUserId
    });
    const timeline = await handleFetchSummaryTimeline();
    const isComplete = !timeline?.find((t) => !t.isComplete);
    const profileResp = await MeService.getProfileDetails();
    setProfile(profileResp);
    setCommutes(commuteList);
    setIsDetailsLoading(false);
    return { commuteList, timeline, isComplete };
  }, [me?.company?.slug, loggedInUserId]);

  const { value, execute: refreshList } = useAsync({
    asyncFunction: loadEmployeeCommutes
  });
  const handleNext = async () => {
    await CompanyTrailService.createCompanyTrail(me.company.slug, {
      trailName: OfficeTrail.EMPLOYEE_ONBOARDING,
      segmentName: OfficeTrailSegment.COMMUTE,
      isComplete: true,
      userId: loggedInUserId ?? me.id
    });
    analytics.track('Segment Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Overview'
    });
    await refreshEmployeeOnboardingTrails();
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_BUSINESS_TRAVEL);
  };

  const { commuteList, timeline, isComplete } = value ?? {
    timeline: [],
    commuteList: []
  };
  const hasCreatedStartDate = profile?.company?.startDate;
  const currentCommutes = commuteList.filter(
    (commute) => !commute.endDate && !commute.isWorkFromHome
  );
  const inactiveCommutes = commuteList.filter(
    (commute) => commute.endDate && !commute.isWorkFromHome
  );
  const workFromHomeCommutes = commuteList.filter(
    (commute) => commute.isWorkFromHome
  );

  const handlePrevious = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW);
  };

  const handleProcessingData = () => {
    loadEmployeeCommutes();
    refreshList();
  };

  const handleOnDeleteItemSuccess = (commute) => {
    analytics.track('Delete item clicked', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Overview',
      commuteDetails: commute
    });
    refreshList();
  };

  const handleAddCommuteAnalytics = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Overview',
      level4: 'Commute'
    });
  };
  const handleAddCommute = () => {
    handleAddCommuteAnalytics();
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH);
  };

  const handleAddWFHAnalytics = () => {
    analytics.track('Add new Selected', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Overview',
      level4: 'Wfh'
    });
  };
  const handleAddWFH = () => {
    handleAddWFHAnalytics();
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_HOME);
  };

  return (
    <>
      <Row className="employee-details-overview mb-4" gutter={[0, 16]}>
        <Col span={24}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Title bottomSpacing={32} size="xl">
                Employee Information
              </Title>
            </Col>
            <Col span={24}>
              <EmployeeInformation onSuccess={refreshList} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="mt-4 mb-4">
            <Card>
              <CompletionTimeline
                timeline={timeline}
                isLoading={employeeCommuteDetails?.isDetailsLoading}
              />
            </Card>
          </div>
        </Col>
        <Col span={24}>
          <Card>
            <Row gutter={[0, 24]}>
              <Col flex="1 1 auto">
                <Title bottomSpacing={4} size="rg">
                  My Commutes
                </Title>
                <Paragraph bottomSpacing={0} size="sm">
                  Add in your current or previous commute details
                </Paragraph>
              </Col>
              <Col flex="0 1 1" className="align-center display-flex">
                {hasCreatedStartDate ? (
                  <Button onClick={handleAddCommute}>Add</Button>
                ) : (
                  <Paragraph bottomSpacing={0}>
                    Company start date required
                  </Paragraph>
                )}
              </Col>
              {((hasCreatedStartDate && currentCommutes.length > 0) ||
                inactiveCommutes.length > 0) && (
                <Col span={24}>
                  <Divider className="m-0" />
                </Col>
              )}
              {hasCreatedStartDate && currentCommutes.length > 0 && (
                <Col span={24}>
                  <Title fontWeight={600} size="xs">
                    Current Commutes
                  </Title>
                  <Row gutter={[0, 16]}>
                    {currentCommutes.map((commute) => (
                      <Col span={24}>
                        <CommuteItem
                          handleOnDeleteSuccess={handleOnDeleteItemSuccess}
                          handleProcessingData={handleProcessingData}
                          key={commute.id}
                          emissionType="commute"
                          commuteItem={commute}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
              {hasCreatedStartDate && inactiveCommutes.length > 0 && (
                <Col span={24}>
                  <Title fontWeight={600} size="xs">
                    Past Commutes
                  </Title>
                  <Row gutter={[0, 16]}>
                    {inactiveCommutes.map((commute) => (
                      <Col span={24}>
                        <CommuteItem
                          handleOnDeleteSuccess={handleOnDeleteItemSuccess}
                          handleProcessingData={handleProcessingData}
                          emissionType="commute"
                          key={commute.id}
                          commuteItem={commute}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row gutter={[0, 24]}>
              <Col flex="1 1 auto">
                <Title bottomSpacing={4} size="rg">
                  Work from home
                </Title>
                <Paragraph size="sm">Add in your home office details</Paragraph>
              </Col>
              <Col flex="0 1 1" className="align-center display-flex">
                {hasCreatedStartDate ? (
                  <Button onClick={handleAddWFH}>Add</Button>
                ) : (
                  <Paragraph bottomSpacing={0}>
                    Company start date required
                  </Paragraph>
                )}
              </Col>

              {hasCreatedStartDate && workFromHomeCommutes.length > 0 && (
                <Col span={24}>
                  <Row gutter={[0, 16]} justify="center">
                    {employeeCommuteDetails?.isDetailsLoading ? (
                      <Row
                        align="middle"
                        justify="middle"
                        className="flex-direction-column"
                      >
                        <Col className="mb-4">
                          <Loader isLoading />
                        </Col>
                        <Col className="mb-2">
                          <Title size="rg" bottomSpacing={0}>
                            Fetching your data
                          </Title>
                        </Col>
                        <Col>
                          <Paragraph bottomSpacing={0}>
                            Sniffing out the carbon...
                          </Paragraph>
                        </Col>
                      </Row>
                    ) : (
                      WFHDetails({
                        workFromHomeCommutes,
                        handleOnDeleteItemSuccess,
                        handleProcessingData
                      })
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Footer className="justify-end display-flex">
        <Space>
          <Button type={BUTTON_TYPES.SECONDARY} onClick={handlePrevious}>
            Back
          </Button>
          <Button
            disabled={!isComplete}
            onClick={handleNext}
            className="employee-commute-overview__complete-btn"
          >
            Next
          </Button>
        </Space>
      </Footer>
    </>
  );
}

export default EmployeeDetailsOverview;
