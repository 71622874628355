import { Col, Row, Space } from 'antd';
import { Card, Tooltip } from '../../../../components';
import { COLOURS_INDIGO } from '../../../../constants/colours';
import { useLayoutContext } from '../../../../context';
import { AppRoutes } from '../../../../routes';
import {
  Anchor,
  Button,
  Icon,
  LegendIndicator,
  Paragraph,
  Title
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../../stories/atoms/Layout';
import { LEGEND_INDICATOR_TYPES } from '../../../../stories/atoms/LegendIndicator/LegendIndicator.types';
import './OnboardingEmployeeOverview.scss';

export function OnboardingEmployeeOverview() {
  const { navStep } = useLayoutContext();
  const handleGetStarted = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
  };
  return (
    <>
      <Row>
        <Col span={24} className="employee-details-header__container">
          <Space direction="vertical">
            <Title size="xl" bottomSpacing={0}>
              Employee details
            </Title>
            <Paragraph bottomSpacing={0}>
              Let’s get you started using Carbonhound.
            </Paragraph>
          </Space>
          <Button onClick={handleGetStarted}>Get Started</Button>
        </Col>
        <Col span={24}>
          <Row className="employee-details-getting-started">
            <Col
              span={12}
              className="employee-details-getting-started-description__wrapper"
            >
              <Title size="xl" bottomSpacing={12}>
                Getting started for employees
              </Title>
              <Paragraph>
                As an employee your role in your overall company emissions can
                be really impactful. Your habits around commuting and travel
                have an impact and you have an opportunity to not only measure
                your footprint but start to reduce it.
              </Paragraph>
            </Col>
            <Col span={12} className="employee-details-video__wrapper">
              <iframe
                className="employee-details-video"
                src="https://www.youtube.com/embed/SVEoy7qeHeA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Space direction="vertical">
            <Title bottomSpacing={0}>
              How to measure your impact as an employee
            </Title>
            <Paragraph bottomSpacing={0}>
              Your impact as an employee is broken into three key categories:
            </Paragraph>
          </Space>
        </Col>
        <Col span={24} className="employee-details-card__container">
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Card className="employee-details-card">
                <Icon
                  className="employee-details-icon"
                  color={COLOURS_INDIGO.INDIGO_500}
                  name="Briefcase"
                />
                <Space direction="vertical" size={0}>
                  <Title bottomSpacing={0}>Commutes</Title>
                  <Paragraph bottomSpacing={0}>
                    How you get to and from work
                  </Paragraph>
                </Space>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="employee-details-card">
                <Icon
                  className="employee-details-icon"
                  color={COLOURS_INDIGO.INDIGO_500}
                  name="Desktop"
                />
                <Space direction="vertical" size={0}>
                  <Title bottomSpacing={0}>Home Offices</Title>
                  <Paragraph bottomSpacing={0}>
                    These are the details of your home office
                  </Paragraph>
                </Space>
              </Card>
            </Col>
            <Col span={24}>
              <Card className="employee-details-card">
                <Icon
                  className="employee-details-icon"
                  color={COLOURS_INDIGO.INDIGO_500}
                  name="Ticket"
                />
                <Space direction="vertical" size={0}>
                  <Title bottomSpacing={0}>Business Travel</Title>
                  <Paragraph bottomSpacing={0}>
                    These are any trips you have taken in your role
                  </Paragraph>
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Space direction="vertical">
            <Title bottomSpacing={0}>Required data</Title>
            <Paragraph bottomSpacing={0}>
              Your company has requested a specific timeline of data and your
              current working details to be completed. Please take some time to
              ensure you have accurate data for these periods
            </Paragraph>
          </Space>
        </Col>
        <Col span={24}>
          <Space direction="vertical">
            <Title bottomSpacing={0}>Completing your data entry</Title>
            <Paragraph bottomSpacing={0}>
              Based off of your start date and your company’s requirements we
              create timelines that represent your coverage. As you enter data
              you will see your coverage update.
            </Paragraph>
          </Space>
        </Col>
        <Col span={24} className="employee-details-timeline__container">
          <Card>
            <Paragraph>Example timeline:</Paragraph>
            <div className="employee-details-timeline">
              <Space className="ch-completion-timeline__total-timeline">
                <Title size="xs">April 2021</Title>
                <Title size="xs">March 2022</Title>
              </Space>
              <Space align="center" className="ch-completion-timeline__chart">
                {Array.from(Array(12)).map((_, ti) => (
                  <Tooltip color="white" key={ti}>
                    <span>
                      <LegendIndicator
                        type={LEGEND_INDICATOR_TYPES.INCOMPLETE}
                      />
                    </span>
                  </Tooltip>
                ))}
              </Space>
              <Space
                size={16}
                align="center"
                className="ch-completion-timeline__legend justify-end"
              >
                <p className="m-0">
                  Complete
                  <LegendIndicator type={LEGEND_INDICATOR_TYPES.COMPLETE} />
                </p>
                <p className="m-0">
                  Incomplete
                  <LegendIndicator type={LEGEND_INDICATOR_TYPES.INCOMPLETE} />
                </p>
              </Space>
            </div>
          </Card>
        </Col>
        <Col span={24} className="employee-details-start-measuring__container">
          <Space
            direction="vertical"
            className="employee-details-start-measuring-description"
          >
            <Title bottomSpacing={0}>
              Let’s get started measuring your impact!
            </Title>
            <Paragraph bottomSpacing={0}>
              If you need any help or support throughout the commute process you
              can always refer to our help centre for guidance.
            </Paragraph>
          </Space>
          <Space size={24}>
            <Anchor
              fontWeight={500}
              showLinkIcon={false}
              href="https://carbonhound.notion.site/Employees-Getting-Started-for-Employees-df295645cca24b74aa3f8b3e47c9862f?pvs=4"
            >
              View Help Centre
            </Anchor>
            <Button type={BUTTON_TYPES.PRIMARY} onClick={handleGetStarted}>
              Measure my Impact
            </Button>
          </Space>
        </Col>
      </Row>
      <Footer className="display-flex justify-end">
        <Button onClick={handleGetStarted}>Get Started</Button>
      </Footer>
    </>
  );
}
