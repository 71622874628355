export const TRAIL_NAME = {
  COMPANY: 'COMPANY'
};

// Config_org is intentionally commented out because order-of-segments matters
export const SEGMENT_NAME = {
  REPORTING_DETAILS: 'REPORTING_DETAILS',
  CONFIGURE_ORGANIZATION: 'CONFIGURE_ORGANIZATION',
  CLIMATE_GOALS: 'CLIMATE_GOALS',
  GREEN_TEAM: 'GREEN_TEAM'
};
