function ExclamationTriangle() {
  return (
    <path
      d="M12 9V11M12 15H12.01M5.072 19H18.928C20.468 19 21.43 17.333 20.66 16L13.732 4C12.962 2.667 11.038 2.667 10.268 4L3.34 16C2.57 17.333 3.532 19 5.072 19Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ExclamationTriangle;
