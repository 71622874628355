import classNames from 'classnames';
import './ColumnItem.scss';

function ColumnItem({ children, className, align, ...props }) {
  return (
    <td
      className={classNames('ch-column-item ant-table-cell', {
        [className]: !!className
      })}
      {...props}
    >
      <span
        className={classNames('ch-column-item__inner', {
          'justify-end': align === 'right',
          'justify-center': align === 'center'
        })}
      >
        {children}
      </span>
    </td>
  );
}
export default ColumnItem;
