import { Card, Col, Row } from 'antd';
import * as dateFns from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadStatus } from '../../constants';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import { useMeContext } from '../../context';
import { useDataImportContext } from '../../context/DataImportContext';
import { analytics, capitalizeText, getFullName } from '../../helpers';
import { useAsync, usePagination } from '../../hooks';
import { NotificationService } from '../../services';
import {
  ButtonIcon,
  ColumnTitle,
  Icon,
  Paragraph,
  Table,
  Tag,
  Title
} from '../../stories/atoms';
import { INPUT_TYPES } from '../../stories/atoms/Input/Input.types';
import { TAG_COLOURS } from '../../stories/atoms/Tag/tag.types';
import TableTitle from '../../stories/molecules/TableTitle';
import './yourExportsOverview.scss';

function YourExportsOverview({ activeKey }) {
  const { handleDownloadFile } = useDataImportContext();
  const { me } = useMeContext();
  const [exportList, setExportList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [exportCount, setExportCount] = useState(null);

  const { paginationParams, handleSetPaginationParams } = usePagination({
    pageSize: 30,
    paginationArray: exportList,
    orderBy: ['createdAt'],
    order: 'DESC'
  });

  const pagination = paginationParams;

  const listNotificationActionDataExport = useCallback(async () => {
    const response = await NotificationService.fetchNotificationActions({
      pagination: {
        ...pagination
      },
      categories: ['DATA_EXPORT']
    });
    if (response.list.length === 0 && searchQuery.length === 0) {
      setExportList(null);
    }
    setExportList(response.list);
    setExportCount(response.count);
    return response.list;
  }, [
    paginationParams.pageNumber,
    paginationParams.orderBy,
    paginationParams.search?.queryString
  ]);

  const { execute, isLoading } = useAsync({
    asyncFunction: listNotificationActionDataExport
  });

  useEffect(() => {
    if (activeKey === '3') {
      execute();
    }
  }, [activeKey]);
  const handleSearch = (query) => {
    setSearchQuery(query);
    handleSetPaginationParams({
      ...pagination,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['title']
          }
        : undefined
    });
  };

  const handleDownload = (col) => {
    analytics.track('Download clicked', me, {
      level1: 'Data Explorer',
      level2: 'Your Exports',
      CSVExportId: col.id
    });
    handleDownloadFile(col);
  };

  const searchProps = {
    type: INPUT_TYPES.SEARCH,
    isFormikField: false,
    enterButton: true,
    placeholder: 'Search by name',
    onSearch: handleSearch,
    allowClear: true
  };

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      title: <ColumnTitle>Report Name</ColumnTitle>,
      render: (_, col) => (
        <Title fontWeight={500} bottomSpacing={0}>
          {capitalizeText(col.title) ?? '--'}
        </Title>
      )
    },
    {
      key: 'reportingPeriod',
      dataIndex: 'reportingPeriod',
      title: <ColumnTitle>Reporting Period</ColumnTitle>,
      render: (_, col) =>
        `${formatInTimeZone(
          new Date(col.details.rangeStart),
          'UTC',
          'MMM dd, yyyy'
        )} - ${formatInTimeZone(
          new Date(col.details.rangeEnd),
          'UTC',
          'MMM dd, yyyy'
        )}`
    },
    {
      key: 'requestedBy',
      dataIndex: 'createdBy',
      title: <ColumnTitle>Requested By</ColumnTitle>,
      render: (createdBy) => getFullName(createdBy)
    },
    {
      key: 'timeOfRequest',
      dataIndex: 'timeOfRequest',
      title: <ColumnTitle>Time of Request</ColumnTitle>,
      render: (_, col) =>
        `${dateFns.format(new Date(col.createdAt), 'MMM dd, yyyy h:mma')}`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        let tagColour;
        switch (status) {
          case UploadStatus.COMPLETE: {
            tagColour = TAG_COLOURS.GREEN;
            break;
          }
          case UploadStatus.PROCESSING: {
            tagColour = TAG_COLOURS.ORANGE;
            break;
          }

          case UploadStatus.PENDING: {
            tagColour = TAG_COLOURS.YELLOW;
            break;
          }
          case UploadStatus.ERROR: {
            tagColour = TAG_COLOURS.RED;
            break;
          }
          case UploadStatus.READY_FOR_REVIEW:
          default: {
            tagColour = TAG_COLOURS.BLUE;
            break;
          }
        }
        return <Tag color={tagColour}>{capitalizeText(status)}</Tag>;
      }
    },
    {
      render: (_, col) => {
        if (col.status === UploadStatus.COMPLETE) {
          return (
            <ButtonIcon
              color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
              name="Download"
              onClick={() => handleDownload(col)}
            />
          );
        }
      }
    }
  ];

  return (
    <Card>
      {!!exportList ? (
        <Row>
          <Col span={24}>
            <TableTitle searchProps={searchProps} />
            <Table
              columns={columns}
              total={exportCount}
              dataSource={exportList}
              showPagination
              pagination={pagination}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[0, 32]} className="your-exports-empty__container">
          <Col
            span={24}
            className="justify-center display-flex your-exports-icon"
          >
            <Icon
              bgColor={COLOURS_CARBONHOUND.PRIMARY_PURPLE_ACCENT}
              showBackground
              color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
              size={24}
              name="Download"
            />
          </Col>
          <Col span={24}>
            <Title align="center" size="md" bottomSpacing={8}>
              No Exports
            </Title>
            <Paragraph align="center" bottomSpacing={0}>
              Once you have started an export of a report in Carbonhound it will
              appear here. You can try this in your Reporting Tab
            </Paragraph>
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default YourExportsOverview;
