import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Card } from '../../../components';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { Button, Logo, Paragraph, Title } from '../../atoms';
import './checkEmail.scss';

function CheckEmail({ email, description, onPrevious }) {
  const { isGroupSettingsMember } = useMeContext();

  return (
    <div
      className={classNames('check__email__page-container', {
        'check__email__page-container--group-settings-member':
          isGroupSettingsMember
      })}
    >
      <Row className="check__email__form-wrapper">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="display-flex justify-center">
            <Card className="check__email__form-card">
              <Row>
                <Col span={24}>
                  <Logo className="check__email__logo mb-8" />
                  <Title
                    size="lg"
                    fontWeight={600}
                    align="center"
                    color={COLOURS_GRAY.GRAY_800}
                    className="my-5"
                  >
                    Check your email
                  </Title>
                  <Paragraph
                    size="rg"
                    align="center"
                    color={COLOURS_GRAY.GRAY_800}
                  >
                    We’ve sent an email with a verification link to:
                  </Paragraph>
                  <div className="check__email__display">{email}</div>
                  <Paragraph
                    size="rg"
                    align="center"
                    color={COLOURS_GRAY.GRAY_800}
                    className="mt-5"
                  >
                    {description}
                  </Paragraph>
                  <div className="mt-5">
                    <Button className="m-auto" onClick={onPrevious}>
                      Back to Login
                    </Button>
                  </div>
                  <div>
                    <Paragraph
                      size="rg"
                      align="center"
                      color={COLOURS_GRAY.GRAY_800}
                      className="mt-5"
                    >
                      Can’t find the email? It could have been sent to your spam
                      or junk folders.
                    </Paragraph>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CheckEmail;
