import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import './appLoader.scss';

function AppLoader({ loading }) {
  return loading ? (
    <div className="app-loader">
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 40, color: COLOURS_CARBONHOUND.PRIMARY_PURPLE }}
            spin
          />
        }
      />
    </div>
  ) : null;
}

export default AppLoader;
