import { AppRoutes } from '../routes';
import { isSuperUser } from './userType';

export function rerouteHelper(user) {
  if (!user.company) return;
  const { onboardingStatus } = user?.company;
  const { isOnboardingRequired, onboardingStep } = onboardingStatus;
  if (!isSuperUser(user) && isOnboardingRequired) {
    return `${AppRoutes.ONBOARDING}${
      !!onboardingStep ? `?screen=${onboardingStep}` : ''
    }`;
  }
}
