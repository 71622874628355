function PawBlue({ size = 87 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 86 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9176 6.2456C29.4246 8.36984 26.3251 9.65361 23.0602 9.91419C19.3699 10.2078 15.9051 11.8069 13.2875 14.4245C10.6699 17.0421 9.07081 20.5069 8.77723 24.1971C8.51664 27.462 7.23288 30.5615 5.10864 33.0545C2.70662 35.8716 1.38721 39.4525 1.38721 43.1545C1.38721 46.8566 2.70662 50.4375 5.10864 53.2545C7.23288 55.7476 8.51664 58.8471 8.77723 62.112C9.07081 65.8022 10.6699 69.267 13.2875 71.8846C15.9051 74.5022 19.3699 76.1013 23.0602 76.3949C26.3251 76.6555 29.4246 77.9392 31.9176 80.0635C34.7346 82.4655 38.3155 83.7849 42.0176 83.7849C45.7197 83.7849 49.3005 82.4655 52.1176 80.0635C54.6106 77.9392 57.7101 76.6555 60.975 76.3949C64.6652 76.1013 68.13 74.5022 70.7476 71.8846C73.3653 69.267 74.9643 65.8022 75.2579 62.112C75.5185 58.8471 76.8023 55.7476 78.9265 53.2545C81.3285 50.4375 82.648 46.8566 82.648 43.1545C82.648 39.4525 81.3285 35.8716 78.9265 33.0545C76.8023 30.5615 75.5185 27.462 75.2579 24.1971C74.9643 20.5069 73.3653 17.0421 70.7476 14.4245C68.13 11.8069 64.6652 10.2078 60.975 9.91419C57.7101 9.65361 54.6106 8.36984 52.1176 6.2456C49.3005 3.84358 45.7197 2.52417 42.0176 2.52417C38.3155 2.52417 34.7346 3.84358 31.9176 6.2456Z"
        fill="#E0E7FF"
      />
      <path
        d="M29.1406 56.1328C29.1406 49.8372 34.0303 40.5884 40.0619 40.5884H46.019C52.0507 40.5884 56.9404 49.8372 56.9404 56.1328C56.9404 62.4284 52.0507 59.2417 46.019 59.2417H40.0619C34.0303 59.2417 29.1406 62.4284 29.1406 56.1328Z"
        fill="#4338CA"
      />
      <ellipse
        cx="36.0899"
        cy="29.4684"
        rx="4.16996"
        ry="5.55995"
        fill="#4338CA"
      />
      <ellipse
        cx="47.2105"
        cy="29.4684"
        rx="4.16996"
        ry="5.55995"
        fill="#4338CA"
      />
      <ellipse
        cx="58.3306"
        cy="40.588"
        rx="4.16996"
        ry="5.55995"
        fill="#4338CA"
      />
      <ellipse
        cx="27.7505"
        cy="40.588"
        rx="4.16996"
        ry="5.55995"
        fill="#4338CA"
      />
    </svg>
  );
}
export default PawBlue;
