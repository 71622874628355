import classNames from 'classnames';
import { Icon } from '../index';
import './ColumnTitle.scss';

function ColumnTitle({
  children,
  sortColumns,
  columnTitle,
  isSortable,
  className
}) {
  const sortedColumn = sortColumns?.find(
    ({ column }) => column.key === columnTitle
  );
  return (
    <div
      className={classNames('ch-table-column-title', {
        [className]: !!className
      })}
    >
      <span className="ch-table-column-title__children">{children}</span>
      <div className="ch-table-column-title__sort">
        {sortedColumn?.order ? (
          sortedColumn.order === 'ASC' ? (
            <Icon size={12} name="ChevronUp" />
          ) : (
            <Icon size={12} name="ChevronDown" />
          )
        ) : isSortable ? (
          <>
            <Icon size={12} name="ChevronUp" />
            <Icon size={12} name="ChevronDown" />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ColumnTitle;
