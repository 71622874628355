import { Outlet } from 'react-router-dom';
import { InitiativeContextProvider } from '../../../context/InitiativeContext';
import { Page } from '../../../stories/atoms';

export function InitiativeOutlet() {
  return (
    <Page>
      <InitiativeContextProvider>
        <Outlet />
      </InitiativeContextProvider>
    </Page>
  );
}
