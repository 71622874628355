import { useEffect } from 'react';
import { TermsSummary } from '../../features/Terms';
import { useModal } from '../../hooks';
import { Page } from '../../stories/atoms';
import './terms.scss';

export function Terms() {
  const { Modal, handleShowModal } = useModal({ width: '70%' });
  useEffect(() => {
    handleShowModal();
  }, []);
  return (
    <Page className="ch-terms">
      <Modal showHeader={false} maskClosable={false}>
        <TermsSummary />
      </Modal>
    </Page>
  );
}
