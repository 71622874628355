import { useLoadScript } from '@react-google-maps/api';
import { Col, Modal as AntModal, Row, Space } from 'antd';
import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import yourHome from '../../../assets/images/yourHome.png';
import yourOffice from '../../../assets/images/yourOffice.png';
import { Card, Popover } from '../../../components';
import { GoogleApiLibraries, TransportationType } from '../../../constants';
import { COLOURS_GREEN } from '../../../constants/colours';
import { DistanceUnitName } from '../../../constants/distanceUnit';
import {
  useEmployeeCommuteContext,
  useLayoutContext,
  useMeContext
} from '../../../context';
import { analytics, formatDecimal, getDistance } from '../../../helpers';
import { getDistanceUnit, getRegionalValue } from '../../../helpers/generators';
import { setVehicleIdKey } from '../../../helpers/setVehicleIdKey';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CommuteService, MetaService, VehicleService } from '../../../services';
import {
  Button,
  Drawer,
  Paragraph,
  Select,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import GoogleSelect from '../../../stories/atoms/GoogleSelect';
import { Footer } from '../../../stories/atoms/Layout';
import { Blurb, VehicleForm } from '../../../stories/molecules';
import './employeeDetailSteps.scss';
import { employeeDetailsValidation } from './employeeDetailsValidation';

const googlePlacesLibraries = GoogleApiLibraries;
function EmployeeDetailSteps() {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GCP_PLACES_API_KEY,
    libraries: googlePlacesLibraries
  });
  const params = useParams();
  const {
    employeeCommuteDetails: { activeCommute }
  } = useEmployeeCommuteContext();
  const { navStep } = useLayoutContext();
  const { me } = useMeContext();

  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};

  const { commuteId } = params;

  const [disableFields, setDisableFields] = useState(false);

  const [transportationTypeOptions, setTransportationTypeOptions] = useState(
    []
  );

  const [distanceUnits, setDistanceUnits] = useState([]);

  const [formValues, setFormValues] = useState({
    stops: []
  });

  const [isVehicleEnabled, setIsVehicleEnabled] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [commuteVehicleOptions, setCommuteVehicleOptions] = useState([]);

  const [showVehicleForm, setShowVehicleForm] = useState(false);

  const [baseVehicle, setBaseVehicle] = useState();
  const transportationTypeIds = useRef([]);

  const carTransportationTypeId = transportationTypeOptions.find(
    (type) => type.name === TransportationType.CAR
  )?.id;
  const motorbikeTransportationTypeId = transportationTypeOptions.find(
    (type) => type.name === TransportationType.MOTORBIKE
  )?.id;

  const drivingVehicleIds = [
    carTransportationTypeId,
    motorbikeTransportationTypeId
  ];
  const handlePrevious = () => {
    navStep(
      generatePath(
        AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_START_FINISH_EDIT,
        {
          commuteId
        }
      )
    );
  };

  const handleNext = () => {
    navStep(
      generatePath(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_DETAILS, {
        commuteId
      })
    );
  };
  const handleSubmit = (values) => {
    if (disableFields) {
      analytics.track('Step Completed', me, {
        level1: 'Office-Based Operations',
        level2: 'Employee Details',
        level3: 'Commute Steps',
        commuteDetails: activeCommute
      });
      handleNext();
      return;
    }
    const { stops } = values;

    const selectedVehicle = commuteVehicleOptions.find(
      (vehicle) => vehicle.id === values.vehicleId
    );
    const isCommuteVehicle = selectedVehicle?.existingCommuteVehicle;
    const vehicle = setVehicleIdKey(
      isCommuteVehicle,
      {},
      values.vehicleId,
      values.vehicleId
    );
    stops.splice(stops.length - 1, 1);
    const commuteItems = {
      items: stops.map((stop, i) => {
        delete stop.lat;
        delete stop.lng;
        return {
          ...stop,
          order: i + 1
        };
      }),
      ...vehicle
    };
    if (commuteId) {
      setFormLoading(true);
      CommuteService.createCommuteItems(
        commuteId,
        commuteItems,
        () => {
          analytics.track('Step Completed', me, {
            level1: 'Office-Based Operations',
            level2: 'Employee Details',
            level3: 'Commute Steps',
            commuteDetails: activeCommute
          });
          handleNext();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleSetDistances = async (formValues, i) => {
    if (formValues?.stops?.length > 0) {
      if (formValues.stops[i].lat && formValues.stops[i].lng) {
        if (
          formValues.stops[i]?.transportationTypeId &&
          formValues.stops[i + 1]?.lat &&
          formValues.stops[i + 1]?.lng
        ) {
          try {
            formValues.stops[i].distance = await handleDistance({
              originStop: formValues.stops[i],
              destStop: formValues.stops[i + 1]
            });
            formValues.stops[i].conversionUnitName = DistanceUnitName.KILOMETER;
          } catch (err) {
            // TODO: set an error state
            console.error('err', err);
          }
        }
        if (
          formValues.stops[i - 1]?.transportationTypeId &&
          formValues.stops[i - 1]?.lat &&
          formValues.stops[i - 1]?.lng
        ) {
          try {
            formValues.stops[i - 1].distance = await handleDistance({
              originStop: formValues.stops[i - 1],
              destStop: formValues.stops[i]
            });
            formValues.stops[i].conversionUnitName = DistanceUnitName.KILOMETER;
          } catch (err) {
            // TODO: set an error state
            console.error('err', err);
          }
        }
      }
      setFormValues({
        ...formValues
      });
    }
  };

  async function handleDistance({ originStop, destStop }) {
    const { google } = window;
    if (google) {
      const origin = new google.maps.LatLng(originStop.lat, originStop.lng);
      const destination = new google.maps.LatLng(destStop.lat, destStop.lng);
      const travelMode = transportationTypeOptions.find(
        (transportationType) =>
          transportationType.value === originStop.transportationTypeId
      ).googleType;
      const directionsService = new google.maps.DirectionsService();
      return await getDistance(
        directionsService,
        origin,
        destination,
        travelMode
      );
    }
  }

  const handleInitFormValues = async () => {
    const formValues = {
      isVehicleEnabled: false,
      vehicleId: null,
      stops: [
        {
          lat: commute?.homeLocation?.lat,
          lng: commute?.homeLocation?.lng,
          transportationTypeId:
            transportationTypeOptions.length > 0
              ? transportationTypeOptions[0].value
              : undefined
        },
        {
          lat: commute?.officeLocation?.lat,
          lng: commute?.officeLocation?.lng
        }
      ]
    };
    await handleSetDistances(formValues, 0);
  };

  const handleRedoCommuteSteps = () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      content:
        'Editing existing commutes will change historical data, do you wish to proceed?',
      onOk: async () => {
        analytics.track('Edit Item Clicked', me, {
          level1: 'Office-Based Operations',
          level2: 'Employee Details',
          level3: 'Commute Steps',
          commuteDetails: activeCommute
        });
        setDisableFields(false);
        setIsVehicleEnabled(false);
        CommuteService.clearAllCommuteItems(commuteId);
        await handleInitFormValues();
      }
    });
  };

  const isFormValid = (values) =>
    values.stops.filter(
      (stop, i) =>
        i === values.stops.length - 1 ||
        (stop.transportationTypeId && stop.distance >= 0)
    ).length === values.stops.length;

  const handleLoadCommute = useCallback(async () => {
    const commute = await CommuteService.getCommuteDetails(commuteId);
    let commuteItems = await CommuteService.fetchCommuteItems(commuteId);
    commuteItems = commuteItems?.map((commuteItem) => {
      if (commuteItem?.transportationType?.id) {
        commuteItem.transportationTypeId = commuteItem.transportationType.id;
      }
      return commuteItem;
    });

    return { commuteItems, commute };
  }, [commuteId]);
  const {
    value: { commute, commuteItems }
  } = useAsync({
    asyncFunction: handleLoadCommute,
    defaultValue: {}
  });

  const handleLoadDropdownOptions = useCallback(async () => {
    await MetaService.fetchTransportationTypes(
      (transportationTypes) => {
        setTransportationTypeOptions(
          transportationTypes.map((transportationType) => ({
            ...transportationType,
            label: transportationType.friendlyName,
            value: transportationType.id
          }))
        );
      },
      () => {},
      () => {}
    );
    await MetaService.fetchDistanceUnits(
      { isoName: me.company?.headQuartersCountry.isoName },
      (distanceUnits) => {
        setDistanceUnits(distanceUnits);
      },
      () => {},
      () => {}
    );
  }, []);
  const { isLoading: areDropdownOptionsLoading } = useAsync({
    asyncFunction: handleLoadDropdownOptions
  });

  const handleInitForm = useCallback(async () => {
    if (
      transportationTypeOptions.length > 0 &&
      distanceUnits.length > 0 &&
      commute
    ) {
      if (commuteItems.length > 0) {
        const stops = commuteItems.map(({ distance, transportationType }) => ({
          distance,
          transportationTypeId: transportationType?.id
        }));
        stops.push({
          lat: commute?.officeLocation?.lat,
          lng: commute?.officeLocation?.lng
        });
        setDisableFields(true);

        setFormValues({
          stops: [...stops],
          vehicleId:
            commuteItems[0]?.details?.commuteVehicleId ||
            commuteItems[0]?.details?.vehicleAssetId,
          isVehicleEnabled: !!(
            commuteItems[0]?.details?.commuteVehicleId ||
            commuteItems[0]?.details?.vehicleAssetId
          )
        });
        if (
          !!(
            commuteItems[0]?.details?.commuteVehicleId ||
            commuteItems[0]?.details?.vehicleAssetId
          )
        ) {
          setIsVehicleEnabled(true);
        } else {
          setIsVehicleEnabled(false);
        }
      } else {
        await handleInitFormValues();
      }
    }
  }, [transportationTypeOptions, distanceUnits, commute, commuteItems]);
  useAsync({ asyncFunction: handleInitForm });

  const handleFetchCommuteItems = useCallback(async () => {
    try {
      await CommuteService.getCommuteDetails(commuteId);
      const commuteItemsResponse = await CommuteService.fetchCommuteItems(
        commuteId
      );
      if (!!commuteItemsResponse && !!isVehicleEnabled) {
        const fetchVehiclesResponse = await VehicleService.fetchVehicles(
          { companySlug: me?.company?.slug, loggedInUserId },
          { baseVehicleType: baseVehicle?.name }
        );

        if (!!fetchVehiclesResponse) {
          setCommuteVehicleOptions(
            fetchVehiclesResponse.map((vehicle) => ({
              ...vehicle,
              label: vehicle.friendlyName,
              value: vehicle.id
            }))
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isVehicleEnabled]);

  useAsync({
    asyncFunction: handleFetchCommuteItems
  });

  const handleAddNewVehicle = () => {
    analytics.track('Add new vehicle clicked', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Commute details',
      commuteDetails: activeCommute
    });
    setShowVehicleForm(true);
  };

  return (
    <div className="employee-detail-steps">
      <Title bottomSpacing={56} size="xl">
        Enter your commute steps
      </Title>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={employeeDetailsValidation}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Card className="employee-detail-steps__card">
              {disableFields && (
                <Button
                  className="float-right"
                  onClick={handleRedoCommuteSteps}
                >
                  Redo commute steps
                </Button>
              )}
              <div className="employee-detail-steps__source">
                <span>
                  <div>
                    <img src={yourHome} alt="Your Home" />
                  </div>
                  <div className="mt-2 text-light-black">
                    {commute?.homeLocation?.address1}
                  </div>
                </span>
              </div>
              <ul className="employee-detail-steps__ul">
                <i className="employee-detail-steps__arrow-up" />
                <i className="employee-detail-steps__arrow-down" />
                <FieldArray
                  name="stops"
                  render={(arrayHelpers) => (
                    <>
                      {values?.stops?.map(
                        (stop, fieldIndex) =>
                          fieldIndex !== values?.stops?.length - 1 && (
                            <React.Fragment key={fieldIndex}>
                              {fieldIndex !== 0 && (
                                <li className="employee-detail-steps__transfer-points display-flex align-center">
                                  <GoogleSelect
                                    loading={areDropdownOptionsLoading}
                                    className="m-0"
                                    placeholder="Search and select transfer point"
                                    disabled={disableFields}
                                    id={fieldIndex}
                                    onSelect={async (places) => {
                                      values.stops[fieldIndex].lat =
                                        places[0].geometry.location.lat();
                                      values.stops[fieldIndex].lng =
                                        places[0].geometry.location.lng();
                                      await handleSetDistances(
                                        values,
                                        fieldIndex
                                      );
                                    }}
                                  />
                                  <Popover content="We do not save transfer points for safety reasons. So you will have to redo this section if you wish to edit your commute" />
                                </li>
                              )}
                              <li className="employee-detail-steps__transportation-type">
                                <div className="employee-detail-steps__transportation-type-step">
                                  {fieldIndex + 1}
                                </div>
                                <div className="employee-detail-steps__transport-distance">
                                  {values.stops[fieldIndex].distance >= 0
                                    ? `${formatDecimal(
                                        getRegionalValue(
                                          values.stops[fieldIndex].distance,
                                          me.company?.headQuartersCountry
                                            ?.isoName
                                        ),
                                        0
                                      )} ${getDistanceUnit(
                                        me.company?.headQuartersCountry?.isoName
                                      )}`
                                    : ''}
                                </div>
                                <span className="ml-2">
                                  Transportation type
                                </span>
                                <span className="ml-4">
                                  <Select
                                    loading={areDropdownOptionsLoading}
                                    name={`stops[${fieldIndex}].transportationTypeId`}
                                    disabled={disableFields}
                                    value={
                                      values.stops[fieldIndex]
                                        .transportationTypeId
                                    }
                                    className="mb-0"
                                    placeholder="Select"
                                    onChange={async (value) => {
                                      const vehicle =
                                        transportationTypeOptions.find(
                                          (transportation) =>
                                            value === transportation.id
                                        );
                                      transportationTypeIds.current[
                                        fieldIndex
                                      ] = value;
                                      const drivingVehiclePresent =
                                        drivingVehicleIds.includes(value) ||
                                        transportationTypeIds.current.findIndex(
                                          (s) => drivingVehicleIds.includes(s)
                                        ) > -1;

                                      if (drivingVehiclePresent) {
                                        setIsVehicleEnabled(true);
                                        setFieldValue('isVehicleEnabled', true);
                                        setBaseVehicle(vehicle);
                                      } else {
                                        setIsVehicleEnabled(false);
                                        setFieldValue(
                                          'isVehicleEnabled',
                                          false
                                        );
                                      }
                                      values.stops[
                                        fieldIndex
                                      ].transportationTypeId = value;
                                      await handleSetDistances(
                                        values,
                                        fieldIndex
                                      );
                                    }}
                                    options={transportationTypeOptions}
                                  />
                                </span>
                                {fieldIndex !== 0 && (
                                  <span className="ml-4">
                                    <i
                                      className="icon-delete"
                                      onClick={async () => {
                                        values.stops.splice(fieldIndex, 1);
                                        transportationTypeIds.current.splice(
                                          fieldIndex,
                                          1
                                        );
                                        await handleSetDistances(
                                          values,
                                          fieldIndex
                                        );
                                        analytics.track(
                                          'Delete item clicked',
                                          me,
                                          {
                                            level1: 'Office-Based Operations',
                                            level2: 'Employee Details',
                                            level3: 'Commute Steps',
                                            commuteDetails: commute
                                          }
                                        );
                                      }}
                                    />
                                  </span>
                                )}
                              </li>
                            </React.Fragment>
                          )
                      )}
                      <li className="employee-detail-steps__add-stop">
                        <i className="icon-button-add" />
                        <Button
                          className="ml-1"
                          disabled={disableFields}
                          onClick={() => {
                            analytics.track('Add new Selected', me, {
                              level1: 'Office-Based Operations',
                              level2: 'Employee Details',
                              level3: 'Commute Steps',
                              commuteDetails: activeCommute
                            });
                            arrayHelpers.insert(values.stops.length - 1, {});
                          }}
                        >
                          Add Stop
                        </Button>
                      </li>
                    </>
                  )}
                />
              </ul>
              <div className="employee-detail-steps__destination">
                <span>
                  <div>
                    <img src={yourOffice} alt="Your Office" />
                  </div>
                  <div className="mt-2 text-light-black">
                    {commute?.officeLocation?.address1}
                  </div>
                </span>
              </div>
              {!!isVehicleEnabled && (
                <Row justify="center" className="vehicle-details mt-5">
                  <Col span={12}>
                    <Select
                      title="Select Vehicle"
                      name="vehicleId"
                      value={values?.vehicleId}
                      disabled={disableFields}
                      options={commuteVehicleOptions}
                      setFieldValue={setFieldValue}
                      placeholder="Select"
                      notFoundContent={
                        <div className="employee-commute-details--no-vehicles">
                          <Space direction="vertical" align="center">
                            <p>You don’t have any vehicles created</p>

                            <Button onClick={handleAddNewVehicle} link>
                              Add Vehicle
                            </Button>
                          </Space>
                        </div>
                      }
                    />
                  </Col>
                  <Col
                    span={12}
                    className="vehicle-details--add-new-button__container"
                  >
                    <Button
                      prefixIcon="Plus"
                      type={BUTTON_TYPES.SECONDARY}
                      onClick={handleAddNewVehicle}
                      disabled={disableFields}
                      className="vehicle-details--add-new-button"
                    >
                      Add new vehicle
                    </Button>
                  </Col>
                </Row>
              )}
              <div className="mt-6">
                <Blurb
                  titleProps={{
                    title: 'Adding the steps of your commute'
                  }}
                >
                  <Paragraph bottomSpacing={0} size="sm">
                    If you take multiple vehicle types in your commute (e.g you
                    drive to a train stop, and then take the train for the rest
                    of the trip you can add multiple stops)
                  </Paragraph>
                </Blurb>
              </div>
              <div className="mt-6">
                <Blurb
                  iconProps={{
                    name: 'ShieldCheck',
                    color: COLOURS_GREEN.GREEN_500,
                    size: 16
                  }}
                >
                  <Paragraph
                    bottomSpacing={0}
                    className="commute-steps-description"
                  >
                    We value your privacy, only you'll be able to see your home
                    address and commute steps.
                  </Paragraph>
                </Blurb>
              </div>
            </Card>
            <Drawer
              showHeader={false}
              width="33%"
              destroyOnClose
              visible={showVehicleForm}
              onClose={() => setShowVehicleForm(false)}
            >
              <VehicleForm
                baseVehicleType={baseVehicle?.name}
                onSuccess={(vehicle) => {
                  analytics.track('Save item clicked', me, {
                    level1: 'Office-Based Operations',
                    level2: 'Employee Details',
                    level3: 'Commute details',
                    level4: 'Add Vehicle',
                    commuteDetails: activeCommute
                  });
                  commuteVehicleOptions.push({
                    ...vehicle,
                    existingCommuteVehicle: true,
                    label: vehicle.friendlyName,
                    value: vehicle.id
                  });
                  setCommuteVehicleOptions([...commuteVehicleOptions]);
                  setFieldValue('vehicleId', vehicle.id);
                }}
                onClose={() => setShowVehicleForm(false)}
              />
            </Drawer>
            <Footer className="display-flex justify-end">
              <Space>
                <Button
                  type={BUTTON_TYPES.SECONDARY}
                  htmlType="button"
                  onClick={handlePrevious}
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  disabled={!isFormValid(values) || formLoading}
                  loading={formLoading}
                >
                  Next
                </Button>
              </Space>
            </Footer>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EmployeeDetailSteps;
