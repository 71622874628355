import { Button as AntButton } from 'antd';
import classNames from 'classnames';
import { Icon } from '../index';
import './Button.scss';
import { BUTTON_TYPES } from './Button.types';

function Button({
  smallOutline,
  disabled,
  children,
  type = BUTTON_TYPES.PRIMARY,
  shape,
  className,
  icon,
  prefixIcon,
  fullWidth,
  ...props
}) {
  //  todo remove -new when old button is deprecated
  return (
    <AntButton
      className={classNames('ch-button-new', {
        [className]: !!className,
        [type]: !!type,
        'small-outline': !!smallOutline,
        'full-width': fullWidth,
        disabled: !!disabled
      })}
      disabled={disabled}
      shape={shape || 'round'}
      {...props}
    >
      {prefixIcon && (
        <span className="ch-button-new__prefix-icon">
          <Icon size={18} name={prefixIcon} />
        </span>
      )}
      <span className="ch-button-new__children">{children}</span>
      {icon && <Icon className="ch-button-new__icon" size={18} name={icon} />}
    </AntButton>
  );
}
export default Button;
