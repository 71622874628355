import { useCallback } from 'react';
import { useMeContext } from '../../../context';
import { getDropdownOptions } from '../../../helpers';
import { getDistanceUnit } from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { MetaService } from '../../../services';
import { Select } from '../../atoms';

function UnitSelect({ unitFamilyName, ...props }) {
  const { me } = useMeContext();
  const isoName = me.company?.headQuartersCountry.isoName;
  const handleLoadDropdown = useCallback(async () => {
    let resp = await MetaService.fetchUnits(unitFamilyName);
    resp = resp.sort((unitA, unitB) => {
      if (!!isoName) {
        const defaultUnit = getDistanceUnit(isoName).toUpperCase();
        return unitA.unit === defaultUnit
          ? -1
          : unitB.unit === defaultUnit
          ? 1
          : unitA.unit - unitB.unit;
      }
      return unitA.unit - unitB.unit;
    });
    return getDropdownOptions(resp, 'friendlyName', 'name');
  }, [unitFamilyName]);

  const { value: unitList, isLoading: isLoadingUnitList } = useAsync({
    asyncFunction: handleLoadDropdown
  });

  return (
    <Select loading={isLoadingUnitList} {...props} options={unitList}>
      {!!props.children && props.children}
    </Select>
  );
}
export default UnitSelect;
