import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Title from '../Title';
import './ModalHeader.scss';

function ModalHeader({
  title,
  handleOnCloseModal,
  className,
  closable = true
}) {
  return (
    <div className={`ch-modal__header ${className}`}>
      <Row>
        <Col className="text-bd text-left mb-4" flex="1">
          <Title bottomSpacing={0} size="large">
            {title}
          </Title>
        </Col>
        {closable && (
          <Col className="text-right">
            <Button
              className="ch-modal__header__close"
              type="link"
              shape="circle"
              onClick={handleOnCloseModal}
              icon={<CloseOutlined />}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ModalHeader;
