import { Col, Row } from 'antd';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification, Progress, Tag } from '../../components';
import { BulkUploadType, UserStatus } from '../../constants';
import { COLOURS_CARBONHOUND, COLOURS_GREEN } from '../../constants/colours';
import { useMeContext } from '../../context';
import {
  analytics,
  capitalizeText,
  getDropdownOptions,
  getFullName
} from '../../helpers';
import { useAsync, useModal, usePagination } from '../../hooks';
import { useDrawer } from '../../hooks/useDrawer';
import { AppRoutes } from '../../routes';
import {
  AuthService,
  CommuteService,
  EmployeeService,
  NotificationService
} from '../../services';
import {
  Button,
  ColumnTitle,
  Icon,
  Input,
  Paragraph,
  Select,
  Table,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { INPUT_TYPES } from '../../stories/atoms/Input/Input.types';
import {
  OnboardingStatus,
  USER_INVITE_STATUS_COLOURS,
  USER_ONBOARDING_STATUS_COLOURS
} from '../../stories/atoms/Tag/tag.types';
import TableTitle from '../../stories/molecules/TableTitle';
import BulkUploadForm from '../BulkUploadForm';
import InviteEmailPreviewButton from '../EmployeeDetail/InviteEmailPreview';
import { addNewUserValidation } from './addNewUserValidation';
import './employeeTable.scss';

function EmployeeOnboardingProgressBar() {
  const { me, getMyGroupSettings } = useMeContext();
  const { groupSettings } = me;
  useEffect(() => {
    if (!groupSettings) {
      getMyGroupSettings();
    }
  }, []);

  const handleGetCommuteAlert = useCallback(async () => {
    const { commuteCompletionPercent } =
      (await NotificationService.getCommuteAlert({
        companySlug: me?.company?.slug
      })) ?? 0;
    return commuteCompletionPercent;
  }, [me?.company?.slug]);

  const { value: completionPercent } = useAsync({
    asyncFunction: handleGetCommuteAlert,
    defaultValue: 0
  });

  const submitFunction = async () => {
    await CommuteService.sendInviteBulk({
      companySlug: me?.company?.slug,
      emailType: 'RESEND',
      groupSlug: groupSettings?.slug
    });
  };
  return (
    <Row
      wrap={false}
      className="employee-onboarding-progress-widget__container"
    >
      <Col flex="auto" className="employee-onboarding-progress-bar__wrapper">
        <Progress
          fillColor={COLOURS_GREEN.GREEN_400}
          secondary
          percent={completionPercent}
          status="normal"
          showInfo
          showBadge={false}
          title="Employee Commute Coverage"
        />
      </Col>
      <Col flex="none">
        <InviteEmailPreviewButton
          buttonProps={{
            text: 'Send Reminders',
            type: BUTTON_TYPES.LINK,
            onSubmit: submitFunction
          }}
        />
      </Col>
    </Row>
  );
}

function EmployeeSettings({
  employeeInfo,
  refreshEmployeeList,
  handleCloseDrawer
}) {
  const { me, updateLoggedInAs } = useMeContext();
  const {
    company: { roles },
    groupSettings
  } = me;
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext();

  const userRoleOptions = getDropdownOptions(roles, 'name', 'id');
  const { user, status } = employeeInfo;
  const { firstName, lastName, email } = user;
  const fullName = getFullName(user);
  const isCurrentAuthenticatedUser = me.id === employeeInfo.user?.id;

  const handleDeactivateUser = async () => {
    await EmployeeService.deleteEmployee(
      { companySlug: me?.company?.slug, id: employeeInfo.userId },
      () => {
        Notification({
          type: 'success',
          message: 'Remove User',
          description: `${fullName} has been removed from the company.`
        });
      }
    );
    refreshEmployeeList();
    handleCloseDrawer();
  };

  const handleResetPassword = async () => {
    await AuthService.sendResetPasswordEmail({
      groupSlug: groupSettings?.slug,
      email
    });
    Notification({
      type: 'success',
      message: 'Reset Password',
      description: `Password reset email sent for ${
        firstName?.length > 0 ? `${fullName}` : email
      }.`
    });
  };
  const handleManageDetails = async () => {
    updateLoggedInAs({ loggedInAs: employeeInfo });
    navigate(AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW);
  };

  const submitFunction = async () => {
    const roleId = employeeInfo.role.id;
    await EmployeeService.addEmployeeToCompany(me?.company?.slug, {
      email,
      roleId,
      emailType: 'RESEND',
      groupSlug: groupSettings?.slug
    });
  };
  return (
    <Row className="employee-settings__container">
      <Col span={24}>
        <Row className="employee-settings-header__wrapper">
          <Col>
            <div className="employee-settings-name__wrapper">
              {(!!firstName || firstName?.length > 0) && (
                <Title className="first-name" size="md" bottomSpacing={0}>
                  {firstName}
                </Title>
              )}
              {(!!lastName || lastName?.length > 0) && (
                <Title size="md" bottomSpacing={0}>
                  {lastName}
                </Title>
              )}
            </div>
            <Paragraph>{email}</Paragraph>
          </Col>
          <Col>
            <Paragraph>
              <Tag color={USER_INVITE_STATUS_COLOURS[status]}>
                {capitalizeText(status)}
              </Tag>
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row className="user-role-selector__wrapper">
          <Col span={24}>
            <Select
              name="roleId"
              title="Role"
              value={values.roleId}
              setFieldValue={setFieldValue}
              options={userRoleOptions}
              placeholder="Select"
              disabled={status === UserStatus.DEACTIVATED}
            />
          </Col>
        </Row>
      </Col>
      {status !== UserStatus.DEACTIVATED ? (
        <>
          <Col span={24}>
            <Row className="edit-blade-section mt-6">
              <Col>
                <Title size="sm" fontWeight={500} bottomSpacing={0}>
                  Deactivate account
                </Title>
                <Paragraph size="sm">
                  Remove this user from your company
                </Paragraph>
              </Col>
              <Col>
                <Button
                  type={BUTTON_TYPES.LINK}
                  className="mb-4"
                  onClick={handleDeactivateUser}
                >
                  Deactivate
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="edit-blade-section mt-4">
              <Col>
                <Title size="sm" fontWeight={500} bottomSpacing={0}>
                  Send reminder
                </Title>
                <Paragraph size="sm">
                  Send a reminder for this user to enter their details
                </Paragraph>
              </Col>
              <Col>
                <InviteEmailPreviewButton
                  buttonProps={{
                    text: 'Remind',
                    type: BUTTON_TYPES.LINK,
                    onSubmit: submitFunction
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="edit-blade-section mt-4">
              <Col>
                <Title size="sm" fontWeight={500} bottomSpacing={0}>
                  Reset password
                </Title>
                <Paragraph size="sm">
                  Trigger a password reset email to this user
                </Paragraph>
              </Col>
              <Col>
                <Button
                  type={BUTTON_TYPES.LINK}
                  className="mb-4"
                  onClick={handleResetPassword}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="edit-blade-section mt-4">
              <Col>
                <Title size="sm" fontWeight={500} bottomSpacing={0}>
                  Manage Details
                </Title>
                <Paragraph size="sm">
                  Modify users commute, home office, and leave
                </Paragraph>
              </Col>
              <Col>
                <Button
                  type={BUTTON_TYPES.LINK}
                  className="mb-4"
                  disabled={isCurrentAuthenticatedUser}
                  onClick={handleManageDetails}
                >
                  Manage
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      ) : null}
    </Row>
  );
}

function EmployeeTable() {
  const { me } = useMeContext();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [currentEmployeeInfo, setCurrentEmployeeInfo] = useState({});

  const {
    company: { roles },
    groupSettings
  } = me;

  const userRoleOptions = getDropdownOptions(roles, 'name', 'id');

  const { paginationParams, handleSetPaginationParams } = usePagination({
    pageSize: 30,
    paginationArray: employeeList,
    orderBy: ['createdAt', 'id'],
    order: 'DESC'
  });

  const {
    Modal: AddNewUserModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%'
  });
  const {
    Drawer: EditEmployeeDrawer,
    handleShowDrawer,
    handleCloseDrawer
  } = useDrawer({
    width: '40%'
  });

  const fetchEmployees = useCallback(async () => {
    const response = await EmployeeService.fetchEmployees({
      companySlug: me?.company?.slug,
      status: '',
      ...paginationParams
    });
    setEmployeeList(response.list);
    setEmployeeCount(response.count);
    return response;
  }, [
    paginationParams.pageNumber,
    paginationParams.orderBy,
    paginationParams.search?.queryString
  ]);

  const { execute: refreshEmployeeList, isLoading } = useAsync({
    asyncFunction: fetchEmployees
  });

  const {
    Modal: BUModal,
    handleShowModal: handleShowBUModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Upload data clicked', me, {
        level1: 'Office-Based Operations',
        level2: 'Employee Details',
        level3: 'Add and Invite Employees'
      });
    },
    onCloseModal: refreshEmployeeList,
    width: '90%'
  });

  const handOpenEmployeeDrawer = (col) => {
    setCurrentEmployeeInfo(col);
    handleShowDrawer();
  };

  const handleSearch = (query) => {
    handleSetPaginationParams({
      ...paginationParams,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['email']
          }
        : undefined
    });
  };

  const searchProps = {
    type: INPUT_TYPES.SEARCH,
    isFormikField: false,
    enterButton: true,
    placeholder: 'Search by email',
    onSearch: handleSearch,
    allowClear: true
  };

  const columns = [
    {
      key: 'firstName',
      className: 'cursor-pointer',
      title: <ColumnTitle>First Name</ColumnTitle>,
      render: (_, col) => (
        <Title bottomSpacing={0}>{col?.user?.firstName ?? '--'}</Title>
      ),
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      key: 'lastName',
      className: 'cursor-pointer',
      title: <ColumnTitle>Last Name</ColumnTitle>,
      render: (_, col) => (
        <Title bottomSpacing={0}>{col?.user?.lastName ?? '--'}</Title>
      ),
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      key: 'email',
      dataIndex: 'email',
      className: 'cursor-pointer',
      title: <ColumnTitle>Email</ColumnTitle>,
      render: (_, col) => `${col.user.email}`,
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      key: 'role',
      className: 'cursor-pointer',
      title: <ColumnTitle>Role</ColumnTitle>,
      render: (_, col) => `${col.role.name}`,
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      key: 'accountStatus',
      className: 'cursor-pointer',
      title: <ColumnTitle>Account Status</ColumnTitle>,
      render: (_, { status }) => (
        <Tag color={USER_INVITE_STATUS_COLOURS[status]}>
          {capitalizeText(status)}
        </Tag>
      ),
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      key: 'onboardingStatus',
      className: 'cursor-pointer',
      title: <ColumnTitle>Onboarding Status</ColumnTitle>,
      render: (_, col) => {
        let status;
        if (col.commuteStatus?.isComplete) {
          status = OnboardingStatus.COMPLETED;
        } else if (col.commuteStatus?.isStarted) {
          status = OnboardingStatus.IN_PROGRESS;
        } else if (col.commuteStatus?.isInvited) {
          status = OnboardingStatus.INVITED;
        } else {
          status = OnboardingStatus.NOT_INVITED;
        }

        return (
          <Tag color={USER_ONBOARDING_STATUS_COLOURS[status]}>
            {capitalizeText(status)}
          </Tag>
        );
      },
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    },
    {
      render: () => (
        <Icon
          color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
          size={24}
          name="ChevronRight"
        />
      ),
      onCell: (col) => ({
        onClick: () => handOpenEmployeeDrawer(col)
      })
    }
  ];

  const initialValues = {
    email: null,
    roleId: null
  };

  const handleSubmit = async (values) => {
    const { email, roleId } = values;
    await EmployeeService.addEmployeeToCompany(me?.company?.slug, {
      email,
      roleId,
      groupSlug: groupSettings?.slug
    });
    Notification({
      type: 'success',
      message: 'Add User',
      description: `An email invitation has been sent to ${email}.`
    });
    refreshEmployeeList();
    handleCloseModal();
  };

  const updateUserRole = async (values) => {
    await EmployeeService.updateEmployee({
      companySlug: me?.company?.slug,
      employeeId: currentEmployeeInfo.userId,
      role: values.roleId
    });
    fetchEmployees();
    handleCloseDrawer();
  };

  return (
    <Row className="employee-table__container">
      <Col span={24}>
        <Row>
          <Col span={24}>
            <TableTitle
              searchProps={searchProps}
              widget={EmployeeOnboardingProgressBar}
              buttons={[
                {
                  children: 'Import Users',
                  type: BUTTON_TYPES.SECONDARY,
                  onClick: handleShowBUModal
                },
                {
                  children: 'Add User',
                  type: BUTTON_TYPES.PRIMARY,
                  onClick: handleShowModal
                }
              ]}
            />
            <Table
              columns={columns}
              total={employeeCount}
              dataSource={employeeList}
              showPagination
              pagination={paginationParams}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Col>
      <AddNewUserModal title="Add new user">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={addNewUserValidation}
        >
          {({ setFieldValue }) => (
            <Form>
              <Row>
                <Col span={24} className="mb-6">
                  <Input
                    type="text"
                    name="email"
                    title="Email"
                    placeholder="Email"
                  />
                </Col>
                <Col span={24}>
                  <Select
                    name="roleId"
                    title="Role"
                    setFieldValue={setFieldValue}
                    options={userRoleOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col span={24} className="mt-6 display-flex justify-end">
                  <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit">
                    Invite User
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </AddNewUserModal>
      <EditEmployeeDrawer
        buttons={[
          {
            disabled: currentEmployeeInfo.status === UserStatus.DEACTIVATED,
            htmlType: 'submit',
            children: 'Save'
          }
        ]}
        formikContext={{
          onSubmit: updateUserRole,
          initialValues: {
            roleId: currentEmployeeInfo?.role?.id
          }
        }}
      >
        <EmployeeSettings
          employeeInfo={currentEmployeeInfo}
          refreshEmployeeList={refreshEmployeeList}
          handleCloseDrawer={handleCloseDrawer}
        />
      </EditEmployeeDrawer>
      <BUModal title="Upload your file" className="bulk-upload-modal">
        <BulkUploadForm
          notificationReqParams={{
            type: BulkUploadType.USER
          }}
          notification={{
            details: {
              uploadType: BulkUploadType.USER
            }
          }}
          handleCloseBulkUploadsModal={handleCloseBUModal}
          onSuccess={handleCloseBUModal}
        />
      </BUModal>
    </Row>
  );
}

export default EmployeeTable;
