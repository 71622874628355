import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Paragraph, Title } from '../../stories/atoms';
import './attachmentInput.scss';

function AttachmentInput({
  title,
  handleOnDropSuccess,
  accept,
  handleOnDropError,
  setFieldValue
}) {
  const onDropAccepted = useCallback(
    (droppedFile) => {
      handleOnDropSuccess?.(droppedFile);
      setFieldValue?.('files', droppedFile);
    },
    [handleOnDropSuccess]
  );
  const onDropRejected = useCallback(() => {
    handleOnDropError?.();
  }, [handleOnDropSuccess]);
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxFiles: 1
  });

  return (
    <Row {...getRootProps()} className="attachment-input">
      <Col span={24} className="text-center display-flex justify-center">
        <input {...getInputProps()} />
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Title size="md">{title}</Title>
            <Paragraph>Drag and drop or upload the completed file</Paragraph>
          </Col>
          <Col span={24} className="justify-center display-flex">
            <Button small primary>
              Upload file
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AttachmentInput;
