import Tada from '../../../../../stories/assets/svgs/Tada';
import { Title } from '../../../../../stories/atoms';
import './Complete.scss';

function Complete() {
  return (
    <div className="emission-source-detail-form-complete text-center">
      <div>
        <Tada />
      </div>
      <Title align="center" className="mt-7 mb-2" size="xl">
        Emission Source Created
      </Title>
      <p className="mb-7">You can edit this any time</p>
    </div>
  );
}
export default Complete;
