import { Menu } from 'antd';
import classNames from 'classnames';
import './SubMenu.scss';

const { SubMenu: AntSubMenu } = Menu;
export function SubMenu({ children, className, ...props }) {
  return (
    <AntSubMenu
      className={classNames('ch-submenu', { [className]: !!className })}
      {...props}
    >
      {children}
    </AntSubMenu>
  );
}
