import { Layout } from 'antd';
import classNames from 'classnames';
import './Sider.scss';

const { Sider: AntSider } = Layout;

export function Sider({ children, className, ...props }) {
  return (
    <AntSider
      className={classNames('ch-layout-sider', {
        [className]: !!className
      })}
      {...props}
    >
      {children}
    </AntSider>
  );
}
