import { notification } from 'antd';
import './notification.scss';

function Notification({ message, description, type, ...props }) {
  notification.config({
    maxCount: 3
  });
  return notification[type ?? 'success']({
    message,
    description,
    className: 'ch-notification',
    duration: 2,
    ...props
  });
}

export default Notification;
