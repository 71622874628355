const FuelType = {
  COMPRESSED_NATURAL_GAS: 'Compressed natural gas',
  DIESEL_GAS: 'Diesel gas',
  FULL_ELECTRIC: 'Full electric',
  HYBRID: 'Hybrid',
  HYBRID_PLUG_IN: 'Hybrid plug-in',
  LIQUEFIED_PETROLEUM_GAS: 'Liquefied petroleum gas',
  UNKNOWN: 'Unknown',
  UNLEADED_GAS: 'Unleaded gas'
};

export default FuelType;
