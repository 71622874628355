import { Notification } from '../components';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class CommuteService {
  static async fetchEmployeeCommutes(
    { companySlug, loggedInUserId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE_LIST.replace(
        ':companySlug',
        companySlug
      )}${loggedInUserId ? '' : '/me'}`;

      const response = await axiosInstance.get(API_URL, {
        params: {
          ...(loggedInUserId && { userId: loggedInUserId })
        }
      });
      onSuccess?.(response?.data?.commutes);
      return response?.data?.commutes;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmployees(
    { companySlug, isInvited, isStarted, isComplete, pagination },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.COMMUTE_EMPLOYEES.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(
        API_URL,
        {
          pagination
        },
        {
          params: {
            isInvited,
            isStarted,
            isComplete
          }
        }
      );

      const responseObj = {
        list: response.data?.employees ?? [],
        count: response.data.totalRowCount ?? []
      };
      onSuccess?.(responseObj);
      return responseObj;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createInvite(
    companySlug,
    employee,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.COMMUTE_CREATE_INVITE.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = await axiosInstance.post(API_URL, employee);
      onSuccess?.(response.data?.userId);
      return response.data?.userId;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async sendInvite(
    { companySlug, groupSlug, employee },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.COMMUTE_SEND_INVITE.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(API_URL, {
        groupSlug,
        ...employee
      });

      onSuccess?.();
      return response?.status;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async sendInviteBulk(
    { companySlug, groupSlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = ApiRoutes.COMMUTE_SEND_INVITE_BULK.replace(
        ':companySlug',
        companySlug
      );
      const response = await axiosInstance.post(API_URL, {
        groupSlug,
        ...params
      });

      onSuccess?.();
      return response?.status;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async cancelInvite(
    companySlug,
    employee,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.COMMUTE_CANCEL_INVITE.replace(
      ':companySlug',
      companySlug
    );

    try {
      const response = await axiosInstance.post(API_URL, employee);

      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createCommute(
    { companySlug, commute, loggedInUserId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE_DETAIL.replace(
        ':companySlug',
        companySlug
      )}${loggedInUserId ? '' : '/me'}`;
      const response = await axiosInstance.post(API_URL, {
        ...commute,
        ...(loggedInUserId && { userId: loggedInUserId })
      });

      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async getCommuteDetails(commuteId, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE}/${commuteId}`;
      const response = await axiosInstance.get(API_URL);

      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchCommuteItems(commuteId, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE_ITEM.replace(
        ':commuteId',
        commuteId
      )}/list`;
      const response = await axiosInstance.get(API_URL);
      onSuccess?.(response.data.commuteItems);
      return response.data.commuteItems;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateCommute(
    { _companySlug, commute, _loggedInUserId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      // Changing a commute from fixed dates to current needs to clear the endDate
      if (commute.isCurrentCommute) {
        commute.endDate = null;
      }
      const API_URL = `${ApiRoutes.COMMUTE}/${commute.id}`;
      const response = await axiosInstance.put(API_URL, commute);

      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async clearAllCommuteItems(commuteId) {
    const API_URL = ApiRoutes.CLEAR_COMMUTE_ITEMS.replace(
      ':commuteId',
      commuteId
    );
    await axiosInstance.delete(API_URL);
  }

  static async deleteCommute(commute, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE}/${commute.id}`;
      await axiosInstance.delete(API_URL);

      onSuccess?.();
      Notification({
        type: 'success',
        message: 'Employee Details',
        description: 'Commute deleted.'
      });
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static createCommuteItems(
    commuteId,
    commuteItems,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = `${ApiRoutes.COMMUTE_ITEM.replace(
      ':commuteId',
      commuteId
    )}/bulk`;
    axiosInstance
      .post(API_URL, commuteItems)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async fetchCommuteSummaryTimeline(
    { companySlug, loggedInUserId },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMMUTE_SUMMARY_TIMELINE.replace(
        ':companySlug',
        companySlug
      )}${loggedInUserId ? '' : '/me'}`;

      const response = await axiosInstance.get(API_URL, {
        params: {
          ...(loggedInUserId && { userId: loggedInUserId })
        }
      });
      onSuccess?.(response?.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }
}

export default CommuteService;
