import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMeContext } from '../../../../../context';
import { useInitiativeContext } from '../../../../../context/InitiativeContext';
import { useModal } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import { analytics } from '../../../../../helpers';
import { Button, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { CompanyInitiativeForm } from '../../CompanyInitiativeForm';
import './InitiativeTemplateCompanyInitiativeListEmptyState.scss';

export function InitiativeTemplateCompanyInitiativeListEmptyState({
  analyticsContext
}) {
  const { handleLoadInitiativeList, initiativeState } = useInitiativeContext();
  const { initiativeTemplateId } = initiativeState;
  const navigate = useNavigate();
  const { me } = useMeContext();
  const {
    Modal: CreateInitiativeModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        ...analyticsContext,
        initiativeTemplateId
      });
    },
    onCloseModal: () => {
      handleLoadInitiativeList({
        initiativeTemplateId,
        pagination: {
          pageSize: 5,
          order: 'ASC',
          orderBy: ['name']
        }
      });
    }
  });
  const handleCreateModalSuccess = ({ id }) => {
    navigate(AppRoutes.COMPANY_INITIATIVE.replace(':companyInitiativeId', id));
  };
  return (
    <Row
      className="initiative-template-company-initiative-list-empty-state"
      gutter={[0, 16]}
    >
      <Col span={24} className="display-flex justify-center">
        <Title size="sm" bottomSpacing={0}>
          You haven’t started any initiatives with this template yet
        </Title>
      </Col>
      <Col span={24} className="display-flex justify-center">
        <Button
          onClick={handleShowModal}
          icon="ArrowRight"
          type={BUTTON_TYPES.LINK}
        >
          Get started with this template
        </Button>
      </Col>
      <CreateInitiativeModal
        title="Create Initiative using template"
        onClose={handleCloseModal}
      >
        <CompanyInitiativeForm
          initiativeTemplateId={initiativeTemplateId}
          onSuccess={handleCreateModalSuccess}
          analyticsContext={analyticsContext}
        />
      </CreateInitiativeModal>
    </Row>
  );
}
