import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import NotificationStatus from '../../../../constants/notificationStatus';
import { capitalizeText } from '../../../../helpers';
import { getActionCategoryCopy } from '../../../../helpers/generators';
import { Paragraph, Tag, Title } from '../../../../stories/atoms';
import { TAG_COLOURS } from '../../../../stories/atoms/Tag/tag.types';
import OwnerSelect from '../../../../stories/molecules/OwnerSelect';
import './ActionsModalSidebar.scss';

export function ActionsModalSidebar({ action, editNotification }) {
  const { title, status, dueDate, ownerId, category } = action;
  const categoryCopy = getActionCategoryCopy(category);
  const [selectedOwnerId, setSelectedOwnerId] = useState(ownerId);
  const isComplete = status === NotificationStatus.COMPLETE;
  return (
    <Row className="actions-modal-sidebar">
      <Col span={24} className="actions-modal-sidebar__bordered-row">
        <Title flex={1} bottomSpacing={8} size="sm">
          Title
        </Title>
        <Title bottomSpacing={0} size="rg">
          {title}
        </Title>
      </Col>
      <Col span={24} className="actions-modal-sidebar__bordered-row">
        <Row gutter={[16, 12]} align="middle">
          <Col flex={1}>
            <Title bottomSpacing={0} size="sm">
              Status
            </Title>
          </Col>
          <Col className="display-flex justify-end">
            <Tag
              color={
                isComplete
                  ? TAG_COLOURS.GRAY
                  : status === NotificationStatus.COMPLETE
                  ? TAG_COLOURS.GREEN
                  : TAG_COLOURS.YELLOW
              }
            >
              {status ? capitalizeText(status) : 'Inactive'}
            </Tag>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="actions-modal-sidebar__bordered-row">
        <Row gutter={[16, 12]} align="middle">
          <Col span={24}>
            <Title bottomSpacing={0} size="sm">
              Assignee
            </Title>
          </Col>
          <Col span={24}>
            <OwnerSelect
              disabled={isComplete}
              value={selectedOwnerId}
              disableForm
              name="ownerId"
              placeholder="Enter"
              onChange={(value) => {
                setSelectedOwnerId(value);
                editNotification(value, 'ownerId');
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="actions-modal-sidebar__bordered-row">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <Title bottomSpacing={0} size="sm">
              Due Date
            </Title>
          </Col>
          <Col span={24}>
            <DatePicker
              disabled={isComplete}
              defaultValue={dueDate ? moment.utc(dueDate) : undefined}
              name="dueDate"
              title="Due date"
              onChange={(value) => {
                editNotification(value, 'dueDate');
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="actions-modal-sidebar__bordered-row">
        <Row gutter={[16, 12]}>
          <Col span={12}>
            <Title bottomSpacing={0} size="sm">
              Type
            </Title>
          </Col>
          <Col span={12} className="display-flex justify-end">
            <Paragraph weight={600} size="sm">
              {categoryCopy}
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
