import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card } from '../../../components';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { rerouteHelper } from '../../../helpers/rerouteHelper';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { AuthService, MeService } from '../../../services';
import poweredByCh from '../../../stories/assets/svgs/poweredByCh.svg';
import { Button, Img, Input, Label, Logo, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './login.scss';
import { loginFormValidation } from './loginFormValidation';

function Login() {
  const {
    me,
    setJWTToken,
    getMyDetails,
    isServiceUser,
    isGroupSettingsMember
  } = useMeContext();

  const navigate = useNavigate();

  const location = useLocation();

  const origin = new URLSearchParams(location.search).get('origin');

  const handleSubmit = async (user) => {
    try {
      const loginResponse = await AuthService.login(user);
      const { token, companies } = loginResponse;
      if (companies?.length === 1) {
        await MeService.changeActiveCompany(
          companies[0].slug,
          ({ token }) => {
            setJWTToken(token);
          },
          () => {},
          () => {}
        );
      } else {
        setJWTToken(token);
      }

      const authedUser = await getMyDetails();

      if (isServiceUser(authedUser)) {
        navigate(AppRoutes.CHC_ADMIN);
      } else if (companies?.length > 1) {
        navigate(AppRoutes.SELECT_COMPANY);
      } else if ((companies ?? []).length <= 0) {
        navigate(AppRoutes.COMPANY_INFORMATION);
      } else {
        const rerouteUrl = rerouteHelper(authedUser);
        if (!!rerouteUrl) {
          navigate(rerouteUrl);
        } else {
          navigate(
            origin === 'commute'
              ? AppRoutes.EMPLOYEE_COMMUTE
              : AppRoutes.DASHBOARD
          );
        }
      }
    } catch (err) {}
  };

  const { execute, isLoading } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });

  useEffect(() => {
    if (me.token) {
      navigate(
        origin === 'commute' ? AppRoutes.EMPLOYEE_COMMUTE : AppRoutes.DASHBOARD
      );
    }
  }, []);

  return (
    <div
      className={classNames('login__page-container', {
        'login__page-container--group-settings-member': isGroupSettingsMember
      })}
    >
      <Row className="login__form-wrapper">
        <Col span={24}>
          <Row className="justify-center" gutter={[0, 40]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="display-flex justify-center">
                <Card className="login__form-card">
                  <Formik
                    initialValues={{
                      email: '',
                      password: ''
                    }}
                    validationSchema={loginFormValidation}
                    onSubmit={execute}
                  >
                    <Form>
                      <Row>
                        <Col span={24}>
                          <Logo className="login__logo mb-8" />
                          <Title
                            fontSize={24}
                            fontWeight={600}
                            align="center"
                            color={COLOURS_GRAY.GRAY_800}
                            className="mb-2"
                          >
                            Welcome back
                          </Title>
                          <Title
                            fontSize={16}
                            fontWeight={400}
                            align="center"
                            color={COLOURS_GRAY.GRAY_800}
                          >
                            Please enter your details
                          </Title>
                          <div className="mb-5">
                            <Label>Email</Label>
                            <Input
                              type="text"
                              name="email"
                              placeholder="Email"
                            />
                          </div>
                          <div className="mb-0">
                            <Label>Password</Label>
                            <Input
                              type="password"
                              name="password"
                              placeholder="Password"
                            />
                          </div>
                          <div>
                            <Link to={AppRoutes.FORGOT_PASSWORD}>
                              <Button
                                type={BUTTON_TYPES.TEXT}
                                className="forgot__password-button p-0 mt-0"
                              >
                                Forgot Password
                              </Button>
                            </Link>
                          </div>
                          <div className="mt-5">
                            <Button
                              fullWidth
                              htmlType="submit"
                              loading={isLoading}
                            >
                              Login
                            </Button>
                          </div>
                          <div className="justify-center display-flex align-center mt-5 pr-2">
                            Don't have an account?
                            <Link to={AppRoutes.CREATE_EMAIL}>
                              <Button
                                type={BUTTON_TYPES.TEXT}
                                className="p-0 ml-2"
                              >
                                Sign up
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Formik>
                </Card>
              </div>
            </Col>
            {isGroupSettingsMember && (
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Img src={poweredByCh} alt="Powered by Carbonhound" />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
