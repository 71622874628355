import {
  createModelSchema,
  date,
  list,
  object,
  optional,
  primitive
} from 'serializr';

class RowErrors {}
createModelSchema(RowErrors, {
  message: primitive(),
  rowNumber: primitive()
});
class NotificationDetails {}

createModelSchema(NotificationDetails, {
  uploadType: optional(primitive()),
  errorFileName: optional(primitive()),
  errorRowCount: optional(primitive()),
  parseProgress: optional(primitive()),
  totalRowCount: optional(primitive()),
  upsertProgress: optional(primitive()),
  successFileName: optional(primitive()),
  successRowCount: optional(primitive()),
  originalFileName: optional(primitive()),
  fileName: optional(primitive()),
  upsertSuccessRowCount: optional(primitive()),
  energyDetailsId: optional(primitive()),
  collectionId: optional(primitive()),
  rowErrors: optional(list(object(RowErrors)))
});
class Notification {}

createModelSchema(Notification, {
  id: primitive(),
  title: primitive(),
  message: primitive(),
  icon: primitive(),
  category: primitive(),
  status: primitive(),
  dueDate: date(),
  ownerId: primitive(),
  owner: primitive(),
  notes: primitive(),
  engagementPoints: primitive(),
  details: optional(object(NotificationDetails))
});

export default Notification;
