import { Col, Row, Space } from 'antd';
import React from 'react';
import { useMeContext } from '../../context';
import { useDataImportContext } from '../../context/DataImportContext';
import { BulkUploadForm } from '../../features';
import { DataImportList } from '../../features/DataImport/DataImportList';
import { analytics } from '../../helpers';
import { useModal } from '../../hooks';
import { Button, Page, Title } from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { TravelItemFormButton } from '../../stories/molecules';
import CarbonhoundConnectCta from '../../stories/molecules/CarbonhoundConnectCta';

export function DataImport() {
  const { me } = useMeContext();
  const { refreshList } = useDataImportContext();
  const {
    Modal: BulkUploadsModal,
    handleShowModal: handleShowBulkUploadsModal,
    handleCloseModal: handleCloseBulkUploadsModal
  } = useModal({
    onCloseModal: refreshList,
    width: '90%'
  });

  const handleTravelItemFormSuccess = (travelExpense) => {
    analytics.track('Save item clicked', me, {
      level1: 'Data Dashboard',
      level2: 'Add item modal',
      travelItem: travelExpense
    });
  };

  return (
    <Page className="data-import">
      <Row className="mb-9">
        <Col flex="1">
          <Title size="lg">Data Imports</Title>
        </Col>
        <Col flex="0 1 auto">
          <Space size={16}>
            <TravelItemFormButton
              onClose={refreshList}
              onSuccess={handleTravelItemFormSuccess}
              buttonProps={{
                type: BUTTON_TYPES.TEXT
              }}
            />
            <Button onClick={handleShowBulkUploadsModal}>New Import</Button>
          </Space>
        </Col>
      </Row>
      <Row className="mb-8">
        <Col span={24}>
          <CarbonhoundConnectCta />
        </Col>
      </Row>

      <Row className="mb-8">
        <Col span={24}>
          <DataImportList />
        </Col>
      </Row>
      <BulkUploadsModal className="bulk-upload-modal" title="Upload your file">
        <BulkUploadForm onSuccess={handleCloseBulkUploadsModal} />
      </BulkUploadsModal>
    </Page>
  );
}
