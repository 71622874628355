import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router';
import {
  COLOURS_BW,
  COLOURS_CARBONHOUND,
  COLOURS_GRAY
} from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { useCollectionContext } from '../../../context/CollectionContext';
import { analytics } from '../../../helpers';
import { convertConstantToString } from '../../../helpers/formatters';
import {
  getCollectionLocation,
  getEmissionSourceDetailsName,
  getFormattedAddress,
  getFullName
} from '../../../helpers/generators';
import { useModal } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CollectionService } from '../../../services';
import {
  ButtonIcon,
  ColumnTitle,
  Icon,
  Paragraph,
  Table,
  Title
} from '../../../stories/atoms';
import TableTitle from '../../../stories/molecules/TableTitle';
import EmissionSourceDetailForm from '../../EmissionSource/EmissionSourceDetail/EmissionSourceDetailForm';
import './collectionEmissionSourceTable.scss';

function CollectionEmissionSourcesTable({ type }) {
  const { me } = useMeContext();

  const { collectionState, fetchChart, fetchTable } = useCollectionContext();
  const { collectionDetails, collectionId } = { ...collectionState };

  const { emissionSources = [], name: collectionName } = {
    ...collectionDetails
  };

  const typeLabel = convertConstantToString(type);

  const {
    Modal,
    handleShowModal: handleShowCreateSourceDetailModal,
    handleCloseModal: handleCloseCreateSourceDetailModal
  } = useModal({
    width: '60%'
  });

  const navigate = useNavigate();

  const handleRemoveEmissionCollection = async (emissionSource) => {
    await CollectionService.deleteCollectionEmissionSource({
      collectionId,
      emissionSourceDetailsId: emissionSource.id
    });
    analytics.track('Delete item clicked', me, {
      level1: `${typeLabel.plural}`,
      level2: 'Details',
      level3: 'Sources Table',
      collectionId,
      emissionSourceDetails: emissionSource
    });
    fetchChart();
    fetchTable();
  };

  const handleRemoveEmissionCollectionModal = (item, itemIndex) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Remove Source',
      cancelText: 'Cancel',
      title: (
        <div className="display-flex align-center justify-space-between">
          <Title size="lg" bottomSpacing={0}>
            {`Remove source from ${typeLabel.singular}?`}
          </Title>
          <ButtonIcon
            name="Times"
            color={COLOURS_BW.BLACK}
            onClick={AntModal.destroyAll}
          />
        </div>
      ),
      content: (
        <Paragraph size="rg">
          This will only remove the source from the {typeLabel.singular}, not
          completely delete the source. To manage or delete your source you have
          to modify it on the source details.
        </Paragraph>
      ),
      onOk: () => handleRemoveEmissionCollection(item, itemIndex)
    });
  };

  const navigateToEmissionSource = (col) => {
    analytics.track('Item clicked', me, {
      level1: `${typeLabel.plural}`,
      level2: 'Details',
      level3: 'Sources Table',
      collectionId,
      emissionSourceDetails: col
    });
    navigate(
      AppRoutes.SOURCES_DETAILS.replace(':emissionSourceDetailsId', col.id),
      {
        state: {
          isCollection: true,
          type,
          collectionId,
          collectionName
        }
      }
    );
  };

  const handleCreateCollectionEmission = () => {
    analytics.track('Add new item', me, {
      level1: `${typeLabel.plural}`,
      level2: 'Details',
      level3: 'Sources Table',
      collectionId
    });
    handleShowCreateSourceDetailModal();
  };

  const columns = [
    {
      key: 'name',
      className: 'cursor-pointer',
      title: <ColumnTitle>Name</ColumnTitle>,
      render: (_, item) => {
        const emissionSourceDetailsName = getEmissionSourceDetailsName(item);
        return <Title bottomSpacing={0}>{emissionSourceDetailsName}</Title>;
      },
      onCell: (col) => ({
        onClick: () => navigateToEmissionSource(col)
      })
    },
    {
      key: 'description',
      className: 'cursor-pointer',
      title: <ColumnTitle>Description</ColumnTitle>,
      render: (_, item) => (
        <Paragraph
          bottomSpacing={0}
          className="business-unit-table-description-text"
        >
          {item.description}
        </Paragraph>
      ),
      onCell: (col) => ({
        onClick: () => navigateToEmissionSource(col)
      })
    },
    {
      key: 'location',
      className: 'cursor-pointer',
      title: <ColumnTitle>Location</ColumnTitle>,
      render: (_, item) => {
        const collection = getCollectionLocation(item);
        const location = collection?.location;
        const name = collection?.name;
        return getFormattedAddress({
          location,
          name,
          defaultReturnString: '--'
        });
      },
      onCell: (col) => ({
        onClick: () => navigateToEmissionSource(col)
      })
    },
    {
      key: 'created',
      className: 'cursor-pointer',
      title: <ColumnTitle>Created</ColumnTitle>,
      render: (_, item) => `${formatInTimeZone(
        new Date(item.createdAt),
        'UTC',
        'MMM dd, yyyy'
      )}
          by
          ${getFullName(item.createdBy)}`,
      onCell: (col) => ({
        onClick: () => navigateToEmissionSource(col)
      })
    },
    {
      width: '50px',
      onCell: () => ({
        className: 'justify-end'
      }),
      render: (_, item, itemIndex) => (
        <Space size={24}>
          <ButtonIcon
            onClick={() => handleRemoveEmissionCollectionModal(item, itemIndex)}
            name="MinusCircle"
            backgroundColour="transparent"
            color={COLOURS_GRAY.GRAY_400}
          />
          <Icon
            name="ChevronRight"
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
          />
        </Space>
      )
    }
  ];
  return (
    <div className="emission-source-item-table business-unit-emission-sources-table__container">
      <Row>
        <Col span={24}>
          <Table
            isHeaderBorder
            dataSource={emissionSources}
            columns={columns}
            title={() => (
              <TableTitle
                isHeaderBorder
                title="Sources"
                buttons={[
                  {
                    children: 'Add Source',
                    onClick: handleCreateCollectionEmission
                  }
                ]}
              />
            )}
          />
          <Modal footer={null} title="Create New Source">
            <EmissionSourceDetailForm
              onClose={handleCloseCreateSourceDetailModal}
              onSuccess={handleShowCreateSourceDetailModal}
              collectionProps={{
                [type]: collectionId
              }}
            />
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default CollectionEmissionSourcesTable;
