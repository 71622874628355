import { Slider as AntSlider } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './slider.scss';

function Slider({ className, ...props }) {
  return (
    <AntSlider
      className={classNames('ch-slider', {
        [className]: !!className
      })}
      {...props}
    />
  );
}

export default Slider;
