import { Col, Divider, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Card } from '../../../components';
import { useMeContext } from '../../../context';
import EmissionSourceLocationSummaryChart from '../../../features/EmissionSource/EmissionSourceLocation/EmissionSourceLocationDetailSummaryChart';
import EmissionSourceLocationSourceTable from '../../../features/EmissionSource/EmissionSourceLocation/EmissionSourceLocationSourcesTable';
import { analytics } from '../../../helpers';
import { getFormattedAddress } from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CollectionService } from '../../../services';
import {
  Breadcrumb,
  Button,
  Drawer,
  Page,
  PageHeader,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { LocationForm } from '../../../stories/molecules';
import './EmissionSourceLocationDetails.scss';

function EmissionSourcesLocationDetails() {
  const { me } = useMeContext();
  const { collectionId } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const getLocationDetails = useCallback(async () => {
    const location = await CollectionService.getCollection({
      collectionId
    });
    return location;
  }, [collectionId]);
  const { value: locationDetails, execute: refreshSource } = useAsync({
    asyncFunction: getLocationDetails
  });
  const analyticsContext = { collectionId };
  const routes = [
    {
      path: AppRoutes.SOURCES_OVERVIEW,
      breadcrumbName: 'Sources'
    },
    {
      breadcrumbName: `${getFormattedAddress({
        location: locationDetails?.location
      })}${
        !!locationDetails?.locationDetails?.moveOutDate ? ' (Moved Out)' : ''
      }`
    }
  ];
  const handleEditLocation = () => {
    analytics.track('Edit Item Clicked', me, {
      ...analyticsContext
    });
    setDrawerOpen(true);
  };
  const handleLocationFormSuccess = () => {
    setDrawerOpen(false);
    refreshSource();
  };
  return (
    <Page className="emission-source-location-details">
      <Breadcrumb routes={routes} />
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card small>
            <PageHeader
              title={getFormattedAddress({
                location: locationDetails?.location
              })}
              footer={
                <>
                  <Divider />
                  <Row gutter={[24, 4]} justify="space-between">
                    {!!locationDetails?.name && (
                      <Col span={6}>
                        <Space direction="vertical" size={0}>
                          <Title size="xs" weight={500} bottomSpacing={8}>
                            Nickname
                          </Title>
                          <Title
                            fontWeight={500}
                            showTooltip
                            className="emission-source-location-details__footer__item-detail"
                            size="md"
                            bottomSpacing={0}
                            wordBreak="break-word"
                          >
                            {locationDetails?.name}
                          </Title>
                        </Space>
                      </Col>
                    )}
                    <Col span={6}>
                      <Space direction="vertical" size={0}>
                        <Title size="xs" weight={500} bottomSpacing={8}>
                          Company Owned
                        </Title>
                        <Title
                          fontWeight={500}
                          showTooltip
                          className="emission-source-location-details__footer__item-detail"
                          size="md"
                          bottomSpacing={0}
                          wordBreak="break-word"
                        >
                          {locationDetails?.locationDetails?.isOwner
                            ? 'Yes'
                            : 'No'}
                        </Title>
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical" size={0}>
                        <Title size="xs" weight={500} bottomSpacing={8}>
                          Current Office
                        </Title>
                        <Title
                          fontWeight={500}
                          showTooltip
                          className="emission-source-location-details__footer__item-detail"
                          size="md"
                          bottomSpacing={0}
                          wordBreak="break-word"
                        >
                          {!!locationDetails?.locationDetails?.moveOutDate
                            ? 'No'
                            : 'Yes'}
                        </Title>
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical" size={0}>
                        <Title size="xs" weight={500} bottomSpacing={8}>
                          Move In Date
                        </Title>
                        <Title
                          fontWeight={500}
                          showTooltip
                          className="emission-source-location-details__footer__item-detail"
                          size="md"
                          bottomSpacing={0}
                          wordBreak="break-word"
                        >
                          {!!locationDetails?.locationDetails?.moveInDate ? (
                            <Title size="md" includeSpacing={false}>
                              {formatInTimeZone(
                                new Date(
                                  locationDetails?.locationDetails?.moveInDate
                                ),
                                'utc',
                                'MMM dd, yyyy'
                              )}
                            </Title>
                          ) : (
                            <Button
                              onClick={handleEditLocation}
                              type={BUTTON_TYPES.LINK}
                            >
                              Add move in date
                            </Button>
                          )}
                        </Title>
                      </Space>
                    </Col>

                    {!!locationDetails?.locationDetails?.moveOutDate && (
                      <Col span={6}>
                        <Space direction="vertical" size={0}>
                          <Title size="xs" weight={500} bottomSpacing={8}>
                            Move Out Date
                          </Title>
                          <Title
                            fontWeight={500}
                            showTooltip
                            className="emission-source-location-details__footer__item-detail"
                            size="md"
                            bottomSpacing={0}
                          >
                            {formatInTimeZone(
                              new Date(
                                locationDetails?.locationDetails?.moveOutDate
                              ),
                              'utc',
                              'MMM dd, yyyy'
                            )}
                          </Title>
                        </Space>
                      </Col>
                    )}
                  </Row>
                </>
              }
              extra={
                <div className="emission-source-location-details__page-header__extra">
                  <Space>
                    {!!locationDetails?.locationDetails?.moveOutDate && (
                      <div>
                        <Button disabled>Moved Out</Button>
                      </div>
                    )}
                    <div>
                      <Button onClick={handleEditLocation}>Edit</Button>
                    </div>
                  </Space>
                </div>
              }
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <EmissionSourceLocationSummaryChart
              analyticsContext={analyticsContext}
            />
          </Card>
        </Col>
        <Col span={24}>
          <EmissionSourceLocationSourceTable
            analyticsContext={analyticsContext}
            locationDetails={locationDetails}
            collectionId={collectionId}
          />
        </Col>
      </Row>
      <Drawer
        showHeader={false}
        width={565}
        visible={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        destroyOnClose
      >
        <LocationForm
          isOffice
          collectionId={collectionId}
          showAdditionalFields
          onSuccess={handleLocationFormSuccess}
          analyticsContext={{
            tracking: 'Popup form clicked',
            level1: 'Emission Sources',
            level2: 'Details',
            level3: 'Activity Data',
            micro: 'Item Modal',
            collectionId
          }}
        />
      </Drawer>
    </Page>
  );
}
export default EmissionSourcesLocationDetails;
