/** OfficeOnboardingContext is responsible for storing the Global State
 * for office onboarding details.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';
import { EnergyService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const OfficeOnboardingContext = createContext({});

// Define the default context state
const officeOnboardingState = {
  activeStep: 0,
  activeEnergyTypeId: undefined,
  activeCompanyLocation: undefined,
  companyLocations: []
};

// Create method to use context
function useOfficeOnboardingContext() {
  const context = useContext(OfficeOnboardingContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useOfficeOnboardingContext must be used within a OfficeOnboardingContextProvider'
    );
  }
  const [officeOnboardingDetails, setOfficeOnboardingDetails] = context;

  // Context Methods //

  const setOfficeOnboardingActiveStep = (activeStep) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      activeStep
    }));
  };

  const setActiveEnergyTypeId = (activeEnergyTypeId) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      activeEnergyTypeId
    }));
  };

  const setActiveCompanyLocation = (activeCompanyLocation) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      activeCompanyLocation
    }));
  };

  const setCompanyLocationId = (companyLocationId) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      activeCompanyLocation: {
        ...officeOnboardingDetails.activeCompanyLocation,
        companyLocationId
      }
    }));
  };

  const setCompanyLocations = (companyLocations) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      companyLocations
    }));
  };

  const setEnergyDetailsId = (energyDetailsId) => {
    setOfficeOnboardingDetails((officeOnboardingDetails) => ({
      ...officeOnboardingDetails,
      activeCompanyLocation: {
        ...officeOnboardingDetails.activeCompanyLocation,
        energyDetailsId
      }
    }));
  };

  const handleFetchEnergies = () => {
    EnergyService.fetchCompanyTrailEnergies(
      me?.company?.slug,
      (companyLocations) => {
        const newActiveItem = companyLocations.find(
          (activeLocation) =>
            activeLocation?.id ===
            officeOnboardingDetails.activeCompanyLocation?.id
        );
        if (!!newActiveItem) {
          setActiveCompanyLocation(newActiveItem);
        }
        setCompanyLocations(companyLocations);
      },
      () => {},
      () => {}
    );
  };

  // Return state and Context Methods
  // Note: DO NOT return "setMe".State updates should be managed through context methods
  return {
    officeOnboardingDetails,
    setOfficeOnboardingActiveStep,
    setCompanyLocationId,
    setCompanyLocations,
    setEnergyDetailsId,
    setActiveCompanyLocation,
    setActiveEnergyTypeId,
    handleFetchEnergies
  };
}

// Create the context provider
function OfficeOnboardingContextProvider(props) {
  const [officeOnboardingDetails, setOfficeOnboardingDetails] = useState(
    officeOnboardingState
  );
  const value = useMemo(
    () => [officeOnboardingDetails, setOfficeOnboardingDetails],
    [officeOnboardingDetails]
  );
  return <OfficeOnboardingContext.Provider value={value} {...props} />;
}

export { OfficeOnboardingContextProvider, useOfficeOnboardingContext };
