import { Breadcrumb as AntBreadcrumb } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { Icon } from '../index';
import './Breadcrumb.scss';

function Breadcrumb({ children, className, params, ...props }) {
  const handleItemRender = (route) =>
    !!route.path ? (
      <Link key={route.breadcrumbName} to={route.path} state={params}>
        {route.breadcrumbName === 'Sources' && (
          <Icon
            size={16}
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            name="ChevronLeft"
          />
        )}
        {route.breadcrumbName}
      </Link>
    ) : (
      <span key={route.breadcrumbName}>{route.breadcrumbName}</span>
    );
  return (
    <AntBreadcrumb
      className={classNames('ch-breadcrumb', {
        [className]: !!className
      })}
      itemRender={handleItemRender}
      {...props}
    >
      {children}
    </AntBreadcrumb>
  );
}

export default Breadcrumb;
