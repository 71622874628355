import { Col, Row } from 'antd';
import { PawBlue } from '../../../stories/assets/svgs';
import { Anchor, Paragraph, Title } from '../../../stories/atoms';
import VerificationTiers from '../../../stories/molecules/VerificationTiers';
import './VerificationLearnMore.scss';

export function VerificationLearnMore() {
  return (
    <Row gutter={[0, 40]} className="verification-learn-more" justify="center">
      <Col span={24}>
        <Row gutter={[0, 32]} justify="center">
          <Col span={14}>
            <PawBlue size={40} />
          </Col>
          <Col span={14}>
            <Title size="xl" bottomSpacing={0}>
              Verification 101
            </Title>
          </Col>
          <Col span={14}>
            <Paragraph>
              A verification is similar to an audit in carbon accounting, it
              will allow you to ensure your footprint and reporting are
              accurate. For many of our users a verification is a way for them
              to stand out in their industry, and give confidence to their
              stakeholders that footprint is accurate and their reduction
              efforts are verified.
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 32]} justify="center">
          <Col span={14}>
            <Title size="lg" bottomSpacing={0}>
              The Basics
            </Title>
          </Col>
          <Col span={14}>
            <ul>
              <li>
                Getting verified is a process of submitting a selected timeframe
                of data to a 3rd party verifier
              </li>
              <li>
                There will also be supporting documentation that will allow a
                third party to cross reference your data.
              </li>
              <li>We process our verifications through Brightspot Climate</li>
              <li>
                Carbonhound is one of the most cost-efficient quantification
                platforms on the market. This enables cost efficient and
                effective verifications.
              </li>
              <li>There are 3 tiers of verification</li>
            </ul>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="verification-learn-more__tiers">
        <VerificationTiers />
      </Col>
      <Col span={14} className="verification-learn-more__tiers">
        <Title size="xl">Questions?</Title>
        <Paragraph>
          Visit our{' '}
          <Anchor href="https://carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4">
            FAQ
          </Anchor>
          , or{' '}
          <Anchor href="https://meetings.hubspot.com/sanders5/cabronhound-verification-inbound">
            get in touch with us
          </Anchor>
        </Paragraph>
      </Col>
    </Row>
  );
}
