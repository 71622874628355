function BadgeCheck() {
  return (
    <path
      d="M9 12L11 14L15 10M7.835 4.697C8.55231 4.63975 9.23328 4.35771 9.781 3.891C10.3999 3.36327 11.1866 3.07339 12 3.07339C12.8134 3.07339 13.6001 3.36327 14.219 3.891C14.7667 4.35771 15.4477 4.63975 16.165 4.697C16.9758 4.7615 17.737 5.11283 18.3121 5.68793C18.8872 6.26303 19.2385 7.02425 19.303 7.835C19.3603 8.55231 19.6423 9.23328 20.109 9.781C20.6367 10.3999 20.9266 11.1866 20.9266 12C20.9266 12.8134 20.6367 13.6001 20.109 14.219C19.6423 14.7667 19.3603 15.4477 19.303 16.165C19.2385 16.9758 18.8872 17.737 18.3121 18.3121C17.737 18.8872 16.9758 19.2385 16.165 19.303C15.4477 19.3603 14.7667 19.6423 14.219 20.109C13.6001 20.6367 12.8134 20.9266 12 20.9266C11.1866 20.9266 10.3999 20.6367 9.781 20.109C9.23328 19.6423 8.55231 19.3603 7.835 19.303C7.02425 19.2385 6.26303 18.8872 5.68793 18.3121C5.11283 17.737 4.7615 16.9758 4.697 16.165C4.63975 15.4477 4.35771 14.7667 3.891 14.219C3.36327 13.6001 3.07339 12.8134 3.07339 12C3.07339 11.1866 3.36327 10.3999 3.891 9.781C4.35771 9.23328 4.63975 8.55231 4.697 7.835C4.7615 7.02425 5.11283 6.26303 5.68793 5.68793C6.26303 5.11283 7.02425 4.7615 7.835 4.697Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default BadgeCheck;
