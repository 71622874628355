import { Col, Row } from 'antd';
import {
  InitiativeTemplateImpactedSources,
  InitiativeTemplateRelatedActions
} from '../../../../features/Initiative/InitiativeTemplate';

export function CompanyInitiativeSources() {
  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Forecasting',
    level3: 'Impacted Source'
  };
  return (
    <Row gutter={[0, 40]}>
      <Col span={24}>
        <InitiativeTemplateImpactedSources
          analyticsContext={analyticsContext}
        />
      </Col>
      <Col span={24}>
        <InitiativeTemplateRelatedActions
          analyticsContext={{
            ...analyticsContext,
            level3: 'Impacted Source',
            level4: 'Actions Table'
          }}
        />
      </Col>
    </Row>
  );
}
