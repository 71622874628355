import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { ChcAdminInstanceHelper } from '../../../features/ChcAdmin/ChcAdminInstanceHelper';
import { ChcAdminProviderList } from '../../../features/ChcAdmin/ChcAdminProviderList';
import ChcAdminEmissionSourcesList from '../../../features/ChcAdmin/ChcAdminSourceList';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { ChcAdminService } from '../../../services';
import { Breadcrumb, Page, TabPane, Tabs } from '../../../stories/atoms';

export function ChcAdminCompanyDetails() {
  const { companySlug } = useParams();
  const location = useLocation();
  const baseCompanyDetails = location.state?.companyDetails;

  const handleLoadBaseCompanyDetails = useCallback(async () => {
    if (!baseCompanyDetails) {
      return await ChcAdminService.getCompany(companySlug);
    }
  }, [companySlug]);

  const { value: companyDetails, isLoading: isLoadingCompanyDetails } =
    useAsync({
      asyncFunction: handleLoadBaseCompanyDetails,
      defaultValue: baseCompanyDetails || {}
    });

  const routes = [
    {
      path: AppRoutes.CHC_ADMIN,
      breadcrumbName: 'Companies'
    },
    {
      breadcrumbName: companyDetails.name ?? ''
    }
  ];
  return (
    <Page className="chc-admin-company-details">
      <Breadcrumb routes={routes} />
      <Row gutter={[0, 34]}>
        <Col span={24}>
          <ChcAdminInstanceHelper
            isLoading={isLoadingCompanyDetails}
            companyDetails={companyDetails}
            companySlug={companySlug}
          />
        </Col>
        <Col span={24}>
          <Tabs>
            <TabPane tab="Sources" key="1">
              <ChcAdminEmissionSourcesList companySlug={companySlug} />
            </TabPane>
            <TabPane tab="Providers" key="2">
              <ChcAdminProviderList companySlug={companySlug} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Page>
  );
}
