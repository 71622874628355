import React from 'react';

function Eat({ color }) {
  return (
    <>
      <path
        d="M16.6872 12.75C17.8172 9.19168 18.3509 7.06353 17.4842 3.76085C17.3249 3.15368 16.7618 2.75 16.1341 2.75C15.335 2.75 14.6872 3.39781 14.6872 4.19691V20C14.6872 20.5523 15.1349 21 15.6872 21H16.1248C16.7008 21 17.1578 20.5145 17.1229 19.9395L16.6872 12.75Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93349 2.82462C7.97471 2.41246 7.674 2.04493 7.26184 2.00372C6.84968 1.9625 6.48215 2.26321 6.44093 2.67537L6.03583 6.72642C5.94983 7.58641 6.34558 8.42363 7.0647 8.90304C7.29742 9.05819 7.43721 9.31939 7.43721 9.59909V19.75C7.43721 20.8546 8.33264 21.75 9.43721 21.75C10.5418 21.75 11.4372 20.8546 11.4372 19.75V9.59909C11.4372 9.31939 11.577 9.05819 11.8097 8.90304C12.5288 8.42363 12.9246 7.58641 12.8386 6.72642L12.4335 2.67537C12.3923 2.26321 12.0247 1.9625 11.6126 2.00372C11.2004 2.04493 10.8997 2.41246 10.9409 2.82462L11.346 6.87567C11.3768 7.18357 11.2351 7.48332 10.9777 7.65496C10.3277 8.08831 9.93721 8.81786 9.93721 9.59909V19.75C9.93721 20.0261 9.71336 20.25 9.43721 20.25C9.16107 20.25 8.93721 20.0261 8.93721 19.75V9.59909C8.93721 8.81786 8.54677 8.08831 7.89675 7.65496C7.63928 7.48332 7.4976 7.18357 7.52839 6.87567L7.93349 2.82462ZM10.25 2.75C10.25 2.33579 9.91421 2 9.5 2C9.08579 2 8.75 2.33579 8.75 2.75V5.75C8.75 6.16421 9.08579 6.5 9.5 6.5C9.91421 6.5 10.25 6.16421 10.25 5.75V2.75Z"
        // stroke={color}
        fill={color}
        strokeWidth="0"
      />
    </>
  );
}

export default Eat;
