import { UserStatus } from '../constants';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class EmployeeService {
  static async fetchEmployees(
    { companySlug, status = UserStatus.ACTIVE, ...paginationParams },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await axiosInstance.post(API_URL, {
        params: {
          status
        },
        pagination: paginationParams
      });
      const employees = response.data?.employees;
      onSuccess?.(employees, response.data.totalRowCount);
      return { list: employees, count: response.data.totalRowCount };
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateEmployee(
    { companySlug, employeeId, role, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/${employeeId}`;
      await axiosInstance.put(API_URL, { roleId: role, ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateEmployeeMe(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/me`;
      await axiosInstance.put(API_URL, { ...params });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async deleteEmployee(
    { companySlug, id },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/${id}`;

      const response = await axiosInstance.delete(API_URL);
      onSuccess?.(response);
      return response;
    } catch (err) {
      onError?.(err);
      return err;
    } finally {
      onFinal?.();
    }
  }

  static async addEmployeeToCompany(
    companySlug,
    { roleId, email, emailType, groupSlug },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/inviteToCompany`;
      const response = await axiosInstance.post(API_URL, {
        roleId,
        email,
        emailType,
        groupSlug
      });
      onSuccess?.(response);
      return response;
    } catch (err) {
      onError?.(err);
      return err;
    } finally {
      onFinal?.();
    }
  }

  static async bulkInvitePendingEmployeeToCompany({
    companySlug,
    uploadProcessId,
    groupSlug
  }) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/inviteToCompany/pending`;
      const response = await axiosInstance.post(API_URL, {
        uploadProcessId,
        groupSlug
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  static async hardDeletePendingEmployees({ companySlug, uploadProcessId }) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_EMPLOYEE.replace(
        ':companySlug',
        companySlug
      )}/pending/clear`;
      const response = await axiosInstance.post(API_URL, {
        uploadProcessId
      });
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default EmployeeService;
