function ArrowNarrowRight() {
  return (
    <path
      d="M17 8L21 12M21 12L17 16M21 12H3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowNarrowRight;
