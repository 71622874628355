/** MetaContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';
import { MetaService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const MetaContext = createContext({});

// Define the default context state
const metaState = {
  industries: [],
  countries: [],
  energyUnits: [],
  energyTypes: [],
  fuelTypes: [],
  vehicleTypes: [],
  distanceUnits: [],
  travelClasses: [],
  travelCategories: []
};

// Create method to use context
function useMetaContext() {
  const context = useContext(MetaContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error('useMetaContext must be used within a MetaContextProvider');
  }
  const [meta, setMeta] = context;

  // Context Methods //

  const setCountries = () => {
    if (!meta.countries.length) {
      MetaService.fetchCountries(
        (countries) => {
          setMeta((meta) => ({ ...meta, countries }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setIndustries = () => {
    if (!meta.industries.length) {
      MetaService.fetchIndustries(
        (industries) => {
          setMeta((meta) => ({ ...meta, industries }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setEnergyTypes = () => {
    if (!meta.energyTypes.length) {
      MetaService.fetchEnergyTypes(
        {},
        (energyTypes) => {
          setMeta((meta) => ({ ...meta, energyTypes }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setEnergyUnits = async () => {
    if (!meta.energyUnits.length) {
      await MetaService.fetchEnergyUnits(
        (energyUnits) => {
          setMeta((meta) => ({ ...meta, energyUnits }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setFuelTypes = () => {
    if (!meta.fuelTypes.length) {
      MetaService.fetchFuelTypes(
        (fuelTypes) => {
          setMeta((meta) => ({ ...meta, fuelTypes }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setVehicleTypes = () => {
    if (!meta.vehicleTypes.length) {
      MetaService.fetchVehicleTypes(
        (vehicleTypes) => {
          setMeta((meta) => ({ ...meta, vehicleTypes }));
        },
        () => {},
        () => {}
      );
    }
  };
  const setDistanceUnits = () => {
    if (!meta.distanceUnits.length) {
      MetaService.fetchDistanceUnits(
        { isoName: me.company?.headQuartersCountry.isoName },
        (distanceUnits) => {
          setMeta((meta) => ({ ...meta, distanceUnits }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setTravelClasses = () => {
    if (!meta.travelClasses.length) {
      MetaService.fetchTravelClasses(
        (travelClasses) => {
          setMeta((meta) => ({ ...meta, travelClasses }));
        },
        () => {},
        () => {}
      );
    }
  };

  const setTravelCategories = () => {
    if (!meta.travelClasses.length) {
      MetaService.fetchTravelCategories(
        (travelCategories) => {
          setMeta((meta) => ({ ...meta, travelCategories }));
        },
        () => {},
        () => {}
      );
    }
  };

  // Return state and Context Methods
  // Note: DO NOT return "setMe".State updates should be managed through context methods
  return {
    meta,
    setIndustries,
    setCountries,
    setEnergyUnits,
    setEnergyTypes,
    setFuelTypes,
    setVehicleTypes,
    setDistanceUnits,
    setTravelClasses,
    setTravelCategories
  };
}

// Create the context provider
function MetaContextProvider(props) {
  const [meta, setMeta] = useState(metaState);
  const value = useMemo(() => [meta, setMeta], [meta]);
  return <MetaContext.Provider value={value} {...props} />;
}

export { MetaContextProvider, useMetaContext };
