import { Radio as AntRadio } from 'antd';
import { ErrorMessage, Field } from 'formik';
import { Img } from '../../stories/atoms';
import Error from '../Error';
import './Radio.scss';

function Radio({
  title,
  name,
  raised,
  options,
  className,
  value,
  setFieldValue,
  onChange,
  disabled,
  type
}) {
  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <Field name={name}>
      {({ _field, form: { _touched, _errors, _setFieldTouched }, _meta }) => (
        <div className={`ch-radio-field ${className}`}>
          {title && <div className="ch-radio-field__title">{title}</div>}
          <AntRadio.Group
            disabled={disabled}
            className={`ch-radio ${raised ? 'ant-radio-raised' : ''}`}
            onChange={onChange || handleChange}
            value={value}
          >
            {options.map((option, i) =>
              type === 'button' ? (
                <AntRadio.Button value={option.value} key={i}>
                  <span className="ch-radio-field__icon display-flex justify-center">
                    <Img {...option.imgProps} />
                  </span>
                  <span className="ch-radio-field__button-label">
                    {option.label}
                  </span>
                </AntRadio.Button>
              ) : (
                <AntRadio value={option.value} key={i}>
                  {option.label}
                </AntRadio>
              )
            )}
          </AntRadio.Group>
          <ErrorMessage name={name}>
            {(message) => (
              <Error className={`${name}__error`} message={message} />
            )}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default Radio;
