import { Col, Modal as AntModal, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Card, Loader } from '../../../../../components';
import {
  useEmployeeCommuteContext,
  useLayoutContext,
  useMeContext
} from '../../../../../context';
import { AddressFields } from '../../../../../features/EmployeeDetail/AddressFields';
import { analytics } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import { AppRoutes } from '../../../../../routes';
import { CommuteService, LocationService } from '../../../../../services';
import { Button, Title } from '../../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../../stories/atoms/Button/Button.types';
import { Footer } from '../../../../../stories/atoms/Layout';
import { Blurb } from '../../../../../stories/molecules';
import { employeeDetailsAddressValidation } from './EmployeeDetailsAddressValidation';

function EmployeeDetailsAddress() {
  const { me } = useMeContext();

  const { loggedInAs } = me;
  const { userId: loggedInUserId } = loggedInAs ?? {};
  const { navStep } = useLayoutContext();
  const { setActiveCommute } = useEmployeeCommuteContext();
  const params = useParams();
  const { commuteId } = params;
  const [formValues, setFormValues] = useState({
    name: undefined,
    countryId: undefined,
    officeCollectionId: undefined,
    homeLocationId: undefined
  });

  const handlePrevious = () => {
    navStep(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS);
  };

  const handleNext = (cId) => {
    navStep(
      generatePath(AppRoutes.ONBOARDING_EMPLOYEE_DETAILS_COMMUTE_STEPS, {
        commuteId: cId
      }),
      cId
    );
  };

  const handleLoadCommuteDetails = useCallback(async () => {
    if (params.commuteId) {
      const commute = await CommuteService.getCommuteDetails(params.commuteId);
      setActiveCommute(commute);
      const { homeLocation, officeCollectionId, name } = commute ?? {};
      const { id: homeLocationId, ...homeLocationDetails } = homeLocation;
      setFormValues({
        name,
        countryId: homeLocation?.country?.id,
        officeCollectionId,
        homeLocationId,
        ...homeLocationDetails
      });
      return commute;
    }
  }, [params.commuteId]);

  const { isLoading: isLoadingCommutes, value: commute } = useAsync({
    asyncFunction: handleLoadCommuteDetails
  });

  const handleAsyncFn = async (values) => {
    const commuteMethod = commuteId
      ? CommuteService.updateCommute
      : CommuteService.createCommute;
    if (commuteId) {
      values.id = commuteId;
      await CommuteService.clearAllCommuteItems(commuteId);
    }
    const location = await LocationService.createLocation({
      companySlug: me.company?.slug,
      location: values,
      isCollection: false
    });
    values.homeLocationId = location?.id;
    const commute = await commuteMethod({
      companySlug: me.company.slug,
      commute: values,
      loggedInUserId
    });
    analytics.track('Step Completed', me, {
      level1: 'Office-Based Operations',
      level2: 'Employee Details',
      level3: 'Start and Finish Address',
      commuteDetails: commute
    });

    handleNext(commute.id);
  };
  const handleSubmit = async (values) => {
    const { homeLocation, officeCollectionId } = commute ?? {};
    const { id: homeLocationId } = homeLocation ?? {};
    if (commuteId) {
      const isHomeOfficeDirty = values.homeLocationId !== homeLocationId;
      const isDirty =
        (isHomeOfficeDirty ||
          values?.officeCollectionId !== officeCollectionId) &&
        !!commuteId;
      if (isDirty) {
        AntModal.confirm({
          className: 'ch-modal',
          icon: null,
          okText: 'Yes',
          cancelText: 'No',
          content: (
            <div>
              Editing existing commutes will change historical data, Add a new
              commute if you have changed routes. <br />
              <br /> Do you wish to proceed?
            </div>
          ),
          onOk: async () => {
            await handleAsyncFn(values);
          }
        });
      } else {
        handleNext(commuteId);
      }
    } else {
      await handleAsyncFn(values);
    }
  };
  const { isLoading: isSubmittingStep, execute } = useAsync({
    asyncFunction: handleSubmit,
    immediate: false
  });
  return (
    <Formik
      validationSchema={employeeDetailsAddressValidation}
      initialValues={formValues}
      enableReinitialize
      onSubmit={execute}
    >
      <Form>
        <Row className="employee-details-overview mb-4" gutter={[0, 16]}>
          <Col span={24}>
            <Title bottomSpacing={40} size="xl">
              Start and finish addresses
            </Title>
          </Col>
          <Loader isLoading={isLoadingCommutes || isSubmittingStep}>
            <Col span={24}>
              <Card>
                <Title bottomSpacing={24} size="lg">
                  Your commute details
                </Title>
                <AddressFields setFormValues={setFormValues} isEdit={false} />
                <Blurb
                  titleProps={{
                    title: 'Quick tips for adding your commutes'
                  }}
                >
                  <ul className="blurb-list">
                    <li>
                      If your commute often changes (e.g you bike into work in
                      the summer and drive in the winter) you can add them each
                      as individual commutes and indicate the start/end of that
                      commute type.
                    </li>
                    <li>
                      You can modify and delete your commutes at any point.
                    </li>
                    <li>
                      If you have changed your home address during your tenure
                      at your current company you can add your former commutes
                      as a separate item if your company requires it.
                    </li>
                  </ul>
                </Blurb>
              </Card>
            </Col>
          </Loader>
        </Row>
        <Footer className="justify-end display-flex">
          <Space>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              htmlType="button"
              onClick={handlePrevious}
            >
              Back
            </Button>
            <Button htmlType="submit">Next</Button>
          </Space>
        </Footer>
      </Form>
    </Formik>
  );
}

export default EmployeeDetailsAddress;
