import BronzeVerification from './BronzeVerification';
import FootprintVerified from './FootprintVerified';
import GhgProtocol from './GhgProtocol';
import GoldCertification from './GoldCertification';
import Iso140643 from './Iso-14064-3';
import SilverVerification from './SilverVerification';

export {
  Iso140643,
  GhgProtocol,
  GoldCertification,
  SilverVerification,
  BronzeVerification,
  FootprintVerified
};

export const BadgeList = [
  Iso140643,
  FootprintVerified,
  GhgProtocol,
  BronzeVerification,
  SilverVerification,
  GoldCertification
];
