import { Col, Divider, Row } from 'antd';
import { isAfter } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Card } from '../../../components';
import { COLOURS_GRAY } from '../../../constants/colours';
import {
  capitalizeText,
  conditionalCapitalizeText,
  formatDecimal
} from '../../../helpers';
import { getEmissionFactor } from '../../../helpers/generators';
import { Button, Title } from '../../atoms';
import { BUTTON_TYPES } from '../../atoms/Button/Button.types';
import './RelatedEmissionFactor.scss';

const globalWarmingPotentialModifier = {
  CO2E: 1,
  CH4: 28,
  N2O: 265,
  CO2: 1
};

function RelatedEmissionFactorTitle({ name, isInternal }) {
  return (
    <Title size="lg" className="mb-4">
      {conditionalCapitalizeText(name, isInternal)}
    </Title>
  );
}

function RelatedEmissionFactorRow({
  id,
  emissionFactorsValue,
  activityDataValue,
  emissionsValue,
  globalWarmingPotentialValue,
  totalValue
}) {
  return (
    <Row className="related-emission-factor__list" key={id}>
      <Col
        flex="5"
        className="related-emission-factor__center-ef text-sbd"
        type="flex"
        align="middle"
      >
        <Row>{emissionFactorsValue}</Row>
      </Col>
      <Col
        flex="grow"
        className="related-emission-factor__subheading related-emission-factor__list__example-text"
        type="flex"
        align="middle"
      >
        <p>Example Equation</p>
      </Col>
      <Col
        flex="2"
        type="flex"
        align="middle"
        className="related-emission-factor__list__equation"
      >
        <Row className="related-emission-factor__subheading">Activity Data</Row>
        <Row className="related-emission-factor__list__equation__value">
          {activityDataValue}
        </Row>
      </Col>
      <Col
        flex="shrink"
        type="flex"
        align="middle"
        className="related-emission-factor__subheading  mb-2 mt-2"
      >
        <Row justify="center">x</Row>
      </Col>
      <Col flex="3" type="flex" align="middle">
        <Row
          justify="center"
          className="related-emission-factor__subheading mb-1"
        >
          Emissions
        </Row>
        <Row
          justify="center"
          className="related-emission-factor__list__equation__value"
        >
          {emissionsValue}
        </Row>
      </Col>
      <Col
        flex="shrink"
        type="flex"
        align="middle"
        className="related-emission-factor__subheading  mb-2 mt-2"
      >
        <Row justify="center">x</Row>
      </Col>
      <Col flex="2" type="flex" align="middle">
        <Row
          justify="center"
          className="related-emission-factor__subheading mb-1"
        >
          GWP
        </Row>
        <Row
          justify="center"
          className="related-emission-factor__list__equation__value"
        >
          {globalWarmingPotentialValue}
        </Row>
      </Col>
      <Col
        flex="shrink"
        type="flex"
        align="middle"
        className="related-emission-factor__subheading mb-2 mt-2"
      >
        <Row justify="center">=</Row>
      </Col>
      <Col flex="3" type="flex" align="middle">
        <Row
          justify="center"
          className="related-emission-factor__subheading mb-1"
        >
          Total
        </Row>
        <Row
          justify="center"
          className="related-emission-factor__list__equation__value"
        >
          {totalValue}
        </Row>
      </Col>
    </Row>
  );
}

function RelatedEmissionFactor({ emissionFactorCollection }) {
  const { endDate } = emissionFactorCollection;
  return (
    <Card className="related-emission-factor mb-4">
      <RelatedEmissionFactorTitle
        name={emissionFactorCollection.emissionFactorDetails?.name}
        isInternal={emissionFactorCollection.emissionFactorDetails?.isInternal}
      />
      <Row gutter={16}>
        <Col span={12}>
          <div className="related-emission-factor__details">
            <Row justify="space-between">
              <Col span={11} className="related-emission-factor__valid-from ">
                <p className="related-emission-factor__subheading mb-1">
                  Valid From
                </p>
                <p className="related-emission-factor__data text-sbd">
                  {!!emissionFactorCollection.startDate
                    ? formatInTimeZone(
                        new Date(emissionFactorCollection.startDate),
                        'UTC',
                        'MMM dd, yyyy'
                      )
                    : '---'}
                </p>
              </Col>
              <Col span={1} className="related-emission-factor__spacer">
                <Divider />
              </Col>
              <Col
                span={11}
                className="related-emission-factor__valid-to text-right"
              >
                <p className="related-emission-factor__subheading mb-1">
                  Valid to
                </p>
                <p className="related-emission-factor__data text-sbd">
                  {isAfter(new Date(), endDate) || !endDate
                    ? 'Current'
                    : formatInTimeZone(
                        new Date(emissionFactorCollection.endDate),
                        'UTC',
                        'MMM dd, yyyy'
                      )}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className="related-emission-factor__details">
            <Row gutter={16}>
              <Col span={12} className="related-emission-factor__factor-url">
                <p className="related-emission-factor__subheading mb-1">
                  Factor Source
                </p>
                <p className="related-emission-factor__data text-sbd">
                  {emissionFactorCollection.sourceName}
                </p>
              </Col>
              <Col
                span={12}
                className="text-right related-emission-factor__factor-url"
              >
                <p className="related-emission-factor__subheading mb-1">
                  Factor Source URL
                </p>
                <a
                  href={emissionFactorCollection.sourceUrl}
                  title={`${emissionFactorCollection.sourceName}: ${emissionFactorCollection.sourceUrl}`}
                  className=" related-emission-factor__data text-sbd"
                >
                  <Button type={BUTTON_TYPES.TEXT}>
                    {emissionFactorCollection.sourceUrl}
                  </Button>
                </a>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Title color={COLOURS_GRAY.GRAY_500} size="med">
          Emission Factors
        </Title>
      </Row>
      {emissionFactorCollection.items?.map((efc) => {
        const { id, value: emissionFactorValue, emissionFactorGas } = efc;
        const globalWarmingPotentialValue =
          globalWarmingPotentialModifier[emissionFactorGas.formula];
        const activityData = 1500;
        const showInGrams = emissionFactorValue && emissionFactorValue < 0.001;
        const emissionValue = formatDecimal(
          emissionFactorValue * (showInGrams ? 1000 : 1),
          3
        );
        const factorValueText = `${emissionValue} ${
          showInGrams ? 'g' : 'kg'
        } ${capitalizeText(emissionFactorGas.formula)}`;
        const calculatedEmissionFactor = getEmissionFactor(
          showInGrams
            ? emissionFactorValue
            : formatDecimal(emissionFactorValue, 3),
          activityData,
          globalWarmingPotentialValue
        );
        const emissionFactorUnit = capitalizeText(
          emissionFactorCollection.emissionFactorDetails?.activityUnit?.unit
        );
        const emissionFactorText = `${factorValueText} / ${emissionFactorUnit}`;
        const activityDataValue = `${activityData} ${emissionFactorUnit}`;

        const emissionsValue = `${factorValueText} / ${emissionFactorUnit}`;
        const totalValue = `${formatDecimal(calculatedEmissionFactor)} ${
          showInGrams ? 'g' : 'kg'
        } CO2e`;

        return (
          <RelatedEmissionFactorRow
            id={id}
            emissionFactorsValue={emissionFactorText}
            activityDataValue={activityDataValue}
            emissionsValue={emissionsValue}
            globalWarmingPotentialValue={globalWarmingPotentialValue}
            totalValue={totalValue}
          />
        );
      })}
    </Card>
  );
}

export default RelatedEmissionFactor;
