import { Steps as AntSteps } from 'antd';
import classNames from 'classnames';
import './Steps.scss';
import { STEPS_TYPES } from './Steps.types';

function Steps({ children, type = STEPS_TYPES.PRIMARY, className, ...props }) {
  return (
    <AntSteps
      className={classNames('ch-steps', {
        [type]: !!type,
        [className]: !!className
      })}
      {...props}
    >
      {children}
    </AntSteps>
  );
}
export default Steps;
