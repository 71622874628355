import { AppRoutes } from '../../../routes';

export const COMPANY_INITIATIVE_STEPS = {
  OVERVIEW: 'OVERVIEW',
  IMPACTED_SOURCES: 'IMPACTED_SOURCES',
  TARGET_BASELINE: 'TARGET_BASELINE',
  BUSINESS_PLAN: 'BUSINESS_PLAN',
  RECOMMENDED_ACTIONS: 'RECOMMENDED_ACTIONS',
  FUNDING: 'FUNDING',
  FORECASTING: 'FORECASTING',
  PLANNING: 'PLANNING'
};
export const COMPANY_INITIATIVE_STEPS_LABEL = {
  [COMPANY_INITIATIVE_STEPS.OVERVIEW]: 'Initiative Overview',
  [COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES]: 'Impacted Sources',
  [COMPANY_INITIATIVE_STEPS.TARGET_BASELINE]: 'Baseline & Target',
  [COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS]: 'Recommended Actions',
  [COMPANY_INITIATIVE_STEPS.FUNDING]: 'Funding',
  [COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN]: 'Business Plan'
};
export const COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS = {
  [COMPANY_INITIATIVE_STEPS.OVERVIEW]: AppRoutes.COMPANY_INITIATIVE,
  [COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES]:
    AppRoutes.COMPANY_INITIATIVE_IMPACTED_SOURCES,
  [COMPANY_INITIATIVE_STEPS.TARGET_BASELINE]:
    AppRoutes.COMPANY_INITIATIVE_BASELINE,
  [COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS]:
    AppRoutes.COMPANY_INITIATIVE_RECOMMENDED_ACTIONS,
  [COMPANY_INITIATIVE_STEPS.FUNDING]: AppRoutes.COMPANY_INITIATIVE_FUNDING,
  [COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN]:
    AppRoutes.COMPANY_INITIATIVE_BUSINESS_PLAN
};
export const CompanyInitiativeStepsRoutes = {
  [AppRoutes.COMPANY_INITIATIVE]: COMPANY_INITIATIVE_STEPS.OVERVIEW,
  [AppRoutes.COMPANY_INITIATIVE_IMPACTED_SOURCES]:
    COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES,
  [AppRoutes.COMPANY_INITIATIVE_BASELINE]:
    COMPANY_INITIATIVE_STEPS.TARGET_BASELINE,
  [AppRoutes.COMPANY_INITIATIVE_RECOMMENDED_ACTIONS]:
    COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS,
  [AppRoutes.COMPANY_INITIATIVE_FUNDING]: COMPANY_INITIATIVE_STEPS.FUNDING,
  [AppRoutes.COMPANY_INITIATIVE_BUSINESS_PLAN]:
    COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN
};

export const CompanyInitiativeStepsTuple = Object.freeze([
  COMPANY_INITIATIVE_STEPS.OVERVIEW,
  COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES,
  COMPANY_INITIATIVE_STEPS.TARGET_BASELINE,
  COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN,
  COMPANY_INITIATIVE_STEPS.FUNDING,
  COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS
]);

export const CompanyInitiativePlanningSteps = [
  COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES,
  COMPANY_INITIATIVE_STEPS.TARGET_BASELINE,
  COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN,
  COMPANY_INITIATIVE_STEPS.FUNDING
];

export const CompanyInitiativeForecastingSteps = [
  COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES,
  COMPANY_INITIATIVE_STEPS.TARGET_BASELINE
];

export const CompanyInitiativeSubMenuKeys = [
  COMPANY_INITIATIVE_STEPS.FORECASTING,
  COMPANY_INITIATIVE_STEPS.PLANNING
];
