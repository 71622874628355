import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMeContext } from '../../context';
import EmployeeTable from '../../features/EmployeeTable';
import { IntegrationList } from '../../features/Integrations/IntegrationList';
import { TargetSettingOverview } from '../../features/Target';
import { UserCompanyDetails } from '../../features/UserCompany';
import { CompanyService } from '../../services';
import { TabPane, Tabs } from '../../stories/atoms';
import './userCompany.scss';

function UserCompany() {
  const { me } = useMeContext();
  const [company, setCompany] = useState({});
  const {
    company: { slug }
  } = me;

  useEffect(() => {
    CompanyService.getCompanyProfile(
      { slug },
      (details) => {
        setCompany(details);
      },
      () => {},
      () => {}
    );
  }, [slug]);
  const location = useLocation();
  const defaultKey = location.state?.defaultKey ?? '1';

  return (
    <div className="user-company">
      <h4 className="text-bd">My Company</h4>
      <Tabs defaultActiveKey={defaultKey}>
        <TabPane tab="Company Details" key="1">
          <UserCompanyDetails company={company} />
        </TabPane>
        <TabPane tab="Employees" key="2">
          <EmployeeTable company={company} />
        </TabPane>
        <TabPane tab="Targets" key="3">
          <TargetSettingOverview isReadOnly />
        </TabPane>
        <TabPane tab="Data Concierge" key="4">
          <IntegrationList />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserCompany;
