function Plus() {
  return (
    <path
      d="M12 6V12M12 12V18M12 12H18M12 12H6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Plus;
