import { Modal, Space } from 'antd';
import * as dateFns from 'date-fns';
import React, { useState } from 'react';
import { Card } from '../../../components';
import {
  BulkUploadType,
  EmissionSourceItemOrigin,
  EmissionSourceItemOriginCopy,
  UploadStatus
} from '../../../constants';
import { useDataImportContext } from '../../../context/DataImportContext';
import { capitalizeText, getFullName } from '../../../helpers';
import { useModal } from '../../../hooks';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table,
  Tag
} from '../../../stories/atoms';
import { INPUT_TYPES } from '../../../stories/atoms/Input/Input.types';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import TableTitle from '../../../stories/molecules/TableTitle';
import { BulkUploadForm } from '../../index';
import { useMeContext } from '../../../context';

export function DataImportList() {
  const [isViewingComplete, setIsViewingComplete] = useState(false);
  const {
    completePagination,
    dataImportState,
    handleDeleteNotification,
    handleDownloadFile,
    incompletePagination,
    isLoading,
    refreshList,
    searchImports,
    setActiveNotification
  } = useDataImportContext();
  const {
    completeDataImportList,
    completeRowCount,
    incompleteDataImportList,
    incompleteRowCount
  } = dataImportState;
  const { isSuperUser } = useMeContext();

  const {
    Modal: BulkUploadsModal,
    handleShowModal: handleShowBulkUploadsModal,
    handleCloseModal: handleCloseBulkUploadsModal
  } = useModal({
    onCloseModal: refreshList,
    width: '90%'
  });

  const handleConfirmDeleteNotification = (notification) => {
    Modal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Delete',
      cancelText: 'Cancel',
      content: (
        <div className="text-left">
          <h5 className="text-bd">
            Are you sure you’d like to delete this data upload?
          </h5>
          <p>All data will be permanently deleted. </p>
        </div>
      ),
      onOk: async () => {
        await handleDeleteNotification(notification);
      }
    });
  };
  const columns = [
    {
      title: <ColumnTitle> Name</ColumnTitle>,
      dataIndex: 'title',
      render: (title) => (
        <span className="text-bd"> {capitalizeText(title)}</span>
      )
    },
    {
      title: 'Filename',
      dataIndex: 'details',
      render: (details) => details?.originalFileName ?? '--'
    },
    {
      title: 'Imported by',
      dataIndex: 'createdBy',
      render: (createdBy, notification) => {
        if (
          notification.details?.origin ===
          EmissionSourceItemOrigin.CARBONHOUND_CONNECT
        ) {
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.CARBONHOUND_CONNECT
          ];
        }
        return capitalizeText(getFullName(createdBy));
      }
    },
    {
      title: 'Imported at',
      dataIndex: 'createdAt',
      render: (createdAt) =>
        dateFns.format(new Date(createdAt), 'MMM dd, yyyy h:maa')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        let tagColour;
        switch (status) {
          case UploadStatus.COMPLETE: {
            tagColour = TAG_COLOURS.GREEN;
            break;
          }
          case UploadStatus.PROCESSING: {
            tagColour = TAG_COLOURS.ORANGE;
            break;
          }

          case UploadStatus.PENDING: {
            tagColour = TAG_COLOURS.YELLOW;
            break;
          }
          case UploadStatus.ERROR: {
            tagColour = TAG_COLOURS.RED;
            break;
          }
          case UploadStatus.READY_FOR_REVIEW:
          default: {
            tagColour = TAG_COLOURS.BLUE;
            break;
          }
        }
        return <Tag color={tagColour}>{capitalizeText(status)}</Tag>;
      }
    },
    ...(isViewingComplete
      ? [
          {
            render: (_, notification) => {
              const showDeleteUploadButton =
                ![
                  BulkUploadType.USER,
                  BulkUploadType.PERSONAL_VEHICLE
                ].includes(notification.details?.uploadType) &&
                (isSuperUser() ||
                  notification.details?.origin !==
                    EmissionSourceItemOrigin.CARBONHOUND_CONNECT);
              return (
                <Space>
                  <ButtonIcon
                    name="Download"
                    onClick={() => handleDownloadFile(notification)}
                  />
                  {showDeleteUploadButton && (
                    <ButtonIcon
                      name="MinusCircle"
                      onClick={() =>
                        handleConfirmDeleteNotification(notification)
                      }
                    />
                  )}
                </Space>
              );
            }
          }
        ]
      : [])
  ];
  const handleSwitchListViews = (value) => {
    setIsViewingComplete(value);
  };
  const dataSource = isViewingComplete
    ? completeDataImportList
    : incompleteDataImportList;
  const rowCount = isViewingComplete ? completeRowCount : incompleteRowCount;
  const pagination = isViewingComplete
    ? completePagination
    : incompletePagination;

  const switcherProps = {
    onClick: handleSwitchListViews,
    activeValue: isViewingComplete,
    options: [
      {
        label: `In Progress (${incompleteRowCount ?? 0})`,
        value: false
      },
      { label: `Complete (${completeRowCount ?? 0})`, value: true }
    ]
  };

  return (
    <Card>
      <Table
        title={() => (
          <TableTitle
            switcherProps={switcherProps}
            searchProps={{
              type: INPUT_TYPES.SEARCH,
              isFormikField: false,
              enterButton: true,
              placeholder: 'Search by name',
              onSearch: searchImports
            }}
          />
        )}
        onRow={(an) => ({
          className: !isViewingComplete && 'cursor-pointer',
          onClick: isViewingComplete
            ? null
            : () => {
                setActiveNotification(an);
                handleShowBulkUploadsModal();
              }
        })}
        pagination={pagination}
        showPagination
        dataSource={dataSource}
        columns={columns}
        total={rowCount}
        isLoading={isLoading}
        isHeaderBorder={false}
        locale={{
          emptyText: (
            <Empty
              icon="Upload"
              title="No active imports"
              description="Once you have active data imports you can track their progress here"
            />
          )
        }}
      />
      <BulkUploadsModal className="bulk-upload-modal" title="Upload your file">
        <BulkUploadForm
          notification={dataImportState.activeNotification}
          handleCloseBulkUploadsModal={handleCloseBulkUploadsModal}
        />
      </BulkUploadsModal>
    </Card>
  );
}
