import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { useAsync } from '../../../../hooks';
import { AppRoutes } from '../../../../routes';
import { Button, Drawer, Link, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { CompanyInitiativeCustomActionForm } from '../CompanyInitiativeCustomActionForm';
import { CompanyInitiativeRelatedActionsTable } from './CompanyInitiativeRelatedActionsTable';

export function CompanyInitiativeRelatedActions() {
  const [showNotifications, setShowNotifications] = useState(false);
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { handleLoadInitiativeRelatedActions } = useInitiativeContext();
  const { me } = useMeContext();

  const analyticsContext = {
    level1: 'Initiatives',
    level2: 'Overview',
    level3: 'Actions Table'
  };

  const handleCreateRelatedActionSuccess = () => {
    setShowNotifications(false);
    handleLoadInitiativeRelatedActions();
  };
  useAsync({ asyncFunction: handleLoadInitiativeRelatedActions });
  const handleCreateCustomActions = () => {
    setShowNotifications(true);
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
  };
  return (
    <Row gutter={16} className="company-initiative-header">
      <Col flex="1">
        <Title>Related actions</Title>
      </Col>
      <Col>
        <Space size={32}>
          <Link fontWeight={600} to={AppRoutes.ACTIONS}>
            View all Actions
          </Link>
          <Button
            onClick={handleCreateCustomActions}
            type={BUTTON_TYPES.SECONDARY}
          >
            Create
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <CompanyInitiativeRelatedActionsTable
          analyticsContext={analyticsContext}
        />
      </Col>
      <Drawer
        showHeader={false}
        width="30%"
        destroyOnClose
        visible={showNotifications}
        onClose={() => setShowNotifications(false)}
      >
        <CompanyInitiativeCustomActionForm
          analyticsContext={analyticsContext}
          onSuccess={handleCreateRelatedActionSuccess}
        />
      </Drawer>
    </Row>
  );
}
