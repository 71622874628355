import { axiosInstance } from '../interceptors';
import { Notification } from '../models';
import { ApiRoutes } from '../routes';

class NotificationService {
  static async fetchNotificationActions(
    { pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.MY_NOTIFICATION_ACTIONS}`;
      const response = await axiosInstance.post(
        API_URL,
        { pagination },
        {
          params
        }
      );
      onSuccess?.(
        response.data?.notifications,
        Number(response.data.totalRowCount)
      );
      return {
        list: response.data?.notifications,
        count: Number(response.data.totalRowCount)
      };
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateNotificationAction(
    notificationItem,
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTION_ITEM.replace(
      ':notificationId',
      notificationItem.id
    );
    try {
      const response = await axiosInstance.put(API_URL, notificationItem);
      Notification({
        type: 'success',
        message: 'Notification',
        description: 'Notification action updated.'
      });
      onSuccess?.(response);
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async getCommuteAlert({ companySlug }, onSuccess, onError, onFinal) {
    const API_URL = ApiRoutes.COMMUTE_ALERT.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = (await axiosInstance.get(API_URL)) ?? {};
      onSuccess?.(response.data);
      return response?.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async createNotification(params, onSuccess, onError, onFinal) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTION;
    try {
      const response = await axiosInstance.post(API_URL, { ...params });
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async getNotification(
    { notificationId },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTION_ITEM.replace(
      ':notificationId',
      notificationId
    );
    try {
      const response = await axiosInstance.get(API_URL);
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async updateNotification(
    { notificationId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTION_ITEM.replace(
      ':notificationId',
      notificationId
    );
    try {
      const response = await axiosInstance.put(API_URL, { ...params });
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async createNotificationActionDataExport(
    { name, companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTION_DATA_EXPORT;
    try {
      const response = await axiosInstance.post(
        API_URL,
        { name, companySlug },
        { params }
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      onError?.(err);
      return err;
    } finally {
      onFinal?.();
    }
  }

  static async authorizeUpload(
    { notificationId, file, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_UPLOAD.replace(
      ':notificationId',
      notificationId
    );
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axiosInstance.post(`${API_URL}/authorize`, {
        ...params
      });
      const SIGNED_URL = response?.data?.signedUrl;
      const uploadedFile = await axiosInstance.put(SIGNED_URL, file, {
        headers: {
          'Content-Type': file.type
        }
      });
      onSuccess(uploadedFile);
    } catch (err) {
      onError(err);
    } finally {
      onFinal();
    }
  }

  static async startBulkUpload(
    { notificationId, _file, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_UPLOAD.replace(
      ':notificationId',
      notificationId
    );
    try {
      const response = await axiosInstance.post(`${API_URL}/start`, {
        ...params
      });
      onSuccess?.(response.data);
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async deleteNotification(
    { _notificationId, companySlug },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_ACTIVITY.replace(
      ':companySlug',
      companySlug
    );
    try {
      const response = await axiosInstance.delete(API_URL);
      onSuccess?.(response.data);
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async authorizeDownload(
    { notificationId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    const API_URL = ApiRoutes.NOTIFICATION_DOWNLOAD.replace(
      ':notificationId',
      notificationId
    );
    try {
      const response = await axiosInstance.post(API_URL, {
        ...params
      });
      const SIGNED_URL = response?.data?.signedUrl;
      const downloadedFile = await axiosInstance.get(SIGNED_URL);
      onSuccess?.(downloadedFile?.data);
      return downloadedFile?.data;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }

  static async getNotificationOverview(params, onSuccess, onError, onFinal) {
    const API_URL = ApiRoutes.NOTIFICATION_OVERVIEW;
    try {
      const response = await axiosInstance.get(API_URL, { params });

      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      onError?.(err);
    } finally {
      onFinal?.();
    }
  }
}

export default NotificationService;
