import { Col, Row, Space } from 'antd';
import { useFormikContext } from 'formik';
import { useInitiativeContext } from '../../../../context';
import { useCompanyInitiativeLayoutContext } from '../../../../context/CompanyInitiativeLayoutContext';
import { CompanyInitiativeStepsTuple } from '../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import { AppRoutes } from '../../../../routes';
import { Button, Icon, Link } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';

export function CompanyInitiativeFooter({
  isFormSubmitting,
  editTrail,
  nextStepAnalyticsContext
}) {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiativeId } = initiativeState;
  const { prevStep, companyInitiativeLayoutState, navStep } =
    useCompanyInitiativeLayoutContext();
  const { currentStep } = companyInitiativeLayoutState;
  const isLastStep =
    CompanyInitiativeStepsTuple[CompanyInitiativeStepsTuple.length - 1] ===
    currentStep;
  const { handleSubmit } = useFormikContext();
  const handleSecondaryBtnFn = () => {
    if (isLastStep) {
      nextStepAnalyticsContext({ level2: 'Recommended Action' });
      editTrail(true);
      navStep(
        AppRoutes.COMPANY_INITIATIVE.replace(
          ':companyInitiativeId',
          companyInitiativeId
        )
      );
    } else {
      prevStep();
    }
  };
  return (
    <Row>
      <Col flex="1" className="display-flex align-center">
        <Link fontWeight={600} flex to={AppRoutes.HELP}>
          <Icon name="InformationCircle" className="mr-2" /> Get help
        </Link>
      </Col>
      <Col>
        <Space size={16}>
          <Button
            onClick={handleSecondaryBtnFn}
            type={isLastStep ? BUTTON_TYPES.TEXT : BUTTON_TYPES.SECONDARY}
          >
            {isLastStep ? 'Back to Overview' : ' Back'}
          </Button>
          <Button
            loading={isFormSubmitting}
            disabled={isFormSubmitting}
            htmlType="submit"
            onClick={handleSubmit}
          >
            {isLastStep ? 'View Actions' : 'Next'}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
