import { Col, Row } from 'antd';
import { AppRoutes } from '../../../routes';
import { Button, Link, Paragraph, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

export function UserProfileCommute() {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title bottomSpacing={8}>Commute & WFH</Title>
        <Paragraph bottomSpacing={0}>
          Your personal commute and work from home details can be modified here{' '}
        </Paragraph>
      </Col>
      <Col>
        <Link to={AppRoutes.ONBOARDING_EMPLOYEE_DETAILS}>
          <Button type={BUTTON_TYPES.SECONDARY}>Manage</Button>
        </Link>
      </Col>
    </Row>
  );
}
