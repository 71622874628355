import { Col, Row, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Card, Loader } from '../../../components';
import { useMeContext } from '../../../context';
import { useAsync, useModal } from '../../../hooks';
import { BulkUploadForm } from '../../../features';
import { AppRoutes } from '../../../routes';
import { EmissionSourceItemOrigin } from '../../../constants';
import { MeService, ChcAdminService } from '../../../services';
import {
  Button,
  CopyToClipboard,
  Paragraph,
  Tag,
  Title
} from '../../../stories/atoms';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import React from 'react';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

export function ChcAdminInstanceHelper({
  companyDetails,
  isLoading = false,
  companySlug
}) {
  const { isSuperUser, setJWTToken, getMyDetails } = useMeContext();
  const { name, id } = companyDetails || {};
  const navigate = useNavigate();
  const {
    Modal: BulkUploadsModal,
    handleShowModal: handleShowBulkUploadsModal,
    handleCloseModal: handleCloseBulkUploadsModal
  } = useModal({
    width: '90%'
  });
  const loginToInstance = async () => {
    try {
      const token = await MeService.changeActiveCompany(companySlug);
      if (!!token) {
        setJWTToken(token);
        await getMyDetails();
        navigate(AppRoutes.DASHBOARD);
      }
    } catch (e) {}
  };
  const isInternal = isSuperUser();
  const { execute: handleLoginToInstance, isLoading: isChangingInstance } =
    useAsync({
      asyncFunction: loginToInstance,
      immediate: false
    });

  const updateIsTestCompany = async (e) => {
    try {
      const updatedCompany = await ChcAdminService.updateCompany(companySlug, {
        isTestCompany: !!e.target.checked
      });
      companyDetails.isTestCompany = updatedCompany.isTestCompany;
    } catch (err) {}
  };
  const { execute: handleUpdateCompany } = useAsync({
    asyncFunction: updateIsTestCompany,
    immediate: false
  });

  const activeSubscription = companyDetails.subscriptions?.find(
    (sub) => sub.type === 'APP_ACCESS'
  );
  let subscriptionStatus = 'Active';
  let subscriptionStatusColour = TAG_COLOURS.BLUE;
  if (activeSubscription?.status === 'EXPIRED') {
    subscriptionStatus = 'Expired';
    subscriptionStatusColour = TAG_COLOURS.RED;
  } else if (activeSubscription?.isFreeTrial) {
    subscriptionStatus = 'Free Trial';
    subscriptionStatusColour = TAG_COLOURS.YELLOW;
  }
  return (
    <Card large>
      <Loader isLoading={isLoading}>
        <Row gutter={[16, 16]} wrap={false} align="middle">
          <Col flex={1}>
            <Row gutter={[24, 16]} align="middle">
              <Col flex="0 1 auto" className="align-center">
                <Title bottomSpacing={0} size="lg">
                  {name}
                </Title>
              </Col>
              <Col flex="0 1 auto" className="align-center display-flex">
                <Paragraph bottomSpacing={0} className="mr-2">
                  {id}
                </Paragraph>
                <CopyToClipboard text={id} />
              </Col>
              <Col flex="0 1 auto" className="align-center">
                <Paragraph bottomSpacing={0} size="lg">
                  <Tag color={subscriptionStatusColour}>
                    Subscription - {subscriptionStatus}
                  </Tag>
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              onClick={handleShowBulkUploadsModal}
              type={BUTTON_TYPES.SECONDARY}
            >
              CHC Data Import
            </Button>
          </Col>
          <Col flex="0 1 auto" className="align-center display-flex">
            <Checkbox
              onChange={handleUpdateCompany}
              checked={companyDetails.isTestCompany}
            >
              Is Test Company?
            </Checkbox>
          </Col>
          {isInternal && (
            <Col>
              <Button
                disabled={isChangingInstance}
                loading={isChangingInstance}
                onClick={handleLoginToInstance}
              >
                Login to Instance
              </Button>
            </Col>
          )}
        </Row>
      </Loader>
      <BulkUploadsModal className="bulk-upload-modal" title="Upload your file">
        <BulkUploadForm
          companySlug={companyDetails.slug}
          notificationReqParams={{
            origin: EmissionSourceItemOrigin.CARBONHOUND_CONNECT
          }}
          onSuccess={handleCloseBulkUploadsModal}
        />
      </BulkUploadsModal>
    </Card>
  );
}
