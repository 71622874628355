import * as Yup from 'yup';

export const companyInformationValidation = Yup.object().shape({
  name: Yup.string().nullable().required('Please enter in a company name'),
  industryId: Yup.string().nullable().required('Please select an industry'),
  reportedEmployeeCount: Yup.string()
    .nullable()
    .required('Please select a range of employees'),
  headQuartersCountry: Yup.string()
    .nullable()
    .required('Please select the country of your company')
});
