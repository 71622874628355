import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useMeContext } from '../../../../context';
import { getDropdownOptions } from '../../../../helpers';
import { useAsync, useModal } from '../../../../hooks';
import IntensityMetricService from '../../../../services/intensityMetricService';
import { Select } from '../../../../stories/atoms';
import {
  targetIntensityTypeOptions,
  targetIntensityTypes
} from '../TargetSettingStrategy.constants';
import { IntensityFormRowCustom } from './IntensityFormRowCustom';
import { IntensityMetricForm } from '../../../IntensityMetric/Form';

function IntensityFormRow() {
  const { values, setValues } = useFormikContext();
  const {
    me: {
      company: { slug: companySlug }
    }
  } = useMeContext();

  const handleLoadDropdownList = useCallback(async () => {
    const { data } = await IntensityMetricService.listIntensityMetricDetails({
      companySlug
    });
    const customOption =
      targetIntensityTypeOptions[targetIntensityTypeOptions.length - 2];
    const templateOption =
      targetIntensityTypeOptions[targetIntensityTypeOptions.length - 1];

    const apiOptions = getDropdownOptions(data, 'name', 'id');
    return [...apiOptions, customOption, templateOption];
  }, [companySlug]);

  const {
    execute: executeHandleDropdownlist,
    value: typeOptions,
    isLoading: isTypeOptionsLoading
  } = useAsync({
    asyncFunction: handleLoadDropdownList,
    defaultValue: []
  });

  const {
    Modal: EditMetricModal,
    handleShowModal: handleShowIntensityMetricModal,
    handleCloseModal: handleCloseIntensityMetricModal
  } = useModal({});

  const handleSetIntensityTemplate = (value, { label }) => {
    if (value === targetIntensityTypes.TEMPLATE) {
      handleShowIntensityMetricModal();
      // Clear the input - you can't actually move forward with this selection its fake
      return setValues({
        ...values,
        name: null,
        intensityMetricDetailsId: null
      });
    }

    const isCustomIntensityMetric = value === targetIntensityTypes.CUSTOM;
    const intensityMetricDetailsId = !isCustomIntensityMetric
      ? value
      : undefined;
    const name = isCustomIntensityMetric ? value : label;
    setValues({
      ...values,
      name,
      intensityMetricDetailsId
    });
  };

  const handleIntensityMetricModalSuccess = async ({
    intensityMetricDetailsId,
    name
  }) => {
    handleCloseIntensityMetricModal();
    await executeHandleDropdownlist();

    setValues({
      ...values,
      name,
      intensityMetricDetailsId
    });
  };

  return (
    <Row gutter={[24, 24]}>
      <Col flex="1">
        <Select
          loading={isTypeOptionsLoading}
          title="Intensity Metric"
          name={'intensityMetricDetailsId'}
          placeholder="Select"
          options={typeOptions}
          onChange={handleSetIntensityTemplate}
          value={values?.intensityMetricDetailsId}
        />
      </Col>
      {values?.name === targetIntensityTypes.CUSTOM && (
        <Col span={24}>
          <IntensityFormRowCustom />
        </Col>
      )}
      <EditMetricModal title={`Create Intensity Metric`}>
        <IntensityMetricForm onSuccess={handleIntensityMetricModalSuccess} />
      </EditMetricModal>
    </Row>
  );
}

export default IntensityFormRow;
