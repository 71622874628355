import { Col, Row } from 'antd';

import { getIsSetupComplete } from '../CompanyInitiative.generators';
import { useCompanyInitiativeLayoutContext } from '../../../../context';
import { CompanyInitiativeOverviewDetails } from '../../../../features/Initiative/CompanyInitiative/CompanyInitiativeOverview';
import { CompanyInitiativeRelatedActions } from '../../../../features/Initiative/CompanyInitiative/CompanyInitiativeRelatedActions';

export function CompanyInitiativeOverview() {
  const { companyInitiativeLayoutState } = useCompanyInitiativeLayoutContext();
  const { companyTrailOverviewList } = companyInitiativeLayoutState;
  // Only show the overview if every setup step has been completed
  const showOverview = getIsSetupComplete(companyTrailOverviewList);

  return (
    <Row gutter={[16, 40]}>
      {showOverview && (
        <Col span={24}>
          <CompanyInitiativeOverviewDetails />
        </Col>
      )}
      <Col span={24}>
        <CompanyInitiativeRelatedActions />
      </Col>
    </Row>
  );
}
