const SourceCategoryTag = {
  BUSINESS_TRAVEL: 'Business Travel',
  CAPITAL_GOODS: 'Capital Goods',
  DOWNSTREAM_TRANSPORTATION_DISTRIBUTION:
    'Downstream Transportation & Distribution',
  DOWNSTREAM_LEASED_ASSETS: 'Downstream Leased Assets',
  EMPLOYEE_COMMUTING: 'Employee Commuting',
  END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS:
    'End of Life Treatment of Sold Products',
  FRANCHISES: 'Franchises',
  FUEL_ENERGY_RELATED_ACTIVITIES: 'Fuel & Energy Related Activities',
  IMPORTED_ENERGY: 'Imported Energy',
  INVESTMENTS: 'Investments',
  FUGITIVE_EMISSIONS: 'Fugitive Emissions',
  MOBILE_COMBUSTION: 'Mobile Combustion',
  PROCESSING_OF_SOLD_PRODUCTS: 'Processing of Sold Products',
  PURCHASED_GOODS_SERVICES: 'Purchased Goods & Services',
  STATIONARY_COMBUSTION: 'Stationary Combustion',
  UPSTREAM_LEASED_ASSETS: 'Upstream Leased Assets',
  USE_OF_SOLD_PRODUCTS: 'Use of Sold Products',
  WASTE_GENERATED_IN_OPERATIONS: 'Waste Generated in Operations',
  OFFSETS: 'Offsets',
  UPSTREAM_TRANSPORTATION_DISTRIBUTION:
    'Upstream Transportation & Distribution',
  NON_MECHANICAL: 'Non-Mechanical'
};
export default SourceCategoryTag;
