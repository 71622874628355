import { Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import { useMeContext } from '../../../context';
import { getDropdownOptions } from '../../../helpers';
import { Input, Paragraph, Select, Title } from '../../../stories/atoms';

export function GreenTeam() {
  const { me } = useMeContext();
  const { setFieldValue } = useFormikContext();

  const {
    company: { roles = [] }
  } = me;

  const userRoleOptions = getDropdownOptions(roles, 'name', 'id');

  return (
    <Row>
      <Col span={24}>
        <Title fontSize={20}>Invite your Green Team</Title>
        <Paragraph bottomSpacing={24}>
          Carbonhound is better together! Invite your admin team to help you
          onboarding your company. If you have more team members you would like
          to invite you can invite them later in My Company.
        </Paragraph>
      </Col>
      <Col span={24} className="climate-goals-dropdown__wrapper">
        <FieldArray name="adminEmails">
          {(fieldArrayProps) => {
            const { form } = fieldArrayProps;
            const { adminFields } = form.values;
            return (
              <Row gutter={[6, 6]}>
                {adminFields?.map((field, fieldIndex) => (
                  <>
                    <Col xs={16} sm={18} md={18} lg={20} xl={21}>
                      <Input
                        type="text"
                        name={`adminFields[${fieldIndex}].email`}
                        placeholder="Email"
                        required
                      />
                    </Col>
                    <Col xs={8} sm={6} md={6} lg={4} xl={3}>
                      <Select
                        name={`adminFields[${fieldIndex}].roleId`}
                        setFieldValue={setFieldValue}
                        options={userRoleOptions}
                        placeholder="Admin"
                        defaultValue={roles?.[0]?.id}
                      />
                    </Col>
                  </>
                ))}
              </Row>
            );
          }}
        </FieldArray>
      </Col>
    </Row>
  );
}
