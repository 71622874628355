function ChevronDoubleDown() {
  return (
    <path
      d="M19 13L12 20L5 13M19 5L12 12L5 5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ChevronDoubleDown;
