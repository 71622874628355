function Beaker() {
  return (
    <path
      d="M19.428 15.428C19.1488 15.1488 18.7932 14.9584 18.406 14.881L16.019 14.404C14.7106 14.1424 13.3524 14.3243 12.159 14.921L11.841 15.079C10.6476 15.6757 9.28936 15.8576 7.981 15.596L6.05 15.21C5.7272 15.1455 5.39348 15.1617 5.07843 15.2571C4.76339 15.3525 4.47677 15.5242 4.244 15.757M8 4H16L15 5V10.172C15.0001 10.7024 15.2109 11.211 15.586 11.586L20.586 16.586C21.846 17.846 20.953 20 19.171 20H4.828C3.046 20 2.154 17.846 3.414 16.586L8.414 11.586C8.7891 11.211 8.99989 10.7024 9 10.172V5L8 4Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default Beaker;
