import { Col, Row } from 'antd';
import React from 'react';
import { useInitiativeContext, useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { useModal } from '../../../../hooks';
import { Button, Title } from '../../../../stories/atoms';
import { InitiativeTemplateImpactedSourcesForm } from '../InitiativeTemplateImpactedSourcesForm';
import { InitiativeTemplateImpactedSourcesTable } from './InitiativeTemplateImpactedSourcesTable';

export function InitiativeTemplateImpactedSources({ analyticsContext }) {
  const { initiativeState } = useInitiativeContext();
  const { companyInitiative } = initiativeState;
  const { initiativeTemplate } = companyInitiative;
  const { me } = useMeContext();
  const {
    Modal: AddEmissionSourceModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        ...analyticsContext,
        initiativeTemplateId: initiativeTemplate?.id,
        companyInitiativeId: companyInitiative.id
      });
    },
    width: '60%'
  });
  return (
    <Row gutter={[16, 48]} align="middle">
      <Col flex={1} className="display-flex align-center">
        <Title bottomSpacing={0}>Sources</Title>
      </Col>
      <Col>
        <Button onClick={handleShowModal}>Add</Button>
      </Col>
      <Col span={24}>
        <InitiativeTemplateImpactedSourcesTable
          analyticsContext={analyticsContext}
        />
      </Col>
      <AddEmissionSourceModal title="Impacted Sources">
        <InitiativeTemplateImpactedSourcesForm onSuccess={handleCloseModal} />
      </AddEmissionSourceModal>
    </Row>
  );
}
