function GusLaptop() {
  return (
    <svg
      width="156"
      height="141"
      viewBox="0 0 156 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.535 125.843C146.943 126.588 146.669 127.508 145.895 127.858C139.986 130.531 115.256 140.451 73.6464 140.451C25.5578 140.451 9.2887 137.4 9.2887 133.637C9.2887 129.873 129.865 95.4008 129.865 95.4008L146.535 125.843Z"
        fill="#27272A"
        fillOpacity="0.04"
      />
      <path
        d="M22.1603 120.387L37.3033 96.9151H131.19L145.576 120.387H22.1603Z"
        fill="#E4E4E7"
      />
      <path
        d="M35.1654 108.287L40.7103 98.4294H127.025L132.57 108.287C133.138 109.297 132.409 110.544 131.25 110.544H36.4852C35.327 110.544 34.5976 109.297 35.1654 108.287Z"
        fill="#D4D4D8"
      />
      <path
        d="M71.3006 115.355L72.4246 112.058H95.3112L96.4352 115.355C96.7701 116.338 96.0398 117.358 95.0019 117.358H72.7339C71.696 117.358 70.9657 116.338 71.3006 115.355Z"
        fill="#D4D4D8"
      />
      <path
        opacity="0.1"
        d="M124.943 109.787C124.943 115.641 106.468 120.387 83.6786 120.387C60.8888 120.387 49.9855 120.387 49.9855 120.387C49.9855 120.387 60.8888 99.1866 83.6786 99.1866C106.468 99.1866 124.943 103.932 124.943 109.787Z"
        fill="black"
      />
      <path
        d="M22.1603 120.387H145.576C145.576 122.896 143.542 124.93 141.033 124.93H26.7032C24.1942 124.93 22.1603 122.896 22.1603 120.387Z"
        fill="#D4D4D8"
      />
      <path
        d="M37.3032 40.1289C37.3032 39.2926 37.9812 38.6146 38.8175 38.6146H129.675C130.512 38.6146 131.19 39.2926 131.19 40.1289V96.9151H37.3032V40.1289Z"
        fill="#A1A1AA"
      />
      <path
        d="M40.3318 43.9147C40.3318 43.0783 41.0098 42.4004 41.8461 42.4004H126.647C127.483 42.4004 128.161 43.0783 128.161 43.9147V93.8865H40.3318V43.9147Z"
        fill="#DBEAFE"
      />
      <rect
        x="81.9751"
        y="40.1289"
        width="5.30005"
        height="0.757149"
        rx="0.378575"
        fill="#D4D4D8"
      />
      <path
        d="M10.4244 134.394H20.646L12.6959 102.972H9.53331C8.3807 102.972 7.24804 103.273 6.24729 103.845C5.5057 104.269 4.85308 104.832 4.32538 105.504L2.66349 107.619C1.55996 109.023 0.960083 110.758 0.960083 112.544C0.960083 113.72 1.22025 114.881 1.72193 115.945L10.4244 134.394Z"
        fill="#FDFBEE"
      />
      <path
        d="M73.2678 117.737L72.0679 123.256C71.7116 124.895 72.96 126.444 74.6371 126.444C75.2218 126.444 75.7898 126.639 76.2513 126.998L78.1386 128.466C78.6588 128.87 79.0116 129.452 79.1294 130.101C79.4284 131.745 78.1653 133.258 76.4943 133.258H70.8191C67.9269 133.258 65.2987 131.577 64.0867 128.951L62.6581 125.855C61.9155 124.246 61.5166 122.5 61.487 120.728L61.0562 94.9678C50.3864 85.2127 46.6337 78.6985 44.991 68.194C44.6121 65.7715 45.4721 63.3352 47.1652 61.5615L51.0795 57.4608C51.8529 56.6506 53.1171 56.5658 53.9917 57.2655C54.2209 57.4488 54.4103 57.6769 54.5483 57.9358L55.5839 59.8775C56.7483 62.0606 58.5182 63.8612 60.6811 65.0628L62.1141 65.8589C65.6642 67.8312 69.7669 68.5739 73.783 67.9715L82.7321 66.6291V76.6386C82.7321 80.2955 82.2225 83.9345 81.2178 87.4508L80.3486 90.0585C79.9272 91.3227 79.1561 92.4415 78.1248 93.2854L77.2972 93.9625C76.1845 94.8728 75.5392 96.2346 75.5392 97.6723V99.2708C75.5392 105.001 74.8887 110.713 73.6002 116.296L73.2678 117.737Z"
        fill="#FDFBEE"
      />
      <path
        d="M92.1966 46.9433C92.1966 58.6518 84.2193 69.2792 72.5107 69.2792C60.8022 69.2792 49.7963 58.6518 49.7963 46.9433C49.7963 35.9646 61.9107 25.7431 68.3464 25.7431C80.055 25.7431 92.1966 35.2347 92.1966 46.9433Z"
        fill="#FDFBEE"
      />
      <path
        d="M55.6236 28.679L58.2928 32.6031C60.555 35.9289 61.0176 40.1611 59.5273 43.897L58.4149 46.6856C56.8005 50.7324 52.4369 52.956 48.214 51.8836C46.0862 51.3433 44.2421 50.017 43.0529 48.1716L40.5998 44.3649C39.5883 42.7952 38.3234 41.4042 36.8566 40.2486C34.1846 38.1435 32.2108 35.2804 31.194 32.0343L30.4666 29.7122C29.7476 27.417 29.8617 24.9417 30.7886 22.7223L31.554 20.8899C32.3101 19.0797 33.8737 17.7305 35.7751 17.2477C37.2717 16.8676 38.8561 17.0561 40.2218 17.7767L48.0462 21.9049C51.0877 23.5097 53.6895 25.8356 55.6236 28.679Z"
        fill="#3F3F46"
      />
      <path
        d="M49.9393 26.9051L52.0597 30.2532C53.7346 32.8978 54.078 36.173 52.9881 39.1074L51.8846 42.0783C50.7024 45.2613 47.2862 47.0158 44.0103 46.1224C42.3787 45.6774 40.982 44.6193 40.1119 43.1691L38.1018 39.819C37.3225 38.5201 36.3349 37.3582 35.1786 36.3798C33.1077 34.6275 31.5984 32.3044 30.8388 29.7002L30.1775 27.4328C29.6431 25.6005 29.7284 23.6431 30.4201 21.8643L31.1427 20.0062C31.6841 18.6141 32.8534 17.5612 34.2944 17.1682C35.4943 16.8409 36.775 17.0014 37.8571 17.6146L43.5992 20.8684C46.1766 22.3289 48.3542 24.4024 49.9393 26.9051Z"
        fill="#FCA5A5"
      />
      <path
        d="M74.1531 24.3805L73.5734 29.0909C73.0821 33.083 74.6058 37.0584 77.6394 39.6995L79.9038 41.6709C83.1898 44.5318 88.0872 44.5141 91.3526 41.6297C92.9979 40.1762 94.0315 38.1534 94.2453 35.9685L94.6865 31.4614C94.8684 29.6029 95.3573 27.7875 96.1335 26.0891C97.5473 22.9953 97.9935 19.5464 97.4136 16.1946L96.9988 13.7969C96.5888 11.4269 95.3556 9.27763 93.5164 7.72765L91.998 6.44798C90.4979 5.18373 88.4904 4.69877 86.5785 5.13876C85.0738 5.48506 83.7509 6.37711 82.8658 7.64235L77.7947 14.8914C75.8235 17.7092 74.5731 20.9674 74.1531 24.3805Z"
        fill="#3F3F46"
      />
      <path
        d="M78.3973 20.2039L78.0423 24.151C77.7619 27.2688 78.954 30.3386 81.2651 32.45L83.6048 34.5876C86.1116 36.8778 89.952 36.8762 92.4569 34.5838C93.7045 33.442 94.4629 31.8624 94.5737 30.1748L94.8295 26.2764C94.9288 24.7649 95.2758 23.28 95.8568 21.8811C96.8974 19.3759 97.1775 16.6198 96.6623 13.9564L96.2137 11.6375C95.8513 9.76363 94.8804 8.0618 93.4519 6.79609L91.9596 5.47398C90.8417 4.48347 89.3203 4.08168 87.8591 4.39103C86.6423 4.64863 85.5767 5.3769 84.8947 6.41701L81.2757 11.9362C79.6513 14.4136 78.6627 17.2533 78.3973 20.2039Z"
        fill="#FCA5A5"
      />
      <path
        d="M66.8313 48.079C61.229 41.3516 60.6467 36.7074 63.0456 27.2574C35.7889 40.8861 52.4463 77.9864 78.9458 69.6577L78.1886 67.3863C74.5889 68.3856 68.725 68.9006 66.0742 67.3863C59.5221 63.6434 56.9892 54.1362 66.8313 48.079Z"
        fill="#3F3F46"
      />
      <circle cx="58.125" cy="50.729" r="3.78575" fill="#FEFCE8" />
      <circle cx="57.7464" cy="50.3504" r="3.40717" fill="#18181B" />
      <circle cx="58.882" cy="49.2147" r="0.757149" fill="#F4F4F5" />
      <path
        d="M75.2479 45.5822C76.6393 36.9388 74.7733 32.6463 67.893 25.7384C98.29 23.5723 103.072 62.8467 78.9464 69.6577L75.3168 67.9821C78.9231 67.0072 84.2308 64.4617 85.7397 61.8078C89.4692 55.2481 86.803 45.7774 75.2479 45.5822Z"
        fill="#3F3F46"
      />
      <circle
        r="3.78575"
        transform="matrix(-0.860372 0.509666 0.509666 0.860372 84.0894 43.4249)"
        fill="#FEFCE8"
      />
      <circle
        r="3.40717"
        transform="matrix(-0.860372 0.509666 0.509666 0.860372 84.2222 42.9062)"
        fill="#18181B"
      />
      <circle
        r="0.757149"
        transform="matrix(-0.860372 0.509666 0.509666 0.860372 85.6626 41.9234)"
        fill="#F4F4F5"
      />
      <path
        d="M74.4037 56.7862C71.4765 57.1766 64.1719 50.3776 72.8892 49.2147C81.6066 48.0518 77.3308 56.3958 74.4037 56.7862Z"
        fill="#18181B"
      />
      <path
        d="M64.4972 60.362C64.3813 60.188 64.1462 60.141 63.9723 60.257C63.7983 60.373 63.7513 60.608 63.8673 60.782L64.4972 60.362ZM74.0398 55.9251C73.2979 58.5214 71.7305 60.98 69.9442 62.0965C69.0609 62.6485 68.1486 62.8592 67.2553 62.6359C66.358 62.4115 65.4106 61.732 64.4972 60.362L63.8673 60.782C64.8468 62.2513 65.9342 63.086 67.0717 63.3704C68.2133 63.6558 69.3358 63.3696 70.3454 62.7386C72.3449 61.4889 73.9953 58.8368 74.7678 56.1331L74.0398 55.9251Z"
        fill="#18181B"
      />
      <path
        d="M84.832 55.5329C84.8618 55.3259 85.0537 55.1823 85.2607 55.2122C85.4676 55.242 85.6112 55.4339 85.5814 55.6409L84.832 55.5329ZM74.3089 55.6343C76.0958 57.6587 78.5687 59.2035 80.6616 59.4426C81.6965 59.5608 82.6107 59.3583 83.3209 58.7723C84.0343 58.1837 84.5971 57.1626 84.832 55.5329L85.5814 55.6409C85.3295 57.3887 84.7071 58.6102 83.8027 59.3564C82.895 60.1053 81.7586 60.3299 80.5757 60.1948C78.2331 59.9272 75.602 58.2435 73.7412 56.1354L74.3089 55.6343Z"
        fill="#18181B"
      />
      <path
        d="M10.4244 123.037C0.358706 95.4568 21.7568 85.3231 45.2533 64.3577C46.8288 78.9091 55.4748 90.4794 60.3963 93.5079C65.3178 96.5365 61.9106 111.301 61.9106 111.301L58.1249 118.872C59.5907 110.063 58.895 106.889 53.2034 106.379C39.5747 108.651 31.6482 119.792 33.5175 135.908C24.5255 136.514 20.3803 136.105 16.8602 132.123C6.81801 131.356 3.63075 129.314 3.61011 121.901L10.4244 123.037Z"
        fill="#3F3F46"
      />
      <path
        d="M34.6441 136.287H63.7804C63.9777 132.263 62.9724 131.134 58.3519 128.715L46.7303 130.228C47.0111 130.191 52.6512 129.425 55.2499 127.201C57.222 125.513 58.4423 122.129 59.0484 120.002C59.4025 118.758 59.5152 117.464 59.5152 116.172V114.195C59.5152 112.538 59.1527 110.901 58.4533 109.399L58.0016 108.429C57.7205 107.825 57.3322 107.277 56.8555 106.811C55.8261 105.806 54.4445 105.244 53.0059 105.244H52.1307C50.8692 105.244 49.6272 105.555 48.5151 106.151L41.6376 109.833C39.3688 111.048 37.4091 112.767 35.9095 114.859C33.8617 117.715 32.7604 121.141 32.7604 124.656V134.402C32.7604 135.443 33.6029 136.287 34.6441 136.287Z"
        fill="#FDFBEE"
      />
    </svg>
  );
}

export default GusLaptop;
