import { Col, Modal as AntModal, Row } from 'antd';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import {
  getMonthDropDownOptions,
  getYearDropdownOptions
} from '../../../helpers/generators';
import { useDrawer } from '../../../hooks/useDrawer';
import { CompanyService } from '../../../services';
import { Paragraph, Select, Title } from '../../../stories/atoms';
import { setReportingDetailsValidation } from '../../Onboarding/ReportingDetails/reportingDetailsValidation';
import { UserCompanyDetailsFields } from './UserCompanyDetailsFields';
import { UserCompanyDetailsLogo } from './UserCompanyDetailsLogo';
import { UserCompanyDetailsReporting } from './UserCompanyDetailsReporting';
import { UserCompanySubscription } from './UserCompanyDetailsSubscription';

const monthDropdownOptions = getMonthDropDownOptions();

function ReportingDetailsFields() {
  const { values, setFieldValue, setValues } = useFormikContext();
  const { me } = useMeContext();

  const [yearDropdownOptions, setYearDropdownOptions] = useState([]);

  function handleSetYearDropdownOptions(startingMonth = 1) {
    const today = new Date();
    const years = getYearDropdownOptions();
    setYearDropdownOptions(
      startingMonth
        ? years.filter(
            (y) =>
              y.value !== today.getFullYear() ||
              startingMonth <= today.getMonth() + 1
          )
        : years
    );
  }
  const reportingStartYearDropdownOptions = useCallback(() => {
    const minFootprintYear = Math.min(...(values?.footprintRange ?? []));
    const years = [...getYearDropdownOptions().reverse()];
    const reportingStartYearOptions = [
      ...years.slice(years.findIndex((year) => year.value === minFootprintYear))
    ];
    return reportingStartYearOptions;
  }, [values.footprintRange]);

  useEffect(() => {
    handleSetYearDropdownOptions();
  }, [values.startingMonth, values.footprintRange]);
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Select
          name="startingMonth"
          title="Start of Fiscal Year"
          value={values?.startingMonth}
          onChange={(value) => {
            const { startingMonth } = values;
            if (!!startingMonth) {
              setValues((prevState) => ({
                ...prevState,
                footprintRange: [],
                reportingStartYear: undefined
              }));
            }
            setFieldValue('startingMonth', value);
            handleSetYearDropdownOptions(value);
            analytics.track('Carbon Footprint Month Selected', me, {
              level1: 'Office-Based Operations',
              level2: 'Set baseline period',
              level3: 'Carbon footprint month'
            });
          }}
          options={monthDropdownOptions}
          placeholder="e.g January"
        />
      </Col>
      <Col span={24}>
        <Select
          mode="multiple"
          name="footprintRange"
          title="Baseline Year"
          value={values?.footprintRange}
          onChange={(value) => {
            setFieldValue('footprintRange', value);
            analytics.track('Carbon Footprint Year Selected', me, {
              level1: 'Office-Based Operations',
              level2: 'Set baseline period',
              level3: 'Carbon footprint year'
            });
          }}
          options={yearDropdownOptions}
          disabled={!values?.startingMonth}
          placeholder="e.g 2019"
        />
      </Col>
      <Col span={24}>
        <Select
          name="reportingStartYear"
          title="First reporting year"
          value={values?.reportingStartYear}
          onChange={(value) => {
            setFieldValue('reportingStartYear', value);
            analytics.track(
              'Carbon Footprint Reporting Start Year Selected',
              me,
              {
                level1: 'Office-Based Operations',
                level2: 'Set baseline period',
                level3: 'Reporting start year'
              }
            );
          }}
          options={reportingStartYearDropdownOptions()}
          disabled={!values?.startingMonth || !values?.footprintRange?.length}
          placeholder="e.g 2020"
        />
      </Col>
    </Row>
  );
}

export function UserCompanyDetails() {
  const { me, updateCompany } = useMeContext();
  const [companyFormValues, setCompanyFormValues] = useState({
    name: me.company.name ?? ''
  });

  const { company, groupSettings } = me;
  const { groups } = company ?? {};
  const isGroupOwner =
    groupSettings &&
    groups?.length &&
    groups.find((grp) => grp.id === groupSettings.id)?.role === 'OWNER';
  const {
    Drawer: ReportingDetailsDrawer,
    handleShowDrawer,
    handleCloseDrawer
  } = useDrawer({
    width: '40%'
  });

  const showWarningModal = () => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Continue',
      cancelText: 'Cancel',
      title: <Title size="lg">Are you sure?</Title>,
      content: (
        <Paragraph size="rg">
          Editing your reporting details will change your baseline period. This
          could impact your targets and public facing commitments your company
          has made.
        </Paragraph>
      ),
      onOk: handleShowDrawer
    });
  };

  useEffect(() => {
    setCompanyFormValues({
      ...companyFormValues,
      headQuartersCountryName: company.headQuartersCountry?.name,
      industry: company.industry?.name,
      locationCount: company.locationCount,
      companySize: company.reportedEmployeeCount ?? 'N/A',
      startingMonth: company.startingMonth,
      footprintRange: company.footprintRange,
      reportingStartYear: company.reportingStartYear
    });
  }, [company]);
  const { startingMonth, footprintRange, reportingStartYear } =
    companyFormValues;

  return (
    <Formik enableReinitialize initialValues={companyFormValues}>
      <Form className="company-details-form">
        <Row gutter={[24, 32]}>
          <Col span={24}>
            <UserCompanyDetailsFields />
          </Col>
          {isGroupOwner && (
            <Col span={24}>
              <UserCompanyDetailsLogo />
            </Col>
          )}
          <Col span={24}>
            <UserCompanyDetailsReporting
              showWarningModal={showWarningModal}
              startingMonth={startingMonth}
              footprintRange={footprintRange}
              reportingStartYear={reportingStartYear}
            />
          </Col>
          <Col span={24} className="mt-5">
            <UserCompanySubscription company={company} />
          </Col>
        </Row>
        <ReportingDetailsDrawer
          onCancel={handleCloseDrawer}
          buttons={[
            {
              htmlType: 'submit',
              children: 'Save'
            }
          ]}
          title="Edit your reporting details"
          formikContext={{
            validationSchema: setReportingDetailsValidation,
            className: 'actions-form',
            onSubmit: async (values) => {
              await CompanyService.updateCompany({
                ...values,
                slug: me.company.slug
              });
              setCompanyFormValues((prevValues) => ({
                ...prevValues,
                startingMonth: values.startingMonth,
                footprintRange: values.footprintRange,
                reportingStartYear: values.reportingStartYear
              }));
              updateCompany({
                footprintRange: values?.footprintRange
              });
              handleCloseDrawer();
            },
            initialValues: {
              startingMonth: companyFormValues?.startingMonth,
              footprintRange: companyFormValues?.footprintRange,
              reportingStartYear: companyFormValues?.reportingStartYear
            }
          }}
        >
          <ReportingDetailsFields />
        </ReportingDetailsDrawer>
      </Form>
    </Formik>
  );
}
