import { Col, Row } from 'antd';
import gusMaintenanceNotFound from '../../stories/assets/svgs/Gus/gusMaintenanceNotFound.svg';
import { Img, Paragraph, Title } from '../../stories/atoms';
import './Maintenance.scss';

export function Maintenance() {
  return (
    <Row
      align="middle"
      justify="center"
      className="ch-maintenance flex-direction-column"
    >
      <Col>
        <Img
          src={gusMaintenanceNotFound}
          alt="An illustration of Gus, the dog, holding a disconnected wire"
        />
      </Col>
      <Col>
        <Title align="center" bottomSpacing={8} size="lg">
          We’re under scheduled maintenance
        </Title>
        <Paragraph align="center" bottomSpacing={0}>
          Our application will be back up shortly, please check back in later.
        </Paragraph>
      </Col>
    </Row>
  );
}
