import { formatSingleEmission } from '../helpers/generators';
import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class InitiativeService {
  static async listInitiatives(
    { companySlug, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE_COMPANY.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await AxiosInstance.post(
        API_URL,
        { pagination },
        {
          params
        }
      );

      onSuccess?.({
        list: response.data?.initiatives,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.initiatives,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async createInitiative(
    { companySlug, initiativeTemplateId, ...initiative },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE_COMPANY.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.post(API_URL, {
        ...initiative,
        initiativeTemplateId
      });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async editInitiative(
    { companyInitiativeId, ...initiative },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE}/${companyInitiativeId}`;
      const response = await AxiosInstance.put(API_URL, initiative);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async deleteInitiative(companyInitiativeId) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE}/${companyInitiativeId}`;
      await AxiosInstance.delete(API_URL);

      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  }

  static async getInitiative(companyInitiativeId, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.INITIATIVE}/${companyInitiativeId}`;
      const response = await AxiosInstance.get(API_URL);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async fetchEmissionsSum(
    { companyInitiativeId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/emissions/sum`;
      const response = await AxiosInstance.get(API_URL, { params });
      onSuccess?.(response.data?.emissions);
      return response.data?.emissions;
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async listInitiativeImpactedSources(
    { companyInitiativeId, pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/emissionSource/list`;
      const response = await AxiosInstance.post(API_URL, pagination, {
        params
      });

      onSuccess?.({
        list: response.data?.emissionSources,
        count: response.data?.totalRowCount
      });
      return {
        list: response.data?.emissionSources,
        count: response.data?.totalRowCount
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async deleteRelatedEmissionSource(
    { companyInitiativeId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/emissionSource`;
      await AxiosInstance.delete(API_URL, { data: { ...params } });
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async addRelatedEmissionSourceBulk(
    { companyInitiativeId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/emissionSource/bulk`;
      await AxiosInstance.post(API_URL, params);
      onSuccess?.();
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getCompanyInitiativeOverview(
    { companyInitiativeId, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.COMPANY_INITIATIVE.replace(
        ':companyInitiativeId',
        companyInitiativeId
      )}/overview/all`;
      const response = await AxiosInstance.get(API_URL, { params });
      if (response.data) {
        response.data.reductions = formatSingleEmission(
          response.data?.reductions
        );
        onSuccess?.(response.data);
        return response.data;
      }
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default InitiativeService;
