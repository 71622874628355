import React from 'react';
import { useLocation } from 'react-router';
import { Link, Outlet } from 'react-router-dom';
import {
  LayoutContextProvider,
  OfficeOnboardingContextProvider
} from '../../../../context';
import { AppRoutes } from '../../../../routes';
import { Button } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { OnboardingAdminLayoutSidebar } from './OnboardingAdminLayoutSidebar';

export function OnboardingAdminLayout() {
  const location = useLocation();
  const { pathname } = location;
  const isReturnLinkVisible =
    pathname !== AppRoutes.ONBOARDING_ADMIN_INVITE_EMPLOYEES;
  return (
    <div className="trails-segments-forms">
      <LayoutContextProvider>
        <OfficeOnboardingContextProvider>
          <OnboardingAdminLayoutSidebar />
          <div className="footprint-segment-form">
            {isReturnLinkVisible && (
              <div className="justify-end display-flex mb-5">
                <Link to={AppRoutes.DASHBOARD}>
                  <Button type={BUTTON_TYPES.SECONDARY}>
                    Save & return to dashboard
                  </Button>
                </Link>
              </div>
            )}
            <Outlet />
          </div>
        </OfficeOnboardingContextProvider>
      </LayoutContextProvider>
    </div>
  );
}
