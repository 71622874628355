function GoldVerification() {
  return (
    <svg
      width="124"
      height="46"
      viewBox="0 0 124 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82035 45.7705L0.0117188 34.5128H3.45335L7.50611 44.2587H5.47973L9.62898 34.5128H12.7811L7.95641 45.7705H4.82035Z"
        fill="url(#paint0_linear_11105_57046)"
      />
      <path
        d="M16.5852 38.8711H21.812V41.2513H16.5852V38.8711ZM16.8104 43.3099H22.6965V45.7705H13.6582V34.5128H22.4874V36.9734H16.8104V43.3099Z"
        fill="url(#paint1_linear_11105_57046)"
      />
      <path
        d="M24.6817 45.7705V34.5128H29.828C30.8573 34.5128 31.7418 34.679 32.4816 35.0114C33.2321 35.3437 33.8111 35.8262 34.2185 36.4588C34.6259 37.0806 34.8296 37.8204 34.8296 38.6782C34.8296 39.5252 34.6259 40.2596 34.2185 40.8814C33.8111 41.4926 33.2321 41.9643 32.4816 42.2967C31.7418 42.6183 30.8573 42.7792 29.828 42.7792H26.4507L27.866 41.4443V45.7705H24.6817ZM31.6453 45.7705L28.847 41.6695H32.2404L35.0548 45.7705H31.6453ZM27.866 41.7821L26.4507 40.3186H29.635C30.2998 40.3186 30.793 40.1738 31.1146 39.8843C31.447 39.5949 31.6132 39.1928 31.6132 38.6782C31.6132 38.1528 31.447 37.7454 31.1146 37.4559C30.793 37.1664 30.2998 37.0217 29.635 37.0217H26.4507L27.866 35.5582V41.7821Z"
        fill="url(#paint2_linear_11105_57046)"
      />
      <path
        d="M36.8045 45.7705V34.5128H39.9888V45.7705H36.8045Z"
        fill="url(#paint3_linear_11105_57046)"
      />
      <path
        d="M45.4315 39.4501H50.6261V41.9107H45.4315V39.4501ZM45.6567 45.7705H42.4724V34.5128H51.3016V36.9734H45.6567V45.7705Z"
        fill="url(#paint4_linear_11105_57046)"
      />
      <path
        d="M53.009 45.7705V34.5128H56.1933V45.7705H53.009Z"
        fill="url(#paint5_linear_11105_57046)"
      />
      <path
        d="M64.2896 45.9956C63.3997 45.9956 62.5741 45.8563 61.8129 45.5775C61.0624 45.288 60.4084 44.8806 59.8509 44.3552C59.3041 43.8299 58.8752 43.2134 58.5643 42.5058C58.2533 41.7874 58.0979 40.9994 58.0979 40.1417C58.0979 39.2839 58.2533 38.5013 58.5643 37.7936C58.8752 37.0753 59.3041 36.4534 59.8509 35.9281C60.4084 35.4027 61.0624 35.0007 61.8129 34.7219C62.5741 34.4324 63.3997 34.2877 64.2896 34.2877C65.3296 34.2877 66.257 34.4699 67.0718 34.8345C67.8974 35.199 68.5836 35.7244 69.1304 36.4105L67.104 38.2439C66.7395 37.8151 66.3374 37.4881 65.8978 37.2629C65.469 37.0378 64.9865 36.9252 64.4504 36.9252C63.9894 36.9252 63.5659 37.0002 63.1799 37.1503C62.7939 37.3004 62.4616 37.5202 62.1828 37.8097C61.9148 38.0885 61.7003 38.4262 61.5395 38.8229C61.3894 39.2196 61.3144 39.6592 61.3144 40.1417C61.3144 40.6241 61.3894 41.0637 61.5395 41.4604C61.7003 41.8571 61.9148 42.2002 62.1828 42.4897C62.4616 42.7684 62.7939 42.9829 63.1799 43.133C63.5659 43.2831 63.9894 43.3581 64.4504 43.3581C64.9865 43.3581 65.469 43.2456 65.8978 43.0204C66.3374 42.7952 66.7395 42.4682 67.104 42.0394L69.1304 43.8728C68.5836 44.5482 67.8974 45.0736 67.0718 45.4488C66.257 45.8134 65.3296 45.9956 64.2896 45.9956Z"
        fill="url(#paint6_linear_11105_57046)"
      />
      <path
        d="M69.2289 45.7705L74.1983 34.5128H77.3344L82.3199 45.7705H79.007L75.115 36.0728H76.3694L72.4775 45.7705H69.2289ZM71.9468 43.5833L72.767 41.2353H78.2672L79.0874 43.5833H71.9468Z"
        fill="url(#paint7_linear_11105_57046)"
      />
      <path
        d="M85.2717 45.7705V37.0378H81.8139V34.5128H91.8976V37.0378H88.456V45.7705H85.2717Z"
        fill="url(#paint8_linear_11105_57046)"
      />
      <path
        d="M93.302 45.7705V34.5128H96.4863V45.7705H93.302Z"
        fill="url(#paint9_linear_11105_57046)"
      />
      <path
        d="M104.647 45.9956C103.746 45.9956 102.915 45.8509 102.154 45.5614C101.393 45.2719 100.728 44.8645 100.16 44.3392C99.6025 43.8031 99.1682 43.1812 98.8573 42.4736C98.5464 41.766 98.3909 40.9887 98.3909 40.1417C98.3909 39.2947 98.5464 38.5173 98.8573 37.8097C99.1682 37.1021 99.6025 36.4856 100.16 35.9602C100.728 35.4242 101.393 35.0114 102.154 34.7219C102.915 34.4324 103.746 34.2877 104.647 34.2877C105.558 34.2877 106.389 34.4324 107.14 34.7219C107.901 35.0114 108.56 35.4242 109.118 35.9602C109.675 36.4856 110.11 37.1021 110.421 37.8097C110.742 38.5173 110.903 39.2947 110.903 40.1417C110.903 40.9887 110.742 41.7713 110.421 42.4897C110.11 43.1973 109.675 43.8138 109.118 44.3392C108.56 44.8645 107.901 45.2719 107.14 45.5614C106.389 45.8509 105.558 45.9956 104.647 45.9956ZM104.647 43.3581C105.076 43.3581 105.473 43.2831 105.837 43.133C106.212 42.9829 106.534 42.7684 106.802 42.4897C107.081 42.2002 107.295 41.8571 107.445 41.4604C107.606 41.0637 107.687 40.6241 107.687 40.1417C107.687 39.6485 107.606 39.2089 107.445 38.8229C107.295 38.4262 107.081 38.0885 106.802 37.8097C106.534 37.5202 106.212 37.3004 105.837 37.1503C105.473 37.0002 105.076 36.9252 104.647 36.9252C104.218 36.9252 103.816 37.0002 103.441 37.1503C103.076 37.3004 102.755 37.5202 102.476 37.8097C102.208 38.0885 101.993 38.4262 101.833 38.8229C101.682 39.2089 101.607 39.6485 101.607 40.1417C101.607 40.6241 101.682 41.0637 101.833 41.4604C101.993 41.8571 102.208 42.2002 102.476 42.4897C102.755 42.7684 103.076 42.9829 103.441 43.133C103.816 43.2831 104.218 43.3581 104.647 43.3581Z"
        fill="url(#paint10_linear_11105_57046)"
      />
      <path
        d="M112.789 45.7705V34.5128H115.41L121.618 42.0072H120.38V34.5128H123.5V45.7705H120.878L114.671 38.2761H115.909V45.7705H112.789Z"
        fill="url(#paint11_linear_11105_57046)"
      />
      <path
        d="M32.3127 19.9165C30.8222 19.9165 29.4395 19.6831 28.1645 19.2162C26.8895 18.7313 25.7851 18.0489 24.8513 17.169C23.9354 16.289 23.2171 15.2565 22.6963 14.0713C22.1756 12.8681 21.9152 11.5482 21.9152 10.1116C21.9152 8.67495 22.1756 7.36404 22.6963 6.17883C23.2171 4.97566 23.9444 3.93411 24.8782 3.05418C25.812 2.17425 26.9164 1.50083 28.1914 1.03393C29.4844 0.54907 30.8941 0.306641 32.4205 0.306641C34.1983 0.306641 35.7875 0.602943 37.1882 1.19555C38.589 1.78816 39.7472 2.64115 40.6631 3.75453L37.2691 6.8253C36.6226 6.12495 35.9222 5.60418 35.168 5.26298C34.4317 4.90383 33.6057 4.72425 32.6898 4.72425C31.8817 4.72425 31.1455 4.84995 30.481 5.10136C29.8166 5.35277 29.2509 5.7209 28.784 6.20576C28.3171 6.67266 27.949 7.23833 27.6796 7.90277C27.4282 8.54925 27.3025 9.28552 27.3025 10.1116C27.3025 10.9017 27.4282 11.629 27.6796 12.2934C27.949 12.9579 28.3171 13.5325 28.784 14.0174C29.2509 14.4843 29.8076 14.8524 30.4541 15.1218C31.1185 15.3732 31.8458 15.4989 32.636 15.4989C33.4261 15.4989 34.1983 15.3732 34.9525 15.1218C35.7067 14.8524 36.4699 14.4035 37.2421 13.775L40.2321 17.5191C39.1546 18.2913 37.9066 18.8839 36.4879 19.297C35.0692 19.71 33.6775 19.9165 32.3127 19.9165ZM35.5182 16.8188V9.70752H40.2321V17.5191L35.5182 16.8188Z"
        fill="url(#paint12_linear_11105_57046)"
      />
      <path
        d="M53.3402 19.9165C51.8317 19.9165 50.44 19.6741 49.165 19.1892C47.89 18.7044 46.7766 18.022 45.8249 17.142C44.8911 16.2441 44.1638 15.2026 43.643 14.0174C43.1222 12.8322 42.8619 11.5302 42.8619 10.1116C42.8619 8.69291 43.1222 7.39097 43.643 6.20576C44.1638 5.02055 44.8911 3.98798 45.8249 3.10805C46.7766 2.21016 47.89 1.51879 49.165 1.03393C50.44 0.54907 51.8317 0.306641 53.3402 0.306641C54.8666 0.306641 56.2583 0.54907 57.5154 1.03393C58.7904 1.51879 59.8948 2.21016 60.8286 3.10805C61.7624 3.98798 62.4897 5.02055 63.0104 6.20576C63.5492 7.39097 63.8185 8.69291 63.8185 10.1116C63.8185 11.5302 63.5492 12.8412 63.0104 14.0443C62.4897 15.2295 61.7624 16.2621 60.8286 17.142C59.8948 18.022 58.7904 18.7044 57.5154 19.1892C56.2583 19.6741 54.8666 19.9165 53.3402 19.9165ZM53.3402 15.4989C54.0585 15.4989 54.7229 15.3732 55.3335 15.1218C55.962 14.8704 56.5008 14.5112 56.9497 14.0443C57.4166 13.5595 57.7758 12.9848 58.0272 12.3204C58.2965 11.6559 58.4312 10.9197 58.4312 10.1116C58.4312 9.28552 58.2965 8.54925 58.0272 7.90277C57.7758 7.23833 57.4166 6.67266 56.9497 6.20576C56.5008 5.7209 55.962 5.35277 55.3335 5.10136C54.7229 4.84995 54.0585 4.72425 53.3402 4.72425C52.6219 4.72425 51.9485 4.84995 51.32 5.10136C50.7094 5.35277 50.1707 5.7209 49.7038 6.20576C49.2548 6.67266 48.8957 7.23833 48.6263 7.90277C48.3749 8.54925 48.2492 9.28552 48.2492 10.1116C48.2492 10.9197 48.3749 11.6559 48.6263 12.3204C48.8957 12.9848 49.2548 13.5595 49.7038 14.0443C50.1707 14.5112 50.7094 14.8704 51.32 15.1218C51.9485 15.3732 52.6219 15.4989 53.3402 15.4989Z"
        fill="url(#paint13_linear_11105_57046)"
      />
      <path
        d="M66.8301 19.5394V0.683753H72.1635V15.3103H81.1603V19.5394H66.8301Z"
        fill="url(#paint14_linear_11105_57046)"
      />
      <path
        d="M83.489 19.5394V0.683753H92.405C94.4881 0.683753 96.3198 1.06985 97.9001 1.84203C99.4803 2.61421 100.71 3.70066 101.59 5.10136C102.488 6.50207 102.937 8.17214 102.937 10.1116C102.937 12.0331 102.488 13.7031 101.59 15.1218C100.71 16.5225 99.4803 17.6089 97.9001 18.3811C96.3198 19.1533 94.4881 19.5394 92.405 19.5394H83.489ZM88.8224 15.2834H92.1895C93.267 15.2834 94.2008 15.0859 94.9909 14.6908C95.799 14.2778 96.4275 13.6852 96.8765 12.913C97.3254 12.1228 97.5499 11.189 97.5499 10.1116C97.5499 9.01615 97.3254 8.08235 96.8765 7.31016C96.4275 6.53798 95.799 5.95435 94.9909 5.55928C94.2008 5.14625 93.267 4.93974 92.1895 4.93974H88.8224V15.2834Z"
        fill="url(#paint15_linear_11105_57046)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_11105_57046"
          x1="10.3024"
          y1="45.9956"
          x2="64.5393"
          y2="-35.9844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default GoldVerification;
