/** TargetContext is responsible for storing the Global State
 * for frequently used metadata.
 * */

import React, { createContext, useContext, useMemo, useState } from 'react';
import { TargetService } from '../services';
import { useMeContext } from './MeContext';

// Create the context
const TargetContext = createContext({});

// Define the default context state
const defaultTargetState = {
  targetDetail: {},
  targetDetailId: undefined
};

// Create method to use context
function useTargetContext() {
  const context = useContext(TargetContext);
  const { me } = useMeContext();
  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useTargetContext must be used within a TargetContextProvider'
    );
  }
  const [targetState, setTargetState] = context;

  // Context Methods //

  const createTarget = async (params) => {
    const { data: newTarget } = await TargetService.createTargetDetails({
      companySlug: me.company?.slug,
      ...params
    });

    setTargetState({
      ...targetState,
      targetDetail: newTarget,
      targetDetailId: newTarget?.id
    });
    return newTarget;
  };
  const updateTarget = async (params) => {
    const { data: newTarget } = await TargetService.updateTargetDetails({
      companySlug: me.company?.slug,
      ...params
    });

    setTargetState({
      ...targetState,
      targetDetail: newTarget,
      targetDetailId: newTarget?.id
    });
    return newTarget;
  };
  const getTargetList = async (params) => {
    const { data: targetDetailsList } = await TargetService.listTargetDetails({
      companySlug: me.company?.slug,
      ...params
    });
    return targetDetailsList ?? [];
  };
  const getTargetFromList = async (params) => {
    const { data: targetDetailsList } = await TargetService.listTargetDetails({
      companySlug: me.company?.slug,
      ...params
    });

    setTargetState({
      ...targetState,
      targetDetail: targetDetailsList[0] ?? {},
      targetDetailId: targetDetailsList[0]?.id
    });
    return targetDetailsList[0] ?? {};
  };
  // Return state and Context Methods
  // Note: DO NOT return "setstate".State updates should be managed through context methods
  return {
    targetState,
    createTarget,
    getTargetList,
    getTargetFromList,
    updateTarget
  };
}

// Create the context provider
function TargetContextProvider(props) {
  const [TargetState, setTargetState] = useState(defaultTargetState);
  const value = useMemo(() => [TargetState, setTargetState], [TargetState]);
  return <TargetContext.Provider value={value} {...props} />;
}

export { TargetContextProvider, useTargetContext };
