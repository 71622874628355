import { eachYearOfInterval, isSameMonth, parseISO, subYears } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  BulkUploadType,
  CollectionType,
  CountryCode,
  DistanceConversionFactor,
  NotificationCategory,
  NotificationCategoryLabelMapping,
  StepStatus,
  TransportationType
} from '../constants';
import DateFormats from '../constants/dateFormats';
import { formatDecimal } from './formatters';

export function getRandomInt(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

export function getFullName(user) {
  if (!user) return '--';
  if (user.displayName) {
    return user.displayName;
  }
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user.firstName) {
    return user.firstName;
  }
  return user.email;
}

export function getEmissionSourceDetailsName(emissionSourceDetails) {
  if (!emissionSourceDetails) return '--';
  if (emissionSourceDetails.displayName) {
    return emissionSourceDetails.displayName;
  }
  if (emissionSourceDetails.internalDetails?.commuteVehicleName) {
    return emissionSourceDetails.internalDetails.commuteVehicleName;
  }
  return conditionalCapitalizeText(
    emissionSourceDetails.name,
    emissionSourceDetails.isInternal
  );
}

export function getDropdownOptions(inputArray, labelKey, valueKey) {
  return inputArray.map((arrayItem) => ({
    label: arrayItem[labelKey],
    value: arrayItem[valueKey]
  }));
}

export function getAirportDropdownOptions(inputArray = []) {
  return inputArray.map((arrayItem) => {
    const currentIATA = arrayItem.IATA.replace(/\\N/g, '');
    return {
      label: `${arrayItem.name}${!!currentIATA ? ` (${currentIATA})` : ''}`,
      value: arrayItem.ICAO
    };
  });
}

export function getAddressValues(inputAddress, countries) {
  const address = {
    address1: [],
    address2: []
  };
  const addressComponents = inputAddress.address_components;
  const address2Keys = ['neighborhood', 'route', 'landmark', 'street_number'];

  addressComponents.map((component) => {
    component.types.map((type) => {
      if (type.includes('subpremise')) {
        address.address1.push(`${component.long_name}/`);
      } else if (type.includes('sublocality_level')) {
        address.address2.push(component.long_name);
      } else if (address2Keys.includes(type)) {
        address.address1.push(`${component.long_name}, `);
      } else if (type === 'country') {
        address.country = component.long_name;
      } else if (type === 'postal_code') {
        address.postalCode = component.long_name;
      } else if (type === 'administrative_area_level_1') {
        address.state = component.long_name;
      } else if (type === 'administrative_area_level_2') {
        address.district = component.long_name;
      } else if (
        type === 'locality' ||
        type === 'administrative_area_level_3'
      ) {
        address.city = component.long_name;
      }
    });
  });
  address.formattedAddress = inputAddress.formatted_address;
  // Join all address1 and remove trailing comma
  address.address1 = address.address1.join('').replace(/,\s*$/, '');
  address.address2 = address.address2.join(', ');
  if (address.country) {
    const country = countries.find(
      (countryItem) => countryItem.name === address.country
    );
    if (country) {
      address.countryId = country.id;
      address.isEstimationSupported = country.isEstimationSupported;
    } else {
      address.countryName = address.country;
    }
  }

  return address;
}

export function getDetailsFromAddress(companyLocation) {
  const result = [];
  companyLocation?.city && result.push(companyLocation.city);
  companyLocation?.state && result.push(companyLocation.state);
  companyLocation?.country?.name && result.push(companyLocation.country.name);
  companyLocation?.postalCode && result.push(companyLocation.postalCode);
  return result.join(', ');
}

export function conditionalCapitalizeText(string, condition) {
  return condition ? capitalizeText(string) : string;
}

export function capitalizeText(string) {
  // Special Cases
  if (string === TransportationType.STREETCAR_LRT) return 'Streetcar/LRT';
  if (string === 'WFH') return 'Work From Home';

  return string
    ? string.toLowerCase().replace(/\w/, (c) => c.toUpperCase())
    : undefined;
}

export function roundTo2DecimalPlaces(number) {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
}

export function getTrailStatus(trail) {
  if (trail?.isComplete) {
    return StepStatus.COMPLETE;
  }
  if (trail?.isStarted) {
    return StepStatus.STARTED;
  }
  return StepStatus.INCOMPLETE;
}

// Get distance using the directionService Matrix
export async function getDistance(
  directionsService,
  origin,
  destination,
  travelMode
) {
  const isValidOrigin = !!origin?.lat && !!origin?.lng;
  const isValidDestination = !!destination?.lat && !!destination?.lng;
  if ((!isValidOrigin && !isValidDestination) || !travelMode) return null;

  const { routes, status } = await directionsService.route({
    origin,
    destination,
    travelMode
  });

  if (status !== 'OK') return null;

  return formatDecimal(routes[0].legs[0].distance.value / 1000);
}

// The directionsService Matrix does not work for all travel types -> when unavailable, great circle distance is used
export async function getGreatCircleDistance(google, origin, destination) {
  let distance = 0;
  // computeDistanceBetween returns in meters
  try {
    distance = google.maps.geometry.spherical.computeDistanceBetween(
      origin,
      destination
    );
  } catch (err) {
    // Log if error occurs
    console.error(err);
  }
  return formatDecimal(distance / 1000);
}

// Chrome doesn't respect autoComplete="off" - this is ugly but solves it for all browsers
export const disableAutocomplete = (e) => {
  if (e.target.autocomplete) e.target.autocomplete = 'none';
};

export const getYearDropdownOptions = () => {
  const years = eachYearOfInterval({
    start: new Date(2006, 1, 1),
    end: new Date()
  }).reverse();
  return years.map((x) => {
    const year = formatInTimeZone(x, 'utc', 'yyyy');
    return {
      label: year,
      value: Number(year)
    };
  });
};

export const getCarbonYear = ({ startingMonth, year }) => {
  const today = new Date();
  const month = startingMonth ? startingMonth - 1 : 0;
  const isDefaultCurrentCarbonYear = !year;
  if (!year) year = today.getUTCFullYear();

  let rangeStart = new Date(year, month, 1);
  if (isDefaultCurrentCarbonYear && rangeStart > today) {
    rangeStart = subYears(rangeStart, 1);
  }

  let rangeEnd = new Date(
    rangeStart.getUTCFullYear() + 1,
    rangeStart.getUTCMonth(),
    0
  );

  // Check if the rangeEnd is in the future, and adjust
  const endOfCurrentCarbonYear = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  );
  if (rangeEnd > endOfCurrentCarbonYear) rangeEnd = endOfCurrentCarbonYear;

  rangeStart = formatInTimeZone(rangeStart, 'UTC', DateFormats.API);

  rangeEnd = formatInTimeZone(rangeEnd, 'UTC', DateFormats.API);
  return { rangeStart, rangeEnd };
};

export const formatNumbersAsIntOrFloat = ({ array, valueKey }) =>
  array.map((a) =>
    a[valueKey] < 1
      ? { ...a, [valueKey]: formatDecimal(a[valueKey], 3).toFixed(3) }
      : { ...a, [valueKey]: parseInt(a[valueKey]) }
  );

export const formatEmissions = ({ emissions, decimalPlaces = 3 }) =>
  emissions?.map((e) => {
    //  This is horrible and I know. will refactor.
    const emissionKey = e.emissions
      ? 'emissions'
      : e.emissionTotal
      ? 'emissionTotal'
      : e.totalEmissions
      ? 'totalEmissions'
      : e.scope1Emissions
      ? 'scope1Emissions'
      : e.scope2Emissions
      ? 'scope2Emissions'
      : 'scope3Emissions';
    const emissionConvertedValue = Number(e[emissionKey]) / 1000;
    return {
      ...e,
      emissions: formatDecimal(emissionConvertedValue, decimalPlaces)
    };
  });

export const getUnitFriendlyName = (unit) => {
  if (!unit) return '';
  return `${capitalizeText(unit.name)} (${unit?.unit
    ?.replace(/ *\([^)]*\) */g, '')
    .trim()
    .toUpperCase()})`;
};

export const getEmissionFactor = (value, ad = 1500, gwp = 1) =>
  ad * gwp * value;

export const getDistanceUnit = (isoName) => {
  switch (isoName) {
    case CountryCode.USA: {
      return 'mi';
    }
    case CountryCode.CAN:
    default: {
      return 'Km';
    }
  }
};

export const getTimeFormat = (isoName) => {
  switch (isoName) {
    case CountryCode.CAN:
    case CountryCode.USA:
    default: {
      return DateFormats.NORTH_AMERICA;
    }
  }
};

export const getRegionalValue = (value, isoName) => {
  const conversionFactor = DistanceConversionFactor[isoName];
  return value * conversionFactor;
};

export const getFormattedAddress = ({
  location,
  addressLevel = 'STREET',
  name,
  defaultReturnString = ''
}) => {
  if (name) return name;
  if (!location) return defaultReturnString;
  switch (addressLevel) {
    case 'STREET':
    default: {
      let address = `${location.address1} `;
      if (location.address2) {
        address += `, ${location.address2} `;
      }
      return address;
    }
  }
};

export const formatSingleEmission = (emission) => emission / 1000 ?? 0;

export const getDefaultYearForChart = ({ startingMonth }) => {
  const today = new Date();
  const month = startingMonth ? startingMonth - 1 : 0;

  return new Date(
    today.getUTCFullYear() - (today.getUTCMonth() < month ? 1 : 0),
    month,
    1
  ).getUTCFullYear();
};

export const getActionCategoryCopy = (category) =>
  NotificationCategoryLabelMapping[NotificationCategory[category]] || '--';

export const getMonthName = (monthNumber) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months[monthNumber - 1];
};
export const getQuarterName = (startingMonth, date) => {
  const dateMonth = new Date(date).getUTCMonth();
  const dateDiff = startingMonth - 1 - dateMonth;
  // Fiscal Years Starting Month >= 10 end up with a 9, 6, 3 pattern that needs reversed to be accurate
  if (startingMonth >= 10) {
    const shiftIndex = dateDiff === 0 ? 12 : Math.abs(dateDiff);
    return Math.floor((12 - shiftIndex) / 3 + 1);
    // Fiscal Years Starting Month <= 2 end up with a 3, 6, 9 pattern which can be used directly. However, for months between [3-9]
    // the pattern is -3, +/-6, 3, so the Q2/Q3 cases can be handled directly, but Q4 ends up resolving to 2 and needs an explicit case to handle
  }
  if (startingMonth <= 2 || dateDiff < 0 || Math.floor(dateDiff / 3) !== 1) {
    return Math.floor(Math.abs(dateDiff) / 3 + 1);
  }
  return 4;
};
export const getMonthDropDownOptions = (startMonth = 0, endMonth = 11) => {
  const monthDropDownOptions = [];

  for (let i = startMonth; i <= endMonth; i++) {
    const monthNum = i + 1;
    monthDropDownOptions.push({
      value: monthNum,
      label: getMonthName(monthNum)
    });
  }

  return monthDropDownOptions;
};

export const getCollectionBusinessUnit = (source) =>
  source?.collections?.find?.((cl) => cl.type === CollectionType.BUSINESS_UNIT);
export const getCollectionLocation = (source) =>
  source?.collections?.find?.((cl) => cl.type === CollectionType.LOCATION);

export const getDataImportFormatting = ({ record, companySlug }) => ({
  title: record.name,
  category: record.category,
  uploadType: record.uploadType,
  companySlug,
  ...((record.uploadType === BulkUploadType.ENERGY ||
    record.uploadType === BulkUploadType.CUSTOM) && {
    emissionSourceDetailsId: record.id
  }),
  ...(record.uploadType === BulkUploadType.ENERGY && {
    energyDetailsId: record?.internalDetails?.energyDetailsId,
    collectionId: getCollectionLocation(record)?.id
  }),
  ...(record.uploadType === BulkUploadType.TRAVEL && {
    travelCategoryId: record.id
  }),
  ...(record.uploadType === BulkUploadType.CUSTOM && {
    dateType: record.dateType
  })
});

export const getKeyByValue = (
  listOfValues,
  initialList,
  matchKey,
  returnKey
) => {
  if (!Array.isArray(listOfValues)) {
    const obj = initialList?.find((elem) => elem[matchKey] === listOfValues);
    return obj[returnKey];
  }

  const newArray = listOfValues.map((value) => {
    const obj = initialList?.find((elem) => elem[matchKey] === value);
    return obj[returnKey];
  });
  return newArray;
};

export function getIsCountryEstimationsSupported({
  countries = [],
  countryName
}) {
  return countries.find((countryItem) => countryItem.name === countryName)
    ?.isEstimationSupported;
}

export function generateItemFieldArray(listResponse, items) {
  listResponse.forEach(({ year, values: months }) => {
    months.forEach((month) => {
      const prevYear = year - 1;
      const monthToUpdateIdx = items[year]?.findIndex((itemMonth) =>
        isSameMonth(parseISO(month.date), itemMonth.date)
      );

      const prevMonthToUpdateIdx = !!items[prevYear]
        ? items[prevYear].findIndex((itemMonth) =>
            isSameMonth(parseISO(month.date), itemMonth.date)
          )
        : -1;

      if (monthToUpdateIdx > -1) {
        items[year][monthToUpdateIdx] = {
          date: parseISO(month.date),
          value: month.value
        };
      } else if (prevMonthToUpdateIdx > -1) {
        items[prevYear][prevMonthToUpdateIdx] = {
          date: parseISO(month.date),
          value: month.value
        };
      }
    });
  });
}
