function SilverVerification() {
  return (
    <svg
      width="124"
      height="46"
      viewBox="0 0 124 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82037 45.7706L0.0117188 34.5129H3.45336L7.50613 44.2589H5.47975L9.62901 34.5129H12.7812L7.95644 45.7706H4.82037Z"
        fill="url(#paint0_linear_11105_57042)"
      />
      <path
        d="M16.5853 38.8713H21.8121V41.2515H16.5853V38.8713ZM16.8104 43.31H22.6966V45.7706H13.6583V34.5129H22.4875V36.9736H16.8104V43.31Z"
        fill="url(#paint1_linear_11105_57042)"
      />
      <path
        d="M24.6818 45.7706V34.5129H29.8281C30.8574 34.5129 31.7419 34.6791 32.4817 35.0115C33.2322 35.3439 33.8112 35.8263 34.2186 36.4589C34.6261 37.0808 34.8298 37.8206 34.8298 38.6783C34.8298 39.5253 34.6261 40.2597 34.2186 40.8816C33.8112 41.4927 33.2322 41.9645 32.4817 42.2968C31.7419 42.6185 30.8574 42.7793 29.8281 42.7793H26.4508L27.8661 41.4445V45.7706H24.6818ZM31.6454 45.7706L28.8471 41.6696H32.2405L35.0549 45.7706H31.6454ZM27.8661 41.7822L26.4508 40.3187H29.6351C30.2999 40.3187 30.7931 40.174 31.1147 39.8845C31.4471 39.595 31.6133 39.1929 31.6133 38.6783C31.6133 38.1529 31.4471 37.7455 31.1147 37.456C30.7931 37.1665 30.2999 37.0218 29.6351 37.0218H26.4508L27.8661 35.5583V41.7822Z"
        fill="url(#paint2_linear_11105_57042)"
      />
      <path
        d="M36.8046 45.7706V34.5129H39.9889V45.7706H36.8046Z"
        fill="url(#paint3_linear_11105_57042)"
      />
      <path
        d="M45.4317 39.4503H50.6263V41.9109H45.4317V39.4503ZM45.6569 45.7706H42.4725V34.5129H51.3018V36.9736H45.6569V45.7706Z"
        fill="url(#paint4_linear_11105_57042)"
      />
      <path
        d="M53.0091 45.7706V34.5129H56.1935V45.7706H53.0091Z"
        fill="url(#paint5_linear_11105_57042)"
      />
      <path
        d="M64.2898 45.9958C63.3999 45.9958 62.5744 45.8564 61.8131 45.5777C61.0626 45.2882 60.4086 44.8807 59.8511 44.3554C59.3043 43.83 58.8754 43.2135 58.5645 42.5059C58.2535 41.7876 58.0981 40.9995 58.0981 40.1418C58.0981 39.2841 58.2535 38.5014 58.5645 37.7938C58.8754 37.0754 59.3043 36.4536 59.8511 35.9282C60.4086 35.4028 61.0626 35.0008 61.8131 34.722C62.5744 34.4325 63.3999 34.2878 64.2898 34.2878C65.3298 34.2878 66.2572 34.4701 67.0721 34.8346C67.8976 35.1991 68.5838 35.7245 69.1306 36.4107L67.1042 38.2441C66.7397 37.8152 66.3376 37.4882 65.8981 37.263C65.4692 37.0379 64.9867 36.9253 64.4506 36.9253C63.9896 36.9253 63.5661 37.0004 63.1801 37.1505C62.7942 37.3006 62.4618 37.5204 62.183 37.8098C61.915 38.0886 61.7006 38.4263 61.5397 38.823C61.3896 39.2197 61.3146 39.6593 61.3146 40.1418C61.3146 40.6243 61.3896 41.0639 61.5397 41.4606C61.7006 41.8573 61.915 42.2003 62.183 42.4898C62.4618 42.7686 62.7942 42.983 63.1801 43.1331C63.5661 43.2832 63.9896 43.3583 64.4506 43.3583C64.9867 43.3583 65.4692 43.2457 65.8981 43.0205C66.3376 42.7954 66.7397 42.4684 67.1042 42.0395L69.1306 43.8729C68.5838 44.5484 67.8976 45.0737 67.0721 45.449C66.2572 45.8135 65.3298 45.9958 64.2898 45.9958Z"
        fill="url(#paint6_linear_11105_57042)"
      />
      <path
        d="M69.2291 45.7706L74.1986 34.5129H77.3347L82.3202 45.7706H79.0072L75.1153 36.0729H76.3697L72.4778 45.7706H69.2291ZM71.947 43.5834L72.7673 41.2354H78.2674L79.0876 43.5834H71.947Z"
        fill="url(#paint7_linear_11105_57042)"
      />
      <path
        d="M85.2719 45.7706V37.0379H81.8142V34.5129H91.8979V37.0379H88.4563V45.7706H85.2719Z"
        fill="url(#paint8_linear_11105_57042)"
      />
      <path
        d="M93.3023 45.7706V34.5129H96.4867V45.7706H93.3023Z"
        fill="url(#paint9_linear_11105_57042)"
      />
      <path
        d="M104.647 45.9958C103.747 45.9958 102.916 45.8511 102.155 45.5616C101.393 45.2721 100.729 44.8647 100.16 44.3393C99.6028 43.8032 99.1686 43.1814 98.8577 42.4737C98.5467 41.7661 98.3913 40.9888 98.3913 40.1418C98.3913 39.2948 98.5467 38.5175 98.8577 37.8098C99.1686 37.1022 99.6028 36.4857 100.16 35.9604C100.729 35.4243 101.393 35.0115 102.155 34.722C102.916 34.4325 103.747 34.2878 104.647 34.2878C105.559 34.2878 106.39 34.4325 107.14 34.722C107.901 35.0115 108.561 35.4243 109.118 35.9604C109.676 36.4857 110.11 37.1022 110.421 37.8098C110.743 38.5175 110.903 39.2948 110.903 40.1418C110.903 40.9888 110.743 41.7715 110.421 42.4898C110.11 43.1975 109.676 43.8139 109.118 44.3393C108.561 44.8647 107.901 45.2721 107.14 45.5616C106.39 45.8511 105.559 45.9958 104.647 45.9958ZM104.647 43.3583C105.076 43.3583 105.473 43.2832 105.837 43.1331C106.213 42.983 106.534 42.7686 106.802 42.4898C107.081 42.2003 107.296 41.8573 107.446 41.4606C107.607 41.0639 107.687 40.6243 107.687 40.1418C107.687 39.6486 107.607 39.209 107.446 38.823C107.296 38.4263 107.081 38.0886 106.802 37.8098C106.534 37.5204 106.213 37.3006 105.837 37.1505C105.473 37.0004 105.076 36.9253 104.647 36.9253C104.218 36.9253 103.816 37.0004 103.441 37.1505C103.077 37.3006 102.755 37.5204 102.476 37.8098C102.208 38.0886 101.994 38.4263 101.833 38.823C101.683 39.209 101.608 39.6486 101.608 40.1418C101.608 40.6243 101.683 41.0639 101.833 41.4606C101.994 41.8573 102.208 42.2003 102.476 42.4898C102.755 42.7686 103.077 42.983 103.441 43.1331C103.816 43.2832 104.218 43.3583 104.647 43.3583Z"
        fill="url(#paint10_linear_11105_57042)"
      />
      <path
        d="M112.789 45.7706V34.5129H115.411L121.619 42.0074H120.38V34.5129H123.5V45.7706H120.879L114.671 38.2762H115.909V45.7706H112.789Z"
        fill="url(#paint11_linear_11105_57042)"
      />
      <path
        d="M20.3889 19.9166C18.8446 19.9166 17.3541 19.728 15.9174 19.3509C14.4988 18.9558 13.3405 18.453 12.4426 17.8424L14.1935 13.9097C15.0375 14.4484 16.0072 14.8974 17.1027 15.2565C18.216 15.5977 19.3204 15.7683 20.4159 15.7683C21.1521 15.7683 21.7448 15.7055 22.1937 15.5798C22.6426 15.4361 22.9659 15.2565 23.1634 15.041C23.3789 14.8076 23.4867 14.5382 23.4867 14.2329C23.4867 13.8019 23.2891 13.4607 22.8941 13.2093C22.499 12.9579 21.9872 12.7514 21.3587 12.5898C20.7301 12.4282 20.0298 12.2665 19.2576 12.1049C18.5034 11.9433 17.7402 11.7368 16.968 11.4854C16.2137 11.234 15.5224 10.9107 14.8938 10.5157C14.2653 10.1026 13.7535 9.57287 13.3585 8.92639C12.9634 8.26195 12.7658 7.42691 12.7658 6.42128C12.7658 5.28993 13.0711 4.26634 13.6817 3.35049C14.3102 2.43464 15.244 1.69837 16.4831 1.14168C17.7222 0.584987 19.2666 0.306641 21.1162 0.306641C22.3553 0.306641 23.5675 0.450303 24.7527 0.737629C25.9559 1.007 27.0244 1.41105 27.9582 1.94978L26.315 5.90948C25.4171 5.42462 24.5282 5.06546 23.6483 4.83201C22.7684 4.5806 21.9154 4.4549 21.0893 4.4549C20.353 4.4549 19.7604 4.53571 19.3115 4.69733C18.8625 4.84099 18.5393 5.03852 18.3417 5.28993C18.1442 5.54134 18.0454 5.82867 18.0454 6.15191C18.0454 6.56494 18.234 6.89716 18.6111 7.14857C19.0062 7.38202 19.518 7.57956 20.1465 7.74118C20.793 7.88484 21.4933 8.03748 22.2476 8.1991C23.0198 8.36072 23.783 8.56723 24.5372 8.81864C25.3094 9.0521 26.0097 9.37534 26.6383 9.78837C27.2668 10.1834 27.7696 10.7132 28.1467 11.3776C28.5418 12.0241 28.7393 12.8412 28.7393 13.8289C28.7393 14.9243 28.4251 15.9389 27.7965 16.8727C27.186 17.7886 26.2611 18.5248 25.0221 19.0815C23.8009 19.6382 22.2566 19.9166 20.3889 19.9166Z"
        fill="url(#paint12_linear_11105_57042)"
      />
      <path
        d="M31.4211 19.5395V0.683755H36.7545V19.5395H31.4211Z"
        fill="url(#paint13_linear_11105_57042)"
      />
      <path
        d="M40.7671 19.5395V0.683755H46.1006V15.3104H55.0975V19.5395H40.7671Z"
        fill="url(#paint14_linear_11105_57042)"
      />
      <path
        d="M61.9548 19.5395L53.9008 0.683755H59.6652L66.4533 17.0074H63.0592L70.0089 0.683755H75.2885L67.2075 19.5395H61.9548Z"
        fill="url(#paint15_linear_11105_57042)"
      />
      <path
        d="M81.5129 7.98361H90.2673V11.9702H81.5129V7.98361ZM81.89 15.4181H91.7488V19.5395H76.6104V0.683755H91.3987V4.80507H81.89V15.4181Z"
        fill="url(#paint16_linear_11105_57042)"
      />
      <path
        d="M94.9266 19.5395V0.683755H103.546C105.27 0.683755 106.752 0.962101 107.991 1.51879C109.248 2.07549 110.218 2.88359 110.9 3.9431C111.582 4.98465 111.924 6.22374 111.924 7.66037C111.924 9.07903 111.582 10.3091 110.9 11.3507C110.218 12.3743 109.248 13.1644 107.991 13.7211C106.752 14.2599 105.27 14.5292 103.546 14.5292H97.8896L100.26 12.2935V19.5395H94.9266ZM106.59 19.5395L101.903 12.6706H107.587L112.301 19.5395H106.59ZM100.26 12.8592L97.8896 10.4079H103.223C104.336 10.4079 105.163 10.1655 105.701 9.68062C106.258 9.19576 106.536 8.52234 106.536 7.66037C106.536 6.78043 106.258 6.09804 105.701 5.61317C105.163 5.12831 104.336 4.88588 103.223 4.88588H97.8896L100.26 2.43464V12.8592Z"
        fill="url(#paint17_linear_11105_57042)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_11105_57042"
          x1="10.3024"
          y1="45.9958"
          x2="64.5396"
          y2="-35.9845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SilverVerification;
