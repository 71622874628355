import { Notification } from '../components';
import { axiosInstance } from '../interceptors';
import { successValidator } from '../interceptors/statusValidators';
import { ApiRoutes } from '../routes';
import { SEGMENT_NAME } from '../constants/companyTrailSegments';

class MeService {
  static async getMyDetails(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.ME);
      const { company } = response?.data;
      if (company?.footprintRange) {
        company.footprintRange = company.footprintRange.sort((a, b) => a - b);
      }
      // Handle Onboarding Potential Redirect
      if (company) {
        const { onboardingStatus } = company;
        let isOnboardingRequired = false;
        let onboardingStep = null;
        if (!onboardingStatus?.length) {
          isOnboardingRequired = true;
        } else {
          for (const segment of Object.values(SEGMENT_NAME).reverse()) {
            if (
              !onboardingStatus.find(
                (os) => segment === os.segmentName && os.isComplete
              )
            ) {
              isOnboardingRequired = true;
              onboardingStep = segment;
            }
          }
        }
        company.onboardingStatus = { isOnboardingRequired, onboardingStep };
      }
      if (company) response.data.company = company;
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async getProfileDetails(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.MY_PROFILE);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async updateProfileDetails(
    userId,
    params,
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const response = await axiosInstance.put(
        `${ApiRoutes.MY_PROFILE}/${userId}`,
        {
          params
        }
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchMyCompanies(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.MY_COMPANIES);
      onSuccess?.(response.data.companies);
      return response.data.companies;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static updatePassword(data, onSuccess, onError, onFinal) {
    axiosInstance
      .put(ApiRoutes.UPDATE_PASSWORD, data)
      .then(() => {
        Notification({
          type: 'success',
          message: 'Update Password',
          description: 'Password successfully updated!'
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async acceptTermsAndConditions({}, onSuccess, onError, onFinal) {
    try {
      const API_URL = `${ApiRoutes.MY_TERMS_AND_CONDITIONS}/accept`;
      const response = await axiosInstance.put(API_URL, {}, {});

      onSuccess?.({ ...response });
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async changeActiveCompany(companySlug, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.post(
        ApiRoutes.CHANGE_ACTIVE_COMPANY,
        { companySlug }
      );
      localStorage.setItem('token', response.data.token);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async acceptTerms() {
    try {
      const response = await axiosInstance.put(
        ApiRoutes.ACCEPT_TERMS,
        {},
        {
          validateStatus: successValidator
        }
      );
      return response;
    } catch (error) {
      Notification({
        type: 'error',
        message: 'Accept Terms',
        description: 'Unable to accept terms. Please Try again'
      });
    } finally {
    }
  }
}

export default MeService;
