import { Menu as AntMenu } from 'antd';
import classNames from 'classnames';
import './Menu.scss';

export function Menu({ children, className, ...props }) {
  return (
    <AntMenu
      className={classNames('ch-menu', { [className]: !!className })}
      {...props}
    >
      {children}
    </AntMenu>
  );
}
