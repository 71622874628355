import * as dateFns from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileImage, Tag } from '../../../components';
import {
  NotificationCategory,
  NotificationCategoryLabelMapping
} from '../../../constants';
import {
  COLOURS_CARBONHOUND,
  COLOURS_VIOLET
} from '../../../constants/colours';
import {
  ActionOverviewListTuple,
  NotificationCategoryLabel,
  NotificationCategoryValueMapping
} from '../../../constants/notificationCategory';
import { ActionStatus } from '../../../constants/status';
import { useMeContext } from '../../../context';
import { analytics, capitalizeText, getFullName } from '../../../helpers';
import { getActionCategoryCopy } from '../../../helpers/generators';
import { useAsync, useModal, usePagination } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { NotificationService } from '../../../services';
import sleepingGus from '../../../stories/assets/svgs/Gus/gusSleeping.svg';
import { ButtonIcon, ColumnTitle, Empty, Table } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { INPUT_TYPES } from '../../../stories/atoms/Input/Input.types';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import TableTitle from '../../../stories/molecules/TableTitle';
import { InitiativeActionDetail } from '../../Initiative/InitiativeAction';
import { ActionsModal } from '../ActionsModal';

export function ActionsTable({ isTableOutdated, setIsTableOutdated }) {
  const { me } = useMeContext();
  const [categories, setCategories] = useState([]);
  const [isViewingComplete, setIsViewingComplete] = useState(false);
  const [activeNotificationAction, setActiveNotificationAction] = useState({});
  const [incompleteActions, setIncompleteActions] = useState([]);
  const [completeActions, setCompleteActions] = useState([]);

  const navigate = useNavigate();
  const defaultOrderByComplete = ['updatedAt', 'id'];
  const defaultOrderByIncomplete = ['dueDate', 'createdAt', 'id'];

  const { Modal, handleShowModal, handleCloseModal } = useModal({
    width: '80%'
  });

  const handleOnClickEmptyState = () => {
    analytics.track('Initiative Overview Clicked', me, {
      level1: 'Actions',
      level2: 'Empty State'
    });
    navigate(AppRoutes.INITIATIVE);
  };

  const {
    paginationParams: completePaginationParams,
    handleSetPaginationParams: handleSetCompletePaginationParams
  } = usePagination({
    paginationArray: completeActions,
    orderBy: defaultOrderByComplete,
    order: 'DESC'
  });

  const {
    paginationParams: inCompletePaginationParams,
    handleSetPaginationParams: handleSetIncompletePaginationParams
  } = usePagination({
    paginationArray: incompleteActions,
    orderBy: defaultOrderByIncomplete,
    orderNulls: { dueDate: 'LAST' },
    order: 'ASC'
  });

  const handleFetchNotifications = async (isComplete, pagination) => {
    const categoriesToSend = categories.reduce((acc, curr) => {
      const params = NotificationCategoryValueMapping[curr] ?? [];
      if (params) {
        acc = [...acc, ...params];
      }
      return acc;
    }, []);
    return await NotificationService.fetchNotificationActions({
      isComplete,
      isDataImport: false,
      pagination,
      categories: categoriesToSend
    });
  };
  const handleFetchIncompleteNotifications = useCallback(async () => {
    const incompleteNotifications = await handleFetchNotifications(
      false,
      inCompletePaginationParams
    );
    setIncompleteActions(incompleteNotifications.list);
    return incompleteNotifications.count ?? 0;
  }, [
    inCompletePaginationParams.pageNumber,
    inCompletePaginationParams.orderBy,
    inCompletePaginationParams.search?.queryString,
    categories
  ]);

  const handleFetchCompleteNotifications = useCallback(async () => {
    const completeNotifications = await handleFetchNotifications(
      true,
      completePaginationParams
    );
    setCompleteActions(completeNotifications.list);
    return completeNotifications.count ?? 0;
  }, [
    completePaginationParams.pageNumber,
    completePaginationParams.orderBy,
    completePaginationParams.search?.queryString,
    categories
  ]);

  const {
    value: completeCount,
    execute: refreshCompleteList,
    isLoading: isCompleteListLoading
  } = useAsync({
    asyncFunction: handleFetchCompleteNotifications,
    defaultValue: 0
  });
  const {
    value: incompleteCount,
    execute: refreshIncompleteList,
    isLoading: isIncompleteListLoading
  } = useAsync({
    asyncFunction: handleFetchIncompleteNotifications,
    defaultValue: 0
  });

  const columns = [
    {
      title: ({ sortColumns }) => (
        <ColumnTitle columnTitle="name" sortColumns={sortColumns} isSortable>
          Name
        </ColumnTitle>
      ),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      onCell: () => ({
        className: 'text-bd'
      })
    },
    {
      title: ({ sortColumns }) => (
        <ColumnTitle
          columnTitle="category"
          sortColumns={sortColumns}
          isSortable
        >
          Type
        </ColumnTitle>
      ),
      sorter: true,
      dataIndex: 'category',
      key: 'category',
      render: getActionCategoryCopy
    },
    {
      title: <ColumnTitle>Assignee</ColumnTitle>,
      dataIndex: 'owner',
      render: (owner) =>
        owner ? (
          <ProfileImage name={getFullName(owner)} size="small" />
        ) : (
          <ButtonIcon
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            backgroundColour={COLOURS_VIOLET.VIOLET_100}
            name="Plus"
          />
        )
    },
    {
      title: ({ sortColumns }) => (
        <ColumnTitle columnTitle="dueDate" sortColumns={sortColumns} isSortable>
          Due Date
        </ColumnTitle>
      ),
      width: '180px',
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      render: (dueDate) =>
        dueDate ? (
          formatInTimeZone(new Date(dueDate), 'UTC', 'MMM dd, yyyy')
        ) : (
          <ButtonIcon
            color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
            backgroundColour={COLOURS_VIOLET.VIOLET_100}
            name="Plus"
          />
        )
    },
    {
      title: ({ sortColumns }) => (
        <ColumnTitle columnTitle="status" sortColumns={sortColumns} isSortable>
          Status
        </ColumnTitle>
      ),
      width: '180px',
      sorter: true,
      dataIndex: 'status',
      key: 'status',
      render: (status, action) => {
        let statusColor;
        let statusLabel = status;
        switch (status) {
          case ActionStatus.COMPLETE:
            statusColor = TAG_COLOURS.GREEN;
            break;
          case ActionStatus.OVERDUE:
          case ActionStatus.PENDING:
          default:
            if (
              status === ActionStatus.OVERDUE ||
              (action?.dueDate &&
                dateFns.startOfDay(new Date(action.dueDate)) <
                  dateFns.startOfDay(new Date()))
            ) {
              statusColor = TAG_COLOURS.RED;
              statusLabel = 'OVERDUE';
            } else {
              statusColor = TAG_COLOURS.YELLOW;
            }
            break;
        }
        return <Tag color={statusColor}>{capitalizeText(statusLabel)}</Tag>;
      }
    }
  ];
  const pagination = isViewingComplete
    ? completePaginationParams
    : inCompletePaginationParams;

  const total = isViewingComplete ? completeCount : incompleteCount;

  const handleSortColumn = (sorter) => {
    let orderBy;
    if (!sorter.column?.key) {
      orderBy = isViewingComplete
        ? defaultOrderByComplete
        : defaultOrderByIncomplete;
      // One of the statuses (Overdue) is visual only. To enforce grouping, we sort by dueDate first
    } else if (sorter.column?.key === 'status') {
      orderBy = ['dueDate', 'status', 'id'];
    } else {
      orderBy = [sorter.column?.key, 'id'];
    }
    handleSetPaginationParams({
      ...pagination,
      paginationArray: dataSource,
      pageNumber: 1,
      prevPageNumber: 1,
      previousValue: null,
      orderBy,
      order: sorter.order,
      reverse: false
    });
  };

  const handleSearch = (query) => {
    handleSetCompletePaginationParams({
      ...pagination,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['title']
          }
        : undefined
    });
    handleSetIncompletePaginationParams({
      ...pagination,
      pageNumber: 1,
      reverse: false,
      prevPageNumber: 1,
      previousValue: undefined,
      search: !!query
        ? {
            queryString: query,
            queryFields: ['title']
          }
        : undefined
    });
  };

  const handleSetPaginationParams = isViewingComplete
    ? handleSetCompletePaginationParams
    : handleSetIncompletePaginationParams;

  const dataSource = isViewingComplete ? completeActions : incompleteActions;

  const refreshListFn = isViewingComplete
    ? refreshCompleteList
    : refreshIncompleteList;

  const isLoading = isViewingComplete
    ? isCompleteListLoading
    : isIncompleteListLoading;

  const handleSwitchListViews = (value) => {
    setIsViewingComplete(value);
  };
  const switcherProps = {
    onClick: handleSwitchListViews,
    activeValue: isViewingComplete,
    options: [
      {
        label: `In Progress (${incompleteCount})`,
        value: false
      },
      { label: `Complete (${completeCount})`, value: true }
    ]
  };
  const searchProps = {
    type: INPUT_TYPES.SEARCH,
    isFormikField: false,
    enterButton: true,
    placeholder: 'Search by name',
    onSearch: handleSearch,
    allowClear: true
  };

  const onFilterType = (type) => {
    setCategories(type);
  };
  const filterProps = {
    mode: 'multiple',
    disableForm: true,
    placeholder: 'Filter by type',
    options: Object.values(ActionOverviewListTuple)
      .filter((category) => category !== NotificationCategoryLabel.DATA_IMPORT)
      .map((category) => ({
        label: NotificationCategoryLabelMapping[category],
        value: category
      })),
    onChange: onFilterType
  };

  const handleOnModalSuccess = () => {
    refreshCompleteList();
    refreshIncompleteList();
  };
  const onRowClick = (an) => {
    analytics.track('Item Clicked', me, {
      notification: an,
      level1: 'Actions',
      level2: 'Empty State'
    });
    setActiveNotificationAction(an);
    handleShowModal();
  };

  useEffect(() => {
    if (isTableOutdated) {
      refreshListFn();
      setIsTableOutdated(false);
    }
  }, [isTableOutdated]);

  return (
    <div>
      <TableTitle
        searchProps={searchProps}
        filterProps={filterProps}
        switcherProps={switcherProps}
      />
      <Table
        columns={columns}
        showPagination
        pagination={pagination}
        total={total}
        dataSource={dataSource}
        isLoading={isLoading}
        onRow={(an) => ({
          className: 'cursor-pointer',
          onClick: () => onRowClick(an)
        })}
        locale={{
          emptyText: (
            <Empty
              imageSrc={sleepingGus}
              title="It’s quiet in here"
              description="You don’t have any actions at the moment, but once you do they will live here"
              buttonProps={{
                children: 'Browse Initiatives',
                type: BUTTON_TYPES.LINK,
                onClick: handleOnClickEmptyState
              }}
            />
          )
        }}
        onChange={(pagination, filters, sorter, { action }) => {
          if (action === 'sort') {
            handleSortColumn(sorter);
          }
        }}
      />
      <Modal showHeader={false} bodyStyle={{ padding: 0 }} closable={false}>
        {activeNotificationAction?.category ===
          NotificationCategory.INITIATIVE ||
        activeNotificationAction?.category === NotificationCategory.CUSTOM ? (
          <InitiativeActionDetail
            onClose={handleCloseModal}
            initiativeAction={activeNotificationAction}
            onSuccess={handleOnModalSuccess}
            companyInitiativeId={activeNotificationAction.companyInitiativeId}
          />
        ) : (
          <ActionsModal
            action={activeNotificationAction}
            onSuccess={handleOnModalSuccess}
            onClose={handleCloseModal}
          />
        )}
      </Modal>
    </div>
  );
}
