import { Col, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useEffect, useState } from 'react';
import { Progress } from '../../../components';
import { COLOURS_GREEN } from '../../../constants/colours';
import { useMeContext } from '../../../context';
import { getTimeFormat } from '../../../helpers/generators';
import { useAsync, usePagination } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CommuteService, NotificationService } from '../../../services';
import { Link, Table, Tag, Title } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { TAG_COLOURS } from '../../../stories/atoms/Tag/tag.types';
import InviteEmailPreviewButton from '../../EmployeeDetail/InviteEmailPreview';
import './ActionsEmployeeForm.scss';

export function ActionsEmployeeForm({ setIsDataIncomplete }) {
  const { me, getMyGroupSettings } = useMeContext();
  const { groupSettings } = me;
  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (!groupSettings) {
      getMyGroupSettings();
    }
  }, []);

  const handleGetCommuteAlert = useCallback(async () => {
    const { commuteCompletionPercent } =
      (await NotificationService.getCommuteAlert({
        companySlug: me?.company?.slug
      })) ?? 0;
    setIsDataIncomplete(commuteCompletionPercent < 100);
    return commuteCompletionPercent;
  }, [me?.company?.slug]);

  const { value: completionPercent } = useAsync({
    asyncFunction: handleGetCommuteAlert,
    defaultValue: 0
  });

  const { paginationParams } = usePagination({
    paginationArray: employees,
    previousValueKeys: ['user.id'],
    orderBy: ['userId'],
    pageSize: 10
  });

  const handleFetchEmployees = useCallback(async () => {
    const response = await CommuteService.fetchEmployees({
      companySlug: me?.company?.slug,
      pagination: paginationParams,
      isComplete: false
    });

    setEmployees(response?.list);

    return response?.count ?? 0;
  }, [me?.company?.slug, paginationParams.pageNumber]);

  const { value: total, isLoading: isLoadingTable } = useAsync({
    asyncFunction: handleFetchEmployees,
    defaultValue: 0
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (id, employee) => <span>{employee?.user?.email ?? '--'}</span>
    },
    {
      width: '101px',
      title: 'Last reminder',
      dataIndex: 'invitedAt',
      key: 'invitedAt',
      render: (date) => (
        <span>
          {date
            ? formatInTimeZone(new Date(date), 'UTC', dateDisplayFormat)
            : '-'}
        </span>
      )
    },
    {
      width: '91px',
      title: 'Status',
      dataIndex: 'isInvited',
      key: 'isInvited',
      render: (_, row) => {
        const { isInvited, isStarted } = row;
        let tagText;
        let tagColor = '';
        if (isStarted) {
          tagText = 'Started';
          tagColor = TAG_COLOURS.YELLOW;
        } else if (isInvited) {
          tagText = 'Invited';
          tagColor = TAG_COLOURS.ORANGE;
        } else {
          tagText = 'Not Invited';
          tagColor = TAG_COLOURS.RED;
        }
        return <Tag color={tagColor}>{tagText}</Tag>;
      }
    }
  ];

  const sendReminders = async () => {
    await CommuteService.sendInviteBulk({
      companySlug: me?.company?.slug,
      groupSlug: groupSettings?.slug,
      emailType: 'RESEND'
    });
  };
  return (
    <Row className="actions-employee-form" gutter={[0, 24]}>
      <Col span={24} className="actions-employee-form__progress ">
        <Progress
          fillColor={COLOURS_GREEN.GREEN_400}
          secondary
          percent={completionPercent}
          status="normal"
          showInfo
          showBadge={false}
          title="Employee Commute Coverage"
        />
      </Col>
      <Col span={24}>
        <Row justify="space-between" className="mb-8">
          <Col>
            <Title bottomSpacing={0}>Employees</Title>
          </Col>
          <Col>
            <Space size={16}>
              <Link
                fontSize={16}
                fontWeight={600}
                to={`${AppRoutes.USER_COMPANY}?tab=2`}
              >
                Manage
              </Link>
              <InviteEmailPreviewButton
                buttonProps={{
                  text: 'Remind All',
                  type: BUTTON_TYPES.PRIMARY,
                  onSubmit: sendReminders
                }}
              />
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={(row) => row.user?.id}
          loading={isLoadingTable}
          columns={columns}
          dataSource={employees}
          showPagination
          pagination={paginationParams}
          total={total}
        />
      </Col>
    </Row>
  );
}
