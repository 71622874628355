import { Col, Row } from 'antd';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { AppRoutes } from '../../../routes';
import congrats from '../../../stories/assets/svgs/congrats.svg';
import {
  Anchor,
  Button,
  Img,
  Link,
  Paragraph,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './onboardingComplete.scss';

export function OnboardingComplete() {
  const { me, isAdmin } = useMeContext();
  const handleOnClickSetUpCompany = () => {
    analytics.track('Link Clicked', me, {
      level1: 'Company Onboarding',
      level2: 'Onboarding Complete'
    });
  };
  const companyOnboardingLink = isAdmin
    ? AppRoutes.ONBOARDING_ADMIN_LOCATION_OVERVIEW
    : AppRoutes.ONBOARDING_EMPLOYEE_OVERVIEW;
  return (
    <Row gutter={[24, 32]} className="onboarding-complete">
      <Col span={24}>
        <Title align="center" size="lg">
          <Img src={congrats} />
          Your onboarding is complete! Here’s your next steps:
        </Title>
      </Col>
      <Col span={8} className="onboarding-complete__cta">
        <Row className="onboarding-complete__cta__inner" gutter={[0, 24]}>
          <Col span={24}>
            <Title bottomSpacing={4}>Onboarding Initiative</Title>
            <Paragraph bottomSpacing={0}>
              Get started with our tailored onboarding initiative that covers
              the majority of your emissions data onboarding.
            </Paragraph>
          </Col>

          <Col span={24}>
            <Button onClick={handleOnClickSetUpCompany}>
              <Link to={companyOnboardingLink}>Set up my company</Link>
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={8} className="onboarding-complete__cta">
        <Row className="onboarding-complete__cta__inner" gutter={[0, 24]}>
          <Col span={24}>
            <Title bottomSpacing={4}>Need help getting started?</Title>
            <Paragraph bottomSpacing={0}>
              We’ve put together a guide for your first few steps in Carbonhound
              that you can follow as you get onboarded.
            </Paragraph>
          </Col>
          <Col span={24}>
            <Button type={BUTTON_TYPES.SECONDARY}>
              <Anchor
                showLinkIcon={false}
                flex
                href="https://carbonhound.notion.site/Getting-Started-f5c2310d674d4adda0eedefb9627d203"
              >
                View Onboarding Guide
              </Anchor>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={8} className="onboarding-complete__cta">
        <Row className="onboarding-complete__cta__inner" gutter={[0, 24]}>
          <Col span={24}>
            <Title bottomSpacing={4}>Onboard your data 10x faster</Title>
            <Paragraph bottomSpacing={0}>
              Boost your onboarding efforts by connecting your data sources to
              our data concierge.
            </Paragraph>
          </Col>

          <Col span={24}>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              onClick={handleOnClickSetUpCompany}
            >
              <Link to={AppRoutes.USER_COMPANY} state={{ defaultKey: '4' }}>
                Start Data Concierge
              </Link>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="onboarding-complete__cta">
        <Row
          className="onboarding-complete__cta__inner"
          gutter={[0, 24]}
          align="middle"
        >
          <Col flex={1}>
            <Title bottomSpacing={4}>
              Book a session with our climate experts
            </Title>
            <Paragraph bottomSpacing={0}>
              Our climate team can help you set up your strategy and plan your
              data onboarding.
            </Paragraph>
          </Col>
          <Col>
            <Button type={BUTTON_TYPES.SECONDARY}>
              <Anchor
                showLinkIcon={false}
                flex
                href="https://meetings.hubspot.com/hannah472/carbonhound-contact-consultant-inbound"
              >
                Book Consult
              </Anchor>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
