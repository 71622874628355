import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { DatePicker, Input } from '../../../../stories/atoms';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import OwnerSelect from '../../../../stories/molecules/OwnerSelect';

export function ActionsFormFields() {
  const { values, setFieldValue } = useFormikContext();
  const { ownerId, dueDate } = values;
  return (
    <Row className="actions-form__wrapper">
      <Col span={24}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Input title="Title (required)" name="title" placeholder="Enter" />
          </Col>
          <Col span={24}>
            <Input
              type={INPUT_TYPES.TEXT_AREA}
              title="Description"
              name="message"
              placeholder="Enter"
            />
          </Col>
          <Col span={24}>
            <OwnerSelect
              value={ownerId}
              setFieldValue={setFieldValue}
              title="Assignee"
              name="ownerId"
              placeholder="Enter"
            />
          </Col>
          <Col span={24}>
            <DatePicker
              title="Due Date"
              name="dueDate"
              defaultValue={dueDate}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
