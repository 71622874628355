import { UnitTypeFamilies } from '../constants';
import { capitalizeText } from '../helpers';
import { getDistanceUnit, getUnitFriendlyName } from '../helpers/generators';
import { axiosInstance } from '../interceptors';
import { ApiRoutes } from '../routes';

class MetaService {
  static async fetchIndustries(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.INDUSTRY_METAS);
      onSuccess(response.data.industries);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchCountries(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.COUNTRY_METAS);
      onSuccess(response.data.countries);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchEnergyTypes({ countryId }, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.ENERGY_TYPE_METAS, {
        params: {
          ...(countryId && { countryId })
        }
      });
      const energyTypes = response.data.energyTypes.map((energyType) => ({
        ...energyType,
        friendlyName: capitalizeText(energyType.name)
      }));
      onSuccess(energyTypes);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchEnergyUnits(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.UNIT_LIST, {
        params: {
          unitFamilyNames: [UnitTypeFamilies.ENERGY]
        }
      });
      const units = response.data?.units.map((unit) => {
        unit.friendlyName = getUnitFriendlyName(unit);
        return unit;
      });
      onSuccess(units);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchTransportationTypes(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(
        ApiRoutes.TRANSPORTATION_TYPE_METAS
      );
      const transportationTypes = response.data.transportationTypes.map(
        (transportationType) => ({
          ...transportationType,
          friendlyName: capitalizeText(transportationType.name)
        })
      );
      onSuccess(transportationTypes);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchDistanceUnits({ isoName }, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.UNIT_LIST, {
        params: {
          unitFamilyNames: [UnitTypeFamilies.DISTANCE]
        }
      });
      const units = response.data?.units
        .map((unit) => {
          unit.friendlyName = capitalizeText(unit.name);
          return unit;
        })
        .sort((unitA, unitB) => {
          if (!!isoName) {
            const defaultUnit = getDistanceUnit(isoName).toUpperCase();
            return unitA.unit === defaultUnit
              ? -1
              : unitB.unit === defaultUnit
              ? 1
              : unitA.unit - unitB.unit;
          }
          return unitA.unit - unitB.unit;
        });
      onSuccess(units);
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchTravelCategories(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.TRAVEL_CATEGORY_METAS);
      const travelCategories = response.data.travelCategories.map(
        (travelCategory) => ({
          ...travelCategory,
          friendlyName: capitalizeText(travelCategory.name)
        })
      );
      onSuccess?.(travelCategories);
      return travelCategories;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchTravelClasses(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.TRAVEL_CLASS_METAS);
      onSuccess(response.data.travelClasses);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchFuelTypes(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.FUEL_TYPE_METAS);
      const fuelTypes = response.data.fuelTypes.map((fuelType) => ({
        ...fuelType,
        friendlyName:
          fuelType.name === 'HYBRID ELECTRIC'
            ? 'Hybrid'
            : capitalizeText(fuelType.name)
      }));
      onSuccess(fuelTypes);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchVehicleTypes(onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.VEHICLE_TYPE_METAS);
      const vehicleTypes = response.data.vehicleTypes.map((vehicleType) => ({
        ...vehicleType,
        friendlyName: capitalizeText(vehicleType.name)
      }));
      onSuccess(vehicleTypes);
    } catch (error) {
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async fetchBuildingTypes(
    { countryId, category },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const response = await axiosInstance.get(ApiRoutes.BUILDING_TYPE_METAS, {
        params: {
          ...(countryId && { countryId }),
          category
        }
      });
      const fuelTypes = response.data.buildingTypes.map((buildingType) => ({
        ...buildingType,
        friendlyName: capitalizeText(buildingType.name)
      }));
      onSuccess?.(fuelTypes);
      return fuelTypes;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }

  static async fetchUnits(unit, onSuccess, onError, onFinal) {
    try {
      const response = await axiosInstance.get(ApiRoutes.UNIT_LIST, {
        params: {
          unitFamilyNames: [unit]
        }
      });
      const units = response.data?.units.map((unit) => {
        unit.friendlyName = capitalizeText(unit.name);
        return unit;
      });
      onSuccess?.(units);
      return units;
    } catch (error) {
      onError?.(error);
    } finally {
      onFinal?.();
    }
  }
}

export default MetaService;
