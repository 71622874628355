import { Col, Modal as AntModal, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useState } from 'react';
import { Card, EnergyItemForm, Loader } from '../../../../components';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS,
  EmissionSourceItemOrigin,
  EmissionSourceItemOriginCopy
} from '../../../../constants';
import { COLOURS_GRAY } from '../../../../constants/colours';
import DateFormats from '../../../../constants/dateFormats';
import { useMeContext } from '../../../../context';
import {
  analytics,
  capitalizeText,
  formatDecimal,
  getFullName
} from '../../../../helpers';
import { getTimeFormat } from '../../../../helpers/generators';
import { useAsync, useModal, usePagination } from '../../../../hooks';
import { EnergyService } from '../../../../services';
import {
  ButtonIcon,
  ColumnTitle,
  Empty,
  Table
} from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { CompletionTimeline } from '../../../../stories/molecules';
import DataRequestTemplate from '../../../../stories/molecules/DataRequestTemplate';
import TableTitle from '../../../../stories/molecules/TableTitle';
import BulkUploadForm from '../../../BulkUploadForm';

function EmissionSourceEnergyItemTable({
  emissionSourceDetails,
  analyticsContext,
  refreshChart,
  timeline,
  isLoadingTimeline,
  refreshTimeline
}) {
  const { me } = useMeContext();
  const dateDisplayFormat = getTimeFormat(
    me.company?.headQuartersCountry?.isoName
  );
  const { internalDetails, location, requireNotifications } =
    emissionSourceDetails ?? {};
  const { energyTypeId, isEstimate, energyDetailsId } = internalDetails ?? {};
  const { id: locationId } = location ?? {};

  const [itemList, setItemList] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [activeItem, setActiveItem] = useState({});
  const { paginationParams } = usePagination({
    pageSize: 30,
    paginationArray: itemList,
    orderBy: ['startDate', 'id']
  });
  const handleLoadTableData = useCallback(async () => {
    if (!!energyDetailsId && !isEstimate) {
      const { list, count } = await EnergyService.fetchEnergyItems(
        energyDetailsId,
        {
          emissionSourceDetailsId: emissionSourceDetails?.id,
          energyDetailsId,
          pagination: paginationParams,
          ...(dateRange?.length > 1 && {
            rangeStart: formatInTimeZone(
              new Date(dateRange?.[0]),
              'UTC',
              DateFormats.API
            ),
            rangeEnd: formatInTimeZone(
              new Date(dateRange?.[1]),
              'UTC',
              DateFormats.API
            )
          })
        }
      );
      setItemList(list);
      return count;
    }
  }, [
    emissionSourceDetails?.id,
    paginationParams.orderBy,
    paginationParams.order,
    paginationParams.pageNumber,
    dateRange
  ]);

  const {
    value: count,
    isLoading,
    execute: refreshList
  } = useAsync({
    asyncFunction: handleLoadTableData
  });
  const handleDeleteItem = async (energyItem) => {
    await EnergyService.deleteEnergyItem(energyItem);
    refreshList();
  };

  const {
    Modal: DataRequestTemplateModal,
    handleShowModal: handleShowTemplateModal
  } = useModal({
    width: '60%'
  });

  const {
    Modal: CreateItemModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onCloseModal: () => {
      refreshList();
      setActiveItem(undefined);
    }
  });

  const {
    Modal: BUModal,
    handleShowModal: handleShowBUModal,
    handleCloseModal: handleCloseBUModal
  } = useModal({
    width: '90%'
  });
  const handleOpenDeleteItemModal = (energyItem) => {
    AntModal.confirm({
      className: 'ch-modal',
      icon: null,
      closable: true,
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: { className: 'ch-button-new primary', shape: 'round' },
      cancelButtonProps: {
        className: 'ch-button-new secondary',
        shape: 'round'
      },
      content: 'Are you sure you want to delete this?',
      onOk: async () => {
        analytics.track('Delete item clicked', me, {
          ...analyticsContext,
          energyItem
        });
        await handleDeleteItem(energyItem);
        await refreshChart();
      }
    });
  };

  const handleShowNewItemModal = () => {
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      level4: 'Item Modal'
    });
    handleShowModal();
  };

  const handleEnergyItemModalSuccess = () => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      energyItem: activeItem,
      level4: 'Item Modal'
    });
    refreshChart();
    refreshList();
    refreshTimeline();
    handleCloseModal();
  };
  const handleEditItemModal = (item) => {
    analytics.track('Edit Item Clicked', me, {
      meId: me.id,
      energyItem: item,
      ...analyticsContext
    });
    setActiveItem(item);
    handleShowModal();
  };

  const handleDateRangeChange = (date) => {
    setDateRange(date ?? null);
  };

  const columns = [
    {
      key: 'Period',
      title: <ColumnTitle>Period</ColumnTitle>,
      render: (id, record) =>
        record?.billingStart && record?.billingEnd
          ? `${formatInTimeZone(
              new Date(record?.billingStart),
              'utc',
              dateDisplayFormat
            )} - ${formatInTimeZone(
              new Date(record?.billingEnd),
              'utc',
              dateDisplayFormat
            )}`
          : ''
    },
    {
      key: 'usage',
      title: <ColumnTitle>Usage</ColumnTitle>,
      render: (_, item) => item.activity
    },
    {
      key: 'unit',
      title: <ColumnTitle>Unit</ColumnTitle>,
      render: (_, item) => capitalizeText(item.activityUnit?.name)
    },
    {
      key: 'emissionTotal',
      dataIndex: 'emissionTotal',
      title: <ColumnTitle>Impact (kg Co2e)</ColumnTitle>,
      render: (emissionTotal) => formatDecimal(Number(emissionTotal), 2)
    },
    {
      key: 'dateSource',
      title: <ColumnTitle>Data Source</ColumnTitle>,
      render: (_, item) => {
        if (
          item.sourceDetails?.origin ===
          EmissionSourceItemOrigin.CARBONHOUND_CONNECT
        ) {
          if (!!item.isEstimate)
            return EmissionSourceItemOriginCopy.CARBONHOUND_CONNECT_ESTIMATE;
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.CARBONHOUND_CONNECT
          ];
        }
        if (!!item.isEstimate) {
          return EmissionSourceItemOriginCopy[
            EmissionSourceItemOrigin.ESTIMATION
          ];
        }
        return EmissionSourceItemOriginCopy.BILL;
      }
    },
    {
      key: 'createdBy',
      dataIndex: 'createdBy',
      title: <ColumnTitle>Created by</ColumnTitle>,
      render: (CreatedBy) => capitalizeText(getFullName(CreatedBy))
    },
    {
      width: '50px',
      render: (_, energyItem) => (
        <Space size={24}>
          <ButtonIcon
            onClick={() => handleEditItemModal(energyItem)}
            name="Pencil"
            color={COLOURS_GRAY.GRAY_500}
            backgroundColour="transparent"
          />
          <ButtonIcon
            onClick={() => handleOpenDeleteItemModal(energyItem)}
            name="MinusCircle"
            backgroundColour="transparent"
            color={COLOURS_GRAY.GRAY_400}
          />
        </Space>
      )
    }
  ];

  return (
    <div className="energy-item-table">
      {isEstimate ? (
        <Empty
          icon="Calculator"
          title="This source is estimated"
          description="You have set that you do not know the energy types for this location so we have estimated this source based off of your regional averages. If you now know the energy types please edit them in the Location Settings"
        />
      ) : (
        <Row gutter={[0, 40]}>
          {requireNotifications && (
            <Col span={24}>
              <Card>
                <Loader isLoading={isLoadingTimeline}>
                  <CompletionTimeline timeline={timeline} />
                </Loader>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Table
              isLoading={isLoading}
              isHeaderBorder
              dataSource={itemList}
              pagination={paginationParams}
              showPagination
              columns={columns}
              total={count}
              title={() => (
                <TableTitle
                  buttons={[
                    {
                      type: BUTTON_TYPES.LINK,
                      children: 'Data Request Template',
                      onClick: handleShowTemplateModal
                    },
                    {
                      type: BUTTON_TYPES.SECONDARY,
                      children: 'Data Import',
                      onClick: handleShowBUModal
                    },
                    {
                      children: 'Add Item',
                      onClick: handleShowNewItemModal
                    }
                  ]}
                  isHeaderBorder
                  dateFilterProps={{
                    value: dateRange,
                    onChange: handleDateRangeChange
                  }}
                />
              )}
            />
          </Col>
          <DataRequestTemplateModal title="Data request template">
            <DataRequestTemplate />
          </DataRequestTemplateModal>
          <CreateItemModal>
            <EnergyItemForm
              energyDetailsId={energyDetailsId}
              energyTypeId={energyTypeId}
              energyItem={activeItem}
              timeline={timeline}
              onCancel={handleCloseModal}
              onSuccess={handleEnergyItemModalSuccess}
            />
          </CreateItemModal>

          <BUModal title="Upload your file" className="bulk-upload-modal">
            <BulkUploadForm
              notificationReqParams={{
                officeLocationId: locationId,
                energyDetailsId,
                emissionSourceDetailsId: emissionSourceDetails?.id,
                unitFamilyNames:
                  emissionSourceDetails?.emissionFactor?.unitFamilyNames,
                address: emissionSourceDetails?.collections?.find(
                  (cl) => cl.type === 'LOCATION'
                ).location.address1
              }}
              notification={{
                details: {
                  uploadType: BulkUploadType.ENERGY
                }
              }}
              internalSpec={EMISSION_SOURCE_INTERNAL_SPECS.ENERGY}
              handleCloseBulkUploadsModal={handleCloseBUModal}
            />
          </BUModal>
        </Row>
      )}
    </div>
  );
}
export default EmissionSourceEnergyItemTable;
