import { Col, Layout, Row } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';
import { NotificationCategory } from '../../../constants';
import { COLOURS_GRAY } from '../../../constants/colours';
import DateFormats from '../../../constants/dateFormats';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { NotificationService } from '../../../services';
import { ButtonIcon } from '../../../stories/atoms';
import { ActionsCustomEmissionSourceForm } from '../ActionsCustomEmissionSourceForm';
import { ActionsEmployeeForm } from '../ActionsEmployeeForm';
import { ActionsEnergyForm } from '../ActionsEnergyForm';
import { ActionsGapIntensityForm } from '../ActionsGapIntensityForm';
import { ActionsTravelForm } from '../ActionsTravelForm';
import './ActionsModal.scss';
import { ActionsModalFooter } from './ActionsModalFooter';
import { ActionsModalSidebar } from './ActionsModalSidebar';

const getDisplayForm = (category) => {
  switch (category) {
    case NotificationCategory.GAP_ENERGY:
      return ActionsEnergyForm;
    case NotificationCategory.GAP_COMMUTE:
      return ActionsEmployeeForm;
    case NotificationCategory.GAP_TRAVEL:
      return ActionsTravelForm;
    case NotificationCategory.GAP_CUSTOM_SOURCE:
      return ActionsCustomEmissionSourceForm;
    case NotificationCategory.GAP_INTENSITY_METRIC:
      return ActionsGapIntensityForm;
    default:
      return null;
  }
};

const getAnalyticsContext = (category) => {
  switch (category) {
    case NotificationCategory.GAP_ENERGY:
      return {
        level1: 'Actions',
        level2: 'Office Energy Action'
      };
    case NotificationCategory.GAP_COMMUTE:
      return {
        level1: 'Actions',
        level2: 'Employee Detail Action'
      };
    case NotificationCategory.GAP_TRAVEL:
      return {
        level1: 'Actions',
        level2: 'Business Travel Action'
      };
    case NotificationCategory.GAP_CUSTOM_SOURCE:
      return {
        level1: 'Actions',
        level2: 'Custom Emission Source Action'
      };
    default:
      return {};
  }
};

const { Sider, Header, Footer, Content } = Layout;

export function ActionsModal({ action, onSuccess, onClose }) {
  const { me } = useMeContext();
  const { id } = action;

  const { category } = action;
  const analyticsContext = getAnalyticsContext(category);
  const DisplayForm = getDisplayForm(category);
  const [isDataIncomplete, setIsDataIncomplete] = useState(
    category !== NotificationCategory.GAP_TRAVEL
  );

  const handleEditNotification = async (value, formKey) => {
    if (formKey === 'dueDate') {
      value = formatInTimeZone(new Date(value), 'UTC', DateFormats.API);
    }

    analytics.track('Edit Item Clicked', me, {
      ...analyticsContext,
      notification: action,
      editField: formKey
    });
    await NotificationService.updateNotificationAction({
      [formKey]: value,
      id
    });
    onSuccess?.();
  };

  const { execute: editNotification } = useAsync({
    asyncFunction: handleEditNotification,
    immediate: false
  });

  return (
    <Layout className="actions-modal">
      <Layout>
        <Sider theme="light" width={344}>
          <ActionsModalSidebar
            editNotification={editNotification}
            action={action}
          />
        </Sider>
        <Layout>
          <Header theme="light">
            <Row justify="end">
              <Col>
                <ButtonIcon
                  backgroundColour="transparent"
                  name="Times"
                  color={COLOURS_GRAY.GRAY_400}
                  onClick={onClose}
                />
              </Col>
            </Row>
          </Header>
          <Content className="actions-modal__content">
            <DisplayForm
              action={action}
              analyticsContext={analyticsContext}
              onSuccess={onSuccess}
              setIsDataIncomplete={setIsDataIncomplete}
            />
          </Content>
        </Layout>
      </Layout>
      <Footer>
        <ActionsModalFooter
          action={action}
          onClose={onClose}
          onSuccess={onSuccess}
          analyticsContext={analyticsContext}
          isDataIncomplete={isDataIncomplete}
        />
      </Footer>
    </Layout>
  );
}
