import { Col, Row } from 'antd';
import { COLOURS_YELLOW } from '../../../constants/colours';
import regularGus from '../../../stories/assets/svgs/Gus/regularGus.svg';
import {
  Anchor,
  Button,
  Icon,
  Img,
  Paragraph,
  Title
} from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './onboardingWelcome.scss';

function OnboardingWelcome({ onStartOnboardingHandler }) {
  return (
    <Row
      align="middle"
      justify="center"
      className="welcome__container"
      gutter={56}
    >
      <Col span={12}>
        <Row gutter={[0, 40]}>
          <Col span={24} className="display-flex align-center">
            <Img src={regularGus} />
          </Col>
          <Col span={24}>
            <Title size="xl" bottomSpacing={32}>
              Welcome to Carbonhound
            </Title>
            <Paragraph size="lg">
              We are excited to be a part of your journey to a climate pawsitive
              future! To complete your team’s onboarding we will need a few more
              details from you. Let’s get started.
            </Paragraph>
          </Col>
          <Col className="welcome-button__container">
            <Button icon="ArrowRight" onClick={onStartOnboardingHandler}>
              Start Onboarding
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row justify="center">
          <Col flex="429px" className="blurb-bg-gray">
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Icon
                  className="mb-6"
                  size={24}
                  name="LightBulb"
                  color={COLOURS_YELLOW.YELLOW_500}
                  bgColor={COLOURS_YELLOW.YELLOW_100}
                />
              </Col>
              <Col span={24}>
                <Title bottomSpacing={0}>Not sure where to get started? </Title>
                <Paragraph bottomSpacing={24}>
                  Our climate team can help you set up your sustainability
                  strategy and ensure you are set up for success!
                </Paragraph>
              </Col>
              <Col span={24}>
                <Button type={BUTTON_TYPES.SECONDARY}>
                  <Anchor
                    showLinkIcon={false}
                    flex
                    href="https://meetings.hubspot.com/hannah472/carbonhound-contact-consultant-inbound"
                  >
                    Book a free consultation
                  </Anchor>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OnboardingWelcome;
