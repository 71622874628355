import { Table as AntTable } from 'antd';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { isNaN } from 'formik';
import { TIMEFRAME_VALUES } from '../../../constants';
import { AGGREGATE_TYPE_LABELS } from '../../../constants/aggregateType';
import { useMeContext } from '../../../context';
import { formatDecimal } from '../../../helpers';
import {
  formatSingleEmission,
  getQuarterName
} from '../../../helpers/generators';
import { Paragraph, Table } from '../../../stories/atoms';
import './ReportingTable.scss';

const SummaryRow = AntTable.Summary.Row;
const SummaryCell = AntTable.Summary.Cell;

const filterDateValues = (key) => !isNaN(Date.parse(key));
const filterNumberValues = (value) => typeof value === 'number';
const reduceTotalValues = (acc, curr) => acc + formatSingleEmission(curr);
const mapFilteredDateToColumn = (date, timeUnit, startingMonth) => {
  let title;
  if (
    timeUnit === TIMEFRAME_VALUES.MONTH ||
    timeUnit === TIMEFRAME_VALUES.YEAR
  ) {
    const timeFormat =
      timeUnit === TIMEFRAME_VALUES.MONTH ? 'MMM yyyy' : 'yyyy';
    title = formatInTimeZone(new Date(date), 'UTC', timeFormat);
  } else {
    const quarterNumber = getQuarterName(startingMonth, date);
    title = `Q${quarterNumber}`;
  }
  return {
    title,
    render: (_, row) => formatDecimal(formatSingleEmission(row[date]), 3)
  };
};

const generateSummaryRowFn = (currentData) => {
  const columnDisplayTotals = [];
  const ColumnValues = currentData.map((curr) =>
    Object.values(curr).filter(filterNumberValues)
  );
  ColumnValues.forEach((valueArr) => {
    valueArr.forEach((value, idx) => {
      if (columnDisplayTotals[idx]) {
        columnDisplayTotals[idx] += value;
      } else {
        columnDisplayTotals[idx] = value;
      }
    });
  });
  const totalTableValue = columnDisplayTotals.reduce(
    (acc, curr) => acc + formatDecimal(formatSingleEmission(curr), 3),
    0
  );
  return (
    <SummaryRow className={classNames('reporting-table__total-row')}>
      <SummaryCell index={0}>Total</SummaryCell>
      {columnDisplayTotals.map((totalValue) => (
        <SummaryCell>
          {formatDecimal(formatSingleEmission(totalValue), 3)}
        </SummaryCell>
      ))}
      <SummaryCell index={(columnDisplayTotals.length ?? 0) + 1}>
        {formatDecimal(totalTableValue, 3)}
      </SummaryCell>
    </SummaryRow>
  );
};

export function ReportingTable({
  listData = [],
  selectedTimeUnit,
  selectedChartType
}) {
  const { me } = useMeContext();
  const { company } = me;
  const { startingMonth } = company;
  const filterColumns = Object.keys(listData[0] ?? {})
    .filter(filterDateValues)
    .map((date) =>
      mapFilteredDateToColumn(date, selectedTimeUnit, startingMonth)
    );
  const columns = [
    {
      title: `${
        AGGREGATE_TYPE_LABELS[selectedChartType] || ''
      } (total emissions in tonnes CO2e)`,
      dataIndex: 'name',
      fixed: 'left',
      onCell: () => ({
        className: 'text-bd'
      })
    },
    ...filterColumns,
    {
      title: 'Total',
      fixed: 'right',
      width: 100,
      render: (_, obj) => {
        const total = Object.values(obj)
          .filter(filterNumberValues)
          .reduce(reduceTotalValues, 0);
        return <Paragraph>{formatDecimal(total, 3)}</Paragraph>;
      }
    }
  ];

  return (
    <Table
      summary={generateSummaryRowFn}
      dataSource={listData}
      columns={columns}
      className="reporting-table"
    />
  );
}
