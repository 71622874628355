import { useState } from 'react';
import { Row, Col } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import { Title, Select, StackedBarChart } from '../../../stories/atoms';
import { useMeContext } from '../../../context';
import {
  formatSingleEmission,
  getDefaultYearForChart,
  getYearDropdownOptions
} from '../../../helpers/generators';
import { CategoryColours } from '../../../stories/atoms/StackedBarChart/StackedBarChart.types';
import { COLOURS_GRAY } from '../../../constants/colours';
import { useCollectionContext } from '../../../context/CollectionContext';

function CollectionEmissionSourcesChart() {
  const { me, selectCarbonYearRange } = useMeContext();
  const { collectionState, ChartLoader, isChartLoading, setSelectedDateRange } =
    useCollectionContext();
  const { selectedDateRange, chartData } = collectionState;
  const currentCarbonYear = selectCarbonYearRange({
    activeYear: getDefaultYearForChart({
      startingMonth: me.company?.startingMonth
    })
  });

  const [chartDateYear, setChartDateYear] = useState(
    new Date(selectedDateRange.rangeStart).getUTCFullYear()
  );

  const footprintRangeDropdownOptions = [
    { label: 'All Time', value: -1 },
    ...getYearDropdownOptions()
  ];

  const labels = chartData?.emissionSummary?.map((summary) =>
    formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
  );

  const outputDataSets = () => {
    const dataMap = {};
    if (!chartData?.emissionSummary?.length) {
      return [];
    }
    const { ...legendLabels } = { ...chartData?.emissionSummary?.[0] };
    chartData?.emissionSummary?.forEach((summary) => {
      Object.keys(legendLabels).forEach((legendLabel) => {
        if (!dataMap[legendLabel]) {
          dataMap[legendLabel] = {
            label: legendLabel,
            backgroundColor: CategoryColours[legendLabel],
            data: []
          };
        }
        dataMap[legendLabel].data.push(
          formatSingleEmission(summary[legendLabel])
        );
      });
    });
    return Object.values(dataMap);
  };

  const handleChangeYear = (value) => {
    setChartDateYear(value);
    let rangeStart;
    let rangeEnd;

    if (value === -1) {
      rangeStart = 2006;
      ({ rangeEnd } = currentCarbonYear);
    } else {
      ({ rangeStart, rangeEnd } = selectCarbonYearRange({
        activeYear: value
      }));
    }
    setSelectedDateRange({ rangeStart, rangeEnd });
  };

  const data = {
    labels,
    datasets: outputDataSets()
  };

  return (
    <>
      <Row gutter={16} className="mb-6 align-center justify-space-between">
        <Col span={15}>
          <Title color={COLOURS_GRAY.GRAY_500} size="md">
            Overview
          </Title>
        </Col>
        <Col span={3}>
          <Select
            value={chartDateYear}
            disableForm
            placeholder="Select Year"
            options={footprintRangeDropdownOptions}
            onChange={handleChangeYear}
          />
        </Col>
      </Row>
      <ChartLoader>
        <Row>
          <Col span={24}>
            <StackedBarChart
              colorSchema={CategoryColours}
              stacked
              isLoading={isChartLoading}
              labels={chartData?.emissionSummary?.map((summary) =>
                summary.date
                  ? formatInTimeZone(new Date(summary.date), 'UTC', 'MMM yy')
                  : ''
              )}
              data={data}
            />
          </Col>
        </Row>
      </ChartLoader>
    </>
  );
}

export default CollectionEmissionSourcesChart;
