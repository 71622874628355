import { Col, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { chartColours } from '../../../constants/chartColours';
import { useMeContext } from '../../../context';
import { analytics, getFullName } from '../../../helpers';
import { useAsync, useModal } from '../../../hooks';
import RemindUsersForm from '../../../pages/Data/RemindUsersForm';
import { TravelExpenseService } from '../../../services';
import { Button, StackedBarChart } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import { TransportationTypeColours } from '../../../stories/atoms/StackedBarChart/StackedBarChart.types';
import { TravelItemFormButton } from '../../../stories/molecules';
import OwnerSelect from '../../../stories/molecules/OwnerSelect';

export function ActionsTravelForm({ action, onSuccess, analyticsContext }) {
  const { me } = useMeContext();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { details } = action;
  const { rangeStart, rangeEnd } = details;

  const handleFetchTravelDrilldown = useCallback(async () => {
    const travelData = await TravelExpenseService.fetchTravelExpenseSummary({
      companySlug: me?.company?.slug,
      rangeStart,
      rangeEnd,
      userCompanyIds: selectedUserIds,
      groupBy: 'USER'
    });
    const userMap = {};
    const labels = [];
    travelData.forEach((summary) => {
      labels.push(summary.month);
      summary.users.forEach((entry) => {
        const { user } = entry;
        const name = getFullName(user);
        if (!userMap[user.id]) userMap[user.id] = { name, totalEmissions: [] };
        userMap[user.id].totalEmissions.push(entry.totalEmissions);
      });
    });
    const datasets = [];
    Object.entries(userMap).forEach(([, value], i) => {
      datasets.push({
        label: value.name,
        data: value.totalEmissions,
        barPercentage: 0.25,
        borderRadius: 50,
        backgroundColor: chartColours[i]
      });
    });
    return {
      labels,
      datasets
    };
  }, [selectedUserIds]);
  const { value: chartData, execute: refreshTravelData } = useAsync({
    asyncFunction: handleFetchTravelDrilldown,
    defaultValue: {
      labels: [],
      datasets: []
    }
  });
  const onShowTravelModal = () => {
    analytics.track('Add new Selected', me, {
      ...analyticsContext,
      notification: action
    });
  };
  const {
    Modal: RemindUsersModal,
    handleCloseModal: handleCloseRemindUserModal,
    handleShowModal: handleOpenRemindUserModal
  } = useModal({ width: '60%' });

  const handleTravelItemFormSuccess = (travelExpense) => {
    analytics.track('Save item clicked', me, {
      ...analyticsContext,
      notification: action,
      travelItem: travelExpense
    });
    refreshTravelData();
    onSuccess?.();
  };
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between">
          <Col flex="231px">
            <OwnerSelect
              mode="multiple"
              value={selectedUserIds}
              disableForm
              name="ownerId"
              placeholder="Enter"
              onChange={(value) => {
                setSelectedUserIds(value);
              }}
            />
          </Col>
          <Col>
            <Space size={16}>
              <Button
                onClick={handleOpenRemindUserModal}
                type={BUTTON_TYPES.TEXT}
              >
                Notify users
              </Button>
              <TravelItemFormButton
                onSuccess={handleTravelItemFormSuccess}
                onOpen={onShowTravelModal}
                analyticsContext={analyticsContext}
              />
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <StackedBarChart
          data={chartData}
          yAxisText="Tonnes CO2 Equivalent"
          colorSchema={TransportationTypeColours}
        />
      </Col>
      <RemindUsersModal>
        <RemindUsersForm
          onSuccess={() => {
            analytics.track('Remind email clicked', me, {
              ...analyticsContext,
              level3: 'Send reminder',
              notification: action
            });
          }}
          onCancel={handleCloseRemindUserModal}
        />
      </RemindUsersModal>
    </Row>
  );
}
