import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Row, Space } from 'antd';
import { differenceInDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../constants';
import { COLOURS_GRAY, COLOURS_ORANGE } from '../../constants/colours';
import { useMeContext } from '../../context';
import { analytics, getFullName } from '../../helpers';
import { AppRoutes } from '../../routes';
import { AuthService } from '../../services';
import { Button, Icon, Paragraph, Tag } from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import { Logo } from '../../stories/atoms/Logo';
import ProfileImage from '../../stories/atoms/ProfileImage';
import { TAG_COLOURS } from '../../stories/atoms/Tag/tag.types';
import { SubscriptionStatusIndicator } from '../../stories/molecules';
import './appHeader.scss';

function AppHeader({ subscriptionStatus, expiryDateCount }) {
  const navigate = useNavigate();

  const location = useLocation();

  const [pathname, setPathname] = useState(location.pathname);

  const hideHeaderRoutes = [
    AppRoutes.SELECT_COMPANY,
    AppRoutes.CREATE_EMAIL,
    AppRoutes.CREATE_ACCOUNT,
    AppRoutes.COMPANY_INFORMATION,
    AppRoutes.TRAILS_SEGMENTS,
    AppRoutes.COMPANY_INITIATIVE_ROOT,
    AppRoutes.ONBOARDING_EMPLOYEE,
    AppRoutes.ONBOARDING_ADMIN
  ];

  const [showHeader, setShowHeader] = useState(() => {
    const item = hideHeaderRoutes.find((_item) =>
      location.pathname.startsWith(_item)
    );
    return !item;
  });

  const { me, isSuperUser, clearUser } = useMeContext();

  const isAdmin = me.company?.role?.permissionType === UserRole.ADMIN;

  const { subscriptions } = me.company ?? {};
  const currentSubscription = subscriptions?.[0] ?? {};
  const currentSubscriptionEndDate = new Date(currentSubscription.endDate);
  const remainingSubscriptionTime = Math.abs(
    differenceInDays(new Date(), currentSubscriptionEndDate)
  );
  const showSubscriptionWarning = remainingSubscriptionTime <= 15;

  const handleLogout = () => {
    AuthService.logout(() => {
      clearUser();
      navigate(AppRoutes.LOGIN);
    });
  };

  const menuItems = [
    {
      route: AppRoutes.DASHBOARD,
      icon: 'Home',
      label: 'Dashboard',
      key: 'dashboard',
      analyticsFn: () => {
        analytics.track('Dashboard Clicked', me, {
          level1: 'Top Navigation',
          level2: 'Dashboard'
        });
      }
    },
    ...(isAdmin || isSuperUser()
      ? [
          {
            route: AppRoutes.ACTIONS,
            icon: 'CheckCircle',
            label: 'Actions',
            key: 'actions',
            analyticsFn: () => {
              analytics.track('Dashboard Actions Clicked', me, {
                level1: 'Top Navigation',
                level2: 'Data Dashboard'
              });
            }
          }
        ]
      : []),
    {
      route: AppRoutes.INITIATIVE,
      icon: 'Map',
      label: 'Initiatives',
      key: 'initiatives',
      analyticsFn: () => {
        analytics.track('Initiative Overview Clicked', me, {
          level1: 'Top Navigation',
          level2: 'Trails'
        });
      }
    },
    ...(isAdmin || isSuperUser()
      ? [
          {
            route: AppRoutes.SOURCES_OVERVIEW,
            icon: 'Truck',
            label: 'Sources',
            key: 'Sources',
            analyticsFn: () => {
              analytics.track('EmissionSource Clicked', me, {
                level1: 'Top Navigation',
                level2: 'Sources'
              });
            }
          }
        ]
      : []),
    {
      route: AppRoutes.DATA_EXPLORER,
      icon: 'DocumentReport',
      label: 'Reporting',
      isBeta: true,
      key: 'Reporting',
      analyticsFn: () => {
        analytics.track('Reporting Clicked', me, {
          level1: 'Top Navigation',
          level2: 'Reporting'
        });
      }
    }
  ];

  useEffect(() => {
    setPathname(location.pathname);
    setShowHeader(() => {
      const item = hideHeaderRoutes.find((_item) =>
        location.pathname.startsWith(_item)
      );
      return !item;
    });
  }, [location]);

  const menu = (
    <Menu className="app-header__profile-dropdown-menu">
      <Menu.Item
        key="0"
        className={`app-header__profile-menu-item ${
          pathname === AppRoutes.USER_PROFILE ? 'active' : ''
        }`}
      >
        <Link to={AppRoutes.USER_PROFILE}>
          <Icon color={COLOURS_GRAY.GRAY_500} name="User" />
          My Profile
        </Link>
      </Menu.Item>
      {(isAdmin || isSuperUser()) && (
        <Menu.Item
          key="1"
          className={`app-header__profile-menu-item ${
            pathname === AppRoutes.USER_COMPANY ? 'active' : ''
          }`}
        >
          <Link to={AppRoutes.USER_COMPANY}>
            <Icon color={COLOURS_GRAY.GRAY_500} name="OfficeBuilding" />
            My Company
          </Link>
        </Menu.Item>
      )}
      {(isAdmin || isSuperUser()) && (
        <Menu.Item
          key="2"
          className={`app-header__profile-menu-item ${
            pathname === AppRoutes.DATA_IMPORTS ? 'active' : ''
          }`}
        >
          <Link to={AppRoutes.DATA_IMPORTS}>
            <Icon color={COLOURS_GRAY.GRAY_500} name="Upload" />
            Data Import
          </Link>
        </Menu.Item>
      )}
      {(isAdmin || isSuperUser()) && (
        <Menu.Item key="3" className="app-header__profile-menu-item">
          <Link to={AppRoutes.VERIFICATION}>
            <Icon color={COLOURS_GRAY.GRAY_500} name="CheckCircle" />
            Verification
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="4" className="app-header__profile-menu-item">
        <Link to={AppRoutes.CREDITS}>
          <Icon color={COLOURS_GRAY.GRAY_500} name="Libra" />
          Credits
        </Link>
      </Menu.Item>
      <Menu.Item
        key="5"
        className="app-header__profile-menu-item"
        onClick={handleLogout}
      >
        <Icon color={COLOURS_GRAY.GRAY_500} name="Logout" />
        Logout
      </Menu.Item>
    </Menu>
  );
  return me.token && showHeader ? (
    <div className="app-header" id="app-header">
      <Row align="middle" gutter={50}>
        <Col className="app-header__logo display-flex align-center ">
          <Row gutter={12} wrap={false}>
            <Col className="mr-3">
              <Link to={AppRoutes.DASHBOARD}>
                <Logo height={37} width={135} />
              </Link>
            </Col>
            {!!expiryDateCount && expiryDateCount <= 15 && (
              <Col>
                <SubscriptionStatusIndicator
                  expiryDateCount={expiryDateCount}
                  subscriptionStatus={subscriptionStatus}
                />
              </Col>
            )}
            <Col style={{ marginTop: '2px' }}>
              {showSubscriptionWarning && (
                <div className="app-header__expired ml-4">
                  <Paragraph
                    bottomSpacing={0}
                    color={COLOURS_ORANGE.ORANGE_600}
                    size="sm"
                  >
                    Free trial:{' '}
                    <span className="text-bd">{`${remainingSubscriptionTime} Days Left`}</span>
                  </Paragraph>
                  <Icon color={COLOURS_ORANGE.ORANGE_600} name="ChevronRight" />
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col flex="1">
          <ul className="app-header__menu-items">
            {menuItems.map((item) => (
              <li
                key={item.key}
                className={`app-header__menu-item ${
                  pathname === item.route ? 'active' : ''
                }`}
              >
                <NavLink
                  className="app-header__menu-item__link"
                  to={item.route}
                  onClick={() => {
                    item.analyticsFn();
                  }}
                >
                  <Icon size={16} name={item.icon} />
                  <span>{item.label}</span>
                </NavLink>
                {item.isBeta && (
                  <Tag
                    className="ml-2 app-header__menu-item__beta"
                    color={TAG_COLOURS.BLUE}
                  >
                    New
                  </Tag>
                )}
              </li>
            ))}
          </ul>
        </Col>
        <Col flex="shrink" className="text-right">
          <Space size={16} wrap>
            <NavLink to={AppRoutes.HELP}>
              <Button
                type={BUTTON_TYPES.PRIMARY_ACCENT}
                prefixIcon="InformationCircle"
              >
                Help
              </Button>
            </NavLink>
            <ul className="app-header__profile-items">
              <li className="app-header__profile-dropdown">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button onClick={(e) => e.preventDefault()} type="">
                    <ProfileImage name={getFullName(me)} size="small" />
                    <div className="app-header__profile-details">
                      <div className="app-header__profile-name">
                        {me?.company?.name}
                        <DownOutlined />
                      </div>
                      <div className="app-header__profile-role">
                        {getFullName(me)}
                      </div>
                    </div>
                  </Button>
                </Dropdown>
              </li>
            </ul>
          </Space>
        </Col>
      </Row>
    </div>
  ) : null;
}

export default AppHeader;
