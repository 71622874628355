import classNames from 'classnames';
import React from 'react';
import './card.scss';

function Card({ className, children, small, large }) {
  return (
    <div
      className={classNames('ch-card', {
        [className]: !!className,
        small: !!small,
        large: !!large
      })}
    >
      {children}
    </div>
  );
}

export default Card;
