import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../../components';
import { useMeContext } from '../../../../context';
import { useInitiativeContext } from '../../../../context/InitiativeContext';
import { useModal } from '../../../../hooks';
import { analytics } from '../../../../helpers';
import { AppRoutes } from '../../../../routes';
import { Button, Icon, Paragraph, Title } from '../../../../stories/atoms';
import { CompanyInitiativeForm } from '../../CompanyInitiative/CompanyInitiativeForm';
import './InitiativeTemplateSidebar.scss';

export function InitiativeTemplateSidebar({ isLoading, analyticsContext }) {
  const { handleLoadInitiativeList, initiativeState } = useInitiativeContext();
  const navigate = useNavigate();
  const { me, isAdmin } = useMeContext();
  const { initiativeTemplateId, initiativeTemplate } = initiativeState;
  const { relatedInitiatives } = initiativeTemplate;
  const {
    Modal: CreateInitiativeModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    width: '60%',
    onShowModal: () => {
      analytics.track('Add new Selected', me, {
        ...analyticsContext,
        initiativeTemplateId
      });
    },
    onCloseModal: () => {
      handleLoadInitiativeList({
        initiativeTemplateId,
        pagination: {
          pageSize: 5,
          order: 'ASC',
          orderBy: ['name']
        }
      });
    }
  });
  const handleCreateModalSuccess = ({ id }) => {
    navigate(AppRoutes.COMPANY_INITIATIVE.replace(':companyInitiativeId', id));
  };
  return (
    <Row gutter={[16, 43]} className="initiative-template-sidebar">
      <Loader isLoading={isLoading}>
        <Col span={24}>
          <Title size="md">Estimated Reductions</Title>
        </Col>
        <Col span={12}>
          <Paragraph
            size="sm"
            bottomSpacing={0}
            className="display-flex align-center"
          >
            <Icon name="TrendingDown" className="mr-2" /> Est. Reduction
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph align="end" bottomSpacing={0} weight={600}>
            {initiativeTemplate?.details?.estimatedReduction}
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph
            size="sm"
            bottomSpacing={0}
            className="display-flex align-center"
          >
            <Icon name="Clock" className="mr-2" /> Est. Timeline
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph align="end" bottomSpacing={0} weight={600}>
            {initiativeTemplate?.details?.estimatedCompletionTimeline}
          </Paragraph>
        </Col>
        {initiativeTemplate?.details?.estimatedReturnTimeline && (
          <>
            <Col span={12}>
              <Paragraph
                size="sm"
                bottomSpacing={0}
                className="display-flex align-center"
              >
                <Icon name="CurrencyDollar" className="mr-2" />
                Return on investment
              </Paragraph>
            </Col>
            <Col span={12}>
              <Paragraph align="end" bottomSpacing={0} weight={600}>
                {initiativeTemplate?.details?.estimatedReturnTimeline}
              </Paragraph>
            </Col>
          </>
        )}
        {isAdmin && (
          <Col span={24}>
            <Button onClick={handleShowModal} icon="ArrowRight" fullWidth>
              Use Template
            </Button>
          </Col>
        )}
      </Loader>
      <CreateInitiativeModal
        title="Create Initiative using template"
        onClose={handleCloseModal}
      >
        <CompanyInitiativeForm
          initiativeTemplateId={initiativeTemplate.id}
          onSuccess={handleCreateModalSuccess}
          analyticsContext={analyticsContext}
          relatedInitiatives={relatedInitiatives}
        />
      </CreateInitiativeModal>
    </Row>
  );
}
