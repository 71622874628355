import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChartBaseSettings } from '../../../constants';
import { capitalizeText, formatDecimal } from '../../../helpers';

defaults.font.size = 12;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StackedBarChart({
  data,
  yAxisText = 'Tonnes CO2 equivalent',
  colorSchema,
  excludedTooltipDatasets = []
}) {
  const { datasets = [], labels = [] } = data ?? {};

  useEffect(() => {
    window.addEventListener('beforeprint', () => {
      for (const id in ChartJS.instances) {
        ChartJS.instances[id].resize(768, 600);
      }
    });
    return () => {
      window.removeEventListener('beforeprint', () => {});
    };
  }, [ChartJS.instances]);
  useEffect(() => {
    window.addEventListener('afterprint', () => {
      for (const id in ChartJS.instances) {
        ChartJS.instances[id].resize();
      }
    });
    return () => {
      window.removeEventListener('afterprint', () => {});
    };
  }, [ChartJS.instances]);

  return (
    <div className="bar-chart">
      <Bar
        height={360}
        data={{
          datasets: datasets.map((ds) => {
            const bgColour =
              ((colorSchema?.[ds.label] || ds.backgroundColor) &&
                colorSchema?.[ds.label]) ||
              ds.backgroundColor;
            return {
              ...ds,
              backgroundColor: bgColour,
              label: capitalizeText(ds.label),
              borderRadius: 2,
              maxBarThickness: 16
            };
          }),
          labels
        }}
        options={{
          ...BarChartBaseSettings,
          scales: {
            ...BarChartBaseSettings.scales,
            y: {
              ...BarChartBaseSettings.scales.y,
              title: {
                ...BarChartBaseSettings.scales.y.title,
                text: yAxisText ?? 'Tonnes CO2 equivalent'
              }
            }
          },
          plugins: {
            ...BarChartBaseSettings.plugins,

            tooltip: {
              callbacks: {
                afterTitle: (tooltipItems) => {
                  const monthlyTotal = tooltipItems.reduce((acc, curr) => {
                    if (excludedTooltipDatasets.includes(curr.dataset.label))
                      return acc;
                    acc += Number(curr.raw || 0);
                    return formatDecimal(acc, 3);
                  }, 0);
                  return `Total ${monthlyTotal}`;
                }
              },
              mode: 'index'
            }
          }
        }}
      />
    </div>
  );
}

export default StackedBarChart;
