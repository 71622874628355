import { Layout } from 'antd';
import classNames from 'classnames';
import './LayoutComponent.scss';

export function LayoutComponent({ children, className }) {
  return (
    <Layout
      className={classNames('ch-layout', {
        [className]: !!className
      })}
    >
      {children}
    </Layout>
  );
}
