import { TAG_COLOURS } from '../stories/atoms/Tag/tag.types';

export const INITIATIVE_TYPE = {
  REDUCTION: 'REDUCTION',
  MEASUREMENT: 'MEASUREMENT'
};

export const INITIATIVE_TYPE_TAG_COLOURS = {
  [INITIATIVE_TYPE.REDUCTION]: TAG_COLOURS.INDIGO,
  [INITIATIVE_TYPE.MEASUREMENT]: TAG_COLOURS.VIOLET
};

export const INITIATIVE_TYPE_BENEFIT = {
  [INITIATIVE_TYPE.REDUCTION]: {
    description: 'Reduce 5 tonnes of CO2e',
    icon: 'TrendingDown'
  },
  [INITIATIVE_TYPE.MEASUREMENT]: {
    description: 'GHG Protocol Standard',
    icon: 'CheckCircle'
  }
};
