import { Form, Formik } from 'formik';
import EmissionSourceService from '../../../../services/emissionSourceService';
import { ModalFooter } from '../../../../stories/atoms';
import SelectEmissionFactor from '../EmissionSourceDetailForm/SelectEmissionFactor';
import { SelectEmissionFactorValidation } from '../EmissionSourceDetailForm/SelectEmissionFactor/SelectEmissionFactorValidation';

function EmissionSourceDetailFactorForm({ emissionSourceDetails, onSuccess }) {
  const handleOnSubmit = async ({ emissionFactorDetailsId }) => {
    await EmissionSourceService.updateEmissionSourcesDetails(
      { emissionFactorDetailsId, id: emissionSourceDetails.id },
      () => {
        onSuccess?.(emissionFactorDetailsId);
      }
    );
  };

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={emissionSourceDetails}
      enableReinitialize
      validationSchema={SelectEmissionFactorValidation}
    >
      {({ _values, _setFieldValue, isValid }) => (
        <Form>
          <SelectEmissionFactor
            activityUnitName={
              emissionSourceDetails?.emissionFactor?.activityUnit?.name
            }
          />
          <ModalFooter
            saveButtonProps={{
              htmlType: 'submit',
              disabled: !isValid
            }}
            showHelpLink={false}
            showCancelButton={false}
            saveButtonCopy="Save"
          />
        </Form>
      )}
    </Formik>
  );
}
export default EmissionSourceDetailFactorForm;
