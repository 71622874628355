import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, DropdownField, SignificanceCard } from '../../../components';
import { useMeContext } from '../../../context';
import { getDropdownOptions } from '../../../helpers';
import { rerouteHelper } from '../../../helpers/rerouteHelper';
import { AppRoutes } from '../../../routes';
import { CompanyService, MeService } from '../../../services';
import { Button, Logo } from '../../../stories/atoms';
import './selectCompany.scss';
import { selectCompanyFormValidation } from './selectCompanyFormValidation';

function SelectCompany() {
  const [formLoading, setFormLoading] = useState(false);

  const { setJWTToken, getMyDetails, isSuperUser } = useMeContext();

  const [companyOptions, setCompanyOptions] = useState([]);

  const location = useLocation();

  const origin = new URLSearchParams(location.search).get('origin');

  const navigate = useNavigate();
  const handleSubmit = async ({ companySlug }) => {
    setFormLoading(true);
    const response = await MeService.changeActiveCompany(companySlug);
    setJWTToken(response.token);
    setFormLoading(false);
    const user = await getMyDetails();
    const rerouteUrl = rerouteHelper(user);
    if (!!rerouteUrl) {
      navigate(rerouteUrl);
    } else {
      navigate(
        origin === 'commute' ? AppRoutes.EMPLOYEE_COMMUTE : AppRoutes.DASHBOARD
      );
    }
  };

  useEffect(() => {
    if (isSuperUser()) {
      CompanyService.listCompanies((companies) => {
        setCompanyOptions(getDropdownOptions(companies, 'name', 'slug'));
      });
    } else {
      MeService.fetchMyCompanies(
        (companies) => {
          setCompanyOptions(getDropdownOptions(companies, 'name', 'slug'));
        },
        () => {},
        () => {}
      );
    }
  }, []);

  return (
    <div className="select-company">
      <Row>
        <Col span={6}>
          <SignificanceCard showLogo={false} />
        </Col>
        <Col span={18} className="select-company__form-wrapper">
          <Card className="select-company__form-card">
            <Logo className="select-company__logo" />
            <h5 className="mb-5">Select Organization</h5>
            <Formik
              enableReinitialize
              initialValues={{
                companySlug: ''
              }}
              onSubmit={handleSubmit}
              validationSchema={selectCompanyFormValidation}
            >
              {({ values, _errors, isValid, dirty, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <DropdownField
                        name="companySlug"
                        value={values?.vehicleId}
                        options={companyOptions}
                        setFieldValue={setFieldValue}
                        placeholder="Search and select"
                        showSearch
                      />
                    </Col>
                    <Col
                      span={24}
                      className="display-flex justify-center select-company__submit-wrapper"
                    >
                      <Button
                        htmlType="submit"
                        disabled={!isValid || !dirty || formLoading}
                        loading={formLoading}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default SelectCompany;
