import { Col, Row, Space } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { INITIATIVE_TYPE_TAG_COLOURS } from '../../../../constants/InitiativeType';
import { useMeContext } from '../../../../context';
import { useCompanyInitiativeLayoutContext } from '../../../../context/CompanyInitiativeLayoutContext';
import { useInitiativeContext } from '../../../../context/InitiativeContext';
import { analytics, capitalizeText } from '../../../../helpers';
import { useModal } from '../../../../hooks';
import {
  COMPANY_INITIATIVE_STEPS,
  COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS,
  CompanyInitiativeStepsTuple
} from '../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.constants';
import { getIsSetupComplete } from '../../../../pages/Initiative/CompanyInitiative/CompanyInitiative.generators';
import { Button, Paragraph, Tag, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import {
  INTERNAL_TAG_COLOURS,
  TAG_COLOURS
} from '../../../../stories/atoms/Tag/tag.types';
import { CompanyInitiativeForm } from '../CompanyInitiativeForm';
import './CompanyInitiativeHeader.scss';

const headerTitleContents = {
  [COMPANY_INITIATIVE_STEPS.IMPACTED_SOURCES]: {
    title: 'Impacted Sources',
    description:
      'Select the specific sources impacted by this initiative so your reductions can be properly measured'
  },
  [COMPANY_INITIATIVE_STEPS.TARGET_BASELINE]: {
    title: 'Baseline & Goal',
    description:
      'Select the specific sources impacted by this initiative so your reductions can be properly measured'
  },
  [COMPANY_INITIATIVE_STEPS.RECOMMENDED_ACTIONS]: {
    title: 'Recommended Actions',
    description: ''
  },
  [COMPANY_INITIATIVE_STEPS.FUNDING]: {
    title: 'Funding',
    description:
      'Browse & select your potential grants & financial partnerships.'
  },
  [COMPANY_INITIATIVE_STEPS.BUSINESS_PLAN]: {
    title: 'Business Plan',
    description: 'Plan around the financial impact of the reduction initiative.'
  }
};

const buttonOnClick = ({
  isFirstStep,
  isSetupComplete,
  companyTrailOverviewList,
  handleSaveAndExit,
  handleFirstStepAction,
  companyInitiative,
  me
}) => {
  const { initiativeTemplate } = companyInitiative;
  if (isFirstStep && companyTrailOverviewList && !isSetupComplete) {
    analytics.track('Next Step Clicked', me, {
      level1: 'Initiatives',
      level2: 'Overview',
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id
    });
    handleFirstStepAction();
  } else {
    handleSaveAndExit();
  }
};
const handleSetButtonProps = (params) => {
  const { isFirstStep, companyTrailOverviewList, isSetupComplete } = params;
  if (isFirstStep && companyTrailOverviewList && !isSetupComplete) {
    return {
      onClick: () => buttonOnClick(params),
      type: BUTTON_TYPES.PRIMARY,
      children: companyTrailOverviewList.length ? 'Continue' : 'Get Started'
    };
  }
};

export function CompanyInitiativeHeader() {
  const { initiativeState, handleSetInitiativeState } = useInitiativeContext();
  const { companyInitiativeLayoutState, navStep } =
    useCompanyInitiativeLayoutContext();
  const { prevRoute, currentStep, companyTrailOverviewList } =
    companyInitiativeLayoutState;
  const { companyInitiative, companyInitiativeId } = initiativeState;
  const { name, endDate, initiativeTemplate, completeAt } = companyInitiative;
  const { tags, id: initiativeTemplateId } = initiativeTemplate;
  const navigate = useNavigate();
  const handleSaveAndExit = () => {
    navigate(prevRoute);
  };
  const { me } = useMeContext();
  const {
    Modal: CreateInitiativeModal,
    handleShowModal,
    handleCloseModal
  } = useModal({
    onShowModal: () => {
      analytics.track('Edit Item Clicked', me, {
        level1: 'Initiatives',
        level2: 'Overview',
        initiativeTemplateId: initiativeTemplate?.id,
        companyInitiativeId: companyInitiative.id
      });
    },
    width: '60%'
  });

  const onEditSuccess = (updatedCompanyInitiative) => {
    if (!!updatedCompanyInitiative) {
      handleSetInitiativeState({
        companyInitiative: {
          ...companyInitiative,
          ...updatedCompanyInitiative
        }
      });
    }
    handleCloseModal();
  };

  const isFirstStep = currentStep === CompanyInitiativeStepsTuple[0];
  const isSetupComplete = getIsSetupComplete(companyTrailOverviewList);

  // Find the last incomplete step in the trail.
  // If there is no matching case, go to the latest step in the list
  // Otherwise just go to step one as a safe backup
  const nextStep = !!companyTrailOverviewList.length
    ? companyTrailOverviewList?.findLast(
        (trail) => trail.isStarted && !trail.isComplete
      )?.segmentName ??
      companyTrailOverviewList[companyTrailOverviewList.length - 1]?.segmentName
    : CompanyInitiativeStepsTuple[1];
  const handleFirstStepAction = () => {
    navStep(
      COMPANY_INITIATIVE_STEPS_ROUTE_MAPPINGS[nextStep].replace(
        ':companyInitiativeId',
        companyInitiativeId
      )
    );
  };
  const onDeleteSuccess = () => {
    handleSaveAndExit();
  };
  const buttonProps = handleSetButtonProps({
    isFirstStep,
    isSetupComplete,
    companyTrailOverviewList,
    handleSaveAndExit,
    handleFirstStepAction,
    companyInitiative,
    me
  });
  return (
    <Row gutter={16} className="company-initiative-header">
      <Col flex="1">
        {isFirstStep ? (
          <Row>
            <Col span={24}>
              <Paragraph size="sm" bottomSpacing={8}>
                Initiative Name
              </Paragraph>
            </Col>
            <Col span={24}>
              <Col flex="1">
                <Space size={[8, 8]} align="center">
                  <Title size="xl" bottomSpacing={0}>
                    {name}
                  </Title>
                  {tags?.map((tag, i) => (
                    <Tag
                      key={i}
                      color={
                        INITIATIVE_TYPE_TAG_COLOURS[tag.name] ||
                        INTERNAL_TAG_COLOURS[tag.name] ||
                        TAG_COLOURS.ORANGE
                      }
                    >
                      {tag.icon && tag.icon}
                      {capitalizeText(tag.name)}
                    </Tag>
                  ))}
                </Space>
              </Col>
            </Col>
            <Col>
              <Paragraph bottomSpacing={0} size="sm">
                Target End Date:{' '}
                {endDate &&
                  formatInTimeZone(new Date(endDate), 'UTC', 'MMM dd, yyyy')}
              </Paragraph>
            </Col>
          </Row>
        ) : (
          <>
            <Title size="xl" bottomSpacing={8}>
              {headerTitleContents[currentStep]?.title}
            </Title>
            <Paragraph bottomSpacing={0} size="sm">
              {headerTitleContents[currentStep]?.description}
            </Paragraph>
          </>
        )}
      </Col>
      <Col className="display-flex align-center">
        <Space size={8}>
          {currentStep === COMPANY_INITIATIVE_STEPS.OVERVIEW && (
            <>
              {completeAt && (
                <Tag color={TAG_COLOURS.GREEN}>
                  Initiative Completed <span className="ml-1">🎉</span>
                </Tag>
              )}
              <Button onClick={handleShowModal} type={BUTTON_TYPES.TEXT}>
                Edit
              </Button>
              {!completeAt && !isSetupComplete && <Button {...buttonProps} />}
            </>
          )}
        </Space>
      </Col>
      <CreateInitiativeModal title="Edit Initiative" onClose={handleCloseModal}>
        <CompanyInitiativeForm
          companyInitiative={companyInitiative}
          initiativeTemplateId={initiativeTemplateId}
          onSuccess={onEditSuccess}
          onDeleteSuccess={onDeleteSuccess}
        />
      </CreateInitiativeModal>
    </Row>
  );
}
