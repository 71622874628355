import { PageHeader as AntPageHeader } from 'antd';
import classNames from 'classnames';
import { Paragraph, Title } from '../index';
import './PageHeader.scss';

function PageHeader({ _children, className, title, subTitle, ...props }) {
  return (
    <AntPageHeader
      className={classNames('ch-page-header', { [className]: !!className })}
      title={
        title && (
          <>
            <Title includeSpacing={false} size="xl">
              {title}
            </Title>
            {subTitle && (
              <Paragraph title={subTitle} bottomSpacing={0} weight={400}>
                {subTitle}
              </Paragraph>
            )}
          </>
        )
      }
      {...props}
    />
  );
}

export default PageHeader;
