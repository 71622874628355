import React, { useMemo, useState } from 'react';
import { Modal as ModalComponent } from '../components';
import ModalHeader from '../stories/atoms/ModalHeader';

const useModal = ({ onShowModal, onCloseModal, width = '50%' }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    onShowModal?.();
    setShowModal(true);
  };
  const handleCloseModal = (props) => {
    onCloseModal?.(props);
    setShowModal(false);
  };

  const Modal = useMemo(
    () =>
      function ({ children, title, showHeader = true, ...props }) {
        return showModal ? (
          <ModalComponent
            width={width}
            visible={showModal}
            onCancel={handleCloseModal}
            closable={false}
            title={!showHeader && title}
            {...props}
          >
            {showHeader && (
              <ModalHeader
                title={title}
                handleOnCloseModal={handleCloseModal}
              />
            )}
            {children}
          </ModalComponent>
        ) : null;
      },
    [showModal]
  );

  return { Modal, handleShowModal, handleCloseModal };
};

export default useModal;
