import { Col, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import * as dateFns from 'date-fns';

import { Notification } from '../../../components';
import { IntegrationStatus } from '../../../constants/status';
import { capitalizeText, getEmissionSourceDetailsName } from '../../../helpers';
import {
  getCollectionLocation,
  getFormattedAddress
} from '../../../helpers/generators';
import { useAsync } from '../../../hooks';
import { ChcAdminService, IntegrationService } from '../../../services';
import {
  ColumnTitle,
  CopyToClipboard,
  Select,
  Table
} from '../../../stories/atoms';
import TableTitle from '../../../stories/molecules/TableTitle';

function IntegrationSelect({
  emissionSource,
  handleUpdateConnectEmissionSource,
  ...props
}) {
  const [selectedIntegration, setSelectedIntegration] = useState(
    emissionSource.integration?.slug
  );
  return (
    <Select
      value={selectedIntegration}
      onChange={(_, integration) =>
        handleUpdateConnectEmissionSource(
          emissionSource,
          integration,
          setSelectedIntegration
        )
      }
      {...props}
    />
  );
}

function ChcAdminEmissionSourcesList({ companySlug }) {
  const [searchValue, setSearchValue] = useState('');

  const loadAdminSourcesList = useCallback(
    async () => await ChcAdminService.listCompanySources(companySlug),
    [companySlug]
  );
  const { value: sourcesList, isLoading } = useAsync({
    asyncFunction: loadAdminSourcesList,
    defaultValue: []
  });

  const loadCompanyIntegrations = useCallback(async () => {
    const response = await IntegrationService.listCompanyIntegrations({
      companySlug,
      parentIntegrationSlug: 'carbonhound-connect'
    });
    return response?.list ?? [];
  }, []);

  const { value: integrationList } = useAsync({
    asyncFunction: loadCompanyIntegrations,
    defaultValue: []
  });

  const integrationListOptions = integrationList.map((integration) => ({
    ...integration,
    label: capitalizeText(integration.slug),
    value: integration.urlSlug
  }));

  const filteredProviderList = sourcesList.filter((source) => {
    const location = getCollectionLocation(source);
    const locationNameFound =
      location?.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    const sourceNameFound =
      source.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    const sourceDisplayNameFound =
      source.displayName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    return sourceDisplayNameFound || locationNameFound || sourceNameFound;
  });

  const handleSearchList = (value) => {
    setSearchValue(value);
  };

  const updateConnectEmissionSource = async (
    emissionSource,
    selectedIntegration,
    setSelectedIntegration
  ) => {
    const upsertStatus = IntegrationStatus.CONNECTED;
    const emissionSourceDetailsId = emissionSource.id;
    const shouldCreateCompanyIntegration =
      integrationList.find(
        (listItem) => listItem.slug === selectedIntegration.slug
      )?.status === null;

    if (shouldCreateCompanyIntegration) {
      await IntegrationService.createCompanyIntegration({
        companySlug,
        integrationSlug: selectedIntegration.slug,
        status: upsertStatus
      });
    }
    try {
      const response = await ChcAdminService.updateEmissionSource({
        integrationName: selectedIntegration.name,
        integrationStatus: upsertStatus,
        emissionSourceDetailsId
      });

      if (!(response instanceof Error)) {
        Notification({
          type: 'success',
          message: `${emissionSource.name} Updated`,
          description: `Updated ${emissionSourceDetailsId} to ${upsertStatus}`
        });
        setSelectedIntegration(selectedIntegration);
      }
    } catch (error) {
      Notification({
        type: 'success',
        message: `Unable to Update ${emissionSource.name}`,
        description: `Error: ${error}`
      });
    }
  };

  const { execute: handleUpdateConnectEmissionSource } = useAsync({
    asyncFunction: updateConnectEmissionSource,
    immediate: false
  });

  const columns = [
    {
      title: <ColumnTitle>Source Name</ColumnTitle>,
      dataIndex: 'name',
      render: (name, emissionSourceDetails) => (
        <span className="text-bd">
          {getEmissionSourceDetailsName(emissionSourceDetails)}
        </span>
      )
    },
    {
      title: <ColumnTitle>Location</ColumnTitle>,
      dataIndex: 'collections',
      render: (collections) => {
        const locationCollection = getCollectionLocation({ collections });
        return getFormattedAddress({
          location: locationCollection?.location,
          name: locationCollection?.name,
          defaultReturnString: '--'
        });
      }
    },
    {
      title: <ColumnTitle>ID</ColumnTitle>,
      dataIndex: 'id',
      render: (id) => (
        <span className="display-flex align-center">
          {id}
          <CopyToClipboard text={id} />
        </span>
      )
    },
    {
      title: <ColumnTitle>Integration</ColumnTitle>,
      dataIndex: 'id',
      render: (id, emissionSource) => (
        <IntegrationSelect
          disableForm
          name="integration"
          emissionSource={emissionSource}
          options={integrationListOptions}
          handleUpdateConnectEmissionSource={handleUpdateConnectEmissionSource}
        />
      )
    },
    {
      title: <ColumnTitle>Most Recent Data Coverage (mm/dd/yyyy)</ColumnTitle>,
      dataIndex: 'lastDateCovered',
      render: (lastDateCovered) => (
        <span className="display-flex align-center">
          {lastDateCovered
            ? dateFns.format(new Date(lastDateCovered), 'MM/dd/yyyy')
            : 'No Data'}
        </span>
      )
    }
  ];
  return (
    <Row className="chc-admin-source-list" align="middle">
      <Col span={24}>
        <TableTitle
          className="mb-8"
          title="Sources"
          searchProps={{ onSearch: handleSearchList }}
        />
        <Table
          dataSource={filteredProviderList}
          columns={columns}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
export default ChcAdminEmissionSourcesList;
