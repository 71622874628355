import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChartBaseSettings } from '../../../constants';

defaults.font.size = 12;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({
  data = [],
  labels = [],
  yAxisText = 'Tonnes CO2 equivalent',
  bgColorHover,
  bgColors
}) {
  return (
    <div className="bar-chart">
      <Bar
        height={360}
        data={{
          labels,
          datasets: [
            {
              data,
              borderRadius: 2,
              maxBarThickness: 16,
              backgroundColor: bgColors ?? '#5C29C7',
              hoverBackgroundColor: bgColorHover ?? '#ED2861'
            }
          ]
        }}
        options={{
          ...BarChartBaseSettings,
          scales: {
            ...BarChartBaseSettings.scales,
            y: {
              ...BarChartBaseSettings.scales.y,
              title: {
                ...BarChartBaseSettings.scales.y.title,
                text: yAxisText ?? 'Tonnes CO2 equivalent'
              }
            }
          },
          plugins: {
            ...BarChartBaseSettings.plugins,
            legend: { ...BarChartBaseSettings.plugins.legend, display: false }
          }
        }}
      />
    </div>
  );
}

export default BarChart;
