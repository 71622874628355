import { Space } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Card, DropdownField } from '../../../components';
import { useMeContext } from '../../../context';
import { analytics } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { AppRoutes } from '../../../routes';
import { CompanyService } from '../../../services';
import { Button } from '../../../stories/atoms';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';
import './TargetSettingBaselineYear.scss';
import { setBaselineYearValidation } from './TargetSettingBaselineYearValidation';

export function TargetSettingBaselineYear() {
  const { me, updateCompany } = useMeContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [formValues, _setFormValues] = useState({
    baseYear: me?.company?.baseYear
  });
  const footPrintDropdownOptions = me?.company?.footprintRange.map((year) => ({
    label: year,
    value: year
  }));
  const handleSubmitForm = async (values) => {
    await CompanyService.updateCompany(
      {
        baseYear: values?.baseYear,
        slug: me?.company?.slug
      },
      () => {
        analytics.track('Step Completed', me, {
          level1: 'Office-Based Operations',
          level2: 'Set Targets',
          level3: 'Set base year'
        });
        updateCompany({ baseYear: values?.baseYear });
      },
      () => {},
      () => {}
    );
    if (
      location.state?.from?.pathname.startsWith(
        AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
          ':targetDetailsId',
          ''
        )
      ) ||
      !me?.company?.baseYear
    ) {
      navigate(
        AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_STRATEGY.replace(
          ':targetDetailsId',
          'new'
        ),
        {
          state: { from: location }
        }
      );
    } else {
      navigate(AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW, {
        state: { from: location }
      });
    }
  };

  const { execute: handleSubmit, isSubmittingForm } = useAsync({
    asyncFunction: handleSubmitForm,
    immediate: false
  });

  return (
    <div className="target-setting-baseline-year">
      <h3>Set your baseline year</h3>
      <p className="mb-9">
        All of your targets will be measured against this year.
      </p>
      <Formik
        validationSchema={setBaselineYearValidation}
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isValid }) => (
          <Form>
            <Card>
              <h6 className="text-sbd">Baseline year</h6>
              <p className="mb-6">
                Your baseline year must be within the last 3 years, be included
                in your baseline period, and represent a typical year
              </p>
              <DropdownField
                name="baseYear"
                title="Select baseline year"
                options={footPrintDropdownOptions}
                value={values?.baseYear}
                setFieldValue={setFieldValue}
                placeholder="Select"
              />
            </Card>
            <div className="target-setting-baseline-year_actions mt-6 text-right">
              <Space>
                <Link to={AppRoutes.ONBOARDING_ADMIN_TARGET_SETTING_OVERVIEW}>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    loading={isSubmittingForm}
                    disabled={!isValid || isSubmittingForm}
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  small
                  htmlType="submit"
                  primary
                  loading={isSubmittingForm}
                  disabled={!isValid || isSubmittingForm}
                >
                  Next
                </Button>
              </Space>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
