import { Col, Row } from 'antd';
import { VerificationPricing } from '../../../constants';
import { Paragraph, Title } from '../../atoms';
import PricingCard from '../../atoms/PricingCard';
import './VerificationTiers.scss';

function VerificationTiers() {
  return (
    <Row className="verification-tiers" gutter={[0, 16]}>
      <Col span={24}>
        <Title bottomSpacing={56} align="center" size="lg">
          Verification Tiers
        </Title>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]} wrap>
          {VerificationPricing.map((pricingTier) => (
            <Col flex="1 1 400px">
              <PricingCard pricingTier={pricingTier} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24} className="verification-tiers__activities">
        <Title bottomSpacing={16} align="center">
          Verification activities
        </Title>
        <Paragraph bottomSpacing={0} align="center">
          The actual activities included in the scope of your verification will
          be dependent on your inventory. There will be a verification planning
          meeting that you will have to determine what activities make the most
          sense for you. Some of these activities could include things like site
          visits and interviewing your team.
        </Paragraph>
      </Col>
    </Row>
  );
}
export default VerificationTiers;
