import { Col, Modal, Row } from 'antd';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Loader } from '../../components';
import { IntegrationStatus } from '../../constants/status';
import { DisconnectIntegrationStatuses } from '../../constants/status/IntegrationStatus';
import { useMeContext } from '../../context';
import { IntegrationProviderList } from '../../features/Integrations/IntegrationProviderList';
import { analytics } from '../../helpers';
import { useAsync } from '../../hooks';
import { AppRoutes } from '../../routes';
import { IntegrationService } from '../../services';
import {
  Breadcrumb,
  Button,
  Page,
  Paragraph,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import './IntegrationDetail.scss';

export function IntegrationDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { integrationSlug } = params;
  const { me } = useMeContext();

  const loadIntegrationDetail = useCallback(async () => {
    const response = await IntegrationService.getCompanyIntegration({
      companySlug: me.company?.slug,
      integrationSlug
    });
    return response;
  }, [integrationSlug, me.company?.slug]);

  const { value: integrationDetail, isLoading: isLoadingDetail } = useAsync({
    asyncFunction: loadIntegrationDetail,
    defaultValue: {}
  });
  const { displayName, description, logoUrl, status } = integrationDetail;

  const disconnectIntegration = async () => {
    await IntegrationService.updateCompanyIntegration({
      companySlug: me.company?.slug,
      integrationSlug,
      status: IntegrationStatus.PENDING_DISCONNECT
    });
    analytics.track('Delete item clicked', me, {
      level1: 'Carbonhound Connect',
      level2: 'Overview',
      oldIntegrationStatus: integrationDetail.status,
      integration: {
        ...integrationDetail,
        status: IntegrationStatus.PENDING_DISCONNECT
      }
    });
    navigate(AppRoutes.USER_COMPANY, {
      state: { defaultKey: '4' }
    });
  };

  const {
    execute: handleDisconnectIntegration,
    isLoading: isDisablingIntegration
  } = useAsync({
    asyncFunction: disconnectIntegration,
    immediate: false
  });

  const handleInitiateDisconnect = () => {
    Modal.confirm({
      className: 'ch-modal',
      icon: null,
      okText: 'Disconnect',
      title: <Title size="md"> Are you sure you’d like to disconnect?</Title>,
      cancelText: 'Cancel',
      content: (
        <Paragraph bottomSpacing={0}>
          You will no longer be able to utilize this integration.
        </Paragraph>
      ),
      onOk: handleDisconnectIntegration
    });
  };

  const routes = [
    {
      path: AppRoutes.USER_COMPANY,
      breadcrumbName: 'Integrations'
    },
    {
      breadcrumbName: integrationDetail.displayName
    }
  ];
  return (
    <Page className="integration-detail">
      <Breadcrumb params={{ defaultKey: '4' }} routes={routes} />
      <Row gutter={[0, 40]}>
        <Col span={24}>
          <Card>
            <Row
              className="integration-detail__summary"
              gutter={[32, 16]}
              align="middle"
            >
              <Loader isLoading={isLoadingDetail || isDisablingIntegration}>
                <Col flex="0 1 1">
                  <img src={logoUrl} alt={`Logo for ${displayName}`} />
                </Col>
                <Col flex="1 1 auto">
                  <Title bottomSpacing={4} size="lg">
                    {displayName}
                  </Title>
                  <Paragraph bottomSpacing={0}>{description}</Paragraph>
                </Col>
                <Col flex="0 1 auto">
                  {DisconnectIntegrationStatuses.indexOf(status) <= -1 && (
                    <Button
                      onClick={handleInitiateDisconnect}
                      className="text-danger"
                      type={BUTTON_TYPES.TEXT}
                    >
                      Disconnect
                    </Button>
                  )}
                </Col>
              </Loader>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <IntegrationProviderList integrationDetail={integrationDetail} />
        </Col>
      </Row>
    </Page>
  );
}
