import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useMeContext, useMetaContext } from '../../../../context';
import { getAddressValues, handleCalculateDistance } from '../../../../helpers';
import { getDistanceUnit } from '../../../../helpers/generators';
import { LocationService } from '../../../../services';
import GoogleSelect from '../../../atoms/GoogleSelect';

export function ToFromFields({ distanceFieldKey }) {
  const { setFieldValue, values } = useFormikContext();
  const { googleType, travelCategoryName } = values;
  const { me } = useMeContext();
  const { meta, setDistanceUnits } = useMetaContext();
  const { countries, distanceUnits } = meta;

  const distanceUnitOption = distanceUnits.find((unit) => {
    const distanceUnit = getDistanceUnit(
      me.company?.headQuartersCountry?.isoName
    );
    return unit.unit === distanceUnit.toUpperCase();
  });

  const onSelectLocation = async ({ places, fieldKey, locationKey }) => {
    const geometry = {
      lat: places[0].geometry.location.lat(),
      lng: places[0].geometry.location.lng()
    };
    setFieldValue(fieldKey, geometry);
    const placeParams =
      fieldKey === 'departureGeometry'
        ? {
            originStop: {
              ...geometry,
              travelCategoryId: values?.travelCategoryId
            },
            destStop: values?.destinationGeometry
          }
        : {
            originStop: {
              ...values?.departureGeometry,
              travelCategoryId: values?.travelCategoryId
            },
            destStop: geometry
          };

    const address = {
      ...getAddressValues(places[0], countries),
      ...geometry
    };
    const newLocation = await LocationService.createLocation({
      companySlug: me.company?.slug,
      location: address,
      isCollection: false
    });

    setFieldValue(`${locationKey}Id`, newLocation.id);
    if (fieldKey === 'departureGeometry') {
      await handleCalculateDistance({
        place: placeParams,
        setFieldValue,
        googleType,
        travelCategoryName,
        distanceUnitOption,
        distanceFieldKey
      });
    } else {
      await handleCalculateDistance({
        place: placeParams,
        setFieldValue,
        googleType,
        travelCategoryName,
        distanceUnitOption,
        distanceFieldKey
      });
    }
  };
  useEffect(() => {
    setDistanceUnits();
  }, []);
  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <GoogleSelect
          isEdit={false}
          title="Departure Address"
          placeholder="Enter address"
          name="departureLocationId"
          defaultValue={
            values.departureLocation?.formattedAddress ??
            values.departureLocation?.address1
          }
          onSelect={(places) =>
            onSelectLocation({
              places,
              fieldKey: 'departureGeometry',
              locationKey: 'departureLocation'
            })
          }
        />
      </Col>
      <Col span={12}>
        <GoogleSelect
          isEdit={false}
          title="Destination Address"
          placeholder="Enter address"
          name="destinationLocationId"
          defaultValue={
            values.destinationLocation?.formattedAddress ??
            values.destinationLocation?.address1
          }
          onSelect={(places) =>
            onSelectLocation({
              places,
              fieldKey: 'destinationGeometry',
              locationKey: 'destinationLocation'
            })
          }
        />
      </Col>
    </Row>
  );
}
