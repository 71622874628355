import classNames from 'classnames';
import { Link as Lnk } from 'react-router-dom';
import './Link.scss';

const getFontWeightFamily = (weight) => {
  let family;
  switch (weight) {
    case 100:
      family = 'MontserratThin, sans-serif';
      break;
    case 200:
      family = 'MontserratExtraLight, sans-serif';
      break;
    case 300:
      family = 'MontserratLight, sans-serif';
      break;
    case 500:
      family = 'MontserratMedium, sans-serif';
      break;
    case 600:
      family = 'MontserratSemiBold, sans-serif';
      break;
    case 700:
      family = 'MontserratBold, sans-serif';
      break;
    case 800:
      family = 'MontserratExtraBold, sans-serif';
      break;
    case 900:
      family = 'MontserratBlack, sans-serif';
      break;
    case 400:
    default:
      family = 'MontserratRegular, sans-serif';
      break;
  }
  return family;
};

function Link({
  children,
  fontWeight,
  align,
  flex,
  fontSize,
  className,
  ...props
}) {
  const fontFamily = getFontWeightFamily(fontWeight);
  return (
    <Lnk
      className={classNames('ch-nav-link', { flex, [className]: !!className })}
      {...props}
      style={{
        textAlign: align,
        fontSize: `${fontSize}px`,
        fontFamily
      }}
    >
      {children}
    </Lnk>
  );
}

export default Link;
