import { DatePicker as AntDatePicker } from 'antd';
import { ErrorMessage, Field } from 'formik';
import moment from 'moment';
import Error from '../Error';
import './datepicker.scss';

function DatePicker({
  isRange,
  title,
  name,
  fromName,
  toName,
  format,
  picker,
  mode,
  disabledDate,
  setFieldValue,
  defaultValue,
  onChange,
  disabled,
  defaultPickerValue,
  type = 'date'
}) {
  const handleChange = (date) => {
    if (setFieldValue) {
      if (isRange) {
        if (type === 'date') {
          setFieldValue(fromName, date[0].format('YYYY-MM-DD'));
          setFieldValue(toName, date[1].format('YYYY-MM-DD'));
        } else {
          setFieldValue(fromName, date[0].format('YYYY-MM-DD HH:mm:ss'));
          setFieldValue(fromName, date[1].format('YYYY-MM-DD HH:mm:ss'));
        }
      } else {
        type === 'date'
          ? setFieldValue(name, date.format('YYYY-MM-DD'))
          : setFieldValue(name, date.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  };

  return (
    <Field name={name}>
      {({ _field, form: { _touched, _errors }, _meta }) => (
        <div className="ch-datepicker-field">
          {title && <div className="date-picker-field__title">{title}</div>}
          {isRange ? (
            <AntDatePicker.RangePicker
              className={`ch-datepicker date-picker-${name}`}
              disabled={disabled}
              placeholder={['mm/dd/yyyy', 'mm/dd/yyyy']}
              allowClear={false}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate || undefined}
              value={
                defaultValue.length > 0
                  ? [moment.utc(defaultValue[0]), moment.utc(defaultValue[1])]
                  : []
              }
              format={format || (picker === 'year' ? 'YYYY' : 'MM/DD/YYYY')}
              name={name}
            />
          ) : (
            <AntDatePicker
              className={`ch-datepicker date-picker-${name}`}
              disabled={disabled}
              allowClear={false}
              mode={mode}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate || undefined}
              value={defaultValue ? moment.utc(defaultValue) : undefined}
              defaultPickerValue={defaultPickerValue}
              format={format || (picker === 'year' ? 'YYYY' : 'MM/DD/YYYY')}
              name={name}
            />
          )}
          {isRange ? undefined : (
            <ErrorMessage name={name}>
              {(message) => <Error message={message} />}
            </ErrorMessage>
          )}
        </div>
      )}
    </Field>
  );
}

export default DatePicker;
