import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserType } from '../../constants';
import { useMeContext } from '../../context';
import ChcAdmin from '../../pages/ChcAdmin';
import { AppRoutes } from '../../routes';

function RequireService() {
  const { me } = useMeContext();
  return me.userType === UserType.SERVICE ||
    me.userType === UserType.INTERNAL ? (
    <ChcAdmin />
  ) : (
    <Navigate to={AppRoutes.HOME} />
  );
}

export default RequireService;
