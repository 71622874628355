function TrendingDown() {
  return (
    <path
      d="M13 17H21M21 17V9M21 17L13 9L9 13L3 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default TrendingDown;
