import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { ActionsForm, ActionsTable } from '../../features/Actions';
import { ActionsOverview } from '../../features/Actions/ActionsOverview';
import { Page, PageHeader } from '../../stories/atoms';

function Actions() {
  const [isTableOutdated, setIsTableOutdated] = useState(false);
  const [isOverviewOutdated, setIsOverviewOutdated] = useState(false);
  const onCreateActionSuccess = () => {
    setIsTableOutdated(true);
    setIsOverviewOutdated(true);
  };
  return (
    <Page className="actions">
      <PageHeader
        extra={<ActionsForm onSuccess={onCreateActionSuccess} />}
        title="Actions"
      />
      <Row gutter={[0, 44]}>
        <Col span={24}>
          <ActionsOverview
            isOverviewOutdated={isOverviewOutdated}
            setIsOverviewOutdated={setIsOverviewOutdated}
          />
        </Col>
        <Col span={24}>
          <ActionsTable
            isTableOutdated={isTableOutdated}
            setIsTableOutdated={setIsTableOutdated}
          />
        </Col>
      </Row>
    </Page>
  );
}
export default Actions;
