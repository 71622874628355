import { PopupButton } from '@typeform/embed-react';
import { Col, Row } from 'antd';
import { useCallback } from 'react';
import { Card } from '../../components';
import { GroupContentTypes } from '../../constants';
import { COLOURS_CARBONHOUND } from '../../constants/colours';
import { useMeContext } from '../../context';
import { useAsync } from '../../hooks';
import { AppRoutes } from '../../routes';
import { GroupService } from '../../services';
import { GusLaptop } from '../../stories/assets/svgs';
import {
  Anchor,
  Button,
  Icon,
  Link,
  Logo,
  Page,
  Paragraph,
  Title
} from '../../stories/atoms';
import { BUTTON_TYPES } from '../../stories/atoms/Button/Button.types';
import './Help.scss';

function Help() {
  const { me, isGroupSettingsMember } = useMeContext();
  const groupId = me?.company?.groups?.[0]?.id;

  const contactTeamDefaultContent = {
    content: {
      cta: {
        href: 'https://meetings.hubspot.com/hannah472/carbonhound-contact-consultant-inbound',
        text: 'Contact'
      },
      icon: 'Briefcase',
      title: 'Contact our Climate Team',
      description:
        'If you want strategic advice on your climate strategy, or help with a specific area of measurement we can connect you with the right consultant.'
    }
  };

  // Currently, only the "Contact" row is replaced. This code however can build any number of "Help" cards based on group configuration
  const handleFetchGroupContent = useCallback(async () => {
    if (!groupId) return [contactTeamDefaultContent];
    try {
      const response = await GroupService.listGroupContent({
        groupId,
        companySlug: me.company?.slug,
        type: GroupContentTypes.HELP
      });
      return !!response?.content.length
        ? response.content
        : [contactTeamDefaultContent];
    } catch (e) {
      console.error(e);
    }
  }, [groupId]);

  const { Loader: GroupContentLoader, value: groupContent } = useAsync({
    asyncFunction: handleFetchGroupContent,
    defaultValue: [contactTeamDefaultContent]
  });

  return (
    <Page className="ch-help">
      <Row gutter={[0, 56]} justify="center">
        <Col flex="156px">
          {isGroupSettingsMember ? <Logo width={300} /> : <GusLaptop />}
        </Col>
        <Col span={24}>
          <Title align="center" size="xl">
            How can we help you?
          </Title>
        </Col>
        <Col span={24}>
          <GroupContentLoader>
            <Row gutter={[24, 24]}>
              <Col xs={24} xl={8} md={12} lg={8} className="display-flex">
                {groupContent.map(({ content }) => (
                  <Card>
                    <Row>
                      <Col flex="40px" className="ch-help__icon-wrapper">
                        <Icon
                          size={24}
                          name={content.icon}
                          color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                        />
                      </Col>
                      <Col span={24}>
                        <Title includeSpacing={false} size="md">
                          {content.title}
                        </Title>
                      </Col>
                      <Col span={24}>
                        <Paragraph
                          bottomSpacing={24}
                          className="ch-help__paragraph"
                        >
                          {content.description}
                        </Paragraph>
                      </Col>
                      {content.cta && (
                        <Col span={24} className="display-flex align-end">
                          <Button type={BUTTON_TYPES.SECONDARY}>
                            <Anchor
                              href={content.cta.href}
                              fontWeight={600}
                              flex
                            >
                              {content.cta.text}
                            </Anchor>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card>
                ))}
              </Col>
              <Col xs={24} xl={8} md={12} lg={8} className="display-flex">
                <Card>
                  <Row>
                    <Col flex="40px" className="ch-help__icon-wrapper">
                      <Icon
                        size={24}
                        name="Collection"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                    <Col span={24}>
                      <Title includeSpacing={false} size="md">
                        Consultant network
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        bottomSpacing={24}
                        className="ch-help__paragraph"
                      >
                        Need help with something specific? Tap into our
                        consultant network for support.
                      </Paragraph>
                    </Col>
                    <Col span={24} className="display-flex align-end">
                      <Button type={BUTTON_TYPES.SECONDARY}>
                        <Link to={AppRoutes.CONSULTANTS}>View Consultants</Link>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} xl={8} md={12} lg={8} className="display-flex">
                <Card>
                  <Row>
                    <Col flex="40px" className="ch-help__icon-wrapper">
                      <Icon
                        size={24}
                        name="ChartSquareBar"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                    <Col span={24}>
                      <Title includeSpacing={false} size="md">
                        Request a report
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        bottomSpacing={24}
                        className="ch-help__paragraph"
                      >
                        If you would like a full CSV of your data exported
                        please send us a request and we’ll send it shortly.
                      </Paragraph>
                    </Col>
                    <Col span={24} className="display-flex align-end">
                      <Button type={BUTTON_TYPES.SECONDARY}>
                        <PopupButton
                          className="ch-typeform-button--link"
                          id="bcYm9DMo"
                        >
                          Request
                        </PopupButton>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={12} lg={12} xl={12} className="display-flex">
                <Card>
                  <Row>
                    <Col flex="40px" className="ch-help__icon-wrapper">
                      <Icon
                        size={24}
                        name="Support"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                    <Col span={24}>
                      <Title includeSpacing={false} size="md">
                        Get help with Carbonhound
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        bottomSpacing={24}
                        className="ch-help__paragraph"
                      >
                        To notify us of a bug, access our FAQ and knowledge
                        base, or get help with general inquiries please visit to
                        our Help Centre
                      </Paragraph>
                    </Col>
                    <Col span={24} className="display-flex align-end">
                      <Button type={BUTTON_TYPES.SECONDARY}>
                        <Anchor
                          href="https://carbonhound.notion.site/Knowledge-Hub-321f4a815c964d119e30af6b3de060f4"
                          flex
                        >
                          Help Centre
                        </Anchor>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={12} lg={12} xl={12} className="display-flex">
                <Card>
                  <Row>
                    <Col flex="40px" className="ch-help__icon-wrapper">
                      <Icon
                        size={24}
                        name="Calculator"
                        color={COLOURS_CARBONHOUND.PRIMARY_PURPLE}
                      />
                    </Col>
                    <Col span={24}>
                      <Title includeSpacing={false} size="md">
                        Request an emission factor
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph
                        bottomSpacing={24}
                        className="ch-help__paragraph"
                      >
                        If you need a new factor in order to accurately map your
                        carbon footprint submit a request and we’ll add it for
                        you.
                      </Paragraph>
                    </Col>
                    <Col span={24} className="display-flex align-end">
                      <Button type={BUTTON_TYPES.SECONDARY}>
                        <PopupButton
                          className="ch-typeform-button--link"
                          id="ebbuWkTC"
                        >
                          Request
                        </PopupButton>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </GroupContentLoader>
        </Col>
      </Row>
    </Page>
  );
}

export default Help;
