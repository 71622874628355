import classNames from 'classnames';
import { COLOURS_GRAY } from '../../../constants/colours';
import './Paragraph.scss';

const getParagraphStyles = (size) => {
  let fontSize;
  switch (size) {
    case 'lg':
      fontSize = 18;
      break;
    case 'rg':
      fontSize = 16;
      break;
    case 'sm':
      fontSize = 14;
      break;
    case 'xs':
      fontSize = 12;
      break;
    default:
      fontSize = 16;
      break;
  }
  return fontSize;
};
const getFontWeightFamily = (weight) => {
  let family;
  switch (weight) {
    case 100:
      family = 'MontserratThin, sans-serif';
      break;
    case 200:
      family = 'MontserratExtraLight, sans-serif';
      break;
    case 300:
      family = 'MontserratLight, sans-serif';
      break;
    case 500:
      family = 'MontserratMedium, sans-serif';
      break;
    case 600:
      family = 'MontserratSemiBold, sans-serif';
      break;
    case 700:
      family = 'MontserratBold, sans-serif';
      break;
    case 800:
      family = 'MontserratExtraBold, sans-serif';
      break;
    case 900:
      family = 'MontserratBlack, sans-serif';
      break;
    case 400:
    default:
      family = 'MontserratRegular, sans-serif';
      break;
  }
  return family;
};
function Paragraph({
  weight,
  children,
  className,
  align,
  includeSpacing = true,
  bottomSpacing = 16,
  size = 'rg',
  color = COLOURS_GRAY.GRAY_700,
  ...props
}) {
  const fontSize = typeof size === 'number' ? size : getParagraphStyles(size);
  const fontFamily = getFontWeightFamily(weight);
  return (
    <p
      className={classNames('ch-paragraph', {
        [className]: !!className,
        'no-space': !includeSpacing
      })}
      style={{
        fontSize: `${fontSize}px`,
        fontWeight: weight,
        color,
        textAlign: align,
        fontFamily,
        marginBottom: includeSpacing ? `${bottomSpacing}px` : undefined
      }}
      {...props}
    >
      {children}
    </p>
  );
}

export default Paragraph;
