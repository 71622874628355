/** MeContext is responsible for storing the Global State
 * for the state of bulk uploads either currently being created,
 * or in the process of uploading
 * */

import produce from 'immer';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  BulkUploadType,
  EMISSION_SOURCE_INTERNAL_SPECS,
  NotificationCategory
} from '../constants';
import { Notification } from '../models';
import { useMeContext } from './MeContext';

// Create the context
const BulkUploadContext = createContext({});

// define the default context state
const initialBulkUploadState = {
  activeStep: undefined,
  missingCount: undefined
};

const useBulkUploadContext = () => {
  const context = useContext(BulkUploadContext);

  if (!context) {
    // TODO: replace with proper error handling
    throw new Error(
      'useBulkUploadContext must be used within a BulkUploadContextProvider'
    );
  }
  const [bulkUploadState, setBulkUploads] = context;

  // Context Methods //
  const setStep = ({ activeStep }) => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.activeStep = activeStep;
      })
    );
  };

  const nextStep = () => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.activeStep += 1;
      })
    );
  };

  const prevStep = () => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.activeStep -= 1;
      })
    );
  };

  const setStatus = ({ status }) => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.notification.status = status;
      })
    );
  };

  const updateNotification = ({ notification }) => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.notification = { ...draft.notification, ...notification };
      })
    );
  };

  const updateNotificationReqParams = ({ notificationReqParams }) => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.notificationReqParams = {
          ...draft.notificationReqParams,
          ...notificationReqParams
        };
      })
    );
  };

  useEffect(() => {
    setBulkUploads((prevState) =>
      produce(prevState, (draft) => {
        draft.missingCount =
          bulkUploadState.notification?.details?.successRowCount -
            bulkUploadState.notification?.details?.upsertSuccessRowCount ??
          undefined;
      })
    );
  }, [
    bulkUploadState.notification?.details?.successRowCount,
    bulkUploadState.notification?.details?.upsertSuccessRowCount
  ]);

  return {
    bulkUploadState,
    setStep,
    nextStep,
    prevStep,
    setStatus,
    updateNotification,
    updateNotificationReqParams
  };
};

// Create the context provider
function BulkUploadContextProvider({
  handleCloseBulkUploadsModal,
  notification = new Notification(),
  internalSpec,
  notificationReqParams = {},
  companySlug,
  children,
  onSuccess
}) {
  const { me } = useMeContext();
  let type;
  if (notificationReqParams.type) {
    ({ type } = notificationReqParams);
  } else if (notificationReqParams.emissionSourceDetailsId && !internalSpec) {
    type = BulkUploadType.CUSTOM;
  } else if (internalSpec) {
    if (internalSpec === EMISSION_SOURCE_INTERNAL_SPECS.TRAVEL) {
      type = BulkUploadType.TRAVEL;
    } else if (internalSpec === EMISSION_SOURCE_INTERNAL_SPECS.ENERGY) {
      type = BulkUploadType.ENERGY;
    }
  }

  const [bulkUpload, setBulkUpload] = useState({
    ...initialBulkUploadState,
    notificationReqParams,
    internalSpec,
    type,
    notification: {
      ...notification,
      category: notification.category || NotificationCategory.DATA_IMPORT,
      details: {
        collectionId: notificationReqParams?.collectionId,
        origin: notificationReqParams?.origin,
        ...notification.details
      }
    },
    companySlug: companySlug || me?.company?.slug,
    handleCloseBulkUploadsModal,
    onSuccess: onSuccess || handleCloseBulkUploadsModal
  });

  const value = useMemo(() => [bulkUpload, setBulkUpload], [bulkUpload]);
  return (
    <BulkUploadContext.Provider value={value}>
      {children}
    </BulkUploadContext.Provider>
  );
}

export { BulkUploadContextProvider, useBulkUploadContext };
