import AxiosInstance from '../interceptors/axiosInstance';
import { ApiRoutes } from '../routes';

class SubscriptionService {
  static async listStripePrices(
    { companySlug, _pagination, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.PAYMENTS_STRIPE_PRICES.replace(
        ':companySlug',
        companySlug
      )}/list`;
      const response = await AxiosInstance.get(API_URL, { params });
      onSuccess?.({
        list: response.data?.prices
      });
      return {
        list: response.data?.prices
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }

  static async getStripeCustomerPortal(
    { companySlug, ...params },
    onSuccess,
    onError,
    onFinal
  ) {
    try {
      const API_URL = `${ApiRoutes.PAYMENTS_STRIPE_CUSTOMER_PORTAL.replace(
        ':companySlug',
        companySlug
      )}`;
      const response = await AxiosInstance.get(API_URL, { params });
      onSuccess?.({
        customerPortalUrl: response.data?.customerPortalUrl
      });
      return {
        customerPortalUrl: response.data?.customerPortalUrl
      };
    } catch (error) {
      onError?.(error);
      return error;
    } finally {
      onFinal?.();
    }
  }
}

export default SubscriptionService;
