import { formatInTimeZone } from 'date-fns-tz';
import { isNaN } from 'formik';
import { TIMEFRAME_VALUES } from '../constants';
import { chartColours } from '../constants/chartColours';
import { capitalizeText, getQuarterName } from './generators';

export function formatDecimal(value, decimalCount = 2) {
  if (isNaN(value)) {
    return 0;
  }
  if (decimalCount === 0) {
    return parseInt(Math.round(value));
  }

  const decimalScaleFactor = 10 ** decimalCount;
  return (
    Math.round((parseFloat(value) + Number.EPSILON) * decimalScaleFactor) /
    decimalScaleFactor
  );
}
export const formatEmissions = (array = [], emissionsKey = '') =>
  array.map((e) => {
    const emissionConvertedValue = Number(e[emissionsKey]) / 1000;
    return {
      ...e,
      emissions: formatDecimal(emissionConvertedValue, 3)
    };
  });

export function formatCurrency(
  value,
  isoCurrency,
  { includeCurrencySymbol = false }
) {
  switch (isoCurrency.toUpperCase()) {
    case 'USD':
    case 'CAD':
    default: {
      const formattedCurrency = formatDecimal(parseFloat(value) / 100, 2);
      if (includeCurrencySymbol) return `$${formattedCurrency}`;
      return formattedCurrency;
    }
  }
}

export function formatOwnershipString(str) {
  return `${str}'${
    str.substr(str.length - 1).toLowerCase() === 's' ? '' : 's'
  }`;
}

export const FormatEmissionsForChart = ({
  emissionsArray,
  params = {},
  startingMonth = 0
}) => {
  const { timeUnit, targetDetailsId, showMonthsWithoutData } = params;
  const scaleEmissions = targetDetailsId ? 1 : 1000;
  const isFilterByQuarter = timeUnit === TIMEFRAME_VALUES.QUARTER;
  const isFilterByYear = timeUnit === TIMEFRAME_VALUES.YEAR;
  const isFilterByAllTime = emissionsArray.length > 12;
  let dateFormat;
  if (isFilterByYear) {
    dateFormat = 'yyyy';
  } else if (isFilterByQuarter) {
    dateFormat = 'yy';
  } else if (isFilterByAllTime) {
    dateFormat = 'MMM yy';
  } else {
    dateFormat = 'MMM';
  }

  const respArray = emissionsArray.map((summary) => {
    const formattedSummary = summary;
    const summaryDate = formatInTimeZone(
      new Date(summary.date),
      'UTC',
      dateFormat
    );
    if (isFilterByQuarter) {
      const quarter = getQuarterName(startingMonth, formattedSummary.date);
      formattedSummary.date = `Q${quarter}${
        isFilterByAllTime ? ` ${summaryDate}` : ''
      }`;
    } else if (formattedSummary.date) {
      formattedSummary.date = summaryDate;
    }
    formattedSummary.scaleEmissions = scaleEmissions;
    return formattedSummary;
  });

  const labelsMap = {};
  const datasetMap = {};

  respArray.forEach((entry) => {
    const { scaleEmissions: entryScaleEmissions, date, ...source } = entry;
    if (!labelsMap[date]) labelsMap[date] = { hasData: false, date };
    Object.entries(source).forEach(([key, value], counter) => {
      if (!datasetMap[key]) {
        datasetMap[key] = {
          label: key,
          barPercentage: 0.37,
          data: [],
          backgroundColor: chartColours[counter],
          borderRadius: 50,
          order: 2
        };
      }
      if (!value || value === 0) {
        datasetMap[key].data.push(
          showMonthsWithoutData ? 0 : { date, value: 0 }
        );
      } else {
        const formattedValue = formatDecimal(
          Number(value) / entryScaleEmissions,
          3
        );
        datasetMap[key].data.push(
          showMonthsWithoutData
            ? formattedValue
            : { date, value: formattedValue }
        );
        labelsMap[date].hasData = true;
      }
    });
  });
  const datasets = Object.values(datasetMap).filter((dataset) => {
    if (showMonthsWithoutData) return dataset;
    dataset.data = dataset.data.reduce((acc, { date, value }) => {
      if (labelsMap[date].hasData) {
        acc.push(value);
      }
      return acc;
    }, []);
    return dataset;
  });

  const labels = showMonthsWithoutData
    ? Object.keys(labelsMap)
    : Object.values(labelsMap).reduce((acc, label) => {
        if (label.hasData) acc.push(label.date);
        return acc;
      }, []);

  return {
    datasets,
    labels
  };
};

export const convertArrayToObject = (array = [], key = '') =>
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});

export const convertConstantToString = (constant) => {
  const text = capitalizeText(constant.toLowerCase().replaceAll('_', ' '));
  return {
    singular: text,
    plural: `${text}s`
  };
};
