import { InputNumber } from 'antd';
import classNames from 'classnames';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import Error from '../Error';
import './inputNumberField.scss';

function InputNumberField(props) {
  const { name, title, className, onChange } = props;
  const { setFieldValue } = useFormikContext();
  const handleChange = (value) => {
    setFieldValue(name, value);
  };
  return (
    <div
      className={classNames('ch-input-number-field', {
        [className]: !!className
      })}
    >
      <div style={{ position: 'relative' }}>
        {title && <p className="input-number-field__title">{title}</p>}
        <Field
          className="input-number-field"
          as={InputNumber}
          {...props}
          onChange={onChange || handleChange}
        />
        <div className="input-number-field__gradient-bg" />
      </div>
      <ErrorMessage name={name}>
        {(message) => <Error className={`${name}__error`} message={message} />}
      </ErrorMessage>
    </div>
  );
}

export default InputNumberField;
