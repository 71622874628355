import { Space } from 'antd';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { Loader, Popover, Tooltip } from '../../../components';
import { COLOURS_BW } from '../../../constants/colours';
import { Icon, Paragraph, Title } from '../../atoms';
import LegendIndicator from '../../atoms/LegendIndicator';
import { LEGEND_INDICATOR_TYPES } from '../../atoms/LegendIndicator/LegendIndicator.types';
import './CompletenessTimeline.scss';

function CompletionTimeline({ timeline = [], isLoading = false, mode }) {
  // Handle completeness right away, skips some processing and protects an empty timeline case
  const isComplete = !timeline?.find((t) => !t.isComplete);

  const timeLineStartDate =
    !!timeline[0]?.date &&
    formatInTimeZone(new Date(timeline[0]?.date), 'UTC', 'MMM yyyy');
  const timeLineEndDate =
    !!timeline[timeline?.length - 1]?.date &&
    formatInTimeZone(
      new Date(timeline[timeline?.length - 1]?.date || ''),
      'UTC',
      'MMM yyyy'
    );

  return (
    <div
      className={classNames('ch-completion-timeline', {
        [mode]: !!mode
      })}
    >
      <Title size="rg">
        Data coverage
        <Popover content="The data coverage timeline shows what data is needed to complete your carbon footprint, change your baseline period to reduce the required inputs. Hover over the timeline to see the exact dates for the missing information" />
      </Title>
      <Loader isLoading={isLoading}>
        {isComplete ? (
          <div className="ch-completion-timeline__completed">
            <div className="ch-completion-timeline__completed__icon-wrapper">
              <Icon color={COLOURS_BW.WHITE} size={23} name="Check" />
            </div>
            <Paragraph bottomSpacing={0} weight="600">
              Click next to proceed
            </Paragraph>
          </div>
        ) : (
          <>
            <Space className="ch-completion-timeline__total-timeline">
              <Title size="xs">{timeLineStartDate && timeLineStartDate}</Title>
              <Title size="xs">{timeLineEndDate && timeLineEndDate}</Title>
            </Space>
            <Space align="center" className="ch-completion-timeline__chart">
              {timeline?.map((timelineMonth, ti) => {
                const isMonthComplete = !!timeline[ti].isComplete;

                return (
                  <Tooltip
                    color="white"
                    key={ti}
                    title={
                      <div>
                        <p>
                          {formatInTimeZone(
                            timelineMonth.date,
                            'UTC',
                            'MMM yyyy'
                          )}
                        </p>
                        {!isMonthComplete &&
                          timelineMonth.missingDates?.length && (
                            <p>
                              Missing Dates:{' '}
                              {timelineMonth.missingDates?.length &&
                                timelineMonth.missingDates.map((m, mi) => (
                                  <div>
                                    {`${formatInTimeZone(
                                      new Date(m.startDate),
                                      'UTC',
                                      'dd MMM'
                                    )} - 
							${formatInTimeZone(new Date(m.endDate), 'UTC', 'dd MMM')}${
                                      mi ===
                                      timelineMonth.missingDates.length - 1
                                        ? ''
                                        : ','
                                    }`}
                                  </div>
                                ))}
                            </p>
                          )}
                      </div>
                    }
                  >
                    <span>
                      <LegendIndicator
                        type={
                          isMonthComplete
                            ? LEGEND_INDICATOR_TYPES.COMPLETE
                            : LEGEND_INDICATOR_TYPES.INCOMPLETE
                        }
                      />
                    </span>
                  </Tooltip>
                );
              })}
            </Space>
            <Space
              size={16}
              align="center"
              className="ch-completion-timeline__legend"
            >
              <p className="m-0">
                Complete
                <LegendIndicator type={LEGEND_INDICATOR_TYPES.COMPLETE} />
              </p>
              <p className="m-0">
                Incomplete
                <LegendIndicator type={LEGEND_INDICATOR_TYPES.INCOMPLETE} />
              </p>
            </Space>
          </>
        )}
      </Loader>
    </div>
  );
}
export default CompletionTimeline;
