import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import { useInitiativeActionContext, useMeContext } from '../../../../context';
import { analytics } from '../../../../helpers';
import { useAsync } from '../../../../hooks';
import { Button, Input, Paragraph, Title } from '../../../../stories/atoms';
import { BUTTON_TYPES } from '../../../../stories/atoms/Button/Button.types';
import { INPUT_TYPES } from '../../../../stories/atoms/Input/Input.types';
import { InitiativeResource } from '../../InitiativeResource';
import './InitiativeActionContent.scss';

export function InitiativeActionContent({ onSuccess, analyticsContext }) {
  const {
    initiativeActionState,
    handleEditNotification,
    isDisabledOrComplete
  } = useInitiativeActionContext();
  const { me } = useMeContext();
  const { initiativeAction, initiativeTemplate, companyInitiative } =
    initiativeActionState;
  const { details, notes } =
    Object.keys(initiativeTemplate || {}).length > 0
      ? initiativeTemplate
      : initiativeAction ?? {};
  const { resourceUrls } = details ?? {};
  const handleSubmitNotification = async (values) => {
    analytics.track('Edit Item Clicked', me, {
      ...analyticsContext,
      initiativeTemplateId: initiativeTemplate?.id,
      companyInitiativeId: companyInitiative.id,
      notificationId: initiativeAction.id,
      editField: 'notes'
    });
    await handleEditNotification({
      id: initiativeAction.id,
      ...values
    });
    onSuccess?.();
  };

  const message = initiativeTemplate.message || initiativeAction.message;
  const { execute: editNotification, isLoading } = useAsync({
    asyncFunction: handleSubmitNotification,
    immediate: false
  });
  return (
    <Row
      initiativeAction={initiativeAction}
      className="initiative-action-content"
    >
      <Col span={24} className="initiative-action-content__section">
        <Title>Action Description</Title>
        <Paragraph size="sm" className="white-space-pre">
          {message}
        </Paragraph>
      </Col>
      {resourceUrls?.length && (
        <Col span={24} className="initiative-action-content__section">
          <Title>Resources</Title>
          {resourceUrls?.map((resource, i) => (
            <div className="mb-4">
              <InitiativeResource
                key={i}
                resource={resource}
                companyInitiative={companyInitiative}
              />
            </div>
          ))}
        </Col>
      )}
      <Col span={24} className="initiative-action-content__section">
        <Formik
          onSubmit={editNotification}
          initialValues={{
            notes
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Input
                    disabled={isDisabledOrComplete}
                    type={INPUT_TYPES.TEXT_AREA}
                    title="Note"
                    value={values.notes}
                    name="notes"
                    placeholder="A note here for future edits"
                    disableForm
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={24} className="display-flex justify-end">
                  <Button
                    disabled={isDisabledOrComplete}
                    type={BUTTON_TYPES.SECONDARY}
                    isLoading={isLoading}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
