function Map() {
  return (
    <path
      d="M9 20L3.553 17.276C3.38692 17.193 3.24722 17.0654 3.14955 16.9075C3.05188 16.7496 3.0001 16.5677 3 16.382V5.618C3.00009 5.44761 3.04372 5.28007 3.12674 5.13127C3.20977 4.98248 3.32944 4.85736 3.47439 4.76781C3.61935 4.67825 3.78479 4.62721 3.95501 4.61955C4.12522 4.61188 4.29458 4.64784 4.447 4.724L9 7M9 20V7M9 20L15 17M9 7L15 4M15 17L19.553 19.276C19.7054 19.3522 19.8748 19.3881 20.045 19.3805C20.2152 19.3728 20.3806 19.3218 20.5256 19.2322C20.6706 19.1426 20.7902 19.0175 20.8733 18.8687C20.9563 18.7199 20.9999 18.5524 21 18.382V7.618C20.9999 7.43234 20.9481 7.25037 20.8504 7.09247C20.7528 6.93458 20.6131 6.80699 20.447 6.724L15 4M15 17V4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default Map;
