import emojiHappy from '../../../assets/images/emoji-happy.svg';
import { COLOURS_CARBONHOUND } from '../../../constants/colours';
import { Icon } from '../index';

function StepsDot({ _iconDot, status, index }) {
  if (status === 'wait' || !status) return null;
  if (status === 'finish') {
    return index < 4 ? (
      <Icon size={16} color={COLOURS_CARBONHOUND.PRIMARY_PURPLE} name="Check" />
    ) : (
      <img src={emojiHappy} alt="Happy Face Emoji" />
    );
  }
  // Adding one because arrays start at 0, but human numbers 1
  if (status === 'process') return index + 1;
  if (status === 'error') return <Icon name="XCircle" />;
}

export default StepsDot;
