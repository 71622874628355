function ChevronDown() {
  return (
    <path
      d="M19 9L12 16L5 9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

export default ChevronDown;
