import { Col, DatePicker, Row, Space } from 'antd';
import classNames from 'classnames';
import { Button, Select, Switcher, Title } from '../../atoms';
import Input from '../../atoms/Input';
import { INPUT_TYPES } from '../../atoms/Input/Input.types';
import './TableTitle.scss';

function TableTitle({
  title,
  searchProps,
  filterProps,
  dateFilterProps,
  className,
  buttons = [],
  buttonComponents = [],
  switcherProps,
  widget,
  isHeaderBorder
}) {
  const Widget = widget;
  return (
    <Row
      className={classNames('ch-table-title', {
        [className]: !!className,
        isHeaderBorder: !!isHeaderBorder
      })}
    >
      <Col span={12} className="ch-table-title__left ">
        {!!title ? (
          <Title bottomSpacing={0} size="lg">
            {title}
          </Title>
        ) : (
          <>
            {!!searchProps && (
              <Input type={INPUT_TYPES.SEARCH} {...searchProps} />
            )}
            {!!filterProps && (
              <Select className="ch-table-title__filter" {...filterProps} />
            )}
            {!!dateFilterProps && (
              <DatePicker.RangePicker
                placeholder={['mm/dd/yyyy', 'mm/dd/yyyy']}
                format="MM/DD/YYYY"
                {...dateFilterProps}
              />
            )}
          </>
        )}
      </Col>
      <Col span={12} className="ch-table-title__right">
        <Space wrap size={24} align="center">
          {!!searchProps && !!title && (
            <Input
              type={INPUT_TYPES.SEARCH}
              isFormikField={false}
              {...searchProps}
            />
          )}
          {switcherProps && <Switcher {...switcherProps} />}
          {buttonComponents.map(({ Component, props }, i) => (
            <Component key={`${i} - button component`} {...props} />
          ))}
          {buttons.map((button, i) => (
            <Button key={i} {...button}>
              {button.children}
            </Button>
          ))}
        </Space>
      </Col>
      {!!Widget && (
        <Col span={24} className="mt-6">
          <Widget />
        </Col>
      )}
    </Row>
  );
}

export default TableTitle;
