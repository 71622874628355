const BarChartBaseSettings = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        padding: 24,
        font: {
          family: 'MontserratSemiBold',
          size: 12
        }
      },
      grid: {
        display: false
      }
    },
    y: {
      grid: { drawBorder: false },
      stacked: true,
      ticks: {
        padding: 24,
        font: {
          family: 'MontserratMedium',
          size: 12
        }
      },
      grace: '5%',
      title: {
        display: true,
        text: 'Tonnes CO2 equivalent'
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',

      labels: {
        font: {
          family: 'MontserratMedium',
          size: 12
        },
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true
      }
    }
  }
};
export default BarChartBaseSettings;
