import React, { useMemo, useState } from 'react';
import { Drawer as DrawerComponent } from '../stories/atoms/Drawer';

export const useDrawer = ({ onShowDrawer, onCloseDrawer, width = '30%' }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowDrawer = () => {
    onShowDrawer?.();
    setShowDrawer(true);
  };
  const handleCloseDrawer = () => {
    onCloseDrawer?.();
    setShowDrawer(false);
  };

  const Drawer = useMemo(
    () =>
      function ({ children, ...props }) {
        return showDrawer ? (
          <DrawerComponent
            width={width}
            open={showDrawer}
            onCancel={handleCloseDrawer}
            closable={false}
            {...props}
          >
            {children}
          </DrawerComponent>
        ) : null;
      },
    [showDrawer]
  );

  return { Drawer, handleShowDrawer, handleCloseDrawer };
};
