import { Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import trailsOverviewBg from '../../assets/images/trailsOverviewBg.png';
import { useMeContext } from '../../context';
import { analytics } from '../../helpers';
import { Button } from '../../stories/atoms';
import Progress from '../Progress';
import './trialOverview.scss';

function TrialOverview({
  title = 'Onboarding',
  description = 'Lorem ipsum',
  completedSegments = 20,
  imgSrc = trailsOverviewBg,
  totalSegments = 5,
  link
}) {
  const navigate = useNavigate();

  const { me } = useMeContext();
  const progressPercentage = (completedSegments / totalSegments) * 100;

  return (
    <div className="trial-overview trail-overview-bg-2">
      <Row align="middle">
        <Col span={15} className="trial-overview__content-wrapper">
          <Row align="middle">
            <Col span={16}>
              <div>Trail</div>
              <h1>{title}</h1>
            </Col>
            <Col span={8}>
              <Progress
                title={`Progress: ${completedSegments}/${totalSegments}`}
                percent={progressPercentage}
              />
            </Col>
            <Col span={24} className="trial-overview__description">
              {description}
            </Col>
            <Col span={24}>
              <Row className="trial-overview__achievements">
                <Col span={14}>
                  <div className="trial-overview__icon">
                    <i className="icon-achievements" />
                  </div>
                  <div className="trial-overview__content">
                    <div className="trial-overview__label">
                      Achievement Earned
                    </div>
                    <div className="trial-overview__value">
                      {/* <img src={logoWhite} alt=''/> */} WRI Office-Based
                      organization standard
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="trial-overview__content">
                    <div className="trial-overview__label">
                      Engagement points
                    </div>
                    <div className="trial-overview__value"> +50 </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: '40px' }}>
              <Button
                onClick={() => {
                  analytics.track('Trail Started', me, {
                    level1: 'Trail Overview',
                    level2: 'Office Based Operations'
                  });
                  navigate(link);
                }}
              >
                {completedSegments > 0 ? 'Continue' : 'Get Started'}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={9} style={{ position: 'relative' }}>
          <div className="trial-overview__bg-overlay-2" />
          <img
            src={imgSrc}
            alt="Trail Overview"
            className="trail-overview__bg-img"
          />
        </Col>
      </Row>
    </div>
  );
}

export default TrialOverview;
