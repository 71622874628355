export const chartColours = [
  '#E30000',
  '#A40062',
  '#1D4ED8',
  '#74A57F',
  '#448AFF',
  '#EA80FC',
  '#388E3C',
  '#29B6F6',
  '#2196F3',
  '#1565C0',
  '#E1BEE7',
  '#004D40',
  '#FB8C00',
  '#311B92',
  '#FF6D00',
  '#B9F6CA',
  '#AA00FF',
  '#82B1FF',
  '#536DFE',
  '#004D40',
  '#00897B',
  '#304FFE',
  '#3949AB',
  '#9CCC65',
  '#E040FB',
  '#0277BD',
  '#D81B60',
  '#FF8A80',
  '#E91E63',
  '#FFC107',
  '#00C853',
  '#C0CA33',
  '#8BC34A',
  '#ED2861',
  '#FF6E40',
  '#EC407A',
  '#B39DDB',
  '#00BCD4',
  '#2E7D32',
  '#ED2861',
  '#F06292',
  '#E57373',
  '#76FF03',
  '#0097A7',
  '#74A57F',
  '#1976D2',
  '#00796B',
  '#FF6F00',
  '#F48FB1',
  '#B2FF59',
  '#EF9A9A',
  '#00838F',
  '#4A148C',
  '#BA68C8',
  '#FF8A65',
  '#0288D1',
  '#B71C1C',
  '#2962FF',
  '#2979FF',
  '#E65100',
  '#6064',
  '#FF80AB',
  '#03A9F4',
  '#F4FF81',
  '#18FFFF',
  '#3D5AFE',
  '#FFEE58',
  '#7E57C2',
  '#DCE775',
  '#AED581',
  '#FF9E80',
  '#AB47BC',
  '#FF7043',
  '#FFFF8D',
  '#26C6DA',
  '#C6FF00',
  '#1DE9B6',
  '#FF8F00',
  '#01579B',
  '#D84315',
  '#C51162',
  '#D1C4E9',
  '#A7FFEB',
  '#00695C',
  '#311B92',
  '#17D7DA',
  '#42A5F5',
  '#303F9F',
  '#FFAB40',
  '#7CB342',
  '#651FFF',
  '#F3E5F5',
  '#5E35B1',
  '#689F38',
  '#F50057',
  '#558B2F',
  '#D50000',
  '#B388FF',
  '#5C6BC0',
  '#FFFF00',
  '#FFD600',
  '#FFCA28',
  '#CE93D8',
  '#00B0FF',
  '#64B5F6',
  '#FF3D00',
  '#80D8FF',
  '#00E5FF',
  '#7B1FA2',
  '#9C27B0',
  '#039BE5',
  '#84FFFF',
  '#4FC3F7',
  '#FFAB00',
  '#FFCDD2',
  '#6A1B9A',
  '#D32F2F',
  '#33691E',
  '#1A237E',
  '#FFB300',
  '#EEFF41',
  '#CDDC39',
  '#827717',
  '#69F0AE',
  '#EF5350',
  '#BF360C',
  '#FF9800',
  '#00E676',
  '#D4E157',
  '#43A047',
  '#9575CD',
  '#1B5E20',
  '#4CAF50',
  '#81C784',
  '#FFA000',
  '#F44336',
  '#4DD0E1',
  '#FFB74D',
  '#E64A19',
  '#283593',
  '#66BB6A',
  '#64DD17',
  '#3F51B5',
  '#0D47A1',
  '#FF9100',
  '#40C4FF',
  '#F57F17',
  '#D500F9',
  '#FF5252',
  '#0097A7',
  '#F8BBD0',
  '#EDE7F6',
  '#64FFDA',
  '#5C29C7',
  '#FBC02D',
  '#880E4F',
  '#00BFA5',
  '#9688',
  '#7986CB',
  '#0091EA',
  '#AD1457',
  '#F57C00',
  '#FF5722',
  '#00ACC1',
  '#DD2C00',
  '#673AB7',
  '#E53935',
  '#FFD740',
  '#F9A825',
  '#7C4DFF',
  '#FF4081',
  '#FFA726',
  '#CCFF90',
  '#C62828',
  '#8C9EFF',
  '#C2185B',
  '#00B8D4',
  '#512DA8',
  '#FFD180',
  '#FFEA00',
  '#6200EA',
  '#4527A0',
  '#1E88E5',
  '#AFB42B',
  '#FFEBEE',
  '#EF6C00',
  '#FFC400',
  '#FF6F00',
  '#FDD835',
  '#FFEB3B',
  '#FCE4EC',
  '#26A69A',
  '#FFE57F',
  '#8E24AA',
  '#F4511E',
  '#9E9D24'
];
