import { Col, Radio, Row, Space } from 'antd';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import Error from '../../../../../components/Error';
import { useMeContext } from '../../../../../context';
import { capitalizeText } from '../../../../../helpers';
import { useAsync } from '../../../../../hooks';
import { EmissionFactorService } from '../../../../../services';
import { Tag } from '../../../../../stories/atoms';
import Input from '../../../../../stories/atoms/Input';
import { INPUT_TYPES } from '../../../../../stories/atoms/Input/Input.types';
import { TAG_COLOURS } from '../../../../../stories/atoms/Tag/tag.types';
import './SelectEmissionFactor.scss';

function SelectEmissionFactor({ activityUnitName }) {
  const { me } = useMeContext();
  const { setFieldValue, values } = useFormikContext();
  const loadEmissionFactors = useCallback(async () => {
    const response = await EmissionFactorService.listEmissionFactorDetails({
      companySlug: me.company?.slug,
      activityUnitName
    });
    setFilteredEmissionFactors(response.list);
    return response.list;
  }, [me.company?.slug]);
  const { Loader, value: emissionFactors } = useAsync({
    asyncFunction: loadEmissionFactors,
    defaultValue: []
  });
  const handleChange = (event) => {
    setFieldValue('emissionFactorDetailsId', event.target.value);
  };
  const [filteredEmissionFactors, setFilteredEmissionFactors] = useState([]);
  const handleSearchFactors = (e) => {
    setFilteredEmissionFactors(
      emissionFactors.filter((factor) =>
        factor.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  return (
    <div className="emission-source-detail-form-select-emission-factor">
      <Input
        isFormikField={false}
        type={INPUT_TYPES.SEARCH}
        onChange={handleSearchFactors}
        placeholder="Search Emission Factors"
      />
      <Field name="emissionFactorDetailsId">
        {() => (
          <Loader>
            <Radio.Group
              onChange={handleChange}
              value={values?.emissionFactorDetailsId}
            >
              <Space direction="vertical">
                {filteredEmissionFactors.map((f, i) => (
                  <Radio
                    key={f.id}
                    value={f.id}
                    className={i % 2 === 0 ? 'even' : 'odd'}
                  >
                    <Row>
                      <Col flex="auto">{capitalizeText(f.name)}</Col>
                      {!f.isInternal && (
                        <Col flex="shrink">
                          <Tag color={TAG_COLOURS.BLUE}>Custom</Tag>
                        </Col>
                      )}
                    </Row>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
            <ErrorMessage name="emissionFactorDetailsId">
              {(message) => (
                <Error
                  className="emissionFactorDetailsId__error"
                  message={message}
                />
              )}
            </ErrorMessage>
          </Loader>
        )}
      </Field>
    </div>
  );
}
export default SelectEmissionFactor;
