function BronzeVerification() {
  return (
    <svg
      width="122"
      height="46"
      viewBox="0 0 122 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74249 45.4042L0.0117188 34.3288H3.39762L7.38475 43.9169H5.39119L9.47325 34.3288H12.5744L7.82777 45.4042H4.74249Z"
        fill="url(#paint0_linear_11105_57038)"
      />
      <path
        d="M16.3169 38.6166H21.459V40.9582H16.3169V38.6166ZM16.5384 42.9834H22.3292V45.4042H13.4373V34.3288H22.1235V36.7496H16.5384V42.9834Z"
        fill="url(#paint1_linear_11105_57038)"
      />
      <path
        d="M24.2822 45.4042V34.3288H29.3452C30.3578 34.3288 31.228 34.4923 31.9559 34.8193C32.6942 35.1463 33.2638 35.6209 33.6646 36.2433C34.0654 36.8551 34.2659 37.5829 34.2659 38.4267C34.2659 39.26 34.0654 39.9825 33.6646 40.5943C33.2638 41.1955 32.6942 41.6597 31.9559 41.9866C31.228 42.3031 30.3578 42.4613 29.3452 42.4613H26.0226L27.415 41.1481V45.4042H24.2822ZM31.1331 45.4042L28.3801 41.3696H31.7185L34.4874 45.4042H31.1331ZM27.415 41.4803L26.0226 40.0405H29.1554C29.8093 40.0405 30.2945 39.8981 30.611 39.6133C30.938 39.3286 31.1015 38.933 31.1015 38.4267C31.1015 37.9099 30.938 37.509 30.611 37.2242C30.2945 36.9394 29.8093 36.797 29.1554 36.797H26.0226L27.415 35.3572V41.4803Z"
        fill="url(#paint2_linear_11105_57038)"
      />
      <path
        d="M36.2087 45.4042V34.3288H39.3415V45.4042H36.2087Z"
        fill="url(#paint3_linear_11105_57038)"
      />
      <path
        d="M44.6961 39.1862H49.8066V41.6069H44.6961V39.1862ZM44.9176 45.4042H41.7848V34.3288H50.4711V36.7496H44.9176V45.4042Z"
        fill="url(#paint4_linear_11105_57038)"
      />
      <path
        d="M52.1508 45.4042V34.3288H55.2836V45.4042H52.1508Z"
        fill="url(#paint5_linear_11105_57038)"
      />
      <path
        d="M63.2488 45.6257C62.3733 45.6257 61.5611 45.4886 60.8122 45.2143C60.0738 44.9295 59.4304 44.5287 58.8819 44.0119C58.344 43.495 57.9221 42.8885 57.6162 42.1923C57.3103 41.4856 57.1573 40.7103 57.1573 39.8665C57.1573 39.0227 57.3103 38.2527 57.6162 37.5565C57.9221 36.8498 58.344 36.238 58.8819 35.7211C59.4304 35.2043 60.0738 34.8088 60.8122 34.5345C61.5611 34.2497 62.3733 34.1073 63.2488 34.1073C64.2719 34.1073 65.1843 34.2866 65.986 34.6453C66.7982 35.0039 67.4732 35.5207 68.0112 36.1958L66.0176 37.9995C65.659 37.5776 65.2634 37.2559 64.831 37.0344C64.4091 36.8129 63.9344 36.7021 63.407 36.7021C62.9534 36.7021 62.5368 36.7759 62.1571 36.9236C61.7773 37.0713 61.4503 37.2875 61.1761 37.5723C60.9124 37.8466 60.7014 38.1788 60.5432 38.5691C60.3956 38.9594 60.3217 39.3918 60.3217 39.8665C60.3217 40.3412 60.3956 40.7736 60.5432 41.1639C60.7014 41.5542 60.9124 41.8917 61.1761 42.1765C61.4503 42.4508 61.7773 42.6617 62.1571 42.8094C62.5368 42.9571 62.9534 43.0309 63.407 43.0309C63.9344 43.0309 64.4091 42.9201 64.831 42.6986C65.2634 42.4771 65.659 42.1554 66.0176 41.7335L68.0112 43.5372C67.4732 44.2017 66.7982 44.7186 65.986 45.0878C65.1843 45.4464 64.2719 45.6257 63.2488 45.6257Z"
        fill="url(#paint6_linear_11105_57038)"
      />
      <path
        d="M68.1081 45.4042L72.9971 34.3288H76.0824L80.9872 45.4042H77.7278L73.8989 35.8635H75.133L71.3041 45.4042H68.1081ZM70.782 43.2524L71.5889 40.9424H77L77.8069 43.2524H70.782Z"
        fill="url(#paint7_linear_11105_57038)"
      />
      <path
        d="M83.8911 45.4042V36.8129H80.4894V34.3288H90.4097V36.8129H87.0238V45.4042H83.8911Z"
        fill="url(#paint8_linear_11105_57038)"
      />
      <path
        d="M91.7914 45.4042V34.3288H94.9242V45.4042H91.7914Z"
        fill="url(#paint9_linear_11105_57038)"
      />
      <path
        d="M102.953 45.6257C102.067 45.6257 101.249 45.4833 100.5 45.1985C99.7514 44.9137 99.0974 44.5129 98.5384 43.996C97.9899 43.4686 97.5627 42.8569 97.2568 42.1607C96.9509 41.4645 96.7979 40.6998 96.7979 39.8665C96.7979 39.0332 96.9509 38.2685 97.2568 37.5723C97.5627 36.8762 97.9899 36.2696 98.5384 35.7528C99.0974 35.2254 99.7514 34.8193 100.5 34.5345C101.249 34.2497 102.067 34.1073 102.953 34.1073C103.849 34.1073 104.667 34.2497 105.405 34.5345C106.154 34.8193 106.803 35.2254 107.351 35.7528C107.9 36.2696 108.327 36.8762 108.633 37.5723C108.949 38.2685 109.107 39.0332 109.107 39.8665C109.107 40.6998 108.949 41.4698 108.633 42.1765C108.327 42.8727 107.9 43.4792 107.351 43.996C106.803 44.5129 106.154 44.9137 105.405 45.1985C104.667 45.4833 103.849 45.6257 102.953 45.6257ZM102.953 43.0309C103.375 43.0309 103.765 42.9571 104.124 42.8094C104.493 42.6617 104.809 42.4508 105.073 42.1765C105.347 41.8917 105.558 41.5542 105.706 41.1639C105.864 40.7736 105.943 40.3412 105.943 39.8665C105.943 39.3813 105.864 38.9488 105.706 38.5691C105.558 38.1788 105.347 37.8466 105.073 37.5723C104.809 37.2875 104.493 37.0713 104.124 36.9236C103.765 36.7759 103.375 36.7021 102.953 36.7021C102.531 36.7021 102.135 36.7759 101.766 36.9236C101.407 37.0713 101.091 37.2875 100.817 37.5723C100.553 37.8466 100.342 38.1788 100.184 38.5691C100.036 38.9488 99.9623 39.3813 99.9623 39.8665C99.9623 40.3412 100.036 40.7736 100.184 41.1639C100.342 41.5542 100.553 41.8917 100.817 42.1765C101.091 42.4508 101.407 42.6617 101.766 42.8094C102.135 42.9571 102.531 43.0309 102.953 43.0309Z"
        fill="url(#paint10_linear_11105_57038)"
      />
      <path
        d="M110.963 45.4042V34.3288H113.542L119.649 41.7019H118.431V34.3288H121.5V45.4042H118.921L112.814 38.0312H114.032V45.4042H110.963Z"
        fill="url(#paint11_linear_11105_57038)"
      />
      <path
        d="M2.8608 19.5978V1.04752H12.348C14.786 1.04752 16.6057 1.49803 17.8071 2.39904C19.0084 3.28239 19.6091 4.44841 19.6091 5.8971C19.6091 6.85112 19.3529 7.6903 18.8406 8.41465C18.3459 9.12133 17.6392 9.68667 16.7205 10.1107C15.8195 10.517 14.7418 10.7202 13.4875 10.7202L14.0175 9.44816C15.3248 9.44816 16.4644 9.65133 17.4361 10.0577C18.4077 10.4463 19.1586 11.0205 19.6886 11.7802C20.2363 12.5222 20.5101 13.4321 20.5101 14.5098C20.5101 16.1174 19.8564 17.3718 18.5491 18.2728C17.2594 19.1562 15.369 19.5978 12.878 19.5978H2.8608ZM8.05489 15.8083H12.454C13.355 15.8083 14.0352 15.6581 14.4945 15.3578C14.9715 15.0398 15.21 14.5628 15.21 13.9267C15.21 13.2907 14.9715 12.8226 14.4945 12.5222C14.0352 12.2042 13.355 12.0452 12.454 12.0452H7.68388V8.41465H11.659C12.5423 8.41465 13.2048 8.26448 13.6465 7.96414C14.0882 7.6638 14.309 7.21329 14.309 6.61262C14.309 6.01194 14.0882 5.57026 13.6465 5.28759C13.2048 4.98725 12.5423 4.83709 11.659 4.83709H8.05489V15.8083Z"
        fill="url(#paint12_linear_11105_57038)"
      />
      <path
        d="M23.4424 19.5978V1.04752H31.9226C33.6186 1.04752 35.0761 1.32136 36.2951 1.86903C37.5318 2.41671 38.4858 3.21172 39.1572 4.25407C39.8285 5.27876 40.1642 6.49778 40.1642 7.91114C40.1642 9.30683 39.8285 10.517 39.1572 11.5417C38.4858 12.5487 37.5318 13.3261 36.2951 13.8737C35.0761 14.4038 33.6186 14.6688 31.9226 14.6688H26.3575L28.6895 12.4692V19.5978H23.4424ZM34.9171 19.5978L30.306 12.8402H35.8976L40.5352 19.5978H34.9171ZM28.6895 13.0257L26.3575 10.6142H31.6046C32.6999 10.6142 33.5126 10.3757 34.0426 9.89867C34.5903 9.42166 34.8641 8.75915 34.8641 7.91114C34.8641 7.04546 34.5903 6.37411 34.0426 5.8971C33.5126 5.4201 32.6999 5.18159 31.6046 5.18159H26.3575L28.6895 2.77005V13.0257Z"
        fill="url(#paint13_linear_11105_57038)"
      />
      <path
        d="M52.6282 19.9688C51.1442 19.9688 49.775 19.7303 48.5206 19.2533C47.2663 18.7763 46.1709 18.105 45.2346 17.2393C44.3159 16.3559 43.6004 15.3313 43.088 14.1652C42.5757 12.9992 42.3195 11.7184 42.3195 10.3227C42.3195 8.92699 42.5757 7.64613 43.088 6.48011C43.6004 5.31409 44.3159 4.29824 45.2346 3.43256C46.1709 2.54921 47.2663 1.86903 48.5206 1.39203C49.775 0.915018 51.1442 0.676514 52.6282 0.676514C54.1299 0.676514 55.4991 0.915018 56.7358 1.39203C57.9901 1.86903 59.0766 2.54921 59.9953 3.43256C60.914 4.29824 61.6295 5.31409 62.1419 6.48011C62.6719 7.64613 62.9369 8.92699 62.9369 10.3227C62.9369 11.7184 62.6719 13.0081 62.1419 14.1917C61.6295 15.3578 60.914 16.3736 59.9953 17.2393C59.0766 18.105 57.9901 18.7763 56.7358 19.2533C55.4991 19.7303 54.1299 19.9688 52.6282 19.9688ZM52.6282 15.6228C53.3349 15.6228 53.9885 15.4991 54.5892 15.2518C55.2076 15.0044 55.7376 14.6511 56.1793 14.1917C56.6386 13.7147 56.9919 13.1494 57.2393 12.4957C57.5043 11.842 57.6368 11.1177 57.6368 10.3227C57.6368 9.51 57.5043 8.78565 57.2393 8.14964C56.9919 7.49597 56.6386 6.93946 56.1793 6.48011C55.7376 6.00311 55.2076 5.64093 54.5892 5.3936C53.9885 5.14626 53.3349 5.02259 52.6282 5.02259C51.9215 5.02259 51.259 5.14626 50.6407 5.3936C50.04 5.64093 49.51 6.00311 49.0506 6.48011C48.609 6.93946 48.2556 7.49597 47.9906 8.14964C47.7433 8.78565 47.6196 9.51 47.6196 10.3227C47.6196 11.1177 47.7433 11.842 47.9906 12.4957C48.2556 13.1494 48.609 13.7147 49.0506 14.1917C49.51 14.6511 50.04 15.0044 50.6407 15.2518C51.259 15.4991 51.9215 15.6228 52.6282 15.6228Z"
        fill="url(#paint14_linear_11105_57038)"
      />
      <path
        d="M65.8996 19.5978V1.04752H70.2192L80.4484 13.3967H78.4078V1.04752H83.5489V19.5978H79.2293L69.0002 7.24863H71.0407V19.5978H65.8996Z"
        fill="url(#paint15_linear_11105_57038)"
      />
      <path
        d="M86.5371 19.5978V16.3118L97.4553 3.35306L98.0913 5.20809H86.7756V1.04752H102.755V4.33358L91.8372 17.2923L91.2012 15.4373H103.153V19.5978H86.5371Z"
        fill="url(#paint16_linear_11105_57038)"
      />
      <path
        d="M110.488 8.22914H119.101V12.1512H110.488V8.22914ZM110.859 15.5433H120.559V19.5978H105.665V1.04752H120.214V5.10209H110.859V15.5433Z"
        fill="url(#paint17_linear_11105_57038)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_11105_57038"
          x1="10.1358"
          y1="45.6257"
          x2="63.4945"
          y2="-35.0269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBAB1D" />
          <stop offset="1" stopColor="#ED2861" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default BronzeVerification;
