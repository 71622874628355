import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserType } from '../../constants';
import { useMeContext } from '../../context';
import { AppRoutes } from '../../routes';

function RequireAuth() {
  const { me } = useMeContext();

  if (me?.token) {
    //  Service Users should always be redirected to /admin
    if (me.userType === UserType.SERVICE) {
      return <Navigate to={AppRoutes.CHC_ADMIN} />;
    }
    return <Outlet />;
  }
  return <Navigate to={AppRoutes.LOGIN} />;
}

export default RequireAuth;
