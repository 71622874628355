import { Col, Row } from 'antd';
import { Card } from '../../../../../components';
import { useMeContext } from '../../../../../context';
import { analytics } from '../../../../../helpers';
import { AppRoutes } from '../../../../../routes';
import { Navigation } from '../../../../../stories/assets/svgs';
import { Link, Paragraph, Title } from '../../../../../stories/atoms';
import './CompanyInitiativeListEmptyState.scss';

export function CompanyInitiativeListEmptyState({ showCard }) {
  const me = useMeContext();
  const handleViewInitiatives = () => {
    analytics.track('Initiative Overview Clicked', me, {
      level1: 'Dashboard',
      level2: 'Empty State',
      level3: 'Next Stepper'
    });
  };
  if (showCard) {
    return (
      <Card className="company-initiative-list-empty-state-card">
        <Row
          justify="center"
          align="middle"
          className="company-initiative-list-empty-state-card__row"
        >
          <Col
            span={24}
            className="company-initiative-list-empty-state-card__icon"
          >
            <Navigation width="40" height="40" />
          </Col>
          <Col flex="70%" justify="center">
            <Title align="center" bottomSpacing={8}>
              No current initiatives
            </Title>
            <Paragraph align="center" size="sm" bottomSpacing={8}>
              Once you have started an initiative this is where they will all
              appear. Get started by browsing the available templates.
            </Paragraph>

            <Link
              onClick={handleViewInitiatives}
              to={AppRoutes.INITIATIVE}
              fontWeight={600}
              align="center"
            >
              View Recommended
            </Link>
          </Col>
        </Row>
      </Card>
    );
  }
  return (
    <Row className="company-initiative-list-empty-state" justify="center">
      <Col flex="490px">
        <Row>
          <Col span={24} className="display-flex justify-center">
            <Navigation />
          </Col>
          <Col span={24}>
            <Title align="center" bottomSpacing={16}>
              No current initiatives
            </Title>
            <Paragraph align="center" bottomSpacing={0}>
              Once you have started an initiatives this is where they will all
              appear. Get started by browsing the available templates.
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
