import { formatInTimeZone } from 'date-fns-tz';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import * as Yup from 'yup';

const today = new Date();

export const setReportingDetailsValidation = Yup.object().shape({
  startingMonth: Yup.string().required(
    'Please select the start of your fiscal year'
  ),
  footprintRange: Yup.array()
    .min(1, 'Please select at least one consecutive footprint baseline year')
    .test(
      'isConsecutive',
      'Baseline period years must be consecutive',
      (value) => {
        if (!value.length) return;
        const sortedYears = value.sort();
        // if there's only one value, it is valid
        if (value.length === 1) return true;
        const [startYear] = sortedYears;
        const endYear = sortedYears[sortedYears.length - 1];
        // Create an array of years based on the earliest and latest years selected
        const consecutiveYears = eachYearOfInterval({
          start: new Date(startYear, 1, 1),
          end: new Date(endYear, 1, 1)
        }).map((date) => {
          // Format the resulting array into int years for easy comparison
          const formattedDate = parseInt(formatInTimeZone(date, 'UTC', 'yyyy'));
          // Finally, return an array that compares the supplied years against the full timeline.
          // if a year is missing, it will be a -1 in the array
          return sortedYears.findIndex((year) => year === formattedDate);
        });
        // If there is a -1 in the above array, there is a year missing somewhere
        return consecutiveYears.indexOf(-1) < 0;
      }
    )
    .when('startingMonth', (startingMonth, schema) =>
      schema.test({
        test: (footprintRange) => {
          if (startingMonth <= today.getMonth() + 1) return true;
          return !footprintRange.find((fp) => fp === today.getFullYear());
        },
        message:
          'Cannot select footprint baseline years from a future fiscal year.'
      })
    ),
  reportingStartYear: Yup.string()
    .required('Reporting start year is required')
    .when('footprintRange', (footprintRange, schema) =>
      schema.test({
        test: (reportingStartYear) => {
          if (!footprintRange?.length) return true;
          return reportingStartYear >= Math.min(...footprintRange);
        },
        message:
          'Cannot select reporting start year before your footprint year.'
      })
    )
});
