import { useCallback, useState } from 'react';
import { Notification } from '../../../components';
import { CHCAdminIntegrationStatus } from '../../../constants';
import { capitalizeText } from '../../../helpers';
import { useAsync } from '../../../hooks';
import { ChcAdminService, IntegrationService } from '../../../services';
import {
  Anchor,
  Button,
  ColumnTitle,
  Select,
  Table
} from '../../../stories/atoms';
import TableTitle from '../../../stories/molecules/TableTitle';
import { BUTTON_TYPES } from '../../../stories/atoms/Button/Button.types';

export function ChcAdminProviderList({ companySlug }) {
  const [searchValue, setSearchValue] = useState('');
  const loadAdminProviderList = useCallback(
    async () => await ChcAdminService.listCompanyProviders(companySlug),
    [companySlug]
  );
  const { value: providerList, isLoading } = useAsync({
    asyncFunction: loadAdminProviderList,
    defaultValue: []
  });

  const filteredProviderList = providerList.filter((company) =>
    company.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleSearchList = (value) => {
    setSearchValue(value);
  };

  const updateProviderStatus = async (status, provider) => {
    if (status === CHCAdminIntegrationStatus.CONNECTED) {
      await IntegrationService.updateCompanyIntegration({
        companySlug,
        integrationSlug: provider.type.toLowerCase(),
        status: CHCAdminIntegrationStatus.CONNECTED
      });
    }
    const response = await ChcAdminService.updateCompanyProvider({
      companyUtilityProviderId: provider.id,
      companySlug,
      status
    });

    if (!(response instanceof Error)) {
      Notification({
        type: 'success',
        message: `${provider.name} Updated`,
        description: `Updated ${provider.utilityProviderId} to ${status}`
      });
    }
  };

  const { execute: handleUpdateProviderStatus } = useAsync({
    asyncFunction: updateProviderStatus,
    immediate: false
  });

  const columns = [
    {
      title: <ColumnTitle>Provider Name</ColumnTitle>,
      dataIndex: 'name',
      render: (name) => <span className="text-bd">{name}</span>
    },
    {
      title: <ColumnTitle>Connection Type</ColumnTitle>,
      dataIndex: 'type',
      render: (type) => capitalizeText(type)
    },
    {
      title: <ColumnTitle>Include Historical Import</ColumnTitle>,
      dataIndex: 'includeHistoricalData',
      render: (includeHistoricalData) => (
        <span>{includeHistoricalData ? 'Yes' : 'No'}</span>
      )
    },
    {
      title: <ColumnTitle>Include Ongoing Import</ColumnTitle>,
      dataIndex: 'includeOngoingData',
      render: (includeOngoingData) => (
        <span>{includeOngoingData ? 'Yes' : 'No'}</span>
      )
    },
    {
      width: '300px',
      title: <ColumnTitle>Provider Status</ColumnTitle>,
      dataIndex: 'status',
      render: (status, provider) => (
        <Select
          className="full-width"
          onChange={(value) => {
            handleUpdateProviderStatus(value, provider);
          }}
          defaultValue={status}
          disableForm
          options={Object.values(CHCAdminIntegrationStatus).map((Is) => ({
            value: Is,
            label: capitalizeText(Is)
          }))}
        />
      )
    },
    {
      title: <ColumnTitle>1Password Credentials Link</ColumnTitle>,
      dataIndex: 'onePasswordItemUrl',
      render: (onePasswordItemUrl) =>
        onePasswordItemUrl ? (
          <Button type={BUTTON_TYPES.SECONDARY}>
            <Anchor href={onePasswordItemUrl}>Login Creds</Anchor>
          </Button>
        ) : (
          'Not Found'
        )
    }
  ];

  return (
    <div>
      <TableTitle
        className="mb-8"
        title="Providers"
        searchProps={{ onSearch: handleSearchList }}
      />
      <Table
        dataSource={filteredProviderList}
        columns={columns}
        isLoading={isLoading}
      />
    </div>
  );
}
