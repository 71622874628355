function ArrowUp() {
  return (
    <path
      d="M5 10L12 3M12 3L19 10M12 3V21"
      stroke="#27272A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}
export default ArrowUp;
