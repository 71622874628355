import {
  EmployeeCommuteContextProvider,
  MeContextProvider,
  MetaContextProvider,
  OfficeTrailContextProvider
} from './context';

export function ContextProviders({ children }) {
  return (
    <MeContextProvider>
      <MetaContextProvider>
        <OfficeTrailContextProvider>
          <EmployeeCommuteContextProvider>
            {children}
          </EmployeeCommuteContextProvider>
        </OfficeTrailContextProvider>
      </MetaContextProvider>
    </MeContextProvider>
  );
}
