import { Anchor, Paragraph } from '../stories/atoms';

function GHGPopover() {
  return (
    <div>
      <Paragraph>
        Carbonhound follows the quantification and reporting requirements laid
        out in the Greenhouse Gas Protocol Standards:
        <br />
        <br />
        <Anchor
          href="https://ghgprotocol.org/companies-and-organizations"
          bottomSpacing={0}
        >
          https://ghgprotocol.org/companies-and-organizations
        </Anchor>
      </Paragraph>
    </div>
  );
}

function LimitedAssurancesPopover() {
  return (
    <div>
      <Paragraph bottomSpacing={0}>
        The verification activities are designed to complete a high-level
        review.
        <br />
        <br />
        The verifier will investigate any issues that arise through this high
        level review.
      </Paragraph>
    </div>
  );
}

function ThirdPartyVerifiedPopover() {
  return (
    <div>
      <Paragraph align="left" bottomSpacing={0}>
        Verification is an independent and objective assessment of the accuracy
        and completeness of reported GHG information.
        <br />
        <br />
        Verification adds quality and credibility to reported GHG information
        and can be a mandatory element of reporting under some programs.
      </Paragraph>
    </div>
  );
}
function BrightspotVerificationStatementPopover() {
  return (
    <Paragraph align="left" bottomSpacing={0}>
      Brightspot will issue a verification statement and does not restrict the
      use of the unaltered statement.
    </Paragraph>
  );
}
function BrightspotVerificationReportPopover() {
  return (
    <Paragraph align="left" bottomSpacing={0}>
      Brightspot will issue a verification report and does not restrict the use
      of the unaltered report.
      <br />
      <br />
      The verification report includes the verification opinion along with
      details of the evidence-gathering activities conducted and any findings.
    </Paragraph>
  );
}
function IsoCompliantPopover() {
  return (
    <Paragraph align="left" bottomSpacing={0}>
      ISO 14064-3 is a standard for the verification of greenhouse gas
      statements.
      <br />
      <br />
      Brightspot has designed a streamlined bronze verification option guided by
      the principles in the ISO 14064-3 Standard.
      <br />
      <br />
      Silver and gold verifications are fully compliant with the requirements of
      ISO 14064-3.
    </Paragraph>
  );
}
function PeerReviewedPopover() {
  return (
    <Paragraph align="left" bottomSpacing={0}>
      ISO-14064-3 compliant verifications require an independent peer review of
      the verification process and conclusion.
    </Paragraph>
  );
}

const VerificationPricing = [
  {
    title: 'Bronze',
    pricePoint: '$375-750',
    features: [
      {
        title: 'Level of assurance',
        included: {
          title: 'Limited',
          popover: {
            title: 'Limited Assurance',
            content: <LimitedAssurancesPopover />
          }
        }
      },
      {
        title: 'GHG Protocol Methodology',
        included: true,
        popover: {
          title: 'GHG Protocol Methodology',
          content: <GHGPopover />
        }
      },
      {
        title: 'Third Party Verified',
        included: true,
        popover: {
          title: 'Third Party Verified',
          content: <ThirdPartyVerifiedPopover />
        }
      },
      {
        title: 'Brightspot Verification Statement',
        included: true,
        popover: {
          title: 'Brightspot Verification Statement',
          content: <BrightspotVerificationStatementPopover />
        }
      },
      {
        title: 'ISO 14064-3 Compliant',
        included: false,
        popover: {
          title: 'ISO 14064-3 Compliant',
          content: <IsoCompliantPopover />
        }
      },
      {
        title: 'Peer Reviewed',
        included: false,
        popover: { title: 'Peer Reviewed', content: <PeerReviewedPopover /> }
      },
      {
        title: 'Timeline',
        included: { title: '1-2 Days' }
      },
      {
        title: 'Interviews',
        included: false
      }
    ],
    badges: [
      { title: 'Bronze Tier Verification', included: true },
      { title: 'Limited Level of Assurance', included: true },
      { title: 'GHG Protocol Quantification', included: true },
      { title: 'ISO 14064-3 Compliant', included: false }
    ]
  },
  {
    title: 'Silver',
    pricePoint: 'On Request',
    features: [
      {
        title: 'Level of assurance',
        included: {
          title: 'Limited',
          popover: {
            title: 'Limited Assurance',
            content: <LimitedAssurancesPopover />
          }
        }
      },
      {
        title: 'GHG Protocol Methodology',
        included: true,
        popover: {
          title: 'GHG Protocol Methodology',
          content: <GHGPopover />
        }
      },
      {
        title: 'Third Party Verified',
        included: true,
        popover: {
          title: 'Third Party Verified',
          content: <ThirdPartyVerifiedPopover />
        }
      },
      {
        title: 'Brightspot Verification Report',
        included: true,
        popover: {
          title: 'Brightspot Verification Report',
          content: <BrightspotVerificationReportPopover />
        }
      },
      {
        title: 'ISO 14064-3 Compliant',
        included: true,
        popover: {
          title: 'ISO 14064-3 Compliant',
          content: <IsoCompliantPopover />
        }
      },
      {
        title: 'Peer Reviewed',
        included: true,
        popover: { title: 'Peer Reviewed', content: <PeerReviewedPopover /> }
      },
      {
        title: 'Timeline',
        included: { title: 'Provided at time of quotation' }
      },
      {
        title: 'Interviews',
        included: true
      }
    ],
    badges: [
      { title: 'Silver Tier Verification', included: true },
      { title: 'Limited Level of Assurance', included: true },
      { title: 'GHG Protocol Quantification', included: true },
      { title: 'ISO 14064-3 Compliant', included: true }
    ]
  },
  {
    title: 'Gold',
    pricePoint: 'On Request',
    features: [
      {
        title: 'Level of assurance',
        included: {
          title: 'Reasonable',
          popover: {
            title: 'Reasonable Assurance',
            content: (
              <div>
                <Paragraph>
                  The verification activities are designed to complete an
                  in-depth review.
                </Paragraph>
                <Paragraph bottomSpacing={0}>
                  This type of assurance allows the verifier to conclude that
                  there are no material errors in your footprint.
                </Paragraph>
              </div>
            )
          }
        }
      },
      {
        title: 'GHG Protocol Methodology',
        included: true,
        popover: {
          title: 'GHG Protocol Methodology',
          content: <GHGPopover />
        }
      },
      {
        title: 'Third Party Verified',
        included: true,
        popover: {
          title: 'Third Party Verified',
          content: <ThirdPartyVerifiedPopover />
        }
      },
      {
        title: 'Brightspot Verification Report',
        included: true,
        popover: {
          title: 'Brightspot Verification Report',
          content: <BrightspotVerificationReportPopover />
        }
      },
      {
        title: 'ISO 14064-3 Compliant',
        included: true,
        popover: {
          title: 'ISO 14064-3 Compliant',
          content: <IsoCompliantPopover />
        }
      },
      {
        title: 'Peer Reviewed',
        included: true,
        popover: { title: 'Peer Reviewed', content: <PeerReviewedPopover /> }
      },
      {
        title: 'Timeline',
        included: { title: 'Variable' }
      },
      {
        title: 'Interviews',
        included: true
      }
    ],
    badges: [
      { title: 'Gold Tier Verification', included: true },
      { title: 'Reasonable Level of Assurance', included: true },
      { title: 'GHG Protocol Quantification', included: true },
      { title: 'ISO 14064-3 Compliant', included: true }
    ]
  }
];

export default VerificationPricing;
